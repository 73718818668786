import React from "react"
import { observer } from "mobx-react-lite"

import Select from "@components/ui/DropDown"
import { Button } from "@components/ui/Button"

type GoalPickerProps = {
  disabled?: boolean
  options: string[]
  className?: string
  renderLabel?: (option: string) => string
  onPick?: (value: string) => void
}

const GoalPicker: React.FC<GoalPickerProps> = observer(
  ({ options, renderLabel, onPick }) => (
    <Select.DropDown
      target={(active) => (
        <Button
          size="big"
          variant={active ? "contained" : "outlined"}
          startIcon={{ name: "add" }}
        >
          Add Monthly Goal
        </Button>
      )}
    >
      <Select.Box>
        <Select.ItemsList>
          {options.map((it) => (
            <Select.Option onClick={() => onPick?.(it)} key={it}>
              {renderLabel?.(it) ?? it}
            </Select.Option>
          ))}
        </Select.ItemsList>
      </Select.Box>
    </Select.DropDown>
  )
)

export default GoalPicker
