import React from "react"
import get from "lodash/get"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import { MetricDescription } from "@framework/types/metrics"
import NoData from "@components/ui/NoData/NoData"
import { MetaCampaignReportData } from "@pages/MetaAdsChannel/types"
import MetricValueChip from "../MetricValueChip"

import styles from "./ReportCard.module.sass"

interface ReportSummaryCardProps {
  data: MetaCampaignReportData
  metrics?: MetricDescription[]
}

export const ReportSummaryCard: React.FC<ReportSummaryCardProps> = ({
  data,
  metrics,
}) => (
  <Box className={styles.root} elevation={1}>
    <div className={styles.header}>
      <Typography type="h5" weight="bold" color="primary100Color">
        Aggregated Metrics
      </Typography>
    </div>

    <Stack
      className={styles.metricsContainer}
      gutter="4"
      direction="column"
      align="stretch"
    >
      {metrics?.map((metric) => {
        const value = get(data, metric.name, null)
        return (
          <Stack
            direction="row"
            justify="space-between"
            align="center"
            key={metric.name}
          >
            <Typography type="subhead1">{metric.label}</Typography>

            <Typography type="caption3">
              <MetricValueChip
                className={styles.metricChip}
                value={value}
                metric={metric}
              />
            </Typography>
          </Stack>
        )
      }) ?? <NoData />}
    </Stack>
  </Box>
)

export default ReportSummaryCard
