import React from "react"
import isUndefined from "lodash/isUndefined"
import { CampaignStatusType } from "@framework/types/account"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { MetricType } from "@framework/types/metrics"
import { statusToLabel } from "@utils/stringUtils"
import Budget from "./Budget"
import CpcForm from "./CpcForm"
import Status from "./Status"
import BidStrategy from "./BidStrategy"
import TableLink from "./TableLink"
import BiddingStatus from "./BiddingStatus"
import KPIsMapper from "./kpi-table-mapper"

export const getMapper = (): MapperType[] => {
  const list: MapperType[] = []

  list.push({
    name: "advertisingChannelType",
    label: "Campaign Type",
    widthBasis: "70px",
    sortable: true,
    renderCallback: (value: string) => statusToLabel(value ?? ""),
  })

  list.push({
    name: "BiddingStrategyType",
    label: "Bid Strategy",
    widthBasis: "70px",
    sortable: true,
    renderCallback: (value: MetricType, data, name) => (
      <BidStrategy
        name={name}
        value={value}
        data={data}
        campaignId={data.CampaignId}
        editable={data.editable}
      />
    ),
  })

  list.push({
    name: "PrimaryStatus",
    label: "Bidding Status",
    widthBasis: "70px",
    sortable: true,
    renderCallback: (value: MetricType, data, name) => (
      <BiddingStatus name={name} value={value} data={data} />
    ),
  })

  return list.concat([
    {
      name: "Rules",
      label: "Rules",
      renderCallback: () => <TableLink to="./rules" />,
    },
    {
      name: "Amount",
      label: "Budget",
      sortable: true,
      renderCallback: (value: MetricType, data, name) =>
        !isUndefined(value) && (
          <Budget
            name={name}
            value={value.Value.toString()}
            data={data}
            campaignId={data.CampaignId}
            editable={data.editable}
          />
        ),
    },
    {
      name: "Status",
      label: "Status",
      widthBasis: "45px",
      sortable: true,
      renderCallback: (value: CampaignStatusType, data, name) =>
        !isUndefined(value) && (
          <Status
            name={name}
            value={value}
            data={data}
            editable={data.editable}
            entityType={data.type}
            campaignId={data.CampaignId}
            adgroupName={data.Name}
            adGroupId={data.AdgroupId}
            keywordId={data.KeywordId}
            adId={data.AdId}
            key={data.type}
          />
        ),
    },
    {
      name: "CpcBid",
      label: "Max CPC $",
      sortable: true,
      renderCallback: (value: MetricType, data, name) =>
        !isUndefined(value) && (
          <CpcForm
            name={name}
            value={value.Value.toString()}
            data={data}
            editable={data.editable}
            adGroupId={data.AdgroupId}
            adgroupName={data.Name}
            keywordId={data.KeywordId}
            entityType={data.type}
          />
        ),
    },
    ...KPIsMapper,
  ])
}

export const mapper = getMapper()
export default mapper
