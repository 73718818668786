/* eslint-disable no-underscore-dangle */
import { action, computed, observable } from "mobx"

import accountService from "@services/account.service"
import { MetricNameType, MetricType } from "@framework/types/metrics"
import { AccountGoalSettingsOption } from "@framework/types/account"
import { ID } from "@framework/types/types"
import { getMetricDescriptor } from "@framework/constants/metrics"

class AccountGoalsPerformanceStore {
  // INJECTIONS

  @observable performance: Partial<Record<MetricNameType, MetricType>> | null =
    null

  @observable settings: AccountGoalSettingsOption[] | null = null

  @observable options: MetricNameType[] | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  @action loadGoalsMonthlyPerformance = async (accountId: number) => {
    try {
      this.isLoading = true
      this.error = null

      const [performance, settings] = await Promise.all([
        accountService.getAccountGoalMonthlyPerformance(accountId),
        accountService.getAccountGoalSettings(accountId),
      ])

      this.performance = performance.data.data ?? null
      this.settings = settings.data.data?.settings ?? null
    } catch (error) {
      this.error = "Unexpected error"
      this.performance = null
      this.settings = null
    } finally {
      this.isLoading = false
    }
    return this.error
  }

  @action loadGoalsSettings = async (accountId: number) => {
    try {
      this.isLoading = true
      this.error = null

      const [options, settings] = await Promise.all([
        accountService.getAccountGoalOptions(),
        accountService.getAccountGoalSettings(accountId),
      ])

      this.options = options.data.data ?? []
      this.settings = settings.data.data?.settings ?? []
    } catch (error) {
      this.error = "Unexpected error"
      this.options = null
      this.settings = null
    } finally {
      this.isLoading = false
    }
    return this.error
  }

  @action updateAccountGoalSettings = async (
    accountId: ID,
    data: AccountGoalSettingsOption[]
  ) => {
    try {
      this.isLoading = true
      this.error = null

      const resp = await accountService.updateAccountGoalSettings(
        accountId,
        normalizeGoalsSettingsForAPI(data)
      )
      this.settings = resp.data.data?.settings ?? null
    } catch (error) {
      this.settings = null
      this.error = "Unexpected error"
    } finally {
      this.isLoading = false
    }
    return this.error
  }

  @computed get normalizedSettings() {
    return (this.settings ?? []).reduce<AccountGoalSettingsOption[]>(
      (acc, it) => {
        const desc = getMetricDescriptor(it.type)
        if (desc == null) return acc
        acc.push({
          ...it,
          targetValue: desc.normalizeForUI?.(it.targetValue) ?? it.targetValue,
        })
        return acc
      },
      []
    )
  }
}

export const normalizeGoalsSettingsForAPI = (
  data: AccountGoalSettingsOption[]
) =>
  data.reduce<AccountGoalSettingsOption[]>((acc, it) => {
    const desc = getMetricDescriptor(it.type)
    if (desc == null) return acc
    acc.push({
      ...it,
      targetValue: desc.normalizeForAPI?.(it.targetValue) ?? it.targetValue,
    })
    return acc
  }, [])

export default AccountGoalsPerformanceStore
