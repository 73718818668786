import { MetricNameType } from "@framework/types/metrics"
import { DataSetType as LineChartDataSetType } from "@components/ui/Charts/LineChart/LineChart"
import { darkColors } from "@components/ui/Charts/utils"

export const extractPlatformReportDataset = (
  metric: MetricNameType,
  data: number[]
): LineChartDataSetType => ({
  key: metric,
  label: metric,
  backgroundColor: darkColors[0],
  borderColor: darkColors[0],
  yAxisID: "y",
  data,
})

export default null
