import React from "react"
import { observer } from "mobx-react-lite"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom"
import EditCampaignPage from "./EditCampaign/EditCampaign"

interface PMaxRootProps {
  basePath?: string
}

export const PMaxRoot: React.FC<PMaxRootProps> = observer(
  ({ basePath = "/" }) => {
    const location = useLocation()
    const history = useHistory()

    return (
      <Switch>
        <Route path={`${basePath}/:campaignId`} component={EditCampaignPage} />
        <Route path={`${basePath}/**`}>
          <Redirect to={basePath} />
        </Route>
      </Switch>
    )
  }
)

export default PMaxRoot
