/* eslint-disable jsx-a11y/no-autofocus */
import React from "react"
import { observer, useLocalStore } from "mobx-react-lite"

import SimpleOption from "@components/ui/DropDown/Option"
import Select from "@components/ui/DropDown"
import NoData from "@components/ui/NoData/NoData"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import { PopperPlacement } from "@components/ui/DropDown/usePopper"
import { Button } from "@components/ui/Button"
import TextField from "@components/ui/TextField/TextField"
import { ID } from "@framework/types/types"
import Collapsable from "@components/ui/Collapsible/Collapsable"
import CollapsibleHeader from "@components/ui/Collapsible/CollapsibleHeader"
import useToggle from "@framework/hooks/useToggle"
import IconButton from "@components/ui/Button/IconButton"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import KeywordCategoriesStore from "./KeywordCategoriesStore"

import styles from "./KeywordSelect.module.scss"

export interface SimpleSelectProps {
  value?: ID
  className?: string
  placement?: PopperPlacement
  disabled?: boolean
  onSelect?: (keywordId: ID) => void
}

const KeywordSelect = observer(
  ({
    value,
    className,
    placement,
    disabled,
    onSelect,
  }: React.PropsWithChildren<SimpleSelectProps>) => {
    const opportunitiesStore = useOpportunitiesStore()
    const keyword = opportunitiesStore.keywordsList.getKeywordById(value)

    const categoryStore = useLocalStore(
      () => new KeywordCategoriesStore(opportunitiesStore)
    )

    const groups = categoryStore.filteredCategories

    return (
      <Select.DropDown
        disabled={disabled}
        placement={placement}
        keepOpened
        target={(isActive) => (
          <Button
            variant="ghost"
            size="small"
            endIcon={{ name: "arrow-right", rotateAngle: isActive ? -90 : 90 }}
          >
            <Typography type="h1" weight="bold" className={className} inline>
              {keyword?.displayName ?? "Not Selected"}
            </Typography>
          </Button>
        )}
      >
        {(_, toggle) => (
          <Select.Box>
            <TextField
              className={styles.search}
              placeholder="Search"
              before={<Icon name="search" />}
              value={categoryStore.searchFiler}
              onChange={(e) => categoryStore.setSearchFilter(e.target.value)}
              after={
                categoryStore.searchFiler ? (
                  <IconButton onClick={() => categoryStore.setSearchFilter("")}>
                    <Icon name="cross" />
                  </IconButton>
                ) : undefined
              }
            />
            <Select.ItemsList>
              {groups.length > 0 ? (
                <Select.Group>
                  {groups.map((it) => (
                    <GroupItem
                      name={it.displayName}
                      total={it.keywords.length}
                      key={it.id}
                    >
                      {it.keywords ? (
                        it.keywords.map((it) => (
                          <SimpleOption
                            isSelected={it.id.toString() === value?.toString()}
                            onClick={(e) => {
                              onSelect?.(it.id)
                              toggle(e, false)
                            }}
                            key={it.id}
                          >
                            {it.displayName}
                          </SimpleOption>
                        ))
                      ) : (
                        <NoData>No keywords found</NoData>
                      )}
                    </GroupItem>
                  ))}
                </Select.Group>
              ) : (
                <NoData>List is empty</NoData>
              )}
            </Select.ItemsList>
          </Select.Box>
        )}
      </Select.DropDown>
    )
  }
)

export default KeywordSelect

interface GroupItemProps {
  name: string
  total: number
}

const GroupItem: React.FC<GroupItemProps> = ({ name, total, children }) => {
  const toggle = useToggle()

  return (
    <>
      <CollapsibleHeader
        open={toggle.isOpened}
        type="body2"
        label={`${name} • ${total}`}
        rootClassName={styles.groupHeader}
        weight="bold"
        onClick={toggle.handleToggle}
      />
      <Collapsable open={toggle.isOpened}>{children}</Collapsable>
    </>
  )
}
