import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"

import { useController, useStore } from "@root/store"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Templates from "@components/ui/Templates"
import { Button } from "@components/ui/Button"
import { FormStepName } from "@store/manage-campaigns/validation"
import PlaceholderLoader from "@components/ui/Loader/PlaceholderLoader"
import NoData from "@components/ui/NoData/NoData"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { makeAssetCreatingLink } from "@framework/constants/manageCampaign"
import useEventListener from "@framework/hooks/useEventListener"
import { throttle } from "lodash"
import { assetsValidationSchema as validationSchema } from "@store/manage-campaigns/validation/assets"
import OnHoverTooltip from "@components/ui/Tooltip/TooltipContainer/TooltipContainer"
import Icon from "@components/ui/Icon/Icon"
import ComicBubble from "@components/ui/Tooltip/ComicBubble/ComicBubble"
import { useHistory } from "react-router-dom"
import useURLSearchParams from "@framework/hooks/useURLSearchParams"
import AssetCard from "./AssetCard/AssetCard"
import FormStepLayout from "../FormStepLayout"

import styles from "./index.module.scss"

const STEP_NAME: FormStepName = "assets"

export type AssignGroupFormProps = {
  className?: string
  onSubmit?: React.MouseEventHandler
  onCancel?: React.MouseEventHandler
}

const AssignGroupForm: React.FC<AssignGroupFormProps> = observer(
  ({ onSubmit, onCancel, className }) => {
    const alert = useAlert()
    const { assetGroupsStore, editPMaxCampaignStore: store } = useStore()
    const { editPMaxCampaignController: controller } = useController()
    const params = useURLSearchParams()

    const hasAssets = !!assetGroupsStore.data?.length

    // const createAssetGroup = params.get("createAssetGroup")

    const handleSubmit = async (form: any) => {
      if (!hasAssets) {
        alert.error(
          <AlertMessage
            title="Failed to save draft"
            description="There are no assets in campaign"
          />
        )
        return
      }

      store.setCampaignValid(STEP_NAME, true)

      onSubmit?.(form)
    }

    const formik = useFormik<any>({
      initialValues: validationSchema.cast(store.data, {
        stripUnknown: true,
      }),
      validationSchema,
      onSubmit: handleSubmit,
    })

    const [isRefreshAllowed, allowRefresh] = React.useState(false)

    const refresh = React.useCallback(
      throttle(() => {
        allowRefresh(false)
        controller.loadAssetGroups(store.campaignId)
      }, 3_000),
      [store.campaignId]
    )

    React.useEffect(() => {
      if (!store.isLoading) refresh()
    }, [store.campaignId])

    React.useEffect(() => {
      if (isRefreshAllowed || hasAssets) return undefined

      const timer = setTimeout(() => {
        allowRefresh(true)
      }, 25_000)

      return () => clearTimeout(timer)
    }, [isRefreshAllowed, hasAssets])

    useEventListener({
      type: "visibilitychange",
      element: document,
      listener: () => {
        if (!isRefreshAllowed) allowRefresh(true)
        else refresh()
      },
    })

    return (
      <FormikProvider value={formik}>
        <FormStepLayout step="assets" className={clsx(styles.root, className)}>
          <form onSubmit={formik.handleSubmit}>
            <Stack gutter="24" align="stretch" className={styles.content}>
              {/* <div className={styles.header}>
                <Typography type="h4">
                  Choose the way to add an Asset group to the campaign
                </Typography>

                <Stack gutter="32" direction="row">
                  <Label text="Choose the existing Asset group">
                    <Radio id="existing" value="existing" />
                  </Label>
                  <Label text="Create new Asset group">
                    <Radio id="new" />
                  </Label>
                </Stack>
              </div> */}

              {assetGroupsStore.data?.length ? (
                assetGroupsStore.data.map((data) => (
                  <AssetCard campaignId={store.campaignId} data={data} />
                ))
              ) : assetGroupsStore.isLoading ? (
                <PlaceholderLoader />
              ) : (
                <AssetGroupsPlaceholder
                  isRefreshAllowed={isRefreshAllowed}
                  onRefresh={refresh}
                />
              )}

              {hasAssets && (
                <Templates.Header
                  right={
                    <>
                      <Button size="big" color="secondary" onClick={onCancel}>
                        Cancel
                      </Button>
                      <Button size="big" color="primary" type="submit">
                        Continue
                      </Button>
                    </>
                  }
                />
              )}
            </Stack>
          </form>
        </FormStepLayout>
      </FormikProvider>
    )
  }
)

export default AssignGroupForm

const AssetGroupsPlaceholder: React.FC<{
  isRefreshAllowed: boolean
  onRefresh: () => void
}> = observer(({ isRefreshAllowed, onRefresh }) => {
  const { editPMaxCampaignStore } = useStore()
  const history = useHistory()

  const createAssetLink = makeAssetCreatingLink(
    editPMaxCampaignStore.campaignId
  )

  return (
    <div>
      <NoData>
        There is no Asset Group assigned to this Campaign. Please, create it at
        Google Ads first.
      </NoData>

      <Stack
        direction="row"
        justify="center"
        className={styles.createAssetGroup}
      >
        <Button
          before={<Icon name="plus" />}
          onClick={() => history.push(`?s=assets&createAssetGroup=true`)}
        >
          Create Asset Group
        </Button>
        {isRefreshAllowed && (
          <Button
            color="secondary"
            before={<Icon name="refresh" />}
            onClick={onRefresh}
          >
            Refresh
          </Button>
        )}

        <OnHoverTooltip
          placement="bottom"
          toolTip={(props) => (
            <ComicBubble justify="center" className={styles.bubble} {...props}>
              <Typography>
                Click “Create Asset Group” button. Follow the prompts to Login
                to Google Ad console with your email that is associated with the
                same Google Ads customer ID that is linked in Adoro. Then Select
                the draft PMax campaign created from Adoro. Add and save Asset
                Group(s) there. Then return to Adoro and continue creating or
                editing the PMax campaign.
              </Typography>
            </ComicBubble>
          )}
        >
          <Typography type="h1" color="black30Color">
            <Icon name="information-solid" />
          </Typography>
        </OnHoverTooltip>
      </Stack>
    </div>
  )
})
