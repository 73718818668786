import React from "react"
import clsx from "clsx"

import { MetricDescription, MetricType } from "@framework/types/metrics"
import { mbNoData } from "@services/utils"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import TempScale from "./TempScale"

import styles from "./MetricCard.module.sass"

export const MetricCard: React.FC<{
  metric: MetricDescription
  value?: MetricType | null
  className?: string
  withScale?: boolean
}> = ({ metric, value, withScale, className }) => (
  <Box className={clsx(styles.root, className)} elevation={0}>
    <Stack direction="row" gutter="8">
      <Stack direction="column" gutter="12">
        <Typography type="caption3">{metric.label}</Typography>
        <Typography type="body1">
          {mbNoData(value?.Value, metric.formatter)}
        </Typography>
      </Stack>

      {!!(value && withScale) && <TempScale value={value?.Change} />}
    </Stack>
  </Box>
)

export default MetricCard
