import React from "react"

import styles from "./ContentMenuTemplate.module.scss"

interface ContentMenuTemplateProps {
  sidebarSlot: React.ReactNode
}

const ContentMenuTemplate: React.FC<ContentMenuTemplateProps> = ({
  sidebarSlot,
  children,
}) => (
  <div className={styles.root}>
    <div className={styles.menuContainer}>{sidebarSlot}</div>

    <div className={styles.content}>{children}</div>
  </div>
)

export default ContentMenuTemplate
