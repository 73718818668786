import React from "react"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import { TabNames } from "@pages/CreateCampaign/CreateCampaign"
import { useStore } from "@store/index"

import styles from "./FinalSuccess.module.scss"

const GoogleAdwordsLink: React.FC<{ switchTab: (tabName: TabNames) => void }> =
  observer(({ switchTab }) => {
    const history = useHistory()

    const { createdCampaign } = useStore()

    const handleClick = () => {
      history.replace(`./manage-campaign`)
    }

    React.useEffect(() => {
      if (createdCampaign.createdCampaigns == null) switchTab("action")
    }, [createdCampaign.createdCampaigns])

    const campaigns = createdCampaign.createdCampaigns?.campaignsNumber ?? 0

    const message =
      campaigns > 0 ? (
        <>
          {campaigns} Google Ads Campaigns were
          <br />
          successfully created and published!
        </>
      ) : (
        <>
          {campaigns} Campaigns were created
          <br />
          Please, add more keywords to the cart
        </>
      )

    return (
      <div className={styles.root}>
        <Typography
          type="h2"
          color="primary"
          weight="bold"
          className={styles.title}
        >
          {message}
        </Typography>
        <div className={styles.buttonsWrapper}>
          <Button variant="contained" color="primary" onClick={handleClick}>
            View Account Campaigns
          </Button>
        </div>
      </div>
    )
  })

export default GoogleAdwordsLink
