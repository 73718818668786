import { observable } from "mobx"
import PMaxProductsStore from "./pmax-products.store"
import ProductCampaignStore from "./product-campaigns.store"

class RevenueProductsStore {
  // INJECTIONS

  @observable campaignStore: ProductCampaignStore

  @observable productsStore: PMaxProductsStore

  // CONSTRUCTOR

  constructor(injections: {
    campaignStore: ProductCampaignStore
    productsStore: PMaxProductsStore
  }) {
    this.campaignStore = injections.campaignStore
    this.productsStore = injections.productsStore
  }

  // STATE
}

export default RevenueProductsStore
