import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import difference from "lodash/difference"

import { useStore } from "@store/index"
import { AssignedAttributes } from "@store/manageProfile/types"
import { AttributesForm } from "./AttributesForm"

const AttributesFormAdapter: React.FC = observer(() => {
  const { manageProfileStore, accountStore } = useStore()
  const {
    categories,
    attributes,
    assignedAttributes,
    setSubmitCallback,
    updateAttributesAssignment,
  } = manageProfileStore

  const { accountId } = accountStore

  const [value, setValue] = useState<AssignedAttributes>(
    new Set(assignedAttributes)
  )

  useEffect(() => {
    const initial = assignedAttributes
      ? Array.from(assignedAttributes.values())
      : []

    const updated = Array.from(value.values())

    const assigned: number[] = difference(updated, initial)
    const unassigned: number[] = difference(initial, updated)

    const hasChanges = assigned.length || unassigned.length

    if (accountId && hasChanges) {
      setSubmitCallback(() => {
        updateAttributesAssignment(accountId, { assigned, unassigned })
      })
    } else {
      setSubmitCallback()
    }
  }, [
    value.size,
    accountId,
    assignedAttributes,
    setSubmitCallback,
    updateAttributesAssignment,
  ])

  return (
    <AttributesForm
      selectedAttributes={assignedAttributes ?? undefined}
      categories={categories ?? undefined}
      attributes={attributes ?? undefined}
      onChange={setValue}
    />
  )
})

export default AttributesFormAdapter
