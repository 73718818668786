import React, { useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import { FormikProvider, useFormik } from "formik"
import { toJS } from "mobx"
import { diff } from "deep-object-diff"
import { useAlert } from "react-alert"

import capitalize from "lodash/capitalize"
import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Loader from "@components/ui/Loader/Loader"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { ID } from "@framework/types/types"
import { PermissionEntity, UserEntity } from "@framework/types/user"
import { useStore } from "@store/index"
import UserForm, { userFormValidationSchema } from "./UserForm"
import PermissionForm, {
  PermissionFormData,
  permissionFormValidationSchema,
} from "./PermissionForm"

import styles from "./EditMemberModal.module.sass"

export const validationSchema = yup.object({
  user: userFormValidationSchema,
  permission: permissionFormValidationSchema,
})

type EditFormType = yup.InferType<typeof validationSchema>

type EditMemberModalProps = {
  isOpen: boolean
  onClose: () => void
}

const EditMemberModal: React.FC<EditMemberModalProps> = observer(
  ({ isOpen, onClose }) => {
    const alert = useAlert()
    const {
      settings: { managementStore, userList, userPermissions },
    } = useStore()
    const { permission, user, isEdit } = managementStore

    const [isLoading, setLoading] = useState(false)

    const initialValues: EditFormType = useMemo(
      () => ({
        user: {
          ...userFormValidationSchema.getDefault(),
          ...userFormValidationSchema.cast(toJS(user), {
            stripUnknown: true,
          }),
        },
        permission: {
          ...permissionFormValidationSchema.getDefault(),
          ...permissionFormValidationSchema.cast(toJS(permission), {
            stripUnknown: true,
          }),
        },
      }),
      [permission, user]
    )

    const submitUser = async (form: EditFormType) => {
      const userData = userFormValidationSchema.cast(form.user, {
        stripUnknown: true,
      })
      const request: Partial<UserEntity> = {
        ...userData,
        // contactPhone: "17632736140",
        // clientIds: [],
        isDirector: form.permission.accessLevel === "director",
      }

      return userList.update(
        isEdit
          ? request // CREATE
          : diff(initialValues.user, request) // UPDATE
      )
    }

    const submitPermission = async (userId: ID, form: PermissionFormData) => {
      const permissionForm = permissionFormValidationSchema.cast(form, {
        stripUnknown: true,
      })
      const { objectId, accessLevel, ...rest } = permissionForm
      const request = {
        ...rest,
        accessLevel: accessLevel === "director" ? "write" : accessLevel,
        objectIds: objectId,
      } as PermissionEntity
      return userPermissions.update(userId, request)
    }

    const handleSubmit = async (form: EditFormType) => {
      try {
        setLoading(true)
        const userId = await submitUser(form)
        if (userId == null) {
          const errorMessage = isEdit
            ? "Failed to update user"
            : "Failed to create user"
          alert.error(
            <AlertMessage title={errorMessage} description="Unexpected error" />
          )
          return
        }
        const error = await submitPermission(userId, form.permission)
        if (error) {
          alert.error(capitalize(error))
          return
        }
        userList.load()
        onClose()
      } finally {
        setLoading(false)
      }
    }

    const formik = useFormik<EditFormType>({
      initialValues,
      validationSchema,
      onSubmit: handleSubmit,
    })

    const title = isEdit ? "Edit member" : "Add new member"
    const submitText = isEdit ? "Apply" : "Create"

    return (
      <Modal isOpen={isOpen} onClose={onClose} hideHeader>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <Stack gutter="24" align="stretch" className={styles.root}>
              <Typography type="h2">
                {title}
                <Loader isLoading={isLoading} />
              </Typography>

              <UserForm isEdit={isEdit} />

              <PermissionForm />

              {/* footer */}
              <Stack direction="row" justify="flex-end">
                <Button
                  color="secondary"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={isLoading}>
                  {submitText}
                </Button>
              </Stack>
            </Stack>
          </FormikProvider>
        </form>
      </Modal>
    )
  }
)

export default EditMemberModal
