import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { reportLevelDescription } from "@framework/constants/report"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import moment from "moment"
import { DEF_YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import { printMomentRange } from "@utils/date"
import { PeriodReport } from "@store/reports/types"
import { useTableContext } from "./TableContext"
import PlatformList from "./PlatformList"

type AccountRowProps = {
  data: PeriodReport
  mapper: MapperType[]
}

const PeriodRow: React.FC<AccountRowProps> = observer(
  ({ data: report, mapper }) => {
    const context = useTableContext()

    const renderSubtitle = () => {
      if (context.viewType === "Months") {
        const subtitle = moment(
          report.period,
          DEF_YYYY_MM_DD_DATE_FORMAT
        ).format("MMMM YYYY")
        return subtitle
      }

      if (context.viewType === "Weeks") {
        const startMoment = moment(report.period, DEF_YYYY_MM_DD_DATE_FORMAT)
        const endMoment = startMoment.clone().add(6, "days")
        return printMomentRange([startMoment, endMoment])
      }

      return ""
    }

    const getTipType = () => {
      if (context.viewType === "Months") return reportLevelDescription.month
      if (context.viewType === "Weeks") return reportLevelDescription.week
      return reportLevelDescription.unknown
    }

    return (
      <ClosableRow
        id={report.id}
        component={
          <TypedRow
            label={
              <Stack gutter="4">
                <Typography>{report.label}</Typography>
                <Typography type="caption2" color="black60Color">
                  {renderSubtitle()}
                </Typography>
              </Stack>
            }
            data={report}
            leftTip={<TypedTip data={getTipType()} />}
            mapper={mapper}
          />
        }
      >
        <PlatformList list={report.platformReportIds} mapper={mapper} />
      </ClosableRow>
    )
  }
)

export default PeriodRow
