import { useStore } from "@store/index"

export const useCampaignOptions = () => {
  const { adCopyStore } = useStore()

  return {
    options: adCopyStore.campaignsCollection.allCampaigns,
    renderValue: (campId: string) =>
      adCopyStore.campaignsCollection.campaignsMap.get(campId)?.name ??
      "Unknown",
  }
}

export default useCampaignOptions
