import * as yup from "yup"

import { PMaxCampaignEntity } from "@framework/types/account"
import {
  biddingAndBudgetValidationSchema,
  transformBiddingAndBudgetForm,
} from "./budget"
import {
  campaignSettingsValidationSchema,
  transformCampaignSettingsForm,
} from "./settings"
import { assetsValidationSchema } from "./assets"

export type FormStepName = "budget" | "settings" | "assets" | "summary"

export const summaryValidationSchema = yup
  .object()
  .concat(biddingAndBudgetValidationSchema)
  .concat(campaignSettingsValidationSchema)
  .concat(assetsValidationSchema)

export const pMaxFormValidators: Record<FormStepName, yup.BaseSchema> = {
  budget: biddingAndBudgetValidationSchema,
  settings: campaignSettingsValidationSchema,
  assets: assetsValidationSchema,
  summary: summaryValidationSchema,
}

export const transformCampaignDraftForm = (
  campaign: Partial<PMaxCampaignEntity>
) => ({
  ...transformBiddingAndBudgetForm(campaign),
  ...transformCampaignSettingsForm(campaign),
})
