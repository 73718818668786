import React, { useEffect } from "react"
import { observer, useLocalStore } from "mobx-react-lite"
import CollapsibleList from "./CollapsibleList"

export const CollapsibleContext = React.createContext<CollapsibleList | null>(
  null
)

export const CollapsibleContextProvider: React.FC<{
  eternal?: CollapsibleList
  singleActive?: boolean
  children?: React.ReactNode | ((context: CollapsibleList) => React.ReactNode)
}> = observer(({ eternal, singleActive = false, children }) => {
  const context = useLocalStore(
    () => eternal ?? new CollapsibleList({ singleActive })
  )

  useEffect(() => {
    context.setSingleActive(singleActive)
  }, [singleActive !== context.singleActive])

  return (
    <CollapsibleContext.Provider value={context}>
      {typeof children === "function" ? children(context) : children}
    </CollapsibleContext.Provider>
  )
})

export default CollapsibleContextProvider
