import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import Stack from "@components/ui/Stack/Stack"

import {
  dayOfWeekOptions,
  hoursOptions,
  minutesOptions,
  renderDay,
  renderHours,
  renderMinutes,
} from "@framework/constants/manageCampaign"
import styles from "./TimePeriodRow.module.scss"

export interface TimePeriodRowProps {
  name: string
  deletable?: boolean
  onDelete?: React.MouseEventHandler
}

const TimePeriodRow: React.FC<TimePeriodRowProps> = observer(
  ({ name, deletable, onDelete }) => (
    <div className={styles.root}>
      <FormSimpleSelectField
        name={`${name}.dayOfWeek`}
        className={styles.input}
        options={dayOfWeekOptions}
        renderValue={renderDay}
      />

      <Stack gutter="4" direction="row" align="center">
        <FormSimpleSelectField
          className={styles.shortInput}
          placeholder="00"
          name={`${name}.startHour`}
          options={hoursOptions}
          renderValue={renderHours}
          hideArrow
        />
        :
        <FormSimpleSelectField
          className={styles.shortInput}
          placeholder="00"
          name={`${name}.startMinute`}
          options={minutesOptions}
          renderValue={renderMinutes}
          hideArrow
        />
      </Stack>

      <Typography color="black40Color">to</Typography>

      <Stack gutter="4" direction="row" align="center">
        <FormSimpleSelectField
          className={styles.shortInput}
          placeholder="00"
          name={`${name}.endHour`}
          options={hoursOptions}
          renderValue={renderHours}
          hideArrow
        />
        :
        <FormSimpleSelectField
          className={styles.shortInput}
          placeholder="00"
          name={`${name}.endMinute`}
          options={minutesOptions}
          renderValue={renderMinutes}
          hideArrow
        />
      </Stack>

      {deletable && (
        <IconButton title="Delete" onClick={onDelete}>
          <Icon name="cross" />
        </IconButton>
      )}
    </div>
  )
)

export default TimePeriodRow
