/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import { channelOptions } from "@store/account-linking/constants"
import useOptionList from "@framework/prototypes/useOptionList"

import styles from "./SelectAccountChannelModal.module.scss"

type SelectAccountChannelModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit?: (item: string) => void
}

const SelectAccountChannelModal: React.FC<SelectAccountChannelModalProps> =
  observer(({ isOpen, onClose, onSubmit }) => {
    const [selected, setSelected] = useState<string>(channelOptions[0].value)

    const handleSubmit = () => {
      if (selected && onSubmit) onSubmit(selected)
    }

    const cannelOptions = useOptionList(channelOptions)

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={
          <Typography type="h2" weight="bold" color="primary">
            Select a channel
          </Typography>
        }
      >
        <div className={styles.container}>
          <div className={styles.body}>
            <SimpleSelect
              {...cannelOptions}
              value={selected}
              onSelect={setSelected}
            />
          </div>
          <div className={styles.footer}>
            <Button disabled={!selected} onClick={handleSubmit}>
              Select
            </Button>
          </div>
        </div>
      </Modal>
    )
  })

export default SelectAccountChannelModal
