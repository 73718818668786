import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { reportLevelDescription } from "@framework/constants/report"
import { ChannelReport } from "@store/reports/types"
import CampaignReportList from "./CampaignReportList"

type CampaignTypeRowProps = {
  data: ChannelReport
  mapper: MapperType[]
}

const CampaignTypeRow: React.FC<CampaignTypeRowProps> = observer(
  ({ data: report, mapper }) => (
    <ClosableRow
      id={report.id}
      component={
        <TypedRow
          label={report?.label ?? "Campaign type"}
          data={report}
          leftTip={<TypedTip data={reportLevelDescription.campaignType} />}
          mapper={mapper}
        />
      }
    >
      <CampaignReportList list={report.campaignReportIds} mapper={mapper} />
    </ClosableRow>
  )
)

export default CampaignTypeRow
