/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom"
import { observer } from "mobx-react-lite"

import Container from "@root/layouts/Container/Container"
import PrivateLayout, {
  NavBarProps,
} from "@root/layouts/PrivateLayout/PrivateLayout"
import { useStore } from "@store/index"
import { findDefaultNavItem } from "@components/ui/Tabs/utils"
import config from "@root/config"
import { NavOption } from "@components/ui/Tabs/types"
import Dashboard from "./AccountDashboard/Dashboard"
import { accountSidebarNavItems, useAccountNavItems } from "../navOptions"
import ManageProfile from "./ManageProfile/ManageProfile"
import ManageCampaign from "../ManageCampaign/ManageCampaign"
import LinkedAccounts from "./ManageProfile/components/LinkedAccounts/LinkedAccounts"
import Checkbook from "./Checkbook/Checkbook"
import ChannelList from "./ChannelList/ChannelList"

const AccountRoot: React.FC = observer(() => {
  const history = useHistory()
  const {
    clientStore: { client },
    accountStore: { loadAccount, setAccountId, account, accountId },
  } = useStore()

  const [tab, setTab] = useState<NavOption>()

  const { id } = useParams<any>()
  const { pathname, search } = useLocation<any>()

  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  )

  const filteredNavItems: NavOption[] = accountSidebarNavItems

  const accountNavItems = useAccountNavItems()

  useEffect(() => {
    if (!id || Number.isNaN(id)) history.push("./")
  }, [id, history])

  useEffect(() => {
    setAccountId(Number(id) || null)
    return () => {
      setAccountId(null)
    }
  }, [setAccountId, id])

  useEffect(() => {
    if (accountId) {
      loadAccount(accountId)
    }
  }, [accountId])

  useEffect(() => {
    setTab(findDefaultNavItem(accountNavItems, pathname, queryParams.alter))
  }, [accountNavItems, pathname, queryParams])

  const handleChangeTab = useCallback(
    (item: NavOption) => {
      history.push(
        item.exact ? item.value : `/account/${accountId}${item.value}`
      )
    },
    [history, accountId]
  )

  const allowedNavItems = useMemo((): NavOption[] => {
    if (!accountId) return []
    return accountNavItems.map((item) => {
      if (item.value === "/product-feed")
        return {
          ...item,
          value: `/shopping/${accountId}/product-feed`,
        }
      return item
    })
  }, [accountId, accountNavItems])

  const withNavBar = useMemo((): NavBarProps | undefined => {
    const confirmation = pathname.endsWith("/create-campaign")
    if (!confirmation) {
      return {
        options: allowedNavItems,
        title: account?.name ?? "",
        onSelect: handleChangeTab,
        currentTab: tab,
      }
    }
  }, [account?.name, allowedNavItems, handleChangeTab, pathname, tab])

  const sidebarNav = useMemo((): NavBarProps | undefined => {
    const confirmation = pathname.endsWith("/create-campaign")

    if (!confirmation) {
      return {
        options: filteredNavItems,
        title: account?.name ?? "",
        onSelect: handleChangeTab,
        currentTab: tab,
      }
    }
  }, [account, handleChangeTab, pathname, tab, filteredNavItems])

  const clientId = client?.id

  return (
    <PrivateLayout navBar={withNavBar} sideBar={sidebarNav} scope="account">
      <Container>
        <Switch>
          <Route path="/account/:id/dashboard" component={Dashboard} />

          <Route path="/account/:id/channels" component={ChannelList} />

          <Route
            path="/account/:id/manage-campaign"
            component={ManageCampaign}
          />

          <Route path="/account/:id/checkbook" component={Checkbook} />

          <Route path="/account/:id/manage-profile" component={ManageProfile} />

          {!config.HIDE_REPORTS && clientId != null && accountId != null && (
            <Route path="/account/:id/reports">
              <Redirect
                to={`/client/${clientId}/reports?account=${accountId}`}
              />
            </Route>
          )}

          <Route path="/account/link/redirect" component={LinkedAccounts} />

          <Route path="/account/:id/**">
            <Redirect to="./dashboard" />
          </Route>

          <Route path="/account/:id" exact>
            <Redirect to="/account" />
          </Route>
        </Switch>
      </Container>
    </PrivateLayout>
  )
})

export default AccountRoot
