import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { reportLevelDescription } from "@framework/constants/report"
import { AdSetReport } from "@store/reports/types"

type MetaAdSetRowProps = {
  data: AdSetReport
  mapper: MapperType[]
}

const MetaAdSetRow: React.FC<MetaAdSetRowProps> = observer(
  ({ data: report, mapper }) => (
    <ClosableRow
      id={report.id}
      component={
        <TypedRow
          label={report?.label ?? "Ad Set"}
          data={report}
          leftTip={<TypedTip data={reportLevelDescription.adSet} />}
          mapper={mapper}
        />
      }
    />
  )
)

export default MetaAdSetRow
