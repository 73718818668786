import React from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Option } from "@framework/types/types"
import OnHoverTooltip from "@components/ui/Tooltip/TooltipContainer/TooltipContainer"
import ComicBubble from "@components/ui/Tooltip/ComicBubble/ComicBubble"
import { useAdCopyAIWizardContext } from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/AdCopyTable/AdCopyAIWizardContext"
import CardItem from "../components/CardItem"
import { FormComponentProps } from "."
import FieldRow from "../components/FieldRow"
import {
  goalsValidationSchema as validationSchema,
  gaolOptions,
} from "./validation"

import styles from "./Goals.module.sass"

type FormData = yup.InferType<typeof validationSchema>

type GoalsProps = {} & FormComponentProps

const Goals: React.FC<GoalsProps> = observer(
  ({ headerSlot, controlSlot, onSubmit }) => {
    const wizardContext = useAdCopyAIWizardContext()

    const handleSubmit = (data: FormData) => {
      wizardContext.updateSettings(data)

      onSubmit()
    }

    const formik = useFormik<FormData>({
      initialValues: validationSchema.cast(wizardContext.settings ?? {}, {
        stripUnknown: true,
      }),
      validationSchema,
      onSubmit: handleSubmit,
    })

    const isSelected = (id: string) => formik.values.goal === id

    const handleSelect = (id: string) => {
      formik.setFieldValue("goal", id)
    }

    return (
      <FormikProvider value={formik}>
        <form className={styles.root} onSubmit={formik.handleSubmit}>
          {headerSlot}

          <div className={styles.body}>
            <FieldRow
              title="What’s your goal for this AdCopy?"
              subtitle="Select your desired goal for this AdCopy so we can tailor it to achieve the best results"
            >
              <div className={styles.list}>
                {gaolOptions.map((goalName) => {
                  const tooltipContent = tooltips[goalName]
                  const item = goalOptionMap[goalName]
                  return (
                    <CardItem
                      className={styles.card}
                      rounded
                      active={isSelected(item.value)}
                      onClick={() => handleSelect(item.value)}
                      key={item.value}
                    >
                      <Icon name={item.icon!} className={styles.icon} />

                      <Typography type="caption3">{item.label}</Typography>

                      {tooltipContent != null && (
                        <OnHoverTooltip
                          className={styles.info}
                          placement="bottom"
                          toolTip={(props) => (
                            <ComicBubble
                              justify="center"
                              className={styles.bubble}
                              {...props}
                            >
                              <Typography>{tooltipContent}</Typography>
                            </ComicBubble>
                          )}
                        >
                          <Typography type="h1" color="black30Color">
                            <Icon name="information-outline" />
                          </Typography>
                        </OnHoverTooltip>
                      )}
                    </CardItem>
                  )
                })}
              </div>
            </FieldRow>
          </div>

          {controlSlot}
        </form>
      </FormikProvider>
    )
  }
)

const tooltips: Record<string, React.ReactNode> = {
  "sell product": (
    <ul>
      <li>
        This goal helps you sell your product. It invites people to take action
        on your ad by buying directly.
      </li>
      <li>The default call to action for these ads is “Shop Now”</li>
    </ul>
  ),
  "sell service": (
    <ul>
      <li>
        This goal helps you sell your service. It invites people to take action
        on your ad by buying directly.
      </li>
      <li>The default call to action for these ads is “Shop Now”</li>
    </ul>
  ),
  "collect leads & sign ups": (
    <ul>
      <li>
        This goal create ads that help people sign up or register for your
        business on your website.
      </li>
      <li>The default call to action for these ads is “Sign Up”</li>
    </ul>
  ),
  "build brand awareness": (
    <ul>
      <li>This goal creates ads for people to get to know your brand.</li>
      <li>The default call to action for these ads is “Learn More”</li>
    </ul>
  ),
}

const goalOptionMap: Record<string, Option> = {
  "sell product": {
    value: "sell product",
    label: "Sell product",
    icon: "money-dollar-box",
  },
  "sell services": {
    value: "sell services",
    label: "Sell services",
    icon: "briefcase-3",
  },
  "collect leads & sign ups": {
    value: "collect leads & sign ups",
    label: "Collect Leads & Sign Ups",
    icon: "user-add",
  },
  "build brand awareness": {
    value: "build brand awareness",
    label: "Build brand awareness",
    icon: "vip-diamond",
  },
}

export default Goals
