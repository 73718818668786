import React from "react"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import StatusBadge, {
  BadgeOption,
} from "@pages/GoogleShoppingChannel/components/Status/StatusBadge"
import { AssetGroupEntity } from "@framework/types/manageCampaign"
import { statusToLabel } from "@utils/stringUtils"
import { ID } from "@framework/types/types"
import { useHistory } from "react-router-dom"

import styles from "./AssetCard.module.scss"

export const productStatusMapper = (status: string): BadgeOption => {
  const upperStatus = status.toUpperCase()

  if (upperStatus === "ENABLED")
    return {
      color: "success",
      label: upperStatus,
    }

  if (upperStatus === "PAUSED")
    return {
      color: "yellow",
      label: upperStatus,
    }

  return {
    color: "secondary",
    label: "UNSPECIFIED",
  }
}

const Footer: React.FC<{
  campaignId: ID
  data: AssetGroupEntity
}> = ({ campaignId, data }) => {
  const history = useHistory()
  // const editAssetLink = makeAssetEditingLink(campaignId, data.id)
  return (
    <Templates.Header
      offset="none"
      className={styles.footer}
      left={
        <Stack gutter="32" direction="row">
          <StatusBadge value={data.status} mapper={productStatusMapper} />

          <Typography type="caption2" color="black80Color">
            Ad strength: {statusToLabel(data.adStrength)}
          </Typography>
          <Typography type="caption2" color="black80Color">
            Status: Eligible
          </Typography>
        </Stack>
      }
      right={
        <>
          {/* <Button variant="ghost">Listing groups</Button> */}
          <Button
            variant="ghost"
            // onClick={() => window.open(editAssetLink, "_blank")}
            onClick={() => history.push(`?s=assets&assetGroupId=${data.id}`)}
          >
            Edit assets
          </Button>
        </>
      }
    />
  )
}

export default Footer
