import React from "react"
import clsx from "clsx"

import { ButtonColorType } from "../Button/types"

import styles from "./Chip.module.scss"

export type ChipProps = {
  color?: ButtonColorType
  className?: string
  type?: "contained" | "outlined"
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  title?: string
  rounded?: boolean
  disabled?: boolean
}

const Chip: React.FC<ChipProps> = ({
  type = "contained",
  title,
  className,
  color,
  startIcon,
  endIcon,
  disabled,
  rounded = false,
  children,
}) => (
  <div
    title={title}
    aria-disabled={disabled}
    className={clsx(
      styles.root,
      styles.color,
      styles[`type-${type}`],
      styles[`color-${color}`],
      { [styles.rounded]: rounded },
      className
    )}
  >
    {startIcon}
    <span className={styles.label}>{children}</span>
    {endIcon}
  </div>
)

export default Chip
