import React from "react"
import { observer } from "mobx-react-lite"

import TextField from "@components/ui/TextField/TextField"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import { useSearchContext } from "."

interface SearchInputProps {
  className?: string
}

export const SearchInput: React.FC<SearchInputProps> = observer(
  ({ className }) => {
    const context = useSearchContext()

    return (
      <TextField
        className={className}
        placeholder="Search"
        before={<Icon name="search" />}
        value={context.searchQuery}
        onChange={(e) => context.setSearchQuery(e.target.value)}
        after={
          context.searchQuery ? (
            <IconButton onClick={() => context.setSearchQuery("")}>
              <Icon name="cross" />
            </IconButton>
          ) : undefined
        }
      />
    )
  }
)

export default SearchInput
