import { nanoid } from "nanoid"
import { action, observable } from "mobx"

import { ID } from "@framework/types/types"
import { AdCopyDetailsData } from "@framework/types/adCopy"
import { DeepPartial } from "@framework/types/utils"
import { AdCopyTask } from "./edit-adCopy.store"

class EditAdCopyTask implements AdCopyTask {
  constructor(adCopyIds: ID[], type: "edit" | "add", withAI: boolean = false) {
    this.adCopyIds = adCopyIds
    this.type = type
    this.withAI = withAI
  }

  readonly id: string = nanoid()

  readonly type: "edit" | "add" = "add"

  readonly withAI: boolean

  readonly adCopyIds: ID[]

  @observable adCopyDetails: DeepPartial<AdCopyDetailsData>[] | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  @action setAdCopies = (data: DeepPartial<AdCopyDetailsData>[]) => {
    this.adCopyDetails = data
  }
}

export default EditAdCopyTask
