/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"

import BaseListItem from "@components/ui/DropDown/ListItem"
import Select from "@components/ui/DropDown"
import { Button } from "@components/ui/Button"

type InlineContextMenuProps = {
  value?: string
  options: string[]
  onChange?: (name: string) => void
}

const InlineContextMenu: React.FC<InlineContextMenuProps> = ({
  value = "Select",
  options,
  onChange,
}) => (
  <Select.DropDown
    placement="bottom-end"
    target={(active) => (
      <Button
        size="small"
        variant="ghost"
        color="primary"
        endIcon={{ name: "arrow-down", rotateAngle: active ? 180 : 0 }}
      >
        {value}
      </Button>
    )}
  >
    <Select.Box>
      {options.map((option) => (
        <BaseListItem onClick={() => onChange && onChange(option)} key={option}>
          {option}
        </BaseListItem>
      ))}
    </Select.Box>
  </Select.DropDown>
)

export default InlineContextMenu
