/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from "react"
import clsx from "clsx"
import { Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  ChartData,
  TimeScale,
  ChartOptions,
} from "chart.js"

import Loader from "@components/ui/Loader/Loader"
import { darkColors, NULL } from "@components/ui/Charts/utils"
import "chartjs-adapter-date-fns"

import styles from "./LineChart.module.scss"

ChartJS.register(
  LinearScale,
  TimeScale,
  CategoryScale,
  PointElement,
  LineElement
)

export interface DataSetType {
  /**
   * usually metric name (check config.ts file)
   */
  key: string
  yAxisID: string
  label: string
  data: number[]
  borderColor?: string
  backgroundColor?: string
}

export type LineDataType = {
  dashed?: boolean
  dataset: DataSetType[]
}

export type LineChartProps = {
  className?: string
  lineData: LineDataType[]
  labels: string[]
  colors?: string[]
  options?: ChartOptions<"line">
  isLoading?: boolean
}

const chartDataProvider = (
  labels: string[],
  data: LineDataType[],
  colors: string[]
): ChartData<"line"> => ({
  labels,
  datasets: data.flatMap<any>(({ dataset, dashed }) =>
    dataset.map((set, idx) => ({
      ...set,
      borderColor: set.borderColor ?? colors[idx],
      backgroundColor: dashed ? "#333333" : set.backgroundColor ?? colors[idx],
      tension: 0.5,
      borderWidth: 2,
      ...(dashed ? { borderDash: [3], borderDashOffset: 3 } : null),
    }))
  ),
})

const LineChart: React.FC<LineChartProps> = ({
  lineData: dataset,
  labels,
  options,
  colors = darkColors,
  isLoading = false,
  className,
}) => {
  const data: any = useMemo(
    () => chartDataProvider(labels, dataset, colors),
    [dataset, labels, colors]
  )

  return (
    <div className={clsx(styles.chartContainer, className)}>
      <Loader isLoading={isLoading} />
      <div className={styles.chart}>
        <Line data={data} options={options} height="100%" width={NULL} />
      </div>
    </div>
  )
}

export default LineChart
