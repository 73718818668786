import { observer } from "mobx-react-lite"
import React from "react"
import TextDiff from "@components/ui/TextDiff/TextDiff"

import Typography from "@components/ui/Typography/Typography"
import { snakeCaseToTitle } from "@components/utils/stringUtils"
import { mbNoData } from "@services/utils"
import { isFilterOptionData } from "@store/product-feed/utils"
import { ViewCellProps } from "../../types"

import styles from "./TableCell.module.scss"

export interface SelectViewCellProps extends ViewCellProps {}

export const SelectViewCell: React.FC<SelectViewCellProps> = observer(
  ({ name, data, changeData }) => {
    const dataRow = data[name]
    const oldValueLabel = isFilterOptionData(dataRow)
      ? snakeCaseToTitle(dataRow.control ?? "")
      : ""
    const newValueLabel = snakeCaseToTitle(
      (changeData?.update as any)?.[name] ?? ""
    )

    return (
      <Typography className={styles.defaultText}>
        {!newValueLabel ? (
          mbNoData(oldValueLabel, snakeCaseToTitle)
        ) : (
          <TextDiff
            by="words"
            oldText={oldValueLabel}
            newText={newValueLabel}
          />
        )}
      </Typography>
    )
  }
)

export default SelectViewCell
