import * as Yup from "yup"

export const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// const urlRegEx = /((https?):\/\/)?(www.)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export const oldUrlRegEx =
  /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const urlRegEx =
  /^((http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(^$))/i

/**
 * @deprecated
 */
export const validateEmail = (email: string) => {
  if (email) return emailRegEx.test(email.toLowerCase())
  return true
}

export const numberOrEmptyString = Yup.mixed().test(
  (value) => value === "" || Yup.string().isValidSync(value)
)

export const websiteURLValidator = Yup.string()
  .typeError("Incorrect field format")
  .matches(urlRegEx, "Enter correct url!")

export const accountProfileValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  postal: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  website: websiteURLValidator.clone().required("Required"),
  industryId: Yup.string().required("Required"),
  yearlyAdBudget: numberOrEmptyString,

  aov: numberOrEmptyString,
  conversionRate: numberOrEmptyString,
  resClick: numberOrEmptyString,
  roas: numberOrEmptyString,

  goals: Yup.array()
    .of(
      Yup.object({
        targetValue: Yup.number().required("Field shouldn't be empty"),
        type: Yup.string().required(),
      }).required()
    )
    // .min(1, "Must be set at least 1 goal")
    .default([]),
})

export type AccountProfileFormData = Yup.InferType<
  typeof accountProfileValidationSchema
>

export const emailValidator = Yup.string()
  .matches(emailRegEx, "Please input a valid Email")
  .trim()

export const firstNameValidator = Yup.string()
  .matches(/^([A-Za-z]{2,256})$/, {
    message: "Please enter a valid first name",
  })
  .trim()

export const lastNameValidator = Yup.string()
  .matches(/^([A-Za-z]{2,256})$/, {
    message: "Please enter a valid last name",
  })
  .trim()

export const passwordValidator = Yup.string()
  .matches(/(?=.*[0-9])/, "Must contain at least one digit")
  .matches(/(?=.*[A-Z])/, "Must contain at least one uppercase later")
  .matches(/(?=.*[a-z])/, "Must contain at least one lowercase later")
  .min(8, "Minimum 6 characters")
  .max(256, "Maximum 256 characters")
  .trim()
