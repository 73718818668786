import React from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"

import Stack from "@components/ui/Stack/Stack"
import FormTextField from "@framework/prototypes/FormTextField"
import Label from "@components/ui/Label/Label"

export const validationSchema = yup.object({
  name: yup.string().min(3).max(265).label("Account Name").default("").trim(),
  adAccountId: yup
    .string()
    .matches(/^([0-9]{1,200})$/, "Incorrect format")
    .label("Account ID")
    .default("")
    .trim(),
})

export type FacebookAccountCredentialsFormData = yup.InferType<
  typeof validationSchema
>

type FacebookAccountCredentialsFormProps = {}

const FacebookAccountCredentialsForm: React.FC<FacebookAccountCredentialsFormProps> =
  observer(() => (
    <Stack gutter="24" align="stretch">
      <Label label="Account Name">
        <FormTextField
          name="name"
          placeholder="Enter account name"
          autoComplete="firstName"
        />
      </Label>

      <Label label="Ads Account ID">
        <FormTextField
          name="adAccountId"
          placeholder="Enter ads account id"
          autoComplete="lastName"
        />
      </Label>
    </Stack>
  ))

export default FacebookAccountCredentialsForm
