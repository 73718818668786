import { observable, action, computed } from "mobx"
import moment from "moment"
import {
  YEAR_MONTH_KEY_FORMAT,
  YYYY_MM_DD_DATE_FORMAT,
} from "@framework/constants/moment-format"

import accountService from "@services/account.service"
import AccountStore from "../account/account"
import {
  transformAdAmount,
  transformCheckbookAmounts,
  transformCheckbookForm,
} from "./checkbook.transformer"
import CheckbookTimeline from "./CheckbookTimeline"

export type MonthlyAmountMap = Record<string, string>

export class CheckbookStore {
  // INJECTIONS

  @observable accountStore: AccountStore | null = null

  @observable timeline: CheckbookTimeline

  // STATE

  @observable campaignDate?: Date

  @observable planedAdSpendLoading: boolean = false

  @observable planedAdSpend: { [id: string]: string } | null = null

  @observable additionalAdSpend = 0

  @observable checkbookData: MonthlyAmountMap | null = null

  @observable checkbookLoading: boolean = false

  @observable loadingError: string | null = null

  @observable updateError: string | null = null

  @observable isActualMonthlyAmountLoading: boolean = false

  @observable actualMonthlyAmount: MonthlyAmountMap | null = null

  // CONSTRUCTOR

  constructor(accountStore: AccountStore) {
    this.accountStore = accountStore
    this.timeline = new CheckbookTimeline(this)
  }

  // COMPUTED

  @computed get initialPlanedAdSpend() {
    const currentYear = this.timeline.activeYear
    if (currentYear == null || this.planedAdSpend == null) return 0
    return Object.entries(this.planedAdSpend).reduce((acc, [month, value]) => {
      if (moment(month, YEAR_MONTH_KEY_FORMAT).year() !== currentYear)
        return acc
      return (!Number.isNaN(Number(value)) ? Number(value) : 0) + acc
    }, 0)
  }

  @computed get contractAdSpend(): number {
    const value = Number(this.accountStore?.account?.yearlyAdBudget)
    return value || 0
  }

  @computed get totalAdSpend(): number {
    return this.additionalAdSpend + this.initialPlanedAdSpend
  }

  @computed get campaignLaunchDate() {
    if (this.accountStore && this.accountStore.account) {
      const launchDate = this.accountStore?.account?.launchDate
      if (launchDate) return moment(launchDate, YYYY_MM_DD_DATE_FORMAT).toDate()
    }
    return null
  }

  @computed get currentCampaignDate() {
    return this.campaignLaunchDate
      ? this.campaignLaunchDate
      : moment().startOf("day").toDate()
  }

  @computed get actualAdSpend() {
    const currentYear = this.timeline.activeYear
    if (currentYear == null || this.actualMonthlyAmount == null) return 0
    return Object.entries(this.actualMonthlyAmount).reduce(
      (acc, [month, amount]) => {
        if (moment(month, YEAR_MONTH_KEY_FORMAT).year() !== currentYear)
          return acc
        return acc + Number.parseFloat(amount)
      },
      0
    )
  }

  @computed get remainAdSpend(): number {
    return this.contractAdSpend - this.actualAdSpend
  }

  @computed get initialMonthlyAdSpend() {
    return this.contractAdSpend / 12
  }

  // ACTIONS

  @action setCampaignDate = (date?: Date | Date[]) => {
    const newDate = Array.isArray(date) ? date[0] : date
    this.campaignDate = newDate
  }

  @action loadActualMonthlyAdSpend = async (
    accountId: number,
    start: string,
    end: string
  ) => {
    this.isActualMonthlyAmountLoading = true
    this.loadingError = null
    this.actualMonthlyAmount = null
    try {
      const response = await accountService
        .getPerformancePeriodReport(accountId, {
          from: start,
          to: end,
          periodicity: "monthly",
        })
        .then((response) => response.data.data)
      this.actualMonthlyAmount = response ? transformAdAmount(response) : null
    } catch (error) {
      this.loadingError = "Loading data failed"
      this.actualMonthlyAmount = null
    }
    this.isActualMonthlyAmountLoading = false
  }

  @action loadCheckbook = async (accountId: number) => {
    this.checkbookLoading = true
    this.loadingError = null
    try {
      const response = await accountService.getAccountCheckbook(accountId)
      const responseData = response.data.data
      if (responseData) {
        const monthlyAmounts = transformCheckbookAmounts(responseData)
        this.checkbookData = monthlyAmounts
      }
    } catch (error) {
      this.loadingError = "Loading data failed"
    } finally {
      this.checkbookLoading = false
    }
  }

  @action updateLaunchDate = async (accountId: number, launchDate: Date) => {
    if (
      !this.campaignLaunchDate ||
      (!isDateInAPast(launchDate) &&
        !areDatesEqual(this.currentCampaignDate, launchDate))
    ) {
      await this.accountStore?.updateAccountInfo(accountId, {
        LaunchDate: moment(launchDate).format(YYYY_MM_DD_DATE_FORMAT),
      })
    }
  }

  @action updateCheckbookData = async (
    accountId: number,
    data: MonthlyAmountMap
  ) => {
    const form = transformCheckbookForm(data)
    if (form.length > 0)
      await accountService.updateAccountCheckbook(accountId, form)
  }

  @action updateCheckbook = async (
    accountId: number,
    launchDate: Date,
    data: MonthlyAmountMap
  ) => {
    this.checkbookLoading = true
    this.updateError = null
    try {
      await this.updateLaunchDate(accountId, launchDate)
      await this.updateCheckbookData(accountId, data)
      this.checkbookData = { ...this.checkbookData, ...data }
      return true
    } catch (error) {
      this.updateError = "Can't update checkbook data"
    } finally {
      this.checkbookLoading = false
    }
    return false
  }

  @action setPlanedAdSpend = (values: MonthlyAmountMap) => {
    this.planedAdSpend = { ...values }
  }
}

export const isDateInAPast = (date: Date) => {
  const todayMoment = moment().startOf("day").unix()
  const testedMoment = moment(date).startOf("day").unix()
  return testedMoment < todayMoment
}

export const areDatesEqual = (date1: Date, date2: Date) =>
  moment(date1).format(YYYY_MM_DD_DATE_FORMAT) ===
  moment(date2).format(YYYY_MM_DD_DATE_FORMAT)

export default CheckbookStore
