/* eslint-disable no-useless-escape */
import _maxBy from "lodash/maxBy"
import _get from "lodash/get"
import moment from "moment"
import keyBy from "lodash/keyBy"

import {
  metricNamesList,
  MetricNameType,
  TimeSegmentedPerformanceReport,
  PerformanceReport,
} from "@framework/types/metrics"
import {
  DataSetType as BarChartDataSetType,
  DataSetType,
} from "@components/ui/Charts/BarChart/BarChart"
import { DataSetType as LineChartDataSetType } from "@components/ui/Charts/LineChart/LineChart"
import {
  AccountData,
  NamedAdGroupSegmentedPerformanceReport,
  PerformanceSegmentType,
} from "@framework/types/account"
import { CampaignKPIDataType } from "@pages/Account/AccountDashboard/components/CampaignPerformance/MockData"
import {
  CheckbookMonthlyAmount,
  PerformanceSegmentReportResponseData,
} from "@services/account.service"
import {
  DEF_YYYY_MM_DD_DATE_FORMAT,
  YEAR_MONTH_KEY_FORMAT,
} from "@framework/constants/moment-format"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import {
  extractTimeSegmentedPerformanceValues,
  periodicityToDateFormat,
} from "@framework/data-transformers/metrics"
import { getMetricDescriptor } from "@framework/constants/metrics"

export const transformValue = (value: number | string): number =>
  typeof value === "string" ? Number(value.replace(/([^0-9^\.])/g, "")) : value

const getEmptyMetric = (): PerformanceReport =>
  Object.fromEntries(
    metricNamesList.map((name) => [name, { Value: 0 }])
  ) as PerformanceReport

export const extractKPIData = (
  metrics: MetricNameType[],
  performances: PerformanceReport[],
  suffix: string = ""
): LineChartDataSetType[] =>
  metrics.map((metricName, idx) => {
    const metric = getMetricDescriptor(metricName)
    if (metric == null) throw new Error(`Metric ${metricName} is not supported`)
    return {
      key: metric.name,
      label: `${metric.label} ${suffix}`,
      yAxisID: `y${idx + 1}`,
      data: extractTimeSegmentedPerformanceValues(metric.name, performances),
    }
  })

export const normalizePrevYearMetrics = (
  currYearLabels: string[],
  metrics: TimeSegmentedPerformanceReport[],
  periodicity: PerformanceReportPeriodicity
): TimeSegmentedPerformanceReport[] => {
  const keyFormat = periodicityToDateFormat(periodicity)
  const metricsMap = keyBy(metrics, (it) =>
    moment(it.Period, DEF_YYYY_MM_DD_DATE_FORMAT)
      .add(1, "year")
      .format(keyFormat)
  )
  return currYearLabels.map((label) => {
    const key = moment(label, DEF_YYYY_MM_DD_DATE_FORMAT).format(keyFormat)
    return metricsMap[key] ?? { Period: label, Metrics: getEmptyMetric() }
  })
}

export const findTrend = (
  currentValue: number,
  previousValue: number
): number => {
  const delta = currentValue - previousValue
  const max = currentValue > previousValue ? currentValue : previousValue
  const percent = (delta / max) * 100
  return percent
}

export const extractMonthlyAdSpend = (
  performanceList: TimeSegmentedPerformanceReport[],
  date: Date
): BarChartDataSetType => ({
  label: moment.utc(date).format("YYYY"),
  data: performanceList.reduce((acc, metricData) => {
    const month = moment(metricData.Period, DEF_YYYY_MM_DD_DATE_FORMAT).month()
    acc[month] = metricData.Metrics?.Cost?.Value ?? 0
    return acc
  }, new Array(12).fill(0)),
})

const segmentMapper: PerformanceSegmentType[] = [
  "Awareness",
  "Consideration",
  "Conversion",
  "Other",
]

export const transformSegmentedReport = (
  report: PerformanceSegmentReportResponseData
): NamedAdGroupSegmentedPerformanceReport[] =>
  segmentMapper
    .filter((segName) => !!report[segName])
    .map((segName) => ({ ...report[segName], Name: segName }))

export const transformAccountToCampaignKPI = (
  metric: "ctrGoal" | "resClickGoal" | "roasGoal",
  account: AccountData
): DataSetType => ({
  label: "Goal",
  data: [account[metric] ? Number.parseFloat(account[metric]) : 0],
})

export const transformPerformanceToCampaignKPI = (
  metric: "Ctr" | "ResClickRate" | "Roas",
  performance?: PerformanceReport
): DataSetType => {
  const mul = metric === "Ctr" || metric === "ResClickRate" ? 100 : 1
  const value = performance?.[metric]?.Value ?? 0
  return {
    label: "Current",
    data: [value * mul],
  }
}

export const transformCampaignKPIData = (
  account: AccountData,
  performance: NamedAdGroupSegmentedPerformanceReport[]
): CampaignKPIDataType => ({
  ctr: [
    transformPerformanceToCampaignKPI(
      "Ctr",
      performance.find((item) => item.Name === "Awareness")
    ),
    transformAccountToCampaignKPI("ctrGoal", account),
  ],
  clicks: [
    transformPerformanceToCampaignKPI(
      "ResClickRate",
      performance.find((item) => item.Name === "Consideration")
    ),
    transformAccountToCampaignKPI("resClickGoal", account),
  ],
  roas: [
    transformPerformanceToCampaignKPI(
      "Roas",
      performance.find((item) => item.Name === "Conversion")
    ),
    transformAccountToCampaignKPI("roasGoal", account),
  ],
})

// Impressions, Clicks, CTR.%, Revenue, ROAS
export const InsightsMetricsList: MetricNameType[] = [
  "Impressions",
  "Clicks",
  "Ctr",
  "Revenue",
  "Roas",
]

export const findMonthlyCheckbookAmount = (
  data: CheckbookMonthlyAmount[],
  date: Date
) => {
  if (data.length === 0) return null
  const compareDateUnix = moment(date).format(YEAR_MONTH_KEY_FORMAT)
  const item = data.find(({ Month, Amount }) => {
    const monthNumber = moment.utc(Month).format(YEAR_MONTH_KEY_FORMAT)
    return monthNumber === compareDateUnix
  })
  return item?.Amount ?? null
}
