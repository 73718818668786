import React from "react"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store/index"
import { Option } from "@framework/types/types"
import NoData from "@components/ui/NoData/NoData"
import { SelectedCampaignsContext } from "@framework/prototypes/SelectedCampaignsContext"
import Menu from "./Menu"
import TopKeywordsTable from "./TopKeywordsTable"
import CampaignsTable from "./CampaignsTable"
import CampaignInsightsTemplate from "./CampaignInsightsTemplate"
import RevenueProductsTable from "./RevenueProductsTable"
import TopQueriesTable from "./TopQuesriesTable"

const CampaignInsights: React.FC = observer(() => {
  const {
    accountStore: { account, accountId },
    accountDashboardStore: {
      campaignInsightsStore: { topCampaignStore },
      dateRange,
    },
  } = useStore()

  const { accountDashboardController: controller } = useController()

  const menuOptions = React.useMemo(() => {
    const res: Option[] = []

    res.push({ label: "Top Campaigns", value: "top-campaigns" })

    res.push({ label: "Top Keywords", value: "top-keywords" })

    if (account?.ReportShoppingCampaigns)
      res.push({
        label: "Top Products",
        value: "revenue-products",
      })

    res.push({ label: "Top Search Queries", value: "top-queries" })

    return res
  }, [account])

  const [tab, setTab] = React.useState(menuOptions[0].value)

  const [selectedCampaigns, setCampaigns] = React.useState<string[]>([])

  const campaigns = topCampaignStore.data

  React.useEffect(() => {
    if (accountId == null) return
    controller.loadTopCampaignList(accountId, dateRange)
  }, [accountId, dateRange])

  React.useEffect(() => {
    setCampaigns(campaigns?.map((it) => it.Id.toString()) ?? [])
  }, [campaigns])

  const campaignContext = React.useMemo(
    () => ({ selectedCampaigns, setCampaigns }),
    [selectedCampaigns, setCampaigns]
  )

  if (!campaigns?.length)
    return (
      <CampaignInsightsTemplate loading={topCampaignStore.isLoading}>
        {!topCampaignStore.isLoading ? <NoData /> : null}
      </CampaignInsightsTemplate>
    )

  const menuNode = <Menu value={tab} options={menuOptions} onChange={setTab} />

  return (
    <SelectedCampaignsContext.Provider value={campaignContext}>
      {tab === "top-campaigns" && <CampaignsTable sidebarSlot={menuNode} />}

      {tab === "top-keywords" && <TopKeywordsTable sidebarSlot={menuNode} />}

      {tab === "revenue-products" && (
        <RevenueProductsTable sidebarSlot={menuNode} />
      )}

      {tab === "top-queries" && <TopQueriesTable sidebarSlot={menuNode} />}
    </SelectedCampaignsContext.Provider>
  )
})

export default CampaignInsights
