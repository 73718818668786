import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Templates from "@components/ui/Templates"
import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import { useKeywordInsightsContext } from "@pages/Opportunities/KeywordInsightsContext"
import { mbNoData } from "@services/utils"
import { getMetricDescriptor } from "@framework/constants/metrics"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import useSortedList from "@framework/hooks/useSortedList"
import HeaderColumn from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/AdCopyTable/HeaderColumn"
import { numberWithCommas } from "@utils/numberUtils"
import { useStore } from "@store/index"
import { formatCompetition } from "../RecommendedKeywords/Table/utils"

import styles from "./TopKeywordsTable.module.scss"

const cpcDesc = getMetricDescriptor("AverageCpc")

export const TopKeywordsTable: React.FC<{ className?: string }> = observer(
  ({ className }) => {
    const {
      accountStore: { accountId },
    } = useStore()
    const { store, controller } = useKeywordInsightsContext()

    const { data, isLoading } = store.topKeywordsStore

    React.useEffect(() => {
      if (accountId) controller.loadTopKeywords(accountId, store.keyword.id)
    }, [accountId])

    const ordered = useSortedList({ items: data })

    return (
      <Box className={clsx(styles.root, className)}>
        <Templates.Header
          offset="none"
          height="big"
          left={
            <Typography type="h3" weight="bold">
              Top Keywords
            </Typography>
          }
        />

        <Typography type="body1" className={styles.body}>
          {ordered.items.length === 0 ? (
            isLoading ? (
              <Loader isLoading />
            ) : (
              <NoData>No keywords found</NoData>
            )
          ) : (
            <Table hideBorder hideHorizontalOffset fixedLayout spacing="small">
              <Row className={styles.tableHeader}>
                {tableMapper.map((it) => (
                  <Column variant="header" width={it.width} key={it.name}>
                    <Typography type="caption3" color="black60Color">
                      <HeaderColumn
                        name={it.name}
                        value={ordered.orderBy}
                        onSort={ordered.sort}
                      >
                        {it.label}
                      </HeaderColumn>
                    </Typography>
                  </Column>
                ))}
              </Row>

              <tbody>
                {ordered.items.map((it) => (
                  <Row key={it.id}>
                    <Column>{mbNoData(it.keyword)}</Column>
                    <Column align="center">
                      <Typography type="inherit" weight="semibold">
                        {mbNoData(it.volume, numberWithCommas)}
                      </Typography>
                    </Column>
                    <Column>
                      <Typography type="inherit" weight="semibold">
                        {mbNoData(it.cpc, cpcDesc.formatter)}
                      </Typography>
                    </Column>
                    <Column align="center">
                      {formatCompetition(it.competition)}
                    </Column>
                  </Row>
                ))}
              </tbody>
            </Table>
          )}
        </Typography>
      </Box>
    )
  }
)

export default TopKeywordsTable

const tableMapper = [
  {
    name: "keywords",
    label: "Keywords",
  },
  {
    name: "searchVolume",
    label: "Search Vol.",
    width: "100px",
  },
  {
    name: "cpc",
    label: "CPC",
    width: "60px",
  },
  {
    name: "competition",
    label: "Competition",
    width: "100px",
  },
]
