import React from "react"

import Templates from "../Templates"
import Counter from "./Counter"
import { PaginatorRowProps } from "./usePagination"
import Pagination from "./index"

export interface PaginationRowProps extends PaginatorRowProps {
  disabled?: boolean
}

export const PaginationRow: React.FC<PaginationRowProps> = (props) => {
  const { options, controllerProps, disabled } = props
  return (
    <Templates.Header
      offset="none"
      left={<Pagination {...controllerProps} disabled={disabled} />}
      right={<Counter {...options} />}
    />
  )
}

export default React.memo(PaginationRow)
