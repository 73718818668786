import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { getManageCampaignOption } from "@framework/constants/manageCampaign"
import { useController, useStore } from "@store/index"
import { useAlert } from "react-alert"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import UpdateMark from "../../UpdateMark"

import styles from "./CampaignsTable.module.scss"

export type CampaignRowProps = {
  id: string
  index: number
}

export const CampaignRow: React.FC<CampaignRowProps> = observer(
  ({ id, index, children }) => {
    const alert = useAlert()
    const { adCopyManagement } = useController()
    const { adCopyStore } = useStore()

    const isSelected = adCopyStore.activeAdCopies.isAnyAdGroupSelected(id)
    const isPartial = !adCopyStore.activeAdCopies.isAllAdGroupsSelected(id)

    const data = adCopyStore.getCampaignByID(id)

    const toggle = async () => {
      const error = await adCopyManagement.selectCampaign(id, !isSelected)
      if (error)
        alert.info(
          <AlertMessage title="Action was canceled" description={error} />
        )
    }

    return (
      <span className={styles.checkedRowContainer}>
        <CheckBox
          className={styles.checkbox}
          checked={isSelected}
          partial={isPartial}
          onChange={toggle}
        />
        <ClosableRow
          id={index}
          component={
            <TypedRow
              active={isSelected}
              label={data?.name ?? "N/A"}
              leftTip={<TypedTip data={getManageCampaignOption("campaign")} />}
              data={data}
              mapper={rowMapper}
              onClick={toggle}
            />
          }
        >
          {children}
        </ClosableRow>
      </span>
    )
  }
)

export default CampaignRow

const rowMapper = [
  {
    name: "status",
    label: "",
    renderCallback: (value: string | undefined) => (
      <UpdateMark hide={value !== "updated"} />
    ),
  },
]
