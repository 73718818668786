import { action, computed, observable } from "mobx"

import { ID } from "@framework/types/types"
import LocationsStore from "./locations.store"

export class LocationsSuggestionsStore {
  @observable locationsStore: LocationsStore

  constructor(config: { locationsStore: LocationsStore }) {
    this.locationsStore = config.locationsStore
  }

  @observable isLoading: boolean = false

  @observable error: string | null = null

  @observable data: ID[] = []

  @action storeData = (data: ID[]) => {
    this.data = data
  }
}

export default LocationsSuggestionsStore
