import React from "react"

import Image from "@components/ui/Image/Image"

import { ViewCellProps } from "../types"
import ReportTitle from "../../ReportTitle"

import styles from "./TableCell.module.scss"

export const LabelCell: React.FC<ViewCellProps> = React.memo(
  ({ name, data }) => {
    const value = data[name]
    return (
      <div className={styles.labelCell}>
        <div className={styles.previewImageContainer}>
          <Image
            className={styles.preview}
            src={data.previewUrl}
            alt="Preview"
          />
        </div>

        <ReportTitle textClassName={styles.link}>{value}</ReportTitle>
      </div>
    )
  }
)

export default LabelCell
