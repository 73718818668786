import clsx from "clsx"
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import throttle from "lodash/throttle"
import ReactDOM from "react-dom"
import useToggle from "@framework/hooks/useToggle"

import {
  ITypographyProps,
  Typography,
} from "@components/ui/Typography/Typography"

import styles from "./Tooltip.module.scss"

export interface TooltipProps extends ITypographyProps {
  text?: ReactNode
  children: ReactNode
  containerClassName?: string
  wrapperClassName?: string
  lineClamp?: number
  delay?: number
  overflowWidth?: string | number
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  containerClassName,
  wrapperClassName,
  text = children,
  lineClamp = 1,
  delay = 600,
  overflowWidth = 0,
  ...rest
}: TooltipProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { isOpened: active, setOpened: setActive } = useToggle()
  const { isOpened: shouldShow, setOpened: setShouldShow } = useToggle()
  const [overlayStyles, setOverlayStyles] = useState<any>(undefined)

  const handleEnter: React.MouseEventHandler<HTMLDivElement> =
    useCallback(() => {
      const node = wrapperRef?.current
      if (!node) return
      const canShow =
        !!(
          (node.scrollHeight ?? 0) > (node.clientHeight ?? 0) ||
          (node.scrollWidth ?? 0) > (node.clientWidth ?? 0)
        ) && !!text
      setShouldShow(canShow)

      if (canShow) {
        const rect = rootRef.current?.getBoundingClientRect()

        setOverlayStyles({
          top: `${rect?.y ?? 0}px`,
          left: `${rect?.x ?? 0}px`,
          width:
            rect?.width && !overflowWidth ? `${rect?.width}px` : overflowWidth,
        })
      }
    }, [wrapperRef, setShouldShow, text, overflowWidth])

  useEffect(() => {
    if (shouldShow) {
      if (delay === 0) {
        return setActive(true)
      }
      const displayTimer = setTimeout(() => setActive(true), delay)
      return () => clearTimeout(displayTimer)
    }
    return setActive(false)
  }, [shouldShow, delay, setActive])

  const handleMove = useMemo(() => {
    if (active) {
      const handleLeave: React.MouseEventHandler<HTMLDivElement> = (e) => {
        const { path } = e as any
        if (
          rootRef.current &&
          path?.length &&
          !path.find((el: HTMLElement) => el?.isSameNode?.(rootRef.current))
        ) {
          setShouldShow(false)
        }
      }

      return throttle(handleLeave, 300)
    }
    return undefined
  }, [active, setShouldShow])

  return (
    <div
      className={clsx(
        styles.root,
        { [styles.active]: active },
        containerClassName
      )}
      onMouseEnter={handleEnter}
      onMouseLeave={() => setShouldShow(false)}
      onMouseMove={handleMove}
      ref={rootRef}
    >
      <div
        ref={wrapperRef}
        className={clsx(styles.wrapper, wrapperClassName)}
        style={{ WebkitLineClamp: lineClamp }}
      >
        {children}
      </div>

      {active &&
        ReactDOM.createPortal(
          <div className={styles.overlay} style={overlayStyles}>
            <Typography {...rest}>{text}</Typography>
          </div>,
          document.body
        )}
    </div>
  )
}

export default Tooltip
