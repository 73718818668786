import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import Templates from "@components/ui/Templates"
import Loader from "@components/ui/Loader/Loader"

import styles from "./CampaignInsightsTemplate.module.scss"

interface CampaignInsightsTemplateProps {
  loading?: boolean
  controlSlot?: React.ReactNode
}

const CampaignInsightsTemplate: React.FC<CampaignInsightsTemplateProps> =
  observer(({ loading = false, children, controlSlot }) => (
    <Box className={styles.root}>
      <Templates.Header
        className={styles.header}
        offset="none"
        left={
          <Typography type="h2" weight="bold">
            Campaign Insights and Context
          </Typography>
        }
        right={controlSlot}
      />

      <div className={styles.header}>
        <Loader isLoading={loading} height="medium" />
      </div>

      <div className={styles.content}>{children}</div>
    </Box>
  ))

export default CampaignInsightsTemplate
