import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Modal from "@components/ui/Modal/Modal"
import { generateCampaignReport } from "@pages/MetaAdsChannel/mock"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import IconButton from "@components/ui/Button/IconButton"
import StatusBadge from "../StatusBadge"
import ReportTitle from "../ReportTitle"
import KPITrendChart from "./KPITrendChart"

import styles from "./KPITrendModal.module.sass"

interface KPITrendModalProps {
  open: boolean
  onClose: () => void
}

export const KPITrendModal: React.FC<KPITrendModalProps> = observer(
  ({ open, onClose }) => (
    // TODO connect store

    <Modal
      className={clsx(styles.root)}
      isOpen={open}
      onClose={onClose}
      hideHeader
    >
      <div className={styles.header}>
        <Templates.TripleHeader
          left={
            <div className={styles.title}>
              <ReportTitle>{reportData.name}</ReportTitle>
              <StatusBadge status={reportData.status} short />
            </div>
          }
          right={
            <>
              <IconButton
                variant="contained"
                color="secondary"
                onClick={onClose}
              >
                <Icon name="cross" />
              </IconButton>
            </>
          }
        />
      </div>

      <div className={clsx(styles.body)}>
        <KPITrendChart />
      </div>
    </Modal>
  )
)

export default KPITrendModal

const reportData = generateCampaignReport(999)
