/* eslint-disable react/no-array-index-key */
import React from "react"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import Templates from "@components/ui/Templates"
import { Button } from "@components/ui/Button"
import Stack from "@components/ui/Stack/Stack"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import GoalWidgetCard from "./GoalWidgetCard"

import styles from "./MonthlyGoals.module.scss"

const MonthlyGoals: React.FC = observer(() => {
  const history = useHistory()
  const {
    accountStore,
    accountDashboardStore: { goals: store },
  } = useStore()
  const { accountId } = accountStore

  React.useEffect(() => {
    if (accountId) store.loadGoalsMonthlyPerformance(accountId)
  }, [accountId])

  const handleChange = () =>
    history.push(`/account/${accountId}/manage-profile`, { reason: "EDIT" })

  const goalsCount = store.settings?.length ?? 0
  const isInline = goalsCount < 3

  return (
    <Box className={styles.root}>
      <Templates.Header
        left={
          <Typography type="h2" weight="bold">
            Monthly Goals
          </Typography>
        }
        right={
          goalsCount > 0 && (
            <Button
              onClick={handleChange}
              variant="ghost"
              color="primary"
              size="small"
            >
              Change Monthly Goals
            </Button>
          )
        }
      />

      {store.settings != null && store.performance != null ? (
        store.settings.length ? (
          <Stack direction="row" gutter="16">
            {store.settings.map((goal) => {
              const desc = getMetricDescriptor(goal.type)

              if (desc == null) return null

              const metricData =
                store.performance?.[goal.type as MetricNameType]

              return (
                <GoalWidgetCard
                  goalValue={goal.targetValue}
                  actualValue={metricData?.Value ?? 0}
                  delta={metricData?.Change ?? 0}
                  metric={desc}
                  inline={isInline}
                  className={styles.card}
                  key={goal.type}
                />
              )
            })}
          </Stack>
        ) : (
          <Stack>
            <Button onClick={handleChange} variant="outlined">
              Add monthly goals
            </Button>
          </Stack>
        )
      ) : store.isLoading ? (
        <Loader />
      ) : (
        <NoData />
      )}
    </Box>
  )
})

export default MonthlyGoals
