import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import { numberWithCommas } from "@utils/numberUtils"
import { Button } from "@components/ui/Button"
import useEventListener from "@framework/hooks/useEventListener"
import { SECOND } from "@framework/constants/time"
import ProgressAnimation from "./ProgressAnimation"
import { useDemoMultiformContext } from "./tasks/FormManager"

import styles from "./ProgressSubForm.module.scss"

const APPROXIMATE_TASK_DURATION = 34 * SECOND

const KeywordScrappingSubForm: React.FC = observer(() => {
  const store = useDemoMultiformContext()
  const task = store.keywordScrappingTask!

  const progress = task.isCompleted
    ? 0
    : ((Date.now() - task.startedAt) / APPROXIMATE_TASK_DURATION) * 100

  useEventListener({
    type: "keypress",
    listener: (e) => {
      if (e instanceof KeyboardEvent && e.key.toLowerCase() === "q")
        task.skipTask()
    },
  })

  const onComplete = () => {
    store.startKeywordsClustering()
    store.nextStep()
  }

  return task.isCompleted ? (
    <div className={styles.root}>
      <ProgressAnimation value={100} />

      <Typography className={styles.title} type="h2">
        Total keywords found
      </Typography>
      <Typography className={styles.subtitle} type="h1" weight="bold">
        {numberWithCommas(task.totalKeywords)}
      </Typography>

      <Button className={styles.control} color="primary" onClick={onComplete}>
        Start Keyword Clustering
      </Button>
    </div>
  ) : (
    <div className={styles.root}>
      <ProgressAnimation value={progress} />

      <Typography type="h2">{task.status.label}...</Typography>
    </div>
  )
})

export default KeywordScrappingSubForm
