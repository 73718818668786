import { observable, autorun } from "mobx"
import OptionsFilter from "@framework/classes/OptionsFilter"
import { KeywordCategoryGroup } from "@framework/types/opportunities"
import { Option } from "@framework/types/types"
import OpportunitiesStore from "./opportunities"

const categoriesToOptions = (categories: KeywordCategoryGroup[]): Option[] =>
  categories.map((item) => ({
    value: item.id.toString(),
    label: item.displayName,
  }))

export class FilterByCategoriesStore extends OptionsFilter {
  @observable opportunitiesStore: OpportunitiesStore

  constructor(opportunitiesStore: OpportunitiesStore) {
    super()
    this.opportunitiesStore = opportunitiesStore

    autorun(() => {
      this.setOptions(
        categoriesToOptions(this.opportunitiesStore?.categoryGroups)
      )
    })
  }
}

export default FilterByCategoriesStore
