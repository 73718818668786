import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import { useStore } from "@store/index"
import { useAlert } from "react-alert"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { ID } from "@framework/types/types"
import Loader from "@components/ui/Loader/Loader"
import accountCampaignService from "@services/account-campaign.service"
import { TabNames } from "../../CreateCampaign"

import styles from "./SuccessfulCreated.module.scss"

type SuccessfulCreatedProps = {
  switchTab: (tabName: TabNames) => void
}

const SuccessfulCreated: React.FC<SuccessfulCreatedProps> = observer(
  ({ switchTab }) => {
    const alert = useAlert()
    const { accountStore, createdCampaign } = useStore()
    const { account, accountId } = accountStore

    const handleSubmit = async (accountId: ID) => {
      if (!accountId) return

      const error = await createdCampaign.createCampaigns(accountId)

      if (error == null) {
        switchTab("success")
        return
      }

      alert.error(
        <AlertMessage title="Failed to create campaigns" description={error} />
      )
    }

    const handleImplement = () => {
      if (account?.adAccountId != null) handleSubmit(account.id)
      else switchTab("link")
    }

    const downloadPackageLink = useMemo(
      () =>
        accountId
          ? accountCampaignService.getCampaignPackageLink(accountId)
          : "",
      [accountId]
    )

    const isLoading =
      accountStore.isLoading ||
      accountStore.account == null ||
      createdCampaign.isLoading

    return (
      <div className={styles.root}>
        <Typography type="h2" color="primary" weight="bold">
          {createdCampaign.isLoading
            ? "Google Ads Campaigns are being created"
            : "Google Ads  Campaigns are successfully built"}
        </Typography>

        <Loader isLoading={isLoading} />

        <div className={styles.buttonsWrapper}>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={() => handleImplement()}
          >
            Implement Campaign
          </Button>
          <Button
            variant="outlined"
            color="primary"
            href={downloadPackageLink}
            as="a"
          >
            Download Campaign Package
          </Button>
        </div>
      </div>
    )
  }
)

export default SuccessfulCreated
