import { observable, action } from "mobx"

import RootStore from "@store/RootStore"
import { ID } from "@framework/types/types"

export type EditAdCopyTaskType =
  | "delete"
  | "duplicate"
  | "edit"
  | "restore"
  | "add"

export interface AdCopyTask {
  readonly type: EditAdCopyTaskType

  readonly adCopyIds: ID[]

  readonly id: string
}

export class EditAdCopyStore {
  root: RootStore

  constructor(root: RootStore) {
    this.root = root
  }

  @observable task: AdCopyTask | null = null

  @action setAdCopyTask = (task: AdCopyTask) => {
    this.task = task
  }

  @action closeTask = () => {
    this.task = null
  }
}

export default EditAdCopyStore
