import { action, computed, observable } from "mobx"
import moment from "moment"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import accountService from "@services/account.service"
import { periodLength } from "@utils/date"
import { adjustToPeriod, reportTotMap } from "./dataTransformers"

class SearchReportStore {
  @observable monthlySearchReport: Record<string, number> | null = null

  @observable period: [Date, Date] | null = null

  @observable isLoading: boolean = false

  // constructor

  // computed

  @computed get loadedMonths() {
    if (!this.monthlySearchReport) return 0
    return Object.values(this.monthlySearchReport).length
  }

  @computed get totalMonths() {
    if (this.period == null) return 0
    return periodLength(this.period, "month")
  }

  // actions

  @action loadMonthlySearchReport = async (
    accountId: number,
    from: Date,
    to: Date
  ) => {
    try {
      this.isLoading = true
      this.period = [from, to]

      const { status, data: payload } =
        await accountService.getMonthlySearchReport(accountId, {
          from: moment(from).format(YYYY_MM_DD_DATE_FORMAT),
          to: moment(to).format(YYYY_MM_DD_DATE_FORMAT),
        })
      if (status === 200) {
        this.monthlySearchReport = adjustToPeriod(
          reportTotMap(payload.data),
          from,
          to
        )
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}

export default SearchReportStore
