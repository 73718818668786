import React from "react"

import Icon from "@components/ui/Icon/Icon"
import { Option } from "@framework/types/types"
import Select from "@components/ui/DropDown"
import NoData from "@components/ui/NoData/NoData"

import styles from "./EditHeaderDropDown.module.scss"

export const EditHeaderDropDown: React.FC<{
  value: string
  options: Option[]
  onSelect: (name: string) => void
}> = ({ value, options, onSelect }) => (
  <Select.DropDown
    placement="bottom-start"
    className={styles.icon}
    target={() => (
      <Icon
        name="pen"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      />
    )}
  >
    <Select.Box className={styles.optionsContainer}>
      {options.length ? (
        options.map((it) => (
          <Select.Option
            onClick={() => onSelect(it.value)}
            isSelected={it.value === value}
          >
            {it.label}
          </Select.Option>
        ))
      ) : (
        <NoData>No options to select</NoData>
      )}
    </Select.Box>
  </Select.DropDown>
)

export default EditHeaderDropDown
