import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"

import styles from "./CustomCampaignsTable.module.scss"

export type AdGroupRowProps = {
  id: string
  label: string
  index: number
  isSelected?: boolean
  onChange?: (id: string) => void
}

export const AdGroupRow: React.FC<AdGroupRowProps> = observer(
  ({ id, label, index, isSelected = false, onChange }) => (
    <span className={styles.checkedRowContainer}>
      <CheckBox
        className={styles.checkbox}
        checked={isSelected}
        onChange={onChange ? () => onChange(id) : undefined}
      />
      <ClosableRow
        id={index}
        component={
          <TypedRow
            label={label}
            leftTip={<TypedTip data={campaignEntityDescription.groupAd} />}
          />
        }
      />
    </span>
  )
)

export default AdGroupRow
