import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import ConfirmModal from "@components/modals/ConfirmModal/ConfirmModal"
import { useStore } from "@store/index"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { AdCopyTask } from "@store/manage-campaigns/edit-adCopy.store"

type DeleteAdCopyModalProps = {
  isOpened: boolean
  task: AdCopyTask
  onClose: () => void
}

const DeleteAndPublishAdCopyModal: React.FC<DeleteAdCopyModalProps> = observer(
  ({ isOpened, task, onClose }) => {
    const alert = useAlert()

    const {
      manageCampaignStore,
      accountStore: { accountId },
    } = useStore()

    const handleDelete = async () => {
      if (!accountId) return

      const error = await manageCampaignStore.deleteAdCopies(
        accountId,
        task.adCopyId
      )

      if (error)
        alert.error(
          <AlertMessage title="Failed to delete Ad Copy" description={error} />
        )
      else onClose()
    }

    const message = "Are you sure want to delete ad copy?"

    return (
      <ConfirmModal
        title="Delete"
        message={message}
        isOpen={isOpened}
        onClose={onClose}
        applyText="Delete"
        onApply={handleDelete}
      />
    )
  }
)

export default DeleteAndPublishAdCopyModal
