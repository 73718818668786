import clsx from "clsx"
import React from "react"

import styles from "./ScrollContainer.module.scss"

interface ScrollContainerProps extends React.HTMLProps<HTMLDivElement> {
  type?: "none" | "primary" | "secondary"
  className?: string
}

const ScrollContainer = React.forwardRef<ScrollContainerProps, any>(
  ({ type = "secondary", children, className, ...props }, ref) => (
    <div
      className={clsx(styles.scrollable, styles[type], className)}
      {...props}
      ref={ref}
    >
      {children}
    </div>
  )
)

export default ScrollContainer
