import React from "react"
import { observer } from "mobx-react-lite"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import TextField from "@components/ui/TextField/TextField"
import FormNumberField from "@framework/prototypes/FormNumberField"
import { useActiveSection } from "@pages/PMax/ActiveSectionContext"

import styles from "./index.module.scss"

export const BudgetSection: React.FC = observer(() => {
  const { props } = useActiveSection<HTMLDivElement>("budget")

  return (
    <Box className={styles.section} {...props}>
      <Stack gutter="16" direction="column">
        <Typography type="h2">Budget</Typography>

        <FormNumberField
          label="Enter the average you want to spend each day"
          name="amount"
          className={styles.field}
          decimalScale={0}
          customInput={TextField}
          thousandSeparator
          prefix="$"
        />

        <Typography type="body2">
          <Typography color="primary60Color" type="inherit" inline>
            Note:
          </Typography>{" "}
          For the month, you won&#8217;t pay more than your daily budget times
          the average number of days in a month. Some days you might spend less
          than your daily budget, and on others you might spend up to twice as
          much
        </Typography>
      </Stack>
    </Box>
  )
})

export default BudgetSection
