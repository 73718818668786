import { action, observable, runInAction } from "mobx"
import { nanoid } from "nanoid"
import capitalize from "lodash/capitalize"
import sortBy from "lodash/sortBy"

import clientService, {
  AccountReportEntity,
  CampaignReportEntity,
  ChannelReportEntity,
  GetExpandedReportResponse,
  MetaAdSetEntity,
  MetaCampaignReportEntity,
  PeriodReportEntity,
  PlatformReportEntity,
} from "@services/client.service"
import {
  Platform,
  CampaignGroup,
  getPlatformOption,
  getCampaignGroupOption,
  bundlePlatformParams,
} from "@framework/constants/report"
import type { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import RootStore from "@store/RootStore"
import { apiDateFormatter } from "@services/utils"
import type {
  AccountReport,
  AdSetReport,
  CampaignReport,
  CampaignStatusType,
  ChannelReport,
  MetaCampaignReport,
  PeriodReport,
  PlatformReport,
} from "./types"

export class ExtendedReportStore {
  @observable root: RootStore

  @observable data: any | null = null

  @observable isLoading = false

  @observable loadingError: string | null = null

  @observable platforms: Map<string, PlatformReport> = new Map()

  @observable accounts: Map<string, AccountReport> = new Map()

  @observable periodReports: Map<string, PeriodReport> = new Map()

  @observable cannelReports: Map<string, ChannelReport> = new Map()

  @observable googleCampaignReports: Map<string, CampaignReport> = new Map()

  @observable metaCampaignReports: Map<string, MetaCampaignReport> = new Map()

  @observable metaAdSetReports: Map<string, AdSetReport> = new Map()

  constructor(root: RootStore) {
    this.root = root
  }

  @observable platformIds: string[] = []

  @observable accountIds: string[] = []

  @action loadReport = async (
    period: [Date, Date],
    comparePeriod: [Date, Date],
    accounts: number[],
    platforms: Platform[],
    channels: CampaignGroup[],
    campaignsStatus: CampaignStatusType
  ) => {
    try {
      this.isLoading = true
      this.loadingError = null

      const response = await clientService.getExpandedReport(
        {
          from: apiDateFormatter(period[0]),
          to: apiDateFormatter(period[1]),
          compareFrom: apiDateFormatter(comparePeriod[0]),
          compareTo: apiDateFormatter(comparePeriod[1]),
        },
        {
          accounts,
          channels: bundlePlatformParams(platforms, channels),
          platforms,
          campaignsStatus: campaignsStatus.toLowerCase(),
        }
      )

      await this.parseReportData(response.data.data)
    } catch (error) {
      this.loadingError =
        (await this.parseReportData(null)) ?? "Unexpected error"
    } finally {
      this.isLoading = false
    }
    return this.loadingError
  }

  parseCampaigns = async (
    reports: CampaignReportEntity[],
    campaignReports: Map<string, CampaignReport>
  ) =>
    Promise.all(
      reports.map(async (campaign) => {
        const id = nanoid()

        const { Name, Id, Status, ...report } = campaign

        campaignReports.set(id, {
          id,
          campaignId: Id,
          label: Name,
          status: Status,
          ...report,
        })

        return id
      })
    )

  parseMetaAdSets = async (
    reports: MetaAdSetEntity[],
    metaAdSets: Map<string, AdSetReport>
  ) =>
    Promise.all(
      reports.map(async (adSet) => {
        const adSetId = nanoid()

        const { name, id, status, ...report } = adSet

        metaAdSets.set(adSetId, {
          id: adSetId,
          label: name,
          status,
          ...report,
        })

        return adSetId
      })
    )

  parseChannels = async (
    reports: ChannelReportEntity[],
    cannelReports: Map<string, ChannelReport>,
    campaignReports: Map<string, CampaignReport>
  ) =>
    Promise.all(
      reports.map(async (channelItem: ChannelReportEntity) => {
        const id = nanoid()

        const campaignReportIds = await this.parseCampaigns(
          channelItem.CampaignsReport ?? [],
          campaignReports
        )

        cannelReports.set(id, {
          id,
          label: getCampaignGroupOption(channelItem.ChannelId).label,
          channelName: channelItem.ChannelId,
          campaignReportIds,
          ...channelItem.Summary,
        })
        return id
      })
    )

  parseMetaCampaigns = async (
    reports: MetaCampaignReportEntity[],
    campaignReports: Map<string, MetaCampaignReport>,
    metaAdSets: Map<string, AdSetReport>
  ) =>
    Promise.all(
      reports.map(async (campaignItem: MetaCampaignReportEntity) => {
        const id = nanoid()

        const { Adsets: adSets, id: campaignId, name, ...rest } = campaignItem

        const adSetIds = await this.parseMetaAdSets(adSets || [], metaAdSets)

        campaignReports.set(id, {
          id,
          label: name,
          campaignId,
          adSetIds,
          ...rest,
        })

        return id
      })
    )

  parsePlatformReports = async (
    reports: PlatformReportEntity[],
    platforms: Map<string, PlatformReport>
  ) =>
    Promise.all(
      reports.map(async (report) => {
        const id = nanoid()

        platforms.set(id, {
          id,
          label: getPlatformOption(report.PlatformId).label,
          platformName: report.PlatformId,
          ...report.Summary,
        })

        return id
      })
    )

  parsePeriodReports = async (
    reports: PeriodReportEntity[],
    periodicity: PerformanceReportPeriodicity,
    platforms: Map<string, PlatformReport>,
    periodReports: Map<string, PeriodReport>
  ) => {
    const sorted = sortBy(reports, (it) => it.Period)

    return Promise.all(
      sorted.map(async (report, idx) => {
        const id = nanoid()

        const platformReportIds = await this.parsePlatformReports(
          report.PlatformReports ?? [],
          platforms
        )

        const label = periodicity.slice(0, -2)

        periodReports.set(id, {
          id,
          periodicity,
          label: `${capitalize(label)} ${idx + 1}`,
          periodIndex: idx,
          period: report.Period,
          platformReportIds,
          ...report.Summary,
        })

        return id
      })
    )
  }

  parseReportData = async (data: GetExpandedReportResponse["data"]) => {
    try {
      const {
        accountStore: { getAccountById },
      } = this.root

      let platformIds: string[] = []
      let accountReportIds: string[] = []
      const platforms = new Map<string, PlatformReport>()
      const accounts = new Map<string, AccountReport>()
      const periodReports = new Map<string, PeriodReport>()
      const cannelReports = new Map<string, ChannelReport>()
      const googleCampaignReports = new Map<string, CampaignReport>()
      const metaCampaignReports = new Map<string, MetaCampaignReport>()
      const metaAdSetReports = new Map<string, AdSetReport>()

      if (data != null) {
        const parseAccountReports = async (
          reports: AccountReportEntity[],
          getPlatformIds: (accountId: string) => string[]
        ) =>
          Promise.all(
            reports.map(async (accountItem) => {
              const accountId = nanoid()

              const monthlyReportIds = await this.parsePeriodReports(
                accountItem.MonthlyReport ?? [],
                "monthly",
                platforms,
                periodReports
              )

              const weeklyReportIds = await this.parsePeriodReports(
                accountItem.WeeklyReport ?? [],
                "weekly",
                platforms,
                periodReports
              )

              accounts.set(accountId, {
                id: accountId,
                label:
                  getAccountById(accountItem.AccountId)?.name ??
                  accountItem.AccountId.toString(),
                accountId: accountItem.AccountId,
                weeklyReportIds,
                monthlyReportIds,
                platformReportIds: getPlatformIds(
                  accountItem.AccountId.toString()
                ),
                ...accountItem.Summary,
              })

              return accountId
            })
          )

        const parsePlatformReports = async (
          reports: PlatformReportEntity[],
          getPlatformIds: (platform: Platform) => string[]
        ) =>
          Promise.all(
            reports.map<Promise<string>>(async (platformItem) => {
              const id = nanoid()

              platforms.set(id, {
                id,
                label: getPlatformOption(platformItem.PlatformId).label,
                platformName: platformItem.PlatformId,
                accountReportIds: getPlatformIds(platformItem.PlatformId),
                ...platformItem.Summary,
              })
              return id
            })
          )

        const parsePlatformAccountReports = async (
          reports: AccountReportEntity[]
        ) => {
          const platformIdsMap: Record<Platform, string[]> = {
            googleAds: [],
            metaAds: [],
          }

          const accountIdsMap: Record<string, string[]> = {}

          await Promise.all(
            reports.map(async (accountItem) => {
              accountIdsMap[accountItem.AccountId.toString()] = []

              // googleAds
              if (accountItem.GoogleAdsReport != null) {
                const googleAccountId = nanoid()

                const campaignGroupReportIds = await this.parseChannels(
                  accountItem.GoogleAdsReport.ChannelsReport ?? [],
                  cannelReports,
                  googleCampaignReports
                )

                platforms.set(googleAccountId, {
                  id: googleAccountId,
                  label: getPlatformOption("googleAds").label,
                  platformName: "googleAds",
                  campaignGroupReportIds,
                  ...accountItem.GoogleAdsReport.Summary,
                })

                platformIdsMap.googleAds.push(googleAccountId)

                accounts.set(googleAccountId, {
                  id: googleAccountId,
                  label:
                    getAccountById(accountItem.AccountId)?.name ??
                    accountItem.AccountId.toString(),
                  accountId: accountItem.AccountId,
                  campaignGroupReportIds,
                  ...accountItem.GoogleAdsReport.Summary,
                })

                accountIdsMap[accountItem.AccountId].push(googleAccountId)
              }

              // metaAds
              if (accountItem.MetaAdsReport != null) {
                const metaAccountId = nanoid()

                const metaCampaignsReportIds = await this.parseMetaCampaigns(
                  accountItem.MetaAdsReport.MetaCampaignsReport ?? [],
                  metaCampaignReports,
                  metaAdSetReports
                )

                platforms.set(metaAccountId, {
                  id: metaAccountId,
                  label: getPlatformOption("metaAds").label,
                  platformName: "metaAds",
                  metaCampaignsReportIds,
                  ...accountItem.MetaAdsReport.Summary,
                })

                platformIdsMap.metaAds.push(metaAccountId)

                accounts.set(metaAccountId, {
                  id: metaAccountId,
                  label:
                    getAccountById(accountItem.AccountId)?.name ??
                    accountItem.AccountId.toString(),
                  accountId: accountItem.AccountId,
                  metaCampaignsReportIds,
                  ...accountItem.MetaAdsReport.Summary,
                })

                accountIdsMap[accountItem.AccountId].push(metaAccountId)
              }
            })
          )

          return { platformIdsMap, accountIdsMap }
        }

        const { platformIdsMap, accountIdsMap } =
          await parsePlatformAccountReports(data.Accounts ?? [])

        accountReportIds = await parseAccountReports(
          data.Accounts ?? [],
          (accountId) => accountIdsMap[accountId] ?? []
        )

        platformIds = await parsePlatformReports(
          data.PlatformsSummary ?? [],
          (platform) => platformIdsMap[platform] ?? []
        )
      }

      runInAction(() => {
        this.platformIds = platformIds
        this.accountIds = accountReportIds
        this.periodReports = periodReports
        this.cannelReports = cannelReports
        this.googleCampaignReports = googleCampaignReports
        this.accounts = accounts
        this.platforms = platforms
        this.metaCampaignReports = metaCampaignReports
        this.metaAdSetReports = metaAdSetReports
      })
    } catch (error: any) {
      return "Failed to parse"
    }
    return null
  }

  @action getExportCSVDownloadLink = (
    period: [Date, Date],
    accounts: number[],
    platforms: Platform[],
    channels: CampaignGroup[]
  ) =>
    clientService.getReportDownloadLink(
      {
        from: apiDateFormatter(period[0]),
        to: apiDateFormatter(period[1]),
      },
      {
        accounts,
        platforms,
        channels: bundlePlatformParams(platforms, channels),
      }
    )
}

export default ExtendedReportStore
