import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"

import styles from "./SimpleColumn.module.scss"

export type SimpleColumnProps = {
  className?: string
  upperCase?: boolean
}

export const SimpleColumn: React.FC<SimpleColumnProps> = ({
  className,
  children,
  upperCase = false,
}) => (
  <div className={clsx(styles.root, className)}>
    <Typography type="h5" upperCase={upperCase}>
      {children}
    </Typography>
  </div>
)

export default SimpleColumn
