/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable } from "mobx"
import _sortBy from "lodash/sortBy"
import { RecommendedKeyword } from "@framework/types/opportunities"
import { ID } from "@framework/types/types"

class KeywordsListStore {
  @observable isLoading: boolean = false

  @observable error: string | null = null

  @observable keywordsIds: ID[] = []

  @observable keywordsMap: Map<ID, RecommendedKeyword> = new Map()

  // constructor

  // computed

  @computed get recommendedKeywords() {
    return Array.from(this.keywordsMap.values())
  }

  @computed get totalKeywords() {
    return this.keywordsIds.length
  }

  // actions

  @computed get getKeywordById() {
    const { keywordsMap } = this
    return (keywordId?: ID) =>
      keywordId ? keywordsMap.get(keywordId.toString()) : null
  }
}

export default KeywordsListStore
