import clsx from "clsx"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import { ID, SortByType } from "@framework/types/types"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import Header from "./Header"
import { MapperType } from "./types"
import NoData from "../NoData/NoData"
import Loader from "../Loader/Loader"

import styles from "./WaterfallTable.module.scss"

type TableContextData = {
  level: number
  withTip: boolean
  openedList: ID[]
  isOpened: (lvl: number, id: ID) => boolean
  onOpen: (...ids: ID[]) => void
}

export const TableOpenContext = React.createContext<TableContextData>({
  level: 0,
  withTip: false,
  isOpened: () => false,
  onOpen: () => undefined,
  openedList: [],
})

export interface WaterfallTableProps {
  label?: string
  labelSortable?: boolean
  withTip?: boolean
  hideHeader?: boolean
  mapper?: MapperType[]
  labelClassName?: string
  rootClassName?: string
  headerClassName?: string
  bodyClassName?: string
  labelWidth?: number
  sortBy?: SortByType
  onSort?: (value: string) => void
}

export const WaterfallTable: React.FC<WaterfallTableProps> = React.memo(
  ({
    label = "Label",
    withTip = false,
    hideHeader = false,
    labelSortable,
    mapper = [],
    labelClassName,
    rootClassName,
    headerClassName,
    bodyClassName,
    sortBy,
    children,
    labelWidth,
    onSort,
  }) => {
    const [openedList, setOpenedList] = useState<ID[]>([])

    const handleOpen = useCallback((...ids: ID[]) => {
      setOpenedList([...ids])
    }, [])

    const isOpened = useCallback(
      (lvl: number, id: ID) => openedList[lvl] === id,
      [openedList]
    )

    const context = useMemo(
      () => ({ level: 0, withTip, isOpened, onOpen: handleOpen, openedList }),
      [handleOpen, isOpened, withTip, openedList]
    )

    const style = {
      "--label-width": `${labelWidth ?? 350}px`,
    } as React.CSSProperties

    return (
      <TableOpenContext.Provider value={context}>
        <div className={clsx(styles.root, rootClassName)} style={style}>
          {!hideHeader && (
            <Header
              className={headerClassName}
              labelClassName={labelClassName}
              label={label}
              isLabelSortable={labelSortable}
              onSort={onSort}
              sortBy={sortBy}
              mapper={mapper}
            />
          )}
          <div className={clsx(styles.body, bodyClassName)}>{children}</div>
        </div>
      </TableOpenContext.Provider>
    )
  }
)

export interface ClosableRowProps {
  id: ID
  component: React.ReactNode
  emptyChildMessage?: React.ReactNode
  isChildLoading?: boolean
  className?: string
  onToggle?: (isOpened: boolean) => void
}

export const ClosableRow: React.FC<ClosableRowProps> = React.memo(
  ({
    id,
    children,
    component,
    emptyChildMessage = "Not found",
    isChildLoading = false,
    className,
    onToggle,
  }) => {
    const { level, onOpen, isOpened, withTip, openedList } =
      useContext(TableOpenContext)
    const hasChildren = children != null
    const isContentShown = isOpened(level, id)

    const handleSwitch = useCallback(
      () => (isContentShown ? onOpen() : onOpen(id)),
      [isContentShown, onOpen, id]
    )

    const handleHeaderClick = useMemo(
      () => (hasChildren ? handleSwitch : undefined),
      [hasChildren, handleSwitch]
    )

    const handleOpenNext = useCallback(onOpen.bind(null, id), [onOpen, id])

    useEffect(() => {
      if (onToggle) onToggle(isContentShown)
    }, [isContentShown])

    const context = useMemo(
      () => ({
        level: level + 1,
        withTip,
        isOpened,
        onOpen: handleOpenNext,
        openedList,
      }),
      [handleOpenNext, isOpened, level, withTip, openedList]
    )

    const childArray = React.Children.toArray(children)

    const childNode = hasChildren ? (
      !childArray.length ? (
        isChildLoading ? (
          <Loader isLoading className={styles.loader} />
        ) : (
          <NoData>{emptyChildMessage}</NoData>
        )
      ) : (
        children
      )
    ) : null

    return (
      <TableOpenContext.Provider value={context}>
        <div className={clsx(styles.rowRoot, className)}>
          <div
            className={clsx(styles.rowContainer, {
              [styles.opened]: isContentShown,
            })}
          >
            <span className={styles.indent}>
              {!!hasChildren && (
                <IconButton
                  color={isContentShown ? "primary" : "default"}
                  onClick={handleHeaderClick}
                >
                  <Icon
                    name="arrow-right"
                    rotateAngle={isContentShown ? 90 : 0}
                  />
                </IconButton>
              )}
            </span>
            <div className={styles.rowContainer}>{component}</div>
          </div>
          {isContentShown && (
            <div className={clsx(styles.rowBody, styles.shifter)}>
              {childNode}
            </div>
          )}
        </div>
      </TableOpenContext.Provider>
    )
  }
)

export default WaterfallTable
