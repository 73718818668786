/* eslint-disable prettier/prettier */
import { numberWithCommas } from "@components/utils/numberUtils"
import { KeywordsViewType } from "@framework/types/opportunities"
import { Option } from "@framework/types/types"
import { parseAddress } from "@services/utils"
import { TableMapper } from "./components/RecommendedKeywords/Table/Table"
import { TableMapperType } from "./components/Table/Table"

const mbNoData = (value: any) => value || "-"

const RELEVANCY_SCORE_DESCRIPTION_TEXT = "The Efficiency Score is score that shows you based on the Google data, which attributes will have the highest value: the best results for the lowest dollars spend. The score is based on calculating a number of data points including search volume, cost per click, and competition for that attribute and analyzing it within the larger context of all of attributes that could be selected for campaigns."

export const keywordsTableMapper: TableMapper[] = [
  { value: "name", label: "Attribute", sortable: true },
  { value: "categoryName", label: "Category", width: 100, sortable: true },
  // { value: "funnelStage", label: "Funnel Stage", sortable: true },
  {
    value: "avgSearchVolume",
    label: "Avg monthly search volume",
    width: 100,
    sortable: true
  },
  {
    value: "graphPoints",
    label: "36 month trend",
    width: 140,
  },
  { value: "avgCpc", label: "CPC", width: 65, sortable: true },
  { value: "estimate", label: "Est Monthly Ad Spend", width: 70, sortable: true },
  { value: "budgetFraction", label: "Allocated budget (%)", width: 75, sortable: false },
  { value: "competition", label: "Comp.", width: 60, sortable: true },
  { value: "relevanceScope", label: "Efficiency Score", width: 72, sortable: true, description: RELEVANCY_SCORE_DESCRIPTION_TEXT },
  { value: "distance", label: "Distance To (miles) ", width: 72, sortable: true },
  { value: "action", label: "Status", width: 65 },
]

/**
 * @deprecated
 */
export const opportunitiesTableMapper: TableMapperType[] = [
  {
    name: "name",
    label: "Name",
    renderCallback: mbNoData,
  },
  {
    name: "address",
    label: "Address",
    renderCallback: mbNoData,
    getValueCallback: parseAddress,
  },
  {
    name: "Industry.name",
    label: "Industry",
    renderCallback: mbNoData,
    style: { flex: "0.6 0.6" },
  },
  { name: "website", label: "Website", renderCallback: mbNoData },
  {
    name: "market.name",
    label: "Market",
    renderCallback: mbNoData,
    style: { flex: "0.6 0.6" },
  },
  {
    name: "yearlyAdBudget",
    label: "Total Contract Ad Spend",
    textStyle: { color: "darkBlue", type: "h1", bold: true },
    renderCallback: (value: string) =>
      `$${numberWithCommas(value ? Number(value) : 0)}`,
    style: { flex: "0.8 0.8" },
  },
]

export const keywordsByCategoryTableMapper: TableMapper[] = keywordsTableMapper.filter(({ value }) => value !== 'categoryName')

const defaultFormatter = (value: number) => numberWithCommas(value)
const defaultFloatFormatter = (value: number) => numberWithCommas(value, 2)
const defaultMoneyFormatter = (value: number) => `$${numberWithCommas(value, 2)}`

export const calculatorTableMapper: TableMapperType[] = [
  {
    name: "mySearchVolume",
    label: "MY SEARCH VOLUME",
    renderCallback: defaultFormatter,
    textStyle: {
      color: "primary",
      type: "h2",
      bold: true,
    },
    withBg: true,
  },
  { name: "avgCpc", label: "AVG.CPC", renderCallback: defaultMoneyFormatter },
  { name: "ctr", label: "CTR %", renderCallback: (value: number) => `${defaultFloatFormatter(value)}%` },
  { name: "clicks", label: "CLICKS", renderCallback: defaultFormatter },
  { name: "estimated", label: "ESTIMATED COST", renderCallback: defaultMoneyFormatter },
  { name: "conversions", label: "Conversions", renderCallback: defaultFloatFormatter },
  { name: "constConversion", label: "Cost/Conv", renderCallback: defaultMoneyFormatter },
  { name: "revenue", label: "Revenue", renderCallback: defaultMoneyFormatter },
  { name: "roas", label: "ROAS", renderCallback: defaultMoneyFormatter },
  // {
  //   name: "actualBudget",
  //   label: "ACTUAL BUDGET",
  //   renderCallback: (value: number) => `$${numberWithCommas(value)}`
  // },
  // {
  //   name: "adBudgetDifference",
  //   label: "AD BUDGET DIFFERENCE",
  //   renderCallback: defaultMoneyFormatter,
  // },
]

export const viewTypeOptions: Option<KeywordsViewType>[] = [
  {
    value: 'categoryGroup',
    label: "Categories",
  },
  {
    value: 'keywords',
    label: "Attributes",
  },
]
