import React, { FC, useEffect, useState } from "react"
import { CollapsibleHeader } from "@components/ui/Collapsible/CollapsibleHeader"
import useToggle from "@framework/hooks/useToggle"
import Collapsable from "@components/ui/Collapsible/Collapsable"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import Stack from "@components/ui/Stack/Stack"
import { useStore } from "@root/store"
import styles from "./index.module.scss"

const METRIC_OPTIONS: Record<string, string> = {
  costConv: "cost/conv",
  conversion: "Conversion",
  roas: "ROAS",
  ctr: "CTR",
  cpc: "CPC",
}
const metricOptionsArray = Object.keys(METRIC_OPTIONS)

const COMPARE_OPTIONS: Record<string, string> = {
  themselves: "Themselves",
  adGroup: "Keywords in Ad Group",
  campaign: "Keywords in Campaign",
  account: "Keywords in Account",
}
const compareOptionsArray = Object.keys(COMPARE_OPTIONS)

interface LowPerformanceKeywordsProps {}
const LowPerformanceKeywords: FC<LowPerformanceKeywordsProps> = (props) => {
  const [metricValue, setMetricValue] = useState<string>()
  const [compareValue, setCompareValue] = useState<string>()
  const toggle = useToggle()
  const { manageCampaignStore, accountStore } = useStore()
  const { accountId } = accountStore
  const { dateFilter, loadKeywordsList } = manageCampaignStore

  const handleLPF = () => {
    if (metricValue && compareValue) {
      loadKeywordsList(
        accountId as number,
        dateFilter,
        metricValue,
        compareValue
      )
    }
  }

  const handleResetLPF = () => {
    setMetricValue("")
    setCompareValue("")
    loadKeywordsList(accountId as number, dateFilter)
  }

  return (
    <div className={styles.lowPerformanceHeader}>
      <CollapsibleHeader
        label="Low-performance finder"
        onClick={toggle.handleToggle}
        open={toggle.isOpened}
        type="h5"
        weight="bold"
        rootClassName={styles.collapsibleHeader}
      />
      <Collapsable open={toggle.isOpened}>
        <div className={styles.collapsibleInner}>
          <Typography type="body2">
            Low-performance finder allows you to find the keywords with the
            lowest performance for a specific time period which is calculated
            based on the selected KPI metric.
          </Typography>
          <Stack
            direction="row"
            justify="flex-start"
            gutter="32"
            className={styles.collapsibleContent}
          >
            <SimpleSelect
              renderValue={(value) => METRIC_OPTIONS[value]}
              value={metricValue}
              options={metricOptionsArray}
              onSelect={(value) => setMetricValue(value)}
              className={styles.performanceSelect}
              placeholder="Select metric"
            />
            <SimpleSelect
              renderValue={(value) => COMPARE_OPTIONS[value]}
              value={compareValue}
              options={compareOptionsArray}
              onSelect={(value) => setCompareValue(value)}
              className={styles.performanceSelect}
              placeholder="Compare with"
            />
            <Button
              disabled={!(metricValue && compareValue)}
              className={styles.performanceButton}
              color="secondary"
              onClick={handleResetLPF}
            >
              Clear
            </Button>
            <Button
              disabled={!(metricValue && compareValue)}
              className={styles.performanceButton}
              color="primary"
              onClick={handleLPF}
            >
              Find
            </Button>
          </Stack>
        </div>
      </Collapsable>
    </div>
  )
}

export default LowPerformanceKeywords
