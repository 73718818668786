import React from "react"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import { Option } from "@framework/types/types"
import Label from "@components/ui/CheckBox/Label/Label"
import InputLabel from "@components/ui/Label/Label"
import Radio from "@components/ui/CheckBox/Radio/Radio"
import {
  CANADA_INDEX,
  LocationsValidationSchema,
  USA_INDEX,
} from "@store/manage-campaigns/validation/settings"
import { useActiveSection } from "@pages/PMax/ActiveSectionContext"
import StateSettingsForm from "./ExtendedSettings"
import OtherLocations from "./OtherLocations"

const options: Option[] = [
  { value: "ALL", label: "All countries and territories" },
  { value: "USA_CANADA", label: "United States and Canada" },
  { value: "USA", label: "United States" },
  { value: "ANOTHER", label: "Enter another location" },
]

export const LocationsForm: React.FC = observer(() => {
  const formik = useFormikContext<LocationsValidationSchema>()

  const handleChangeTarget = (value: string) => {
    formik.setFieldValue("locationTarget", value)

    if (value === "USA") {
      formik.setFieldValue("locationIds", [USA_INDEX])
      formik.setFieldValue("excludeLocationIds", [])
      return
    }
    if (value === "USA_CANADA") {
      formik.setFieldValue("locationIds", [USA_INDEX, CANADA_INDEX])
      formik.setFieldValue("excludeLocationIds", [])
      return
    }
    if (value === "ALL") {
      formik.setFieldValue("locationIds", [])
      formik.setFieldValue("excludeLocationIds", [])
      return
    }

    formik.setFieldValue("locationIds", formik.initialValues.locationIds)
    formik.setFieldValue(
      "excludeLocationIds",
      formik.initialValues.excludeLocationIds
    )
  }

  const { props } = useActiveSection<HTMLDivElement>("locations")

  return (
    <Stack gutter="16" {...props}>
      <Typography type="h2">Locations</Typography>

      <Stack gutter="16">
        <InputLabel label="Select locations to target">
          {options.map((item) => (
            <Label text={item.label}>
              <Radio
                id={item.value}
                name={item.value}
                value={formik.values.locationTarget}
                onChange={() => handleChangeTarget(item.value)}
              />
            </Label>
          ))}
        </InputLabel>

        {formik.values.locationTarget === "ANOTHER" ? (
          <OtherLocations />
        ) : (
          <StateSettingsForm />
        )}
      </Stack>
    </Stack>
  )
})

export default LocationsForm
