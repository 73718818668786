import React from "react"
import { observer } from "mobx-react-lite"
import capitalize from "lodash/capitalize"
import { useFormikContext } from "formik"

import Stack from "@components/ui/Stack/Stack"
import FormTextField from "@framework/prototypes/FormTextField"
import { Button } from "@components/ui/Button"
import FormSimpleMultiSelectField from "@framework/prototypes/FormSimpleMultiSelectField"
import {
  ageOptions,
  customerTypeOptions,
  occasionOptions,
  toneOptions,
} from "@components/EditAdCopyModal/AdCopyWizardModal/validation"
import RowContainer from "./RowContainer"

import styles from "./RegenerateAdCopyForm.module.scss"

export interface AdvancedSettingsFormProps {}

export const AdvancedSettingsForm: React.FC<AdvancedSettingsFormProps> =
  observer(() => {
    const formik = useFormikContext<any>()

    return (
      <Stack gutter="24" align="stretch" className={styles.sectionBody}>
        <RowContainer label="Text Tone">
          <Stack gutter="8" direction="row" wrappable>
            {toneOptions.map((it) => {
              const active = formik.values.toneType === it
              return (
                <Button
                  onClick={() => formik.setFieldValue("toneType", it)}
                  variant={active ? "outlined" : "contained"}
                  color={active ? "primary" : "secondary"}
                  key={it}
                >
                  {capitalize(it)}
                </Button>
              )
            })}
          </Stack>
        </RowContainer>

        <RowContainer label="Occasion">
          <Stack gutter="8" direction="row" wrappable>
            {occasionOptions.map((it) => {
              const active = formik.values.occasion === it
              return (
                <Button
                  onClick={() => formik.setFieldValue("occasion", it)}
                  variant={active ? "outlined" : "contained"}
                  color={active ? "primary" : "secondary"}
                  key={it}
                >
                  {capitalize(it)}
                </Button>
              )
            })}
          </Stack>
        </RowContainer>

        <RowContainer label="Audience Age">
          <FormSimpleMultiSelectField
            name="audienceAges"
            placement="bottom-end"
            options={ageOptions}
            renderValue={capitalize}
          />
        </RowContainer>

        <RowContainer label="Customer Type">
          <FormSimpleMultiSelectField
            name="customerTypes"
            placement="bottom-end"
            options={customerTypeOptions}
            renderValue={capitalize}
          />
        </RowContainer>

        <RowContainer label="Purchase Terms">
          <FormTextField name="promotionOffer" placeholder="Description" />
        </RowContainer>
      </Stack>
    )
  })

export default AdvancedSettingsForm
