/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable } from "mobx"
import _sortBy from "lodash/sortBy"
import {
  KeywordCategoryGroup,
  RecommendedKeyword,
} from "@framework/types/opportunities"
import OpportunitiesStore from "@store/opportunities/opportunities"

const searchFilter = (
  query: string,
  value: RecommendedKeyword | KeywordCategoryGroup
) =>
  !query ||
  value.displayName.toLocaleLowerCase().includes(query.toLocaleLowerCase())

class KeywordCategoriesStore {
  @observable searchFiler: string = ""

  @observable opportunitiesStore: OpportunitiesStore

  // constructor

  constructor(opportunitiesStore: OpportunitiesStore) {
    this.opportunitiesStore = opportunitiesStore
  }

  // computed

  @computed get categoryGroups() {
    return this.opportunitiesStore.categoryGroups
  }

  @computed get filteredCategories(): KeywordCategoryGroup[] {
    const { categoryGroups, searchFiler } = this

    return categoryGroups
      .map((category) => ({
        ...category,
        keywords: category.keywords.filter((keyword) =>
          searchFilter(searchFiler, keyword)
        ),
      }))
      .filter(
        (category) =>
          (searchFiler && searchFilter(searchFiler, category)) ||
          category.keywords.length > 0
      )
  }

  // actions

  @action setSearchFilter = (value: string) => {
    this.searchFiler = value
  }
}

export default KeywordCategoriesStore
