import React, { useState } from "react"

import {
  ConfirmModalName,
  ConfirmModalProps,
} from "@components/modals/GlobalConfirmModal/type"
import SaveCampaignDraftModal from "@components/modals/GlobalConfirmModal/SaveCampaignDraftModal"

export const useConfirmModal = () => {
  const [modalProps, setModal] = useState<{
    Component: React.FC<ConfirmModalProps>
    props: ConfirmModalProps
  } | null>(null)

  const handleConfirm = (
    modalName: string,
    callback: (ok: boolean) => void
  ) => {
    const Modal = getModalComponent(modalName)
    if (Modal == null) {
      callback(true)
      return
    }
    const onConfirm = (ok: boolean) => {
      callback(ok)
      setModal(null)
    }
    setModal({ Component: Modal, props: { onConfirm } })
  }

  const ModalNode = modalProps?.Component != null && (
    <modalProps.Component {...modalProps.props} />
  )

  return {
    ModalNode,
    handleConfirm,
  }
}

const getModalComponent = (modalName: string) => {
  if (modalName == null) return null
  if (modalName === ConfirmModalName.NEW_CAMPAIGN_DRAFT_WAS_NOT_SAVED)
    return SaveCampaignDraftModal
  return null
}

export default useConfirmModal
