import { PMaxProductReportType } from "@framework/types/account"
import { action, computed, observable } from "mobx"

class PMaxProductsStore<
  T extends PMaxProductReportType = PMaxProductReportType
> {
  // INJECTIONS

  // CONSTRUCTOR

  // STATE

  @observable data: T[] | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  // ACTIONS

  @computed get dataIndex() {
    return new Map(this.data?.map((it) => [it.Title, it]))
  }

  @action storeData = (data: T[]) => {
    this.data = data
  }
}

export default PMaxProductsStore
