import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { useStore } from "@store/index"
import ConfirmModal from "@components/modals/ConfirmModal/ConfirmModal"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { countedFormat } from "@components/utils/stringUtils"

type SkipChangesConfirmModalProps = {
  isOpen: boolean
  onClose: () => void
}

const SkipChangesConfirmModal: React.FC<SkipChangesConfirmModalProps> =
  observer(({ isOpen, onClose }) => {
    const alert = useAlert()
    const {
      accountStore: { accountId },
      productFeedStore: { list, changes },
    } = useStore()

    const handleSkipAll = async () => {
      onClose()
      if (!accountId) return
      const error = await changes.dropProductChanges(accountId)
      if (error) alert.error(<AlertMessage title="Oops!" description={error} />)
      else {
        alert.success("All selected changes were successfully discarded!")
      }
    }

    const { isAllSelected, selectedCount } = list.selected

    return (
      <ConfirmModal
        title="Skip all changes?"
        message={`Do you want to skip ${
          isAllSelected && selectedCount > 1 ? "all" : selectedCount
        } ${countedFormat("change", selectedCount)}?`}
        isOpen={isOpen}
        onClose={onClose}
        applyText="Confirm"
        onApply={handleSkipAll}
      />
    )
  })

export default SkipChangesConfirmModal
