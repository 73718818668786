import React, { useEffect, useState } from "react"
import clsx from "clsx"
import _uniqueId from "lodash/uniqueId"

import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import Row from "../Row/Row"

import styles from "./EditableList.module.scss"

type AdCopyProps = {
  title: string
  label?: string
  defaultList: [string, string][]
  isEdit: boolean
  maxLength?: number
  onChange?: (list: [string, string][]) => void
  minRows?: number
  maxRows?: number
  className?: string
}

const EditableList: React.FC<AdCopyProps> = ({
  title,
  label,
  defaultList = [],
  isEdit,
  maxLength = Number.MAX_VALUE,
  onChange,
  minRows = 0,
  maxRows = Infinity,
  className,
}) => {
  const [list, setList] = useState(defaultList)
  const [showNewForm, setShowNewForm] = useState(false)

  const canAddMore = maxRows > list.length
  const canDelete = minRows < list.length

  const handleAddNew = (value: string) => {
    setShowNewForm(false)
    if (!canAddMore) return
    if (onChange && list[list.length - 1][1]) {
      setList([...list, [_uniqueId(), value]])
    }
  }

  const handleSave = (id: string, value: string) => {
    const index = list.findIndex(([itemId, _]) => itemId === id)
    const newList = [...list]
    if (onChange && index >= 0 && newList[index][1] !== value) {
      newList[index] = [id, value]
      onChange(newList)
    }
  }

  const handleDelete = (id: string) => {
    const index = list.findIndex(([itemId, _]) => itemId === id)
    if (onChange && index >= 0 && canDelete) {
      const newList = [...list]
      newList.splice(index, 1)
      onChange(newList)
    }
  }

  useEffect(() => {
    if (defaultList !== list) setList(defaultList)
  }, [defaultList])

  return (
    <div className={clsx(styles.root, className)}>
      <Typography type="h4" className={styles.title}>
        {title}
      </Typography>
      <div className={clsx(styles.row, { [styles.gridTemplate]: !isEdit })}>
        <Typography type="subhead1" color="gray" upperCase>
          {label ?? "text"}
        </Typography>
        <Typography
          className={styles.textRight}
          type="subhead1"
          color="gray"
          upperCase
        >
          Character Count
        </Typography>
        {isEdit && (
          <Typography
            className={styles.textCenter}
            type="subhead1"
            color="gray"
            upperCase
          >
            Actions
          </Typography>
        )}
      </div>
      <div className={styles.container}>
        {list.map(([id, text]) => (
          <Row
            key={id}
            maxCharacterNumber={maxLength}
            defValue={text}
            canDelete={canDelete}
            onSave={(value) => handleSave(id, value)}
            onDelete={() => handleDelete(id)}
          />
        ))}
        {showNewForm ? (
          <Row
            key="new_row"
            maxCharacterNumber={maxLength}
            canDelete={canDelete}
            onSave={(value) => handleAddNew(value)}
            onDelete={() => setShowNewForm(false)}
            newRow
          />
        ) : (
          isEdit &&
          canAddMore && (
            <div className={styles.footer}>
              <Button
                variant="ghost"
                className={styles.button}
                startIcon={{ name: "plus" }}
                onClick={() => setShowNewForm(true)}
              >
                Add another
              </Button>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default EditableList
