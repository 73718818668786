import React from "react"
import clsx from "clsx"
import moment from "moment"

import Typography from "@components/ui/Typography/Typography"

import styles from "./AlertPopupTemplate.module.sass"

export interface AlertPopupTemplateProps {
  title: string
  description: React.ReactNode
  timeStamp?: number
  className?: string
}

const AlertMessage: React.FC<AlertPopupTemplateProps> = ({
  title,
  description,
  timeStamp = Date.now(),
  className,
}) => {
  const time = !timeStamp
    ? null
    : moment(timeStamp).format("HH:mm A • dddd DD YYYY")
  return (
    <div className={clsx(styles.message, className)}>
      <Typography type="h3">{title}</Typography>
      <Typography type="body2" color="darkGray">
        {description}
      </Typography>
      <Typography type="h6" color="gray">
        {time}
      </Typography>
    </div>
  )
}

export default AlertMessage
