/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"

import { useStore } from "@store/index"
import Typography from "@components/ui/Typography/Typography"
import Loader from "@components/ui/Loader/Loader"
import AttributesInfo from "./components/AttributesInfo/AttributesInfo"
import AttributesFormAdapter from "./components/AttributesForm/AttributesFormAdapter"

import styles from "./Attributes.module.scss"

export type AttributesProps = {
  className?: string
  isEdit?: boolean
}

export const Attributes: React.FC<AttributesProps> = observer(
  ({ className }) => {
    const { manageProfileStore, accountStore } = useStore()
    const {
      isEdit,
      attributesAssignLoading,
      attributesLoading,
      setIsEdit,
      submitFormCallback,
      fetchAttributes,
      fetchAssignedAttributes,
    } = manageProfileStore
    const { account, loading: accountLoading, loadAccount } = accountStore

    useEffect(() => {
      if (account?.industryId && !isEdit) {
        fetchAttributes(account.industryId)
        fetchAssignedAttributes(account.id)
      }
    }, [account, isEdit])

    useEffect(() => {
      if (!attributesAssignLoading && submitFormCallback) {
        loadAccount()
        setIsEdit(false)
      }
    }, [attributesAssignLoading])

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.header}>
          <Typography type="h2">Attributes</Typography>
        </div>
        <Loader isLoading={accountLoading || attributesLoading} />
        <div
          className={clsx(styles.container, {
            [styles.checkboxesContainer]: isEdit,
          })}
        >
          {isEdit ? <AttributesFormAdapter /> : <AttributesInfo />}
        </div>
      </div>
    )
  }
)

export default Attributes
