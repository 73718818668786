import React from "react"
import { observer } from "mobx-react-lite"
import { useHistory, useLocation } from "react-router-dom"

import PublicLayout from "@root/layouts/public/PublicLayout"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"

import styles from "./index.module.scss"

const DEFAULT_MESSAGE = "Something went wrong"

const MessagePage: React.FC = observer(() => {
  const history = useHistory()
  const location = useLocation<{ message?: string }>()

  const message = location.state?.message ?? DEFAULT_MESSAGE

  const handleSubmit = async () => {
    history.replace("/")
  }

  return (
    <PublicLayout>
      <div className={styles.root}>
        <Typography className={styles.title} type="h2" color="dark">
          {message}
        </Typography>

        <Button
          className={styles.control}
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit}
        >
          Back to Homepage
        </Button>
      </div>
    </PublicLayout>
  )
})

export default MessagePage
