import React, { useContext } from "react"

export interface SelectedAdGroupsContextStore {
  selectedAdGroups: string[]
  setAdGroups: (value: string[]) => void
}

export const SelectedAdGroupsContext =
  React.createContext<SelectedAdGroupsContextStore | null>(null)

export const useSelectedAdGroupsContext = () => {
  const context = useContext(SelectedAdGroupsContext)
  if (context == null)
    throw new Error("Can't find SelectedAdGroupsContext context")
  return context
}
