import React, { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import LineChartModal from "@components/modals/LineChartModal/LineChartModal"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import Sticky from "@components/ui/Sticky/Sticky"
import config from "@root/config"
import PaidSearchFooter from "@root/layouts/PrivateLayout/components/PaidSeachFooter/PaidSearchFooter"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import RecommendedKeywords from "./components/RecommendedKeywords/RecommendedKeywords"
import StickyContainer from "./components/AmountSection/StickyContainer"
import { opportunitiesTableMapper } from "./mock.temp"
import Calculator from "./components/Calculator/Calculator"
import Table from "./components/Table/Table"
import SelectMonths, {
  MonthsType,
} from "./components/SelectMonths/SelectMonths"
import SidebarFilter from "./components/SidebarFilter/SidebarFilter"
import FixedAmountSection from "./components/AmountSection/FixedAmountSection"
import FloatingAmountSection from "./components/AmountSection/FloatingAmountSection"

import styles from "./Opportunities.module.scss"

type OpportunitiesProps = {}

const Opportunities: React.FC<OpportunitiesProps> = observer(() => {
  const opportunitiesStore = useOpportunitiesStore()
  const {
    accountStore: { account, accountId },
  } = useStore()

  const [months, setMonths] = useState<MonthsType>([])
  const [spendAmount, setSpendAmount] = useState(0)
  const [showSelectMonth, setShowSelectMonth] = useState(false)
  const [showChart, setShowChart] = useState(false)

  const handleSetMonth = (month: string) => {
    setMonths((prev) => {
      if (prev.includes(month)) return prev.filter((item) => item !== month)
      return [...prev, month]
    })
  }

  const handleSetSpendAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSpendAmount(Number.parseInt(value, 10) || 0)
  }

  useEffect(() => {
    opportunitiesStore.setSelectedMonths(months)
  }, [months])

  const accountData = useMemo(() => (account ? [account] : []), [account])

  const stickyOffset = opportunitiesStore.view.showCalculator
    ? showChart
      ? 544
      : 282
    : 85
  const calculatorStickyOffset = 87

  useEffect(
    () => () => {
      opportunitiesStore.attributeChartStore.hideChart()
    },
    []
  )

  return (
    <div className={styles.root}>
      <ServiceDownInterceptor />
      <div className={styles.body}>
        {!config.ENABLE_OPPORTUNITIES_UPDATE &&
          opportunitiesStore.view.showCalculator && (
            <div className={styles.mainTable}>
              <Table data={accountData} dataMapper={opportunitiesTableMapper} />
            </div>
          )}

        {showSelectMonth && (
          <div className={styles.selectMonths}>
            <SelectMonths
              selectMonth={handleSetMonth}
              spendAmount={spendAmount}
              onChange={handleSetSpendAmount}
              selectedMonths={months}
              usedMonths={opportunitiesStore.usedMonths}
            />
          </div>
        )}
        {opportunitiesStore.view.showCalculator && (
          <div className={styles.calculator}>
            <Sticky
              topOffset={calculatorStickyOffset}
              stickyStyle={{
                top: `-${calculatorStickyOffset}px`,
                zIndex: 2,
                borderRadius: "10px",
              }}
            >
              <Calculator
                showChart={showChart}
                toggleChart={() => setShowChart(!showChart)}
              />
            </Sticky>
          </div>
        )}
      </div>
      <div className={styles.keywords}>
        <RecommendedKeywords stickyOffset={stickyOffset} />
        {opportunitiesStore.view.sidebarFixed ? (
          <StickyContainer stickyOffset={stickyOffset}>
            <FixedAmountSection />
          </StickyContainer>
        ) : (
          <FloatingAmountSection />
        )}
      </div>
      <LineChartModal />

      <SidebarFilter />

      {!!config.ENABLE_OPPORTUNITIES_UPDATE && (
        <PaidSearchFooter key={accountId} />
      )}
    </div>
  )
})

export default Opportunities
