import React from "react"
import { observer } from "mobx-react-lite"

import Template from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import ToggleButton from "@components/ui/CheckBox/ToggleButton/ToggleButton"
import { MetricNameType } from "@framework/types/metrics"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import { renderMetricOption } from "@framework/constants/metrics"

import styles from "./Header.module.sass"

export const Header: React.FC = observer(() => {
  const {
    reports: { mainFilter, filter },
  } = useStore()

  const prevPeriodName = mainFilter.activeFilter.prevPeriod.label

  return (
    <Template.Header
      offset="medium"
      left={<Typography type="h2">KPI Trends</Typography>}
      right={
        <>
          <Typography type="subhead1" color="darkGray" bold upperCase>
            Compare to {prevPeriodName}
          </Typography>

          <ToggleButton
            checked={mainFilter.isComparisonEnabled}
            onChange={mainFilter.toggleComparison}
          />

          <SimpleSelect
            className={styles.filed}
            value={filter.activeMetric}
            options={filter.metricsOptions}
            renderValue={renderMetricOption}
            onSelect={(v) => filter.setActiveMetric(v as MetricNameType)}
          />
        </>
      }
    />
  )
})

export default Header
