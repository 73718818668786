import React from "react"

interface YouTubeIconProps {
  width?: number
  height?: number
}
const YouTubeIcon = ({ width, height }: YouTubeIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 33}
    height={height || 25}
    fill="none"
  >
    <path
      fill="red"
      d="M31.628 4.373a3.96 3.96 0 0 0-2.795-2.795c-2.465-.66-12.351-.66-12.351-.66s-9.886 0-12.35.66a3.96 3.96 0 0 0-2.796 2.795c-.66 2.465-.66 7.61-.66 7.61s0 5.143.66 7.608a3.96 3.96 0 0 0 2.795 2.795c2.465.66 12.351.66 12.351.66s9.886 0 12.35-.66a3.96 3.96 0 0 0 2.796-2.795c.66-2.465.66-7.609.66-7.609s0-5.144-.66-7.609Z"
    />
    <path fill="#fff" d="m13.32 16.724 8.212-4.742L13.32 7.24v9.484Z" />
  </svg>
)
export default YouTubeIcon
