/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx"
import React, { useEffect, useRef, useState } from "react"

import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"

import styles from "./Popover.module.scss"

export interface PopoverProps {
  className?: string
  title?: string
  subTitle?: string
}

const Popover: React.FC<PopoverProps> = ({
  className,
  title = 0,
  subTitle = 0,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const rootNode = useRef<HTMLDivElement>(null)

  const handleClick = (e: MouseEvent) => {
    if (rootNode.current && rootNode.current.contains(e.target as Node)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const handleOpen = () => {
    setIsOpen((val) => !val)
  }

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })
      }, 110)
    }
  }, [isOpen])

  return (
    <Box
      className={clsx(styles.root, className, {
        [styles.clickable]: children,
        [styles.isOpen]: isOpen,
      })}
      padding="25px 0"
      onClick={children ? handleOpen : undefined}
      ref={rootNode}
    >
      <Typography
        type="subhead1"
        color="gray"
        upperCase
        className={styles.title}
      >
        <span>{title}</span>
        {children && (
          <Icon
            name="arrow-down"
            className={styles.icon}
            rotateAngle={isOpen ? 180 : 0}
          />
        )}
      </Typography>
      <Typography type="h2" color="primary" bold>
        {subTitle}
      </Typography>
      {children && (
        <Box
          className={clsx(styles.content, { [styles.opened]: isOpen })}
          padding="24px 15px 19px 22px"
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

export default Popover
