import { BadgeOption } from "@pages/GoogleShoppingChannel/components/Status/StatusBadge"
import { websiteURLValidator } from "@utils/validators"
import * as yup from "yup"
import { AdCopyDetailsData, AdCopyPrompts } from "@framework/types/adCopy"
import { DeepPartial } from "@framework/types/utils"
import { adCopyPromptsValidationSchema } from "@components/EditAdCopyModal/AdCopyWizardModal/validation"
import { initArrayByLength } from "@utils/numberUtils"
import uniq from "lodash/uniq"

export type CampaignStatus = "paused" | "enabled"

const campaignStatusMapper: Record<CampaignStatus, BadgeOption> = {
  paused: { color: "info", label: "Paused" },
  enabled: { color: "success", label: "Enabled" },
}

export const getCampaignStatusMapper = (status: CampaignStatus | string = "") =>
  campaignStatusMapper[status as CampaignStatus] ?? campaignStatusMapper.enabled

const HEADLINE_PREFIX = "headline"

export const headlineValidator = yup
  .string()
  .typeError("Incorrect field format")
  .max(30)
  .test(
    "unique",
    "Two or more headlines can not have the same text",
    (value, ctx) => {
      const num = Number(ctx.path.replace(HEADLINE_PREFIX, ""))

      if (!value) return true

      for (let i = 1; i < num; i += 1) {
        const otherValue = ctx.parent[`${HEADLINE_PREFIX}${i}`]
        if (otherValue && otherValue === value) return false
      }

      return true
    }
  )
  .default("")

export const headlinePositionValidator = yup
  .number()
  .transform((v) => (!v ? 0 : Number(v)))
  .default(0)

const DESCRIPTION_PREFIX = "description"

export const descriptionValidator = yup
  .string()
  .typeError("Incorrect field format")
  .max(90)
  .test(
    "unique",
    "Two or more descriptions can not have the same text",
    (value, ctx) => {
      const num = Number(ctx.path.replace(DESCRIPTION_PREFIX, ""))

      if (!value) return true

      for (let i = 1; i < num; i += 1) {
        const otherValue = ctx.parent[`${DESCRIPTION_PREFIX}${i}`]
        if (otherValue && otherValue === value) return false
      }

      return true
    }
  )
  .default("")

export const editAdCopyValidationSchema = yup.object({
  status: yup
    .string()
    .typeError("Incorrect field format")
    .oneOf(["enabled", "paused"])
    .required()
    .default("enabled"),

  campaign: yup.string().label("Campaign").required().default(""),

  adGroup: yup.string().label("Ad Group").required().default(""),

  headline1: headlineValidator.clone().label("Headline 1").required(),
  headline2: headlineValidator.clone().label("Headline 2").required(),
  headline3: headlineValidator.clone().label("Headline 3").required(),
  headline4: headlineValidator.clone().label("Headline 4"),
  headline5: headlineValidator.clone().label("Headline 5"),
  headline6: headlineValidator.clone().label("Headline 6"),
  headline7: headlineValidator.clone().label("Headline 7"),
  headline8: headlineValidator.clone().label("Headline 8"),
  headline9: headlineValidator.clone().label("Headline 9"),
  headline10: headlineValidator.clone().label("Headline 10"),
  headline11: headlineValidator.clone().label("Headline 11"),
  headline12: headlineValidator.clone().label("Headline 12"),
  headline13: headlineValidator.clone().label("Headline 13"),
  headline14: headlineValidator.clone().label("Headline 14"),
  headline15: headlineValidator.clone().label("Headline 15"),

  headline1Position: headlinePositionValidator.clone(),
  headline2Position: headlinePositionValidator.clone(),
  headline3Position: headlinePositionValidator.clone(),
  headline4Position: headlinePositionValidator.clone(),
  headline5Position: headlinePositionValidator.clone(),
  headline6Position: headlinePositionValidator.clone(),
  headline7Position: headlinePositionValidator.clone(),
  headline8Position: headlinePositionValidator.clone(),
  headline9Position: headlinePositionValidator.clone(),
  headline10Position: headlinePositionValidator.clone(),
  headline11Position: headlinePositionValidator.clone(),
  headline12Position: headlinePositionValidator.clone(),
  headline13Position: headlinePositionValidator.clone(),
  headline14Position: headlinePositionValidator.clone(),
  headline15Position: headlinePositionValidator.clone(),

  description1: descriptionValidator.clone().label("Description 1").required(),
  description2: descriptionValidator.clone().label("Description 2").required(),
  description3: descriptionValidator.clone().label("Description 3").required(),
  description4: descriptionValidator.clone().label("Description 4"),

  finalUrl: websiteURLValidator
    .clone()
    .label("Final URL")
    .required()
    .default(""),

  mobileUrl: websiteURLValidator.clone().label("Mobile URL").default(""),
})

export type EditAdCopyFormData = yup.InferType<
  typeof editAdCopyValidationSchema
>

const solveDifference = (diff: any[]) => {
  if (diff.length === 0) return undefined
  if (diff.length === 1) return diff.at(0)
  return diff
}

export const mergeAdCopiesData = (
  entities: DeepPartial<AdCopyDetailsData>[]
): DeepPartial<AdCopyDetailsData> => {
  const headlineDiffEntries = initArrayByLength(15).map((idx) => {
    const key = `headline${idx + 1}` as keyof AdCopyDetailsData
    const uniques = extractUniques(entities, key)
    return [key, solveDifference(uniques)]
  })

  const headlinePosDiffEntries = initArrayByLength(15).map((idx) => {
    const key = `headline${idx + 1}Position` as keyof AdCopyDetailsData
    const uniques = extractUniques(entities, key)
    return [key, solveDifference(uniques)]
  })

  const descriptionDiffEntries = initArrayByLength(4).map((idx) => {
    const key = `description${idx + 1}` as keyof AdCopyDetailsData
    const uniques = extractUniques(entities, key)
    return [key, solveDifference(uniques)]
  })

  const campaign = solveDifference(extractUniques(entities, "campaignID"))
  const adGroup = solveDifference(extractUniques(entities, "adGroupID"))
  const status = solveDifference(extractUniques(entities, "status"))
  const finalUrl = solveDifference(extractUniques(entities, "finalUrl"))
  const mobileUrl = solveDifference(extractUniques(entities, "mobileUrl"))

  return {
    ...Object.fromEntries(headlineDiffEntries),
    ...Object.fromEntries(headlinePosDiffEntries),
    ...Object.fromEntries(descriptionDiffEntries),
    campaign,
    adGroup,
    status,
    finalUrl,
    mobileUrl,
  }
}

export const mergeAdCopiesPromptsData = (
  entities: DeepPartial<AdCopyDetailsData>[]
): AdCopyPrompts | undefined => {
  const res = entities.length === 1 ? entities[0].promptSettings : undefined
  try {
    if (adCopyPromptsValidationSchema.isValidSync(res)) return res
  } catch (error) {
    console.error(error)
  }
  return undefined
}

export const extractModifiedFieldsList = (
  data: DeepPartial<AdCopyDetailsData>[] | null
): string[] => {
  if (data == null) return []
  return uniq(data.flatMap((it) => it.modifiedFields ?? []))
}

const extractUniques = <T extends any>(items: T[], key: keyof T) =>
  Array.from(
    items
      .reduce<Set<any>>((collection, it) => {
        if (it[key] != null) collection.add(it[key])
        return collection
      }, new Set())
      .values()
  )
