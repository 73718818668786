import clsx from "clsx"
import React from "react"

import styles from "./Stack.module.sass"

type ListGutter = "0" | "4" | "8" | "12" | "16" | "20" | "24" | "32"
type ListDirection = "column" | "row"
type ListAlign = "flex-start" | "center" | "flex-end" | "stretch"
type ListJustify = "flex-start" | "center" | "flex-end" | "space-between"

export interface StackProps extends React.HTMLProps<HTMLDivElement> {
  gutter?: ListGutter
  direction?: ListDirection
  align?: ListAlign
  justify?: ListJustify
  wrappable?: boolean
}

export const Stack = React.forwardRef<HTMLDivElement, StackProps>(
  (
    {
      children,
      className,
      gutter = "16",
      direction = "column",
      align = "flex-start",
      justify = "flex-start",
      wrappable = false,
      ...rest
    },
    ref
  ) => {
    const rootClassName = clsx(
      styles.root,
      styles[`gutter-${gutter}`],
      styles[`direction-${direction}`],
      styles[`align-${align}`],
      styles[`justify-${justify}`],
      {
        [styles.wrappable]: wrappable,
      },
      className
    )
    return (
      <div className={rootClassName} {...rest} ref={ref}>
        {children}
      </div>
    )
  }
)

export default Stack
