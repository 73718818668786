import keyBy from "lodash/keyBy"
import { action, computed, observable } from "mobx"
import { ID, Option } from "@framework/types/types"
import { PermissionType } from "@framework/types/user"
import RootStore from "../RootStore"
import ManagementStore from "./management.store"
import UserListStore from "./user-list.store"
import UserPermissionsStore from "./user-permissions.store"

const entitiesToOptions = (list: { id: ID; name: string }[]): Option[] =>
  list.map<Option>(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }))

export class SettingsStore {
  @observable root: RootStore

  @observable managementStore = new ManagementStore()

  constructor(root: RootStore) {
    this.root = root
  }

  @observable userList = new UserListStore()

  @observable userPermissions = new UserPermissionsStore()

  @observable isContentReady = false

  @observable isContentLoading = false

  @observable contentLoadingError: string | null = null

  @computed get accountMap(): Record<ID, Option> {
    const { accountList } = this.root.accountStore
    if (!accountList) return {}
    return keyBy(entitiesToOptions(accountList), "value")
  }

  @computed get agencyMap(): Record<ID, Option> {
    const { agencyList } = this.root.agencyStore
    if (!agencyList) return {}
    return keyBy(entitiesToOptions(agencyList), "value")
  }

  @computed get clientMap(): Record<ID, Option> {
    const { clientList } = this.root.clientStore
    if (!clientList) return {}
    return keyBy(entitiesToOptions(clientList), "value")
  }

  @computed get getMapper() {
    const { clientMap, accountMap, agencyMap } = this
    const map = { client: clientMap, account: accountMap, agency: agencyMap }
    return (objectName: PermissionType) => map[objectName]
  }

  @action loadContent = async (reload: boolean = false) => {
    try {
      this.isContentLoading = true
      this.isContentReady = false
      this.contentLoadingError = null

      const content = await Promise.all([
        this.loadUsers(reload),
        this.loadClients(reload),
        this.loadAgencies(reload),
        this.loadAccounts(reload),
      ])

      if (content.every((it) => it != null)) this.isContentReady = true
      else this.contentLoadingError = "Content loading failed"
    } catch (e) {
      this.contentLoadingError = "Content loading failed"
    } finally {
      this.isContentLoading = false
    }
    return this.contentLoadingError
  }

  @action loadUsers = async (reload: boolean = false) => {
    const { userList } = this
    if (
      reload ||
      (userList.list == null && !userList.isLoading && !userList.error)
    ) {
      await userList.load()
    }
    return userList.list
  }

  @action loadClients = async (reload: boolean = false) => {
    const { clientStore } = this.root
    if (
      reload ||
      (clientStore.clientList == null &&
        !clientStore.isClientsLoading &&
        !clientStore.clientsListError)
    ) {
      await clientStore.loadClientList()
    }
    return clientStore.clientList
  }

  @action loadAgencies = async (reload: boolean = false) => {
    const { agencyStore } = this.root
    if (
      reload ||
      (agencyStore.agencyList == null &&
        !agencyStore.isAgencyListLoading &&
        !agencyStore.agencyListError)
    ) {
      await agencyStore.loadAgencyList()
    }
    return agencyStore.agencyList
  }

  @action loadAccounts = async (reload: boolean = false) => {
    const { accountStore } = this.root
    if (
      reload ||
      (accountStore.accountList == null &&
        !accountStore.isAccountListLoading &&
        !accountStore.accountListError)
    ) {
      await accountStore.loadAccountList()
    }
    return accountStore.accountList
  }
}

export default SettingsStore
