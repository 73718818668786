import React from "react"

import Typography from "../Typography/Typography"

export interface CounterProps {
  className?: string
  page: number
  pageSize: number
  total: number
}

export const Counter: React.FC<CounterProps> = ({
  page,
  total,
  pageSize,
  className,
}) => {
  const from = page * pageSize
  const to = total - from > pageSize ? from + pageSize : total
  return (
    <Typography type="h5" className={className}>
      {from + 1}-{to} of {total}
    </Typography>
  )
}

export default React.memo(Counter)
