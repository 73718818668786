import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"

import CampaignSegmentation from "../CampaignSegmentation/CampaignSegmentationTable"

import styles from "./CampaignPerformance.module.scss"

type CampaignPerformanceProps = {
  className?: string
}

const CampaignPerformance: React.FC<CampaignPerformanceProps> = observer(
  ({ className }) => (
    <div className={clsx(styles.root, className)}>
      <Typography type="h1" color="primary" weight="bold">
        Account Performance
      </Typography>
      <div className={styles.body}>
        {/* /// Campaign Segmentation /// */}
        <div className={styles.section}>
          <CampaignSegmentation />
        </div>
      </div>
    </div>
  )
)

export default CampaignPerformance
