import React from "react"
import { Prompt, useHistory, useLocation } from "react-router-dom"
import { useFormikContext } from "formik"
import { observer } from "mobx-react-lite"

import { Button } from "@components/ui/Button"
import Templates from "@components/ui/Templates"
import { ConfirmModalName } from "@components/modals/GlobalConfirmModal/type"
import { useStore } from "@store/index"
import { FormStepName } from "@store/manage-campaigns/validation"

const FormStepLayout: React.FC<{
  step: FormStepName
  className?: string
}> = observer(({ step, children, className }) => {
  const history = useHistory()
  const location = useLocation()

  const formik = useFormikContext<any>()

  const {
    accountStore: { accountId },
  } = useStore()

  const { editPMaxCampaignStore: store } = useStore()

  const handleSave = () => {
    history.push(`/google-ads/${accountId}/manage-campaign`, {
      isSaveInitiative: true,
    })
  }

  return (
    <div className={className}>
      <Prompt
        when={formik.dirty && !formik?.isSubmitting}
        message={(newLocation) => {
          if (location.pathname === newLocation.pathname) return true
          store.setPendingDraft(
            step,
            formik.values,
            (newLocation.state as any)?.isSaveInitiative
              ? "initiated"
              : "interrupt"
          )

          return ConfirmModalName.NEW_CAMPAIGN_DRAFT_WAS_NOT_SAVED
        }}
      />

      <Templates.Header
        offset="big"
        right={
          <Button
            disabled={!formik.dirty || store.isLoading}
            size="big"
            variant="contained"
            color="secondary"
            onClick={handleSave}
          >
            Save draft
          </Button>
        }
      />
      {children}
    </div>
  )
})

export default FormStepLayout
