/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react"
import clsx from "clsx"
import * as Yup from "yup"
import { FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import { ContractDetailsFormType } from "@framework/types/clientProfile"
import ContractDetailsForm from "./components/ContractDetailsForm/ContractDetailsForm"
import BrandGuidelines from "./components/BrandGuidelines/BrandGuidelines"

import styles from "./ContractDetails.module.scss"

export const clientContractDetailsValidationSchema = Yup.object().shape({
  contractStartDate: Yup.date().required("Required"),
  contractEndDate: Yup.date().required("Required"),
  yearlyAdBudget: Yup.number().required("Required"),
  contractInformation: Yup.string(),
})

export type ContractDetailsProps = {
  className?: string
}

const ContractDetails: React.FC<ContractDetailsProps> = observer(
  ({ className }) => {
    const {
      clientStore: { clientId, loading },
      clientProfileStore: {
        isEdit,
        clientContractDetails,
        clientUpdateLoading,
        updateClient,
        setIsEdit,
        setIsValid,
        submitFormCallback,
        setSubmitCallback,
      },
    } = useStore()

    const handleSubmit = useCallback(
      async (values: ContractDetailsFormType) => {
        if (clientId) {
          updateClient(clientId, values)
        }
      },
      [clientId, updateClient]
    )

    const formik = useFormik<ContractDetailsFormType>({
      initialValues: clientContractDetails,
      validateOnBlur: true,
      validationSchema: clientContractDetailsValidationSchema,
      onSubmit: handleSubmit,
    })

    const isTouched = useMemo(
      () => Object.keys(formik.touched).length,
      [formik.touched]
    )

    useEffect(() => {
      if (!clientUpdateLoading && submitFormCallback) {
        setIsEdit(false)
      }
    }, [clientUpdateLoading])

    useEffect(() => {
      if (isEdit) formik.resetForm({ values: clientContractDetails })
    }, [isEdit])

    useEffect(() => {
      if (isTouched) {
        setSubmitCallback(formik.submitForm)
      }
    }, [isTouched])

    useEffect(() => {
      setIsValid(formik.isValid)
    }, [formik.isValid, setIsValid])

    useEffect(() => () => setIsEdit(false), [setIsEdit])

    return (
      <FormikProvider value={formik}>
        <div className={clsx(styles.root, className)}>
          <Loader isLoading={loading || clientUpdateLoading} />
          <ContractDetailsForm />
          <BrandGuidelines />
        </div>
      </FormikProvider>
    )
  }
)

export default ContractDetails
