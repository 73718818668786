import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"

import { useController, useStore } from "@store/index"
import Box from "@components/ui/Box/Box"
import Stack from "@components/ui/Stack/Stack"
import Templates from "@components/ui/Templates"
import { Button } from "@components/ui/Button"
import { campaignSettingsValidationSchema as validationSchema } from "@store/manage-campaigns/validation/settings"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import Loader from "@components/ui/Loader/Loader"
import { FormStepName } from "@store/manage-campaigns/validation"
import FormStepLayout from "../FormStepLayout"
import LanguagesForm from "./LanguagesForm"
import LocationsForm from "./LocationsForm"
import MoreSettingsForm from "./MoreSettingsForm"

import styles from "./index.module.scss"

const STEP_NAME: FormStepName = "settings"

export type CampaignSettingsFormProps = {
  className?: string
  onSubmit?: React.MouseEventHandler
  onCancel?: React.MouseEventHandler
}

const CampaignSettingsForm: React.FC<CampaignSettingsFormProps> = observer(
  ({ onSubmit, onCancel, className }) => {
    const alert = useAlert()
    const { editPMaxCampaignStore: store } = useStore()
    const { editPMaxCampaignController: controller } = useController()

    const handleSubmit = async (form: any) => {
      if (formik.dirty) {
        const data = validationSchema.cast(form)
        const error = await controller.saveDraft(STEP_NAME, data, true)

        if (error == null) store.updateData(data)
        else {
          alert.error(
            <AlertMessage title="Failed to save draft" description={error} />
          )
          return
        }
      }

      onSubmit?.(form)
    }

    const formik = useFormik<Partial<any>>({
      initialValues: validationSchema.cast(store.data, {
        stripUnknown: true,
      }),
      validationSchema,
      onSubmit: handleSubmit,
    })

    React.useEffect(() => {
      if (!formik.values.languageIds.length)
        formik.setFieldValue("languageIds", [1000])
    }, [])

    return (
      <FormikProvider value={formik}>
        <FormStepLayout
          step={STEP_NAME}
          className={clsx(styles.root, className)}
        >
          <form onSubmit={formik.handleSubmit}>
            <Stack gutter="24" align="stretch">
              <Box className={styles.section}>
                <LocationsForm />
              </Box>

              <Box className={styles.section}>
                <LanguagesForm />
              </Box>

              <MoreSettingsForm />

              {store.isLoading ? (
                <Loader />
              ) : (
                <Templates.Header
                  right={
                    <>
                      <Button
                        size="big"
                        color="secondary"
                        onClick={onCancel}
                        disabled={store.isLoading}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        size="big"
                        disabled={store.isLoading}
                      >
                        Continue
                      </Button>
                    </>
                  }
                />
              )}
            </Stack>
          </form>
        </FormStepLayout>
      </FormikProvider>
    )
  }
)

export default CampaignSettingsForm
