import React from "react"

import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"

import styles from "./index.module.scss"

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => (
  <Templates.Header
    offset="none"
    className={styles.header}
    left={
      <Typography type="h2" bold>
        Selected Keywords
      </Typography>
    }
  />
)

export default Header
