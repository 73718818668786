import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { reportLevelDescription } from "@framework/constants/report"
import { CampaignReport } from "@store/reports/types"

type CampaignRowProps = {
  data: CampaignReport
  mapper: MapperType[]
}

const CampaignRow: React.FC<CampaignRowProps> = observer(
  ({ data: report, mapper }) => (
    <ClosableRow
      id={report.id}
      component={
        <TypedRow
          label={report?.label ?? "Campaign"}
          data={report}
          leftTip={<TypedTip data={reportLevelDescription.campaign} />}
          mapper={mapper}
        />
      }
    />
  )
)

export default CampaignRow
