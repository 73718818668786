import { useState } from "react"
import { PreviewMode } from "../types"

export const useHeaderControl = (defaultMode: PreviewMode = "desktop") => {
  const [mode, setMode] = useState<PreviewMode>(defaultMode)
  return {
    mode,
    onModeChange: setMode,
  }
}

export default useHeaderControl
