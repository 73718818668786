import React from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import OnHoverTooltip from "@components/ui/Tooltip/TooltipContainer/TooltipContainer"
import ComicBubble from "@components/ui/Tooltip/ComicBubble/ComicBubble"

import styles from "./UpdateMark.module.scss"

type DeletedMarkProps = {
  hide?: boolean
}

const DeletedMark: React.FC<DeletedMarkProps> = ({ hide }) => (
  <OnHoverTooltip
    className={clsx({
      [styles.hidden]: hide,
    })}
    toolTip={(props) => (
      <ComicBubble justify="end" {...props}>
        This item was deleted, yet can be restored before publish to Google
      </ComicBubble>
    )}
  >
    <Icon name="triangle" className={clsx(styles.mark, styles.deleted)} />
  </OnHoverTooltip>
)

export default DeletedMark
