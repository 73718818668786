/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import { IconName } from "./types"

import styles from "./Icon.module.scss"

export type IconProps = {
  name: IconName
  className?: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  style?: React.CSSProperties
  primary?: boolean
  circle?: boolean
  rotateAngle?: number
  rotateYAngle?: number
  rotateXAngle?: number
}

export const Icon: React.FC<IconProps> = ({
  name,
  className,
  onClick,
  style,
  primary,
  circle,
  rotateAngle = 0,
  rotateYAngle = 0,
  rotateXAngle = 0,
  ...rest
}) => (
  <i
    {...rest}
    style={{
      transform: `rotateX(${rotateXAngle}deg) rotateY(${rotateYAngle}deg) rotateZ(${rotateAngle}deg)`,
      ...style,
    }}
    onClick={onClick}
    className={clsx(
      "UIIcon-root",
      styles.icon,
      styles[`icon-${name}`],
      className,
      {
        [styles.circle]: circle,
        [styles.gradient]: primary,
        [styles.clickable]: !!onClick,
      }
    )}
  />
)

export default Icon
