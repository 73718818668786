import React from "react"

import Tabs from "@components/ui/Tabs/Tabs"
import { Option } from "@framework/types/types"

import styles from "./Menu.module.scss"

interface MenuProps {
  value: string
  options: Option[]
  onChange: (name: string) => void
}

export const Menu: React.FC<MenuProps> = ({ value, options, onChange }) => (
  <div className={styles.root}>
    <Tabs
      underlined
      direction="vertical"
      options={options}
      currentValue={value}
      onChange={({ value }) => onChange(value)}
    />
  </div>
)

export default Menu
