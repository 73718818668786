import React from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"

export const ContactUs: React.FC = observer(() => (
  <Stack gutter="16">
    <Typography type="h2">Contact us</Typography>

    <Stack gutter="8">
      <Typography type="subhead1" color="darkGray" upperCase>
        Email
      </Typography>
      <Typography>info@adoro.us</Typography>
    </Stack>
  </Stack>
))

export default ContactUs
