import React from "react"

import { SortByType } from "@framework/types/types"
import sortBy from "lodash/sortBy"

const useSortedList = <T>(props: {
  items: T[]
}): {
  items: T[]
  orderBy: SortByType
  sort: (name: string) => void
} => {
  const [orderBy, setOrderBy] = React.useState<SortByType>(() => ({
    value: "none",
    direction: false,
  }))

  const handleSort = (item: string) => {
    setOrderBy((current) => {
      const newValue = {
        value: item,
        direction: current.value === item && !current.direction,
      }
      return newValue
    })
  }

  const sortedItems = React.useMemo(() => {
    const res = sortBy(props.items, orderBy.value)
    return orderBy.direction ? res.reverse() : res
  }, [props.items, orderBy])

  return { items: sortedItems, orderBy, sort: handleSort }
}

export default useSortedList
