import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import HeaderColumn from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/AdCopyTable/HeaderColumn"
import { Option } from "@framework/types/types"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import { useTableSortContext } from "@framework/prototypes/TableSortContext"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import useCollapsible from "@framework/prototypes/Collapsable/useCollapsible"
import Column from "./Table/Column"
import Row from "./Table/Row"
import EditHeaderDropDown from "./EditHeaderDropDown"

import styles from "./InsightTable.module.scss"

interface Props {
  nameLabel?: string
  columns: MetricNameType[]
  options: MetricNameType[]
  onChangeColumn: (name: string, idx: number) => void
}

export const Header: React.FC<Props> = observer(
  ({ onChangeColumn, options, nameLabel = "Label", columns }) => {
    const sortingContext = useTableSortContext()

    const collapsibleContext = useCollapsible()

    const optionList: Option[] = React.useMemo(
      () =>
        options.map((name) => {
          const it = getMetricDescriptor(name)
          return {
            value: it.name,
            label: it.label,
          }
        }),
      []
    )

    const open = collapsibleContext.isAnyActive

    return (
      <Row className={styles.productRow}>
        <Column key="label">
          <IconButton
            title={open ? "Close All" : "Open All"}
            color={open ? "primary" : "secondary"}
            onClick={() => collapsibleContext.toggleAll()}
          >
            <Icon name="arrow-up-double" rotateXAngle={open ? 0 : 180} />
          </IconButton>
        </Column>

        <Column key="label">
          <Typography type="caption3" color="black60Color">
            <HeaderColumn
              name="label"
              value={sortingContext.sortBy}
              onSort={sortingContext.sort}
            >
              {nameLabel}
            </HeaderColumn>
          </Typography>
        </Column>

        {columns.map((name, idx) => {
          const it = getMetricDescriptor(name)
          return (
            <Column key={name}>
              <Typography type="caption3" color="black60Color">
                <HeaderColumn
                  name={it.name}
                  value={sortingContext.sortBy}
                  onSort={sortingContext.sort}
                >
                  <EditHeaderDropDown
                    value={it.name}
                    options={optionList}
                    onSelect={(name) => onChangeColumn(name, idx)}
                  />
                  {it.shortLabel}
                </HeaderColumn>
              </Typography>
            </Column>
          )
        })}
      </Row>
    )
  }
)

export default Header
