import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { ChannelReport } from "@store/reports/types"
import NoData from "@components/ui/NoData/NoData"
import { useTableContext } from "./TableContext"
import { sortReports } from "./utils"
import CampaignTypeRow from "./CampaignTypeRow"

interface CampaignTypeRowListProps {
  list: string[]
  mapper: MapperType[]
}

const CampaignTypeRowList: React.FC<CampaignTypeRowListProps> = observer(
  ({ list, mapper }) => {
    const {
      reports: { extendedReport },
    } = useStore()

    const context = useTableContext()

    const reports = useMemo(
      () =>
        sortReports(
          list
            .map((id) => extendedReport.cannelReports.get(id))
            .filter(Boolean) as ChannelReport[],
          context.sortBy
        ),
      [list, context.sortBy]
    )

    if (!reports.length) return <NoData>Not found</NoData>
    return (
      <>
        {reports.map((report) => (
          <CampaignTypeRow data={report} mapper={mapper} key={report.id} />
        ))}
      </>
    )
  }
)

export default CampaignTypeRowList
