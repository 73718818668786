/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Tooltip from "@components/ui/Tooltip/Tooltip"
import Typography, {
  ITypographyProps,
} from "@components/ui/Typography/Typography"
import { numberWithCommas } from "@components/utils/numberUtils"
import { KeywordCategoryGroup } from "@framework/types/opportunities"
import {
  transformEstimate,
  transformPointToChartData,
} from "@store/opportunities/opportunities.transformer"
import SimpleAreaChart from "@components/ui/Charts/AreaChart/SimpleAreaChart"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import { TableMapper } from "./Table"
import { formatCompetition } from "./utils"

import styles from "./Table.module.scss"

export interface RowProps {
  data: KeywordCategoryGroup
  mapper: TableMapper[]
  ctr?: number
}

export const KeywordsCategoryRow: React.FC<RowProps> = observer((props) => {
  const { data, ctr, mapper } = props

  const { attributeChartStore } = useOpportunitiesStore()

  const {
    id,
    name,
    displayName,
    avgCpc,
    graphPoints,
    avgSearchVolume,
    competition,
    keywords,
  } = data

  const { data: chartData } = useMemo(
    () => transformPointToChartData(graphPoints ?? []),
    [graphPoints]
  )

  const estimate = transformEstimate(data, ctr)

  const openChart = () => {
    attributeChartStore.showChart("category", id)
  }

  const renderColumn = (item: TableMapper) => {
    const { value: columnName, width } = item
    const style: React.CSSProperties = {
      width: width != null ? "auto" : "100%",
      minWidth: width,
      maxWidth: width,
    }
    const defaultProps = { style, key: columnName }
    switch (columnName) {
      case "name":
        return (
          <Column {...defaultProps} type="h2">
            <Tooltip lineClamp={2} text={displayName ?? name ?? "-"}>
              {displayName ?? name ?? "-"} • {keywords.length}
            </Tooltip>
          </Column>
        )

      case "avgSearchVolume":
        return (
          <Column {...defaultProps}>{numberWithCommas(avgSearchVolume)}</Column>
        )

      case "graphPoints":
        return (
          <Column {...defaultProps} color="gray">
            {chartData.length > 0 ? (
              <span onClick={openChart} className={styles.chartContainer}>
                <SimpleAreaChart data={chartData} />
              </span>
            ) : (
              <span className={styles.centered}>(No data)</span>
            )}
          </Column>
        )

      case "avgCpc":
        return (
          <Column {...defaultProps}>{`$${numberWithCommas(avgCpc, 2)}`}</Column>
        )
      case "estimate":
        return (
          <Column {...defaultProps}>{`$${numberWithCommas(estimate)}`}</Column>
        )

      case "competition":
        return (
          <Column {...defaultProps}>{formatCompetition(competition)}</Column>
        )

      default:
        return <Column {...defaultProps} />
    }
  }

  return <div className={styles.body}>{mapper.map(renderColumn)}</div>
})

export default KeywordsCategoryRow

interface ColumnProps extends ITypographyProps {}

const Column: React.FC<ColumnProps> = ({ children, className, ...rest }) => (
  <Typography type="h3" className={clsx(styles.column, className)} {...rest}>
    {children}
  </Typography>
)
