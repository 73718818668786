import React from "react"
import { observer } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import Label from "@components/ui/CheckBox/Label/Label"
import Typography from "@components/ui/Typography/Typography"
import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import { numberWithCommas } from "@utils/numberUtils"
import { TableMapperItem } from "@framework/types/types"
import { Button } from "@components/ui/Button"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import { useKeywordInsightsContext } from "@pages/Opportunities/KeywordInsightsContext"
import { RelatedQuestionSetName } from "@framework/types/opportunities"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { mbNoData } from "@services/utils"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import NoData from "@components/ui/NoData/NoData"
import config from "@root/config"
import InlineContextMenu from "./InlineContextMenu"
import KeywordAnswerFormBox from "./KeywordAnswerFormBox"
import { useKeywordManagementContext } from "./KeywordManagementContext"

import styles from "./QuestionsList.module.scss"

const hideAnswers = !!config.DISABLE_OPPORTUNITIES_ANSWERS

interface QuestionsListProps {}

export const QuestionsList: React.FC<QuestionsListProps> = observer(() => {
  const [editedAnswer, setEditedAnswer] = React.useState<string | null>(null)

  const {
    store: { relatedQuestionsStore },
  } = useKeywordInsightsContext()

  const managerContext = useKeywordManagementContext()

  const collection = relatedQuestionsStore.getCollectionBySetName(
    managerContext.questionsDetailsSetName ??
      relatedQuestionsStore.questionSetNames[0]
  )

  const tableMapper: TableMapperItem[] = React.useMemo(() => {
    const res = [
      {
        name: "searchVolume",
        label: "Search Vol.",
        width: "100px",
      },
      {
        name: "cpc",
        label: "CPC",
        width: "55px",
      },
      {
        name: "question",
        label: "Question",
      },
    ]

    if (!hideAnswers)
      res.push({
        name: "action",
        label: "",
        width: "135px",
      })

    return res
  }, [])

  const cpcDesc = getMetricDescriptor("AverageCpc")

  const { data } = collection
  const { isLoading } = relatedQuestionsStore

  return (
    <div className={styles.root}>
      <Templates.Header
        offset="small"
        left={
          <>
            <Label text="Question word:" textPosition="before">
              <InlineContextMenu
                options={relatedQuestionsStore.questionSetNames}
                value={
                  managerContext.questionsDetailsSetName ??
                  relatedQuestionsStore.questionSetNames[0]
                }
                onChange={(name) =>
                  managerContext.showQuestionDetails(
                    name as RelatedQuestionSetName
                  )
                }
              />
            </Label>

            {!hideAnswers && (
              <Label text="Answers:" textPosition="before">
                <InlineContextMenu
                  options={answerTypeOption}
                  value={answerTypeOption[0]}
                />
              </Label>
            )}
          </>
        }
      />

      <ScrollContainer className={styles.scrollable}>
        <Typography type="body1">
          <Table hideBorder hideHorizontalOffset fixedLayout spacing="small">
            <Row>
              {!hideAnswers && (
                <Column variant="header" width="30px" key="checkbox">
                  <CheckBox />
                </Column>
              )}

              {tableMapper.map((it) => (
                <Column variant="header" width={it.width} key={it.name}>
                  <Typography type="caption2" color="black60Color">
                    {it.label}
                  </Typography>
                </Column>
              ))}
            </Row>

            {data.length === 0 ? (
              isLoading ? (
                <CircleLoader />
              ) : (
                <NoData>No Questions found </NoData>
              )
            ) : (
              data.map((it) => (
                <React.Fragment key={it.id}>
                  <Row key={it.id}>
                    {!hideAnswers && (
                      <Column key="checkbox">
                        <CheckBox />
                      </Column>
                    )}

                    <Column align="center" vAlign="top">
                      <Typography type="inherit" weight="semibold">
                        {mbNoData(it.searchVolume, numberWithCommas)}{" "}
                      </Typography>
                    </Column>

                    <Column vAlign="top">
                      <Typography type="inherit" weight="semibold">
                        {mbNoData(it.cpc, cpcDesc.formatter)}
                      </Typography>
                    </Column>

                    <Column vAlign="top">{it.description}</Column>

                    {!hideAnswers && (
                      <Column vAlign="top" align="end">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => setEditedAnswer(it.id)}
                        >
                          Generate answer
                        </Button>
                      </Column>
                    )}
                  </Row>

                  {editedAnswer === it.id && (
                    <Row>
                      <Column colSpan={tableMapper.length + 1}>
                        <KeywordAnswerFormBox
                          onClose={() => setEditedAnswer(null)}
                        />
                      </Column>
                    </Row>
                  )}
                </React.Fragment>
              ))
            )}
          </Table>
        </Typography>
      </ScrollContainer>
    </div>
  )
})

export default QuestionsList

const answerTypeOption = ["None", "Some"]
