import React from "react"

import { ViewCellProps } from "../../types"
import ImagesViewColumn from "../ImageCarusele/ImagesColumn"

export const ImagesCell: React.FC<ViewCellProps> = React.memo(
  ({ name, data }) => {
    const value = data[name]
    if (!value || !Array.isArray(value)) return null
    return (
      <ImagesViewColumn
        list={value}
      >{`+${value.length} Images`}</ImagesViewColumn>
    )
  }
)

export default ImagesCell
