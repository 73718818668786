/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import styles from "./index.module.sass"

export type HeaderSize = "auto" | "small" | "medium" | "big"

export interface TripleHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  left?: React.ReactNode
  right?: React.ReactNode
  height?: HeaderSize
}

export const TripleHeader: React.FC<TripleHeaderProps> = ({
  left,
  right,
  height = "auto",
  className,
  children,
  ...rest
}) => (
  <section
    className={clsx(styles.root, styles[`height-${height}`], className)}
    {...rest}
  >
    <div className={styles.left}>{left}</div>
    <div className={styles.center}>{children}</div>
    <div className={styles.right}>{right}</div>
  </section>
)

export default TripleHeader
