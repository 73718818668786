import React from "react"
import { observer } from "mobx-react-lite"
import { CampaignStatusType } from "@framework/types/account"
import Select from "@components/ui/DropDown"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import { ButtonColorType } from "@components/ui/Button/types"
import useKeywordStatusOptionList from "./useCampaignStatusOptionList"

const statusOptions: CampaignStatusType[] = ["ENABLED", "PAUSED"]

export interface StatusProps {
  status: CampaignStatusType
  loading?: boolean
  editable?: boolean
  onChange: (value: CampaignStatusType) => void
}

export const StatusCell: React.FC<StatusProps> = observer(
  ({ status, loading, editable = true, onChange }) => {
    const statusOptionProps = useKeywordStatusOptionList(statusOptions)

    const activeOptions = statusOptionProps.statusMapper(status)

    if (status === "REMOVED" || !editable)
      return statusOptionProps.renderValue(status)

    return (
      <Select.DropDown
        disabled={loading}
        target={(active) => (
          <Chip
            type="outlined"
            color={(activeOptions?.color as ButtonColorType) ?? "default"}
            endIcon={<Icon name="arrow-down" rotateAngle={active ? 180 : 0} />}
          >
            {statusOptionProps.renderValue(status)}
          </Chip>
        )}
      >
        <Select.Box>
          <Select.ItemsList>
            {statusOptionProps.options.map((it) => (
              <Select.Option
                onClick={() => onChange(it as CampaignStatusType)}
                isSelected={it === status}
                key={it}
              >
                {statusOptionProps.renderValue(it)}
              </Select.Option>
            ))}
          </Select.ItemsList>
        </Select.Box>
      </Select.DropDown>
    )
  }
)

export default StatusCell
