import { action, computed, observable } from "mobx"

import { MetricNameType } from "@framework/types/metrics"
import reportsService from "@services/reports.service"
import { ID } from "@framework/types/types"
import MetricSequence from "./metrics-sequence.store"
import { MetricOption } from "./types"

export class ReportSettingsStore {
  constructor() {
    this.editableSequence = new MetricSequence([])
    this.sequence = new MetricSequence([])
  }

  @observable defaultSettings: MetricOption[] = []

  @observable editableSequence: MetricSequence

  @observable sequence: MetricSequence

  @observable isSettingsShown: boolean = false

  @observable isLoading: boolean = false

  @observable error: string | null = null

  @computed get hasDifferent() {
    const oldSequence = this.sequence.allActive
    const newSequence = this.editableSequence.allActive
    return !(
      oldSequence.length === newSequence.length &&
      oldSequence.every((it, idx) => newSequence[idx] === it)
    )
  }

  @action loadUserReportTableSettings = async (userId: ID) => {
    try {
      this.isLoading = true
      this.error = null

      const [defaultSettings, userSettings] = await Promise.all([
        reportsService.getReportsTableSettings().then(({ data }) => data.data),
        reportsService
          .getUserReportsTableSettings(userId)
          .then(({ data }) => data.data),
      ])

      const defaults = initOptions(defaultSettings)
      const userOptions = zipOptions(defaults, userSettings?.settings)

      this.sequence = new MetricSequence(userOptions)
      this.editableSequence = new MetricSequence(userOptions)
      this.defaultSettings = defaults
    } catch (error) {
      this.error = "Unexpected error"
    } finally {
      this.isLoading = false
    }
    return this.error
  }

  @action applyChange = async (userId: ID) => {
    try {
      this.isLoading = true
      this.error = null

      const newSequence = this.editableSequence.sequence.map((name) => {
        const res = this.editableSequence.metrics.get(name)
        if (!res) throw new Error("Sequence corrupted")
        return res
      })

      const response = await reportsService
        .updateUserReportsTableSettings(userId, newSequence)
        .then(({ data }) => data.data)

      this.sequence = new MetricSequence(
        response?.settings ?? this.defaultSettings
      )
      this.editableSequence = new MetricSequence(
        response?.settings ?? this.defaultSettings
      )
    } catch (error) {
      this.error = "Failed to apply new table settings"
    } finally {
      this.isLoading = false
    }
    return this.error
  }

  @action cancelChange = () => {
    this.editableSequence = new MetricSequence(this.sequence)
  }

  @action showSettings = (value: boolean) => {
    this.isSettingsShown = value
  }

  @action resetAll = async (userId: ID) => {
    try {
      this.isLoading = true
      this.error = null
      await reportsService
        .updateUserReportsTableSettings(userId, this.defaultSettings)
        .then(({ data }) => data.data)

      this.sequence = new MetricSequence(this.defaultSettings)
      this.editableSequence = new MetricSequence(this.defaultSettings)
    } catch (error) {
      this.error = "Failed to reset table settings"
    } finally {
      this.isLoading = false
    }
    return this.error
  }
}

export default ReportSettingsStore

const initOptions = (defaultSequence: MetricNameType[] = []): MetricOption[] =>
  defaultSequence.map((it) => ({ name: it, isEnabled: true }))

const zipOptions = (
  defaultSequence: MetricOption[] = [],
  userSettings: MetricOption[] = []
): MetricOption[] => {
  const userSet = userSettings.map((it) => it.name)
  const leftovers = defaultSequence.reduce<MetricOption[]>((acc, it) => {
    if (!userSet.includes(it.name)) acc.push({ ...it })
    return acc
  }, [])
  return [...userSettings, ...leftovers]
}
