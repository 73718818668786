import React from "react"
import OpportunitiesStore from "./opportunities"
import OpportunitiesController from "./opportunities.controller"
import DemoOpportunitiesController from "./demo-opportunities.controller"

export interface ControllerContext {
  opportunities: DemoOpportunitiesController | OpportunitiesController
}

export interface ContextProps {
  store: OpportunitiesStore
  controller: ControllerContext
}

const StoreContext = React.createContext<ContextProps | null>(null)

// StoreProvider to inject root store using react context
export const OpportunitiesStoreProvider: React.FC<ContextProps> = ({
  children,
  store,
  controller,
}): React.ReactElement => (
  <StoreContext.Provider value={{ store, controller }}>
    {children}
  </StoreContext.Provider>
)

// Hook to use store in any functional component
export const useOpportunitiesStore = (): OpportunitiesStore => {
  const context = React.useContext(StoreContext)
  if (context == null) throw new Error("Can't find Opportunities Store context")
  return context.store
}

export const useOpportunitiesController = (): ControllerContext => {
  const context = React.useContext(StoreContext)
  if (context == null)
    throw new Error("Can't find Opportunities Controller context")
  return context.controller
}
