import moment from "moment"
import min from "lodash/min"
import max from "lodash/max"
import { action, computed, observable, reaction } from "mobx"

import { YEAR_MONTH_KEY_FORMAT } from "@framework/constants/moment-format"
import { initArrayByLength } from "@components/utils/numberUtils"
import CheckbookStore from "./checkbook"

export class CheckbookTimeline {
  // INJECTIONS

  @observable checkbookStore: CheckbookStore

  // STATE

  @observable currentMonthFormatted: string // YYYY-MM

  @observable nextYearEndFormatted: string // YYYY-MM

  @observable activeYearIndex: number = 0

  // CONSTRUCTOR

  constructor(checkbookStore: CheckbookStore) {
    this.checkbookStore = checkbookStore

    this.currentMonthFormatted = moment
      .utc()
      .startOf("month")
      .format(YEAR_MONTH_KEY_FORMAT)

    this.nextYearEndFormatted = moment
      .utc()
      .add(1, "year")
      .endOf("year")
      .startOf("month")
      .format(YEAR_MONTH_KEY_FORMAT)
    // TODO

    reaction(
      () => this.years,
      (years) => {
        const currentYear = moment().year()
        const currentYearIndex = years.findIndex(
          (itYear) => itYear === currentYear
        )
        this.setActiveYearIndex(
          currentYearIndex < 0 ? this.years.length - 1 : currentYearIndex
        )
      }
    )
  }

  // COMPUTED

  @computed private get actualCampaignDate() {
    return (
      this.checkbookStore.campaignDate ||
      this.checkbookStore.currentCampaignDate
    )
  }

  @computed private get checkbookFormattedDatesList() {
    const data = this.checkbookStore.checkbookData
    return data ? Object.keys(data) : []
  }

  @computed get editablePeriodStartFormatted() {
    const campaignDateFormatted = moment
      .utc(this.actualCampaignDate)
      .format(YEAR_MONTH_KEY_FORMAT)

    return (
      max([campaignDateFormatted, this.currentMonthFormatted]) ??
      this.currentMonthFormatted
    )
  }

  @computed get editablePeriodEndFormatted() {
    const campaignEndFormatted = moment
      .utc(this.actualCampaignDate)
      .add(1, "year")
      .endOf("year")
      .format(YEAR_MONTH_KEY_FORMAT)

    return (
      max([campaignEndFormatted, this.nextYearEndFormatted]) ??
      this.nextYearEndFormatted
    )
  }

  @computed get viewPeriodStartFormatted() {
    return (
      min([
        ...this.checkbookFormattedDatesList,
        this.editablePeriodStartFormatted,
      ]) ?? this.editablePeriodStartFormatted
    )
  }

  @computed get viewPeriodEndFormatted() {
    return (
      max([this.nextYearEndFormatted, this.editablePeriodEndFormatted]) ??
      this.nextYearEndFormatted
    )
  }

  @computed get viewablePeriod() {
    return {
      start: this.viewPeriodStartFormatted,
      end: this.viewPeriodEndFormatted,
    }
  }

  @computed get editablePeriod() {
    return {
      start: this.editablePeriodStartFormatted,
      end: this.editablePeriodEndFormatted,
    }
  }

  @computed get years(): number[] {
    const startYear = moment
      .utc(this.viewablePeriod.start, YEAR_MONTH_KEY_FORMAT)
      .startOf("month")
      .year()
    const endYear = moment
      .utc(this.viewablePeriod.end, YEAR_MONTH_KEY_FORMAT)
      .startOf("month")
      .year()

    const yearsCount = Math.abs(startYear - endYear) + 1

    return initArrayByLength(yearsCount).map((idx) => startYear + idx)
  }

  @computed get activeYear(): number | undefined {
    return this.years[this.activeYearIndex]
  }

  // ACTIONS
  @action setActiveYearIndex = (index: number) => {
    this.activeYearIndex = index
  }
}

export default CheckbookTimeline
