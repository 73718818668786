import React from "react"
import clsx from "clsx"

import styles from "./Container.module.scss"

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  embedded?: boolean
}

export const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, embedded = false, children }, ref) => (
    <div
      className={clsx(styles.root, { [styles.embedded]: embedded }, className)}
      ref={ref}
    >
      {children}
    </div>
  )
)

export default Container
