import React, { CSSProperties, useLayoutEffect, useRef, useState } from "react"

export type Alignment = "start" | "end"
export type Placement = "top" | "right" | "bottom" | "left"
export type PopperPlacement = `${Placement}-${Alignment}`

export interface ContainerProps {
  placement?: PopperPlacement
  disabled?: boolean
  defaultValue?: boolean
}

export const usePopper = (
  containerRef: HTMLElement | null,
  popperRef: HTMLElement | null,
  {
    placement = "bottom-start",
    disabled = false,
    defaultValue = false,
  }: ContainerProps = {}
) => {
  const [isOpened, setIsOpened] = useState<boolean>(defaultValue)

  const [pos, setPos] = useState<CSSProperties>({ position: "fixed" })

  useLayoutEffect(() => {
    if (!containerRef || !popperRef || !isOpened || disabled) return undefined

    const handleScroll = () => {
      if (!containerRef) return
      const contRect = containerRef.getBoundingClientRect()
      const popRect = popperRef.getBoundingClientRect()

      const [place, align] = placement.split("-")

      setPos({
        position: "fixed",
        ...calcPosition(place, align, contRect, popRect),
      })
    }

    handleScroll()
    document.addEventListener("scroll", handleScroll, true)
    window.addEventListener("resize", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", handleScroll)
    }
  }, [placement, containerRef, popperRef, isOpened, disabled])

  useLayoutEffect(() => {
    if (!containerRef || disabled) return undefined

    if (!isOpened) {
      const handleClick = (e: Event) => {
        setIsOpened(true)
      }
      containerRef.addEventListener("click", handleClick)
      return () => containerRef.removeEventListener("click", handleClick)
    }

    if (!popperRef) return undefined

    const handleClick = (e: Event) => {
      if (
        !containerRef.contains(e.target as Node) &&
        !popperRef.contains(e.target as Node)
      ) {
        setIsOpened(false)
      }
    }
    document.addEventListener("mouseup", handleClick)
    return () => document.removeEventListener("mouseup", handleClick)
  }, [containerRef, popperRef, isOpened, disabled])

  useLayoutEffect(() => {
    if (disabled) setIsOpened(false)
    else setIsOpened(defaultValue)
  }, [disabled])

  const handleToggleRef = useRef((e: React.MouseEvent, value?: boolean) => {
    setIsOpened((prev) => value ?? !prev)
    e.preventDefault()
    e.stopPropagation()
  })

  return {
    isActive: isOpened,
    style: pos as CSSProperties,
    toggle: handleToggleRef.current,
  }
}

export default usePopper

const calcPosition = (
  placement = "bottom",
  alignment = "start",
  contRect: DOMRect,
  popRect: DOMRect
) => {
  const viewPortHeight = document.documentElement.clientHeight
  const viewPortWidth = document.documentElement.clientWidth

  const params: CSSProperties = {
    maxHeight: viewPortHeight / 2,
  }

  const isPlacementAvailable = canDraw(placement, contRect, popRect)

  if (placement === "top" || placement === "bottom") {
    // horizontal
    if (isPlacementAvailable ? placement === "top" : placement === "bottom") {
      params.top = contRect.top - popRect.height
      params.paddingBottom = 4
    } else {
      params.top = contRect.bottom
      params.paddingTop = 4
    }

    // vertical
    if (alignment === "start") {
      params.left = contRect.left
      params.minWidth = contRect.width
      params.width = "min-content"
    } else {
      params.right = viewPortWidth - contRect.right
      params.minWidth = contRect.width
      params.width = "min-content"
    }
  } else if (placement === "right" || placement === "left") {
    // vertical
    if (isPlacementAvailable ? placement === "right" : placement === "left") {
      params.paddingLeft = 4
      params.left = contRect.left + contRect.width
    } else {
      params.paddingRight = 4
      params.left = contRect.left - popRect.width
    }

    // horizontal
    if (alignment === "start") {
      params.top = contRect.top
    } else {
      params.top = contRect.top + contRect.height - popRect.height
    }
  }

  return params
}

const canDraw = (placement = "bottom", contRect: DOMRect, popRect: DOMRect) => {
  const viewPortHeight = document.documentElement.clientHeight
  const viewPortWidth = document.documentElement.clientWidth

  if (placement === "top") return contRect.top - popRect.height > 0
  if (placement === "bottom")
    return contRect.bottom + popRect.height < viewPortHeight
  if (placement === "left") return contRect.left - popRect.width > 0
  if (placement === "right")
    return contRect.left + contRect.width + popRect.width < viewPortWidth

  return true
}
