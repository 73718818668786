import React from "react"
import { observer } from "mobx-react-lite"
import { action, observable } from "mobx"

import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import NoData from "@components/ui/NoData/NoData"
import {
  AdCopyDetailsData,
  AdCopyPrompts,
  EditAdCopyData,
} from "@framework/types/adCopy"
import PlaceholderLoader from "@components/ui/Loader/PlaceholderLoader"
import { FormMetaContextProvider } from "@framework/prototypes/FormMetaContext"
import { DeepPartial } from "@framework/types/utils"
import AccountStore from "@store/account/account"
import { useStore } from "@store/index"
import ManageCampaignsStore from "@store/manage-campaigns/manage-campaigns"
import EditAdCopyTask from "@store/manage-campaigns/EditAdCopyTask"
import AdCopyEditor from "./EditAdCopyForm"
import {
  extractModifiedFieldsList,
  mergeAdCopiesData,
  mergeAdCopiesPromptsData,
} from "./utils"
import { EditAdCopyContext, IEditAdCopyContext } from "./EditAdCopyContext"

import styles from "./EditCopySidebar.module.scss"

export interface EditGoogleAdCopySidebarProps {
  isOpened?: boolean
  task: EditAdCopyTask
}

export const EditGoogleAdCopySidebar: React.FC<EditGoogleAdCopySidebarProps> =
  observer(({ isOpened = false, task }) => {
    const { accountStore, manageCampaignStore } = useStore()

    const data = task.adCopyDetails

    const isReadyForEdit = data != null

    const editContext = React.useMemo(
      () =>
        new EditLocalAdCopyContext({
          task,
          accountStore,
          manageCampaignStore,
        }),
      [accountStore, manageCampaignStore, task]
    )

    const [context, setContext] = React.useState<{
      initialData?: DeepPartial<AdCopyDetailsData>
      prompts?: AdCopyPrompts | undefined
      timestamp: number
    }>({ timestamp: Date.now() })

    React.useEffect(() => {
      if (data == null) {
        setContext({
          timestamp: Date.now(),
        })
        return
      }

      const initialData = mergeAdCopiesData(data)
      const prompts = mergeAdCopiesPromptsData(data)

      setContext({
        initialData,
        prompts,
        timestamp: Date.now(),
      })
    }, [data])

    const wasEdited = React.useMemo(
      () => extractModifiedFieldsList(data),
      [data]
    )

    return (
      <EditAdCopyContext.Provider value={editContext}>
        <FormMetaContextProvider editedFields={wasEdited}>
          <SidebarContainer isOpen={isOpened} onClose={editContext.onClose}>
            {isReadyForEdit ? (
              <AdCopyEditor
                initialValues={context.initialData}
                initialPrompts={context.prompts}
                adCopyIds={[task.adCopyId]}
                withAISupport={false}
                key={context.timestamp}
              />
            ) : task.error != null ? (
              <div className={styles.noFoundContainer}>
                <NoData>Failed to load data</NoData>
              </div>
            ) : (
              <div className={styles.loaderContainer}>
                <PlaceholderLoader />
              </div>
            )}
          </SidebarContainer>
        </FormMetaContextProvider>
      </EditAdCopyContext.Provider>
    )
  })

export default EditGoogleAdCopySidebar

class EditLocalAdCopyContext implements IEditAdCopyContext {
  @observable readonly accountStore: AccountStore

  @observable readonly manageCampaignsStore: ManageCampaignsStore

  @observable readonly task: EditAdCopyTask

  constructor(options: {
    task: EditAdCopyTask
    manageCampaignStore: ManageCampaignsStore
    accountStore: AccountStore
  }) {
    this.task = options.task
    this.accountStore = options.accountStore
    this.manageCampaignsStore = options.manageCampaignStore
  }

  readonly isEdit = true

  readonly isBulk = false

  readonly submitLabel = "Save & Publish"

  getAccount = () => {
    const { accountId } = this.accountStore
    if (accountId == null) throw new Error("Account is not initialized yet")
    return accountId
  }

  @action onCreate = async () => "Action not supported"

  @action onUpdate = async (data: Partial<EditAdCopyData>) => {
    const res = await this.manageCampaignsStore
      .updateAdCopy(this.getAccount(), this.task.adCopyId, data)
      .catch(() => "Unexpected error")

    return res
  }

  @action onDelete = async () => {
    this.manageCampaignsStore.createDeleteAdCopyTask(this.task.adCopyId)
  }

  @action onClose = async () => {
    this.manageCampaignsStore.adCopyEditor.closeTask()
  }
}
