import React, { useMemo } from "react"

import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"
import { RecommendedKeyword } from "@framework/types/opportunities"
import { transformPointToChartData } from "@store/opportunities/opportunities.transformer"
import config from "@root/config"
import clsx from "clsx"
import SearchChart from "./SearchChart"
import TopQuestionsTable from "./TopQuestionsTable"
import TopKeywordsTable from "./TopKeywordsTable"
import RelatedTopicsSection from "./RelatedTopicsSection"

import styles from "./index.module.scss"

const hideDisconnected = !!config.DISABLE_DISCONNECTED_INSIGHTS

export const StatisticsSection: React.FC<{
  keyword: RecommendedKeyword | null
}> = ({ keyword = null }) => {
  const chartProps = useMemo(
    () => transformPointToChartData(keyword?.graphPoints ?? []),
    [keyword?.graphPoints]
  )

  return (
    <div className={styles.root}>
      <Templates.Header
        offset="medium"
        left={
          <Typography type="h1" weight="bold">
            Statistics
          </Typography>
        }
      />

      <div
        className={clsx(styles.body, {
          [styles.hideDisabled]: hideDisconnected,
        })}
      >
        <SearchChart className={styles.chart} {...chartProps} />

        {!hideDisconnected && (
          <RelatedTopicsSection className={styles.relatedTopics} />
        )}

        {!hideDisconnected && (
          <TopQuestionsTable className={styles.topQuestions} />
        )}

        <TopKeywordsTable className={styles.topKeywords} />
      </div>
    </div>
  )
}

export default StatisticsSection
