import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useHistory, useLocation } from "react-router-dom"

import { QuadSizeType } from "@components/utils/enums"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import Button from "@components/ui/Button/Button"
import BaseProgressCircle from "@components/ui/ProgressBar/BaseProgressCircle/BaseProgressCircle"
import Icon from "@components/ui/Icon/Icon"
import Stack from "@components/ui/Stack/Stack"

import styles from "./KeywordGenerationProgress.module.scss"

export type KeywordGenerationProgressProps = {
  className?: string
}

const INTERVAL_KEYWORDS_STATS_POLLING = 10_000

export const KeywordGenerationProgress: React.FC<KeywordGenerationProgressProps> =
  observer(({ className }) => {
    const { pathname } = useLocation()
    const history = useHistory()

    const {
      manageProfileStore: {
        keywordsGenerationStats,
        generatingStatusMessage: data,
        setShowBar,
        loadKeywordsGenerationStats,
      },
      accountStore: { accountId },
    } = useStore()

    const handleShowResult = () => {
      setShowBar(false)
      history.push("./opportunities")
    }

    useEffect(() => {
      if (keywordsGenerationStats) {
        if (accountId && keywordsGenerationStats.running) {
          const timer = setTimeout(() => {
            loadKeywordsGenerationStats(accountId)
          }, INTERVAL_KEYWORDS_STATS_POLLING)
          return () => clearTimeout(timer)
        }
      }
      return undefined
    }, [keywordsGenerationStats, accountId, loadKeywordsGenerationStats])

    const barSpeed = keywordsGenerationStats?.running ? "2s" : ".3s"

    return data ? (
      <div className={clsx(styles.root, className)}>
        <Stack
          direction="row"
          gutter="12"
          align="center"
          className={styles.column}
        >
          {data.isCompleted ? (
            <Icon name="checkbox-circle" className={styles.doneBadge} />
          ) : (
            <BaseProgressCircle
              value={data.percentage}
              size={QuadSizeType.tiny}
              transitionTime={barSpeed}
            />
          )}

          <Stack gutter="0">
            <Typography type="h3">{data.title}</Typography>
            <Typography type="h5">{data.message}</Typography>
          </Stack>

          {data.isCompleted && (
            <Button
              size="big"
              variant="outlined"
              color="primary"
              onClick={handleShowResult}
            >
              {pathname.includes("opportunities") ? "Close" : "Show result"}
            </Button>
          )}
        </Stack>
      </div>
    ) : null
  })

export default KeywordGenerationProgress
