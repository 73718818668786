import React from "react"
import clsx from "clsx"

import styles from "./BaseField.module.scss"

export interface BaseFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  valid?: boolean
  error?: boolean
  active?: boolean
  edited?: boolean
  disabled?: boolean
  before?: ((isActive: boolean) => React.ReactNode) | React.ReactNode
  after?: ((isActive: boolean) => React.ReactNode) | React.ReactNode
}

const BaseField = React.forwardRef<HTMLDivElement, BaseFieldProps>(
  (
    {
      valid,
      error,
      disabled = false,
      active = false,
      edited = false,
      before,
      after,
      children,
      className,
      ...rest
    },
    ref
  ) => {
    const beforeNode = typeof before === "function" ? before(active) : before
    const afterNode = typeof after === "function" ? after(active) : after

    return (
      <div
        ref={ref}
        className={clsx(
          "UIBaseField-root",
          styles.root,
          {
            [styles.valid]: valid,
            [styles.error]: error,
            [styles.active]: active,
            [styles.withAfter]: after != null,
            [styles.disabled]: disabled,
          },
          className
        )}
        {...rest}
      >
        {!!edited && (
          <span className={styles.edited} title="Field was edited" />
        )}
        {beforeNode != null && (
          <div className={styles.before}>{beforeNode}</div>
        )}
        {children}
        {afterNode != null && <div className={styles.after}>{afterNode}</div>}
      </div>
    )
  }
)

export default BaseField
