import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import IntroForm, { FormData } from "./IntroForm"
import { useDemoMultiformContext } from "./tasks/FormManager"

import styles from "./IntroSubForm.module.scss"

const IntroSubForm: React.FC = observer(() => {
  const formContext = useDemoMultiformContext()

  const handleSubmit = async (data: FormData) => {
    await formContext.saveIntro(data)
    formContext.startKeywordsIndexing()
    formContext.nextStep()
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Typography type="body1">
          Provides general information about the process and describes the
          outcome of the process (created campaign in Google).
        </Typography>

        <Typography type="body1">
          Here presenter can select a website we are going to run the process
          for.
        </Typography>
      </div>
      <div className={styles.body}>
        <IntroForm
          initialValues={formContext.introData}
          loading={formContext.isLoading}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  )
})

export default IntroSubForm
