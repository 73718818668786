import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { Button } from "@components/ui/Button"
import useToggle from "@framework/hooks/useToggle"
import Box from "@components/ui/Box/Box"
import { useKeywordInsightsContext } from "@pages/Opportunities/KeywordInsightsContext"
import Templates from "@components/ui/Templates"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import Typography from "@components/ui/Typography/Typography"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import RelatedTopicsTable from "./RelatedTopicsTable"
import RatedTopicsModal from "./RatedTopicsModal"

import styles from "./RelatedTopicsSection.module.scss"

const PREVIEW_COUNT = 5

export const RelatedTopicsSection: React.FC<{ className?: string }> = observer(
  ({ className }) => {
    const modal = useToggle()

    const {
      store: { relatedTopicsStore: store, keyword },
      controller,
    } = useKeywordInsightsContext()

    React.useEffect(() => {
      controller.loadRelatedTopics(keyword.id)
    }, [])

    const allItems = store.activeList
    const topItems = allItems.slice(0, PREVIEW_COUNT)

    return (
      <>
        <Box className={clsx(styles.root, className)}>
          <Templates.TripleHeader
            height="big"
            left={
              <Typography type="h3" weight="bold">
                Related Topics
              </Typography>
            }
            right={
              <Button
                size="big"
                color="secondary"
                onClick={modal.handleToggle}
                disabled={!allItems.length}
              >
                Show All ({allItems.length})
              </Button>
            }
          >
            <SimpleSelect
              value={store.activeGroup}
              options={store.options}
              onSelect={store.setActiveGroup}
              className={styles.dropdown}
            />
          </Templates.TripleHeader>

          {allItems.length ? (
            <RelatedTopicsTable items={topItems} />
          ) : store.isLoading ? (
            <Loader isLoading />
          ) : (
            <NoData>No Related Topics found</NoData>
          )}
        </Box>

        {allItems.length > 0 && (
          <RatedTopicsModal
            isOpen={modal.isOpened}
            onClose={modal.handleToggle}
          />
        )}
      </>
    )
  }
)

export default RelatedTopicsSection
