import React from "react"

import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Icon from "@components/ui/Icon/Icon"
import Label from "@components/ui/CheckBox/Label/Label"
import IconButton from "@components/ui/Button/IconButton"
import { FormikProvider, useFormik } from "formik"
import LimitLengthTextField from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/EditCopySidebar/LimitLengthTextField"
import InlineContextMenu from "./InlineContextMenu"

import styles from "./KeywordAnswerForm.module.scss"

interface KeywordAnswerFormProps {
  onSubmit?: (form: any) => void
  onClose?: () => void
}

export const KeywordAnswerForm: React.FC<KeywordAnswerFormProps> = ({
  onSubmit,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: {},
    onSubmit: (form) => {
      onSubmit?.(form)
      onClose?.()
      // TODO
    },
  })

  return (
    <FormikProvider value={formik}>
      <form className={styles.root} onSubmit={formik.handleSubmit}>
        <Templates.Header
          offset="small"
          left={
            <>
              <Label text="Tone of the answer:" textPosition="before">
                <InlineContextMenu options={toneOption} value={toneOption[0]} />
              </Label>

              <Label text="From:" textPosition="before">
                <InlineContextMenu
                  options={limitOption}
                  value={limitOption[0]}
                />
              </Label>

              <IconButton variant="contained" color="secondary">
                <Icon name="file-copy" />
              </IconButton>

              <IconButton variant="contained" color="secondary">
                <Icon name="download" />
              </IconButton>
            </>
          }
          right={
            <>
              <Button variant="contained" color="primary" type="submit">
                Regenerate
              </Button>

              {onClose != null && (
                <IconButton onClick={onClose}>
                  <Icon name="cross" />
                </IconButton>
              )}
            </>
          }
        />
        <LimitLengthTextField name="answer" maxChars={90} />
      </form>
    </FormikProvider>
  )
}

export default KeywordAnswerForm

const toneOption = ["Friendly", "Hostilely"]

const limitOption = [
  "Short (up to 25 words)",
  "Medium (up to 50 words)",
  "Long (up to 75 words)",
]
