import React, { useEffect, useState } from "react"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"

import styles from "./Header.module.scss"

export interface PreviewProps {
  title: string
  itemNumber?: number
  currentType: string
  onTypeChange: (type: string) => void
  onFrameChange: (dir?: "left" | "right") => void
}

const Header: React.FC<PreviewProps> = ({
  title,
  itemNumber = 0,
  currentType = "mobile",
  onTypeChange,
  onFrameChange,
}) => {
  const [isPlaying, setPlay] = useState(true)

  const togglePlay = () => setPlay((val) => !val)

  const handleSwipe = (dir: "left" | "right") => () => {
    setPlay(false)
    onFrameChange(dir)
  }

  useEffect(() => {
    if (!isPlaying) return undefined
    const interval = setInterval(() => {
      onFrameChange("right")
    }, 7_000)
    return () => {
      clearInterval(interval)
    }
  }, [itemNumber, isPlaying, onFrameChange])

  return (
    <div className={styles.root}>
      <Typography type="h2">{title}</Typography>
      <div className={styles.control}>
        <div className={styles.controlSection}>
          <Button
            className={styles.arrow}
            variant="ghost"
            color="secondary"
            onClick={handleSwipe("left")}
          >
            <Icon name="arrow-right" rotateYAngle={180} />
          </Button>
          <Button
            className={styles.arrow}
            variant="ghost"
            color="secondary"
            onClick={handleSwipe("right")}
          >
            <Icon name="arrow-right" />
          </Button>
          <Button variant="ghost" color="secondary" onClick={togglePlay}>
            <Icon name={isPlaying ? "pause" : "play"} />
          </Button>
        </div>
        <div className={styles.controlSection}>
          <Button
            variant="ghost"
            color={currentType === "mobile" ? "primary" : "secondary"}
            onClick={() => onTypeChange("mobile")}
          >
            <Icon name="mobile" />
          </Button>
          <Button
            variant="ghost"
            color={currentType === "desktop" ? "primary" : "secondary"}
            onClick={() => onTypeChange("desktop")}
          >
            <Icon name="display" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Header
