import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import { useStore } from "@store/index"
import OverallKPIs from "./components/OverallKPIs/OverallKPIs"
import HistoricalKPITrends from "./components/HistoricalKPITrends/HistoricalKPITrends"
import AdSpendBudget from "./components/AdSpendBudget/AdSpendBudget"
import CampaignPerformance from "./components/CampaignPerformance/CampaignPerformance"
import Filter from "./components/Filter/Filter"
// import TechInfo from "./components/TechInfo/TechInfo"

import styles from "./Dashboard.module.scss"

type DashboardProps = {
  className?: string
}

const Dashboard: React.FC<DashboardProps> = observer(({ className }) => {
  const { accountDashboardStore } = useStore()
  const { setDateRange } = accountDashboardStore

  return (
    <div className={clsx(styles.root, className)}>
      <ServiceDownInterceptor />
      <div className={styles.wrapper}>
        {/* <TechInfo /> */}
        <Filter onSubmit={setDateRange} />
      </div>
      <OverallKPIs />
      <HistoricalKPITrends />
      <AdSpendBudget />
      <CampaignPerformance />
    </div>
  )
})

export default Dashboard
