import React, { useCallback } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import { Legend } from "@components/ui/Charts/Legends/LegendList"
import { useStore } from "@store/index"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"

import styles from "./Header.module.scss"

const items = [
  campaignEntityDescription.campaign,
  campaignEntityDescription.groupAd,
]

export type HeaderProps = {
  className?: string
}

export const Header: React.FC<HeaderProps> = observer(({ className }) => {
  const { rulesStore } = useStore()
  const { setRuleToEdit } = rulesStore

  const handleCreate = useCallback(() => setRuleToEdit(null), [setRuleToEdit])

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.left}>
        {items.map((props) => (
          <Legend {...props} />
        ))}
      </div>
      <div className={styles.right}>
        <Button onClick={handleCreate}>Create Rule</Button>
      </div>
    </div>
  )
})

export default Header
