import React from "react"
import { observer } from "mobx-react-lite"

import { useHistory } from "react-router-dom"
import TableButton from "./TableButton"

export interface TableLinkProps {
  to: string
}

export const TableLink: React.FC<TableLinkProps> = observer(({ to }) => {
  const history = useHistory()
  return <TableButton onClick={() => history.push(to)}>RULE</TableButton>
})

export default TableLink
