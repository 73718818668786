import { observer } from "mobx-react-lite"
import React, { useState } from "react"

import FileUploadProgress from "@components/ui/ProgressBar/FileUploadProgress/FileUploadProgress"
import { useStore } from "@store/index"

type UploadingFileProps = {
  fileId: number
  clientId: number
  fileName: string
  fileIcon?: string
  className?: string
}

const UploadingFile: React.FC<UploadingFileProps> = observer(
  ({ clientId, fileId, fileName, fileIcon, className }) => {
    const {
      clientProfileStore: { getFileUploadProgress, deleteFile },
    } = useStore()

    const [progress, setProgress] = useState(100)

    const handleCancel = () => {
      if (!clientId || !fileId) return
      deleteFile(clientId, fileId)
    }

    // useEffect(() => {
    //   if (!clientId || !fileId) return undefined

    //   const updateFileProgress = () => {
    //     getFileUploadProgress(clientId, fileId).then((progress) => {
    //       if (progress < 0 || progress > 100) return
    //       setProgress(progress)
    //     })
    //   }
    //   const interval = setInterval(updateFileProgress, 10_000)
    //   return () => clearInterval(interval)
    // }, [clientId, fileId])

    return (
      <FileUploadProgress
        value={progress}
        fileName={fileName}
        fileExtension={fileIcon}
        className={className}
        onCancel={handleCancel}
      />
    )
  }
)

export default UploadingFile
