import React from "react"
import { action, computed, observable, reaction } from "mobx"

import ManageProfileStore from "@store/manageProfile/manageProfile"
import { delay } from "@utils/promise"
import { AssignedAttributes } from "@store/manageProfile/types"
import OpportunitiesStore from "@store/opportunities/opportunities"
import AccountStore from "@store/account/account"
import { FormData as IntroData } from "../IntroForm"
import KeywordScrappingTask from "./KeywordScrappingTask"
import KeywordsClusteringTask from "./KeywordsClusteringTask"
import KeywordsGenerationTask from "./KeywordsGenerationTask"
import { getAccountByURL } from "../utils"

export class DemoFormManager {
  @observable manageProfileStore: ManageProfileStore

  @observable opportunitiesStore: OpportunitiesStore

  @observable accountStore: AccountStore

  constructor(injections: {
    accountStore: AccountStore
    manageProfileStore: ManageProfileStore
    opportunitiesStore: OpportunitiesStore
  }) {
    this.accountStore = injections.accountStore
    this.manageProfileStore = injections.manageProfileStore
    this.opportunitiesStore = injections.opportunitiesStore

    reaction(
      () => this.introData,
      () => {
        this.keywordScrappingTask = undefined
      }
    )
    reaction(
      () => this.keywordScrappingTask,
      () => {
        this.keywordClusteringTask = undefined
      }
    )
    reaction(
      () => this.keywordClusteringTask,
      () => {
        this.attributes = undefined
      }
    )
    reaction(
      () => this.attributes,
      () => {
        this.keywordGenerationTask = undefined
      }
    )
    reaction(
      () => this.keywordGenerationTask,
      () => {
        this.isCampaignPublished = false
      }
    )
  }

  @observable activeStep: number = 0

  @observable introData?: IntroData

  @observable attributes?: AssignedAttributes

  @observable isLoading: boolean = false

  @observable keywordScrappingTask?: KeywordScrappingTask

  @observable keywordClusteringTask?: KeywordsClusteringTask

  @observable keywordGenerationTask?: KeywordsGenerationTask

  @observable isCampaignPublished = false

  @action setActiveStep = (nextStep: number) => {
    this.activeStep = nextStep
  }

  @action nextStep = () => {
    this.activeStep += 1
  }

  @action saveIntro = async (data: IntroData) => {
    try {
      this.isLoading = true
      this.introData = data

      this.accountStore.setAccountId(
        getAccountByURL(data.website)?.accountId ?? 10
      )

      await delay(500)

      this.isLoading = false
    } catch (e) {
      console.log(e)
    }
  }

  @action saveAttributes = async (data: AssignedAttributes) => {
    try {
      this.isLoading = true
      this.attributes = data

      await delay(500)

      this.isLoading = false
    } catch (e) {
      console.log(e)
    }
  }

  @action startKeywordsIndexing = () => {
    if (!this.introData?.website) throw new Error("Intro form invalid")

    this.keywordScrappingTask = new KeywordScrappingTask({
      websiteName: this.introData?.website!,
    })
    this.keywordScrappingTask.runTask()
  }

  @action startKeywordsClustering = () => {
    const { totalCategories, totalAttributes } = this.manageProfileStore
    const { keywordsList } = this.opportunitiesStore

    this.keywordClusteringTask = new KeywordsClusteringTask({
      totalCategories,
      totalAttributes,
      totalKeywordsGenerated: keywordsList.totalKeywords,
    })
    this.keywordClusteringTask.runTask()
  }

  @action startKeywordsGeneration = () => {
    const { keywordsList } = this.opportunitiesStore

    this.keywordGenerationTask = new KeywordsGenerationTask({
      totalKeywordsGenerated: keywordsList.totalKeywords,
    })

    this.keywordGenerationTask.runTask()
  }

  @action publishCampaign = () => {
    this.isCampaignPublished = true
  }

  @computed get isIntroValid() {
    return this.introData != null
  }

  @computed get isKeywordsScrappingValid() {
    return !!this.keywordScrappingTask?.isCompleted
  }

  @computed get isKeywordsClusteringValid() {
    return !!this.keywordClusteringTask?.isCompleted
  }

  @computed get isAttributesValid() {
    return !!this.attributes?.size
  }

  @computed get isKeywordsGenerationValid() {
    return !!this.keywordGenerationTask?.isCompleted
  }
}

export default DemoFormManager

export const DemoMultiformContext = React.createContext<DemoFormManager | null>(
  null
)

export const useDemoMultiformContext = () => {
  const context = React.useContext(DemoMultiformContext)
  if (context === null) throw new Error("No DemoMultiformContext found")
  return context
}
