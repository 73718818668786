import { observable } from "mobx"

import { CampaignReportType } from "@framework/types/account"
import TopCampaignStore from "./top-campaigns.store"
import TopKeywordsStore from "./top-keywords.store"
import KeywordsStore from "./keywords.store"
import RevenueProductsStore from "./revenue-products.store"
import PMaxProductsStore from "./pmax-products.store"
import ProductCampaignStore from "./product-campaigns.store"
import TopSearchQueriesStore from "./top-search-queries.store"
import QueriesStore from "./queries.store"

class CampaignInsightsStore {
  // INJECTIONS

  @observable topCampaignStore: TopCampaignStore<CampaignReportType>

  @observable topKeywordsStore: TopKeywordsStore

  @observable revenueProductsStore: RevenueProductsStore

  @observable topQueriesStore: TopSearchQueriesStore

  // CONSTRUCTOR

  constructor() {
    this.topCampaignStore = new TopCampaignStore()

    this.topKeywordsStore = new TopKeywordsStore({
      campaignStore: new TopCampaignStore(),
      keywordsStore: new KeywordsStore(),
    })

    this.revenueProductsStore = new RevenueProductsStore({
      campaignStore: new ProductCampaignStore(),
      productsStore: new PMaxProductsStore(),
    })

    this.topQueriesStore = new TopSearchQueriesStore({
      campaignStore: new ProductCampaignStore(),
      queriesStore: new QueriesStore(),
    })
  }

  // STATE

  // ACTIONS
}

export default CampaignInsightsStore
