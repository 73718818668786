import React from "react"
import isUndefined from "lodash/isUndefined"

import { MapperType } from "@components/ui/WaterfallTable/types"
import { AdsReportType, CampaignStatusType } from "@framework/types/account"
import KPIsMapper from "./kpi-table-mapper"
import AdStatus from "./AdStatus"
import DefaultStatusCell from "./DefaultStatusCell"
import Status from "./Status"

export const getMapper = (): MapperType[] => {
  const list: MapperType[] = []

  return list.concat([
    {
      name: "Status",
      label: "Status",
      widthBasis: "45px",
      sortable: true,
      renderCallback: (value: CampaignStatusType, data: any, name) =>
        !isUndefined(value) && (
          <Status
            name={name}
            value={value}
            data={data}
            entityType="ad"
            campaignId={data.CampaignId}
            adGroupId={data.AdGroupId}
            editable={data.editable}
            adId={data.Id}
            key={data.Id}
          />
        ),
    },
    {
      name: "CampaignName",
      label: "Campaign",
      sortable: true,
      widthBasis: "120px",
    },
    {
      name: "AdGroupName",
      label: "Ad Group",
      sortable: true,
      widthBasis: "120px",
    },
    {
      name: "AdStatus",
      label: "Ad Status",
      sortable: true,
      widthBasis: "20px",
      renderCallback: (value: string, data: AdsReportType, name) =>
        !isUndefined(value) && (
          <AdStatus name={name} value={value} data={data} />
        ),
    },
    {
      name: "AdStrength",
      label: "Ad Strength",
      sortable: true,
      widthBasis: "20px",
      renderCallback: (value: string, data: AdsReportType, name) =>
        !isUndefined(value) && (
          <DefaultStatusCell name={name} value={value} data={data} />
        ),
    },
    {
      name: "AdType",
      label: "Ad Type",
      sortable: true,
      widthBasis: "20px",
      renderCallback: (value: string, data: AdsReportType, name) =>
        !isUndefined(value) && (
          <DefaultStatusCell name={name} value={value} data={data} />
        ),
    },

    ...KPIsMapper,
  ])
}

export const mapper = getMapper()

export default mapper
