import React, { FC, useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import Stack from "@components/ui/Stack/Stack"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import {
  ListingGroupEntity,
  ListingGroupOptionsEntity,
  ListingGroupOptionsKeys,
  ListingGroupOptionTypeEntity,
} from "@framework/types/manageCampaign"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { AddAssetListingGroupFiltersDataType } from "@services/account-campaign.service"
import { Button } from "@components/ui/Button"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import TextField from "@components/ui/TextField/TextField"
import styles from "./ListingGroups.module.scss"

interface ListingGroupsOptionsModalProps {
  isOpen: boolean
  onClose: () => void
  onSaveFilters: (
    deleteOptions: number[] | null,
    addOptions: AddAssetListingGroupFiltersDataType | null,
    selectedResourceId: number
  ) => void
  onSetNewOptionFilters: (
    addOptions: AddAssetListingGroupFiltersDataType,
    selectedResourceId: number
  ) => void
  listingGroupOptions: ListingGroupOptionsEntity
  selectedResource: ListingGroupEntity
}

const LISTING_GROUP_OPTIONS: Record<ListingGroupOptionsKeys, string> = {
  Brand: "Brand",
  Category: "Category",
  Channel: "Channel",
  Condition: "Condition",
  ItemId: "Item ID",
  ProductType: "Product Type",
}
const optionsArray: ListingGroupOptionsKeys[] = Object.keys(
  LISTING_GROUP_OPTIONS
) as ListingGroupOptionsKeys[]

type MappedListingGroupOptions = ListingGroupOptionTypeEntity & {
  case: ListingGroupOptionsKeys
  isSelected: boolean
  optionId: number | null
}
const ListingGroupsOptionsModal: FC<ListingGroupsOptionsModalProps> = observer(
  ({
    isOpen,
    onClose,
    onSaveFilters,
    onSetNewOptionFilters,
    listingGroupOptions,
    selectedResource,
  }) => {
    const [option, setOption] = useState(
      selectedResource.Subdivisions
        ? selectedResource.Subdivisions[0].CaseValue.Dimension
        : optionsArray[0]
    )
    const [mappedOptions, setMappedOptions] = useState<
      MappedListingGroupOptions[]
    >([])
    const [filteredOptionsList, setFilteredOptionsList] = useState<
      MappedListingGroupOptions[]
    >([])
    const [optionsForDeletion, setOptionsForDeletion] = useState<number[]>([])
    const [optionsForAdding, setOptionsForAdding] =
      useState<AddAssetListingGroupFiltersDataType | null>(null)
    const [searchString, setSearchString] = useState<string>("")

    const mapGroupsWithOptions = useCallback(() => {
      const selectedGroupOptions = listingGroupOptions[option]
      const mappedOptions: MappedListingGroupOptions[] =
        selectedGroupOptions?.map((opt) => {
          const selected = selectedResource.Subdivisions?.find(
            (i) => i.CaseValue.Id === opt.Id
          )
          return {
            ...opt,
            case: option as ListingGroupOptionsKeys,
            isSelected: Boolean(selected),
            optionId: selected ? selected.Id : null,
          }
        })
      setMappedOptions(mappedOptions || [])
    }, [option])

    useEffect(() => {
      const selectedBaseOption: ListingGroupOptionsKeys =
        selectedResource.Subdivisions
          ? selectedResource.Subdivisions[0].CaseValue.Dimension
          : optionsArray[0]
      setOption(selectedBaseOption)
      mapGroupsWithOptions()
    }, [])

    useEffect(() => {
      setFilteredOptionsList(mappedOptions)
    }, [mappedOptions])

    useEffect(() => {
      mapGroupsWithOptions()
      setOptionsForDeletion([])
      setOptionsForAdding(null)
    }, [option])

    const handleOnSave = () => {
      const deleteOptions = optionsForDeletion.length
        ? optionsForDeletion
        : null
      const addOptions = optionsForAdding?.values.length
        ? optionsForAdding
        : null

      if (
        selectedResource.Subdivisions &&
        selectedResource.Subdivisions[0].CaseValue.Dimension !== option &&
        addOptions
      ) {
        onSetNewOptionFilters(addOptions, selectedResource.Id)
      } else {
        onSaveFilters(deleteOptions, addOptions, selectedResource.Id)
      }

      setOptionsForDeletion([])
      setOptionsForAdding(null)
      onClose()
    }

    const handleCheckboxChange = (id: string, optionId: number | null) => {
      if (optionId) {
        setOptionsForDeletion((prevState) => {
          const alreadyAddedId = prevState.find((el) => el === optionId)
          if (alreadyAddedId) {
            return prevState.filter((item) => item !== optionId)
          }
          return [...prevState, optionId]
        })
      } else {
        setOptionsForAdding((prevState) => {
          if (!prevState) {
            return {
              case: option,
              values: [id],
            }
          }
          if (prevState.values.findIndex((elem) => elem === id) !== -1) {
            const filters = [...prevState.values]
            filters.splice(filters.findIndex((elem) => elem === id))
            return {
              ...prevState,
              values: filters,
            }
          }
          return {
            ...prevState,
            values: [...prevState.values, id],
          }
        })
      }
    }

    const onSearch = (str: string) => {
      setSearchString(str)
      const filteredItems = mappedOptions?.filter((opt) =>
        opt.Name.toLowerCase().includes(str.toLowerCase())
      )
      setFilteredOptionsList(filteredItems)
    }

    return (
      <Modal
        title={
          <Typography weight="bold" type="h2" title="Subdivide Online">
            Subdivide {`"${selectedResource.CaseValue.Name}"`} by:
          </Typography>
        }
        isOpen={isOpen}
        onClose={onClose}
        className={styles.optionsModal}
      >
        <Stack align="flex-start" direction="column" justify="center">
          <div>
            <SimpleSelect
              renderValue={(value) => LISTING_GROUP_OPTIONS[value]}
              value={option}
              options={optionsArray}
              onSelect={(value) => setOption(value)}
              className={styles.dropdown}
            />
          </div>
          <div>
            <TextField
              before={<Icon name="search" key="search" />}
              after={
                searchString ? (
                  <IconButton onClick={() => onSearch("")}>
                    <Icon name="cross" />
                  </IconButton>
                ) : null
              }
              placeholder="Search"
              onChange={(e) => onSearch(e.target.value)}
              value={searchString}
            />
          </div>
          {mappedOptions.length ? (
            <ul className={styles.optionsList}>
              <li className={styles.optionsItem}>
                <label className={styles.optionsItemLabel}>
                  <div className={styles.optionsItemCheckboxWrap}>
                    {/* <CheckBox name={`all-${option}`} checked={false} /> */}
                    <Typography
                      className={styles.optionsItemValue}
                      type="h4"
                      weight="bold"
                    >
                      Listing group
                    </Typography>
                  </div>
                  <Typography weight="bold" type="h4">
                    Listings
                  </Typography>
                </label>
              </li>
              {filteredOptionsList?.map((opt) => {
                const optionForDeletion = optionsForDeletion.find(
                  (el) => el === opt.optionId
                )
                const optionForAdding = Boolean(
                  optionsForAdding?.values.find((id) => id === opt.Id)
                )
                let isChecked: boolean = false

                if (
                  (optionForDeletion && opt.isSelected) ||
                  optionForDeletion
                ) {
                  isChecked = false
                } else if (
                  (opt.isSelected || optionForAdding) &&
                  !optionForDeletion
                ) {
                  isChecked = true
                }
                return (
                  <li className={styles.optionsItem} key={opt.Id}>
                    <label className={styles.optionsItemLabel}>
                      <div className={styles.optionsItemCheckboxWrap}>
                        <CheckBox
                          className={styles.optionsItemCheckbox}
                          onChange={(event) =>
                            handleCheckboxChange(opt.Id, opt.optionId)
                          }
                          name={opt.Id}
                          checked={isChecked}
                        />
                        <Typography
                          className={styles.optionsItemValue}
                          type="body1"
                        >
                          {opt.Name}
                        </Typography>
                      </div>
                      <Typography type="body1">{opt.Listings}</Typography>
                    </label>
                  </li>
                )
              })}
            </ul>
          ) : (
            <Typography type="h3" className={styles.emptyList}>
              No groups
            </Typography>
          )}
        </Stack>
        <Stack align="flex-start" direction="row" justify="flex-start">
          <Button color="primary" onClick={handleOnSave}>
            Save
          </Button>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </Modal>
    )
  }
)

export default ListingGroupsOptionsModal
