import React from "react"
import Typography from "@components/ui/Typography/Typography"
import { ILabeled } from "../types"
import styles from "./Legends.module.scss"

export type LegendListProps = {
  className?: string
  items: ILabeled[]
  colors: string[]
  dashed?: boolean
  margin?: string
}

export type LegendProps = {
  className?: string
  label: string
  color?: string
  dashed?: boolean
}

const dashedGradientStyle = (color: string) =>
  `repeating-linear-gradient(90deg, white, white 2px, ${color} 2px, ${color} 4px)`

export const Legend: React.FC<LegendProps> = ({
  label,
  color = "currentColor",
  dashed = false,
}) => (
  <li className={styles.label} key={label}>
    <span
      className={styles.point}
      style={{
        background: dashed ? dashedGradientStyle(color) : color,
      }}
    />
    <Typography type="body2">{label}</Typography>
  </li>
)

export const LegendList: React.FC<LegendListProps> = ({
  items,
  colors,
  dashed = false,
  margin,
}) => (
  <ul className={styles.root} style={{ margin }}>
    {items.map(({ label }, idx) => (
      <Legend
        color={colors[idx]}
        dashed={dashed}
        label={label}
        key={colors[idx]}
      />
    ))}
  </ul>
)

export default LegendList
