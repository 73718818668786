import * as React from "react"

interface GmailIconProps {
  width?: number
  height?: number
}
const GmailIcon = ({ width, height }: GmailIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 33}
    height={height || 32}
    fill="none"
  >
    <path
      fill="#4285F4"
      d="M3.545 27h4.773V15.666l-6.818-5V25c0 1.107.917 2 2.045 2Z"
    />
    <path
      fill="#34A853"
      d="M24.68 27h4.772c1.132 0 2.046-.897 2.046-2V10.666l-6.818 5"
    />
    <path
      fill="#FBBC04"
      d="M24.68 7v8.666l6.818-5V8c0-2.474-2.888-3.884-4.91-2.4"
    />
    <path fill="#EA4335" d="M8.32 15.667V7l8.182 6 8.182-6v8.667l-8.182 6" />
    <path
      fill="#C5221F"
      d="M1.5 8v2.666l6.818 5V7L6.41 5.6C4.384 4.116 1.5 5.526 1.5 8Z"
    />
  </svg>
)
export default GmailIcon
