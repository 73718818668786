export const calcPointCoords = (
  node: HTMLElement,
  placement: string,
  relative: boolean = false
) => {
  const rect = node.getBoundingClientRect()
  const defOffsetY = placement === "bottom" ? rect.height : 0
  const defOffsetX = placement === "right" ? rect.width : 0

  const offsetX = relative ? 0 : rect.x
  const offsetY = relative ? 0 : rect.y

  const x =
    offsetX +
    (placement === "top" || placement === "bottom"
      ? rect.width / 2
      : defOffsetX)
  const y =
    offsetY +
    (placement === "left" || placement === "right"
      ? rect.height / 2
      : defOffsetY)
  return { x, y }
}

export default null
