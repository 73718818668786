import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"

import styles from "./AdCard.module.scss"

interface AdContentProps {
  website: string
  headlines: string[]
  descriptions: string[]
  headlinesLimit?: number
  variant?: "mobile" | "desktop"
}

export const AdContent: React.FC<AdContentProps> = observer(
  ({
    website = "(url not provided)",
    headlines = [],
    descriptions = [],
    variant = "desktop",
    headlinesLimit = Infinity,
  }) => {
    const headlineText = (
      headlinesLimit < headlines.length
        ? headlines.slice(0, headlinesLimit)
        : headlines
    ).join(" | ")

    const headlinesOverflow =
      headlinesLimit < headlines.length ? headlines.length - headlinesLimit : 0

    const descriptionsText = descriptions.join(". ")

    return (
      <div className={clsx(styles.content, styles[variant])}>
        <div className={styles.title}>
          <span className={styles.prefix}>
            Ad <span>·</span>
          </span>
          <span className={styles.site} title={website}>
            {website}
          </span>
          {variant === "desktop" && (
            <Icon className={styles.arrowDown} name="arrow-down" />
          )}
          {variant === "mobile" && (
            <Icon className={styles.info} name="information-solid" />
          )}
        </div>
        <div className={styles.header} title={headlines.join(" | ")}>
          {headlineText}{" "}
          {headlinesOverflow ? <span>+{headlinesOverflow} more</span> : null}
        </div>
        <div className={styles.description} title={descriptionsText}>
          {descriptionsText}
        </div>
      </div>
    )
  }
)

export default AdContent
