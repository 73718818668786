import React, { CSSProperties } from "react"
import clsx from "clsx"

import { OrientationType, QuadSizeType } from "@utils/enums"
import usePrevious from "@framework/hooks/usePrevious"

import styles from "./BaseProgressBar.module.scss"

const maxValue = 100

export interface IBaseProgressBarProps {
  value: number
  size?: QuadSizeType
  gradient?: OrientationType
  barTransitionTime?: string
}

export const BaseProgressBar: React.FC<IBaseProgressBarProps> = ({
  value,
  size = QuadSizeType.medium,
  gradient,
  barTransitionTime = "0s",
}: IBaseProgressBarProps) => {
  const rootStyles = clsx(
    styles.root,
    styles[size],
    gradient ? styles[gradient] : ""
  )

  const percentage = value > maxValue ? maxValue : value

  const oldPercentage = usePrevious(percentage)

  const overflow = value - maxValue
  const overflowPercentage =
    overflow >= 0 ? ((value - overflow) / value) * maxValue : maxValue

  const offset = overflowPercentage > maxValue ? maxValue : overflowPercentage
  const gap = (maxValue - offset) * (maxValue / value) * 0.5
  const yellowRim = offset + gap
  const redRim = offset + (maxValue - yellowRim) * 0.5 + gap
  const barStyles = {
    width: `${percentage}%`,
    "--bar-transition-speed":
      oldPercentage && oldPercentage > percentage ? "0s" : barTransitionTime,
  }
  const overflowGradientStyles: CSSProperties = {
    backgroundImage: `linear-gradient(90deg, transparent ${offset}%, rgb(253, 139, 32) ${yellowRim}%, rgb(235, 13, 13) ${redRim}%, rgb(235, 13, 13) 100%)`,
    ...barStyles,
  }

  return (
    <div className={rootStyles}>
      <div className={styles.bar} style={barStyles} />
      <div className={styles.overflowGradient} style={overflowGradientStyles} />
    </div>
  )
}

export default BaseProgressBar
