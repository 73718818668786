import React from "react"
import clsx from "clsx"

import { initArrayByLength, randomNumberMinMax } from "@utils/numberUtils"

import styles from "./Skeleton.module.sass"

export type SkeletonProps = {
  count?: number
  rounded?: boolean
  lineHeight?: string | number
  maxWidth?: number // %
  minWidth?: number // %
  className?: string
  lineClassName?: string
  spacing?: number
  loading?: boolean
  align?: "start" | "end"
  style?: React.CSSProperties
}

export const Skeleton: React.FC<SkeletonProps> = ({
  count = 1,
  rounded = false,
  lineHeight = "1em",
  maxWidth = 100,
  minWidth = 80,
  spacing = 5,
  loading = true,
  className,
  lineClassName,
  align = "start",
  style,
}) => {
  const lines = initArrayByLength(count).map(() =>
    randomNumberMinMax(minWidth, maxWidth)
  )

  return (
    <div
      className={clsx(
        styles.root,
        { [styles.rounded]: rounded, [styles.withAnimation]: loading },
        styles[`align-${align}`],
        className
      )}
      style={{ gap: spacing, ...style }}
    >
      {lines.map((lineWidth, idx) => (
        <p
          className={clsx(styles.line, lineClassName)}
          style={{ width: `${lineWidth}%`, height: lineHeight }}
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
        />
      ))}
    </div>
  )
}

export default React.memo(Skeleton)
