import React from "react"
import useToggle from "@framework/hooks/useToggle"
import { Collapsable } from "@components/ui/Collapsible/Collapsable"
import { CollapsibleHeader } from "@components/ui/Collapsible/CollapsibleHeader"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import { FieldArray, useFormikContext } from "formik"
import LimitLengthTextField from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/EditCopySidebar/LimitLengthTextField"
import { EditAssetGroupFormValues } from "@pages/PMax/components/EditAssetGroupPage/EditAssetGroupForm"
import IconButton from "@components/ui/Button/IconButton"
import { observer } from "mobx-react-lite"
import styles from "./Assets.module.scss"

const Headlines = observer(() => {
  const toggle = useToggle()
  const { values, setFieldValue } = useFormikContext<EditAssetGroupFormValues>()
  const headlineData =
    values.headline && values.headline.length ? values.headline : []

  const handleAddField = () => {
    if (!toggle.isOpened) {
      toggle.setOpened(true)
    }
    setFieldValue("headline", [...headlineData, "New headline"], true)
  }

  return (
    <div className={styles.section}>
      <CollapsibleHeader
        label="Headlines"
        onClick={toggle.handleToggle}
        open={toggle.isOpened}
        type="h2"
        rootClassName={styles.collapsibleHeader}
      />
      <Typography
        type="subtitle"
        color="gray"
        className={styles.assetSectionTitle}
      >
        Add up to 15 headlines
      </Typography>
      {headlineData.length < 15 && (
        <Button
          color="primary"
          before={<Icon name="plus" key="plus" />}
          variant="ghost"
          className={styles.buttonIcon}
          onClick={handleAddField}
        >
          Headline
        </Button>
      )}
      <Collapsable open={toggle.isOpened}>
        <FieldArray
          name="headline"
          render={(formikProps) => (
            <div>
              {values.headline && values.headline.length ? (
                values.headline.map((item: string, i: number) => (
                  <div className={styles.input} key={`headline-${i + 1}`}>
                    <LimitLengthTextField
                      className={styles.textField}
                      name={`headline.${i}`}
                      maxChars={30}
                    />
                    <IconButton
                      color="secondary"
                      variant="ghost"
                      onClick={() => {
                        if (headlineData.length !== 1) formikProps.remove(i)
                      }}
                    >
                      <Icon name="trash" />
                    </IconButton>
                  </div>
                ))
              ) : (
                <div />
              )}
              {formikProps.form.errors.headline && (
                <Typography type="body2" color="red">
                  {formikProps.form.errors.headline}
                </Typography>
              )}
            </div>
          )}
        />
      </Collapsable>
    </div>
  )
})

export default Headlines
