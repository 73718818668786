/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import Popover from "@components/ui/Popover/Popover"
import Typography from "@components/ui/Typography/Typography"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import { preciseMoneyFormatter } from "@services/utils"
import { useStore } from "@store/index"
import PlannedAdSpend from "./components/PlannedAdSpend/PlannedAdSpend"

import styles from "./Checkbook.module.scss"

type CheckbookProps = {
  className?: string
}

const mapper = [
  { name: "totalAmount", label: "Total Amount" },
  { name: "monthsSelected", label: "Months Selected" },
  { name: "approved", label: "Approved" },
]

const moneyWithSignFormatter = (
  value: number = 0,
  withPositiveSign: boolean = false
) => {
  const sign = value < 0 ? "-" : value > 0 && withPositiveSign ? "+" : ""
  const unsignedValue = Math.abs(value)
  return `${sign}${preciseMoneyFormatter(unsignedValue.toString())}`
}

const Checkbook: React.FC<CheckbookProps> = observer(({ className }) => {
  const { checkbookStore } = useStore()
  const {
    initialPlanedAdSpend,
    additionalAdSpend,
    totalAdSpend,
    actualAdSpend,
    remainAdSpend,
    loadingError,
    updateError,
  } = checkbookStore

  const renderPopoverContent = () => (
    <div className={styles.container}>
      <div className={styles.row}>
        {mapper.map(({ label, name }) => (
          <Typography type="subhead1" color="gray" upperCase key={name}>
            {label}
          </Typography>
        ))}
      </div>

      <Typography type="h3" color="gray" className={styles.empty}>
        (no additional spend found)
      </Typography>
      {/* {mockPopoverData.map((data) => (
        <div className={styles.row} key={data.approved}>
          {mapper.map(({ name }) => (
            <Typography type={"h3"} key={name}>
              {data[name as keyof typeof data]}
            </Typography>
          ))}
        </div>
      ))} */}
    </div>
  )

  return (
    <div className={clsx(styles.root, className)}>
      <Typography type="h1" color="primary" className={styles.title} bold>
        Checkbook
      </Typography>
      <ServiceDownInterceptor />

      {(updateError || loadingError) && (
        <Typography type="h3" color="red" className={styles.empty}>
          {updateError || loadingError}
        </Typography>
      )}

      <PlannedAdSpend />

      <div className={styles.results}>
        <Popover
          key="initial"
          title="Initial Planned Ad Spend"
          subTitle={moneyWithSignFormatter(initialPlanedAdSpend)}
        />
        <Popover
          key="additional"
          title="Additional Ad Spend"
          subTitle={moneyWithSignFormatter(additionalAdSpend, true)}
        >
          {renderPopoverContent()}
        </Popover>
        <Popover
          key="total_planed"
          title="Total Planned Ad Spend"
          subTitle={moneyWithSignFormatter(totalAdSpend)}
        />
        <Popover
          key="actual"
          title="Actual Ad Spend to Date"
          subTitle={moneyWithSignFormatter(actualAdSpend)}
        />
        <Popover
          key="remaining"
          title="Remaining Ad Spend"
          subTitle={moneyWithSignFormatter(remainAdSpend)}
        />
      </div>
    </div>
  )
})

export default Checkbook
