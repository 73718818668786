import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import { useAlert } from "react-alert"

import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Alert from "@components/ui/Alert/Alert"
import { useStore } from "@store/index"
import useValidShopifyCredentials from "@framework/hooks/useValidShopifyCredentials"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import PublicLayout from "@root/layouts/public/PublicLayout"
import { emailValidator } from "@components/utils/validators"
import FormTextField from "@framework/prototypes/FormTextField"

import styles from "./SignUp.module.scss"

const MAIN_TITLE =
  "Intelligent Feed Management and Paid Search Marketing Automation Platform"
const SUB_TITLE =
  "Automate your entire ad process all in one platform for Paid search and Shopping Ads"
const FORM_TITLE = "Let's be in touch"

const insight_list = [
  "Intelligent Feed optimization",
  "AI Keyword research & analysis",
  "Auto Campaign creation",
  "Ongoing ad monitoring & management",
]

const SUCCESS_MESSAGE =
  "Thanks for submitting your information. Someone will be in touch shortly."

interface SignUpForm {
  email: string
}

const validationSchema = yup.object().shape({
  email: emailValidator.clone().required("Required"),
})

interface SignUpProps {}

const SignUp: React.FC<SignUpProps> = () => {
  const [showMessage, setShowMessage] = useState<string | null>(null)
  const history = useHistory()
  const location = useLocation()
  const alert = useAlert()

  const shopifyCredentials = useValidShopifyCredentials()

  const {
    authStore: { signUpShopify },
  } = useStore()

  const handleSubmit = async ({ email }: SignUpForm) => {
    if (!shopifyCredentials) return
    const error = await signUpShopify(email, shopifyCredentials)
    if (error) {
      alert.error(
        <AlertMessage
          title="Failed to connect with Shopify app"
          description={error}
        />
      )
      return
    }
    setShowMessage(SUCCESS_MESSAGE)
  }

  const handleLoginClick = () => {
    history.push(`/login${location.search}`)
  }

  useEffect(() => {
    if (showMessage) {
      const timeout = setTimeout(() => setShowMessage(""), 7_000)
      return () => clearTimeout(timeout)
    }
    return undefined
  }, [showMessage])

  const formik = useFormik<SignUpForm>({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <PublicLayout
      color="primary"
      after={
        <Button onClick={handleLoginClick} color="light" variant="ghost">
          Login
        </Button>
      }
    >
      <FormikProvider value={formik}>
        <form className={styles.body} onSubmit={formik.handleSubmit}>
          <section className={styles.content}>
            <h1 className={styles.title}>{MAIN_TITLE}</h1>
            <h2 className={styles.subtitle}>{SUB_TITLE}</h2>

            <ul className={styles.list}>
              {insight_list.map((text) => (
                <li key={text}>
                  <Icon name="checkbox-circle-fill" /> {text}
                </li>
              ))}
            </ul>
          </section>

          <section className={styles.formContainer}>
            <Typography type="h1" color="dark" bold>
              {FORM_TITLE}
            </Typography>
            <FormTextField label="Email address" name="email" />

            <div className={styles.control}>
              <Button variant="contained" color="primary" type="submit">
                Get Access
              </Button>
            </div>

            <div className={clsx(styles.alertContainer)}>
              <Alert
                className={clsx(
                  styles.alert,
                  showMessage == null
                    ? ""
                    : showMessage
                    ? styles.visible
                    : styles.hidden
                )}
              >
                {SUCCESS_MESSAGE}
              </Alert>
            </div>
          </section>
        </form>
      </FormikProvider>
    </PublicLayout>
  )
}

export default observer(SignUp)
