import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import NoData from "@components/ui/NoData/NoData"

import styles from "./Dashboard.module.scss"

type DashboardProps = {
  className?: string
}

const Dashboard: React.FC<DashboardProps> = observer(({ className }) => {
  const { accountStore } = useStore()

  return (
    <div className={clsx(styles.root, className)}>
      <NoData>Page is under maintenance</NoData>
    </div>
  )
})

export default Dashboard
