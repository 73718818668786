import React from "react"
import { observer } from "mobx-react-lite"

import Tabs from "@components/ui/Tabs/Tabs"
import { numberWithDiff } from "@components/utils/stringUtils"
import BaseSidebarFilter from "@components/prototypes/BaseSidebarFilter/BaseSidebarFilter"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import CategoryFilter from "./CategoryFilter"
import AttributeFilter from "./AttributeFilter"

export interface SidebarFilterProps {}

export const SidebarFilter: React.FC<SidebarFilterProps> = observer(() => {
  const {
    keywordsFilter: {
      group,
      isSync,
      isOpened,
      filterTabs,
      activeFilterTab,
      tempFiltersCount,
      activeFiltersCount,
      toggleFilter,
      resetTemp,
      resetAll,
      applyFilter,
      setActiveTab,
    },
  } = useOpportunitiesStore()

  const handleApply = () => {
    applyFilter()
    toggleFilter(false)
  }

  const handleCancel = () => {
    resetTemp()
    toggleFilter(false)
  }

  const distribution: Record<string, string> = {
    byAttribute: numberWithDiff(
      group.byAttributes.activeFiltersCount,
      group.byAttributes.filtersCountDifference
    ),
    byCategory: numberWithDiff(
      group.byCategories.activeFiltersCount,
      group.byCategories.filtersCountDifference
    ),
  }

  return (
    <BaseSidebarFilter
      isOpened={isOpened}
      onClose={() => toggleFilter(false)}
      canReset={Boolean(tempFiltersCount || activeFiltersCount)}
      onReset={resetAll}
      hasChanges={!isSync}
      onApply={handleApply}
      onCancel={handleCancel}
      switchSocket={
        <Tabs
          options={filterTabs}
          currentValue={activeFilterTab}
          onChange={setActiveTab}
          underlined
          fullWidth
        >
          {({ value, label }) => `${label} • ${distribution[value] ?? 0}`}
        </Tabs>
      }
    >
      {activeFilterTab.value === "byCategory" ? (
        <CategoryFilter />
      ) : (
        <AttributeFilter />
      )}
    </BaseSidebarFilter>
  )
})

export default SidebarFilter
