import React from "react"

import Typography from "@components/ui/Typography/Typography"
import { UserData } from "@framework/types/user"
import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import { ColumnMapper } from "./types"
import ActionsColumn from "./ActionsColumn"
import tableMapper from "./tableMapper"

export interface MenuItemProps {
  users: UserData[]
}

export const UserTable: React.FC<MenuItemProps> = ({ users }) => (
  <Table>
    <Row>
      {tableMapper.map((mapper) => (
        <Column variant="header" width={mapper.width} key={mapper.name}>
          <Typography
            type="subhead1"
            color="darkGray"
            key={mapper.name}
            upperCase
          >
            {mapper.label}
          </Typography>
        </Column>
      ))}
    </Row>

    {users.map((user) => (
      <Row key={user.ID}>
        {tableMapper.map((mapper) => {
          const Component = mapper.column?.Component ?? ActionsColumn
          return (
            <Column width={mapper.width} key={mapper.name}>
              <Component mapper={mapper} data={user} />
            </Column>
          )
        })}
      </Row>
    ))}
  </Table>
)

export interface ColumnProps<T = any> {
  mapper: ColumnMapper
  data?: T
}

export default UserTable
