import { MetricNameType } from "@framework/types/metrics"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { TableMapper, OptionsType } from "../Table/types"

const makeMetricMapper = (metric: MetricNameType) => {
  const desc = getMetricDescriptor(metric)
  return {
    name: desc.name,
    label: desc.shortLabel,
    options: {
      valueFormatter: desc.formatter,
      getChangeDirection: desc.trendFormat,
    },
  }
}

export const accountTableMapperPart: TableMapper<
  MetricNameType,
  OptionsType
>[] = [
  makeMetricMapper("Impressions"),
  makeMetricMapper("SearchImpressionShare"),
  makeMetricMapper("Clicks"),
  makeMetricMapper("Ctr"),
  makeMetricMapper("AverageCpc"),
  makeMetricMapper("Cost"),
  makeMetricMapper("Conversions"),
  makeMetricMapper("ConversionRate"),
  makeMetricMapper("Revenue"),
  makeMetricMapper("AOV"),
  makeMetricMapper("CostConv"),
  makeMetricMapper("Roas"),
]

export const accountTableMapper: TableMapper<MetricNameType | "Name">[] = [
  {
    name: "Name",
    label: "",
  },
  ...accountTableMapperPart,
]
