import { NavOption } from "@components/ui/Tabs/types"

export const productFeedNavItems: NavOption[] = [
  {
    label: "Dashboard",
    icon: "dashboard",
    value: "/dashboard",
  },
  {
    label: "Product Feed",
    icon: "hummer",
    value: "/product-feed",
  },
]

export default null
