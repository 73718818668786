import React from "react"
import { observer } from "mobx-react-lite"
import { action, computed, observable } from "mobx"

import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import EditAdCopyTask from "@store/manageProfile/EditAdCopyTask"
import NoData from "@components/ui/NoData/NoData"
import {
  AdCopyDetailsData,
  AdCopyInstance,
  AdCopyPrompts,
  EditAdCopyData,
} from "@framework/types/adCopy"
import PlaceholderLoader from "@components/ui/Loader/PlaceholderLoader"
import { FormMetaContextProvider } from "@framework/prototypes/FormMetaContext"
import { DeepPartial } from "@framework/types/utils"
import { useController, useStore } from "@store/index"
import AccountStore from "@store/account/account"
import AdCopyManagementController from "@store/manageProfile/ad-copy-management.controller"
import AdCopyEditor from "./EditAdCopyForm"
import {
  extractModifiedFieldsList,
  mergeAdCopiesData,
  mergeAdCopiesPromptsData,
} from "./utils"
import { EditAdCopyContext, IEditAdCopyContext } from "./EditAdCopyContext"

import styles from "./EditCopySidebar.module.scss"

export interface EditCopySidebarProps {
  isOpened?: boolean
  task: EditAdCopyTask
}

export const EditCopySidebar: React.FC<EditCopySidebarProps> = observer(
  ({ isOpened = false, task }) => {
    const { accountStore } = useStore()
    const { adCopyManagement } = useController()

    const data = task.adCopyDetails

    const isReadyForEdit = task.adCopyIds.length > 0 && data != null

    const editContext = React.useMemo(
      () =>
        new EditLocalAdCopyContext({
          task,
          accountStore,
          adCopyManagement,
        }),
      [task, accountStore, adCopyManagement]
    )

    const [context, setContext] = React.useState<{
      initialData?: DeepPartial<AdCopyDetailsData>
      prompts?: AdCopyPrompts
      timestamp: number
    }>({ timestamp: Date.now() })

    React.useEffect(() => {
      if (data == null) {
        setContext({
          timestamp: Date.now(),
        })
        return
      }

      const initialData = mergeAdCopiesData(data)
      const prompts = mergeAdCopiesPromptsData(data)

      setContext({
        initialData,
        prompts,
        timestamp: Date.now(),
      })
    }, [data])

    const wasEdited = React.useMemo(
      () => extractModifiedFieldsList(data),
      [data]
    )

    return (
      <EditAdCopyContext.Provider value={editContext}>
        <FormMetaContextProvider editedFields={wasEdited}>
          <SidebarContainer isOpen={isOpened} onClose={editContext.onClose}>
            {(task.type === "edit" && isReadyForEdit) || task.type === "add" ? (
              <AdCopyEditor
                initialValues={context.initialData}
                initialPrompts={context.prompts}
                adCopyIds={task.adCopyIds}
                withAISupport={task.withAI}
                key={context.timestamp}
              />
            ) : !task.adCopyIds.length ? (
              <div className={styles.noFoundContainer}>
                <NoData>There aro no valid Ad Copy to edit selected</NoData>
              </div>
            ) : task.error != null ? (
              <div className={styles.noFoundContainer}>
                <NoData>Failed to load data</NoData>
              </div>
            ) : (
              <div className={styles.loaderContainer}>
                <PlaceholderLoader />
              </div>
            )}
          </SidebarContainer>
        </FormMetaContextProvider>
      </EditAdCopyContext.Provider>
    )
  }
)

export default EditCopySidebar

class EditLocalAdCopyContext implements IEditAdCopyContext {
  @observable readonly accountStore: AccountStore

  @observable readonly adCopyManagement: AdCopyManagementController

  @observable readonly task: EditAdCopyTask

  constructor(options: {
    task: EditAdCopyTask
    adCopyManagement: AdCopyManagementController
    accountStore: AccountStore
  }) {
    this.task = options.task
    this.accountStore = options.accountStore
    this.adCopyManagement = options.adCopyManagement
  }

  @computed get isEdit() {
    return this.task.type === "edit" && this.task.adCopyIds.length > 0
  }

  @computed get isBulk() {
    return this.isEdit && this.task.adCopyIds.length > 1
  }

  @computed get submitLabel() {
    return this.isEdit ? "Save changes" : "Create"
  }

  getAccount = () => {
    const { accountId } = this.accountStore
    if (accountId == null) throw new Error("Account is not initialized yet")
    return accountId
  }

  @action onCreate = async (data: AdCopyInstance) => {
    const res = await this.adCopyManagement
      .createAdCopy(this.getAccount(), data)
      .catch(() => "Unexpected error")

    return res
  }

  @action onUpdate = async (data: Partial<EditAdCopyData>) => {
    const res = await this.adCopyManagement
      .updateAdCopy(this.getAccount(), this.task.adCopyIds, data)
      .catch(() => "Unexpected error")

    return res
  }

  @action onDelete = async () => {
    this.adCopyManagement.createDeleteAdCopyTask(this.task.adCopyIds)
  }

  @action onClose = async () => {
    this.adCopyManagement.adCopyStore.editor.closeTask()
  }
}
