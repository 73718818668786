import { randomNumber } from "@utils/numberUtils"
import { getPerformanceReportMock } from "@utils/mock-generators"
import { nanoid } from "nanoid"
import { metricNamesList } from "@framework/types/metrics"
import { MetaCampaignReportData, metaCampaignStatusNames } from "./types"

export const generateCampaignReport = (
  idx: number,
  withTrend: boolean = true
): MetaCampaignReportData => ({
  id: nanoid(),
  name: `Ultimate-swipe-file-${idx + 1}`,
  campaignName: `Campaign name`,
  adSetName: `Ad Set name`,
  status:
    metaCampaignStatusNames[randomNumber(0, metaCampaignStatusNames.length)],
  previewUrl:
    "https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg",
  totalViews: randomNumber(1_000, 100_000),
  ...getPerformanceReportMock([...metricNamesList], withTrend),
})

export default null
