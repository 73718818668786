import React from "react"
import { observer } from "mobx-react-lite"

import { TrendValue } from "@components/ui/DataChangeWidget/DataChangeWidget"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import Typography from "@components/ui/Typography/Typography"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import { QueryReportType } from "@framework/types/account"
import { statusToLabel } from "@utils/stringUtils"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Row from "./Table/Row"
import Column from "./Table/Column"

import styles from "./InsightTable.module.scss"

export const QueryRow: React.FC<{
  data: QueryReportType
  mapper: MetricNameType[]
}> = observer(({ data: item, mapper: columns }) => {
  const matchTypes = item.MatchTypes?.map((it) =>
    renderQueryMatchType(it)
  ).join(", ")

  return (
    <Row className={styles.queryRow}>
      <Column key="label">
        <Tooltip text={item.Text} lineClamp={2}>
          {item.Text}
        </Tooltip>
      </Column>

      <Column key="MatchTypes">
        <Tooltip text={matchTypes} lineClamp={2}>
          {matchTypes}
        </Tooltip>
      </Column>

      {columns.map((metricName) => {
        const metricValue = item[metricName]
        const value = metricValue?.Value ?? 0
        const delta = metricValue?.Change ?? 0
        const desc = getMetricDescriptor(metricName)
        return (
          <Column key={metricName}>
            <Typography className={styles.column}>
              <Typography>{desc.formatter(value) ?? value}</Typography>
              <TrendValue
                className={styles.trend}
                value={Math.ceil(delta * 100)}
                arrowAfter
                metricDir={(desc.trendFormat ?? positiveTransformDirection)(
                  delta
                )}
              />
            </Typography>
          </Column>
        )
      })}
    </Row>
  )
})

export default QueryRow

const renderQueryMatchType = (matchType: string) => {
  if (matchType === "UNSPECIFIED") return "Unspecified"
  if (matchType === "BROAD") return "Broad match"
  if (matchType === "EXACT") return "Exact match"
  if (matchType === "PHRASE") return "Phrase match"
  if (matchType === "NEAR_EXACT") return "Exact match (close variant)"
  if (matchType === "PHRASE_MATCH") return "Phrase match (close variant)"
  return statusToLabel(matchType)
}
