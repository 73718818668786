import React, { useCallback, useEffect, useRef, useState } from "react"
import clsx from "clsx"
import throttle from "lodash/throttle"
import ReactDOM from "react-dom"

import useToggle from "@framework/hooks/useToggle"
import { ComicBubbleProps } from "../ComicBubble/ComicBubble"
import { PlacementType } from "../types"
import { calcPointCoords } from "./utils"

import styles from "./TooltipContainer.module.scss"

export type TooltipContainerProps = {
  placement?: PlacementType
  delay?: number
  disabled?: boolean
  toolTip?: (props: ComicBubbleProps) => React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export const OnHoverTooltip: React.FC<TooltipContainerProps> = ({
  children,
  placement = "top",
  className,
  delay = 300,
  disabled,
  toolTip,
  ...rest
}) => {
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const { isOpened: active, setOpened: setActive } = useToggle()
  const { isOpened: visible, setOpened: setVisible } = useToggle()

  const rootRef = useRef<HTMLDivElement>(null)

  const handleEnter: React.MouseEventHandler<HTMLDivElement> = useCallback(
    throttle(() => {
      setActive(true)
      setCoords(calcPointCoords(rootRef.current as HTMLElement, placement))
    }, 300),
    [placement, coords, setCoords]
  )

  useEffect(() => {
    if (disabled) return undefined

    if (active) {
      if (delay === 0) {
        return setVisible(true)
      }
      const displayTimer = setTimeout(() => setVisible(true), delay)
      return () => clearTimeout(displayTimer)
    }
    return setVisible(false)
  }, [active, disabled, delay, setVisible])

  useEffect(() => {
    if (disabled) return undefined

    if (visible) {
      const handleLeave = (e: MouseEvent) => {
        const { path } = e as any
        if (
          rootRef.current &&
          path?.length &&
          !path.find((el: HTMLElement) => el?.isSameNode?.(rootRef.current))
        ) {
          setActive(false)
        }
      }

      const throttledMouseHandler = throttle(handleLeave, 300)

      document.addEventListener("mousemove", throttledMouseHandler)
      return () => {
        document.removeEventListener("mousemove", throttledMouseHandler)
      }
    }
    return undefined
  }, [visible, disabled, setActive])

  return (
    <div
      ref={rootRef}
      className={clsx(styles.root, className)}
      onMouseEnter={handleEnter}
      onMouseLeave={() => setActive(false)}
      {...rest}
    >
      {children}
      {!!toolTip &&
        active &&
        ReactDOM.createPortal(
          toolTip?.({ coords, hidden: !visible, placement }),
          document.body
        )}
    </div>
  )
}

export default OnHoverTooltip
