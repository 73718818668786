import { observable, action } from "mobx"

import RootStore from "@store/RootStore"
import { AdCopyGroupData, CampaignData } from "@framework/types/adCopy"
import { isStringIncludesQuery } from "@utils/stringUtils"

export class CampaignsListStore {
  root: RootStore

  constructor(root: RootStore) {
    this.root = root
  }

  @observable allCampaigns: string[] = []

  @observable campaignsMap: Map<string, CampaignData> = new Map()

  @observable adGroupsMap: Map<string, AdCopyGroupData> = new Map()

  @observable isLoading = false

  @observable error: null | string = null

  @action setCampaigns = (data: CampaignData[]) => {
    this.allCampaigns = data.map((it) => it.id)
    this.campaignsMap = new Map(data.map((it) => [it.id, it]))
  }

  @action setAdGroups = (data: AdCopyGroupData[]) => {
    this.adGroupsMap = new Map(data.map((it) => [it.id, it]))
  }

  @action startLoading = () => {
    this.isLoading = true
    this.error = null
  }

  @action endLoading = (error: string | null = null) => {
    this.error = error
    this.isLoading = false
  }

  searchCampaigns = (query: string) =>
    this.allCampaigns.reduce<CampaignData[]>((acc, campId) => {
      const camp = this.campaignsMap.get(campId)

      if (camp == null) return acc

      const groupIds =
        camp.groupIds?.filter((adGroupId) => {
          const adGroup = this.adGroupsMap.get(adGroupId)
          if (adGroup == null) return false
          return isStringIncludesQuery(adGroup?.name, query)
        }) ?? null

      if (isStringIncludesQuery(camp.name, query) || groupIds?.length)
        acc.push({ ...camp, groupIds })

      return acc
    }, [])
}

export default CampaignsListStore
