import React from "react"
import { observer } from "mobx-react-lite"

import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import { ModalProps } from "@components/ui/Modal/Modal"
import Tabs from "@components/ui/Tabs/Tabs"
import { NavOption } from "@components/ui/Tabs/types"
import config from "@root/config"
import QuestionsList from "./QuestionsList"
import AnswersList from "./AnswersList"

import styles from "./KeywordManagementSidebar.module.scss"

const hideAnswers = !!config.DISABLE_OPPORTUNITIES_ANSWERS

export interface KeywordManagementSidebarProps extends ModalProps {
  title?: never
}

export const KeywordManagementSidebar: React.FC<KeywordManagementSidebarProps> =
  observer(({ ...rest }) => {
    const tabOptions = React.useMemo(() => {
      const res: NavOption[] = [
        { value: "question", label: "Questions", icon: "question-circle" },
      ]

      if (!hideAnswers)
        res.push({ value: "answer", label: "Answers", icon: "file-list" })

      return res
    }, [])

    const [activeTab, setActiveTab] = React.useState(tabOptions[0])

    return (
      <SidebarContainer {...rest}>
        <div className={styles.root}>
          <Tabs
            options={tabOptions}
            currentValue={activeTab}
            onChange={setActiveTab}
            underlined
            fullWidth
          />

          {tabOptions[0]?.value === activeTab.value && <QuestionsList />}
          {tabOptions[1]?.value === activeTab.value && <AnswersList />}
        </div>
      </SidebarContainer>
    )
  })

export default KeywordManagementSidebar
