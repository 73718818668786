import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import { FormikProvider, useFormik } from "formik"

import { useController, useStore } from "@store/index"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import Table from "@components/ui/Table/Table"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { FormStepName } from "@store/manage-campaigns/validation"
import { nameValidationSchema as validationSchema } from "@store/manage-campaigns/validation/budget"
import { useEditorFormRedirect } from "@pages/PMax/ActiveSectionContext"
import { renderShortEnumeration } from "@utils/stringUtils"
import { mbNoData } from "@services/utils"
import SuccessModal from "@components/modals/ConfirmModal/SuccessModal"
import useToggle from "@framework/hooks/useToggle"
import Cortege from "./Cortege"
import BudgetBiddingTable from "./BudgetBiddingTable"
import SettingsTable from "./SettingsTable"
import CampaignInfoTable from "./CampaignInfoTable"

import styles from "./FormSummary.module.scss"

const STEP_NAME: FormStepName = "summary"

export type FormSummaryProps = {
  className?: string
  onSubmit?: () => void
  onCancel?: () => void
}

const FormSummary: React.FC<FormSummaryProps> = observer(
  ({ onSubmit, onCancel, className }) => {
    const alert = useAlert()
    const successModal = useToggle()

    const { editPMaxCampaignStore: store } = useStore()
    const { editPMaxCampaignController } = useController()

    const handleSuccess = () => {
      successModal.setOpened(false)
      onSubmit?.()
    }

    const handleSubmit = async (form: any) => {
      const data = { ...form, isDraftMode: false, status: "ENABLED" }
      const error = await editPMaxCampaignController.saveDraft(
        STEP_NAME,
        data,
        true
      )

      if (error != null) {
        alert.error(
          <AlertMessage title="Failed to save draft" description={error} />
        )
        return
      }

      successModal.setOpened(true)
      store.updateData(data)
    }

    const formik = useFormik<any>({
      initialValues: validationSchema.cast(store.data, {
        stripUnknown: true,
      }),
      onSubmit: handleSubmit,
    })

    return (
      <FormikProvider value={formik}>
        <form
          className={clsx(styles.root, className)}
          onSubmit={formik.handleSubmit}
        >
          <Stack gutter="24" align="stretch">
            <Templates.Header
              offset="none"
              left={
                <Stack gutter="4">
                  <Typography type="h2">Review your campaign</Typography>
                  <Typography type="caption1" color="black80Color">
                    Double-check your details before you publish
                  </Typography>
                </Stack>
              }
              right={
                <Button size="big" type="submit">
                  Publish campaign
                </Button>
              }
            />

            <CampaignInfoTable />

            <BudgetBiddingTable />

            <SettingsTable />

            <AssetGroupsTable />

            <Templates.Header
              right={
                <>
                  <Button size="big" color="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button size="big" type="submit">
                    Publish campaign
                  </Button>
                </>
              }
            />
          </Stack>
        </form>

        <SuccessModal
          title="Publishing Successful"
          message="This campaign was successfully published on Google."
          applyText="Back to Campaigns"
          isOpen={successModal.isOpened}
          onClose={handleSuccess}
        />
      </FormikProvider>
    )
  }
)

export default FormSummary

const AssetGroupsTable: React.FC = () => {
  const { assetGroupsStore } = useStore()

  const editAssets = useEditorFormRedirect("assets", "assets")

  const assetNames = assetGroupsStore.data?.map((it) => it.name)

  return (
    <Stack gutter="16" direction="column">
      <Typography type="h2">Asset group</Typography>
      <Box className={styles.section}>
        <Table>
          <Cortege label="Asset Groups" onEdit={editAssets}>
            {mbNoData(assetNames, renderShortEnumeration)}
          </Cortege>
        </Table>
      </Box>
    </Stack>
  )
}
