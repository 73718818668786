/* eslint-disable no-underscore-dangle */
import { action, observable } from "mobx"
import moment from "moment"
import { TimeSegmentedPerformanceReport } from "@framework/types/metrics"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import clientService from "@services/client.service"
import RootStore from "../RootStore"

const getYear = (shift: number = 0): Date =>
  moment.utc().startOf("year").add(shift, "year").toDate()

class ClientMonthlyReportStore {
  // INJECTIONS

  @observable root: RootStore

  constructor(rootStore: RootStore) {
    this.root = rootStore
  }

  // STATE

  @observable currentYear: Date = getYear(0)

  @observable previousYear: Date = getYear(-1)

  @observable currYearPerf: TimeSegmentedPerformanceReport[] | null = null

  @observable prevYearPerf: TimeSegmentedPerformanceReport[] | null = null

  @observable historicalKPILoadError: string | null = null

  @observable loadingYearPerformance: boolean = false

  @observable checkbookAmount: number | null = null

  // ACTIONS

  @action loadYearPerformance = async (accountId: number) => {
    this.loadingYearPerformance = true
    try {
      this.currYearPerf = null
      this.prevYearPerf = null
      this.historicalKPILoadError = null
      const [current, previous] = await Promise.all([
        this._loadMonthlyPerformance(accountId, this.currentYear),
        this._loadMonthlyPerformance(accountId, this.previousYear),
      ])
      this.currYearPerf = current
      this.prevYearPerf = previous
    } catch (error) {
      this.historicalKPILoadError = "error"
    }
    this.loadingYearPerformance = false
  }

  private _loadMonthlyPerformance = async (
    accountId: number,
    startOfYear: Date
  ) =>
    clientService
      .getPerformancePeriodReport(accountId, {
        from: moment.utc(startOfYear).format(YYYY_MM_DD_DATE_FORMAT),
        to: moment
          .utc(startOfYear)
          .endOf("year")
          .format(YYYY_MM_DD_DATE_FORMAT),
        periodicity: "monthly",
      })
      .then((response) => response.data.data)

  @action loadMonthlyCheckbookAmount = async (clientId: number) => {
    try {
      const response = await clientService.getAccountCheckbookAmount(clientId)
      this.checkbookAmount = Number.isNaN(response.data.data)
        ? 0
        : response.data.data
    } catch (error) {
      // Loading data failed
    }
  }
}

export default ClientMonthlyReportStore
