export const demoAccounts = [
  {
    accountId: 10, // River Inn
    links: ["https://www.washingtonplazahotel.com/"],
  },
  {
    accountId: 11, // Windsor Suites
    links: ["https://www.warwickrittenhouse.com/"],
  },
  {
    accountId: 56, // AutoCamp - Cape Cod
    links: ["https://www.capecodderresort.com/"],
  },
  {
    accountId: 9, // Hotel Hive
    links: ["https://thegrahamgeorgetown.com/"],
  },
  {
    accountId: 53,
    links: [
      "https://www.godiva.com/",
      "https://www.lindtusa.com/",
      "https://www.ghirardelli.com/",
      "https://mrchocolate.com/",
      "https://hukitchen.com/",
      "https://www.harryanddavid.com/",
    ],
  },
  // ??
  {
    accountId: 80,
    links: [
      "https://www.harryanddavid.com/",
      "https://homesick.com/",
      "https://www.yankeecandle.com/",
      "https://www.anthropologie.com/",
      "https://birthdate.com/",
    ],
  },
  {
    accountId: 71,
    links: [
      "https://www.kindredbravely.com/",
      "https://www.motherhood.com/",
      "https://www.seraphine.com/",
      "https://bumpboxes.com/",
      "https://www.hatchcollection.com/",
      "https://itsbodily.com/",
    ],
  },
  {
    accountId: 72,
    links: [
      "https://www.aventon.com/",
      "https://lectricebikes.com/",
      "https://www.radpowerbikes.com/",
      "https://blixbike.com/",
      "https://www.trek.com",
      "https://www.velotricbike.com/",
    ],
  },
  {
    accountId: 46,
    links: [
      "https://www.2ndswing.com/",
      "https://www.3balls.com/",
      "https://www.adamsgolf.com/",
      "https://www.callawaygolf.com/",
      "https://www.bombtechgolf.com/",
      "https://www.pxg.com/",
    ],
  },
  // ??
  {
    accountId: 118,
    links: [
      "https://www.amh.com/",
      "https://rentprogress.com/",
      "https://www.divvyhomes.com/",
      "https://forrent.com/",
      "https://www.invitationhomes.com/",
    ],
  },
]

export const allIndustriesWebsites = demoAccounts.flatMap<string>(
  (it) => it.links
)

export const getAccountByURL = (url: string) =>
  demoAccounts.find((item) => item.links.includes(url.toLowerCase()))
