import { observer } from "mobx-react-lite"
import React from "react"

import Stack from "@components/ui/Stack/Stack"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Typography from "@components/ui/Typography/Typography"
import { UserData } from "@framework/types/user"
import { mbNoData } from "@services/utils"
import { useStore } from "@store/index"
import { ColumnProps } from "./UserTable"

export const AccessDetails: React.FC<ColumnProps<UserData>> = observer(
  ({ mapper, data: user = {} }) => {
    const { settings } = useStore()

    const permission = user.permissions?.[0]
    const accessLevel = permission?.objectType
    const typeMapper = accessLevel ? settings.getMapper(accessLevel) : {}
    const accessed = permission?.objectId?.map(
      (id) => typeMapper[id]?.label ?? id
    )
    const text = mbNoData(accessed?.join(", "))
    const list = mbNoData(accessed?.join(",\n"))
    return (
      <Stack gutter="0" direction="row">
        <Tooltip text={list} overflowWidth="auto">
          <Typography>{text}</Typography>
        </Tooltip>
      </Stack>
    )
  }
)

export default AccessDetails
