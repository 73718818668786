/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react"
import clsx from "clsx"
import { toJS } from "mobx"
import { useAlert } from "react-alert"

import { FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import {
  AccountProfileFormData,
  accountProfileValidationSchema,
} from "@utils/validators"
import { AccountGoalSettingsOption } from "@framework/types/account"
import { ID } from "@framework/types/types"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import Goals from "./components/Goals/Goals"
import Information from "./components/Information/Information"
import WebsiteKPIs from "./components/WebsiteKPIs/WebsiteKPIs"
import Segmentation from "./components/Segmentation/Segmentation"

import styles from "./AccountInformation.module.scss"

export type AccountInformationProps = {
  className?: string
}

const AccountInformation: React.FC<AccountInformationProps> = observer(
  ({ className }) => {
    const alert = useAlert()
    const {
      manageProfileStore: {
        isEdit,
        accountInfo,
        setIsEdit,
        submitFormCallback,
        setSubmitCallback,
      },
      accountDashboardStore,
      accountStore: { accountId, accountUpdating, loading, updateAccountInfo },
    } = useStore()

    const initialValues = React.useMemo(
      () =>
        accountProfileValidationSchema.cast(
          {
            ...accountProfileValidationSchema.getDefault(),
            ...accountInfo,
          },
          {
            stripUnknown: true,
          }
        ) as AccountProfileFormData,
      [accountInfo]
    )

    const handleUpdateGoals = useCallback(
      async (accountId: ID, data: AccountGoalSettingsOption[]) => {
        const error =
          await accountDashboardStore.goals.updateAccountGoalSettings(
            accountId,
            data
          )
        if (error)
          alert.error(
            <AlertMessage
              title="Failed to update Account Goals"
              description={error}
            />
          )
      },
      [accountId, accountDashboardStore.goals.updateAccountGoalSettings]
    )

    const handleUpdateAccountInfo = useCallback(
      async (accountId: ID, data) => {
        const { industryId, ...rest } = data

        const requestData = {
          industryId: Number.parseFloat(industryId),
          ...rest,
        }
        const error = await updateAccountInfo(accountId, requestData)
        if (error)
          alert.error(
            <AlertMessage
              title="Failed to update Account Information"
              description={error}
            />
          )
      },
      [updateAccountInfo]
    )

    const handleSubmit = useCallback(
      async (data: AccountProfileFormData) => {
        if (!accountId) return
        const { goals, ...accountInfo } = accountProfileValidationSchema.cast(
          data,
          { stripUnknown: true }
        ) as AccountProfileFormData
        await handleUpdateAccountInfo(accountId, accountInfo)
        if (goals.length) {
          await handleUpdateGoals(accountId, goals)
        }
      },
      [accountId, handleUpdateAccountInfo, handleUpdateGoals]
    )

    const formik = useFormik<AccountProfileFormData>({
      initialValues,
      validateOnBlur: true,
      validationSchema: accountProfileValidationSchema,
      onSubmit: handleSubmit,
    })

    const isTouched = useMemo(
      () => Object.keys(formik.touched).length,
      [formik.touched]
    )

    useEffect(() => {
      if (!accountUpdating && submitFormCallback) {
        setIsEdit(false)
      }
    }, [accountUpdating])

    useEffect(() => {
      if (isEdit) formik.resetForm({ values: initialValues })
    }, [isEdit])

    useEffect(() => {
      if (formik.dirty && formik.isValid) {
        setSubmitCallback(formik.submitForm)
      } else {
        setSubmitCallback()
      }
    }, [formik.dirty, formik.isValid, formik.submitForm])

    useEffect(() => {
      const newValues = accountProfileValidationSchema.cast(
        {
          ...accountProfileValidationSchema.getDefault(),
          ...accountInfo,
        },
        {
          stripUnknown: true,
        }
      ) as AccountProfileFormData

      formik.setValues(newValues)
    }, [accountInfo])

    useEffect(() => () => setIsEdit(false), [setIsEdit])

    return (
      <FormikProvider value={formik}>
        <div className={clsx(styles.root, className)}>
          <Loader isLoading={loading} />
          <Information />
          <WebsiteKPIs />
          <Goals />
          <Segmentation />
        </div>
      </FormikProvider>
    )
  }
)

export default AccountInformation
