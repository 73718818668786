import { ID } from "@framework/types/types"
import { nanoid } from "nanoid"
import { AdCopyTask } from "./edit-adCopy.store"

class DuplicateAdCopyTask implements AdCopyTask {
  constructor(adCopyIds: ID[]) {
    this.adCopyIds = adCopyIds
  }

  readonly id = nanoid()

  readonly type = "duplicate"

  readonly adCopyIds: ID[]
}

export default DuplicateAdCopyTask
