import React, { useEffect, useState } from "react"
import Calendar from "react-calendar"
import clsx from "clsx"
import { format } from "date-fns"
import ReactInputMask from "react-input-mask"
import moment from "moment"

import "./Calendar.scss"
import Button from "@components/ui/Button/Button"
import {
  DATE_MASK,
  DIVIDER,
  parseDateStr,
  validateDates,
  datesToString,
} from "./utils"

import styles from "./DatePickerCard.module.sass"

interface DatePickerCardProps {
  defValue?: [Date, Date]
  minDate?: Date
  maxDate?: Date
  className?: string
  name?: string
  onApply?: (date: [Date, Date], e: React.MouseEvent) => void
  onCancel?: (e: React.MouseEvent) => void
}

const DatePickerCard: React.FC<DatePickerCardProps> = ({
  defValue,
  minDate,
  maxDate,
  name,
  className,
  onApply,
  onCancel,
}) => {
  const [isValid, setValid] = useState(true)
  const [date, setDate] = useState<[Date, Date] | undefined>()
  const [value, setValue] = useState<string>("")

  useEffect(() => {
    if (defValue) handlePick(defValue)
  }, [defValue])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)
    setValid(false)

    if (value.includes("_")) return

    const dates = parseDateStr(value || " ", true)
    if (Array.isArray(dates) && validateDates(dates)) {
      const sorted = (
        dates.length > 1
          ? dates.sort((d1, d2) => moment(d1).unix() - moment(d2).unix())
          : dates
      ) as [Date, Date]

      const dateStr = datesToString(sorted)
      setValue(dateStr)
      setDate(sorted)
      setValid(true)
    }
  }

  const handleBlur = (e: any) => {
    if (value.includes("_")) return
    setValid(validateDates(parseDateStr(value, true)))
  }

  const handlePick = (value: Date | [Date, Date]) => {
    if (!Array.isArray(value)) return
    const normalized = value.map((it) =>
      moment(it).startOf("day").toDate()
    ) as [Date, Date]
    const dateStr = datesToString(normalized)
    setValue(dateStr)
    setDate(normalized)
    setValid(validateDates(normalized))
  }

  const handleApply = (e: React.MouseEvent) => {
    if (date != null) onApply?.(date, e)
  }

  const complete = !value.includes("_")

  const mask = `${DATE_MASK}${DIVIDER}${DATE_MASK}`

  const canApply = complete && isValid

  return (
    <div className={clsx(styles.root, className)}>
      <ReactInputMask
        type="text"
        name={name}
        mask={mask}
        value={value}
        className={clsx(styles.input, {
          [styles.invalid]: complete && !isValid,
        })}
        onChange={handleInputChange}
        onFocus={() => setValid(true)}
        onBlur={handleBlur}
      />
      <Calendar
        minDate={minDate}
        maxDate={maxDate}
        selectRange
        onChange={handlePick}
        value={date as any}
        className={clsx("react-calendar", styles.calendar)}
        formatMonthYear={(_, date) => format(date, "MMM yyyy")}
        formatMonth={(_, date) => format(date, "MMM")}
        formatShortWeekday={(_, date) => format(date, "EEEEE")}
      />
      <div className={styles.control}>
        <Button fullWidth color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          fullWidth
          color="primary"
          disabled={!canApply}
          onClick={handleApply}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

export default DatePickerCard
