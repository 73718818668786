import React, { useState } from "react"
import { throttle } from "lodash"
import { observer } from "mobx-react-lite"

import TextField from "@components/ui/TextField/TextField"
import Select from "@components/ui/DropDown"
import Popper from "@components/ui/DropDown/Popper"
import usePopper from "@components/ui/DropDown/usePopper"
import { ID } from "@framework/types/types"
import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import SelectLocationTable from "./SelectLocationTable"

import styles from "./SearchLocation.module.scss"

export interface SearchLocationProps {
  className?: string
  onSelect?: (action: string, option: ID) => void
}

export const SearchLocation: React.FC<SearchLocationProps> = observer(
  ({ className, onSelect }) => {
    const { locationSuggestionsStore: suggestionsStore } = useStore()
    const { editPMaxCampaignController: controller } = useController()

    const [query, setQuery] = useState("")

    const [popperNode, setPepperNode] = useState<HTMLElement | null>(null)
    const [containerNode, setContainerNode] = useState<HTMLElement | null>(null)
    const { isActive, style } = usePopper(containerNode, popperNode, {
      disabled: !query,
      defaultValue: !!query,
    })

    const search = React.useCallback(
      throttle((query: string) => {
        controller.searchLocations(query)
      }, 300),
      []
    )

    React.useEffect(() => {
      if (query) search(query)
      else controller.dropLocationSuggestions()
    }, [controller, query, search])

    return (
      <>
        <TextField
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          ref={setContainerNode}
          className={className}
          placeholder="Enter a location to target or exclude"
          before={<Icon name="search" />}
          after={
            query ? <Icon name="cross" onClick={() => setQuery("")} /> : null
          }
        />
        <Popper ref={setPepperNode} style={style} isActive={isActive}>
          <Select.Box className={styles.tableBox}>
            <Select.ItemsList>
              <Select.Group>
                {suggestionsStore.data.length ? (
                  <SelectLocationTable
                    title="Matches"
                    list={suggestionsStore.data}
                    onAction={onSelect}
                  />
                ) : suggestionsStore.isLoading ? (
                  <Loader isLoading />
                ) : (
                  <NoData>No locations found by query</NoData>
                )}
              </Select.Group>
              {/* <Select.Group>
            <SelectLocationTable
              title="Relocated locations"
              list={relocateOptions}
              onAction={onSelect}
            />
          </Select.Group> */}
            </Select.ItemsList>
          </Select.Box>
        </Popper>
      </>
    )
  }
)

export default SearchLocation
