import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import { FormStepName } from "@store/manage-campaigns/validation"

export interface ContextData {
  activeForm: string | null
}

const ActiveSectionContext = React.createContext<ContextData | null>(null)

// StoreProvider to inject root store using react context
export const ActiveSectionContextProvider: React.FC = ({
  children,
}): React.ReactElement => {
  const location = useLocation<{ activeForm?: string }>()

  const activeForm = location.state?.activeForm ?? null

  return (
    <ActiveSectionContext.Provider value={{ activeForm }}>
      {children}
    </ActiveSectionContext.Provider>
  )
}

export const useActiveSectionContext = (): ContextData => {
  const context = React.useContext(ActiveSectionContext)
  if (context == null)
    throw new Error("Can't find ActiveSectionContext provider")

  return context
}

export const useActiveSection = <RT extends Element>(sectionName: string) => {
  const context = useActiveSectionContext()

  const isActive = context.activeForm === sectionName

  const ref = React.useRef<RT>(null)

  React.useEffect(() => {
    if (isActive)
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center" })
  }, [isActive])

  return { isActive, props: { ref } }
}

export const useEditorFormRedirect = (
  stepName: FormStepName,
  formName: string
) => {
  const location = useLocation()
  const history = useHistory()

  const handleEdit = () => {
    history.push(`${location.pathname}?s=${stepName}`, {
      activeForm: formName,
    })
  }

  return handleEdit
}
