import React from "react"
import { useField } from "formik"
import { observer } from "mobx-react-lite"

import Label from "@components/ui/Label/Label"
import SimpleSelect, {
  SimpleSelectProps,
} from "@components/ui/DropDown/SimpleSelect"
import { useFormMetaContext } from "./FormMetaContext"

export interface FormSelectProps<T extends string>
  extends SimpleSelectProps<T> {
  name: string
  label?: string
  hideArrow?: boolean
}

const FormSimpleSelectField = observer(
  <T extends string>({
    name,
    label,
    placeholder,
    ...rest
  }: FormSelectProps<T>) => {
    const formMeta = useFormMetaContext()

    const [field, meta, helpers] = useField(name)

    const withValidate = !!meta.touched

    return (
      <Label
        label={label}
        error={withValidate && meta.touched ? meta.error : ""}
      >
        <SimpleSelect
          error={!!meta.error}
          edited={formMeta?.isEdited(name)}
          value={Array.isArray(field.value) ? undefined : field.value}
          placeholder={Array.isArray(field.value) ? "<varies>" : placeholder}
          onSelect={(v) => helpers.setValue(v)}
          {...rest}
        />
      </Label>
    )
  }
)

export default FormSimpleSelectField
