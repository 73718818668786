import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"

import FormTextField from "@framework/prototypes/FormTextField"
import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Typography from "@components/ui/Typography/Typography"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { passwordValidator } from "@components/utils/validators"

const validationSchema: yup.BaseSchema = yup.object({
  password: passwordValidator
    .clone()
    .trim()
    .required("Can not be empty")
    .default(""),
  passwordConfirm: yup
    .string()
    .trim()
    .required("Can not be empty")
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .default(""),
})

type ChangePasswordForm = yup.InferType<typeof validationSchema>

const initialValues: ChangePasswordForm = validationSchema.getDefault()

export const ChangePassword: React.FC = observer(() => {
  const {
    userStore: { isLoading, changePassword },
  } = useStore()
  const alert = useAlert()

  const handleSubmit = async (form: ChangePasswordForm) => {
    const error = await changePassword(form)
    if (error) {
      alert.error(
        <AlertMessage
          title="Failed to update your password"
          description={error}
        />
      )
      return
    }
    alert.info(
      <AlertMessage
        title="Your password has been changed successfully"
        description="Please login back using your new password"
      />
    )
    formik.resetForm()
  }

  const formik = useFormik<ChangePasswordForm>({
    initialValues,
    validationSchema,
    onSubmit: (form) => handleSubmit(validationSchema.cast(form)),
  })

  const valid = Object.keys(formik.touched).length && formik.isValid

  return (
    <FormikProvider value={formik}>
      <Stack gutter="24" align="stretch">
        <Typography type="h2">Change your password</Typography>

        <FormTextField
          label="New password"
          name="password"
          placeholder="Enter your new password"
          type="password"
        />

        <FormTextField
          label="Confirm password"
          name="passwordConfirm"
          placeholder="Enter your old password"
          type="password"
        />

        <Stack direction="row" justify="flex-end">
          <Button disabled={isLoading || !valid} onClick={formik.submitForm}>
            Change password
          </Button>
        </Stack>
      </Stack>
    </FormikProvider>
  )
})

export default ChangePassword
