import { diff } from "deep-object-diff"
import { observable, action, computed } from "mobx"
import AbstractFilter from "./AbstractFilter"

export class SimpleFilter<T extends object> extends AbstractFilter {
  @observable active: T

  @observable temp: T

  constructor() {
    super()
    this.temp = {} as T
    this.active = {} as T
  }

  @computed get tempFiltersCount() {
    return Object.values(this.temp).length
  }

  @computed get activeFiltersCount() {
    return Object.values(this.active).length
  }

  @computed get filtersCountDifference() {
    return Object.values(diff(this.active, this.temp)).length
  }

  @computed get isSync() {
    return !Object.values(diff(this.active, this.temp)).length
  }

  @action resetTemp = () => {
    this.temp = this.active
  }

  @action resetAll = () => {
    this.active = {} as T
    this.temp = {} as T
  }

  @action applyFilter = () => {
    this.active = this.temp
  }

  @action setValue = (value: T = {} as T) => {
    this.temp = value
  }
}

export default SimpleFilter
