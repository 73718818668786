/* eslint-disable no-useless-escape */
import camelCase from "lodash/camelCase"

import {
  AccountData,
  AdGroupEntity,
  CampaignEntity,
} from "@framework/types/account"
import { AccountInfoType } from "@framework/types/manageProfile"
import {
  AdCopyEntity,
  AdCopyData,
  AdCopyGroupData,
  CampaignData,
  AdCopyDetailsEntity,
  AdCopyDetailsData,
} from "@framework/types/adCopy"
import { ID } from "@framework/types/types"
import { initArrayByLength } from "@utils/numberUtils"
import { AssignedAttribute } from "../opportunities/opportunities.transformer"
import { AssignedAttributes } from "./types"

export const initialAccountInfo: AccountInfoType = {
  name: "",
  street: "",
  city: "",
  state: "",
  postal: "",
  website: "",
  industryId: "",
  country: "",
  yearlyAdBudget: "",

  aov: "",
  conversionRate: "",
  resClick: "",
  roas: "",
}

export const transformAccountForm = (
  data?: AccountData | null
): AccountInfoType => {
  if (!data) return { ...initialAccountInfo }

  return Object.keys(initialAccountInfo).reduce<AccountInfoType>(
    (acc, key) => {
      const value = data[key as keyof AccountData]
      if (value) acc[key as keyof AccountInfoType] = value.toString()
      return acc
    },
    { ...initialAccountInfo }
  )
}

export const transformAssignedAttributes = (
  assignedAttributesList: AssignedAttribute[]
) => {
  let isAllAttributesProcessed = assignedAttributesList.length > 0
  const assignedAttributes: AssignedAttributes = new Set()

  for (let i = 0; i < assignedAttributesList.length; i += 1) {
    const attribute = assignedAttributesList[i]

    assignedAttributes.add(attribute.id)

    isAllAttributesProcessed =
      isAllAttributesProcessed &&
      attribute?.assignedProperties?.[0]?.hasKeywords !== undefined &&
      attribute.assignedProperties[0].hasKeywords
  }
  return {
    assignedAttributes,
    isAllAttributesProcessed,
  }
}

export const countFirstNoneEmpty = (
  object: any,
  keys: string[],
  min: number = 0
) =>
  keys.reduce(
    (minCount, key, idx) =>
      (object as any)[key] && idx >= minCount - 1 ? idx + 1 : minCount,
    min
  )

export const transformAdCopyData = (
  keys: string[],
  account: AccountData,
  minRowCount: number = 1
) => {
  const noneEmptyEntriesNumber = countFirstNoneEmpty(account, keys, minRowCount)
  const entries = [...keys]
    .splice(0, noneEmptyEntriesNumber)
    .map<[string, string]>((key) => {
      const text = (account as any)[key]
      return [key, text]
    })

  return entries
}

export const transformCampaign = ({
  Id,
  Name,
  Adgroups,
}: CampaignEntity): CampaignData => ({
  id: Id.toString(),
  name: Name,
  condition: "new", // TODO
  groupIds: Adgroups?.length ? Adgroups.map((it) => it.Id.toString()) : null,
})

export const transformAdGroup = (
  campaignId: ID,
  { Id, Name }: AdGroupEntity
): AdCopyGroupData => ({
  id: Id.toString(),
  name: Name,
  campaignId: campaignId.toString(),
})

export const transformAdCopy = ({
  id,
  adCopyID,
  adGroupID,
  campaignID,
  PropertyID,
  modified,
  ...rest
}: AdCopyEntity): AdCopyData => ({
  id: id.toString(),
  modified,
  adCopyID: adCopyID.toString(),
  adGroupID: adGroupID.toString(),
  campaignID: campaignID.toString(),
  propertyID: PropertyID.toString(),
  ...rest,
})

export const transformAdCopyDetails = (
  item: AdCopyDetailsEntity
): AdCopyDetailsData => {
  const {
    id,
    adCopyID,
    adGroupID,
    campaignID,
    PropertyID,
    modified,
    status,
    FinalURL: finalUrl,
    MobileURL: mobileUrl,
    ModifiedFields: modifiedFields,
    PromptSettings: promptSettings,
  } = item
  return {
    id: id.toString(),
    condition: !modified || modified === "none" ? "new" : "updated",
    modified,
    status,
    adCopyID: adCopyID.toString(),
    adGroupID: adGroupID.toString(),
    campaignID: campaignID.toString(),
    propertyID: PropertyID.toString(),
    modifiedFields: modifiedFields?.map(camelCase),
    ...Object.fromEntries(
      initArrayByLength(15).map((idx) => {
        const fromKey = `Headline${idx + 1}` as keyof AdCopyDetailsEntity
        const toKey = `headline${idx + 1}` as keyof AdCopyDetailsData
        return [toKey, item[fromKey]]
      })
    ),
    ...Object.fromEntries(
      initArrayByLength(15).map((idx) => {
        const fromKey = `Headline${
          idx + 1
        }Position` as keyof AdCopyDetailsEntity
        const toKey = `headline${idx + 1}Position` as keyof AdCopyDetailsData
        return [toKey, item[fromKey]]
      })
    ),
    ...Object.fromEntries(
      initArrayByLength(4).map((idx) => {
        const fromKey = `Description${idx + 1}` as keyof AdCopyDetailsEntity
        const toKey = `description${idx + 1}` as keyof AdCopyDetailsData
        return [toKey, item[fromKey]]
      })
    ),
    finalUrl,
    mobileUrl,
    promptSettings,
  } as any as AdCopyDetailsData
}

export const transformAdCopiesResponse = (
  items: AdCopyEntity[]
): AdCopyData[] => items.map((copy) => transformAdCopy(copy))

export const transformCampaignsResponse = (
  items: CampaignEntity[]
): { campaigns: CampaignData[]; campAdGroups: AdCopyGroupData[] } => {
  const campaigns: CampaignData[] = []

  const campAdGroups = items.flatMap((camp) => {
    campaigns.push(transformCampaign(camp))
    return (
      camp.Adgroups?.map((adGroup) => transformAdGroup(camp.Id, adGroup)) ?? []
    )
  })

  return {
    campaigns,
    campAdGroups,
  }
}
