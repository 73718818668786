/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import ReactDOM from "react-dom"

import useToggle from "@framework/hooks/useToggle"
import ComicBubble, { ComicBubbleProps } from "../ComicBubble/ComicBubble"
import { JustificationType, PlacementType } from "../types"
import { calcPointCoords } from "./utils"

import styles from "./OnClickTooltipContainer.module.scss"

export type OnClickTooltipContainerProps = {
  className?: string
  placement?: PlacementType
  justify?: JustificationType
  toolTip?: React.ReactNode
  relative?: boolean
  onChange?: (active: boolean) => void
}

export const OnClickTooltip: React.FC<OnClickTooltipContainerProps> = ({
  children,
  placement = "top",
  justify = "center",
  className,
  toolTip,
  relative = false,
  onChange,
}) => {
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const { isOpened: active, setOpened: setActive } = useToggle()

  const childRef = useRef<HTMLDivElement>(null)
  const rootRef = useRef<HTMLDivElement>(null)

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!active) {
      setActive(true)
      setCoords(
        calcPointCoords(rootRef.current as HTMLElement, placement, relative)
      )
    }
    e.stopPropagation()
  }

  useEffect(() => {
    if (active) {
      const handleClick = (e: MouseEvent) => {
        if (rootRef.current && rootRef.current.contains(e.target as Node)) {
          return
        }
        if (childRef.current && childRef.current.contains(e.target as Node)) {
          return
        }
        setActive(false)
      }

      document.addEventListener("mousedown", handleClick)
      return () => {
        document.removeEventListener("mousedown", handleClick)
      }
    }
    return undefined
  }, [active, setActive])

  useEffect(() => {
    if (active && !relative) {
      const handleHide = () => {
        setActive(false)
      }

      document.addEventListener("wheel", handleHide)
      return () => {
        document.removeEventListener("wheel", handleHide)
      }
    }
    return undefined
  }, [relative, active, setActive])

  useEffect(() => {
    onChange?.(active)
  }, [onChange, active])

  const renderTooltip = () => {
    const bubbleProps: ComicBubbleProps = {
      coords,
      hidden: !active,
      placement,
      justify,
      containerClassName: relative ? styles.relative : "",
    }

    const TooltipNode = (
      <ComicBubble {...bubbleProps} ref={childRef}>
        {toolTip}
      </ComicBubble>
    )
    if (relative) return TooltipNode
    return ReactDOM.createPortal(TooltipNode, document.body)
  }

  return (
    <div
      ref={rootRef}
      className={clsx(styles.root, { [styles.relative]: relative }, className)}
      onClick={handleClick}
    >
      {children}

      {active && renderTooltip()}
    </div>
  )
}

export default OnClickTooltip
