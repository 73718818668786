/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import { MapperOption } from "../types"

import styles from "./RowWithLabel.module.scss"

type LabelProps = {
  withBorder?: boolean
  label?: string
  className?: string
  columnClassName?: string
  mapper: MapperOption[]
  children?: (item: MapperOption) => React.ReactNode
}

const RowWithLabel: React.FC<LabelProps> = ({
  label,
  children,
  mapper,
  className,
  withBorder = false,
  columnClassName,
}) => (
  <div className={clsx(styles.root, className)}>
    <Typography type="h6" color="gray" upperCase>
      {label}
    </Typography>
    <div
      className={clsx(
        styles.row,
        { [styles.withBorder]: withBorder },
        columnClassName
      )}
    >
      {mapper.map(children ?? (() => null))}
    </div>
  </div>
)

export default RowWithLabel
