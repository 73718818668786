import clsx from "clsx"
import React from "react"

import CircleLoader from "./CircleLoader"
import LoaderText from "./TextLoader"

import styles from "./PlaceholderLoader.module.scss"

export interface PlaceholderLoaderProps {
  className?: string
}

const PlaceholderLoader: React.FC<PlaceholderLoaderProps> = ({
  children,
  className,
}) => (
  <div className={clsx(styles.root, className)}>
    <LoaderText>{children}</LoaderText>
    <CircleLoader />
  </div>
)

export default PlaceholderLoader
