import React from "react"
import clsx from "clsx"

import { QuadSizeType } from "@utils/enums"
import Typography from "@components/ui/Typography/Typography"
import usePrevious from "@framework/hooks/usePrevious"

import styles from "./BaseProgressCircle.module.scss"

const MIN_VALUE = 0
const MAX_VALUE = 100

const stroke = 10
const center = 50
const r = center - stroke
const strokeLength = Math.PI * 2 * r

export interface BaseProgressCircleProps {
  /**
   * @param value should be between 0 and 100
   */
  value: number
  size?: QuadSizeType
  transitionTime?: string
  percentageShow?: boolean
}

export const BaseProgressCircle: React.FC<BaseProgressCircleProps> = ({
  value,
  size = QuadSizeType.medium,
  transitionTime,
  percentageShow = true,
}: BaseProgressCircleProps) => {
  const rootStyles = clsx(styles.root, styles[size])
  const percentage =
    value > MAX_VALUE ? MAX_VALUE : value < MIN_VALUE ? MIN_VALUE : value

  const oldPercentage = usePrevious(percentage)

  const barStyles = {
    "--bar-transition-speed":
      percentage === 100
        ? "0.1s"
        : oldPercentage && oldPercentage > percentage
        ? "0s"
        : transitionTime,
    strokeDashoffset: strokeLength - strokeLength * (percentage / 100),
    strokeDasharray: strokeLength,
    transformOrigin: center,
    rotate: "-90deg",
  } as React.CSSProperties

  return (
    <div className={rootStyles}>
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <circle r={r} cx={center} cy={center} className={styles.frame} />
        <circle
          r={r}
          cx={center}
          cy={center}
          className={styles.progress}
          style={barStyles}
        />
      </svg>
      {percentageShow && (
        <Typography color="black70Color" className={styles.label}>
          {percentage}%
        </Typography>
      )}
    </div>
  )
}

export default BaseProgressCircle
