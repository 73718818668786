import { ChartArea } from "chart.js"

export const createRadialGradient = (
  ctx?: CanvasRenderingContext2D,
  area?: ChartArea
) => {
  if (!ctx || !area) return "#5cbd9f00"
  const { width, height } = area

  const gradient = ctx.createRadialGradient(
    width / 2,
    -height / 8,
    0,
    width / 2,
    height / 8,
    height * 1.3
  )
  gradient.addColorStop(0.5, "rgba(92, 189, 159, 0.4)")
  gradient.addColorStop(1, "rgba(23, 137, 158, 0)")
  return gradient
}

export const createLinearAreaGradient = (
  ctx?: CanvasRenderingContext2D,
  area?: ChartArea
) => {
  if (!ctx || !area) return "#5cbd9f00"
  const { height } = area

  const gradient = ctx.createLinearGradient(0, 0, 0, height)
  gradient.addColorStop(0, "rgba(92, 189, 159, 0.4)")
  gradient.addColorStop(1, "rgba(23, 137, 158, 0)")
  return gradient
}

export const createLineGradient = (
  ctx?: CanvasRenderingContext2D,
  area?: ChartArea
) => {
  if (!ctx || !area) return "#17899e"
  const { width, height } = area
  const gradient = ctx.createRadialGradient(
    width / 2,
    -height / 8,
    0,
    width / 2,
    height / 8,
    height * 1
  )
  gradient.addColorStop(0.5, "#17899e")
  gradient.addColorStop(1, "#5cbd9f")
  return gradient
}
