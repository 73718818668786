import { action, computed, observable } from "mobx"
import { MetricNameType } from "@framework/types/metrics"
import { MetricOption } from "./types"

export class MetricSequence {
  constructor(data: MetricOption[] | MetricSequence) {
    if (data instanceof MetricSequence) {
      this.metrics = new Map(
        Array.from(data.metrics.entries()).map(([_, value]) => [
          value.name,
          { ...value },
        ])
      )
      this.sequence = [...data.sequence]
    } else {
      const copied = data.map<[MetricNameType, MetricOption]>((value) => [
        value.name,
        { ...value },
      ])
      this.sequence = copied.map((it) => it[0])
      this.metrics = new Map(copied)
    }
  }

  @observable sequence: MetricNameType[] = []

  @observable metrics: Map<string, MetricOption> = new Map()

  @computed get isActive() {
    const { getById } = this
    return (id: string) => Boolean(getById(id)?.isEnabled)
  }

  @computed get allActive() {
    const { isActive } = this
    return this.sequence.filter((name) => isActive(name))
  }

  @computed get getById() {
    const { metrics } = this
    return (id: string) => metrics.get(id)
  }

  @action selectMetric = (id: MetricNameType) => {
    const metric = this.getById(id)
    if (metric == null) return
    metric.isEnabled = !metric.isEnabled
  }

  @action swipeMetrics = (fromIdx: number, toIdx: number) => {
    const element = this.sequence.splice(fromIdx, 1)[0]
    this.sequence.splice(toIdx, 0, element)
  }
}

export default MetricSequence
