/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from "react"
import clsx from "clsx"
import { observer, useLocalStore } from "mobx-react-lite"
import _chunk from "lodash/chunk"
import _ceil from "lodash/ceil"
import _floor from "lodash/floor"
import { action, computed, observable } from "mobx"

import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Typography from "@components/ui/Typography/Typography"
import { AttributeCategoryType, AttributeType } from "@services/account.service"
import { AssignedAttributes } from "@store/manageProfile/types"
import { ID } from "@framework/types/types"

import styles from "./AttributesForm.module.scss"

const CHUNK_SIZE = 10
const COLUMN_NUMBER = 5

type AttributesFormProps = {
  className?: string
  selectedAttributes?: AssignedAttributes
  categories?: AttributeCategoryType[]
  attributes?: Record<number, AttributeType[]>
  onChange?: (data: AssignedAttributes) => void
}

export const AttributesForm: React.FC<AttributesFormProps> = observer(
  ({
    className,
    selectedAttributes,
    categories = [],
    attributes = {},
    onChange,
  }) => {
    const store = useLocalStore(() => new SelectedList<number>())

    useEffect(() => {
      store.setSelected(selectedAttributes)
    }, [selectedAttributes])

    const isAttributeSelected = (attrId: number) => store.selected.has(attrId)

    const handleChange = (attributeId: number) => {
      if (isAttributeSelected(attributeId)) store.selected.delete(attributeId)
      else store.selected.add(attributeId)
    }

    useEffect(() => {
      onChange?.(store.selected)
    }, [onChange, store.selected.size])

    let chunksBefore = 0
    return (
      <div className={clsx(styles.root, className)}>
        {categories?.map((category, idx) => {
          const attributeList = attributes?.[category.id] ?? []
          const chunksInList = _ceil(attributeList.length / CHUNK_SIZE)
          chunksBefore += chunksInList
          return (
            <GroupedAttributes
              gray={!!(idx % 2)}
              title={category.displayName || category.name}
              attributes={attributeList}
              chunksBefore={chunksBefore}
              isSelected={isAttributeSelected}
              onChange={handleChange}
              // eslint-disable-next-line react/no-array-index-key
              key={`${category.name}_${idx}`}
            />
          )
        })}
      </div>
    )
  }
)

interface GroupedAttributesProps {
  title: string
  gray?: boolean
  attributes: AttributeType[]
  chunksBefore: number
  isSelected: (id: number) => boolean
  onChange: (id: number) => void
}

const GroupedAttributes: React.FC<GroupedAttributesProps> = ({
  title,
  gray = false,
  attributes,
  chunksBefore,
  isSelected,
  onChange,
}) => {
  const attributeList = useMemo(
    () => _chunk(attributes, CHUNK_SIZE),
    [attributes]
  )
  return (
    <>
      {attributeList.map((chunk, idx) => (
        <div
          className={clsx(styles.chunk, {
            [styles.gray]: gray,
            [styles.withLeftBorder]:
              chunksBefore % COLUMN_NUMBER && attributeList.length - 1 === idx,
            [styles.withTopBorder]: !!_floor((chunksBefore - 1) / 5),
          })}
          key={chunk[0].id}
        >
          <div className={styles.title}>
            {idx === 0 ? (
              <Typography type="h4" color="primary" upperCase>
                {title}
              </Typography>
            ) : null}
          </div>
          <div className={styles.content}>
            {chunk.map((attribute) => {
              const id = attribute.id.toString()
              const { name } = attribute
              return (
                <label className={styles.wrapper} id={id} key={id}>
                  <CheckBox
                    id={id}
                    value={id}
                    checked={isSelected(attribute.id)}
                    onClick={() => onChange(attribute.id)}
                  />
                  <Typography type="h3" className={styles.keyword}>
                    {name}
                  </Typography>
                </label>
              )
            })}
          </div>
        </div>
      ))}
    </>
  )
}

class SelectedList<T extends ID = ID> {
  @observable selected: Set<T>

  constructor(initial: Set<T> = new Set()) {
    this.selected = new Set(initial)
  }

  @action setSelected = (initial: Set<T> = new Set()) => {
    this.selected = new Set(initial)
  }

  @computed get isSelected() {
    return (name: T) => this.selected.has(name)
  }
}

export default AttributesForm
