import React, { useEffect, useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useHistory, useLocation } from "react-router-dom"
import _ceil from "lodash/ceil"

import { QuadSizeType } from "@components/utils/enums"
import IndicatedProgressBar from "@components/ui/ProgressBar/IndicatedProgressBar/IndicatedProgressBar"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import Button from "@components/ui/Button/Button"
import { numberWithCommas } from "@components/utils/numberUtils"
import { secondsToRemainText } from "@utils/date"
import { GENERATE_PER_HOUR } from "@store/manageProfile/manageProfile"

import styles from "./KeywordGenerationFooter.module.scss"

export type KeywordGenerationFooterProps = {
  className?: string
}

const INTERVAL_KEYWORDS_STATS_POLLING = 10_000

export const KeywordGenerationFooter: React.FC<KeywordGenerationFooterProps> =
  observer(({ className }) => {
    const { pathname } = useLocation()
    const history = useHistory()

    const {
      manageProfileStore: {
        keywordsGenerationStats,
        showBar,
        isGenerating,
        setShowBar,
        loadKeywordsGenerationStats,
      },
      accountStore: { accountId },
    } = useStore()

    const handleShowResult = () => {
      setShowBar(false)
      history.push("./opportunities")
    }

    const data = useMemo(() => {
      if (keywordsGenerationStats) {
        if (isGenerating) {
          if (
            keywordsGenerationStats.running &&
            keywordsGenerationStats.generated > 0
          ) {
            const { generated, unique, processed } = keywordsGenerationStats
            const progress = _ceil((processed / unique) * 95)
            const time = _ceil(
              ((generated - processed) / GENERATE_PER_HOUR) * 3600
            )
            const remainsTimeText = secondsToRemainText(time)
            return {
              message: `Generating and analyzing search volume for ${numberWithCommas(
                generated
              )} keywords. ${remainsTimeText}`,
              percentage: progress,
            }
          }

          return {
            message: `Starting keyword generating process`,
            percentage: 0,
          }
        }

        const { generated, finished_at, started_at } = keywordsGenerationStats
        const finishDate = Date.parse(finished_at)
        const startDate = Date.parse(started_at)
        if (finishDate && finishDate > 0 && finishDate > startDate) {
          return {
            message: `Generating process is completed. ${numberWithCommas(
              generated
            )} keyword generated`,
            percentage: 100,
          }
        }
      }
      return null
    }, [keywordsGenerationStats, isGenerating])

    useEffect(() => {
      if (accountId) loadKeywordsGenerationStats(accountId)
    }, [accountId, loadKeywordsGenerationStats])

    useEffect(() => {
      if (keywordsGenerationStats) {
        if (accountId && keywordsGenerationStats.running) {
          const timer = setTimeout(() => {
            loadKeywordsGenerationStats(accountId)
          }, INTERVAL_KEYWORDS_STATS_POLLING)
          return () => clearTimeout(timer)
        }
      }
      return undefined
    }, [keywordsGenerationStats, accountId, loadKeywordsGenerationStats])

    const shouldShow = !!["opportunities", "manage-profile"].find((item) =>
      pathname.includes(item)
    )

    const barSpeed = keywordsGenerationStats?.running ? "2s" : ".3s"

    return shouldShow && data && showBar ? (
      <div className={clsx(styles.root, className)}>
        <div className={styles.footer}>
          <div className={styles.barContainer}>
            <div className={styles.column}>
              <Typography type="h3" color="light">
                {data.message}
              </Typography>
              <IndicatedProgressBar
                className={styles.bar}
                value={data.percentage}
                size={QuadSizeType.tiny}
                showPercentage={false}
                barTransitionTime={barSpeed}
              />
            </div>
            {!isGenerating && (
              <Button color="secondary" onClick={handleShowResult}>
                {pathname.includes("opportunities") ? "Close" : "Show result"}
              </Button>
            )}
          </div>
        </div>
      </div>
    ) : null
  })

export default KeywordGenerationFooter
