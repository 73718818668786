import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import ModalTemplate from "@components/ui/Modal/ModalTemplate"

import styles from "./CreateAdCopyModal.module.sass"

type CreateAdCopyModalProps = {
  isOpen: boolean
  onClose: () => void
  onManualClick: () => void
  onAIClick: () => void
}

const CreateAdCopyModal: React.FC<CreateAdCopyModalProps> = observer(
  ({ isOpen, onManualClick, onAIClick, onClose }) => {
    const handleManual = () => {
      onManualClick()
      onClose()
    }

    const handleAI = () => {
      onAIClick()
      onClose()
    }

    return (
      <Modal
        className={styles.root}
        isOpen={isOpen}
        onClose={onClose}
        hideHeader
      >
        <ModalTemplate.Header
          title="New AdCopy"
          subtitle="Choose how do you want to create AdCopy"
        />

        <ModalTemplate.Body>
          <Stack gutter="16" align="stretch">
            <div className={clsx(styles.card, styles.green)}>
              <Typography type="h2">Manually</Typography>
              <Typography type="caption1" color="black70Color">
                You can create New AdCopy manually.
              </Typography>
              <Button onClick={handleManual}>Create manually</Button>
            </div>

            <div className={clsx(styles.card, styles.blue)}>
              <Typography type="h2">With AI Helper</Typography>
              <Typography type="caption1" color="black70Color">
                You can create New AdCopy with help and suggestions from AI
                generator. All you need is to fill the form and our AI Helper
                come back to you with content decisions which you can use ,
                regenerate or rewrite.
              </Typography>
              <Button onClick={handleAI} className={styles.blue}>
                Create with AI Helper
              </Button>
            </div>
          </Stack>
        </ModalTemplate.Body>

        <ModalTemplate.Footer>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
        </ModalTemplate.Footer>
      </Modal>
    )
  }
)

export default CreateAdCopyModal
