import React from "react"
import clsx from "clsx"
import Icon from "@components/ui/Icon/Icon"

import styles from "./StarsBar.module.scss"

export interface IStarsBarProps {
  color?: string
  className?: string
  length?: number
  value?: number
  max?: number
}

export const StarsBar: React.FC<IStarsBarProps> = ({
  className,
  value = 0,
  max = 100,
  length = 5,
  color = "gold",
}) => {
  const classNames = clsx(styles.root, className)

  const normValue = value / (max / length / 2)
  return (
    <div className={classNames} style={{ color }}>
      {Array.from(Array(length)).map((_, idx) => {
        const limit = (idx + 1) * 2
        const starName =
          normValue >= limit - 1
            ? normValue >= limit
              ? "star-full"
              : "star-half"
            : "star-empty"
        return <Icon name={starName} />
      })}
    </div>
  )
}

export default StarsBar
