import React from "react"
import { observer } from "mobx-react-lite"

import IntroForm, { FormData } from "./IntroFormV2"
import { useDemoMultiformContext } from "./tasks/FormManager"

import styles from "./IntroSubForm.module.scss"

const IntroSubForm: React.FC = observer(() => {
  const formContext = useDemoMultiformContext()

  const handleSubmit = async (data: FormData) => {
    await formContext.saveIntro(data)
    formContext.startKeywordsIndexing()
    formContext.nextStep()
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <IntroForm
          initialValues={formContext.introData}
          loading={formContext.isLoading}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  )
})

export default IntroSubForm
