import type { Platform, CampaignGroup } from "@framework/constants/report"
import type { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import type {
  MetricNameType,
  PerformanceReport,
} from "@framework/types/metrics"

export type MetricOption = {
  name: MetricNameType
  isEnabled: boolean
}

export type ReportFilterRequest = {
  accounts: number[]
  channels: Partial<Record<Platform, CampaignGroup[]>>
  platforms: Platform[]
}

export type ExtendedReportFilterRequest = {
  accounts: number[]
  channels: Partial<Record<Platform, CampaignGroup[]>>
  platforms: Platform[]
  campaignsStatus: string
}

export type AccountReport = {
  id: string
  accountId: number
  label: string
  campaignGroupReportIds?: string[]
  metaCampaignsReportIds?: string[]
  platformReportIds?: string[]
  monthlyReportIds?: string[]
  weeklyReportIds?: string[]
} & PerformanceReport

export type PlatformReport = {
  id: string
  platformName: Platform
  label: string
  accountReportIds?: string[]
  campaignGroupReportIds?: string[]
  metaCampaignsReportIds?: string[]
} & PerformanceReport

export type ChannelReport = {
  id: string
  label: string
  channelName: CampaignGroup
  campaignReportIds: string[]
} & PerformanceReport

export type CampaignReport = {
  id: string
  campaignId: number
  label: string
  status: string
} & PerformanceReport

export type MetaCampaignReport = {
  adSetIds: string[]
} & CampaignReport

export type AdSetReport = {
  id: string
  label: string
  status: string
} & PerformanceReport

export type PeriodReport = {
  id: string
  label: string
  periodicity: PerformanceReportPeriodicity
  periodIndex: number
  period: string // YYYY-MM-DD
  platformReportIds: string[]
} & PerformanceReport

export const campaignStatusList = [
  "ANY",
  "ENABLED",
  "PAUSED",
  "REMOVED",
] as const

export type CampaignStatusType = (typeof campaignStatusList)[number]
