/* eslint-disable prefer-destructuring */
import { action, computed, observable } from "mobx"
import uniq from "lodash/uniq"
import sortBy from "lodash/sortBy"

import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import {
  CampaignGroup,
  Platform,
  bundlePlatformParams,
} from "@framework/constants/report"
import PlatformTimeSegmentedPerformanceStore from "./platform-period-performance.store"

export class TimeSegmentedPerformanceStore {
  @observable reports: Map<Platform, PlatformTimeSegmentedPerformanceStore> =
    new Map()

  @observable isLoading = false

  @observable loadingError: string | null = null

  @observable labels: string[] = []

  @computed get getPlatformData() {
    return (platforms: Platform) => this.reports.get(platforms)?.data
  }

  @action load = async (
    period: [Date, Date],
    periodicity: PerformanceReportPeriodicity,
    accounts: number[],
    channels: CampaignGroup[],
    platforms: Platform[]
  ) => {
    try {
      this.loadingError = null
      this.isLoading = true
      this.labels = []

      const entries = platforms.map<
        [Platform, PlatformTimeSegmentedPerformanceStore]
      >((pl) => [pl as Platform, new PlatformTimeSegmentedPerformanceStore()])

      this.reports = new Map(entries)

      await Promise.all(
        entries.map(([key, instance]) =>
          instance.load(period, periodicity, {
            accounts,
            channels: bundlePlatformParams([key], channels),
            platforms: [key],
          })
        )
      )

      this.labels = sortBy(
        uniq(entries.flatMap(([_, instance]) => instance.labels))
      )
    } catch (error) {
      this.loadingError = "Unexpected error"
    } finally {
      this.isLoading = false
    }
    return this.loadingError
  }
}

export default TimeSegmentedPerformanceStore
