import React, { useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import moment from "moment"
import { observer } from "mobx-react-lite"
import uniqueId from "lodash/uniqueId"
import sum from "lodash/sum"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import BarChart from "@components/ui/Charts/BarChart/BarChart"
import { darkColors } from "@components/ui/Charts/utils"
import DatePicker from "@components/ui/DatePicker/DatePicker"
import LegendList from "@components/ui/Charts/Legends/LegendList"
import { makeLevelLinePlugin } from "@components/ui/Charts/BarChart/plugins"
import { useStore } from "@store/index"
import {
  MON_YY_DATE_FORMAT,
  YYYY_MM_DD_DATE_FORMAT,
} from "@framework/constants/moment-format"
import {
  initArrayByLength,
  numberWithCommas,
} from "@components/utils/numberUtils"
import { useOpportunitiesStore } from "@store/opportunities/provider"

import styles from "./MonthlySearchVolume.module.scss"

interface MonthlySearchVolumeProps {
  open?: boolean
  className?: string
  onToggle?: () => void
}

const lineColor = "#33B882"

const getDefaultDate = () => {
  const endDate = moment(Date.now()).endOf("month").toDate()
  return [
    moment(endDate).subtract(11, "month").startOf("month").toDate(),
    endDate,
  ]
}

const getChartPoints = (firstDate: Date, points: number) => {
  const lastMoment = moment(firstDate).startOf("month")
  return initArrayByLength(points).map((idx) =>
    lastMoment.clone().add(idx, "month").format(YYYY_MM_DD_DATE_FORMAT)
  )
}

export const MonthlySearchVolume: React.FC<MonthlySearchVolumeProps> = observer(
  ({ open, className, onToggle }) => {
    const { searchReport } = useOpportunitiesStore()
    const {
      accountStore: { accountId },
    } = useStore()

    const [date, setDate] = useState<Date | Date[]>(getDefaultDate)

    useEffect(() => {
      if (accountId && Array.isArray(date) && date.length === 2)
        searchReport.loadMonthlySearchReport(accountId, date[0], date[1])
    }, [date, accountId])

    const points = useMemo(
      () =>
        Array.isArray(date) ? getChartPoints(date[0] ?? new Date(), 12) : [],
      [date]
    )

    const dataset = useMemo(
      () => [
        {
          label: "Total",
          data: points.map(
            (key) => searchReport.monthlySearchReport?.[key] ?? 0
          ),
        },
      ],
      [points, searchReport.monthlySearchReport]
    )

    const avg = useMemo(() => {
      if (!searchReport.monthlySearchReport) return 0
      return (
        sum(Object.values(searchReport.monthlySearchReport)) /
        (searchReport.loadedMonths || 1)
      )
    }, [searchReport.loadedMonths, searchReport.monthlySearchReport])

    const labels = useMemo(
      () =>
        points.map((item) =>
          moment(item, YYYY_MM_DD_DATE_FORMAT).format(MON_YY_DATE_FORMAT)
        ) ?? [],
      [points]
    )

    const { plugins, chartKey } = useMemo(
      () => ({
        chartKey: uniqueId("chart"),
        plugins: [makeLevelLinePlugin(avg, lineColor)],
      }),
      [avg]
    )

    return (
      <Box className={clsx(styles.root, className)}>
        <div className={styles.header}>
          <Typography type="h2" color="dark">
            Monthly Search Volume
          </Typography>
          <div className={styles.control}>
            <LegendList
              items={[
                {
                  label: `The average (${numberWithCommas(avg)})`,
                  dashed: false,
                },
              ]}
              colors={[lineColor]}
            />
            <DatePicker
              defValue={date}
              onChange={setDate}
              className={styles.datePicker}
              placement="bottom-end"
              multiSelect
            />
            <Icon
              name="arrow-right"
              rotateAngle={open ? -90 : 90}
              onClick={onToggle}
              className={styles.collapseArrow}
            />
          </div>
        </div>
        {!!open && (
          <div className={styles.chartContainer}>
            <BarChart
              dataset={dataset}
              labels={labels}
              className={styles.chart}
              colors={darkColors}
              hideLegends
              hideLoader
              plugins={plugins}
              key={chartKey}
            />
          </div>
        )}
      </Box>
    )
  }
)

export default MonthlySearchVolume
