import { observer } from "mobx-react-lite"
import React from "react"
import SystemMessage from "@components/ui/SystemMessage/SystemMessage"

import { capitalizeFirstLetter } from "@components/utils/stringUtils"
import useServiceDownInterceptor from "./useServiceDownInterceptor"

import styles from "./ServiceDownInterceptor.module.scss"

const ServiceDownInterceptor: React.FC = observer(() => {
  const errorsStore = useServiceDownInterceptor()

  const errorMessage =
    errorsStore.errors.values().next().value?.error ??
    "Service is temporary down, try again later"

  if (errorsStore.isAnyServiceDown) {
    return (
      <SystemMessage
        className={styles.root}
        type="error"
        message={capitalizeFirstLetter(errorMessage)}
      />
    )
  }
  return null
})

export default ServiceDownInterceptor
