import { observer } from "mobx-react-lite"
import React from "react"

import Icon from "@components/ui/Icon/Icon"
import OnClickTooltip from "@components/ui/Tooltip/TooltipContainer/OnClickTooltipContainer"
import Typography from "@components/ui/Typography/Typography"
import { UserData } from "@framework/types/user"
import { mbNoData } from "@services/utils"
import { ColumnProps } from "./UserTable"
import { getDescriptor, getPermissionLevelOption } from "./utils"

import styles from "./UserRoleColumn.module.scss"

export const UserRolesColumn: React.FC<ColumnProps<UserData>> = observer(
  ({ mapper, data: user = {} }) => {
    const permission = user.permissions?.[0]

    const option = getPermissionLevelOption(permission?.accessLevel)

    if (!permission) return <Typography className={styles.root}>-</Typography>
    const { accessLevel, objectType } = permission

    const contentNode = (
      <Typography className={styles.root}>
        {user.isAdmin ? "Admin" : mbNoData(option?.label)}
        <Icon name="information-solid" />
      </Typography>
    )

    return (
      <OnClickTooltip
        placement="bottom"
        toolTip={
          <Typography className={styles.message}>
            {getDescriptor(objectType, accessLevel)}
          </Typography>
        }
      >
        {contentNode}
      </OnClickTooltip>
    )
  }
)

export default UserRolesColumn
