import {
  CampaignType,
  ConditionFrequencyType,
  OperatorType,
  RuleActionType,
} from "@framework/types/manageCampaign"
import { Option } from "@framework/types/types"

export type RuleType = "DATE" | "BUDGET"

export const ruleTypeOptions: Option<RuleType>[] = [
  { label: "Time Period", value: "DATE" },
  { label: "Budget", value: "BUDGET" },
]

export const conditionFrequencyOptions: Option<ConditionFrequencyType>[] = [
  { label: "Monthly", value: "MONTHLY" },
  { label: "Daily", value: "DAILY" },
]

export const triggerOptions: Option<RuleType>[] = [
  { label: "Budget", value: "BUDGET" },
]

export const actionOptions: Option<RuleActionType>[] = [
  { label: "Pause", value: "PAUSE" },
  { label: "Allow 5% overbudget", value: "5_PERCENT_OVER_BUDGET" },
  { label: "Allow 10% overbudget", value: "10_PERCENT_OVER_BUDGET" },
  { label: "Allow 15% overbudget", value: "15_PERCENT_OVER_BUDGET" },
  { label: "Allow 20% overbudget", value: "20_PERCENT_OVER_BUDGET" },
  { label: "Keep Active", value: "KEEP_ACTIVE" },
]

export const campaignTypeOptions: Option<CampaignType>[] = [
  { label: "All Campaigns", value: "ALL_CAMPAIGNS" },
  { label: "Brand Campaigns", value: "BRAND_CAMPAIGNS" },
  { label: "Non-brander Campaigns", value: "NON_BRAND_CAMPAIGNS" },
  { label: "Custom", value: "CUSTOM" },
]

export const operatorOptions: Option<OperatorType>[] = [
  // { label: "Less Than", value: "LESS_THAN" },
  // { label: "Less or Equal", value: "LESS_THAN_EQUAL" },
  { label: "Equals", value: "EQUAL" },
  // { label: "Greater than", value: "GREATER_THAN" },
  // { label: "Greater or Equal", value: "GREATER_THAN_EQUAL" },
]
