import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useAlert } from "react-alert"

import Loader from "@components/ui/Loader/Loader"
import useQueryParams from "@framework/hooks/useQueryParams"

export const linkAccountCases = [
  "client",
  "account",
  "google-ads",
  "pre-registration",
  "create-campaign",
] as const

export type LinkAccountCases = (typeof linkAccountCases)[number]

export type RedirectState = {
  linkingCase: LinkAccountCases
  requestState: any
}

type LinkedAccountsProps = {}

const LinkAccountRedirect: React.FC<LinkedAccountsProps> = observer(() => {
  const history = useHistory()
  const params = useQueryParams()
  const alert = useAlert()

  useEffect(() => {
    try {
      const { state, code } = params
      if (!state) throw new Error("Empty linking state")

      if (!code) throw new Error("Code was not sent")

      const { linkingCase, requestState } = JSON.parse(
        decodeURIComponent(state)
      ) as RedirectState

      if (!linkAccountCases.includes(linkingCase))
        throw new Error("Unexpected linking case")

      if (linkingCase === "pre-registration") {
        history.replace(`/pre-registration?code=${code}`, {
          form: JSON.parse(requestState),
        })
        return
      }

      const id = requestState?.id

      if (id == null) throw new Error("Id was not passed")

      if (linkingCase === "client") {
        history.replace(
          `/client/${id}/profile?alter=linkedAccounts&code=${code}`
        )
        return
      }

      if (linkingCase === "account") {
        history.replace(
          `/account/${id}/manage-profile?alter=linkedAccounts&code=${code}`
        )
        return
      }

      if (linkingCase === "google-ads") {
        history.replace(
          `/google-ads/${id}/manage-profile?alter=linkedAccounts&code=${code}`
        )
        return
      }

      if (linkingCase === "create-campaign") {
        history.replace(
          `/google-ads/${id}/create-campaign?code=${code}&phase=link`
        )
        return
      }
    } catch (err) {
      alert.error("Failed to link account")
    }
    history.push("/")
  }, [params, history])

  return <Loader />
})

export default LinkAccountRedirect
