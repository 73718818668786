import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { WaterfallTable } from "@components/ui/WaterfallTable/WaterfallTable"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { useStore } from "@store/index"
import { useTableContext } from "./TableContext"
import PlatformList from "./PlatformList"
import AccountReportList from "./AccountReportList"

import styles from "./index.module.scss"

type MetricsTableProps = {
  className?: string
  mapper?: MapperType[]
}

export const MetricsTable: React.FC<MetricsTableProps> = observer(
  ({ className, mapper = [] }) => {
    const {
      reports: { extendedReport },
    } = useStore()

    const context = useTableContext()

    return (
      <div className={clsx(styles.root, className)}>
        <WaterfallTable
          label="Campaign Name"
          labelSortable
          mapper={mapper}
          labelClassName={styles.labelText}
          onSort={context.toggleOrder}
          sortBy={context.sortBy}
          withTip
        >
          {context.viewType === "Platform" ? (
            <PlatformList list={extendedReport.platformIds} mapper={mapper} />
          ) : (
            <AccountReportList
              list={extendedReport.accountIds}
              mapper={mapper}
            />
          )}
        </WaterfallTable>
      </div>
    )
  }
)

export default MetricsTable
