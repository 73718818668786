import React from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import { useAlert } from "react-alert"

import DefaultLayout from "@root/layouts/DefaultLayout/DefaultLayout"
import PlaceholderLoader from "@components/ui/Loader/PlaceholderLoader"
import NoData from "@components/ui/NoData/NoData"
import { useController, useStore } from "@store/index"
import { ID } from "@framework/types/types"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { EditCampaignForm } from "./EditCampaignForm"
import { ActiveSectionContextProvider } from "../ActiveSectionContext"

export const EditCampaignPage: React.FC = observer(() => {
  const { campaignId } = useParams<{
    campaignId: string
  }>()
  const alert = useAlert()

  const {
    accountStore: { accountId },
  } = useStore()

  const { editPMaxCampaignStore: store } = useStore()
  const { editPMaxCampaignController: controller } = useController()

  const init = async (campaignId: ID) => {
    const error = await controller.loadCampaign(campaignId)
    if (error != null) {
      alert.error(
        <AlertMessage title="Failed to load campaign" description={error} />
      )
    }
  }

  React.useEffect(() => {
    if (campaignId && accountId) init(campaignId)
  }, [accountId])

  React.useEffect(() => {
    controller.loadLanguages()
    return () => {
      controller.resetCampaignData()
    }
  }, [])

  if (!store.isInit && store.isLoading)
    return <PlaceholderLoader>Loading Campaign draft</PlaceholderLoader>

  return (
    <ActiveSectionContextProvider>
      <DefaultLayout>
        {campaignId != null && !store.isInit ? (
          <NoData />
        ) : (
          <EditCampaignForm />
        )}
      </DefaultLayout>
    </ActiveSectionContextProvider>
  )
})

export default EditCampaignPage
