import React, { FC } from "react"
import useToggle from "@framework/hooks/useToggle"
import { Collapsable } from "@components/ui/Collapsible/Collapsable"
import { CollapsibleHeader } from "@components/ui/Collapsible/CollapsibleHeader"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import Image from "@components/ui/Image/Image"
import { FieldArray, useFormikContext } from "formik"
import { observer } from "mobx-react-lite"
import IconButton from "@components/ui/Button/IconButton"
import { YoutubeVideoAssetEntity } from "@framework/types/manageCampaign"
import FormTextField from "@framework/prototypes/FormTextField"
import { EditAssetGroupFormValues } from "@pages/PMax/components/EditAssetGroupPage/EditAssetGroupForm"
import styles from "./Assets.module.scss"

interface VideoProps {
  handleDeleteVideo: (id: number) => void
  videos: YoutubeVideoAssetEntity[]
}
const Videos: FC<VideoProps> = observer(({ handleDeleteVideo, videos }) => {
  const { setFieldValue } = useFormikContext<EditAssetGroupFormValues>()
  const toggle = useToggle()

  const videoList: YoutubeVideoAssetEntity[] =
    videos && videos.length ? videos : []

  const handleAddField = () => {
    if (!toggle.isOpened) {
      toggle.setOpened(true)
    }
    setFieldValue("youtubeVideos", [...videoList, { url: "", name: "" }], true)
  }

  return (
    <div className={styles.section}>
      <CollapsibleHeader
        label="Videos"
        onClick={toggle.handleToggle}
        open={toggle.isOpened}
        type="h2"
        rootClassName={styles.collapsibleHeader}
      />
      <Typography type="subtitle" color="gray">
        Add up to 5 videos. If you don’t have one, google will create videos
        automatically when possible.
      </Typography>
      <div className={styles.videoPreviews}>
        {videoList.slice(0, 4).map((preview, i) => (
          <Image
            src={preview.ThumbUrlDefault}
            className={styles.image}
            key={`${i + 1}-video`}
          />
        ))}
      </div>
      {videoList.length < 5 && (
        <Button
          color="primary"
          before={<Icon name="plus" key="plus" />}
          variant="ghost"
          className={styles.buttonIcon}
          onClick={handleAddField}
        >
          Video
        </Button>
      )}
      <Collapsable open={toggle.isOpened}>
        <div className={styles.editVideosForm}>
          <FieldArray
            name="youtubeVideos"
            render={(formikProps) => (
              <div>
                {videoList && videoList.length ? (
                  videoList.map((item, i: number) => (
                    <div
                      className={styles.videoEdit}
                      key={`youtubeVideos-${i + 1}`}
                    >
                      {item.ThumbUrlDefault && (
                        <Image
                          src={item.ThumbUrlDefault}
                          className={styles.videoImage}
                        />
                      )}
                      <div className={styles.input}>
                        <FormTextField
                          className={styles.videoUrlField}
                          name={`youtubeVideos[${i}].url` || ""}
                        />
                        <IconButton
                          color="secondary"
                          variant="ghost"
                          onClick={() => {
                            if (item.id) {
                              handleDeleteVideo(item.id)
                            }
                            formikProps.remove(i)
                          }}
                        >
                          <Icon name="trash" />
                        </IconButton>
                      </div>
                    </div>
                  ))
                ) : (
                  <div />
                )}
                {formikProps.form.errors.youtubeVideos && (
                  <Typography type="body2" color="red">
                    {formikProps.form.errors.youtubeVideos
                      ? "Please, fill the required fields."
                      : ""}
                  </Typography>
                )}
              </div>
            )}
          />
        </div>
      </Collapsable>
    </div>
  )
})

export default Videos
