/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment"
import _sumBy from "lodash/sumBy"
import _groupBy from "lodash/groupBy"
import _sortBy from "lodash/sortBy"
import _capitalize from "lodash/capitalize"
import {
  ChartDataset,
  ChartPoint,
  KeywordCategory,
  KeywordCategoryGroup,
  RecommendedKeyword,
} from "@framework/types/opportunities"
import { kebabCaseToTitle } from "@components/utils/stringUtils"

export interface AssignedAttribute {
  distance: number
  id: number
  name: string
  point: [number, number] // [lng, lat]
  source: string
  thirdPartyId: string
  assignedProperties?: {
    active: boolean
    hasKeywords: boolean
    inUse: boolean
    relevancyScore?: number
  }[]
}

export interface KeywordEntity extends AssignedAttribute {
  activeEndDate: Date
  activeStartDate: Date
  attributeStats: {
    attributeId: number | null
    volume: number
    id: number
    name: string
    attribute: null
    competition: number
    cpc: number
    funnel_segment: "awareness" | "consideration" | ""
  }[]
  monthlyVolume: ChartPoint[]
  funnel?: string
  category: KeywordCategory
  categoryId: number
  subCategory: string
  ratingTotal?: number
  starRating?: number
  vicinity?: string
  // bestKeywords param = true
  attachedKeywords?: {
    Keywords?: {
      keyword: "string"
      variants: ["string"]
    }[]
    Total?: number
  }
}

export const transformKeywords = (
  keyword: KeywordEntity[]
): RecommendedKeyword[] => keyword.map(transformKeyword)

export const transformLabel = (date: string): string =>
  moment.utc(date).format("MMM 'YY")

export const transformPointToChartData = (points: ChartPoint[]): ChartDataset =>
  _sortBy(points, (it) => it.date).reduce<ChartDataset>(
    (acc, item) => {
      const { date, volume } = item
      acc.labels.push(transformLabel(date))
      acc.data.push(volume)
      return acc
    },
    {
      labels: [],
      data: [],
    }
  )

export const transformKeyword = (
  keyword: KeywordEntity
): RecommendedKeyword => {
  const {
    attributeStats,
    assignedProperties,
    monthlyVolume,
    point,
    ratingTotal,
    attachedKeywords,
  } = keyword

  const stat = attributeStats?.length ? attributeStats[0] : null
  const avgCpc = stat?.cpc ?? 0
  const competition = stat?.competition ?? 0
  const avgSearchVolume = stat?.volume ?? 0
  const mySearchVolume = avgSearchVolume
  const estimate = avgCpc * avgSearchVolume
  const active = assignedProperties?.[0].active
  const inUse = assignedProperties?.[0].inUse
  const relevanceScope = assignedProperties?.[0].relevancyScore
  const budgetFraction = 100
  const topKeywords =
    attachedKeywords?.Keywords?.map(({ keyword }) => _capitalize(keyword)) ?? []

  return {
    id: keyword.id,
    name: keyword.name,
    displayName: keyword.name,
    distance: keyword.distance,
    category: {
      ...keyword.category,
      displayName:
        keyword.category.displayName || kebabCaseToTitle(keyword.category.name),
    },
    funnelStage: keyword.funnel,
    starRating: keyword.starRating,
    address: keyword.vicinity,
    active,
    inUse,
    avgSearchVolume,
    estimate,
    avgCpc,
    mySearchVolume,
    relevanceScope,
    graphPoints: monthlyVolume ?? [],
    budgetFraction,
    competition,
    location: { lat: point[1], lng: point[0] },
    ratingTotal: ratingTotal ?? 0,
    attachedKeywords: {
      keywords: topKeywords,
      total: attachedKeywords?.Total ?? 0,
    },
  }
}

export const groupByCategory = (
  keywords: RecommendedKeyword[]
): KeywordCategoryGroup[] => {
  const groupsMap = _groupBy(keywords, (item) => item.category.id)
  return Object.values(groupsMap).map(shrinkKeywordsToCategory)
}

export const shrinkKeywordsToCategory = (
  keywords: RecommendedKeyword[]
): KeywordCategoryGroup => ({
  ...keywords[0].category,
  keywords,
  budgetFraction: 100,
  avgSearchVolume: _sumBy(keywords, "avgSearchVolume"),
  estimate: _sumBy(keywords, "estimate"),
  avgCpc: _sumBy(keywords, "avgCpc") / keywords.length,
  competition: _sumBy(keywords, "competition") / keywords.length,
  graphPoints: _sortBy(reduceGraph(keywords), (it) => it.date),
})

export const reduceGraph = (keywords: RecommendedKeyword[]): ChartPoint[] => {
  const chartMap = keywords.reduce<Record<string, number>>((acc, subList) => {
    subList.graphPoints.forEach(({ date, volume }) => {
      acc[date] = (acc[date] ?? 0) + volume
    })
    return acc
  }, {})
  return Object.entries(chartMap).map(([date, volume]) => ({ date, volume }))
}

export const transformEstimate = (
  data: RecommendedKeyword | KeywordCategoryGroup,
  ctr: number = 0
) => data.estimate * (ctr / 100) * (data.budgetFraction / 100)

export const transformCalculatorValues = (
  data: KeywordEntity[]
): { avgCpc: number; mySearchVolume: number } => {
  const accumulator = { cpc: 0, volume: 0 }
  const sum = data.reduce(reducer, accumulator)
  const avgCpc = sum.cpc / data.length
  const mySearchVolume = sum.volume

  return { avgCpc, mySearchVolume }
}

export const reducer = (
  accumulator: { cpc: number; volume: number },
  keyword: KeywordEntity
): { cpc: number; volume: number } => {
  const { attributeStats } = keyword

  const stat = attributeStats ? attributeStats[0] : null
  const volume = stat?.volume ?? 0
  const cpc = stat?.cpc ?? 1 * 0

  return { cpc: accumulator.cpc + cpc, volume: accumulator.volume + volume }
}
