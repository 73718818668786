import React from "react"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"

interface CortegeProps {
  label: string
  onEdit?: () => void
}

export const Cortege: React.FC<CortegeProps> = ({
  label,
  onEdit,
  children,
}) => (
  <Row>
    <Column width="30%">{label}</Column>
    <Column width="65%">{children}</Column>
    <Column width="5%">
      {onEdit != null && (
        <IconButton title="Edit" onClick={onEdit}>
          <Icon name="pen" />
        </IconButton>
      )}
    </Column>
  </Row>
)

export default Cortege
