import React, { useState, useRef, useEffect } from "react"
import clsx from "clsx"
import moment from "moment"
import { Option } from "@framework/types/types"
import { MM_DD_YYYY_DATE_FORMAT } from "@framework/constants/moment-format"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import ContextMenu from "@components/ui/ContextMenu/ContextMenu"
import { NavOption } from "@components/ui/Tabs/types"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import { mbNoData } from "@services/utils"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import { numberWithCommas } from "@utils/numberUtils"

import styles from "./ListItem.module.scss"

type ListItemProps = {
  className?: string
  name?: string
  industry?: string
  totalAdSpend?: number
  noOfAccount?: number
  contractStartDate?: string
  contractEndDate?: string
  contactName?: string
  remaining?: number
  options: NavOption[]
  checked?: boolean
  onAction: (name: Option) => void
  onChange?: () => void
}

const ListItem: React.FC<ListItemProps> = ({
  className,
  name,
  industry,
  noOfAccount,
  totalAdSpend,
  contractStartDate,
  contractEndDate,
  remaining,
  contactName,
  options,
  checked,
  onAction,
  onChange,
}) => {
  const rootNode = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (e: MouseEvent) => {
    if (rootNode.current && rootNode.current.contains(e.target as Node)) {
      return
    }
    setIsOpen(false)
  }

  const handleOpenMenu = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  return (
    <Box className={clsx(styles.root, className)} ref={rootNode}>
      <CheckBox checked={checked} onChange={onChange} />

      <div className={styles.wrapper}>
        <Tooltip text={name} type="h5">
          <Typography
            type="h5"
            color="dark"
            className={styles.clickable}
            onClick={() => onAction(options[0])}
          >
            {name}
          </Typography>
        </Tooltip>
        <Typography type="h5" color="dark">
          {mbNoData(industry)}
        </Typography>
        <Typography type="h5" color="dark">
          {mbNoData(noOfAccount)}
        </Typography>
        <Typography type="h5" color="dark">
          {`$${numberWithCommas(totalAdSpend)}`}
        </Typography>
        <Typography type="h5" color="dark">
          {mbNoData(contactName)}
        </Typography>
        <Typography type="h5" color="dark">
          {dateFormatted(contractStartDate)}
        </Typography>
        <Typography type="h5" color="dark">
          {dateFormatted(contractEndDate)}
        </Typography>
        <Typography type="h5" color="dark">
          {mbNoData(remaining)}
        </Typography>
        <IconButton
          size="small"
          variant="ghost"
          color={isOpen ? "default" : "primary"}
          onClick={handleOpenMenu}
        >
          <Icon name="three-dots" />
        </IconButton>
      </div>

      {isOpen && (
        <ContextMenu
          options={options}
          onClick={onAction}
          className={styles.contextMenu}
        />
      )}
    </Box>
  )
}

const dateFormatted = (date?: string) => {
  const dateMoment = moment.utc(date)
  if (dateMoment.year() < 999) return "-"
  return dateMoment.format(MM_DD_YYYY_DATE_FORMAT)
}

export default ListItem
