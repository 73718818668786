import { action, observable } from "mobx"
import React, { useContext } from "react"

export class SearchContextStore {
  @observable searchQuery = ""

  @action setSearchQuery = (value: string) => {
    this.searchQuery = value
  }
}

export const SearchContext = React.createContext<SearchContextStore | null>(
  null
)

export const useSearchContext = () => {
  const context = useContext(SearchContext)
  if (context == null) throw new Error("Can't find Table context")
  return context
}
