/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { ReactNode, useState } from "react"
import clsx from "clsx"

import {
  MetricDirection,
  MetricNameType,
  PerformanceReport,
} from "@framework/types/metrics"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import {
  TrendValue,
  TrendValueProps,
} from "@components/ui/DataChangeWidget/DataChangeWidget"
import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"

import styles from "./CampaignSegmentationTable.module.scss"

export type TableMapperItem = {
  name: MetricNameType
  label: string
  renderLabel?: (value: number) => string
  transformDirection?: (value: number) => MetricDirection
}

export type SubLabelType = string | TrendValueProps

export type SubLabelProps = {
  item: SubLabelType
}

export type TableColumnProps = {
  label: string | number
  subLabel?: string | number
  renderLabel?: (value: number) => string
  transformDirection?: (value: number) => MetricDirection
  onClick?: () => void
}

export type TableDatasetType = Record<MetricNameType, any>

export const TableColumn: React.FC<TableColumnProps> = ({
  label,
  subLabel,
  renderLabel,
  transformDirection = positiveTransformDirection,
  onClick,
}) => (
  <Typography
    className={clsx(styles.item, { [styles.clickable]: !!onClick })}
    onClick={onClick}
  >
    <div>
      {renderLabel && typeof label === "number" ? renderLabel(label) : label}
    </div>
    {subLabel ? (
      <div className={styles.subLabel}>
        {typeof subLabel === "string" ? (
          <Typography type="subhead1" color="gray">
            {subLabel.toUpperCase()}
          </Typography>
        ) : (
          <TrendValue
            value={Math.ceil(subLabel * 100)}
            metricDir={transformDirection(subLabel)}
          />
        )}
      </div>
    ) : null}
  </Typography>
)

export type TableRowProps = {
  title: string
  mapper: TableMapperItem[]
  dataset: PerformanceReport
  onTitleClick?: () => void
}

export const TableRow: React.FC<TableRowProps> = ({
  title,
  mapper,
  dataset,
  onTitleClick,
}) => (
  <div className={styles.row}>
    <TableColumn label={title} onClick={onTitleClick} key="title" />
    {mapper.map(({ name, renderLabel, transformDirection }, idx) => {
      const data = dataset[name]
      if (!data) return null

      const change =
        data.Change && !Number.isNaN(Number(data.Change))
          ? Number(data.Change)
          : data.Change ?? "-"
      return (
        <TableColumn
          label={data.Value}
          subLabel={change}
          renderLabel={renderLabel}
          transformDirection={transformDirection}
          key={idx}
        />
      )
    })}
  </div>
)

type TableBodyProps = {
  className?: string
  withDetails?: boolean
  children?: ReactNode
} & TableRowProps

export const TableRowBox: React.FC<TableBodyProps> = ({
  title,
  mapper,
  dataset,
  children,
  withDetails = false,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <Box
      padding="none"
      className={clsx(styles.body, { [styles.withDetails]: withDetails })}
    >
      <TableRow dataset={dataset} mapper={mapper} title={title} {...rest} />
      {children && isOpen ? (
        <div className={styles.detailsContainer}>{children}</div>
      ) : null}
      {withDetails && children ? (
        <Icon
          name="arrow-down"
          className={styles.openButton}
          rotateAngle={isOpen ? 180 : 0}
          onClick={() => setOpen(!isOpen)}
        />
      ) : null}
    </Box>
  )
}

export default TableRowBox
