/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { useHistory } from "react-router-dom"

import Typography from "@components/ui/Typography/Typography"
import { OrientationType, QuadSizeType } from "@components/utils/enums"
import IndicatedProgressBar from "@components/ui/ProgressBar/IndicatedProgressBar/IndicatedProgressBar"
import Button from "@components/ui/Button/Button"
import { numberWithCommas } from "@components/utils/numberUtils"
import NoData from "@components/ui/NoData/NoData"
import { useStore } from "@store/index"
import { DEF_YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"

import styles from "./MonthAdSpendChart.module.scss"

type MonthAdSpendChartProps = {
  className?: string
}

const MonthAdSpendChart: React.FC<MonthAdSpendChartProps> = observer(
  ({ className }) => {
    const history = useHistory()
    const {
      accountDashboardStore: { monthlyPerformanceStore },
      accountStore,
    } = useStore()
    const { currYearPerf, checkbookAmount } = monthlyPerformanceStore
    const { account } = accountStore

    const { dayOfMonthLeft, monthName, daysInMonth } = useMemo(() => {
      const currentDate = Date.now()
      const dayOfMonthLeft = Number.parseInt(
        moment(currentDate).format("DD"),
        10
      )
      const monthName = moment(currentDate).format("MMMM YYYY")
      const daysInMonth = moment(currentDate).daysInMonth()
      return { dayOfMonthLeft, monthName, daysInMonth }
    }, [])

    const latestMonthPerformance = useMemo(() => {
      if (currYearPerf) {
        const currentMonth = moment
          .utc()
          .startOf("month")
          .format(DEF_YYYY_MM_DD_DATE_FORMAT)
        const idx = currYearPerf.findIndex(
          ({ Period }) => Period === currentMonth
        )
        if (idx < 0) return null
        return currYearPerf[idx]
      }
      return null
    }, [currYearPerf])

    const data = useMemo(() => {
      if (account && latestMonthPerformance) {
        const totalBudget =
          checkbookAmount ?? Number.parseFloat(account.yearlyAdBudget) / 12
        const daysLeftPercent = dayOfMonthLeft / daysInMonth
        const currentSpend = latestMonthPerformance.Metrics.Cost?.Value ?? 0

        const spendPerDay = currentSpend / daysLeftPercent
        const currentPercent = (spendPerDay / totalBudget) * 100
        return {
          currentSpend,
          totalBudget,
          currentPercent,
          daysLeftPercent,
        }
      }
      return null
    }, [
      account,
      latestMonthPerformance,
      dayOfMonthLeft,
      daysInMonth,
      checkbookAmount,
    ])

    const handleCheckbookClick = () => history.push(`./checkbook`)

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type="h2" color="dark" bold>
          This Month's Ad Spend
        </Typography>
        <Typography type="h6" color="gray" upperCase>
          ({monthName})
        </Typography>

        {data && (
          <>
            <div className={styles.wrapper}>
              <div className={styles.section}>
                <div className={styles.leftSection}>
                  <Typography type="h6" color="gray" upperCase>
                    Current spend
                  </Typography>
                  <Typography type="h2" color="darkBlue" bold>
                    {data.currentSpend
                      ? `$ ${numberWithCommas(data.currentSpend)}`
                      : "N/A"}
                  </Typography>
                </div>

                <div className={styles.section}>
                  <div className={styles.leftSection}>
                    <Typography type="h6" color="gray" upperCase>
                      Monthly budget
                    </Typography>
                    <Typography type="h2" bold>
                      {data.totalBudget
                        ? `$ ${numberWithCommas(data.totalBudget)}`
                        : "N/A"}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.rightSection}>
                  <Typography type="h6" color="gray" upperCase>
                    Days Left in Month
                  </Typography>
                  <Typography type="h2" color="dark" bold>
                    {daysInMonth - dayOfMonthLeft}
                  </Typography>
                </div>
                <div className={styles.rightSection}>
                  <Typography type="h6" color="gray" upperCase>
                    Projected Spend
                  </Typography>
                  <Typography type="h2" color="dark" bold>
                    {data.currentPercent
                      ? `${numberWithCommas(data.currentPercent, 2)}%`
                      : "N/A"}
                  </Typography>
                </div>
              </div>
            </div>
            {data.totalBudget ? (
              <IndicatedProgressBar
                value={data.currentSpend}
                maxValue={data.totalBudget}
                gradient={OrientationType.vertical}
                size={QuadSizeType.medium}
                showValue
                onDrawValue={(value) => `$${value}`}
              />
            ) : (
              <NoData className={styles.noData} />
            )}
          </>
        )}

        <div className={styles.buttonWrapper}>
          <Button
            variant="ghost"
            color="primary"
            fit
            onClick={handleCheckbookClick}
            endIcon={{ name: "long-arrow-right" }}
          >
            View Yearly Checkbook
          </Button>
        </div>
      </div>
    )
  }
)

export default MonthAdSpendChart
