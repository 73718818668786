/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from "react"
import clsx from "clsx"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"

// import Button from "@components/ui/Button/Button"
// import { ButtonColor, ButtonType } from "@components/utils/enums"
import { Option } from "@framework/types/types"
import Pagination from "@components/ui/Pagination"
import { NavOption } from "@components/ui/Tabs/types"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import usePagination from "@components/ui/Pagination/usePagination"
import { useAccountNavItems } from "../navOptions"
import ColumnNames from "./components/ColumnNames/ColumnNames"
import getListItems from "./MockData"
import ListItem from "./components/ListItem/ListItem"

import styles from "./AccountList.module.scss"

const ACCOUNTS_CHUNK_SIZE = 20

export type TableMapper = Option & {
  isSortable?: boolean
}

const tableMapper: TableMapper[] = [
  { label: "Profile", value: "profile", isSortable: true },
  { label: "Industry", value: "industry", isSortable: false },
  { label: "Total contract ad spend", value: "totalSpend", isSortable: false },
  { label: "Profile status", value: "profileStatus", isSortable: false },
  { label: "Profile completion", value: "competition", isSortable: false },
  {
    label: "Connected Channels",
    value: "channels",
    isSortable: false,
  },
  { label: "Campaign status", value: "campaignStatus", isSortable: false },
]

type AccountListProps = {
  className?: string
}

const itemList = getListItems()

const AccountList: React.FC<AccountListProps> = observer(({ className }) => {
  const history = useHistory()

  const [isAllChecked, setIsAllChecked] = useState(false)
  const [sortBy, setSortBy] = useState({ value: "none", direction: false })
  const [checkedItems, setCheckedItems] = useState<string[]>([])

  const {
    accountStore: {
      clientAccounts: accountList,
      loading,
      totalAccountCount,
      loadSortedAccountList,
    },
    clientStore: { clientId },
  } = useStore()

  const pagination = usePagination({
    total: totalAccountCount,
    pageSize: ACCOUNTS_CHUNK_SIZE,
  })

  useEffect(() => {
    if (clientId)
      loadSortedAccountList(
        clientId,
        sortBy,
        pagination.options.page,
        ACCOUNTS_CHUNK_SIZE
      )
  }, [loadSortedAccountList, sortBy, pagination.options.page, clientId])

  // const handleBack = () => {
  //   history.push("/clients")
  // }

  const handleAction = useCallback(
    (id: number) => (navOption: NavOption) => {
      history.push(`/account/${id}${navOption.value}`)
    },
    [history]
  )

  const handleCheckAll = useCallback(() => {
    if (!isAllChecked) {
      const ids = itemList.map((item) => item.id)
      setCheckedItems(ids)
      setIsAllChecked(true)
    } else {
      setIsAllChecked(false)
      setCheckedItems([])
    }
  }, [isAllChecked])

  const handleCheck = useCallback(
    (id: string) => {
      const hasId = checkedItems.includes(id)
      hasId
        ? setCheckedItems((prev) => prev.filter((item) => item !== id))
        : setCheckedItems((prev) => [...prev, id])
    },
    [setCheckedItems, checkedItems]
  )

  const accountNavItems = useAccountNavItems()

  return (
    // <PrivateLayout
    //   navBar={{
    //     currentTab: accountListNavItems[0],
    //     options: accountListNavItems,
    //     title: "",
    //   }}
    //   onBack={handleBack}
    // >
    <div className={clsx(styles.root, className)}>
      {/* <div className={styles.buttonsWrapper}>
          <Button
            color={"primary"}
            startIcon={{ name: "trash" }}
            variant={ButtonType.ghost}
            onClick={handleDeleteAllChosen}
          >
            Delete all chosen
          </Button>

          <Button
            color={"primary"}
            variant={ButtonType.contained}
            onClick={handleAddAccount}
          >
            Add Account
          </Button>

          <div className={styles.button}>
            <Button
              color={"primary"}
              variant={ButtonType.outlined}
              onClick={handleBulkUpload}
              disabled
            >
              Add Account Bulk Upload
            </Button>
          </div>
        </div> */}

      <div>
        <ColumnNames
          mapper={tableMapper}
          checked={isAllChecked}
          onChange={handleCheckAll}
          onSort={setSortBy}
        />
        <Loader isLoading={loading} />
        {accountList.length
          ? accountList.map((data: any) => (
              <ListItem
                {...data}
                checked={checkedItems.includes(data.id)}
                onChange={() => handleCheck(data.id)}
                options={accountNavItems}
                onAction={handleAction(data.id)}
                key={data.id}
              />
            ))
          : !loading && <NoData>No accounts found</NoData>}
      </div>
      {pagination.controllerProps.pageCount > 1 && (
        <div className={styles.pagination}>
          <Pagination {...pagination.controllerProps} />
        </div>
      )}
    </div>
    // </PrivateLayout>
  )
})

export default AccountList
