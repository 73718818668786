import { observable, action, computed } from "mobx"
import accountService from "@services/account.service"
import AbstractFilter from "@framework/classes/AbstractFilter"
import RootStore from "@store/RootStore"
import {
  getNodeDeep,
  parseCategoryNodes,
  parseCategoryTree,
  stringifyTree,
} from "./utils"
import { Node } from "./types"

export class FilterByCategoriesStore extends AbstractFilter {
  @observable rootStore: RootStore

  constructor(root: RootStore) {
    super()
    this.rootStore = root
  }

  @observable categoryMap: Record<string, any> = {}

  @observable activePath: string[] = []

  @observable temp: Node = new Map()

  @observable active: string[] = []

  @computed get tempFiltersCount() {
    return this.temp.size ? this.countLeafs(this.temp) : 0
  }

  @computed get activeFiltersCount() {
    return this.active.length
  }

  @computed get filtersCountDifference() {
    return this.tempFiltersCount - this.activeFiltersCount
  }

  @computed get isSync() {
    return this.activeFiltersCount === this.tempFiltersCount
  }

  @action resetTemp = () => {
    this.temp = parseCategoryTree(this.active)
  }

  @action resetAll = () => {
    this.active = []
    this.temp = new Map()
    this.activePath = []
  }

  @action applyFilter = () => {
    this.active = stringifyTree(this.temp)
  }

  @action setActivePath = (path: string[]) => {
    this.activePath = path
  }

  @action selectCategory = (path: string[]) => {
    if (path.length < 1) throw new Error()
    const prevPath = path.slice(0, -1)
    const prevNode = this.getNodeDeep(prevPath)
    const last = path.at(-1)!

    if (prevNode.has(last)) {
      prevNode.delete(last)
    } else {
      prevNode.set(last, new Map())
    }
  }

  countLeafs = (node: Node) => {
    if (node.size === 0) return 1
    let res = 0
    const it = node.values()
    let result = it.next()
    while (!result.done) {
      res += this.countLeafs(result.value)!
      result = it.next()
    }
    return res
  }

  getNodeDeep = (path: string[]) => getNodeDeep(this.temp, path)

  hasNode = (path: string[]) => {
    let node: Node = this.temp
    for (let i = 0; i < path.length; i += 1) {
      node = node.get(path[i])!
      if (node == null) return false
    }
    return node != null
  }

  @observable isBasicListLoading = false

  @action loadBaseCategories = async (accountId: number) => {
    try {
      this.isBasicListLoading = true
      const response = await accountService.getCategoriesTree(accountId)
      if (response.data.data) {
        this.categoryMap = parseCategoryNodes(response.data.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isBasicListLoading = false
    }
  }
}

export default FilterByCategoriesStore
