import { MetricDirection } from "@framework/types/metrics"

export const positiveTransformDirection = (value: number): MetricDirection => {
  if (value > 0) return { direction: "up", color: "lightGreen" }
  if (value < 0) return { direction: "down", color: "red" }
  return { direction: "none", color: "gray" }
}

export const negativeTransformDirection = (value: number): MetricDirection => {
  if (value > 0) return { direction: "up", color: "red" }
  if (value < 0) return { direction: "down", color: "lightGreen" }
  return { direction: "none", color: "gray" }
}
