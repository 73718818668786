/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect } from "react"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Dashboard from "./Dashboard/Dashboard"
import ProductFeed from "./ProductFeed/ProductFeed"

interface ParamsType {
  id?: string
}

const GoogleShoppingRoot: React.FC = observer(() => {
  const history = useHistory()
  const { id } = useParams<ParamsType>()

  const {
    accountStore: { setAccountId, loadAccount, accountId },
    productFeedStore: {
      isFeedAvailable,
      subscribeOnImportStatus,
      unsubscribeOnImportStatus,
    },
  } = useStore()

  useEffect(() => {
    if (!id || Number.isNaN(id)) history.push("./")
  }, [id, history])

  useEffect(() => {
    setAccountId(Number(id) || null)
    return () => {
      setAccountId(null)
    }
  }, [setAccountId, id])

  useEffect(() => {
    if (accountId) loadAccount(accountId)
  }, [accountId, loadAccount])

  useEffect(() => {
    if (!accountId) return undefined
    subscribeOnImportStatus(accountId, isFeedAvailable ? 45_000 : 10_000)
    return unsubscribeOnImportStatus
  }, [accountId, isFeedAvailable])

  return (
    <Switch>
      <Route path="/shopping/:id/dashboard" component={Dashboard} />

      <Route path="/shopping/:id/product-feed" component={ProductFeed} />
      <Route path="/shopping/:id/**">
        <Redirect to="./dashboard" />
      </Route>
    </Switch>
  )
})

export default GoogleShoppingRoot
