/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"

import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { initArrayByLength } from "@components/utils/numberUtils"
import Pagination from "@components/ui/Pagination"
import { MonthlyCheckbookAmount } from "@framework/types/clientCheckbook"
import { MapperOption } from "@components/forms/CheckbookForm/types"
import { YEAR_MONTH_KEY_FORMAT } from "@framework/constants/moment-format"
import useToggle from "@framework/hooks/useToggle"
import Stack from "@components/ui/Stack/Stack"
import Collapsable from "@components/ui/Collapsible/Collapsable"
import CollapsibleHeader from "@components/ui/Collapsible/CollapsibleHeader"
import SimpleCheckbookTable from "../CheckbookTable/SimpleCheckbookTable"

import styles from "./CheckbookSummary.module.scss"

const mapToObject = <T extends any>(
  checkbookAmounts: T[],
  keyName: keyof T,
  valueName: keyof T
) =>
  Object.fromEntries(checkbookAmounts.map((it) => [it[keyName], it[valueName]]))

const transformCheckbook = (checkbookAmounts?: MonthlyCheckbookAmount[]) =>
  checkbookAmounts
    ? {
        planned: mapToObject(checkbookAmounts, "month", "planned"),
        actual: mapToObject(checkbookAmounts, "month", "actual"),
      }
    : {}

type CheckbookSummaryProps = {
  className?: string
}

const CheckbookSummary: React.FC<CheckbookSummaryProps> = observer(
  ({ className }) => {
    const { isOpened: openSummary, handleToggle: toggleSummary } = useToggle()

    const {
      clientCheckbookStore: {
        activeYearIndex,
        summaryReport,
        years,
        accountReportList,
        activeYear,
        setActiveYearIndex,
      },
    } = useStore()

    const yearStr = useMemo(() => {
      if (!years) return null
      return activeYear ?? null
    }, [years, activeYear])

    const mapper: MapperOption[] = useMemo(() => {
      const yearStart =
        yearStr != null
          ? moment(yearStr.toString(), "YYYY").startOf("year")
          : null
      return initArrayByLength(12).map((idx) => ({
        index: idx,
        name:
          yearStart?.clone().add(idx, "month").format(YEAR_MONTH_KEY_FORMAT) ??
          `${idx}_month`,
      }))
    }, [yearStr])

    const data = useMemo(
      () => transformCheckbook(summaryReport?.checkbooks),
      [summaryReport]
    )

    const checkbookList = useMemo(
      () =>
        accountReportList?.map(({ name, checkbooks }) => ({
          name,
          data: transformCheckbook(checkbooks),
        })) ?? [],
      [accountReportList]
    )

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type="h3" className={styles.title}>
          Planned Monthly Ad Spend Breakdown
        </Typography>

        <SimpleCheckbookTable mapper={mapper} data={data} />

        {years.length > 1 && (
          <div className={styles.control}>
            <Pagination
              forcePage={activeYearIndex}
              pageCount={years.length}
              onPageChange={({ selected }) => setActiveYearIndex(selected)}
              pageLabelBuilder={(page) => years?.[page - 1] ?? "-"}
            />
          </div>
        )}

        {!!checkbookList.length && (
          <Stack gutter="0">
            <CollapsibleHeader
              label="All checkbooks"
              open={openSummary}
              onClick={toggleSummary}
            />
            <Collapsable open={openSummary}>
              {checkbookList.map(({ name, data }) => (
                <div className={styles.section} key={name}>
                  <Typography type="h3" color="primary" bold>
                    {name}
                  </Typography>

                  <SimpleCheckbookTable mapper={mapper} data={data} />
                </div>
              )) ?? null}
            </Collapsable>
          </Stack>
        )}
      </div>
    )
  }
)

export default CheckbookSummary
