import React from "react"
import { observer } from "mobx-react-lite"

import { BaseCellProps } from "@components/ui/WaterfallTable/types"
import useToggle from "@framework/hooks/useToggle"
import EditBidStrategyModal from "@components/modals/EditBidStrategyModal/EditBidStrategyModal"
import TableButton from "./TableButton"

export interface BidStrategyProps extends BaseCellProps {
  campaignId: string
  editable: boolean
}

export const BidStrategy: React.FC<BidStrategyProps> = observer(
  ({ value, campaignId }) => {
    const modal = useToggle()

    const handleToggleVisibility = (e: any) => {
      modal.handleToggle()
      e.stopPropagation()
    }

    if (value == null) return <></>

    const desc = getBillingStrategy(value)

    return (
      <>
        <TableButton color="primary" onClick={handleToggleVisibility}>
          {desc?.label ?? "Unset"}
        </TableButton>
        {modal.isOpened && (
          <EditBidStrategyModal
            campaignId={campaignId}
            isOpen
            onClose={modal.handleToggle}
          />
        )}
      </>
    )
  }
)

export default BidStrategy

export const getBillingStrategy = (value?: string) => {
  if (!value) return null
  return (
    billingStrategyDescMap[value as keyof typeof billingStrategyDescMap] ?? {
      label: value,
    }
  )
}

const billingStrategyDescMap: Record<string, { label: React.ReactNode }> = {
  MAXIMIZE_CONVERSION_VALUE: {
    label: (
      <>
        Conversion Value
        <br />
        (or Target ROAS)
      </>
    ),
  },
  MAXIMIZE_CONVERSIONS: {
    label: (
      <>
        Conversions
        <br />
        (or Target CPA)
      </>
    ),
  },
  TARGET_SPEND: {
    label: (
      <>
        Clicks
        <br />
        (or Target CPC)
      </>
    ),
  },
  TARGET_IMPRESSION_SHARE: {
    label: <>Impression Share</>,
  },
}
