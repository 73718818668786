import React, { useEffect } from "react"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import DefaultLayout from "@root/layouts/DefaultLayout/DefaultLayout"
import CreativeAnalyticsLayout from "./CreativeAnalyticsLayout/CreativeAnalyticsLayout"
import Dashboard from "./Dashboard/Dashboard"
import WinnersPage from "./Winners/Winners"
import GeneralPage from "./General/General"

const MetaAdsChannelRoot: React.FC = observer(() => {
  const history = useHistory()
  const { accountStore } = useStore()
  const { loadAccount, setAccountId, accountId } = accountStore

  const { id } = useParams<any>()

  useEffect(() => {
    if (!id || Number.isNaN(id)) history.push("./")
  }, [id, history])

  useEffect(() => {
    setAccountId(Number(id) || null)
    return () => setAccountId(null)
  }, [setAccountId, id])

  useEffect(() => {
    if (accountId) loadAccount(accountId)
  }, [accountId, loadAccount])

  return (
    <DefaultLayout>
      <CreativeAnalyticsLayout>
        <Switch>
          <Route path="/meta-ads/:id/dashboard" component={Dashboard} />

          <Route path="/meta-ads/:id/general" component={GeneralPage} />

          <Route path="/meta-ads/:id/winners" component={WinnersPage} />

          <Route
            path="/meta-ads/:id/report/:reportId"
            component={GeneralPage}
          />

          <Route path="/meta-ads/:id/checkbook">
            <Redirect to="./manage-profile?alter=checkbook" />
          </Route>

          <Route path="/meta-ads/:id/**">
            <Redirect to="./general" />
          </Route>

          <Route path="/meta-ads/:id" exact>
            <Redirect to="/meta-ads" />
          </Route>
        </Switch>
      </CreativeAnalyticsLayout>
    </DefaultLayout>
  )
})

export default MetaAdsChannelRoot
