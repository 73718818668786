import { observable, action, computed } from "mobx"

import { ID } from "@framework/types/types"
import { FormStepName } from "./validation"

export class EditPMaxCampaignStore {
  @observable isLoading: boolean = false

  @observable error: string | null = null

  @observable campaignId: ID | "new" = "new"

  @observable data: Partial<any> = {}

  @observable pendingDraft: {
    step: FormStepName
    cause: "interrupt" | "initiated"
    data: Partial<any>
  } | null = null

  @observable validSteps: Set<string> | null = null

  @computed get isStepValid() {
    const { validSteps } = this
    return (step: string) => !!validSteps?.has(step)
  }

  @computed get isInit() {
    return this.validSteps != null
  }

  @action storeData = (
    campaignId: ID,
    campaignData: Partial<any>,
    validSteps: string[]
  ) => {
    this.campaignId = campaignId
    this.data = campaignData
    this.validSteps = new Set(validSteps)
  }

  @action updateData = (campaignData: Partial<any>) => {
    this.data = { ...this.data, ...campaignData }
  }

  @action setPendingDraft = (
    step: FormStepName,
    campaignData: Partial<any>,
    cause: "interrupt" | "initiated"
  ) => {
    this.pendingDraft = {
      step,
      data: campaignData,
      cause,
    }
  }

  @action setCampaignValid = (step: FormStepName, valid: boolean = true) => {
    if (valid) this.validSteps?.add(step)
    else this.validSteps?.delete(step)
  }

  @action reset = () => {
    this.pendingDraft = null
    this.data = {}
    this.validSteps = null
    this.error = null
    this.isLoading = false
    this.campaignId = "new"
  }
}

export default EditPMaxCampaignStore
