import React, { useEffect, useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { monthListMock } from "@framework/constants/common"
import Typography from "@components/ui/Typography/Typography"

import BarChart from "@components/ui/Charts/BarChart/BarChart"
import { numberWithCommas } from "@components/utils/numberUtils"
import { useStore } from "@store/index"
import { extractMonthlyAdSpend } from "@store/account-dashboard/dataTransformers"
import MonthAdSpendChart from "../MonthAdSpendChart/MonthAdSpendChart"

import styles from "./AdSpendBudget.module.scss"

type AdSpendBudgetProps = {
  className?: string
}

const yAxisRenderCallback = (value: any) => `$${numberWithCommas(value)}`

const AdSpendBudget: React.FC<AdSpendBudgetProps> = observer(
  ({ className }) => {
    const {
      accountStore: { accountId },
      accountDashboardStore: {
        monthlyPerformanceStore: {
          loadingYearPerformance,
          currYearPerf,
          prevYearPerf,
          currentYear,
          previousYear,
          loadYearPerformance,
          loadMonthlyCheckbookAmount,
        },
      },
    } = useStore()

    const data = useMemo(() => {
      if (currYearPerf && prevYearPerf) {
        return [
          extractMonthlyAdSpend(currYearPerf, currentYear),
          extractMonthlyAdSpend(prevYearPerf, previousYear),
        ]
      }
      return []
    }, [currYearPerf, prevYearPerf, previousYear, currentYear])

    useEffect(() => {
      if (accountId) {
        loadYearPerformance(accountId)
        loadMonthlyCheckbookAmount(accountId)
      }
    }, [accountId])

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type="h1" color="primary" weight="bold">
          Ad Spend Budget
        </Typography>

        <div className={styles.wrapper}>
          <div className={styles.column}>
            <MonthAdSpendChart />
          </div>
          <div className={styles.column}>
            <BarChart
              dataset={data}
              labels={monthListMock}
              isLoading={loadingYearPerformance}
              title="Historical Monthly Ad Spend"
              subtitle="(vs Previous year)"
              yAxisRender={yAxisRenderCallback}
            />
          </div>
        </div>
      </div>
    )
  }
)

export default AdSpendBudget
