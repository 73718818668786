import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { CollapsibleHeader } from "@components/ui/Collapsible/CollapsibleHeader"
import useToggle from "@framework/hooks/useToggle"
import { Collapsable } from "@components/ui/Collapsible/Collapsable"
import Typography from "@components/ui/Typography/Typography"
import LimitLengthTextField from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/EditCopySidebar/LimitLengthTextField"
import { useFormikContext } from "formik"
import { EditAssetGroupFormValues } from "@pages/PMax/components/EditAssetGroupPage/EditAssetGroupForm"
import styles from "./Assets.module.scss"

const BusinessName: FC = observer(() => {
  const toggle = useToggle()
  const { errors } = useFormikContext<EditAssetGroupFormValues>()
  return (
    <div className={styles.section}>
      <CollapsibleHeader
        label="Business name"
        onClick={toggle.handleToggle}
        open={toggle.isOpened}
        type="h2"
        rootClassName={styles.collapsibleHeader}
      />
      <Typography
        type="subtitle"
        color="gray"
        className={styles.assetSectionTitle}
      >
        Add your business name
      </Typography>
      <Collapsable open={toggle.isOpened}>
        <LimitLengthTextField
          className={styles.textField}
          name="businessName"
          maxChars={25}
        />
        {errors.businessName && (
          <Typography type="body2" color="red">
            {errors.businessName}
          </Typography>
        )}
      </Collapsable>
    </div>
  )
})

export default BusinessName
