import React, { CSSProperties } from "react"
import Sticky from "@components/ui/Sticky/Sticky"

import styles from "./StickyContainer.module.scss"

export const StickyContainer: React.FC<{ stickyOffset?: number }> = React.memo(
  ({ stickyOffset = 0, children }) => {
    const rootStyle = {
      "--sticky-offset": `${stickyOffset}px`,
    } as CSSProperties

    return (
      <div className={styles.root} style={rootStyle}>
        <Sticky
          stickyStyle={{ top: `${stickyOffset}px` }}
          topOffset={-stickyOffset}
        >
          {children}
        </Sticky>
      </div>
    )
  }
)

export default StickyContainer
