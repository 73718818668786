import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useMemo } from "react"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import accountService from "@services/account.service"
import { useStore } from "@store/index"
import { countedFormat } from "@utils/stringUtils"
import SkipChangesConfirmModal from "@components/modals/ConfirmModal/SkipChangesConfirmModal"
import useToggle from "@framework/hooks/useToggle"
import PublishToGMCConfirmModal from "@components/modals/ConfirmModal/PublishToGMCConfirmModal"
import { numberWithCommas } from "@utils/numberUtils"

import styles from "./ControlHeader.module.scss"

const minutesToRemainText = (total: number) => {
  const hours = Math.floor(total / 60)
  return `Products will be published to Google in ${hours} ${countedFormat(
    "hour",
    hours
  )}`
}

export interface ControlHeaderProps {
  className?: string
}

export const ControlHeader: React.FC<ControlHeaderProps> = observer(
  ({ className }) => {
    const {
      accountStore: { accountId },
      productFeedStore: { list, GMC },
    } = useStore()

    const skipModal = useToggle()
    const gmcModal = useToggle()

    const downloadFeedLink = useMemo(
      () =>
        accountId ? accountService.getDownloadFeedLink(accountId) : undefined,
      [accountId]
    )

    const handlePublish = () => {
      if (GMC.isConnected) {
        gmcModal.setOpened(true)
      } else {
        GMC.showPlaceholderModal(true)
      }
    }

    return (
      <div className={clsx(styles.root, className)}>
        <SkipChangesConfirmModal
          isOpen={skipModal.isOpened}
          onClose={() => skipModal.setOpened(false)}
        />
        <PublishToGMCConfirmModal
          isOpen={gmcModal.isOpened}
          onClose={() => gmcModal.setOpened(false)}
        />
        <div className={styles.leftSection}>
          {list.status && (
            <>
              {list.selected.selectedCount > 0 ? (
                <Typography
                  type="h3"
                  color="darkGray"
                  bold
                  className={clsx(styles.metric, styles.withIcon)}
                >
                  <Icon name="stack" />{" "}
                  {numberWithCommas(list.selected.selectedCount)} Products
                  selected
                </Typography>
              ) : (
                <Typography
                  type="h3"
                  color="darkGray"
                  bold
                  className={clsx(styles.metric, styles.withIcon)}
                >
                  <Icon name="stack" />{" "}
                  {numberWithCommas(list.status?.numberOfProducts)} Products
                </Typography>
              )}
              <Typography
                type="h3"
                color="darkGray"
                bold
                className={clsx(styles.metric, styles.withIcon)}
              >
                <Icon name="magic" /> {list.status?.numberOfChanges} Changes
              </Typography>
              {/* {status?.gmcPostingDurationMinutes > 0 && (
                <Typography
                  type="h3"
                  color="darkGray"
                  bold
                  className={clsx(styles.metric, styles.withIcon)}
                >
                  <Icon name="loader" />{" "}
                  {minutesToRemainText(status?.gmcPostingDurationMinutes ?? 0)}
                </Typography>
              )} */}
            </>
          )}
        </div>

        <div className={styles.rightSection}>
          <Button
            disabled={!list.selected.isAnySelected}
            color="secondary"
            onClick={() => skipModal.setOpened(true)}
          >
            Skip{" "}
            {list.selected.isAllSelected ? "all" : list.selected.selectedCount}{" "}
            {countedFormat("change", list.selected.selectedCount)}
          </Button>

          <Button
            disabled={
              !list.selected.isAnySelected ||
              !GMC.isExportAvailable ||
              !GMC.isInited
            }
            onClick={handlePublish}
          >
            Publish to Google
          </Button>

          <Button
            disabled={!downloadFeedLink}
            href={downloadFeedLink}
            variant="outlined"
            as="a"
            target="_blank"
            download
          >
            Download Feed
          </Button>

          {/* <Button startIcon={{ name: "checkbox-circle" }}>
            Apply all changes
          </Button> */}
        </div>
      </div>
    )
  }
)
export default ControlHeader
