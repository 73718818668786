import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import KPIsWidget from "@components/ui/KPIsWidget/KPIsWidget"
import DataBlock from "@components/ui/KPIsWidget/Components/DataBlock"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricDescription } from "@framework/types/metrics"

import styles from "./OverallKPIs.module.scss"

type OverallKPIsProps = {
  className?: string
}

export interface Mapper {
  title: string
  subTitle: string
  blockMapper: MetricDescription[]
}

const mappers: Mapper[] = [
  {
    title: "Acquisition",
    subTitle: "(vs. Previous Period)",
    blockMapper: [
      getMetricDescriptor("Impressions"),
      getMetricDescriptor("Ctr"),
      getMetricDescriptor("Clicks"),
      getMetricDescriptor("Cost"),
    ],
  },
  {
    title: "Traffic Quality",
    subTitle: "(vs. Previous Period)",
    blockMapper: [
      getMetricDescriptor("Conversions"),
      getMetricDescriptor("ConversionRate"),
    ],
  },
  {
    title: "Transactions",
    subTitle: "(vs. Previous Period)",
    blockMapper: [
      getMetricDescriptor("Revenue"),
      getMetricDescriptor("AOV"),
      getMetricDescriptor("Roas"),
      getMetricDescriptor("CostConv"),
    ],
  },
]

const OverallKPIs: React.FC<OverallKPIsProps> = observer(({ className }) => {
  const { accountDashboardStore, accountStore } = useStore()
  const { accountId } = accountStore
  const {
    performance,
    loadPerformance,
    loadingPerformance: loading,
    currPeriod,
  } = accountDashboardStore

  useEffect(() => {
    if (accountId == null) return
    loadPerformance(accountId, currPeriod.from, currPeriod.to)
  }, [accountId, currPeriod, loadPerformance])

  return (
    <div className={clsx(styles.root, className)}>
      <Typography type="h1" color="primary" weight="bold">
        Overall KPIs
      </Typography>

      <div className={styles.wrapper}>
        {mappers.map(({ title, subTitle, blockMapper }) => (
          <KPIsWidget title={title} subTitle={subTitle}>
            {performance ? (
              blockMapper.map((mapper) => {
                const data = performance[mapper.name]
                if (!data) return null

                const trend = (data.Change ?? 0) * 100
                return (
                  <DataBlock
                    name={mapper.label}
                    amount={mapper.formatter(data.Value)}
                    trendFormatter={mapper.trendFormat}
                    trend={trend}
                    key={mapper.name}
                  />
                )
              })
            ) : loading ? (
              <Loader isLoading={loading} />
            ) : (
              <NoData />
            )}
          </KPIsWidget>
        ))}
      </div>
    </div>
  )
})

export default OverallKPIs
