import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import Box from "@components/ui/Box/Box"
import Button from "@components/ui/Button/Button"
import DocumentDropZone from "@components/ui/DropZone/DropZone"
import Label from "@components/ui/Label/Label"
import Typography from "@components/ui/Typography/Typography"
import { getFileExtension } from "@components/utils/stringUtils"
import clientService from "@services/client.service"
import { mbNoData } from "@services/utils"
import { useStore } from "@store/index"
import FormTextField from "@framework/prototypes/FormTextField"
import UploadingFile from "../UploadingFile/UploadingFile"

import styles from "./BrandGuidelines.module.scss"

export const getFileIcon = (fileName: string) => {
  const extension = getFileExtension(fileName)
  if (!extension) return "unknown_file"
  if (extension === "pdf") return "pdf"
  if (extension === "doc" || extension === "docx") return "docx"
  if (extension === "fine") return "fine"

  return "unknown_file"
}

type BrandGuidelinesProps = {
  className?: string
}

const BrandGuidelines: React.FC<BrandGuidelinesProps> = observer(
  ({ className }) => {
    const {
      clientProfileStore: { files, isEdit, uploadFileError, uploadFiles },
      clientStore: { client, clientId },
    } = useStore()

    const handleUploadFiles = (files: File[]) => {
      if (clientId) uploadFiles(clientId, files)
    }

    return (
      <Box className={clsx(styles.root, className)}>
        {isEdit ? (
          <div className={styles.formContainer}>
            <FormTextField
              name="contractInformation"
              label="Additional Contract Information"
            />

            <Label label="Additional Material">
              <DocumentDropZone
                onDrop={handleUploadFiles}
                className={styles.dropzone}
              />
            </Label>
            {uploadFileError && (
              <Typography color="red">
                Error happened while file uploading: {uploadFileError}
              </Typography>
            )}

            <div className={styles.documentsContainer}>
              {files?.map(({ id, clientId, fileName }) => (
                <UploadingFile
                  fileId={id}
                  clientId={clientId}
                  fileIcon={getFileIcon(fileName)}
                  fileName={fileName}
                  className={styles.fileProgress}
                  key={id}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div>
              <Label label="Additional Contract Information">
                <Typography type="h5">
                  {mbNoData(client?.contractInformation)}
                </Typography>
              </Label>
            </div>

            <div>
              <Label label="Additional Material">
                <div className={styles.filesWrapper}>
                  {files?.map((file) => (
                    <div title={file.fileName}>
                      <Button
                        href={clientService.getFileLink(file.clientId, file.id)}
                        as="a"
                        target="_blank"
                        download
                        variant="ghost"
                        key={file.id}
                      >
                        <img
                          height="40rem"
                          src={`/images/${getFileIcon(file.fileName)}.svg`}
                          alt="icon"
                        />
                      </Button>
                    </div>
                  ))}
                </div>
              </Label>
            </div>
          </div>
        )}
      </Box>
    )
  }
)

export default BrandGuidelines
