import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"

import styles from "./ConfirmModal.module.sass"

export type SuccessModalProps = {
  isOpen: boolean
  title: React.ReactNode
  message: React.ReactNode
  applyText?: string
  disableApply?: boolean
  onApply?: React.MouseEventHandler
  onClose: () => void
}

export const SuccessModal: React.FC<SuccessModalProps> = observer(
  ({
    isOpen,
    title,
    message,
    applyText = "Ok",
    disableApply,
    onClose,
    onApply = onClose,
  }) => (
    <Modal isOpen={isOpen} onClose={onClose} hideHeader>
      <Stack gutter="24" align="stretch" className={styles.root}>
        <Stack gutter="16">
          <Typography type="h2">{title}</Typography>
          <Typography type="body1">{message}</Typography>
        </Stack>
        <Stack direction="row" justify="center">
          <Button disabled={disableApply} onClick={onApply}>
            {applyText}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
)

export default SuccessModal
