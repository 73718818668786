import React, { useEffect } from "react"

export interface GlobalScrollContextType {
  isDisabled: boolean
  toggleValue: (id: string, value: boolean) => void
}

export const GlobalScrollContext =
  React.createContext<GlobalScrollContextType | null>(null)

export const GlobalScrollContextProvider: React.FC = React.memo(
  ({ children }) => {
    const [disabled, setDisabled] = React.useState<string[]>([])

    const isDisabled = disabled.length > 0

    const toggleValue = React.useCallback((id: string, disable: boolean) => {
      setDisabled((prev) =>
        disable ? [...prev, id] : prev.filter((it) => it !== id)
      )
    }, [])

    useEffect(() => {
      if (isDisabled) {
        document.body.style.overflow = "hidden"
        document.body.style.paddingRight = "0.8em"
      } else {
        document.body.style.overflow = "initial"
        document.body.style.paddingRight = "0"
      }
    }, [isDisabled])

    return (
      <GlobalScrollContext.Provider value={{ isDisabled, toggleValue }}>
        {children}
      </GlobalScrollContext.Provider>
    )
  }
)

export default GlobalScrollContextProvider
