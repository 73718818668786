import React from "react"
import { observer } from "mobx-react-lite"
import { useField } from "formik"

import TextField, { TextFieldProps } from "@components/ui/TextField/TextField"
import Typography from "@components/ui/Typography/Typography"
import Label from "@components/ui/Label/Label"
import { useFormMetaContext } from "@framework/prototypes/FormMetaContext"

interface LimitLengthTextFieldProps extends TextFieldProps {
  name: string
  label?: React.ReactNode
  maxChars: number
  after?: React.ReactNode
}

export const LimitLengthTextField: React.FC<LimitLengthTextFieldProps> =
  observer(({ name, maxChars, label, placeholder, after, ...rest }) => {
    const formMeta = useFormMetaContext()

    const [field, meta] = useField(name)

    const leftChars = maxChars - (field.value?.length ?? 0)
    const error = meta.touched ? meta.error : undefined
    return (
      <Label label={label}>
        <TextField
          {...rest}
          edited={formMeta?.isEdited(name)}
          placeholder={Array.isArray(field.value) ? "<varies>" : placeholder}
          name={name}
          value={Array.isArray(field.value) ? "" : field.value}
          error={error != null}
          valid={
            meta.touched &&
            meta.value !== meta.initialValue &&
            meta.error == null
          }
          onChange={field.onChange}
          onBlur={field.onBlur}
          maxLength={maxChars}
          after={
            <Typography type="caption1" color="black60Color">
              {leftChars}
            </Typography>
          }
        />
      </Label>
    )
  })

export default LimitLengthTextField
