import React, { useCallback, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import _uniqueId from "lodash/uniqueId"

import Box from "@components/ui/Box/Box"
import Button from "@components/ui/Button/Button"
import { toBudgetCondition } from "@store/account-rules/dataTransformer"
import FormNumberField from "@framework/prototypes/FormNumberField"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import useOptionList from "@framework/prototypes/useOptionList"
import {
  BudgetConditionFormType,
  ConditionFrequencyType,
  ConditionType,
} from "@framework/types/manageCampaign"
import {
  actionOptions,
  campaignTypeOptions,
  operatorOptions,
  triggerOptions,
} from "./options"
import CustomCampaignsTable from "../CustomCampaignsTable/CustomCampaignsTable"

import styles from "./BudgetTypeRoleForm.module.scss"

const defaultInitialValues = {
  trigger: triggerOptions[0].value,
  operator: operatorOptions[0].value,
  value: "",
  action: actionOptions[0].value,
  appliedArea: campaignTypeOptions[0].value,
  campaignIds: [],
}

const validationSchema = yup.object({
  value: yup
    .number()
    .typeError("Should be a Number")
    .required("Filed is required"),
})

export type BudgetTypeRoleFormProps = {
  onSubmit: (data: ConditionType) => any
  onCancel?: () => any
  defaultValue?: ConditionType
  frequency: ConditionFrequencyType
}

const BudgetTypeRoleForm: React.FC<BudgetTypeRoleFormProps> = observer(
  ({ frequency, defaultValue, onSubmit, onCancel }) => {
    const handleSubmit = useCallback(
      (data: BudgetConditionFormType) => {
        onSubmit(
          toBudgetCondition(
            defaultValue?.id ?? _uniqueId("new_"),
            data,
            frequency,
            defaultValue?.status === "OLD" ? "UPDATE" : "CREATE"
          )
        )
      },
      [frequency, defaultValue, onSubmit]
    )

    const initialValues = useMemo(
      () => (defaultValue ? defaultInitialValues : defaultInitialValues),
      [defaultValue]
    )

    const formik = useFormik<BudgetConditionFormType>({
      initialValues,
      validationSchema,
      validateOnBlur: true,
      onSubmit: handleSubmit,
    })

    const handleChangeIds = useCallback(
      (data: string[]) => {
        formik.setFieldValue("campaignIds", data)
      },
      [formik.setFieldValue]
    )

    const triggerOptionsProps = useOptionList(triggerOptions)
    const operatorProps = useOptionList(operatorOptions)
    const actionOptionsProps = useOptionList(actionOptions)
    const campaignTypeOptionsProps = useOptionList(campaignTypeOptions)

    return (
      <FormikProvider value={formik}>
        <form className={styles.root}>
          <Box className={styles.shortRow}>
            <FormSimpleSelectField
              className={styles.field}
              label="IF (Trigger)"
              name="trigger"
              {...triggerOptionsProps}
            />

            <FormSimpleSelectField
              className={styles.field}
              label="is (Operator)"
              name="operator"
              {...operatorProps}
            />

            <FormNumberField
              label="Value %"
              name="value"
              thousandSeparator
              decimalScale={0}
              suffix="%"
              floatingError
            />

            <FormSimpleSelectField
              className={styles.field}
              label="Then (Action)"
              name="action"
              {...actionOptionsProps}
            />

            <FormSimpleSelectField
              className={styles.field}
              label="Applied to (Campaign Type) "
              name="appliedArea"
              {...campaignTypeOptionsProps}
            />
            <Button
              className={styles.rowButton}
              onClick={formik.isValid ? formik.submitForm : onCancel}
              variant="outlined"
              size="big"
            >
              {formik.isValid ? "Save" : "Cancel"}
            </Button>
          </Box>
          {formik.values.appliedArea === "CUSTOM" && (
            <CustomCampaignsTable onChange={handleChangeIds} />
          )}
        </form>
      </FormikProvider>
    )
  }
)

export default BudgetTypeRoleForm
