import React from "react"
import clsx from "clsx"

import { observer } from "mobx-react-lite"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { useStore } from "@store/index"
import RowGroup from "./RowGroup"
import HeaderCell from "./HeaderCell"
import { ColumnMapper } from "../../ProductFeed/types"

import styles from "./Row.module.scss"

interface HeaderProps<T extends object = any> {
  staticBefore?: number
  staticAfter?: number
  mapper: ColumnMapper<T>[]
  className?: string
}

const Header: React.FC<HeaderProps> = observer(
  ({ mapper, staticBefore = 0, className }) => {
    if (Number.isNaN(staticBefore))
      throw new Error("staticBefore filed is not a number")
    if (staticBefore < 0) throw new Error("staticBefore filed should be >= 0 ")

    const {
      productFeedStore: { list },
    } = useStore()

    const beforeMapper = mapper.slice(0, staticBefore)
    const middleMapper = mapper.slice(staticBefore, mapper.length)
    const { sortBy, setSortBy } = list

    const handleSort = (name: string) => {
      const newValue = {
        value: name,
        direction: sortBy.value === name && !sortBy.direction,
      }
      setSortBy(newValue)
    }

    const renderCell = (mapper: ColumnMapper) => {
      const sortName = mapper.isSortable
        ? typeof mapper.isSortable === "string"
          ? mapper.isSortable
          : mapper.name
        : null
      const isSorted = Boolean(sortBy && sortName && sortBy.value === sortName)
      return (
        <HeaderCell
          label={mapper.label}
          onSort={sortName ? () => handleSort(sortName) : undefined}
          sortDirection={sortBy.direction}
          isSorted={isSorted}
        />
      )
    }

    return (
      <div className={clsx(styles.header, className)}>
        <div className={styles.before}>
          <CheckBox
            partial={!list.selected.isAllSelected}
            checked={list.selected.isAnySelected}
            onChange={() => list.selected.selectAll()}
          />
        </div>
        <RowGroup
          name="before"
          mapper={beforeMapper}
          className={clsx(styles.group, styles.staticGroup)}
          renderCell={renderCell}
        />
        <RowGroup
          name="middle"
          mapper={middleMapper}
          className={styles.group}
          renderCell={renderCell}
        />
        <HeaderCell className={styles.after} label="Changes" />
      </div>
    )
  }
)

export default Header
