/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { mbNoData } from "@services/utils"
import Label from "@components/ui/Label/Label"
import FormTextField from "@framework/prototypes/FormTextField"

import styles from "./ContractsForm.module.scss"

type ContractsFormProps = {
  className?: string
}

const ContractsForm: React.FC<ContractsFormProps> = observer(
  ({ className }) => {
    const {
      clientProfileStore: { isEdit },
      clientStore: { client },
    } = useStore()

    return (
      <Box className={clsx(styles.root, className)}>
        <Typography type="h2">Contacts</Typography>
        {isEdit ? (
          <div className={styles.formContainer}>
            <div className={styles.fieldsWrapper}>
              <FormTextField label="Name" name="contactName" />

              <FormTextField label="Profession" name="contactProfession" />
            </div>
            <div className={styles.fieldsWrapper}>
              <FormTextField label="Email" name="contactEmail" />

              <FormTextField label="Phone number" name="contactPhone" />
            </div>
          </div>
        ) : (
          client && (
            <div className={styles.container}>
              <div>
                <Label label="Name" className={styles.wrapper}>
                  <Typography>{mbNoData(client.contactName)}</Typography>
                </Label>

                <Label label="Email" className={styles.wrapper}>
                  <Typography>{mbNoData(client.contactEmail)}</Typography>
                </Label>
              </div>

              <div>
                <Label label="Profession" className={styles.wrapper}>
                  <Typography>{mbNoData(client.contactProfession)}</Typography>
                </Label>

                <Label label="Phone Number" className={styles.wrapper}>
                  <Typography>{mbNoData(client.contactPhone)}</Typography>
                </Label>
              </div>
            </div>
          )
        )}
      </Box>
    )
  }
)

export default ContractsForm
