import { preciseMoneyFormatter } from "@services/utils"

export const moneyWithSignFormatter = (
  value: number = 0,
  withPositiveSign: boolean = false
) => {
  const sign = value < 0 ? "-" : value > 0 && withPositiveSign ? "+" : ""
  const unsignedValue = Math.abs(value)
  return `${sign}${preciseMoneyFormatter(unsignedValue.toString())}`
}
export default null
