import React from "react"
import clsx from "clsx"

import BasicButton, { BasicButtonProps } from "./BasicButton"

import styles from "./IconButton.module.scss"

export interface IconButtonProps extends BasicButtonProps {}

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  className,
  variant = "ghost",
  ...rest
}) => {
  const classNames = clsx(
    "UIButton-root",
    styles.root,
    styles[variant],
    className
  )
  return (
    <BasicButton {...rest} className={classNames} variant={variant}>
      {children}
    </BasicButton>
  )
}

export default IconButton
