import React from "react"
import { observer } from "mobx-react-lite"
import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"

import { useStore } from "@store/index"

import styles from "./GMCNotConnectedModal.module.scss"

const IMAGE_SRC = "/images/google-shopping.svg"

type GMCNotConnectedModalProps = {
  isOpen: boolean
  onClose: () => void
}

const GMCNotConnectedModal: React.FC<GMCNotConnectedModalProps> = observer(
  ({ isOpen, onClose }) => {
    const {
      productFeedStore: { isProductsImportLoading: isShopifyProductsLoading },
    } = useStore()

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className={styles.container}>
          <img src={IMAGE_SRC} alt="" height={112} width={112} />
          <Typography type="h2" color="dark">
            Your Google Merchant Center Account is not linked with Adoro
          </Typography>
          <Typography className={styles.subtitle} color="gray">
            To setup your Google Merchant Center Link please contact{" "}
            <span>info@adoro.us</span>
          </Typography>

          <Button
            className={styles.control}
            disabled={isShopifyProductsLoading}
            onClick={onClose}
          >
            Ok
          </Button>
        </div>
      </Modal>
    )
  }
)

export default GMCNotConnectedModal
