import React from "react"
import clsx from "clsx"

import styles from "./Image.module.scss"

export interface Props {
  className?: string
  message: React.ReactNode
}

export const ImageTopper: React.FC<Props> = ({
  className,
  children,
  message,
}) => (
  <div className={clsx(styles.container, className)}>
    {children}
    <div className={clsx(styles.topper, className)}>{message}</div>
  </div>
)

export default ImageTopper
