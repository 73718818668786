/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { MouseEventHandler } from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"

import { IconName } from "@components/ui/Icon/types"
import styles from "./Radio.module.scss"

type RadioProps = {
  className?: string
  checked?: boolean
  disabled?: boolean
  name?: string
  value?: string
  id?: string
  onChange?: React.ChangeEventHandler
  onClick?: MouseEventHandler
  checkedIcon?: IconName
}

const Radio: React.FC<RadioProps> = ({
  id,
  name,
  value,
  checked = value != null && value === id,
  disabled = false,
  className,
  onChange,
  onClick,
  checkedIcon = "radio-button",
}) => (
  <label htmlFor={id} className={clsx(styles.root, className)}>
    <input
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      className={styles.input}
      onChange={onChange}
      onClick={onClick}
    />
    <Icon
      className={styles.mark}
      name={checked ? checkedIcon : "checkbox-blank-circle"}
    />
  </label>
)

export default Radio
