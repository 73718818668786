import sumBy from "lodash/sumBy"
import { observable, action, computed } from "mobx"
import AbstractFilter from "./AbstractFilter"

export class FilterGroup<
  T extends Record<string, AbstractFilter>
> extends AbstractFilter {
  @observable group: T

  constructor(filters: T) {
    super()
    this.group = filters
  }

  @computed get filterList(): AbstractFilter[] {
    return Object.values(this.group)
  }

  @computed get tempFiltersCount() {
    return sumBy(this.filterList, "tempFiltersCount")
  }

  @computed get activeFiltersCount() {
    return sumBy(this.filterList, "activeFiltersCount")
  }

  @computed get filtersCountDifference() {
    return sumBy(this.filterList, "filtersCountDifference")
  }

  @computed get isSync() {
    return this.filterList.every((item) => item.isSync)
  }

  @action resetTemp = () => {
    this.filterList.forEach((item) => item.resetTemp())
  }

  @action resetAll = () => {
    this.filterList.forEach((item) => item.resetAll())
  }

  @action applyFilter = () => {
    this.filterList.forEach((item) => item.applyFilter())
  }
}

export default FilterGroup
