/* eslint-disable prefer-destructuring */
import { action, computed, observable } from "mobx"

import { periodicityOptions } from "@framework/constants/report"
import { MetricNameType } from "@framework/types/metrics"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { metricsToDisplay } from "./constants"

export class KpiTrendsFilterStore {
  constructor() {
    this.periodicityOptions = periodicityOptions
    this.activePeriodicity = this.periodicityOptions[0].value
    this.metricsOptions = metricsToDisplay
    this.activeMetric = this.metricsOptions[0]
  }

  @observable periodicityOptions

  @observable activePeriodicity: PerformanceReportPeriodicity

  @observable metricsOptions: MetricNameType[]

  @observable activeMetric: MetricNameType

  @action setActiveMetric = (metric: MetricNameType) => {
    this.activeMetric = metric
  }

  @action setPeriodicity = (periodicity: PerformanceReportPeriodicity) => {
    this.activePeriodicity = periodicity
  }
}

export default KpiTrendsFilterStore
