import React from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"

import styles from "./Table.module.scss"

interface HeaderProps {
  withDetails?: boolean
  mapper: any[]
  sortBy: { colName: string; dir: boolean }
  onSort?: (name: string) => void
}

export const Header: React.FC<HeaderProps> = ({
  withDetails = false,
  mapper,
  sortBy,
  onSort,
}) => (
  <div
    className={clsx(styles.header, {
      [styles.withDetails]: withDetails,
    })}
  >
    {mapper.map(({ label, name }) => (
      <Typography
        className={clsx(styles.item, { [styles.clickable]: !!onSort })}
        type="h6"
        color="gray"
        upperCase
        onClick={onSort ? () => onSort(name) : undefined}
        key={name}
      >
        {label}
        <Icon
          name="arrow-right"
          rotateAngle={sortBy.dir ? 90 : -90}
          className={sortBy.colName !== name ? styles.invisible : ""}
        />
      </Typography>
    ))}
  </div>
)

export default Header
