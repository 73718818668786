import React from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { Button, ButtonGroup } from "@components/ui/Button"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import { getCurrentPeriodOptions } from "@store/reports/utils"
import ButtonDatePicker from "@components/ui/DatePicker/ButtonDatePicker"
import IconButton from "@components/ui/Button/IconButton"
import { ViewType } from "./types"

interface ReportControlPanelProps {
  view?: ViewType
  onViewChange?: (view: ViewType) => void
}

const ReportControlPanel: React.FC<ReportControlPanelProps> = observer(
  ({ view = "card", onViewChange }) => {
    const datePickerOptions = React.useMemo(() => getCurrentPeriodOptions(), [])
    const [period, setPeriod] = React.useState(datePickerOptions[0])

    return (
      <Box>
        <Stack direction="row" gutter="8" justify="space-between">
          <Stack direction="row" gutter="8">
            <Button color="secondary" before={<Icon name="refresh" />}>
              Sync
            </Button>
            <ButtonDatePicker
              range={period}
              onRangeChange={setPeriod}
              rangeOptions={datePickerOptions}
            />
            <Button color="secondary" before={<Icon name="filter" />}>
              Filters
            </Button>
            <Button color="secondary" before={<Icon name="stack" />}>
              Groups
            </Button>
            <Button color="secondary" before={<Icon name="eye" />}>
              Metrics
            </Button>
          </Stack>

          <Stack direction="row" gutter="8" justify="flex-end">
            <ButtonGroup>
              <IconButton
                variant="contained"
                color={view === "card" ? "primary" : "secondary"}
                onClick={() => onViewChange?.("card")}
              >
                <Icon name="dashboard1" />
              </IconButton>
              <IconButton
                variant="contained"
                color={view === "table" ? "primary" : "secondary"}
                onClick={() => onViewChange?.("table")}
              >
                <Icon name="menu" />
              </IconButton>
            </ButtonGroup>
            <Button color="primary" before={<Icon name="add" />}>
              Create report
            </Button>
          </Stack>
        </Stack>
      </Box>
    )
  }
)

export default ReportControlPanel
