import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import FormTextField from "@framework/prototypes/FormTextField"
import { mbNoData } from "@services/utils"
import { printDate } from "@utils/date"
import { useEditorFormRedirect } from "@pages/PMax/ActiveSectionContext"
import Cortege from "./Cortege"

import styles from "./FormSummary.module.scss"

export const CampaignInfoTable: React.FC = observer(() => {
  const { editPMaxCampaignStore: store } = useStore()

  const editPeriodForm = useEditorFormRedirect("budget", "info")

  return (
    <Stack gutter="16" direction="column">
      <Box className={styles.section}>
        <Table>
          <Row key="form">
            <Column width="30%">
              <Typography>Campaign name</Typography>
            </Column>
            <Column width="65%">
              <Typography>
                <FormTextField placeholder="Enter name" name="name" />
              </Typography>
            </Column>
            <Column width="5%" />
          </Row>

          <Cortege label="Campaign type">Performance Max</Cortege>

          <Cortege label="Goal">Purchase</Cortege>

          <Cortege label="Start and end dates" onEdit={editPeriodForm}>
            Start date: {mbNoData(store.data.startDate, printDate, "N/A")},
            &nbsp; End date: {mbNoData(store.data.endDate, printDate, "N/A")}
          </Cortege>
        </Table>
      </Box>
    </Stack>
  )
})

export default CampaignInfoTable
