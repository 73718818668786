/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react"
import clsx from "clsx"
import * as Yup from "yup"
import { FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { emailRegEx } from "@components/utils/validators"
import Loader from "@components/ui/Loader/Loader"
import { ContractsFormType } from "@framework/types/clientProfile"
import ContractsForm from "./components/ContractsForm/ContractsForm"

import styles from "./ContractsInfo.module.scss"

export const clientContractsValidationSchema = Yup.object().shape({
  contactName: Yup.string().required("Required"),
  contactEmail: Yup.string()
    .matches(emailRegEx, "Must be valid email")
    .required("Required"),
  contactProfession: Yup.string(),
  contactPhone: Yup.string(),
})

export type ContractsInfoProps = {
  className?: string
}

const ContractsInfo: React.FC<ContractsInfoProps> = observer(
  ({ className }) => {
    const {
      clientProfileStore,
      clientStore: { clientId, loading },
    } = useStore()

    const {
      isEdit,
      clientContracts,
      clientUpdateLoading,
      setIsEdit,
      setIsValid,
      submitFormCallback,
      setSubmitCallback,
      updateClient,
    } = clientProfileStore

    const handleSubmit = useCallback(
      async (values: ContractsFormType) => {
        if (clientId) {
          updateClient(clientId, { ...values })
        }
      },
      [clientId, updateClient]
    )

    const formik = useFormik<ContractsFormType>({
      initialValues: clientContracts,
      validateOnBlur: true,
      validationSchema: clientContractsValidationSchema,
      onSubmit: handleSubmit,
    })

    const isTouched = useMemo(
      () => Object.keys(formik.touched).length,
      [formik.touched]
    )

    useEffect(() => {
      if (!clientUpdateLoading && submitFormCallback) {
        setIsEdit(false)
      }
    }, [clientUpdateLoading])

    useEffect(() => {
      if (isEdit) formik.resetForm({ values: clientContracts })
    }, [isEdit])

    useEffect(() => {
      if (isTouched) {
        setSubmitCallback(formik.submitForm)
      }
    }, [formik.touched])

    useEffect(() => {
      setIsValid(formik.isValid)
    }, [formik.isValid, setIsValid])

    useEffect(() => () => setIsEdit(false), [setIsEdit])

    return (
      <FormikProvider value={formik}>
        <form
          className={clsx(styles.root, className)}
          onSubmit={formik.handleSubmit}
        >
          <Loader isLoading={loading || clientUpdateLoading} />
          <ContractsForm />
        </form>
      </FormikProvider>
    )
  }
)

export default ContractsInfo
