import { randomNumber } from "@utils/numberUtils"
import { MetricNameType } from "@framework/types/metrics"
import { MetricsCardProps } from "./MetricCard/MetricCard"
import { DataBlockProps } from "./Components/DataBlock"

export const acquisitionDataMock: (DataBlockProps & MetricsCardProps)[] = [
  { name: "Clicks", amount: 960, trend: 60 },
  { name: "Ctr", amount: 10, trend: 60 },
  {
    name: "Impressions",
    amount: randomNumber(0, 10000),
    trend: 60,
  },
]

export const trafficQualityDataMock = [
  {
    name: "Res. Click %",
    amount: `10%`,
    trend: 10.06,
  },
  {
    name: "Conversion rate%",
    amount: `1.83%`,
    trend: -0.3,
  },
]

export const transactionsDataMock = [
  {
    name: "AOV",
    amount: randomNumber(0, 5000),
    trend: 60,
  },
  {
    name: "Revenue",
    amount: randomNumber(0, 5000),
    trend: 60,
  },
  {
    name: "ROAS",
    amount: randomNumber(0, 2000),
    trend: -60,
  },
]

export const accountKPIDropDownListMock: MetricNameType[] = [
  "Clicks",
  "Ctr",
  "Impressions",
  "ConversionRate",
  "AOV",
  "Revenue",
  "Roas",
  "Cost",
  "AverageCpc",
  "Conversions",
  "CostConv",
]

export const clientKPIDropDownListMock: MetricNameType[] =
  accountKPIDropDownListMock
