import { ColumnMapper } from "./types"
import ActionsColumn from "./ActionsColumn"
import UserMetaColumn from "./UserMetaColumn"
import UserRolesColumn from "./UserRolesColumn"
import AccessLevelColumn from "./AccessLevelColumn"
import AccessDetails from "./AccessDetails"

const tableMapper: ColumnMapper[] = [
  {
    name: "userName",
    label: "User Name",
    column: { Component: UserMetaColumn },
    width: 220,
  },
  {
    name: "ObjectType",
    label: "Access Level",
    column: { Component: AccessLevelColumn },
    width: 90,
  },
  {
    name: "Clients",
    label: "Access details",
    column: { Component: AccessDetails },
    width: 120,
  },
  {
    name: "AccessLevel",
    label: "Permissions",
    width: 90,
    column: { Component: UserRolesColumn },
  },
  {
    name: "actions",
    label: "Actions",
    column: { Component: ActionsColumn },
    width: 40,
  },
]

export default tableMapper
