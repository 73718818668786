import React, { FC, useState } from "react"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Label from "@components/ui/Label/Label"
import TextField from "@components/ui/TextField/TextField"
import { useAlert } from "react-alert"
import styles from "./AssetGroupImagesSidebar.module.scss"

interface RenameImageModalProps {
  baseName?: string
  image?: { name: string; imageId: number }
  isOpen: boolean
  onClose: () => void
  onLocalRename?: (name: string, baseName: string) => void
  onRename?: (name: string, id: number) => void
}

const RenameImageModal: FC<RenameImageModalProps> = ({
  onClose,
  isOpen,
  onRename,
  onLocalRename,
  baseName,
  image,
}) => {
  const alert = useAlert()
  const [name, setName] = useState<string>("")

  const handleRename = () => {
    if (onLocalRename && baseName) {
      const filename = baseName
      const regex = /\.(png|jpe?g)$/i
      const match = filename.match(regex)

      let result: string = ""
      if (match) {
        result = `${name}${match[0]}`
      } else {
        alert.error(`Wrong file extension ${baseName}`)
      }
      onLocalRename(result, baseName)
      setName("")
    } else if (onRename && image) {
      onRename(name, image.imageId)
      setName("")
    }
  }
  return (
    <Modal
      title="Rename image?"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.renameImageModal}
    >
      <Stack align="stretch">
        <Stack direction="column" justify="space-between" align="stretch">
          <Label label="Current name">
            <TextField value={image ? image.name : baseName} disabled />
          </Label>
          <Label label="New name">
            <TextField value={name} onChange={(e) => setName(e.target.value)} />
          </Label>
        </Stack>
        <Stack direction="row" justify="flex-end">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleRename}>
            Rename
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default RenameImageModal
