import { Color } from "chart.js"

export const lightColors = ["#5CBD9F", "#C9F1E5"]
export const darkColors = ["#2B5B64", "#58B7C9", "#448FD4"]

export const NULL = null as unknown as undefined

export const drawPoint = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number,
  color: Color = "black"
) => {
  ctx.fillStyle = color
  ctx.beginPath()
  ctx.arc(x, y, radius, 0, 2 * Math.PI)
  ctx.fill()
  ctx.restore()
}

export const drawLine = (
  ctx: CanvasRenderingContext2D,
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  color: Color = "black"
) => {
  ctx.lineWidth = 2
  ctx.strokeStyle = color
  ctx.beginPath()
  ctx.moveTo(x0, y0)
  ctx.lineTo(x1, y1)
  ctx.stroke()
  ctx.restore()
}
