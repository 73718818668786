import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { ScrollSync } from "react-scroll-sync"
import clsx from "clsx"
import { FixedSizeList, ListChildComponentProps } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"
import InfiniteLoader from "react-window-infinite-loader"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import { useStore } from "@store/index"
import { ID } from "@framework/types/types"
import NoData from "@components/ui/NoData/NoData"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { ColumnMapper, ViewType } from "../../ProductFeed/types"
import Header from "./Header"
import Row from "./Row"

import styles from "./Table.module.scss"

const ROW_BLOCK_PADDING = 8

const SINGLE_ROW_HEIGHT = 68
const DOUBLE_ROW_HEIGHT = 136

const STATIC_BEFORE = 3

interface TableProps<T extends object> {
  bodyClassName?: string
  viewType?: ViewType
  mapper?: ColumnMapper<T>[]
  data?: T
  onRowClick?: (index: number) => void
}

const Table: React.FC<any> = observer(
  <T extends object>({
    bodyClassName,
    viewType,
    mapper = [],
    onRowClick,
  }: TableProps<T>) => {
    const alert = useAlert()
    const {
      productFeedStore: {
        list: {
          isLoading,
          totalCount,
          needReload,
          productIdByIndex,
          productByIndex,
          loadProductFeedList,
        },
        filter,
      },
      accountStore: { accountId },
    } = useStore()

    const onRowClickHandler = useCallback(
      (productId: number) => {
        if (!onRowClick) return undefined
        return onRowClick(productId)
      },
      [onRowClick]
    )

    const handleLoadMore = async (startIndex: number, stopIndex: number) => {
      const count = stopIndex - startIndex + 1
      const error = await loadProductFeedList(
        accountId,
        startIndex,
        count,
        filter.active
      )
      if (error != null) {
        alert.error(
          <AlertMessage
            title="Failed to load product list"
            description={error}
          />
        )
      }
    }

    const rowHeight =
      (viewType === "simple" ? SINGLE_ROW_HEIGHT : DOUBLE_ROW_HEIGHT) +
      2 * ROW_BLOCK_PADDING

    const defaultRowProps = useMemo(
      () => ({
        viewType,
        mapper,
        getIdByIndex: productIdByIndex,
        onRowClickHandler,
      }),
      [mapper, viewType, onRowClickHandler, productIdByIndex]
    )

    const isRowLoaded = (idx: number) => !!productByIndex(idx) && !needReload

    const canLoad = accountId != null

    const infiniteLoaderRef = useRef<InfiniteLoader>(null)
    const hasMountedRef = useRef(false)

    useEffect(() => {
      if (hasMountedRef.current && infiniteLoaderRef.current && needReload) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true)
      }
      hasMountedRef.current = true
    }, [needReload])

    return (
      <ScrollSync>
        <>
          <Header
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
            className={styles.headerContainer}
          />
          <div className={clsx(styles.root, bodyClassName)}>
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isRowLoaded}
              itemCount={canLoad ? totalCount : 0}
              loadMoreItems={handleLoadMore}
              threshold={50}
              minimumBatchSize={50}
            >
              {({ onItemsRendered, ref }) => (
                <AutoSizer>
                  {(size) => (
                    <FixedSizeList
                      overscanCount={3}
                      height={size.height}
                      width={size.width}
                      itemCount={totalCount}
                      itemSize={rowHeight}
                      className={styles.list}
                      onItemsRendered={onItemsRendered}
                      outerElementType={ScrollContainer}
                      itemData={defaultRowProps}
                      ref={ref}
                    >
                      {RenderRow}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
            {!isLoading && totalCount <= 0 && (
              <NoData className={styles.noFoundMessage}>
                NO PRODUCTS FOUND
              </NoData>
            )}
          </div>
        </>
      </ScrollSync>
    )
  }
)

const RenderRow: React.FC<ListChildComponentProps> = ({
  style,
  index,
  data: rowProps,
}) => {
  const { onRowClickHandler, mapper, viewType, getIdByIndex } = rowProps

  const productId: number | null = getIdByIndex(index)

  const handleClick = useCallback(() => {
    if (productId) onRowClickHandler(productId.toString())
  }, [onRowClickHandler, productId])

  return (
    <div
      style={{
        ...style,
        paddingInline: "12px",
        paddingBlock: `${ROW_BLOCK_PADDING}px`,
        boxSizing: "border-box",
      }}
    >
      <ConnectedRenderRow
        productId={productId}
        mapper={mapper}
        viewType={viewType}
        onClick={handleClick}
      />
    </div>
  )
}

interface ConnectedRenderRowProps {
  productId: ID | null
  viewType?: ViewType
  mapper: ColumnMapper[]
  onClick: () => void
}

const ConnectedRenderRow: React.FC<ConnectedRenderRowProps> = observer(
  ({ productId, viewType, mapper, onClick }) => {
    const {
      productFeedStore: {
        changes: { changeById },
        list: { selected, productById },
      },
    } = useStore()

    const data = productById(productId)
    const change = changeById(productId)

    const handleSelect = useCallback(() => {
      if (productId) selected.select(productId.toString())
    }, [productId, selected.select])

    const isSelected = productId ? selected.isSelected(productId) : false

    return (
      <Row
        data={data}
        change={change}
        mapper={mapper}
        viewType={viewType}
        staticBefore={STATIC_BEFORE}
        selected={isSelected}
        onSelect={handleSelect}
        onClick={onClick}
      />
    )
  }
)

export default Table
