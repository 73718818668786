/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Box from "@components/ui/Box/Box"
import Label from "@components/ui/Label/Label"
import Loader from "@components/ui/Loader/Loader"
import Typography from "@components/ui/Typography/Typography"
import { mbNoData, moneyFormatter } from "@services/utils"
import { useStore } from "@store/index"
import CheckbookSummary from "../CheckbookSummary/CheckbookSummary"

import styles from "./PlannedAdSpend.module.scss"

type PlannedAdSpendProps = {
  className?: string
  isEdit?: boolean
}

const PlannedAdSpend: React.FC<PlannedAdSpendProps> = observer(
  ({ className }) => {
    const {
      clientStore: { clientId },
      clientCheckbookStore: {
        accountNumber,
        contractAdSpend,
        reportLoading,
        loadClientCheckbookReport,
      },
    } = useStore()

    useEffect(() => {
      if (clientId) loadClientCheckbookReport(clientId)
    }, [clientId, loadClientCheckbookReport])

    const idLoading = reportLoading

    return (
      <Box className={clsx(styles.root, className)}>
        <div className={styles.header}>
          <Label label="Contract Yearly Ad Spend">
            <Typography type="h2" color="primary" bold>
              {mbNoData(contractAdSpend.toString(), moneyFormatter)}
            </Typography>
          </Label>
          <Label label="Number of Accounts">
            <Typography type="h2" color="primary" bold>
              {accountNumber ?? "-"}
            </Typography>
          </Label>
        </div>

        <Loader isLoading={idLoading} />

        <CheckbookSummary />
      </Box>
    )
  }
)

export default PlannedAdSpend
