import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import { WrappableContainer } from "@components/ui/WrappableContainer/WrappableContainer"

import styles from "./FilterSection.module.scss"

interface FilterListProps {
  label: string
  openByDefault?: boolean
}

const FilterSection: React.FC<FilterListProps> = observer(
  ({ label, openByDefault = false, children }) => {
    const [open, setOpen] = useState(openByDefault)

    return (
      <WrappableContainer
        open={open}
        onClick={() => setOpen((prev) => !prev)}
        label={<div className={styles.wrapTitle}>{label}</div>}
        className={styles.section}
        titleClassName={styles.wrapHeader}
      >
        {children}
      </WrappableContainer>
    )
  }
)

export default FilterSection
