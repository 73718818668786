import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Modal from "@components/ui/Modal/Modal"
import { generateCampaignReport } from "@pages/MetaAdsChannel/mock"
import { metricsDescriptors } from "@framework/constants/metrics"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import IconButton from "@components/ui/Button/IconButton"
import DetailsSection from "./DetailsSection"
import MediaSection from "./MediaSection"
import StatusBadge from "../StatusBadge"
import ReportTitle from "../ReportTitle"

import styles from "./ReportDetails.module.sass"

interface ReportDetailsProps {
  open: boolean
  onClose: () => void
}

export const ReportDetailsModal: React.FC<ReportDetailsProps> = observer(
  ({ open, onClose }) => (
    // TODO connect store

    <Modal
      className={clsx(styles.root)}
      isOpen={open}
      onClose={onClose}
      hideHeader
    >
      <div className={styles.header}>
        <Templates.TripleHeader
          left={
            <div className={styles.title}>
              <ReportTitle>{reportData.name}</ReportTitle>
              <StatusBadge status={reportData.status} short />
            </div>
          }
          right={
            <>
              <IconButton variant="contained" color="secondary">
                <Icon name="arrow-right" rotateAngle={180} />
              </IconButton>
              <IconButton variant="contained" color="secondary">
                <Icon name="arrow-right" />
              </IconButton>
              <IconButton
                variant="contained"
                color="secondary"
                onClick={onClose}
              >
                <Icon name="cross" />
              </IconButton>
            </>
          }
        />
      </div>

      <div className={clsx(styles.body)}>
        <MediaSection data={reportData} />

        <DetailsSection
          data={reportData}
          coreMetrics={activeCoreMetrics}
          otherMetrics={activeOtherMetrics}
        />
      </div>
    </Modal>
  )
)

export default ReportDetailsModal

// TODO provide metrics list from filter
const activeCoreMetrics = metricsDescriptors.slice(0, 4)
const activeOtherMetrics = metricsDescriptors.slice(4)

const reportData = generateCampaignReport(999)
