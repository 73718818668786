/* eslint-disable no-plusplus */

import { numberWithCommas, randomNumber } from "@components/utils/numberUtils"

const getListItems = () => {
  const items = []
  for (let i = 0; i < 1000; i++) {
    items.push({
      id: `${i}`,
      profileName: `Account Name ${
        Math.random() > 0.9 ? "long name to show tooltip" : ""
      } ${i}`,
      industry: "Resort",
      totalAdSpend: numberWithCommas(randomNumber(0, 5000)),
      profileStatus: Math.random() > 0.5 ? "Active" : "Inactive",
      profileCompetition: Math.random() > 0.5 ? "In progress" : "Completed",
      linkedAdwordsAccount: Math.random() > 0.5 ? "Yes" : "No",
      compaignStatus: Math.random() > 0.5 ? "Active" : "Inactive",
    })
  }

  return items
}

export default getListItems
