/* eslint-disable no-unused-expressions */
import uniqueId from "lodash/uniqueId"
import { observable, action, computed, reaction } from "mobx"
import {
  ProductFeedInstance,
  ProductFeedStatus,
} from "@framework/types/account"
import { ID, SortByType } from "@framework/types/types"
import accountService from "@services/account.service"
import InfiniteSelected from "@store/common/InfiniteSelected"
import RootStore from "../RootStore"
import { extractFilters, filterStateToURLParams } from "./utils"

const BATCH_SIZE = 40

export class ProductListStore {
  @observable private productFeedList: ID[] = []

  @observable private productFeedMap: Record<ID, ProductFeedInstance> = {}

  @observable isLoading: boolean = false

  @observable loadingError: string | null = null

  @observable rootStore: RootStore

  @observable totalCount: number = 99999

  @observable selected: InfiniteSelected

  @observable sortBy: SortByType = { value: "none", direction: false }

  @observable needReload: string | null = null

  @observable status: ProductFeedStatus | null = null

  constructor(root: RootStore) {
    this.rootStore = root
    this.selected = new InfiniteSelected()
    reaction(
      () => this.rootStore.accountStore.accountId,
      (accountId) => {
        if (!accountId) {
          this.productFeedMap = {}
          this.productFeedList = []
        }
      }
    )

    reaction(
      () => this.productFeedList,
      () => {
        this.selected = new InfiniteSelected({ total: this.totalCount })
      }
    )

    reaction(
      () => this.totalCount,
      () => this.selected.setTotal(this.totalCount)
    )

    reaction(
      () => this.sortBy,
      () => {
        this.reloadProductFeed()
      }
    )
  }

  @action loadStatus = async (accountId: number) => {
    try {
      const response = await accountService.fetchProductFeedStats(accountId)
      if (response.data.data) {
        this.status = response.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action reloadProductFeed = (reset: boolean = false) => {
    if (reset || !this.totalCount) {
      this.productFeedMap = {}
    }
    this.totalCount = 99999
    this.needReload = uniqueId("reload")
  }

  @action setSortBy = (sortBy: SortByType) => {
    this.sortBy = sortBy
  }

  @action updateFilters = (products: ProductFeedInstance[]) => {
    const first = products[0]
    if (first) {
      this.rootStore.productFeedStore.filter.filters.byAttributes.setFilters(
        extractFilters(first)
      )
    }
  }

  @action loadProductFeedList = async (
    accountId: number | null = null,
    offset: number = 0,
    limit: number = BATCH_SIZE,
    filter: Record<string, string[]> = {}
  ) => {
    this.isLoading = true
    this.loadingError = null
    try {
      if (accountId == null) return null

      const params = filterStateToURLParams(filter)
      params.append("limit", limit.toString())
      params.append("offset", offset.toString())
      if (this.sortBy.value !== "none") {
        params.append("sort", this.sortBy.value)
        params.append("order", this.sortBy.direction ? "ASC" : "DESC")
      } else {
        params.append("sort", "product_id")
        params.append("order", "ASC")
      }
      await accountService.fetchProductFeedStats(accountId)
      await accountService.fetchProductFeetStatusUpdate(accountId)
      const response = await accountService.getProductFeedList(
        accountId,
        params
      )
      if (response.data.data) {
        const { products, total } = response.data.data
        if (products != null && total != null) {
          this.updateFilters(products)
          products.forEach((item, idx) => {
            const index = offset + idx
            this.productFeedList[index] = item.product_id
            this.productFeedMap[item.product_id] = item
          })
        }
        this.totalCount = total ?? 0
        this.needReload = null
      } else {
        this.totalCount = offset
      }
    } catch (error: any) {
      this.productFeedMap = {}
      this.totalCount = 0

      if (error?.response?.data?.error != null) {
        this.loadingError = error?.response?.data?.error
      } else {
        this.loadingError = "Unknown error while loading product feed"
      }
    } finally {
      this.isLoading = false
    }
    return this.loadingError
  }

  @computed get productById() {
    const { productFeedMap } = this
    return (productId: ID | null): ProductFeedInstance | null => {
      if (!productId) return null
      return productFeedMap[productId]
    }
  }

  @computed get productIdByIndex() {
    const { productFeedList } = this
    return (index: number): ID | null => {
      if (productFeedList.length - 1 < index) return null
      const productId = productFeedList[index]
      return productId ?? null
    }
  }

  @computed get productByIndex() {
    const { productIdByIndex, productById } = this
    return (index: number): ProductFeedInstance | null => {
      const productId = productIdByIndex(index)
      if (!productId) return null
      return productById(productId) ?? null
    }
  }
}

export default ProductListStore
