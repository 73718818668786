import { useCallback } from "react"
import { useStore } from "@store/index"

import { LinkAccountCases, RedirectState } from "./LinkAccountRedirect"

const useAccountLinkRedirect = () => {
  const {
    accountLinkingStore: { locationLoading, getLink },
  } = useStore()

  const redirect = useCallback(
    async (linkingCase: LinkAccountCases, requestState: any) => {
      if (locationLoading) return null
      const state: RedirectState = { linkingCase, requestState }

      const result = await getLink(JSON.stringify(state))

      if (result.status === "success") window.location.href = result.data

      return result
    },
    [getLink, locationLoading]
  )

  return { redirect }
}

export default useAccountLinkRedirect
