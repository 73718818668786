import React, { useEffect } from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import useGlobalScrollSwitch from "@components/prototypes/GlobalScrollContext/useGlobalScrollSwitch"
import Drawer from "@components/ui/Drawer/Drawer"
import BlurContainer from "./BlurContainer"

import styles from "./SidebarContainer.module.scss"

export interface SidebarContainerProps {
  isOpen: boolean
  className?: string
  onClose?: (e: any) => void
}

export const SidebarContainer: React.FC<SidebarContainerProps> = ({
  isOpen,
  children,
  className,
  onClose,
}) => {
  const [_, toggleValue] = useGlobalScrollSwitch()

  useEffect(() => toggleValue(isOpen), [isOpen, toggleValue])

  useEffect(() => {
    if (!isOpen || !onClose) return undefined

    const handleKeyUp = (e: any) => {
      if (e.key === "Escape") onClose(e)
    }

    document.body.addEventListener("keyup", handleKeyUp)
    return () => document.body.removeEventListener("keyup", handleKeyUp)
  }, [isOpen, onClose])

  return (
    <BlurContainer blur={isOpen}>
      <Drawer open={isOpen}>
        <div className={clsx(styles.root, className)}>{children}</div>
        <IconButton
          variant="contained"
          className={styles.closeIcon}
          onClick={onClose}
          title="Close"
        >
          <Icon name="cross" />
        </IconButton>
      </Drawer>
    </BlurContainer>
  )
}

export default SidebarContainer
