import React, { useEffect, useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import compact from "lodash/compact"

import LineChart from "@components/ui/Charts/LineChart/LineChart"
import Typography from "@components/ui/Typography/Typography"
import ToggleButton from "@components/ui/CheckBox/ToggleButton/ToggleButton"
import { darkColors } from "@components/ui/Charts/utils"
import { accountKPIDropDownListMock } from "@components/ui/KPIsWidget/mockData"
import { useStore } from "@store/index"
import Tabs from "@components/ui/Tabs/Tabs"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import Box from "@components/ui/Box/Box"
import LegendList from "@components/ui/Charts/Legends/LegendList"
import Stack from "@components/ui/Stack/Stack"
import useToggle from "@framework/hooks/useToggle"
import makePerformanceChartConfig, {
  makePerformanceChartYAxisConfig,
} from "@components/ui/Charts/LineChart/config"
import { periodicityOptions } from "@framework/constants/report"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import { MetricNameType } from "@framework/types/metrics"
import { renderMetricOption } from "@framework/constants/metrics"
import Label from "@components/ui/CheckBox/Label/Label"
import InputLabel from "@components/ui/Label/Label"
import { extractKPIData } from "@store/account-dashboard/dataTransformers"

import styles from "./HistoricalKPITrends.module.scss"

type HistoricalKPITrendsProps = {
  className?: string
}

const HistoricalKPITrends: React.FC<HistoricalKPITrendsProps> = observer(
  ({ className }) => {
    const { isOpened: withCompare, handleToggle: toggleCompare } = useToggle()

    const { accountDashboardStore, accountStore } = useStore()
    const { accountId } = accountStore
    const {
      currPeriod,
      currPeriodPerformance,
      prevPeriodPerformance,
      activeMetrics,
      isHistoricalPerformanceLoading,
      currPeriodLabels: labels,
      error,
      periodicity,
      setPeriodicity,
      setMetric,
      loadHistoricalPerformance,
    } = accountDashboardStore

    useEffect(() => {
      if (accountId == null) return
      loadHistoricalPerformance(accountId)
    }, [currPeriod, accountId, periodicity])

    const currPeriodDataset = useMemo(() => {
      if (currPeriodPerformance == null) return null
      return {
        dashed: false,
        dataset: extractKPIData(activeMetrics, currPeriodPerformance),
      }
    }, [currPeriodPerformance, ...activeMetrics])

    const prevPeriodDataset = useMemo(() => {
      if (prevPeriodPerformance == null || !withCompare) return null
      return {
        dashed: true,
        dataset: extractKPIData(
          activeMetrics,
          prevPeriodPerformance,
          "(previous year)"
        ),
      }
    }, [withCompare, prevPeriodPerformance, ...activeMetrics])

    const data = useMemo(() => {
      if (error) return []
      return compact([currPeriodDataset, prevPeriodDataset])
    }, [currPeriodDataset, prevPeriodDataset, error])

    const chartConfig = useMemo(
      () =>
        makePerformanceChartConfig(
          labels,
          periodicity,
          {
            y1: makePerformanceChartYAxisConfig(activeMetrics[0], "left"),
            y2: makePerformanceChartYAxisConfig(
              activeMetrics[1],
              "right",
              false
            ),
          },
          "unit"
        ),
      [labels, periodicity, ...activeMetrics]
    )

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.wrapper}>
          <Typography type="h1" color="primary" bold>
            Historical KPI Trends
          </Typography>

          <div className={styles.controls}>
            <Label
              text="Compare to previous year"
              textPosition="before"
              type="subhead1"
              upperCase
              weight="bold"
            >
              <ToggleButton checked={withCompare} onChange={toggleCompare} />
            </Label>

            <div className={styles.dropDownsWrapper}>
              {activeMetrics.map((metric, idx) => (
                <InputLabel label={`KPI ${idx + 1}`}>
                  <SimpleSelect
                    options={accountKPIDropDownListMock}
                    renderValue={renderMetricOption}
                    onSelect={(option) =>
                      setMetric(idx, option as MetricNameType)
                    }
                    value={metric}
                  />
                </InputLabel>
              ))}
            </div>
          </div>
        </div>
        <Box className={styles.chartBox}>
          <Stack direction="row" justify="space-between">
            <div className={styles.legendContainer}>
              {data.map(({ dataset: data, dashed }, idx) => (
                <LegendList
                  items={data}
                  colors={darkColors}
                  dashed={dashed}
                  key={darkColors[idx]}
                />
              ))}
            </div>
            <Tabs
              options={periodicityOptions}
              currentValue={periodicity}
              onChange={({ value }) =>
                setPeriodicity(value as PerformanceReportPeriodicity)
              }
            />
          </Stack>
          <LineChart
            lineData={data}
            labels={labels}
            colors={darkColors}
            options={chartConfig}
            isLoading={isHistoricalPerformanceLoading}
          />
        </Box>
      </div>
    )
  }
)

export default HistoricalKPITrends
