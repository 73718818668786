import React from "react"
import ReportSkeletonCard from "./ReportSkeletonCard"

import styles from "./ReportSummaryPreview.module.sass"

interface ReportGroupProps {
  totalMetrics?: number
}

export const ReportSummaryPreview: React.FC<ReportGroupProps> = ({
  totalMetrics = 14,
  children,
}) => {
  const metricRows = Math.min(totalMetrics - 7, 1) // minus preview img height
  return (
    <div className={styles.root}>
      <div className={styles.previews}>
        <ReportSkeletonCard totalMetrics={metricRows} />
        <ReportSkeletonCard totalMetrics={metricRows} />
      </div>

      {children}
    </div>
  )
}

export default ReportSummaryPreview
