import { observable, action, computed, reaction, toJS } from "mobx"
import _groupBy from "lodash/groupBy"
import _ceil from "lodash/ceil"

import accountService, {
  AttributeCategoryType,
  AttributeType,
  KeywordsGenerationStats,
} from "@services/account.service"
import { Option } from "@framework/types/types"
import { numberWithCommas } from "@utils/numberUtils"
import { secondsToRemainText } from "@utils/date"
import AccountDashboardStore from "@store/account-dashboard/account-dashboard"
import AccountStore from "../account/account"
import {
  transformAccountForm,
  transformAdCopyData,
  transformAssignedAttributes,
} from "./dataTransformers"
import { AssignedAttributes } from "./types"

export const MIN_ROWS = 3
export const MAX_ADS_HL_ROWS = 14
export const MAX_DESC_ROWS = 4
export const GENERATE_PER_HOUR = 1_000_000

const getTrashIds = (startIndex: number, maxCount: number, prefix: string) =>
  Array.from(new Array(maxCount)).map((_, i) => `${prefix}${i + startIndex}`)

export const headlineKeys = getTrashIds(2, 14, "creativeAdHl")
export const descriptionKeys = getTrashIds(1, 8, "creativeDesc")

export class ManageProfileStore {
  @observable accountStore: AccountStore

  @observable accountDashboardStore: AccountDashboardStore

  @observable isValid = false

  @observable isEdit = false

  @observable submitFormCallback?: () => void

  @observable industries: Option[] | null = null

  @observable attributes: Record<number, AttributeType[]> | null = null

  @observable totalAttributes = 0

  @observable categories: AttributeCategoryType[] | null = null

  @observable attributesLoading = false

  @observable attributesAssignLoading = false

  @observable assignedAttributes: AssignedAttributes | null = null

  @observable industriesLoading = false

  @observable keywordsStatsLoading = false

  @observable keywordsGenerationStats: KeywordsGenerationStats | null = null

  @observable hasNonProcessedAttributes: boolean = false

  @observable showBar: boolean = true

  @observable isGenerating: boolean = false

  @action setShowBar = (value: boolean) => {
    this.showBar = value
  }

  @action setIsGenerating = (value: boolean) => {
    this.isGenerating = value
  }

  @computed get totalCategories() {
    return this.categories?.length ?? 0
  }

  @computed get defaultHeadlineEntries() {
    if (this.accountStore?.account) {
      const { account } = this.accountStore
      return transformAdCopyData([...headlineKeys], account, MIN_ROWS)
    }
    return null
  }

  @computed get defaultDescriptionEntries() {
    if (this.accountStore?.account) {
      const { account } = this.accountStore
      return transformAdCopyData([...descriptionKeys], account, MIN_ROWS)
    }
    return null
  }

  @computed get accountInfo() {
    return {
      ...transformAccountForm(this.accountStore?.account),
      goals: toJS(this.accountDashboardStore.goals.settings ?? []),
    }
  }

  constructor(injections: {
    accountStore: AccountStore
    accountDashboardStore: AccountDashboardStore
  }) {
    this.accountStore = injections.accountStore
    this.accountDashboardStore = injections.accountDashboardStore

    reaction(
      () => this.keywordsGenerationStats?.running,
      (running) => {
        if (running == null) return
        if (running && !this.showBar) this.setShowBar(true)
        if (this.isGenerating !== running) this.setIsGenerating(running)
      }
    )
  }

  @action cleanUp = () => {
    try {
      this.attributes = null
      this.categories = null
      this.assignedAttributes = null
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchIndustries = async () => {
    this.industriesLoading = true
    try {
      const response = await accountService.getIndustriesList()

      this.industries =
        response.data.data?.map((item) => ({
          value: item.id.toString(),
          label: item.name,
        })) ?? []
    } catch (error) {
      console.log(error)
    }
    this.industriesLoading = false
  }

  @action fetchAttributes = async (industryId: number) => {
    this.attributesLoading = true
    try {
      const response = await accountService.getAttributesByIndustryId(
        industryId
      )

      const attributes = _groupBy(
        response.data.data.records ?? [],
        "categoryId"
      )
      this.categories = Object.keys(attributes).map(
        (id) => attributes[id][0].category
      )
      this.attributes = attributes
      this.totalAttributes = response.data.data.total
    } catch (error) {
      this.totalAttributes = 0
      this.categories = null
      this.attributes = null
      console.log(error)
    }
    this.attributesLoading = false
  }

  @action fetchAssignedAttributes = async (accountId: number) => {
    // this.attributesLoading = true
    try {
      const response = await accountService.getAssignedAttributesByAccountId(
        accountId
      )

      const { assignedAttributes, isAllAttributesProcessed } =
        transformAssignedAttributes(response.data.data.records ?? [])

      this.assignedAttributes = assignedAttributes
      this.hasNonProcessedAttributes = !isAllAttributesProcessed
    } catch (error) {
      console.log(error)
    }
    // this.attributesLoading = false
  }

  @action updateAttributesAssignment = async (accountId: number, data: any) => {
    this.attributesAssignLoading = true
    try {
      await accountService.updateAttributesAssignment(accountId, data)
    } catch (error) {
      console.log(error)
    }
    this.attributesAssignLoading = false
  }

  @action loadKeywordsGenerationStats = async (accountId: number) => {
    this.keywordsStatsLoading = true
    try {
      const response = await accountService.getKeywordsGeneratingStatus(
        accountId
      )
      this.keywordsGenerationStats = response.data.data
    } catch (error) {
      console.log(error)
    }
    this.keywordsStatsLoading = false
  }

  @action startKeywordsGenerating = async (accountId: number) => {
    this.keywordsStatsLoading = true
    try {
      this.setIsGenerating(true)
      this.setShowBar(true)
      await accountService.startKeywordsGenerating(accountId)
      await this.loadKeywordsGenerationStats(accountId)
    } catch (error) {
      console.log(error)
    }

    this.keywordsStatsLoading = false
  }

  @action setIsEdit = (state: boolean) => {
    this.isEdit = state
    this.setSubmitCallback()
  }

  @action setSubmitCallback = (callback?: () => void) => {
    this.submitFormCallback = callback
  }

  @computed get generatingStatusMessage() {
    const { keywordsGenerationStats: status, isGenerating, showBar } = this
    if (!showBar || !status) return null
    const isCompleted = !isGenerating
    if (!isCompleted) {
      const { generated, unique, processed, running } = status
      if (running && generated > 0) {
        const progress = _ceil((processed / unique) * 95)
        const time = _ceil(((generated - processed) / GENERATE_PER_HOUR) * 3600)
        const remainsTimeText = secondsToRemainText(time)
        return {
          title: `Generating and analyzing search volume for ${numberWithCommas(
            generated
          )} keywords`,
          message: `${remainsTimeText}`,
          percentage: progress,
          isCompleted,
        }
      }

      return {
        title: "Starting keyword generating process",
        message: "",
        percentage: 0,
        isCompleted,
      }
    }

    const { generated, finished_at, started_at } = status
    const finishDate = Date.parse(finished_at)
    const startDate = Date.parse(started_at)
    if (finishDate && finishDate > 0 && finishDate > startDate) {
      return {
        title: "Generating process is completed",
        message: `${numberWithCommas(generated)} keywords generated`,
        percentage: 100,
        isCompleted,
      }
    }

    return null
  }
}

export default ManageProfileStore
