import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"

import Stack from "@components/ui/Stack/Stack"
import { initArray } from "@utils/numberUtils"
import { metricsDescriptors } from "@framework/constants/metrics"
import useToggle from "@framework/hooks/useToggle"
import ReportControlPanel from "../ReportControlPanel"
import ReportCard from "../components/ReportCard/ReportCard"
import { MetaCampaignReportData, ViewType } from "../types"
import mapper from "./mapper"
import Table from "../components/Table/Table"
import ReportDetailsModal from "../components/ReportDetails/ReportDetails"
import { generateCampaignReport } from "../mock"

import styles from "./General.module.scss"

type GeneralPageProps = {
  className?: string
}

const GeneralPage: React.FC<GeneralPageProps> = observer(({ className }) => {
  const { accountStore } = useStore()

  const [view, setView] = React.useState<ViewType>("card")

  const modal = useToggle()

  // TODO provide metrics list from filter
  const activeMetricsMock = metricsDescriptors

  return (
    <div className={clsx(styles.root, className)}>
      <Stack direction="column" gutter="20" align="stretch">
        <ReportControlPanel view={view} onViewChange={setView} />

        {view === "table" && (
          <Table
            className={styles.table}
            mapper={mapper}
            data={reportsMock}
            summary={summaryReport}
          />
        )}

        {view === "card" && (
          <div className={styles.grid}>
            {reportsMock.map((report) => (
              <ReportCard
                data={report}
                metrics={activeMetricsMock}
                onClick={modal.handleToggle}
                key={report.id}
              />
            ))}
          </div>
        )}
      </Stack>

      <ReportDetailsModal
        open={modal.isOpened}
        onClose={() => modal.setOpened(false)}
      />
    </div>
  )
})

export default GeneralPage

const reportsMock = initArray<MetaCampaignReportData>(111, (idx) =>
  generateCampaignReport(idx)
)

const summaryReport = generateCampaignReport(999, false)
