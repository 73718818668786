import { useRef, useEffect, useCallback, RefObject } from "react"

interface UseEventListener {
  type: keyof WindowEventMap | keyof DocumentEventMap
  listener: EventListener
  element?: RefObject<Element> | Document | Window | null | VisualViewport
  options?: AddEventListenerOptions
}

export const useEventListener = ({
  type,
  listener,
  element = window,
  options,
}: UseEventListener): void => {
  const savedListener = useRef<EventListener>()

  useEffect(() => {
    savedListener.current = listener
  }, [listener])

  const handleEventListener = useCallback((event: Event) => {
    savedListener.current?.(event)
  }, [])

  useEffect(() => {
    const target = getRefElement(element)
    target?.addEventListener(type, handleEventListener, options)
    return () => target?.removeEventListener(type, handleEventListener)
  }, [type, element, options, handleEventListener])
}

export const getRefElement = <T>(
  element?: RefObject<Element> | T
): Element | T | undefined | null => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (element && "current" in element) {
    return element.current
  }

  return element
}

export default useEventListener
