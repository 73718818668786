import findLast from "lodash/findLast"
import sumBy from "lodash/sumBy"
import startCase from "lodash/startCase"
import sortBy from "lodash/sortBy"

import { idArrayToOptions } from "@components/utils/arrayUtils"
import {
  FilterOptionData,
  ProductAttribute,
  ProductCategoryNodeData,
  ProductCategoryOptionData,
  ProductFeedInstance,
  ProductTempChangeData,
  ProductTempChangeEntity,
} from "@framework/types/account"
import { Option } from "@framework/types/types"
import { editableProductFeedMapper } from "@pages/GoogleShoppingChannel/ProductFeed/productEditFormMapper"
import { FilterOption } from "./filter-by-attributes"
import { Node } from "./types"

const optionalFields: ProductAttribute[] = [
  "adult",
  "ageGroup",
  "availability",
  "gender",
  "energyEfficiencyClass",
  "maxEnergyEfficiencyClass",
  "minEnergyEfficiencyClass",
  "sizeSystem",
  "sizeType",
  "subscriptionCostPeriod",
  "identifierExists",
  "isBundle",
]

// TODO move to model utils
export const isFilterOptionData = (
  entity: FilterOptionData | any
): entity is FilterOptionData =>
  entity != null &&
  typeof entity === "object" &&
  "values" in entity &&
  Array.isArray(entity.values)

export const transformCategoryOption = (
  category: ProductCategoryOptionData
): Option => ({
  label: category.google_category,
  value: category.id.toString(),
})

export const extractFilters = (product: ProductFeedInstance): FilterOption[] =>
  optionalFields.reduce<FilterOption[]>((acc, key) => {
    const entity = product[key]
    if (isFilterOptionData(entity))
      acc.push({
        label: startCase(key),
        name: key,
        options: idArrayToOptions(entity.values),
      })
    return acc
  }, [])

export const filterStateToURLParams = (
  filter: Record<string, string[]>
): URLSearchParams => {
  const params = new URLSearchParams()
  Object.entries(filter).forEach(([filterName, values]) =>
    values.forEach((value) => params.append(filterName, value))
  )
  return params
}

export const countChangesCount = (
  data: ProductFeedInstance,
  changeData: ProductTempChangeData
) => {
  if (!changeData || !data) return []
  return editableProductFeedMapper.reduce<string[]>((acc, { name, label }) => {
    const change = (changeData?.update as any)?.[name]
    if (change != null && data[name] !== change) {
      acc.push(label)
    }
    return acc
  }, [])
}

/**
 * @deprecated
 */
export const sliceBaseCategories = (
  allTypes: ProductCategoryOptionData[]
): Record<string, any> => {
  if (!allTypes.length) return []
  const total = allTypes.length
  const categories: any = {}
  for (let i = 0, currentItem = ""; i < total; i += 1) {
    currentItem = allTypes[i].google_category
    if (!findLast(categories, (v) => currentItem.startsWith(v))) {
      const key: string = allTypes[i].google_category.split(" > ")[0]
      if (key) categories[key] = null
    }
  }
  return categories
}

export const parseCategoryNodes = (
  categories: ProductCategoryNodeData[]
): Record<string, any> =>
  Object.fromEntries(
    sortBy(categories, (it) => it.google_category).map((it) => [
      it.google_category,
      parseCategoryNodes(it.children),
    ])
  )

const deepGrouping = (list: string[], group: any = {}) => {
  if (list.length) {
    const [prefix, ...other] = list
    // eslint-disable-next-line no-param-reassign
    group[prefix] = {
      ...group[prefix],
      ...deepGrouping(other, group[prefix]),
    }
  }
  return group
}

export const parseCategoryOptions = (
  categoryList: ProductCategoryOptionData[]
): Record<string, any> => {
  const table = categoryList.map((item) => item.google_category.split(" > "))

  if (!table.length) return {}
  return table.reduce<Record<string, string[]>>(
    (acc, row) => deepGrouping(row, acc),
    {}
  )
}

export const parseCategoryTree = (categoryList: string[]): Node => {
  if (!categoryList.length) return new Map()
  return categoryList
    .map((item) => item.split(" > "))
    .reduce<Node>((acc, row) => {
      getNodeDeep(acc, row)
      return acc
    }, new Map())
}

export const getNodeDeep = (root: Node, path: string[]) => {
  let node: Node = root
  for (let i = 0; i < path.length; i += 1) {
    if (!node.has(path[i])) {
      node.set(path[i], new Map())
    }
    node = node.get(path[i])!
  }
  return node
}

export const stringifyTree = (node: Node, prefix?: string) => {
  const it = node.entries()
  const list: string[] = []

  if (!node.size) return prefix ? [prefix] : []

  let result = it.next()
  while (!result.done) {
    const pref = result.value[0]
    const next = result.value[1]
    const nextPref = prefix ? `${prefix} > ${pref}` : pref
    list.push(...stringifyTree(next, nextPref))
    result = it.next()
  }
  return list
}

export const countFilters = (filter: Record<string, string[]>) =>
  sumBy(Object.values(filter), ({ length }) => length)

export const transformTempChange = (
  data: ProductTempChangeEntity
): ProductTempChangeData => {
  const {
    changes,
    product_id,
    propertyId,
    itemGroupId,
    origin,
    status,
    updatedAt,
    ...rest
  } = data
  return {
    update: Object.entries(rest).reduce<ProductTempChangeData["update"]>(
      (acc, [key, value]) => {
        const newValue = isFilterOptionData(value) ? value.control : value
        if (newValue != null)
          acc[key as keyof ProductTempChangeData["update"]] = newValue
        return acc
      },
      {}
    ),
    meta: {
      changes,
      product_id,
      propertyId,
      itemGroupId,
      origin,
      status,
      updatedAt,
    },
  }
}
