import React from "react"
import { observer } from "mobx-react-lite"

import AttributesForm from "@pages/PaidSearchChannel/ManageProfile/components/Attributes/components/AttributesForm/AttributesForm"
import Typography from "@components/ui/Typography/Typography"
import Templates from "@components/ui/Templates"
import { Button } from "@components/ui/Button"
import { AssignedAttributes } from "@store/manageProfile/types"
import { useDemoMultiformContext } from "./tasks/FormManager"

import styles from "./CustomizingAttributesSubForm.module.scss"

const CustomizingAttributesSubForm: React.FC = observer(() => {
  const store = useDemoMultiformContext()
  const { categories, attributes } = store.manageProfileStore

  const [selected, setSelectedAttributes] = React.useState<AssignedAttributes>(
    store.attributes ?? new Set()
  )

  const handleStartGenerating = async () => {
    store.saveAttributes(selected)
    store.startKeywordsGeneration()
    store.nextStep()
  }

  const isValid = selected.size > 0

  return (
    <div className={styles.root}>
      <Templates.Header
        className={styles.header}
        left={<Typography type="h2">Attributes</Typography>}
        right={
          <Button
            disabled={!isValid}
            variant="contained"
            onClick={handleStartGenerating}
          >
            Generate Keywords
          </Button>
        }
      />
      <AttributesForm
        selectedAttributes={selected}
        categories={categories ?? undefined}
        attributes={attributes ?? undefined}
        onChange={setSelectedAttributes}
      />
    </div>
  )
})

export default CustomizingAttributesSubForm
