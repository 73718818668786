import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { AdSetReport } from "@store/reports/types"
import NoData from "@components/ui/NoData/NoData"
import { useTableContext } from "./TableContext"
import { sortReports } from "./utils"
import MetaAdSetRow from "./MetaAsSetRow"

interface CampaignReportListProps {
  list: string[]
  mapper: MapperType[]
}

const MetaAdSetReportList: React.FC<CampaignReportListProps> = observer(
  ({ list, mapper }) => {
    const {
      reports: { extendedReport },
    } = useStore()

    const context = useTableContext()

    const reports = useMemo(
      () =>
        sortReports(
          list.reduce<AdSetReport[]>((list, id) => {
            const item = extendedReport.metaAdSetReports.get(id)
            if (item != null) list.push(item)
            return list
          }, []),
          context.sortBy
        ),
      [list, context.sortBy, extendedReport.metaAdSetReports]
    )

    if (!reports.length) return <NoData>Not found</NoData>
    return (
      <>
        {reports.map((report) => (
          <MetaAdSetRow data={report} mapper={mapper} key={report.id} />
        ))}
      </>
    )
  }
)

export default MetaAdSetReportList
