import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import NumberFormat, { NumberFormatValues } from "react-number-format"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import TextField from "@components/ui/TextField/TextField"
import { CalculatorDataIn } from "@framework/types/opportunities"
import { Option } from "@framework/types/types"
import Label from "@components/ui/Label/Label"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import { DatasetType, Table } from "../Table/Table"
import { calculatorTableMapper } from "../../mock.temp"
import { MonthlySearchVolume } from "../MonthlySearchVolume/MonthlySearchVolume"

import styles from "./Calculator.module.scss"

export type CalculatorProps = {
  showChart?: boolean
  toggleChart?: () => void
}

export interface FieldMapper extends Option<keyof CalculatorDataIn> {
  prefix?: string
  suffix?: string
}

const mapper: FieldMapper[] = [
  { value: "monthlyAdBudget", label: "Monthly Ad budget $", prefix: "$" },
  { value: "avgCpc", label: "Avg.CPC $ ", prefix: "$" },
  { value: "ctr", label: "CTR %", suffix: "%" },
  { value: "webConversion", label: "Website conversion %", suffix: "%" },
  { value: "aov", label: "AOV $", prefix: "$" },
]

const Calculator: React.FC<CalculatorProps> = observer(
  ({ showChart = false, toggleChart }) => {
    const opportunitiesStore = useOpportunitiesStore()
    const { calculator } = opportunitiesStore
    calculator.selectedKeywords =
      opportunitiesStore.shoppingCart.selectedKeywords
    const { calculatorOnChange } = calculator

    useEffect(() => {
      calculator.avgCpc = opportunitiesStore.shoppingCart.calculatedAvgCpc
    }, [opportunitiesStore.shoppingCart.calculatedAvgCpc])

    const {
      mySearchVolume,
      avgCpc,
      ctr,
      clicks,
      estimated,
      revenue,
      roas,
      conversions,
      constConversion,
      actualBudget,
      adBudgetDifference,
    } = calculator

    const tableData: DatasetType = {
      mySearchVolume,
      avgCpc,
      ctr,
      clicks,
      estimated,
      revenue,
      roas,
      actualBudget,
      conversions,
      constConversion,
      adBudgetDifference,
    }

    return (
      <span className={styles.root}>
        <Box padding="0px">
          <div className={styles.header}>
            <Typography type="h2" color="dark">
              Selected Keyword Calculator
            </Typography>
          </div>
          <div className={styles.form}>
            {mapper.map(({ value, label, prefix, suffix }) => (
              <Label label={label}>
                <NumberFormat
                  decimalScale={2}
                  customInput={TextField}
                  thousandSeparator
                  prefix={prefix}
                  suffix={suffix}
                  value={calculator[value] || ""}
                  onValueChange={(values: NumberFormatValues) =>
                    calculatorOnChange(values, value)
                  }
                />
              </Label>
            ))}
          </div>
          <div className={styles.body}>
            <div className={styles.divider} />
            <div className={styles.tableContainer}>
              <Table data={[tableData]} dataMapper={calculatorTableMapper} />
            </div>
          </div>
        </Box>

        <MonthlySearchVolume
          className={styles.chartCard}
          open={showChart}
          onToggle={toggleChart}
        />
      </span>
    )
  }
)

export default Calculator
