import React, { FC, useState } from "react"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import AudienceSignalSidebar from "@pages/PMax/components/EditAssetGroupPage/AudienceSignal/AudienceSignalSidebar"
import { observer } from "mobx-react-lite"
import styles from "./AudienceSignal.module.scss"

interface AudienceSignalProps {}

const AudienceSignal: FC<AudienceSignalProps> = observer(() => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={styles.audienceSignalWrap}>
      <div className={styles.audienceSignalTitle}>
        <Typography type="h2">Audience signal</Typography>
        <Icon name="question-circle" />
      </div>
      <Typography type="subtitle" color="gray">
        Use an audience signal to reach the right audiences faster. Start by
        creating or adding an audience. Performance Max will use it as a
        starting point, to find new conversions across Google.
      </Typography>
      <Button
        color="primary"
        variant="ghost"
        className={styles.buttonIcon}
        onClick={() => setIsOpen(true)}
      >
        Edit an audience signal
      </Button>
      {/* <AudienceSignalItem */}
      {/*  audience={null} */}
      {/* /> */}
      <div className={styles.audienceSignalSwitch}>
        {/* <Button */}
        {/*  color="primary" */}
        {/*  variant="ghost" */}
        {/*  className={styles.buttonIcon} */}
        {/*  onClick={() => setIsOpen(true)} */}
        {/* > */}
        {/*  Create an audience signal */}
        {/* </Button> */}
      </div>
      <div className={styles.audienceSignalInfo}>
        <Icon
          name="checkbox-circle1"
          primary
          className={styles.audienceSignalInfoIcon}
        />
        <Typography type="caption2">
          Your audience signal has been added. Performance Max will go beyond
          your selections to find new conversions based on your goals.
        </Typography>
      </div>

      <AudienceSignalSidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
})

export default AudienceSignal
