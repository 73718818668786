import React from "react"
import { SortByType } from "@framework/types/types"
import { useStore } from "@store/index"
import { searchCampaigns, sortCampaignList } from "@pages/ManageCampaign/utils"
import { CampaignReportDataType } from "@framework/types/account"

export const useCampaignsReports = (config: {
  campaignIds: string[]
  campaignTypes?: string[]
  sortBy?: SortByType
  searchQuery?: string
}) => {
  const { campaignIds, campaignTypes, sortBy, searchQuery } = config

  const {
    manageCampaignStore: { campaignsReport, campaignsReportLoading },
  } = useStore()

  const [inProgress, setInProgress] = React.useState(false)

  const [sortedCampaigns, setSortedCampaigns] = React.useState<
    CampaignReportDataType[]
  >([])

  const update = async (
    campaignList: CampaignReportDataType[],
    selectedCampaignIds: string[],
    selectedCampaignTypes: string[],
    sortBy?: SortByType,
    searchQuery?: string
  ) => {
    setInProgress(true)

    const selected = await selectCampaignsAsync(campaignList, {
      campaignIds: selectedCampaignIds,
      campaignTypes: selectedCampaignTypes,
    })
    const filtered = await searchCampaignsAsync(selected, searchQuery)
    const res = await sortCampaignsAsync(filtered, sortBy)

    setSortedCampaigns(res)
    setInProgress(false)
  }

  React.useEffect(() => {
    update(
      campaignsReport ?? [],
      campaignIds,
      campaignTypes ?? [],
      sortBy,
      searchQuery
    )
  }, [campaignsReport, searchQuery, sortBy, campaignIds, campaignTypes])

  return { sortedCampaigns, inProgress: inProgress || campaignsReportLoading }
}

const sortCampaignsAsync = async (
  campaignList: CampaignReportDataType[],
  sortBy?: SortByType
) => {
  if (campaignList.length === 0) return []

  const res = await sortCampaignList(campaignList, sortBy)

  return res
}

const searchCampaignsAsync = async (
  campaignList: CampaignReportDataType[],
  searchQuery: string = ""
) => {
  const res = await searchCampaigns(campaignList, searchQuery)
  return res
}

const selectCampaignsAsync = async (
  campaignList: CampaignReportDataType[],
  options: { campaignIds: string[]; campaignTypes: string[] }
) =>
  campaignList.filter((it) => {
    if (!options.campaignIds.includes(it.Campaign.Id.toString())) return false

    if (
      it.Campaign.advertisingChannelType &&
      !options.campaignTypes.includes(it.Campaign.advertisingChannelType)
    )
      return false

    return true
  })

export default useCampaignsReports
