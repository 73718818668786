/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React, { useContext } from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import { MapperType } from "./types"
import { TableOpenContext } from "./WaterfallTable"

import styles from "./WaterfallTable.module.scss"

export interface TypedRowProps {
  label: React.ReactNode
  status?: React.ReactNode
  containerClassName?: string
  leftTip?: React.ReactNode
  mapper?: MapperType[]
  active?: boolean
  data?: any
  onClick?: React.MouseEventHandler
}

export const TypedRow: React.FC<TypedRowProps> = observer(
  ({
    leftTip = null,
    containerClassName,
    label,
    mapper,
    active = false,
    data,
    status,
    onClick,
  }) => {
    const context = useContext(TableOpenContext)

    const renderCell = (item: MapperType) => {
      if (!data) return null
      if (item.render) {
        const { Component, props = null } = item.render
        const value = data[item.name]
        return (
          <Component name={item.name} value={value} data={data} {...props} />
        )
      }

      if (item.renderCallback)
        return item.renderCallback(data[item.name], data, item.name)

      return data[item.name]
    }

    return (
      <div
        className={clsx(
          styles.rowHeader,
          { [styles.active]: active, [styles.clickable]: onClick != null },
          containerClassName
        )}
        onClick={onClick}
      >
        <div className={styles.label}>
          {context.withTip ? leftTip : null}
          <Typography className={styles.column} type="h5" color="dark">
            {label}
          </Typography>
          {status != null && (
            <Typography className={styles.status} type="h5" color="dark">
              {status}
            </Typography>
          )}
        </div>
        {mapper?.map((item) => (
          <Typography
            type="h5"
            color="dark"
            className={styles.column}
            key={item.name}
            style={{ flexBasis: item.widthBasis, minWidth: item.widthBasis }}
          >
            {renderCell(item)}
          </Typography>
        ))}
      </div>
    )
  }
)

export default TypedRow
