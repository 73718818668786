import React from "react"
import clsx from "clsx"

import styles from "./Table.module.scss"

export interface TableProps {
  className?: string
}

export const Table: React.FC<TableProps> = ({ children, className }) => (
  <div className={clsx(styles.root, className)}>{children}</div>
)

export default Table
