import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"

import PrivateLayout, {
  NavBarProps,
} from "@root/layouts/PrivateLayout/PrivateLayout"
import { NavOption } from "@components/ui/Tabs/types"
import { useStore } from "@store/index"
import { findDefaultNavItem } from "@components/ui/Tabs/utils"
import { accountSidebarNavItems, paidSearchNavItems } from "../navOptions"

const defPageNavItem =
  accountSidebarNavItems.find(({ value }) => value === "/channels") ?? null

const GoogleAdsMainLayout: React.FC<{ embedded?: boolean }> = observer(
  ({ embedded, children }) => {
    const history = useHistory()
    const { accountStore } = useStore()
    const { account, accountId } = accountStore

    const [tab, setTab] = useState<NavOption>()
    const [page, setPage] = useState<NavOption | null>(defPageNavItem)

    const { pathname, search } = useLocation<any>()

    const queryParams = useMemo(
      () => Object.fromEntries(new URLSearchParams(search)),
      [search]
    )

    const handleChangeTab = useCallback(
      (item: NavOption) => {
        history.push(`/google-ads/${accountId}${item.value}`)
      },
      [history, accountId]
    )

    const handleChangePage = useCallback(
      (item: NavOption) => {
        if (accountId) history.push(`/account/${accountId}${item.value}`)
        else history.push("/")
      },
      [history, accountId]
    )

    const withNavBar: NavBarProps | undefined = useMemo(() => {
      const confirmation = pathname.endsWith("/create-campaign")

      if (confirmation) return undefined

      return {
        options: paidSearchNavItems,
        title: account?.name ?? "",
        onSelect: handleChangeTab,
        currentTab: tab,
      }
    }, [account, handleChangeTab, pathname, tab])

    const sidebarNav: NavBarProps | undefined = useMemo(() => {
      const confirmation = pathname.endsWith("/create-campaign")

      if (confirmation) return undefined

      return {
        options: accountSidebarNavItems,
        title: account?.name ?? "",
        onSelect: handleChangePage,
        currentTab: page ?? undefined,
      }
    }, [account, handleChangePage, pathname, page])

    useEffect(() => {
      setTab(
        findDefaultNavItem(paidSearchNavItems, pathname, queryParams.alter)
      )
    }, [pathname, queryParams])

    return (
      <PrivateLayout
        navBar={withNavBar}
        sideBar={sidebarNav}
        scope="channel"
        embedded={embedded}
      >
        {children}
      </PrivateLayout>
    )
  }
)

export default GoogleAdsMainLayout
