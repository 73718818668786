/* eslint-disable jsx-a11y/no-autofocus */
import React from "react"
import { observer, useLocalStore } from "mobx-react-lite"
import sortBy from "lodash/sortBy"

import SimpleOption from "@components/ui/DropDown/Option"
import Select from "@components/ui/DropDown"
import NoData from "@components/ui/NoData/NoData"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import TextField from "@components/ui/TextField/TextField"
import { ID } from "@framework/types/types"
import IconButton from "@components/ui/Button/IconButton"
import BaseField from "@components/ui/TextField/BaseField"
import { BaseCampaignReportType } from "@framework/types/account"
import SearchCampaignsStore from "./SearchCampaignsStore"

import styles from "./CampaignsSelect.module.scss"

export interface CampaignsSelectProps {
  value: string[]
  options: BaseCampaignReportType[]
  className?: string
  unit?: string
  placeholder?: string
  disabled?: boolean
  isOptionValuable?: (keywordId: string) => void
  onChange?: (keywordId: string[]) => void
}

const CampaignsSelect = observer(
  ({
    value,
    placeholder = "Select...",
    unit = "item",
    className,
    disabled,
    options,
    isOptionValuable,
    onChange,
  }: React.PropsWithChildren<CampaignsSelectProps>) => {
    const searchStore = useLocalStore(
      () => new SearchCampaignsStore({ campaigns: options })
    )

    const totalOptions = searchStore.campaigns.length

    const isSelected = (id: ID) => value.includes(id.toString())
    const isAllSelected = value.length === totalOptions

    const onSelect = (id: ID) => {
      onChange?.(
        isSelected(id)
          ? value.filter((it) => it.toString() !== id.toString())
          : [...value, id.toString()]
      )
    }

    const onSelectAll = () => {
      onChange?.(
        !isAllSelected
          ? searchStore.campaigns.map((it) => it.Id.toString())
          : []
      )
    }

    const sortedOptions = sortBy(
      searchStore.filteredCategories,
      (it) => !isSelected(it.Id)
    )

    React.useEffect(() => {
      searchStore.setCampaigns(options)
    }, [options, searchStore])

    return (
      <Select.DropDown
        disabled={disabled}
        placement="bottom-end"
        keepOpened
        className={className}
        target={(isActive) => (
          <BaseField
            active={isActive}
            disabled={disabled}
            after={
              <IconButton>
                <Icon name="arrow-down" rotateAngle={isActive ? 180 : 0} />
              </IconButton>
            }
          >
            <Typography type="inherit">
              {isAllSelected
                ? `All ${unit}s`
                : value.length
                ? `${value.length} selected`
                : placeholder}
            </Typography>
          </BaseField>
        )}
      >
        <Select.Box>
          <TextField
            className={styles.search}
            placeholder="Search"
            before={<Icon name="search" />}
            value={searchStore.searchFiler}
            onChange={(e) => searchStore.setSearchFilter(e.target.value)}
            after={
              searchStore.searchFiler ? (
                <IconButton onClick={() => searchStore.setSearchFilter("")}>
                  <Icon name="cross" />
                </IconButton>
              ) : undefined
            }
            key="search"
          />

          <Select.Group key="all">
            <SimpleOption
              isSelected={isAllSelected}
              onClick={onSelectAll}
              key="all"
            >
              All {unit}s
            </SimpleOption>
          </Select.Group>

          <Select.Group key="options">
            <Select.ItemsList key="options_list">
              {sortedOptions.length ? (
                sortedOptions.map((it) => (
                  <SimpleOption
                    displayDisabled={
                      isOptionValuable?.(it.Id.toString()) ?? false
                    }
                    isSelected={isSelected(it.Id)}
                    onClick={() => onSelect?.(it.Id)}
                    key={it.Id}
                  >
                    {it.Name ?? it.Id}
                  </SimpleOption>
                ))
              ) : (
                <NoData>No {unit}s found</NoData>
              )}
            </Select.ItemsList>
          </Select.Group>
        </Select.Box>
      </Select.DropDown>
    )
  }
)

export default CampaignsSelect
