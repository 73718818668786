import AdCopyAIWizardStore from "@components/EditAdCopyModal/AdCopyWizardModal/ad-copy-wizard.store"
import { createContext } from "@utils/context"

const context = createContext<AdCopyAIWizardStore>("AdCopyAIWizardContext")

export const AdCopyAIWizardContextProvider = context[0].Provider

export const useAdCopyAIWizardContext = context[1]

export default null
