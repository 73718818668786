import React, { useEffect } from "react"
import clsx from "clsx"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"

import Stack from "@components/ui/Stack/Stack"
import { RangeType } from "@framework/types/types"
import FormNumberField from "@framework/prototypes/FormNumberField"

import styles from "./Filter.module.scss"

const cpcValidator = yup.number()
const validationSchema = yup.object().shape({
  min: cpcValidator.clone(),
  max: cpcValidator.clone().test("max < min", (max, ctx) => {
    if (max != null && ctx.parent.min != null && ctx.parent.min > max) {
      return ctx.createError({ message: "MAX is less then MIN" })
    }
    return true
  }),
})

interface RangeFilterProps {
  initialValues?: RangeType
  onChange?: (value?: RangeType) => void
  className?: string
}

const formatterProps = {
  decimalScale: 2,
  thousandSeparator: true,
  prefix: "$",
}

export const RangeFilter: React.FC<RangeFilterProps> = ({
  initialValues = {},
  onChange,
  className,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: () => {},
  })

  useEffect(() => {
    if (!onChange || formik.isValidating) return
    if (formik.isValid) onChange(formik.values)
    else onChange()
  }, [formik.isValidating, formik.isValid])

  return (
    <FormikProvider value={formik}>
      <Stack direction="row" className={clsx(styles.formContainer, className)}>
        <FormNumberField label="Min" name="min" {...formatterProps} />

        <FormNumberField label="Max" name="max" {...formatterProps} />
      </Stack>
    </FormikProvider>
  )
}

export default RangeFilter
