import { action, observable } from "mobx"
import { delay } from "@utils/promise"
import { SECOND } from "@framework/constants/time"
import { numberWithCommas } from "@utils/numberUtils"

type StatusType = {
  stage: string
  label: string
}

export class KeywordsGenerationTask {
  @observable isCompleted: boolean = false

  @observable totalKeywordsGenerated: number

  constructor(options: { totalKeywordsGenerated: number }) {
    this.totalKeywordsGenerated = options.totalKeywordsGenerated
  }

  @observable startedAt = Date.now()

  @observable status: StatusType = {
    stage: "NOT_STARTED",
    label: `Keyword generation starting`,
  }

  @action skipTask = () => {
    this.isCompleted = true
  }

  @action runTask = async () => {
    this.isCompleted = false
    this.isCompleted = await this.handleTask()
  }

  handleTask = async () => {
    this.startedAt = Date.now()

    await delay(500)

    this.status = {
      stage: "GENERATION_STARTED",
      label: `Keyword generation started`,
    }

    await delay(2 * SECOND)

    for (let i = 0; i < 14; i += 1) {
      const fraction = ((i + 1) / 14) * this.totalKeywordsGenerated

      this.status = {
        stage: "KEYWORDS_GENERATING",
        label: `${numberWithCommas(fraction)} Keyword generated`,
      }
      // eslint-disable-next-line no-await-in-loop
      await delay((7 * SECOND) / 14)
    }

    await delay(500)

    return true
  }
}

export default KeywordsGenerationTask
