import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import * as yup from "yup"

import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import FormTextField from "@framework/prototypes/FormTextField"
import useQueryParams from "@framework/hooks/useQueryParamsNew"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import Stack from "@components/ui/Stack/Stack"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { useStore } from "@store/index"
import PublicLayout from "@root/layouts/public/PublicLayout"
import { passwordValidator } from "@components/utils/validators"

import styles from "./index.module.scss"

const validationSchema: yup.BaseSchema = yup.object({
  password: passwordValidator
    .clone()
    .trim()
    .required("Can not be empty")
    .default(""),
  passwordConfirm: yup
    .string()
    .trim()
    .required("Can not be empty")
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .default(""),
})

const searchParamsValidationSchema: yup.BaseSchema = yup.object({
  token: yup.string().trim().required("Can not be empty"),
})

type ChangePasswordForm = yup.InferType<typeof validationSchema>

type SearchParams = yup.InferType<typeof searchParamsValidationSchema>

const initialValues: ChangePasswordForm = validationSchema.getDefault()

const ResetPassword: React.FC = () => {
  const history = useHistory()
  const { userStore } = useStore()
  const alert = useAlert()

  const params = useQueryParams<SearchParams>(searchParamsValidationSchema)

  const handleLoginRedirect = () => history.push("/login")

  const handleSubmit = async (form: ChangePasswordForm) => {
    const error = await userStore.resetPassword(
      params?.token ?? "",
      validationSchema.cast(form)
    )
    if (error) {
      alert.error(
        <AlertMessage title="Failed to reset password" description={error} />
      )
      return
    }
    alert.success(
      <AlertMessage
        title="Alright!"
        description="Your password was successfully changed"
      />
    )
    handleLoginRedirect()
  }

  const formik = useFormik<ChangePasswordForm>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    if (!params?.token) history.push("/login")
  }, [params?.token])

  return (
    <PublicLayout
      after={
        <Button variant="ghost" color="primary" onClick={handleLoginRedirect}>
          Login
        </Button>
      }
    >
      <FormikProvider value={formik}>
        <form className={styles.root} onSubmit={formik.handleSubmit}>
          <Stack gutter="16" align="stretch">
            <Typography type="title" color="dark" bold>
              Restore Password
            </Typography>

            <Stack gutter="8" align="stretch">
              <FormTextField
                label="New Password"
                name="password"
                type="password"
              />

              <FormTextField
                label="Confirm New Password"
                name="passwordConfirm"
                type="password"
              />
            </Stack>

            <Button
              className={styles.control}
              variant="contained"
              color="primary"
              type="submit"
            >
              Send
            </Button>
          </Stack>
        </form>
      </FormikProvider>
    </PublicLayout>
  )
}

export default ResetPassword
