import { ID } from "./types"

export interface UserData {
  APIKey: string
  Clients: null | ID[]
  ContactPhone: string
  Email: string
  ID: ID
  Name: string
  Surname: string

  isAdmin: boolean
  isDirector: boolean

  level?: PermissionType

  // required widthPermissions param
  permissions?: PermissionData[]
}

export const permissionsType = ["agency", "client", "account"] as const

// higher index better
export const permissionLevelPriority = ["read", "write", "director"] as const

export type PermissionType = (typeof permissionsType)[number]

export type PermissionLevel = (typeof permissionLevelPriority)[number]

// @deprecated
export interface UserPermissionData {
  accessLevel: PermissionLevel
  id: ID
  objectId: number
  objectType: PermissionType
  user?: UserData
  userId: ID
}

export interface UserEntity {
  id?: ID
  clientIds: number[]
  contactPhone: string
  email: string
  name: string
  password: string
  surname: string
  isDirector: boolean
}

export interface SuccessPreSignupEntity {
  name?: string
  companyName: string
  email: string
  website?: string
  adAccountId: string
  adAccountName: string
  accessToken: string
  isLinkingSucceeded: true
}

export interface FailedPreSignupEntity {
  name?: string
  companyName: string
  email: string
  website?: string
  isLinkingSucceeded: false
}

export type PreSignupEntity = SuccessPreSignupEntity | FailedPreSignupEntity

export interface PermissionEntity {
  objectIds: number[]
  objectType: PermissionType
  accessLevel: PermissionLevel
}

export interface PermissionData {
  objectId: number[]
  objectType: PermissionType
  accessLevel: PermissionLevel
}
