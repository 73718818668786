import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import { FormikProvider, useFormik } from "formik"
import { useHistory, useLocation } from "react-router-dom"

import {
  emailValidator,
  websiteURLValidator,
} from "@components/utils/validators"
import PublicLayout from "@root/layouts/public/PublicLayout"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import FormTextField from "@framework/prototypes/FormTextField"
import { useStore } from "@store/index"
import useAccountLinkRedirect from "@framework/prototypes/LinkAccount/useAccountLinkRedirect"
import SelectCustomerModal from "@components/modals/SelectCustomerModal/SelectCustomerModal"
import { CustomerType } from "@store/account-linking/account-linking"
import useQueryParams from "@framework/hooks/useQueryParamsNew"

import styles from "./index.module.scss"

const SUCCESS_MESSAGE =
  "Thank you for linking your Google Account. Adoro Team will get back to you soon"
const FAIL_MESSAGE =
  "The Google Account linking process was not completed. Please, try again later or get in touch with the Adoro team"

export const validationSchema = yup.object({
  name: yup.string().default(""),
  email: emailValidator.clone().required("Can not be empty").default(""),
  companyName: yup.string().required("Can not be empty").default(""),
  website: websiteURLValidator.clone().default(""),
})

type PreRegistrationFormData = yup.InferType<typeof validationSchema>

const initialValues: PreRegistrationFormData = validationSchema.getDefault()

const PreRegistrationPage: React.FC = observer(() => {
  const history = useHistory()
  const location = useLocation()
  const params = useQueryParams<{ code?: string }>()

  const [isCustomersListShown, setCustomersListShown] = useState(false)
  const {
    authStore: { preSignUp, isPreSignupInProgress },
    accountLinkingStore: { linkPending, customerListLoading, loadCustomerList },
  } = useStore()

  const { redirect } = useAccountLinkRedirect()

  const handleSubmit = async (form: PreRegistrationFormData) => {
    redirect("pre-registration", JSON.stringify(form))
  }

  const handleLoadCustomers = async (
    code: string,
    form: PreRegistrationFormData
  ) => {
    const error = await loadCustomerList(code)
    if (error == null) setCustomersListShown(true)
    else {
      await preSignUp({
        ...form,
        isLinkingSucceeded: false,
      })
      history.replace("/pre-registration/result", {
        status: "failed",
        message: FAIL_MESSAGE,
      })
    }
  }

  const handlePreRegistration = async (selected: CustomerType) => {
    const error = await preSignUp({
      ...formik.values,
      adAccountId: selected.AccountID,
      adAccountName: selected.AccountName,
      accessToken: selected.Token,
      isLinkingSucceeded: true,
    })
    if (error == null) {
      setCustomersListShown(false)
      history.replace("/pre-registration/result", {
        status: "success",
        message: SUCCESS_MESSAGE,
      })
    } else {
      history.replace("/pre-registration/result", {
        status: "failed",
        message: FAIL_MESSAGE,
      })
    }
  }

  const handleLinkModalClose = () => {
    setCustomersListShown(false)
  }

  useEffect(() => {
    const state = location.state as any
    const code = params?.code
    if (state != null && state.form && typeof code === "string") {
      const casted = validationSchema.cast(state.form, { stripUnknown: true })
      if (validationSchema.isValidSync(casted)) {
        formik.resetForm({ values: casted })
        handleLoadCustomers(code, casted)
        history.replace(location.pathname, state)
      }
    }
  }, [params?.code])

  const formik = useFormik<PreRegistrationFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  const isLoading = linkPending || isPreSignupInProgress || customerListLoading

  return (
    <PublicLayout>
      <FormikProvider value={formik}>
        <form className={styles.root} onSubmit={formik.handleSubmit}>
          <Typography className={styles.title} type="h1" color="dark" bold>
            Pre-registration
          </Typography>

          <FormTextField label="Name" name="name" />

          <FormTextField label="Email" name="email" />

          <FormTextField label="Company Name" name="companyName" />

          <FormTextField label="Website" name="website" />

          <Button
            fullWidth
            className={styles.control}
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
          >
            Connect your Google Ads Account & Register
          </Button>
        </form>
      </FormikProvider>
      <SelectCustomerModal
        isOpen={isCustomersListShown}
        isLoading={linkPending}
        onSubmit={handlePreRegistration}
        onClose={handleLinkModalClose}
      />
    </PublicLayout>
  )
})

export default PreRegistrationPage
