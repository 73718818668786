import React from "react"
import { useField } from "formik"
import { observer } from "mobx-react-lite"

import Label from "@components/ui/Label/Label"
import TextAreaField, {
  TextAreaFieldProps,
} from "@components/ui/TextAreaField/TextAreaField"
import { useFormMetaContext } from "./FormMetaContext"

export interface FormFieldProps extends Omit<TextAreaFieldProps, "label"> {
  name: string
  label?: React.ReactNode
  afterBlur?: (
    e: React.FocusEvent<HTMLTextAreaElement>,
    field: ReturnType<typeof useField>
  ) => void
}

const FormTextAreaField: React.FC<FormFieldProps> = observer(
  ({ afterBlur, name, label, className, placeholder, ...rest }) => {
    const formMeta = useFormMetaContext()

    const fieldContext = useField(name)

    const [field, meta] = fieldContext

    const error = meta.touched ? meta.error : undefined

    const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
      field.onBlur(e)
      afterBlur?.(e, fieldContext)
    }

    return (
      <Label label={label} error={error} className={className}>
        <TextAreaField
          name={name}
          placeholder={Array.isArray(field.value) ? "<varies>" : placeholder}
          value={Array.isArray(field.value) ? undefined : field.value}
          withValidate={meta.touched && meta.value !== meta.initialValue}
          error={error}
          onChange={field.onChange}
          onBlur={handleBlur}
          {...rest}
        />
      </Label>
    )
  }
)

export default FormTextAreaField
