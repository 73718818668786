/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"

import { InfoWindow } from "@react-google-maps/api"
import Typography from "@components/ui/Typography/Typography"
import StarsBar from "@components/ui/StarsBar/StarsBar"
import { RecommendedKeyword } from "@framework/types/opportunities"
import { numberWithCommas } from "@utils/numberUtils"

import styles from "./MapTooltip.module.scss"

export interface KeywordLocationTooltipProps {
  data: RecommendedKeyword
  short?: boolean
  onCloseClick?: () => void
}

export const KeywordLocationTooltip: React.FC<KeywordLocationTooltipProps> =
  React.memo(({ data, short = false, onCloseClick }) => (
    <InfoWindow position={data.location} onCloseClick={onCloseClick}>
      <div className={styles.root}>
        <Typography type="h3" bold>
          {data.displayName}
        </Typography>
        <div className={styles.stars}>
          <Typography type="h3" color="primary" className={styles.capitalize}>
            {data.category.displayName || data.category.name}
          </Typography>
          {data.starRating && !short && !Number.isNaN(data.starRating) && (
            <div>
              {data.starRating}
              &nbsp;
              <StarsBar value={data.starRating} max={5} />
              &nbsp;
              <span>({numberWithCommas(data.ratingTotal)})</span>
            </div>
          )}
        </div>
        <div className={styles.row}>
          <Typography type="h5" upperCase color="gray">
            Search volume:
          </Typography>
          &nbsp;
          <Typography type="h3">{data.avgSearchVolume}</Typography>
        </div>
        <div className={styles.row}>
          <Typography type="h5" upperCase color="gray">
            Distance to:
          </Typography>
          &nbsp;
          <Typography type="h3">
            {numberWithCommas(data.distance, 2)} miles
          </Typography>
        </div>
        {data.address && !short ? (
          <div className={styles.row}>
            <Typography type="h5" upperCase color="gray">
              Address:
            </Typography>
            &nbsp;
            <Typography type="h3">{data.address}</Typography>
          </div>
        ) : null}
      </div>
    </InfoWindow>
  ))

export default KeywordLocationTooltip
