import { observable, action, reaction } from "mobx"
import agenciesService from "@services/agencies.service"
import RootStore from "../RootStore"

export class AgencyStore {
  @observable agency: any = null

  @observable agencyList: any[] | null = null

  @observable checkedAgency: number | null = null

  @observable isAgenciesLoading: boolean = false

  @observable isAgencyListLoading: boolean = false

  @observable agencyListError: string | null = null

  @observable rootStore: RootStore

  constructor(root: RootStore) {
    this.rootStore = root

    reaction(
      () => this.rootStore.clientStore.client,
      async (client) => {
        if (client && client.agencyId !== this.agency?.id)
          await this.loadAgency(client.agencyId)
      }
    )
  }

  @action loadAgency = async (agencyId: number) => {
    try {
      this.isAgenciesLoading = true
      const response = await agenciesService.fetchAgencyList() // FIXME
      this.agency = response.data.data.find((item: any) => item.id === agencyId)
    } catch (error) {
      console.error(error)
    } finally {
      this.isAgenciesLoading = false
    }
  }

  @action loadAgencyList = async () => {
    try {
      this.isAgencyListLoading = true
      this.agencyListError = null
      this.agencyList = null
      const response = await agenciesService.fetchAgencyList()
      this.agencyList = response.data.data
    } catch (error) {
      this.agencyListError = "Loading agencies list failed"
    } finally {
      this.isAgencyListLoading = false
    }
  }

  @action setCheckedAgencyId(id: number) {
    this.checkedAgency = id
  }
}

export default AgencyStore
