/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect } from "react"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom"
import { observer, useLocalStore } from "mobx-react-lite"

import Container from "@root/layouts/Container/Container"
import { useStore } from "@store/index"
import config from "@root/config"
import {
  ControllerContext,
  OpportunitiesStoreProvider,
} from "@store/opportunities/provider"
import InsightsPage from "@pages/Opportunities/Insights"
import OpportunitiesStore from "@store/opportunities/opportunities"
import OpportunitiesController from "@store/opportunities/opportunities.controller"
import KeywordGenerationFooter from "@root/layouts/PrivateLayout/components/KeywordGenerationFooter/KeywordGenerationFooter"
import PMaxRoot from "@pages/PMax/PMaxRoot"
import Opportunities from "../Opportunities/Opportunities"
import Dashboard from "./AccountDashboard/Dashboard"
import CreateCampaign from "../CreateCampaign/CreateCampaign"
import ManageProfile from "./ManageProfile/ManageProfile"
import ManageCampaign from "../ManageCampaign/ManageCampaign"
import PaidSearchLinkedAccounts from "./ManageProfile/components/LinkedAccounts/LinkedAccounts"
import Rules from "../Rules/Rules"
import AdCopyManagement from "./ManageProfile/components/AdCopyManagement/AdCopy"
import GoogleAdsMainLayout from "./GoogleAdsLayout"

const PaidSearchRoot: React.FC = observer(() => {
  const history = useHistory()
  const { accountStore, createdCampaign } = useStore()
  const { loadAccount, setAccountId, account, accountId } = accountStore

  const { id } = useParams<any>()

  useEffect(() => {
    if (!id || Number.isNaN(id)) history.push("./")
  }, [id, history])

  useEffect(() => {
    setAccountId(Number(id) || null)
    return () => {
      setAccountId(null)
    }
  }, [setAccountId, id])

  useEffect(() => {
    if (accountId) {
      loadAccount(accountId)
    }
  }, [accountId, loadAccount])

  const opportunitiesStore = useLocalStore(
    () =>
      new OpportunitiesStore({
        defaultSortBy: { value: "avgSearchVolume", direction: false },
      })
  )

  const opportunitiesController: ControllerContext = useLocalStore(() => ({
    opportunities: new OpportunitiesController({
      opportunitiesStore,
      createdCampaign,
    }),
  }))

  useEffect(() => {
    const handler =
      accountId != null
        ? () => {
            history.push(`/google-ads/${accountId}/create-campaign`)
          }
        : undefined

    opportunitiesController.opportunities.setPublishCampaignHandler(handler)
  }, [accountId, history, opportunitiesController.opportunities])

  return (
    <OpportunitiesStoreProvider
      store={opportunitiesStore}
      controller={opportunitiesController}
    >
      <Switch>
        {!config.HIDE_PMAX && (
          <Route path="/google-ads/:id/campaign">
            <PMaxRoot basePath="/google-ads/:id/campaign" />
          </Route>
        )}

        <Route
          path="/google-ads/:id/manage-campaign"
          component={ManageCampaign}
        />

        <Route path="/google-ads/:id">
          <PaidSearchMainRouts />
        </Route>
      </Switch>
      {!config.ENABLE_OPPORTUNITIES_UPDATE && (
        <KeywordGenerationFooter key={account?.id} />
      )}
    </OpportunitiesStoreProvider>
  )
})

const PaidSearchMainRouts: React.FC = observer(() => (
  <GoogleAdsMainLayout>
    <Container>
      <Switch>
        <Route path="/google-ads/:id/dashboard" component={Dashboard} />

        {!!config.ENABLE_OPPORTUNITIES_UPDATE && (
          <Route
            path="/google-ads/:id/opportunities/insights/:kid"
            component={InsightsPage}
          />
        )}

        {!!config.ENABLE_OPPORTUNITIES_UPDATE && (
          <Route
            path="/google-ads/:id/opportunities/insights"
            component={InsightsPage}
          />
        )}

        <Route path="/google-ads/:id/opportunities" component={Opportunities} />

        <Route
          path="/google-ads/:id/manage-ad-copy"
          component={AdCopyManagement}
        />

        <Route
          path="/google-ads/:id/create-campaign"
          component={CreateCampaign}
        />
        <Route path="/google-ads/:id/checkbook">
          <Redirect to="./manage-profile?alter=checkbook" />
        </Route>
        <Route
          path="/google-ads/:id/manage-profile"
          component={ManageProfile}
        />
        <Route
          path="/google-ads/link/redirect"
          component={PaidSearchLinkedAccounts}
        />

        <Route path="/google-ads/:id/rules" component={Rules} />
        <Route path="/google-ads/:id/**">
          <Redirect to="./dashboard" />
        </Route>
        <Route path="/google-ads/:id" exact>
          <Redirect to="/google-ads" />
        </Route>
      </Switch>
    </Container>
  </GoogleAdsMainLayout>
))

export default PaidSearchRoot
