import React, { useMemo, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import _sortBy from "lodash/sortBy"

import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import { TrendValue } from "@components/ui/DataChangeWidget/DataChangeWidget"
import Typography from "@components/ui/Typography/Typography"
import { Header } from "../Table/Header"
import { TableMapper } from "../Table/types"
import { TableRow, WrappableRowContainer } from "../Table/Table"
import { accountTableMapper as mapper } from "./constants"

import styles from "./CampaignSegmentationTable.module.scss"

const parseNumber = (value: any) =>
  value && !Number.isNaN(Number(value))
    ? Math.ceil(Number(value) * 100)
    : value ?? "-"

const sortAdGroup = (list: any[], name: string, dir: boolean = false) => {
  const sorted = _sortBy(list, (item: any) => item[name]?.Value ?? item[name])
  if (dir) return sorted.reverse()
  return sorted
}

type CampaignSegmentationProps = {
  className?: string
  withDetails?: boolean
}

const CampaignSegmentation: React.FC<CampaignSegmentationProps> = observer(
  ({ className, withDetails = true }) => {
    const { accountDashboardStore } = useStore()
    const { performanceSegmented: data, loadingSegmentedPerformance: loading } =
      accountDashboardStore

    const [sortBy, setSort] = useState<any>({ colName: "", dir: false })

    const handleSort = (name: string) => {
      setSort((prev: any) => {
        if (prev.colName === name) {
          if (!prev.dir) return { colName: "", dir: true }
          return { ...prev, dir: !prev.dir }
        }
        return { colName: name, dir: true }
      })
    }

    const renderColumn = (data: any, { options = {} }: TableMapper) => {
      const {
        valueFormatter,
        getChangeDirection = positiveTransformDirection,
      } = options

      if (typeof data === "string") return data

      const label = data?.Value
      const subLabel = parseNumber(data?.Change)

      return (
        <div className={styles.col}>
          <Typography>{valueFormatter?.(label) ?? label}</Typography>
          {subLabel != null ? (
            <div className={styles.subLabel}>
              {typeof subLabel === "string" ? (
                <Typography type="subhead1" color="gray" upperCase>
                  {subLabel}
                </Typography>
              ) : (
                <TrendValue
                  value={subLabel}
                  metricDir={getChangeDirection(subLabel)}
                />
              )}
            </div>
          ) : null}
        </div>
      )
    }

    const sortedData = useMemo(() => {
      if (!sortBy.colName) return data
      return data.map((item) => ({
        ...item,
        AdGroups: sortAdGroup(item.AdGroups, sortBy.colName, sortBy.dir),
      }))
    }, [sortBy, data])

    return (
      <div className={clsx(styles.root, className)}>
        <Header
          withDetails={withDetails}
          mapper={mapper}
          onSort={handleSort}
          sortBy={sortBy}
        />

        <Loader isLoading={loading} />
        <div className={styles.bodyWrapper}>
          {sortedData.length
            ? sortedData.map((report) => (
                <WrappableRowContainer
                  body={
                    <TableRow
                      dataset={report}
                      mapper={mapper}
                      renderColumn={renderColumn}
                    />
                  }
                  withDetails={withDetails}
                >
                  {report.AdGroups?.map((rowItem) => (
                    <TableRow
                      dataset={rowItem}
                      mapper={mapper}
                      renderColumn={renderColumn}
                    />
                  ))}
                </WrappableRowContainer>
              ))
            : !loading && <NoData />}
        </div>
      </div>
    )
  }
)

export default CampaignSegmentation
