import React from "react"
import { observer } from "mobx-react-lite"

import RemoveMemberModal from "@components/modals/RemoveMemberModal/RemoveMemberModal"
import Icon from "@components/ui/Icon/Icon"
import Stack from "@components/ui/Stack/Stack"
import useToggle from "@framework/hooks/useToggle"
import { UserData } from "@framework/types/user"
import { useStore } from "@store/index"
import { ColumnProps } from "./UserTable"

export const ActionsColumn: React.FC<ColumnProps<UserData>> = observer(
  ({ data }) => {
    const {
      userStore: { userId },
      settings: { managementStore },
    } = useStore()

    const { isOpened, handleToggle } = useToggle()
    const isCurrentUser = data?.ID && userId && data?.ID === userId

    const canEdit = !isCurrentUser && !data?.isAdmin

    return (
      <Stack direction="row" justify="flex-end">
        {canEdit && (
          <>
            <Icon name="pen" onClick={() => managementStore.editUser(data)} />
            <Icon name="trash" onClick={handleToggle} />
          </>
        )}

        {isOpened && data?.ID && (
          <RemoveMemberModal
            userId={data?.ID}
            onSubmit={handleToggle}
            onClose={handleToggle}
          />
        )}
      </Stack>
    )
  }
)

export default ActionsColumn
