/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { ListRowProps } from "react-virtualized"
import { useAlert } from "react-alert"

import { WaterfallTable } from "@components/ui/WaterfallTable/WaterfallTable"
import { ID, SortByType } from "@framework/types/types"
import { useStore } from "@store/index"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import NoData from "@components/ui/NoData/NoData"
import PlaceholderLoader from "@components/ui/Loader/PlaceholderLoader"
import Loader from "@components/ui/Loader/Loader"
import { useSearchContext } from "@framework/prototypes/SearchContext"
import { useSelectedCampaignsContext } from "@framework/prototypes/SelectedCampaignsContext"
import CampaignRow from "./CampaignRow"
import mapper from "./campaign-table-mapper"
import VirtualizedTable from "./VirtualizedTable"
import useCampaignsReports from "./useCampaignsReports"

import styles from "./index.module.scss"

type CampaignTableProps = {
  className?: string
  campaignTypes?: string[]
}

const CampaignTable: React.FC<CampaignTableProps> = observer(
  ({ campaignTypes, className }) => {
    const alert = useAlert()
    const { manageCampaignStore, accountStore } = useStore()
    const { accountId } = accountStore
    const { dateFilter, updateLoading, updateError, loadCampaignReportList } =
      manageCampaignStore

    const searchContext = useSearchContext()
    const campaignContext = useSelectedCampaignsContext()

    const init = async (accountId: number) => {
      const error = await loadCampaignReportList(accountId, dateFilter)
      if (error)
        alert.error(
          <AlertMessage title="Failed to load report" description={error} />
        )
    }

    React.useEffect(() => {
      if (!accountId || updateLoading || updateError) return
      init(accountId)
    }, [accountId, dateFilter, updateLoading])

    const [sortBy, setSortBy] = React.useState<SortByType>({
      value: "",
      direction: true,
    })

    const handleSort = (name: string) => {
      setSortBy((oldSortBy) => ({
        value: name,
        direction: !oldSortBy.direction,
      }))
    }

    const { sortedCampaigns, inProgress } = useCampaignsReports({
      campaignIds: campaignContext.selectedCampaigns,
      campaignTypes,
      searchQuery: searchContext.searchQuery,
      sortBy,
    })

    const campaigns = sortedCampaigns

    const getIndexById = (id: ID) =>
      campaigns.findIndex((it) => it.Campaign.Id.toString() === id.toString())

    const renderRow = ({ index }: ListRowProps) => {
      const data = campaigns[index]
      return (
        <CampaignRow
          mapper={mapper}
          data={data}
          sortBy={sortBy}
          className={styles.campaignRow}
          key={data.Campaign.Id}
        />
      )
    }

    const isLoading = inProgress

    if (campaigns.length)
      return (
        <div className={clsx(styles.root, className)}>
          <Loader isLoading={isLoading} />

          <WaterfallTable
            labelWidth={420}
            labelSortable
            withTip
            mapper={mapper}
            label="Campaign Name"
            onSort={handleSort}
            sortBy={sortBy}
            rootClassName={styles.flexFullHeight}
            bodyClassName={styles.flexFullHeight}
          >
            <VirtualizedTable
              list={campaigns}
              renderRow={renderRow}
              getIndexById={getIndexById}
            />
          </WaterfallTable>
        </div>
      )

    if (isLoading) return <PlaceholderLoader />

    if (!campaignContext.selectedCampaigns.length)
      return <NoData>No campaigns selected</NoData>

    return <NoData>No campaigns found</NoData>
  }
)

export default CampaignTable
