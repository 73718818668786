import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import { useAlert } from "react-alert"

import { useStore } from "@store/index"
import Modal, { ModalProps } from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import { ID } from "@framework/types/types"
import ModalTemplate from "@components/ui/Modal/ModalTemplate"
import FormTextAreaField from "@framework/prototypes/FormTextAreaField"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import useCampaignOptions from "@store/manageProfile/useCampaignOptions"
import useAdGroupOptions from "@store/manageProfile/useAdGroupOptions"
import OnHoverTooltip from "@components/ui/Tooltip/TooltipContainer/TooltipContainer"
import Icon from "@components/ui/Icon/Icon"
import ComicBubble from "@components/ui/Tooltip/ComicBubble/ComicBubble"
import FormTextField from "@framework/prototypes/FormTextField"
import { websiteURLValidator } from "@utils/validators"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"

import { normalizeUrl } from "@utils/stringUtils"
import styles from "./AddKeywordsModal.module.sass"

const validKeywordsSeparatorRegex = /[\n]/

const restrictedKeywordsSymbolsRegex = /^(([^!@*%])*)$/

const max10WordsRegex = /^(([^\s]*)([\s]{0,})){0,10}$/

const validKeywordsFormatHint = (
  <>
    Match type can be set as follows:
    <br />
    <strong>keyword</strong> - Broad match,
    <br />
    <strong>[keyword]</strong> - Exact match,
    <br />
    <strong>“keyword”</strong> - Phrase match.
  </>
)

const normalizeKeywords = (keywordsStr: string) =>
  keywordsStr
    .split(validKeywordsSeparatorRegex)
    .map((it) => it.trim().replace(/(?:([\s]{1,}))/g, " "))
    .filter((it) => !!it)
    .join("\n")

const validationSchema = yup.object({
  campaign: yup.string().required(),
  adGroup: yup.string().required(),
  keywords: yup
    .string()
    .label("Keywords")
    .matches(restrictedKeywordsSymbolsRegex, {
      message: "Keywords cannot contain next symbols: ! @ * %",
    })
    .test(
      "max keyword length",
      "Keywords must be at max 80 characters long",
      (v: string | undefined) =>
        (v?.split(validKeywordsSeparatorRegex) ?? []).find(
          (it) => it.trim().length > 80
        ) == null
    )
    .test(
      "max keyword words",
      "Keywords must contain at max 10 words",
      (v: string | undefined) =>
        (v?.split(validKeywordsSeparatorRegex) ?? [])
          .map((it) => it.trim())
          .find((it) => it !== "" && !it.match(max10WordsRegex)) == null
    )
    .trim()
    .required()
    .default(""),
  url: websiteURLValidator.clone().trim().optional(),
})

export type FormData = yup.InferType<typeof validationSchema>

export interface AddKeywordsModalProps extends ModalProps {
  campaignId?: ID
  adGroupId?: ID
}

export const AddKeywordsModal: React.FC<AddKeywordsModalProps> = observer(
  ({ campaignId, adGroupId, ...rest }) => {
    const { manageCampaignStore, accountStore } = useStore()
    const alert = useAlert()

    const initValue = useMemo(
      () =>
        validationSchema.cast(
          {
            campaign: campaignId?.toString(),
            adGroup: adGroupId?.toString(),
          },
          { stripUnknown: true }
        ) as FormData,
      [campaignId, adGroupId]
    )

    const handleSubmit = async (form: FormData) => {
      if (!accountStore.accountId) return

      const keywords = form.keywords
        .split(validKeywordsSeparatorRegex)
        .map((it) => it.trim())
        .filter((it) => !!it)

      const errorMessage = await manageCampaignStore.addKeywords(
        accountStore.accountId,
        form.adGroup,
        keywords,
        normalizeUrl(form.url)
      )

      if (errorMessage) {
        alert.error(
          <AlertMessage
            title="Failed to add keywords"
            description={errorMessage}
          />
        )
        return
      }

      alert.success(
        <AlertMessage
          title="Success!"
          description="New Keywords was successfully added"
        />
      )

      rest.onClose?.()
    }

    const formik = useFormik<FormData>({
      initialValues: initValue,
      validationSchema,
      onSubmit: handleSubmit,
    })

    const campaignOptionProps = useCampaignOptions()
    const adGroupOptionProps = useAdGroupOptions(formik.values.campaign)

    const isLoading = manageCampaignStore.updateLoading

    return (
      <Modal {...rest} hideHeader>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Stack
              className={styles.root}
              gutter="0"
              direction="column"
              align="stretch"
            >
              <ModalTemplate.Header title="Add Keywords" />

              <ModalTemplate.Body>
                <Stack direction="column" gutter="16" align="stretch">
                  <FormSimpleSelectField
                    name="campaign"
                    placement="bottom-end"
                    label="Campaign"
                    placeholder="Select campaign..."
                    {...campaignOptionProps}
                  />

                  <FormSimpleSelectField
                    name="adGroup"
                    placement="bottom-end"
                    label="Ad Group"
                    placeholder="Select ad group..."
                    disabled={!formik.values.campaign}
                    {...adGroupOptionProps}
                  />

                  <FormTextAreaField
                    label={
                      <Typography color="inherit" type="inherit" inline>
                        <OnHoverTooltip
                          placement="right"
                          toolTip={(props) => (
                            <ComicBubble justify="center" {...props}>
                              {validKeywordsFormatHint}
                            </ComicBubble>
                          )}
                        >
                          Keywords <Icon name="information-outline" />
                        </OnHoverTooltip>
                      </Typography>
                    }
                    rows={7}
                    name="keywords"
                    placeholder="Enter or paste keywords. You can separate each keyword by commas or enter one per line"
                    onBlur={() => {
                      formik.setFieldValue(
                        "keywords",
                        normalizeKeywords(formik.values.keywords)
                      )
                    }}
                  />

                  <FormTextField
                    label="URL (optional)"
                    name="url"
                    placeholder="Define URL for the new keywords"
                  />
                </Stack>
              </ModalTemplate.Body>

              <ModalTemplate.Footer>
                <Button
                  size="big"
                  color="secondary"
                  disabled={isLoading}
                  onClick={rest.onClose}
                >
                  Cancel
                </Button>
                <Button size="big" type="submit" disabled={isLoading}>
                  Apply
                </Button>
              </ModalTemplate.Footer>
            </Stack>
          </form>
        </FormikProvider>
      </Modal>
    )
  }
)

export default AddKeywordsModal
