import React from "react"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import TextField from "@components/ui/TextField/TextField"
import { monthListMock } from "@framework/constants/common"

import styles from "./SelectMonths.module.scss"

export type SelectMonthsProps = {
  selectMonth: (name: string) => void
  spendAmount: number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedMonths: string[]
  usedMonths: string[]
}

const months = monthListMock

export type MonthsType = typeof months

const Calculator: React.FC<SelectMonthsProps> = ({
  selectMonth,
  spendAmount,
  onChange,
  selectedMonths,
  usedMonths,
}) => (
  <Box className={styles.root}>
    <Typography type="h2" color="dark">
      Select Months to Allocate Spend
    </Typography>
    <Typography type="h2" color="dark">
      Additional Spend Amount
    </Typography>

    <div className={styles.monthsContainer}>
      {months.map((name) => (
        <Button
          key={name}
          onClick={() => selectMonth(name)}
          variant={
            selectedMonths.includes(name) || usedMonths.includes(name)
              ? "contained"
              : "outlined"
          }
          disabled={usedMonths.includes(name)}
        >
          {name}
        </Button>
      ))}
    </div>

    <div className={styles.form}>
      <TextField value={spendAmount.toString()} onChange={onChange} />
      <Typography type="subhead1" color="gray">
        Monthly
      </Typography>
    </div>
  </Box>
)

export default Calculator
