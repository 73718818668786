import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import { TopicEntity } from "@framework/types/opportunities"
import { mbNoData } from "@services/utils"
import HeaderColumn from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/AdCopyTable/HeaderColumn"
import useSortedList from "@framework/hooks/useSortedList"

interface RelatedTopicsTableProps {
  items: TopicEntity[]
}

export const RelatedTopicsTable: React.FC<RelatedTopicsTableProps> = observer(
  ({ items }) => {
    const ordered = useSortedList({ items })
    return (
      <ScrollContainer>
        <Typography type="body1">
          <Table hideBorder hideHorizontalOffset fixedLayout spacing="small">
            <Row sticky>
              {tableMapper.map((it) => (
                <Column variant="header" width={it.width} key={it.name}>
                  <Typography type="caption3" color="black60Color">
                    <HeaderColumn
                      name={it.name}
                      value={ordered.orderBy}
                      onSort={ordered.sort}
                    >
                      {it.label}
                    </HeaderColumn>
                  </Typography>
                </Column>
              ))}
            </Row>

            {ordered.items.map((it) => (
              <Row key={it.id}>
                <Column>{mbNoData(it.name)}</Column>
                <Column align="center">
                  <Typography type="inherit" weight="semibold">
                    {mbNoData(it.popularity)}
                  </Typography>
                </Column>
              </Row>
            ))}
          </Table>
        </Typography>
      </ScrollContainer>
    )
  }
)

export default RelatedTopicsTable

const tableMapper = [
  {
    name: "name",
    label: "Topic",
  },
  {
    name: "popularity",
    label: "Popularity",
    width: "90px",
  },
]
