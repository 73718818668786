/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from "react"
import Calendar from "react-calendar"
import clsx from "clsx"
import { format } from "date-fns"
import ReactInputMask from "react-input-mask"
import moment from "moment"

import "./Calendar.scss"
import Icon from "@components/ui/Icon/Icon"
import {
  DATE_MASK,
  DIVIDER,
  parseDateStr,
  validateDates,
  datesToString,
} from "./utils"
import Select from "../DropDown"
import BaseField from "../TextField/BaseField"
import Typography from "../Typography/Typography"
import { PopperPlacement } from "../DropDown/usePopper"

import styles from "./DatePicker.module.scss"

interface DatePickerProps {
  disabled?: boolean
  multiSelect?: boolean
  onChange?: (date: Date | Date[]) => void
  onBlur?: (e: any) => void
  placement?: PopperPlacement
  defValue?: Date | Date[]
  minDate?: Date
  maxDate?: Date
  className?: string
  name?: string
}

const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  onBlur,
  placement,
  multiSelect = false,
  defValue,
  minDate,
  maxDate,
  name,
  disabled = false,
  className,
}) => {
  const [isValid, setValid] = useState(true)
  const [date, setDate] = useState<Date | Date[] | undefined>()
  const [value, setValue] = useState<string>("")

  useEffect(() => {
    if (defValue) handlePick(defValue)
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)

    const dates = parseDateStr(value || " ", multiSelect)
    if (validateDates(dates)) {
      const sorted =
        Array.isArray(dates) && dates.length > 1
          ? dates.sort((d1, d2) => moment(d1).unix() - moment(d2).unix())
          : dates

      const dateStr = datesToString(sorted)
      setValue(dateStr)

      setDate(sorted)
      onChange?.(sorted)
    } else onChange?.([])
  }

  const mask = useMemo(
    () => (multiSelect ? `${DATE_MASK}${DIVIDER}${DATE_MASK}` : `${DATE_MASK}`),
    [multiSelect]
  )

  const handleBlur = (e: any) => {
    if (
      multiSelect ? value !== "__/__/____ - __/__/____" : value !== "__/__/____"
    ) {
      const isDateValid = validateDates(parseDateStr(value, multiSelect))
      setValid(isDateValid)
    }
    onBlur?.(e)
  }

  const handlePick = (value: Date | Date[]) => {
    const dateStr = datesToString(value)
    setValue(dateStr)

    setDate(value)
    onChange?.(value)

    setValid(true)
  }

  return (
    <Select.DropDown
      keepOpened
      placement={placement}
      disabled={disabled}
      target={(isActive) => (
        <BaseField
          error={!isValid}
          active={isActive}
          className={className}
          disabled={disabled}
          after={
            <Typography
              type="inherit"
              color={isActive ? "primary100Color" : "inherit"}
            >
              <Icon name="calendar" />
            </Typography>
          }
        >
          <ReactInputMask
            type="text"
            name={name}
            mask={mask}
            value={value}
            onChange={handleInputChange}
            onFocus={() => setValid(true)}
            onBlur={handleBlur}
          />
        </BaseField>
      )}
    >
      <Select.Box>
        <Calendar
          minDate={minDate}
          maxDate={maxDate}
          selectRange={multiSelect}
          onChange={handlePick}
          defaultValue={date as any}
          className={clsx("react-calendar", styles.calendar)}
          formatMonthYear={(_, date) => format(date, "MMM yyyy")}
          formatMonth={(_, date) => format(date, "MMM")}
          formatShortWeekday={(_, date) => format(date, "EEEEE")}
        />
      </Select.Box>
    </Select.DropDown>
  )
}

export default DatePicker
