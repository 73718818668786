import React from "react"
import clsx from "clsx"

import styles from "./DropDownContainer.module.scss"

export const ItemsList: React.FC<{ className?: string }> = ({
  className,
  children,
}) => <div className={clsx(styles.list, className)}>{children}</div>

export default ItemsList
