import { observable, computed } from "mobx"

import OrderController from "@store/common/TableHeaderController"
import RootStore from "@store/RootStore"
import EditAdCopyStore from "./edit-adCopy.store"
import AdCopyListStore from "./adCopy-list.store"
import CampaignsListStore from "./campaign-list.store"
import ActiveAdCopiesStore from "./active-adCopy-list.store"

export class AdCopyStore {
  root: RootStore

  @observable editor: EditAdCopyStore

  @observable copiesCollection: AdCopyListStore

  @observable campaignsCollection: CampaignsListStore

  @observable activeAdCopies: ActiveAdCopiesStore

  @observable header: OrderController

  @observable isPublishing: boolean = false

  constructor(root: RootStore) {
    this.root = root

    this.editor = new EditAdCopyStore(root)
    this.copiesCollection = new AdCopyListStore(root)
    this.campaignsCollection = new CampaignsListStore(root)
    this.activeAdCopies = new ActiveAdCopiesStore(root)
    this.header = new OrderController()
  }

  @computed get isLoading() {
    return (
      this.campaignsCollection.isLoading ||
      this.copiesCollection.isLoading ||
      this.isPublishing
    )
  }

  @computed get isEditingAvailable() {
    return (
      !this.isLoading &&
      this.copiesCollection.isSync &&
      this.copiesCollection.isGoogleSync
    )
  }

  @computed get getCampaignByID() {
    const { campaignsCollection } = this
    return (campaignId: string) =>
      campaignsCollection.campaignsMap.get(campaignId)
  }

  @computed get getAdGroupByID() {
    const { campaignsCollection } = this
    return (adGroupId: string) => campaignsCollection.adGroupsMap.get(adGroupId)
  }

  @computed get getAdCopyByLocalID() {
    const { copiesCollection } = this
    return (localAdCopyId: string) =>
      copiesCollection.adCopyMap.get(localAdCopyId)
  }

  findAdCopyByGoogleID = (googleAdCopyId: string) =>
    [...this.copiesCollection.adCopyMap.values()].find(
      (it) => it.adCopyID === googleAdCopyId
    )
}

export default AdCopyStore
