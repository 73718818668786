import React from "react"

import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Templates from "@components/ui/Templates"

import styles from "./ConfirmModal.module.sass"

export type InterruptionConfirmModalProps = {
  isOpen: boolean
  title: React.ReactNode
  message: React.ReactNode
  applyText?: string
  skipText?: string
  cancelText?: string
  onApply?: React.MouseEventHandler
  onSkip?: React.MouseEventHandler
  onCancel?: React.MouseEventHandler
  onClose: () => void
}

export const InterruptionConfirmModal: React.FC<
  InterruptionConfirmModalProps
> = ({
  isOpen,
  title,
  message,
  applyText = "Apply",
  skipText = "Skip",
  cancelText = "Cancel",
  onClose,
  onApply = onClose,
  onSkip = onClose,
  onCancel = onClose,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} hideHeader>
    <Stack gutter="24" align="stretch" className={styles.root}>
      <Stack gutter="16">
        <Typography type="h2">{title}</Typography>
        <Typography type="body1">{message}</Typography>
      </Stack>
      <Templates.Header
        offset="none"
        left={
          <Button color="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        }
        right={
          <>
            <Button color="secondary" onClick={onSkip}>
              {skipText}
            </Button>
            <Button onClick={onApply}>{applyText}</Button>
          </>
        }
      />
    </Stack>
  </Modal>
)

export default React.memo(InterruptionConfirmModal)
