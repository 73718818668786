import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Radio from "@components/ui/CheckBox/Radio/Radio"
import Label from "@components/ui/CheckBox/Label/Label"
import ListingGroupsTable from "@pages/PMax/components/EditAssetGroupPage/ListingGroups/ListingGroupsTable"
import useURLSearchParams from "@framework/hooks/useURLSearchParams"
import { useStore } from "@root/store"
import { AddAssetListingGroupFiltersDataType } from "@services/account-campaign.service"
import { useAlert } from "react-alert"
import styles from "./ListingGroups.module.scss"

interface ListingGroupsSidebarProps {
  isOpen: boolean
  onClose: () => void
}
type RadioBtnValueType = "all" | "selection"

const ListingGroupsSidebar: FC<ListingGroupsSidebarProps> = observer(
  ({ isOpen, onClose }) => {
    const params = useURLSearchParams()
    const assetGroupId: number = Number(params.get("assetGroupId"))
    const { pMaxAssetGroupStore, accountStore } = useStore()
    const alert = useAlert()
    const {
      getAssetListingGroups,
      listingGroups,
      addAssetListingGroupFilter,
      removeAssetListingGroupFilters,
      setAssetListingGroupFilter,
      createAssetListingGroups,
      removeAssetListingGroup,
    } = pMaxAssetGroupStore
    const accountId = accountStore.getAccountId()
    const gmcMerchantId = accountStore.account?.gmcMerchantId

    const [selectionType, setSelectionType] = useState<RadioBtnValueType>("all")
    const onChange = (e: RadioBtnValueType) => {
      setSelectionType(e)
    }

    useEffect(() => {
      getAssetListingGroups(accountId, assetGroupId)
    }, [])

    useEffect(() => {
      if (!listingGroups && selectionType === "selection") {
        createAssetListingGroups(accountId, assetGroupId).then(() =>
          getAssetListingGroups(accountId, assetGroupId)
        )
      }
    }, [selectionType])

    useEffect(() => {
      if (listingGroups?.Subdivisions || listingGroups) {
        setSelectionType("selection")
      }
    }, [listingGroups])

    const handleSaveFilters = async (
      deleteOptions: number[] | null,
      addOptions: AddAssetListingGroupFiltersDataType | null,
      selectedResourceId: number
    ) => {
      if (deleteOptions) {
        await removeAssetListingGroupFilters(
          accountId,
          assetGroupId,
          selectedResourceId,
          {
            filterIds: deleteOptions,
          }
        ).then((res) => {})
      }

      if (addOptions) {
        await addAssetListingGroupFilter(
          accountId,
          assetGroupId,
          selectedResourceId,
          addOptions
        ).then((res) => {})
      }
      await getAssetListingGroups(accountId, assetGroupId)
    }

    const handleSetNewOptionFilters = async (
      addOptions: AddAssetListingGroupFiltersDataType,
      selectedResourceId: number
    ) => {
      if (addOptions) {
        await setAssetListingGroupFilter(
          accountId,
          assetGroupId,
          selectedResourceId,
          addOptions
        ).then((res) => getAssetListingGroups(accountId, assetGroupId))
      }
    }

    const handleRemoveAllOptions = async () => {
      if (listingGroups && selectionType === "all") {
        await removeAssetListingGroup(accountId, assetGroupId)
        await getAssetListingGroups(accountId, assetGroupId)
      }
      alert.success("All updates saved successfully")
      onClose()
    }

    return (
      <SidebarContainer isOpen={isOpen} onClose={onClose}>
        <Stack gutter="32" grid-gap="0" className={styles.sidebar}>
          <div>
            <Typography type="h2">Listing groups</Typography>
            <Typography type="body1" className={styles.listingSubtitle}>
              Merchant center account: {gmcMerchantId}
            </Typography>
            <Typography
              type="body1"
              color="gray"
              className={styles.listingSubtitle}
            >
              Choose with products to show in your ads. Some of your ads will
              use images, headlines and descriptions from Merchant Center.
            </Typography>
          </div>

          <div>
            <Label text="Use all products">
              <Radio
                id="all"
                name="products"
                value={selectionType}
                onChange={() => onChange("all")}
              />
            </Label>
            <Label text="Use a selection of products">
              <Radio
                id="selection"
                name="products"
                value={selectionType}
                onChange={() => onChange("selection")}
              />
            </Label>
          </div>
          {selectionType === "selection" && (
            <ListingGroupsTable
              onSaveFilters={handleSaveFilters}
              onSetNewOptionFilters={handleSetNewOptionFilters}
            />
          )}

          <div className={styles.sidebarFooter}>
            <Button
              color="primary"
              className={styles.sidebarButton}
              onClick={handleRemoveAllOptions}
            >
              Save
            </Button>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Stack>
      </SidebarContainer>
    )
  }
)

export default ListingGroupsSidebar
