import { websiteURLValidator } from "@utils/validators"
import * as yup from "yup"

export const gaolOptions: string[] = [
  "sell product",
  "sell services",
  "collect leads & sign ups",
  "build brand awareness",
]

export const toneOptions: string[] = [
  "neutral",
  "formal",
  "strict",
  "emotional",
  "friendly",
  "business",
  "smart",
]

export const occasionOptions: string[] = [
  "none",
  "holiday",
  "anniversary",
  "sale",
  "new year",
  "christmas",
  "thanksgiving",
]

export const ageOptions: string[] = [
  "18-24",
  "25-34",
  "35-44",
  "45-54",
  "55-64",
  "65+",
]

export const customerTypeOptions: string[] = [
  "men",
  "women",
  "unisex",
  "kids",
  "baby",
  "toddlers",
  "teens",
  "seniors",
]

export const goalsValidationSchema = yup.object({
  goal: yup.string().label("Goal").required().default(gaolOptions[0]),
})

export const requiredSettingsValidationSchema = yup.object({
  finalUrl: websiteURLValidator.clone().label("URL").required().default(""),

  products: yup.string().label("Products").required().max(100).default(""),

  productDescription: yup
    .string()
    .label("Product Description")
    .required()
    .max(300)
    .default(""),

  companyDescription: yup
    .string()
    .label("Company Description")
    .required()
    .max(300)
    .default(""),
})

export const regenerateRequiredSettingsValidationSchema = yup.object({
  products: yup.string().label("Products").required().max(100).default(""),

  productDescription: yup
    .string()
    .label("Product Description")
    .required()
    .max(300)
    .default(""),

  companyDescription: yup
    .string()
    .label("Company Description")
    .required()
    .max(300)
    .default(""),
})

export const advancedSettingsValidationSchema = yup.object({
  toneType: yup.string().label("Text Tone").required().default(toneOptions[0]),

  occasion: yup
    .string()
    .label("Occasion")
    .required()
    .default(occasionOptions[0]),

  audienceAges: yup
    .array()
    .of(yup.string().required().default(""))
    .label("Audience Age")
    .required()
    .default([...ageOptions]),

  customerTypes: yup
    .array()
    .of(yup.string().required().default(""))
    .label("Customer Type")
    .required()
    .default([...customerTypeOptions]),

  promotionOffer: yup.string().label("Purchase Terms").max(40),
})

export const adCopyPromptsValidationSchema = yup
  .object({})
  .concat(goalsValidationSchema)
  .concat(regenerateRequiredSettingsValidationSchema)
  .concat(advancedSettingsValidationSchema)
