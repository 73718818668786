import React from "react"

function* ticker(steps: number) {
  for (let i = 0; i < steps; i += 1) yield i
  return null
}
export const useProgressTicker = ({
  steps = [],
  onTick,
  onEnd,
}: {
  steps?: number[]
  onTick: (options: { step: number; steps: number[]; progress: number }) => void
  onEnd?: () => void
}) => {
  const [isRunning, setIsRunning] = React.useState(false)
  const [step, setStep] = React.useState(0)
  const stepRef = React.useRef(ticker(steps.length))

  const intervalDuration = steps[step]

  const handleTick = () => {
    const tick = stepRef.current.next()

    if (!tick.done) {
      const nextStep = tick.value + 1
      onTick?.({
        step: nextStep,
        progress: 100 * (nextStep / steps.length),
        steps,
      })
      setStep(nextStep)
    } else {
      onEnd?.()
      setIsRunning(false)
    }
  }

  React.useEffect(() => {
    const timeout = setTimeout(handleTick, intervalDuration)
    return () => {
      clearTimeout(timeout)
    }
  }, [steps, isRunning, step, intervalDuration])

  React.useEffect(() => {
    setIsRunning(true)
    setStep(0)
    stepRef.current = ticker(steps.length)
  }, [steps.length])

  return {
    step,
    steps,
    progress: 100 * (step / steps.length),
    isRunning,
  }
}

export default useProgressTicker
