import { useEffect, useMemo } from "react"
import moment from "moment"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import { useStore } from "@store/index"

const useActualMonthlyAdSpend = (activeYear?: number) => {
  const store = useStore()
  const { checkbookStore, accountStore } = store
  const {
    actualMonthlyAmount,
    isActualMonthlyAmountLoading,
    loadActualMonthlyAdSpend,
  } = checkbookStore

  const { accountId } = accountStore

  const period = useMemo(() => {
    const currentMoment = moment()

    if (!activeYear || currentMoment.year() < activeYear) return null

    const startOfYear = moment()
      .set("year", activeYear)
      .startOf("year")
      .startOf("month")

    if (currentMoment.year() === activeYear) {
      return {
        start: startOfYear.format(YYYY_MM_DD_DATE_FORMAT),
        end: currentMoment.format(YYYY_MM_DD_DATE_FORMAT),
      }
    }

    const endOfYear = startOfYear.clone().endOf("year").endOf("month")

    return {
      start: startOfYear.format(YYYY_MM_DD_DATE_FORMAT),
      end: endOfYear.format(YYYY_MM_DD_DATE_FORMAT),
    }
  }, [activeYear])

  useEffect(() => {
    if (accountId && period) {
      loadActualMonthlyAdSpend(accountId, period.start, period.end)
    }
  }, [period, accountId, loadActualMonthlyAdSpend])

  return { isActualMonthlyAmountLoading, actualMonthlyAmount }
}

export default useActualMonthlyAdSpend
