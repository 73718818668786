import React from "react"
import { useField } from "formik"
import { observer } from "mobx-react-lite"

import TextField, { TextFieldProps } from "@components/ui/TextField/TextField"
import Label from "@components/ui/Label/Label"
import { useFormMetaContext } from "./FormMetaContext"

export interface FormFieldProps extends TextFieldProps {
  name: string
  label?: React.ReactNode
  afterBlur?: (
    e: React.FocusEvent<HTMLInputElement>,
    field: ReturnType<typeof useField>
  ) => void
}

const FormTextField: React.FC<FormFieldProps> = observer(
  ({ afterBlur, name, label, className, placeholder, ...rest }) => {
    const formMeta = useFormMetaContext()

    const fieldContext = useField(name)

    const [field, meta] = fieldContext

    const error = meta.touched ? meta.error : undefined

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
      field.onBlur(e)
      afterBlur?.(e, fieldContext)
    }

    return (
      <Label label={label} error={error} className={className}>
        <TextField
          name={name}
          edited={formMeta?.isEdited(name)}
          placeholder={Array.isArray(field.value) ? "<varies>" : placeholder}
          value={Array.isArray(field.value) ? undefined : field.value}
          valid={
            meta.touched &&
            meta.value !== meta.initialValue &&
            meta.error == null
          }
          error={!!error}
          onChange={field.onChange}
          onBlur={handleBlur}
          {...rest}
        />
      </Label>
    )
  }
)

export default FormTextField
