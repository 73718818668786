import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Typography from "@components/ui/Typography/Typography"
import { moneyFormatter } from "@services/utils"
import {
  useOpportunitiesController,
  useOpportunitiesStore,
} from "@store/opportunities/provider"
import Pair from "../Pair"
import SelectedKeywordsList from "../SelectedKeywordsList"

import styles from "./index.module.scss"

export interface FixedContainerProps {}

const FixedAmountSection: React.FC<FixedContainerProps> = observer(() => {
  const { shoppingCart } = useOpportunitiesStore()
  const { opportunities } = useOpportunitiesController()

  const isSubmittable =
    shoppingCart.selected.size > 0 && opportunities.publishCampaign != null

  return (
    <span className={styles.container}>
      <span className={styles.box}>
        <div className={styles.header}>
          <Typography type="h2" color="dark">
            Selected Keyword
          </Typography>
        </div>

        <SelectedKeywordsList />

        <div className={styles.footer}>
          <Pair
            label="Total Ad Spend"
            value={shoppingCart.totalAmount}
            renderValue={moneyFormatter}
            className={styles.totalAmount}
            strong
          />
          <div className={styles.confirm}>
            <Button
              onClick={opportunities.publishCampaign}
              disabled={!isSubmittable}
              variant="contained"
              color="primary"
              fullWidth
            >
              Confirm and Create Campaigns
            </Button>
            <Typography type="h4">
              By selecting confirm, these attributes will be added you account
              within 24 hours.
            </Typography>
          </div>
        </div>
      </span>
    </span>
  )
})

export default FixedAmountSection
