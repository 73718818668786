import React from "react"
import clsx from "clsx"

import { OrientationType, QuadSizeType } from "@utils/enums"
import usePrevious from "@framework/hooks/usePrevious"

import styles from "./LoaderProgressBar.module.scss"

const maxValue = 100

export interface LoaderProgressBarProps {
  value: number
  size?: QuadSizeType
  gradient?: OrientationType
  barTransitionTime?: string
  className?: string
}

export const LoaderProgressBar: React.FC<LoaderProgressBarProps> = ({
  value,
  size = QuadSizeType.medium,
  gradient,
  barTransitionTime = "0s",
  className,
}: LoaderProgressBarProps) => {
  const rootStyles = clsx(
    styles.root,
    styles[size],
    gradient ? styles[gradient] : undefined,
    className
  )

  const percentage = value > maxValue ? maxValue : value

  const oldPercentage = usePrevious(percentage)

  const barStyles = {
    width: `${percentage}%`,
    "--bar-transition-speed":
      oldPercentage && oldPercentage > percentage ? "0s" : barTransitionTime,
  }

  return (
    <div className={rootStyles}>
      <div className={styles.bar} style={barStyles}>
        <div className={styles.gradient} />
      </div>
    </div>
  )
}

export default LoaderProgressBar
