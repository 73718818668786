import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { getManageCampaignOption } from "@framework/constants/manageCampaign"
import { useController, useStore } from "@store/index"

import styles from "./CampaignsTable.module.scss"

export type AdGroupRowProps = {
  id: string
  index: number
}

export const AdGroupRow: React.FC<AdGroupRowProps> = observer(
  ({ id, index }) => {
    const { adCopyManagement } = useController()
    const { adCopyStore } = useStore()

    const data = adCopyStore.getAdGroupByID(id)
    const isSelected = adCopyStore.activeAdCopies.isAdGroupSelected(id)

    const toggle = () => adCopyManagement.selectAdGroup(id, !isSelected)

    return (
      <span className={styles.checkedRowContainer}>
        <CheckBox
          className={styles.checkbox}
          checked={isSelected}
          onChange={toggle}
        />
        <ClosableRow
          id={index}
          component={
            <TypedRow
              active={isSelected}
              label={data?.name ?? "N/A"}
              leftTip={<TypedTip data={getManageCampaignOption("groupAd")} />}
              onClick={toggle}
            />
          }
        />
      </span>
    )
  }
)

export default AdGroupRow
