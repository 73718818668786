import React from "react"
import { observer } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"
import { countedFormat } from "@utils/stringUtils"

import styles from "./EditAdCopyForm.module.scss"

interface HeaderProps {
  isEdit?: boolean
  isBalk?: boolean
  total?: number
  withAISupport?: boolean
}

export const Header: React.FC<HeaderProps> = observer(
  ({ isEdit = false, isBalk = false, total = 0, withAISupport = false }) => (
    <Templates.Header
      offset="none"
      className={styles.header}
      left={
        <Typography type="h2" weight="bold">
          {isEdit
            ? `Edit selected Ad Copy ${
                isBalk ? `• ${total} ${countedFormat("item", total)}` : ""
              }`
            : `Add new Ad Copy ${withAISupport ? `• AI helper` : ""}`}
        </Typography>
      }
    />
  )
)

export default Header
