import React from "react"

const useDelayedValue = <T extends any>(value: T, delay: number = 300): T => {
  const [actualValue, setValue] = React.useState<T>(value)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(value)
    }, delay)

    return () => {
      clearTimeout(timeout)
      setValue(value)
    }
  }, [value])

  return actualValue
}

export default useDelayedValue
