import { nanoid } from "nanoid"
import React from "react"

export const createContext = <T>(
  name: string = nanoid()
): [React.Context<T | null>, () => T] => {
  const Context = React.createContext<T | null>(null)

  Context.displayName = `Context_${name}_${nanoid()}`

  const useContext = (): T => {
    const context = React.useContext(Context)
    if (context == null)
      throw new Error(`Out of context: ${Context.displayName} scope`)
    return context
  }

  return [Context, useContext]
}

export default null
