import React from "react"
import { matchPath, useHistory, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import BackButton from "@framework/prototypes/BackButton"
import Templates from "@components/ui/Templates"
import Box from "@components/ui/Box/Box"
import { useStore } from "@store/index"
import { NavOption } from "@components/ui/Tabs/TabItem"
import { Button } from "@components/ui/Button"
import Icon from "@components/ui/Icon/Icon"
import SidebarMenu, {
  companySettingsOptions,
  otherOptions,
  personalSettingsOptions,
} from "./SidebarMenu"

import styles from "./CreativeAnalyticsLayout.module.scss"

export const CreativeAnalyticsLayout: React.FC = observer(({ children }) => {
  const location = useLocation()
  const history = useHistory()

  const {
    accountStore: { accountId },
  } = useStore()

  const menuOptions: NavOption[] = React.useMemo(
    () => [
      ...personalSettingsOptions,
      ...companySettingsOptions,
      ...otherOptions,
    ],
    []
  )

  const activeRoute = React.useMemo(
    () =>
      menuOptions.find(
        ({ value }) =>
          !!matchPath(location.pathname, {
            path: `/meta-ads/${accountId}/${value}`,
          })
      ),
    [location.pathname, menuOptions, accountId]
  )

  React.useEffect(() => {
    if (!activeRoute && accountId != null)
      history.replace(`/meta-ads/${accountId}/${menuOptions[1].value}`)
  }, [activeRoute, menuOptions, accountId])

  return (
    <Stack
      className={styles.root}
      direction="column"
      gutter="16"
      align="stretch"
    >
      <Templates.Header
        offset="none"
        left={
          <Stack direction="row" gutter="16" align="center">
            <BackButton />
            <Typography type="h1" weight="bold">
              Creative Analytics
            </Typography>
          </Stack>
        }
        right={
          <Button
            variant="ghost"
            color="primary"
            after={<Icon name="arrow-down" />}
          >
            Facebook Ads account
          </Button>
        }
      />

      <Stack direction="row" gutter="32">
        <div className={styles.menuContainer}>
          <Box className={styles.menu}>
            <SidebarMenu activeRoute={activeRoute} />
          </Box>
        </div>

        <div className={styles.bodyContainer}>{children}</div>
      </Stack>
    </Stack>
  )
})

export default CreativeAnalyticsLayout
