import React from "react"

import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"

import Stack from "@components/ui/Stack/Stack"
import styles from "./RegenerateAdCopyForm.module.scss"

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => (
  <Templates.Header
    offset="none"
    className={styles.header}
    left={
      <Stack gutter="4">
        <Typography type="h2" weight="bold">
          Regenerate AdCopy
        </Typography>
        <Typography type="caption2" color="black70Color">
          Choose how you want to change settings to rephrase the content{" "}
        </Typography>
      </Stack>
    }
  />
)

export default Header
