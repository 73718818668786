import { AdCopyInstance, EditAdCopyData } from "@framework/types/adCopy"
import { createContext } from "@utils/context"

export interface IEditAdCopyContext {
  readonly isEdit: boolean

  readonly isBulk: boolean

  readonly submitLabel: string

  onCreate: (data: AdCopyInstance) => Promise<string | null>

  onUpdate: (data: Partial<EditAdCopyData>) => Promise<string | null>

  onDelete: () => Promise<void>

  onClose: () => Promise<void>
}

const context = createContext<IEditAdCopyContext>("EditAdCopyContext")

export const EditAdCopyContext = context[0]

export const useEditAdCopyContext = context[1]
