import { observable, action, computed } from "mobx"

import RootStore from "@store/RootStore"
import { AdCopyData } from "@framework/types/adCopy"
import { ID } from "@framework/types/types"

export class AdCopyListStore {
  root: RootStore

  constructor(root: RootStore) {
    this.root = root
  }

  @observable adCopies: ID[] = []

  @observable adCopyMap: Map<string, AdCopyData> = new Map()

  @observable isLoading = false

  @observable isSync = false

  @observable isGoogleSync = true

  @observable error: null | string = null

  @action getById = (adCopyId: ID) => this.adCopyMap.get(adCopyId.toString())

  @action setAdCopies = (data: AdCopyData[]) => {
    this.adCopyMap = new Map(data.map((it) => [it.id, it]))
    this.adCopies = Array.from(this.adCopyMap.keys())
  }

  @action updateAdCopies = (data: AdCopyData[]) => {
    data.forEach((copy) => {
      if (this.adCopyMap.has(copy.id)) this.adCopyMap.set(copy.id, copy)
    })
  }

  @action requestReload = () => {
    this.isSync = false
  }

  @action endLoading = (error: string | null = null) => {
    this.error = error
    this.isLoading = false
    this.isSync = true
  }
}

export default AdCopyListStore
