import React from "react"

import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"

import styles from "./EditAdCopyForm.module.scss"

export interface RowContainerProps {
  label: React.ReactNode
}

export const RowContainer: React.FC<RowContainerProps> = ({
  label,
  children,
}) => (
  <Stack direction="row" align="center" className={styles.row}>
    <Typography type="h4" color="black70Color">
      {label}
    </Typography>
    {children}
  </Stack>
)

export default RowContainer
