import { useCallback, useEffect, useMemo, useState } from "react"
import { accountProfileValidationSchema } from "@components/utils/validators"
import { useStore } from ".."
import { MIN_ROWS } from "./manageProfile"

const checkFirstEntriesNotEmpty = (
  entries: [string, string][],
  minRowsCount: number = 1
) => {
  if (entries.length < minRowsCount) return false
  for (let i = 0; i < minRowsCount; i += 1) {
    if (!entries[i][1]) return false
  }
  return true
}

const useManageProfile = () => {
  const [isAccountInfoValid, setAccountInfoValid] = useState(false)

  const {
    manageProfileStore: {
      isEdit,
      keywordsGenerationStats,
      accountInfo,
      assignedAttributes,
      attributes,
      categories,
      hasNonProcessedAttributes,
      defaultHeadlineEntries,
      defaultDescriptionEntries,
      isGenerating,
      startKeywordsGenerating,
      fetchAttributes,
      fetchAssignedAttributes,
    },
    accountStore: { account },
  } = useStore()

  useEffect(() => {
    accountProfileValidationSchema.isValid(accountInfo).then((value) => {
      setAccountInfoValid(value)
    })
  }, [accountInfo])

  useEffect(() => {
    if (account?.industryId && !isGenerating) {
      fetchAttributes(account.industryId)
      fetchAssignedAttributes(account.id)
    }
  }, [account, isGenerating])

  // valid if any checked attribute exist
  const isAssignedAttributesValid = useMemo(
    () =>
      !!(
        categories &&
        attributes &&
        assignedAttributes &&
        categories.findIndex((category) => {
          if (attributes[category.id])
            return (
              attributes[category.id].findIndex((attribute) =>
                assignedAttributes.has(attribute.id)
              ) >= 0
            )
          return false
        }) >= 0
      ),
    [assignedAttributes, attributes, categories]
  )

  const isAdCopyValid = useMemo(
    () =>
      !!(
        defaultHeadlineEntries &&
        defaultDescriptionEntries &&
        checkFirstEntriesNotEmpty(defaultHeadlineEntries, MIN_ROWS) &&
        checkFirstEntriesNotEmpty(defaultDescriptionEntries, MIN_ROWS)
      ),
    [defaultHeadlineEntries, defaultDescriptionEntries]
  )

  const isAccountLinked = useMemo(() => !!account?.adAccountId, [account])

  const cantGenerateKeywords = useMemo(
    () =>
      !!(
        keywordsGenerationStats &&
        !keywordsGenerationStats.running &&
        isAccountInfoValid &&
        isAssignedAttributesValid &&
        hasNonProcessedAttributes &&
        !isEdit
      ),
    [
      hasNonProcessedAttributes,
      keywordsGenerationStats,
      isAccountInfoValid,
      isAssignedAttributesValid,
      isEdit,
    ]
  )

  const isCampaignLaunched = useMemo(
    () => Boolean(account?.launchDate),
    [account]
  )

  const handleStartGenerating = useCallback(() => {
    if (account) {
      startKeywordsGenerating(account.id)
    }
  }, [account, startKeywordsGenerating])

  return {
    cantGenerateKeywords,
    isAccountInfoValid,
    isAssignedAttributesValid,
    isCampaignLaunched,
    isAdCopyValid,
    isAccountLinked,
    handleStartGenerating,
  }
}

export default useManageProfile
