import React from "react"
import { ID, SortByType } from "@framework/types/types"
import { useStore } from "@store/index"
import {
  searchCampaignReports,
  sortCampaignReportsAsync,
} from "@pages/ManageCampaign/utils"
import { CampaignReportType } from "@framework/types/account"

export const useCampaignsReports = (config: {
  campaigns: ID[]
  sortBy?: SortByType
  searchQuery?: string
}) => {
  const { campaigns, sortBy, searchQuery } = config

  const {
    accountDashboardStore: {
      campaignInsightsStore: { topCampaignStore: store },
    },
  } = useStore()

  const [inProgress, setInProgress] = React.useState(false)

  const [campaignList, setCampaignList] = React.useState<CampaignReportType[]>(
    []
  )

  const [sortedCampaigns, setSortedCampaigns] = React.useState<
    CampaignReportType[]
  >([])

  const update = async (
    campaignList: CampaignReportType[],
    sortBy?: SortByType,
    searchQuery?: string
  ) => {
    setInProgress(true)

    const filtered = await searchCampaigns(campaignList, searchQuery)
    const res = await sortCampaigns(filtered, sortBy)

    setSortedCampaigns(res)
    setInProgress(false)
  }

  React.useEffect(() => {
    if (campaigns == null) {
      setCampaignList([])
      return
    }
    setCampaignList(
      campaigns.reduce<CampaignReportType[]>((acc, id) => {
        const item = store.getById(id)
        if (item != null) acc.push(item)
        return acc
      }, [])
    )
  }, [campaigns, store.getById])

  React.useEffect(() => {
    update(campaignList, sortBy, searchQuery)
  }, [campaignList, searchQuery, sortBy])

  return { sortedCampaigns, inProgress }
}

const sortCampaigns = async (
  campaignList: CampaignReportType[],
  sortBy?: SortByType
) => {
  if (campaignList.length === 0) return campaignList

  const res = await sortCampaignReportsAsync(campaignList, sortBy)

  return res
}

const searchCampaigns = async (
  campaignList: CampaignReportType[],
  searchQuery: string = ""
) => {
  const res = await searchCampaignReports(campaignList, searchQuery)
  return res
}

export default useCampaignsReports
