/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { ListRowProps } from "react-virtualized"
import { useAlert } from "react-alert"

import { WaterfallTable } from "@components/ui/WaterfallTable/WaterfallTable"
import { ID, SortByType } from "@framework/types/types"
import { useStore } from "@store/index"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import NoData from "@components/ui/NoData/NoData"
import PlaceholderLoader from "@components/ui/Loader/PlaceholderLoader"
import Loader from "@components/ui/Loader/Loader"
import { useSearchContext } from "@framework/prototypes/SearchContext"
import useFilteredAdGroupChild from "@pages/Account/AccountDashboard/components/CampaignInsights/useFilteredAdGroupChild"
import useSortedAds from "@pages/Account/AccountDashboard/components/CampaignInsights/useSortedAds"
import { AdsReportType } from "@framework/types/account"
import { useSelectedAdGroupsContext } from "@framework/prototypes/SelectedAdGroupsContext"
import mapper from "./ads-table-mapper"
import VirtualizedTable from "./VirtualizedTable"
import AdRow from "./AdRow"

import styles from "./index.module.scss"

type AdsTableProps = {
  className?: string
}

const AdsTable: React.FC<AdsTableProps> = observer(({ className }) => {
  const alert = useAlert()
  const { manageCampaignStore, accountStore } = useStore()
  const { accountId } = accountStore
  const { updateLoading, adsStore, dateFilter, loadAdsList } =
    manageCampaignStore

  const searchContext = useSearchContext()
  const adGroupsContext = useSelectedAdGroupsContext()

  const init = async (accountId: number) => {
    const error = await loadAdsList(accountId, dateFilter)
    if (error)
      alert.error(
        <AlertMessage title="Failed to load report" description={error} />
      )
  }

  React.useEffect(() => {
    if (!accountId || adsStore.error || updateLoading) return
    init(accountId)
  }, [accountId, dateFilter, updateLoading])

  const [sortBy, setSortBy] = React.useState<SortByType>({
    value: "",
    direction: true,
  })

  const handleSort = (name: string) => {
    setSortBy((oldSortBy) => ({
      value: name,
      direction: !oldSortBy.direction,
    }))
  }

  const { filteredCollection: filteredAds, inProgress: isFiltering } =
    useFilteredAdGroupChild<AdsReportType>({
      collection: adsStore.data,
      adGroups: adGroupsContext.selectedAdGroups,
    })

  const { sortedKeywords, inProgress: isSorting } = useSortedAds({
    ads: filteredAds,
    searchQuery: searchContext.searchQuery,
    sortBy,
  })

  const ads = sortedKeywords

  const getIndexById = (id: ID) =>
    sortedKeywords.findIndex((it) => it.Id.toString() === id.toString())

  const renderRow = ({ index }: ListRowProps) => {
    const data = ads[index]

    const isEditable =
      data.CampaignStatus !== "REMOVED" && data.AdGroupStatus !== "REMOVED"

    return (
      <AdRow
        mapper={mapper}
        data={data}
        editable={isEditable}
        className={styles.campaignRow}
      />
    )
  }

  const isLoading =
    updateLoading || adsStore.isLoading || isSorting || isFiltering

  if (ads.length)
    return (
      <div className={clsx(styles.root, className)}>
        <Loader isLoading={isLoading} />

        <WaterfallTable
          labelWidth={420}
          labelSortable
          withTip
          mapper={mapper}
          label="Ad Name"
          onSort={handleSort}
          sortBy={sortBy}
          rootClassName={styles.flexFullHeight}
          bodyClassName={styles.flexFullHeight}
        >
          <VirtualizedTable
            list={ads}
            renderRow={renderRow}
            getIndexById={getIndexById}
          />
        </WaterfallTable>
      </div>
    )

  if (adsStore.isLoading) return <PlaceholderLoader />

  if (!adGroupsContext.selectedAdGroups.length)
    return <NoData>No Ad Groups selected</NoData>

  return <NoData>No Ads found</NoData>
})

export default AdsTable
