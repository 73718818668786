import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import * as yup from "yup"
import { FormikProvider, useFormik } from "formik"

import PublicLayout from "@root/layouts/public/PublicLayout"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import { emailValidator } from "@components/utils/validators"
import { useStore } from "@store/index"
import useValidShopifyCredentials from "@framework/hooks/useValidShopifyCredentials"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import FormTextField from "@framework/prototypes/FormTextField"
import usePasswordFiledToggle from "@components/ui/TextField/usePasswordFiledToggle"
import config from "@root/config"

import styles from "./Login.module.scss"

export const validationSchema = yup.object({
  email: emailValidator.clone().required("Can not be empty").default(""),
  password: yup.string().required("Can not be empty").default(""),
})

type LoginFormData = yup.InferType<typeof validationSchema>

const initialValues: LoginFormData = validationSchema.getDefault()

const Login: React.FC = observer(() => {
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()
  const {
    authStore: { isAuthorized, user, isLoading, login, loginWithShopify },
  } = useStore()

  const form = useValidShopifyCredentials()

  const handleSubmit = async (form: LoginFormData) => {
    const error = await login(form.email, form.password)
    if (error) {
      alert.error(<AlertMessage title="Login failed" description={error} />)
    }
  }

  const handleRestorePassword = () => history.push("/forgot-password")

  const formik = useFormik<LoginFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  if (!config.HIDE_SHOPIFY) {
    useEffect(() => {
      if (!isAuthorized) return

      if (!form) {
        history.push("/")
        return
      }
      if (user?.Email) {
        loginWithShopify(user.Email, form).then(({ propertyId, error }) => {
          if (error) {
            alert.error(
              <AlertMessage
                title="Failed to login Shopify"
                description={error}
              />
            )
          }
          if (propertyId != null) {
            history.push(`/shopping/${propertyId}/dashboard/welcome`)
          }
        })
      }
    }, [isAuthorized, form, user])
  } else {
    useEffect(() => {
      if (isAuthorized) history.push("/")
    }, [isAuthorized])
  }

  const handleSignUpRedirect = () => {
    history.replace(`/signup${location.search}`)
  }

  const passToggleProps = usePasswordFiledToggle()

  return (
    <PublicLayout
      after={
        config.HIDE_SHOPIFY ? undefined : (
          <Button
            onClick={handleSignUpRedirect}
            color="primary"
            variant="ghost"
          >
            Sign Up
          </Button>
        )
      }
    >
      <FormikProvider value={formik}>
        <form className={styles.root} onSubmit={formik.handleSubmit}>
          <Typography type="h1" color="dark" bold>
            Sign In
          </Typography>

          <FormTextField label="Email" name="email" />

          <FormTextField
            label="Password"
            name="password"
            {...passToggleProps}
          />

          <div className={styles.control}>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              type="submit"
            >
              Sign In
            </Button>
            <Button
              variant="ghost"
              color="default"
              onClick={handleRestorePassword}
            >
              Forgot Password
            </Button>
          </div>
        </form>
      </FormikProvider>
    </PublicLayout>
  )
})

export default Login
