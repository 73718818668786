import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"

import Button from "@components/ui/Button/Button"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import { useController, useStore } from "@store/index"
import useCampaignOptions from "@store/manageProfile/useCampaignOptions"
import useAdGroupOptions from "@store/manageProfile/useAdGroupOptions"
import { ID } from "@framework/types/types"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"

import styles from "./DuplicateAdCopyModal.module.sass"

export const validationSchema = yup.object({
  campaign: yup.string().label("Campaign").required().default(""),
  adGroup: yup.string().label("Ad Group").required().default(""),
})

type EditFormType = yup.InferType<typeof validationSchema>

type DuplicateAdCopyFormProps = {
  onSubmit?: () => void
  onCancel?: () => void
  adCopyIds: ID[]
}

const DuplicateAdCopyForm: React.FC<DuplicateAdCopyFormProps> = observer(
  ({ adCopyIds, onCancel, onSubmit }) => {
    const alert = useAlert()
    const { adCopyManagement } = useController()
    const {
      accountStore: { accountId },
    } = useStore()

    const handleSubmit = async (form: EditFormType) => {
      if (!accountId || !adCopyIds) return

      const error = await adCopyManagement.duplicateAdCopy(
        accountId,
        form.campaign,
        [form.adGroup],
        adCopyIds
      )

      if (error)
        alert.error(
          <AlertMessage
            title="Failed to duplicate Ad Copy"
            description={error}
          />
        )
      else onSubmit?.()
    }

    const formik = useFormik<EditFormType>({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      onSubmit: handleSubmit,
    })

    useEffect(() => {
      formik.setFieldValue("adGroup", "")
    }, [formik.values.campaign])

    const campaignOptionProps = useCampaignOptions()
    const adGroupOptionProps = useAdGroupOptions(formik.values.campaign)

    const total = adCopyIds?.length ?? 0
    const noneSelected = total === 0
    const message = noneSelected
      ? "There are no existing Ad Copy selected, nothing to duplicate"
      : total > 1
      ? `Where do you want to move to the duplicated selected ad copies: ${total}?`
      : "Where do you want to move to the duplicated ad copy"

    return (
      <FormikProvider value={formik}>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Stack gutter="12" align="stretch" className={styles.root}>
            <Typography type="h2">Duplicate</Typography>

            <Typography type="caption1">{message}</Typography>

            {!noneSelected ? (
              <>
                <FormSimpleSelectField
                  name="campaign"
                  label="Select campaign"
                  {...campaignOptionProps}
                />

                <FormSimpleSelectField
                  name="adGroup"
                  label="Select Ad Group"
                  disabled={!formik.values.campaign}
                  {...adGroupOptionProps}
                />

                <Typography type="caption2">
                  If you select multiple Ad Groups, Ad Copy will be created in
                  each one
                </Typography>
              </>
            ) : null}

            <Stack direction="row" justify="flex-end">
              <Button color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={noneSelected}>
                Apply
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormikProvider>
    )
  }
)

export default DuplicateAdCopyForm
