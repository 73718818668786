/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom"
import { observer } from "mobx-react-lite"
import Container from "@root/layouts/Container/Container"
import { useStore } from "@store/index"
import PrivateLayout, {
  NavBarProps,
} from "@root/layouts/PrivateLayout/PrivateLayout"
import { NavOption } from "@components/ui/Tabs/types"
import { findDefaultNavItem } from "@components/ui/Tabs/utils"
import ReportsPage from "@pages/Reports/Reports"
import config from "@root/config"
import { clientListNavItems } from "../navOptions"
import Dashboard from "./Dashboard/Dashboard"
import AccountList from "../AccountList/AccountList"
import Profile from "./Profile/Profile"
import Checkbook from "./Checkbook/Checkbook"

const restricted = ["/dashboard", "/checkbook"]

interface ParamsType {
  id?: string
}

const ClientRoot: React.FC = observer(() => {
  const history = useHistory()
  const {
    appStore: { showEverything },
    clientStore: { clientId, client, loadClient, setClientId },
    clientProfileStore: { clientUpdateLoading, clientUpdateError },
  } = useStore()

  const [tab, setTab] = useState<NavOption>()

  const { id } = useParams<ParamsType>()

  const { pathname, search } = useLocation<any>()

  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  )

  const filteredNavItems = useMemo(
    () =>
      clientListNavItems.filter(
        ({ value }) => showEverything || !restricted.includes(value)
      ),
    [showEverything]
  )

  useEffect(() => {
    if (!id || Number.isNaN(id)) history.push("./")
  }, [id, history])

  useEffect(() => {
    setClientId(Number(id) || null)
    return () => {
      setClientId(null)
    }
  }, [setClientId, id])

  useEffect(() => {
    if (clientId) {
      loadClient(clientId)
    }
  }, [clientId, loadClient])

  useEffect(() => {
    if (!clientUpdateLoading && !clientUpdateError && clientId) {
      loadClient(clientId)
    }
  }, [clientUpdateLoading])

  useEffect(() => {
    setTab(findDefaultNavItem(filteredNavItems, pathname, queryParams.alter))
  }, [pathname, queryParams, filteredNavItems])

  const handleChangeTab = useCallback(
    (item: NavOption) => {
      history.push(`.${item.value}`)
    },
    [history]
  )

  const withNavBar = useMemo((): NavBarProps | undefined => {
    const confirmation = pathname.endsWith("/create-campaign")

    if (!confirmation) {
      return {
        options: filteredNavItems,
        title: client?.name ?? "",
        onSelect: handleChangeTab,
        currentTab: tab,
      }
    }
  }, [handleChangeTab, pathname, tab, filteredNavItems])

  return (
    <PrivateLayout sideBar={withNavBar} scope="client">
      <Container>
        <Switch>
          <Route path="/client/:id/accounts" component={AccountList} />

          {!config.HIDE_REPORTS && (
            <Route path="/client/:id/reports" component={ReportsPage} />
          )}

          {showEverything && (
            <Route path="/client/:id/dashboard" component={Dashboard} />
          )}

          <Route path="/client/:id/profile" component={Profile} />

          {showEverything && (
            <Route path="/client/:id/checkbook" component={Checkbook} />
          )}

          <Route path="/client/:id/**">
            <Redirect to="./accounts" />
          </Route>
        </Switch>
      </Container>
    </PrivateLayout>
  )
})

export default ClientRoot
