import React from "react"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"
import clsx from "clsx"

import Stack from "@components/ui/Stack/Stack"
import Icon from "@components/ui/Icon/Icon"
import { useStore } from "@store/index"
import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import Loader from "@components/ui/Loader/Loader"

import styles from "./ImportProductsModal.module.scss"

const IMPORT_IMAGE_SRC = "/images/import_products_box.png"
const DONE_IMAGE_SRC = "/images/import_products_done.png"

type ImportProductsModalProps = {
  isOpen: boolean
  straightMount?: boolean
}

const ImportProductsModal: React.FC<ImportProductsModalProps> = observer(
  ({ isOpen, straightMount }) => {
    const history = useHistory()
    const {
      accountStore: { accountId },
      productFeedStore: {
        importStatus,
        importStatusError,
        isInited,
        isImportStatusLoading,
        isProductsImportLoading,
        importShopifyProducts,
        finalizeImport,
      },
    } = useStore()

    const handleImport = () => {
      if (!accountId) return
      importShopifyProducts(accountId)
    }

    const render = () => {
      if (!isInited && isImportStatusLoading) {
        return (
          <div className={styles.container}>
            Loading...
            <Loader />
          </div>
        )
      }

      if (importStatus === null || importStatusError) {
        return (
          <div className={styles.container}>
            <Icon
              name="alert"
              className={clsx(styles.icon, styles.alertIcon)}
            />
            <Typography type="h2" color="dark">
              Service temporary unavailable
            </Typography>
            <Typography color="gray">Try again later</Typography>

            <Button
              color="secondary"
              disabled={accountId == null}
              onClick={() => history.push(`/account/${accountId}/channels`)}
            >
              Back To Channels
            </Button>
          </div>
        )
      }

      if (isProductsImportLoading || importStatus.running === "running") {
        return (
          <div className={styles.container}>
            <img
              src={IMPORT_IMAGE_SRC}
              className={styles.icon}
              height={112}
              width={112}
              alt=""
            />
            <Typography type="h2" color="dark">
              Import is running
            </Typography>
            <Loader />
            <Typography color="gray">
              Present page will be blocked till the end of import process
            </Typography>
            <Button
              color="secondary"
              disabled={accountId == null}
              onClick={() => history.push(`/account/${accountId}/channels`)}
            >
              Go To Channels
            </Button>
          </div>
        )
      }

      if (importStatus.running === "finished") {
        const handleImportComplete = () => {
          finalizeImport()
          history.push("./product-feed")
        }

        return (
          <div className={styles.container}>
            <img
              src={DONE_IMAGE_SRC}
              className={styles.icon}
              height={112}
              width={112}
              alt=""
            />
            <Typography type="h2" color="dark">
              Import is finished successfully
            </Typography>
            <div className={styles.points}>
              <Typography color="darkGray">
                Shopify products for import: <b>{importStatus.fetched}</b>
              </Typography>
              <Typography color="darkGray">
                Imported products: <b>{importStatus.imported}</b>
              </Typography>

              <Typography color="darkGray">
                Deleted products: <b>{importStatus.deleted}</b>
              </Typography>
            </div>

            <Button onClick={handleImportComplete}>See Product feed</Button>
          </div>
        )
      }

      if (importStatus.running === "failed") {
        return (
          <div className={styles.container}>
            <Icon
              name="information-outline"
              className={clsx(styles.icon, styles.infoIcon)}
            />
            <Typography type="h2" color="dark">
              Import is unsuccessful
            </Typography>
            <div className={styles.points}>
              <Typography color="darkGray">
                Shopify products for import: <b>{importStatus.fetched}</b>
              </Typography>
              <Typography color="darkGray">
                Imported products: <b>{importStatus.imported}</b>
              </Typography>

              <Typography color="darkGray">
                Deleted products: <b>{importStatus.deleted}</b>
              </Typography>
            </div>

            <Button onClick={finalizeImport}>Ok</Button>
          </div>
        )
      }

      return (
        <div className={styles.container}>
          <img
            src={IMPORT_IMAGE_SRC}
            className={styles.icon}
            height={112}
            width={112}
            alt=""
          />
          <Typography type="h2" color="dark">
            Product Feed List is Empty
          </Typography>
          <Typography color="gray">
            To Start, click below to add your products into Adoro.
          </Typography>
          <Stack align="stretch">
            <Button disabled={isProductsImportLoading} onClick={handleImport}>
              Import products
            </Button>
            <Button
              color="secondary"
              disabled={accountId == null}
              onClick={() => history.push(`/account/${accountId}/channels`)}
            >
              Go To Channels
            </Button>
          </Stack>
        </div>
      )
    }

    return (
      <Modal isOpen={isOpen} hideHeader straightMount={straightMount}>
        {render()}
      </Modal>
    )
  }
)

export default ImportProductsModal
