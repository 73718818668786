/* eslint-disable react/jsx-props-no-spreading */
import clsx from "clsx"
import React, { useCallback, useMemo } from "react"
import { numberWithCommas } from "@utils/numberUtils"
import BaseProgressBar, {
  IBaseProgressBarProps,
} from "../BaseProgressBar/BaseProgressBar"

import styles from "./IndicatedProgressBar.module.scss"

export interface IIndicatedProgressBarProps extends IBaseProgressBarProps {
  onDrawValue?: (value: string) => string
  showPercentage?: boolean
  showValue?: boolean
  showLimit?: boolean
  className?: string
  maxValue?: number
  minValue?: number
}

export const IndicatedProgressBar: React.FC<IIndicatedProgressBarProps> = ({
  value,
  onDrawValue,
  showPercentage = true,
  showValue = false,
  showLimit = false,
  className = "",
  maxValue = 100,
  minValue = 0,
  ...rest
}: IIndicatedProgressBarProps) => {
  const rootStyles = useMemo(() => clsx(styles.root, className), [className])

  const validValue = !Number.isNaN(value) && value > 0 ? value : minValue

  const realMaxValue = validValue > maxValue ? validValue : maxValue
  const percent = Math.ceil(((validValue - minValue) / maxValue) * 100)

  // const maxValuePos = (maxValue / realMaxValue) * 100

  const handleDrawValue = useCallback(
    (value: number) =>
      onDrawValue
        ? onDrawValue(numberWithCommas(value))
        : numberWithCommas(value),
    [onDrawValue]
  )

  const limitValue = validValue > maxValue ? validValue : maxValue
  const realValue = validValue > maxValue ? maxValue : validValue

  const limitPercentage = (limitValue / maxValue) * 100
  const realPercent = (realValue / realMaxValue) * 100

  const indicatorStyles = { marginLeft: `${realPercent}%` }

  const offset =
    realPercent < 10
      ? realPercent * 5
      : realPercent < 90
      ? 50
      : realPercent * 0.9

  const maxIndexOpacity = realPercent > 60 ? 1 - (realPercent - 60) / 25 : 1

  const isOverflow = validValue > maxValue

  return (
    <div className={rootStyles}>
      {showPercentage && (
        <div className={styles.topIndicatorContainer} style={indicatorStyles}>
          <span>
            <span
              style={{
                marginLeft: `-${offset}%`,
                opacity: !isOverflow ? 1 : maxIndexOpacity,
              }}
              className={styles.tooltip}
            >
              {numberWithCommas(isOverflow ? 100 : realPercent)}%
            </span>
          </span>
          {showLimit && (
            <span
              style={{ opacity: isOverflow ? 1 : maxIndexOpacity }}
              className={styles.limit}
            >
              {numberWithCommas(limitPercentage)}%
            </span>
          )}
        </div>
      )}
      <BaseProgressBar value={percent} {...rest} />
      {showValue && (
        <div
          className={styles.bottomIndicatorContainer}
          style={indicatorStyles}
        >
          <span>
            <span
              style={{
                marginLeft: `-${offset}%`,
                opacity: !isOverflow ? 1 : maxIndexOpacity,
              }}
              className={styles.tooltip}
            >
              {handleDrawValue(realValue)}
            </span>
          </span>
          <span
            style={{ opacity: isOverflow ? 1 : maxIndexOpacity }}
            className={styles.limit}
          >
            {handleDrawValue(limitValue)}
          </span>
        </div>
      )}
    </div>
  )
}

export default IndicatedProgressBar
