import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Stack from "@components/ui/Stack/Stack"
import CollapsibleHeader from "@components/ui/Collapsible/CollapsibleHeader"
import useToggle from "@framework/hooks/useToggle"
import Collapsable from "@components/ui/Collapsible/Collapsable"

import styles from "./WrappableContainer.module.scss"

export interface WrappableContainerProps {
  className?: string
  label: React.ReactNode
  defaultOpened?: boolean
}

export const WrappableContainer: React.FC<WrappableContainerProps> = observer(
  ({ label, className, defaultOpened, children }) => {
    const section = useToggle(defaultOpened)
    return (
      <Stack
        gutter="0"
        align="stretch"
        className={clsx(styles.root, className)}
      >
        <CollapsibleHeader
          type="h2"
          label={label}
          open={section.isOpened}
          onClick={section.handleToggle}
        />
        <Collapsable open={section.isOpened}>{children}</Collapsable>
      </Stack>
    )
  }
)

export default WrappableContainer
