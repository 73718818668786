import React from "react"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import TextField from "@components/ui/TextField/TextField"
import SystemMessage from "@components/ui/SystemMessage/SystemMessage"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import InlineLabel from "@components/ui/CheckBox/Label/Label"
import {
  getBillingStrategy,
  pMaxBillingOptions,
} from "@framework/constants/manageCampaign"
import FormNumberField from "@framework/prototypes/FormNumberField"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import { useActiveSection } from "@pages/PMax/ActiveSectionContext"

import styles from "./index.module.scss"

export const billingOptions = pMaxBillingOptions

export const BiddingSection: React.FC = observer(() => {
  const formik = useFormikContext<any>()

  const isTargetRequired = formik.values.target
  const focusOn = formik.values.biddingStrategyType
  const billingStrategy = getBillingStrategy(focusOn)

  const { props } = useActiveSection<HTMLDivElement>("bidding")

  return (
    <Box className={styles.section} {...props}>
      <Stack gutter="16" direction="column">
        <Typography type="h2">Bidding</Typography>

        <FormSimpleSelectField
          label="What do you want to focus on?"
          name="biddingStrategyType"
          options={billingOptions}
          renderValue={renderBillingOption}
          className={styles.field}
        />

        <InlineLabel
          text="Set a target return on ad spend (optional)"
          color="black60Color"
        >
          <CheckBox
            name="target"
            checked={formik.values.target}
            onChange={formik.handleChange}
          />
        </InlineLabel>

        {isTargetRequired && focusOn === "MAXIMIZE_CONVERSION_VALUE" && (
          <span>
            <FormNumberField
              label="Target ROAS %"
              name="targetValue"
              decimalScale={0}
              customInput={TextField}
              thousandSeparator
              suffix="%"
            />

            <Typography type="caption3" color="black60Color">
              Your recommended target ROAS is 300% based on your account’s
              average return on ad spend
            </Typography>
          </span>
        )}

        {isTargetRequired && focusOn === "MAXIMIZE_CONVERSIONS" && (
          <span>
            <FormNumberField
              label="Target CPA"
              name="targetValue"
              decimalScale={2}
              customInput={TextField}
              thousandSeparator
              prefix="$"
            />

            <Typography type="caption3" color="black60Color">
              Your recommended target CPA is $25.00 based on your account’s
              average cost per conversion
            </Typography>
          </span>
        )}

        <SystemMessage type="success" message={billingStrategy.message} />
      </Stack>
    </Box>
  )
})

export default BiddingSection

export const renderBillingOption = (value: string) =>
  getBillingStrategy(value).label
