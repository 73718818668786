import React, { useContext, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { TableOpenContext } from "@components/ui/WaterfallTable/WaterfallTable"
import { ID } from "@framework/types/types"
import {
  AutoSizer,
  CellMeasurerCache,
  InfiniteLoader,
  ListRowProps,
  List as VirtualList,
} from "react-virtualized"
import { RectAutoMeasurer } from "./RectAutoMeasurer"

import styles from "./index.module.scss"

export const VirtualizedTable: React.FC<{
  list: any[]
  renderRow: (index: ListRowProps) => React.ReactNode
  getIndexById?: (id: ID) => number | undefined
}> = observer(({ list, getIndexById, renderRow }) => {
  const { openedList } = useContext(TableOpenContext)

  const listRef = React.useRef<VirtualList | null>()

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 65,
        minHeight: 50,
      }),
    [list]
  )

  React.useEffect(() => {
    listRef.current?.recomputeRowHeights()
  }, [cache])

  React.useEffect(() => {
    if (getIndexById == null || openedList[0] == null) return

    const index = getIndexById(openedList[0])

    setTimeout(() => {
      listRef.current?.scrollToRow(index)
    }, 0)
  }, [openedList[0]])

  const render = (props: ListRowProps) => {
    const { index, key, parent, style } = props

    return (
      <div style={style} key={key}>
        <RectAutoMeasurer index={index} parent={parent} cache={cache} key={key}>
          {renderRow(props)}
        </RectAutoMeasurer>
      </div>
    )
  }

  const total = list.length

  return (
    <InfiniteLoader
      isRowLoaded={() => true}
      loadMoreRows={async () => {}}
      rowCount={total}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ width, height }) => (
            <VirtualList
              rowCount={total}
              ref={(ref) => {
                registerChild(ref)
                listRef.current = ref
              }}
              className={styles.noScroll}
              onRowsRendered={onRowsRendered}
              rowHeight={cache.rowHeight}
              rowRenderer={render}
              height={height}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  )
})

export default VirtualizedTable
