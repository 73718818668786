import { ID } from "@framework/types/types"
import { useStore } from "@store/index"
import { useMemo } from "react"

export const useAdGroupOptions = (campId?: ID) => {
  const {
    adCopyStore: { campaignsCollection },
  } = useStore()

  const addGroups = useMemo(
    () => [...campaignsCollection.adGroupsMap.values()],
    [campaignsCollection.adGroupsMap]
  )

  const options = useMemo(
    () =>
      (campId
        ? addGroups.filter((it) => it.campaignId === campId.toString())
        : addGroups
      ).map((it) => it.id),
    [addGroups, campId]
  )

  return {
    options,
    renderValue: (adGroupId: string) =>
      campaignsCollection.adGroupsMap.get(adGroupId)?.name ?? "Unknown",
  }
}

export default useAdGroupOptions
