import { useMemo } from "react"
import { makeAdCopyPreviews } from "@framework/prototypes/AdCopy/Preview/utils"
import { AdCopyPreview } from "@framework/prototypes/AdCopy/Preview/types"

export interface PropType {
  headlines: [string, string][]
  descriptions: [string, string][]
  website: string
}

export const useAdPreviewGenerator = ({
  headlines,
  descriptions,
  website,
}: PropType): AdCopyPreview[] =>
  useMemo(() => {
    const validHeadlines = headlines.filter((it) => !!it[1]).map((it) => it[1])

    const validDescriptions = descriptions
      .filter((it) => !!it[1])
      .map((it) => it[1])

    if (validDescriptions.length < 3 || validHeadlines.length < 3) return []

    return makeAdCopyPreviews(
      website,
      validHeadlines,
      validDescriptions,
      Math.min(validDescriptions.length, 6)
    )
  }, [headlines, descriptions, website])

export default useAdPreviewGenerator
