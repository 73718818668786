import React from "react"
import clsx from "clsx"

import styles from "./Table.module.scss"

export interface TableProps {
  hideBorder?: boolean
  hideHorizontalOffset?: boolean
  fixedLayout?: boolean
  className?: string
  spacing?: "big" | "medium" | "small"
}

export const Table: React.FC<TableProps> = ({
  children,
  hideBorder = false,
  hideHorizontalOffset = false,
  fixedLayout = false,
  className,
  spacing = "medium",
}) => (
  <table
    className={clsx(
      styles.root,
      { [styles.withBorder]: !hideBorder },
      { [styles.hideXOffset]: hideHorizontalOffset },
      { [styles.layoutFixed]: fixedLayout },
      styles[`gutter-${spacing}`],
      className
    )}
  >
    {children}
  </table>
)

export default Table
