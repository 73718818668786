import React from "react"
import { observer } from "mobx-react-lite"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

import { useStore } from "@store/index"
import { MetricNameType } from "@framework/types/metrics"
import { MetricItem } from "./MetricItem"

interface MetricsListProps {}

const MetricsList: React.FC<MetricsListProps> = observer(() => {
  const {
    reports: { settings },
  } = useStore()

  const list = settings.editableSequence.sequence
  return (
    <DragDropContext
      onDragEnd={(e) => {
        const sourceIdx = e.source.index
        const destinationIdx = e.destination?.index
        if (sourceIdx != null && destinationIdx != null)
          settings.editableSequence.swipeMetrics(sourceIdx, destinationIdx)
      }}
    >
      <Droppable droppableId="METRICS">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {list.map((id, idx) => (
              <Draggable draggableId={id} index={idx} key={id}>
                {(provided, { isDragging }) => (
                  <MetricItem
                    id={id as MetricNameType}
                    provided={provided}
                    isDragging={isDragging}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
})

export default MetricsList
