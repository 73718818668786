import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { reportLevelDescription } from "@framework/constants/report"
import { PlatformReport } from "@store/reports/types"
import { useTableContext } from "./TableContext"
import AccountReportList from "./AccountReportList"
import CampaignTypeRowList from "./CampaignTypeRowList"
import MetaCampaignReportList from "./MetaCampaignReportList"

type PlatformRowProps = {
  data: PlatformReport
  mapper: MapperType[]
}

export const PlatformRow: React.FC<PlatformRowProps> = observer(
  ({ data: report, mapper }) => {
    const context = useTableContext()

    const renderChild = () => {
      if (context.viewType === "Platform" && report.accountReportIds != null) {
        return (
          <AccountReportList list={report.accountReportIds} mapper={mapper} />
        )
      }

      if (context.viewType === "Account") {
        if (
          report.platformName === "googleAds" &&
          report.campaignGroupReportIds?.length
        ) {
          return (
            <CampaignTypeRowList
              list={report.campaignGroupReportIds}
              mapper={mapper}
            />
          )
        }

        if (
          report.platformName === "metaAds" &&
          report.metaCampaignsReportIds?.length
        ) {
          return (
            <MetaCampaignReportList
              list={report.metaCampaignsReportIds}
              mapper={mapper}
            />
          )
        }
      }

      return null
    }

    return (
      <ClosableRow
        id={report.id}
        component={
          <TypedRow
            label={report?.label ?? "Platform"}
            data={report}
            leftTip={<TypedTip data={reportLevelDescription.platform} />}
            mapper={mapper}
          />
        }
      >
        {renderChild()}
      </ClosableRow>
    )
  }
)

export default PlatformRow
