import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import NumberFormat, { NumberFormatValues } from "react-number-format"
import { useAlert } from "react-alert"

import { preciseMoneyFormatter } from "@services/utils"
import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import TextField from "@components/ui/TextField/TextField"
import { useStore } from "@store/index"
import {
  UpdateAdGroupRequestType,
  UpdateKeywordRequestType,
} from "@store/manage-campaigns/manage-campaigns"
import { BaseCellProps } from "@components/ui/WaterfallTable/types"
import Label from "@components/ui/Label/Label"
import ModalTemplate from "@components/ui/Modal/ModalTemplate"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import TableButton from "./TableButton"

export interface CpcFormProps extends BaseCellProps {
  value: string
  entityType: "adGroup" | "keyword"
  adgroupName: string
  adGroupId: string
  keywordId: string
  editable: boolean
}

export const CpcForm: React.FC<CpcFormProps> = observer(
  ({ value, entityType, adGroupId, keywordId, adgroupName, editable }) => {
    const alert = useAlert()

    const [open, setOpen] = useState(false)
    const [cpcAmount, setCpcAmount] = useState(value)

    const { manageCampaignStore, accountStore } = useStore()
    const { updateLoading, updateAdGroup, updateKeyword } = manageCampaignStore
    const { accountId } = accountStore

    const handleToggleVisibility = (e: any) => {
      setOpen((value) => !value)
      e.stopPropagation()
    }

    const handleSubmit = async () => {
      if (accountId == null) return

      const data: UpdateAdGroupRequestType | UpdateKeywordRequestType = {
        cpcAmount: Number.parseFloat(cpcAmount) || 0,
        type: "MAX_CPC",
      }
      const promise =
        (entityType === "adGroup" &&
          updateAdGroup(accountId, adGroupId, { ...data, adgroupName })) ||
        (entityType === "keyword" &&
          updateKeyword(accountId, adGroupId, keywordId, data)) ||
        null

      const error = await promise

      if (error) {
        alert.error(
          <AlertMessage
            title="Faield to update Max CPC value"
            description={error}
          />
        )
        return
      }

      alert.success("Max CPC value was successfully updated")

      setOpen(false)
    }

    const formattedValue = preciseMoneyFormatter(value)

    return (
      <span>
        {editable ? (
          <TableButton onClick={handleToggleVisibility}>
            {formattedValue}
          </TableButton>
        ) : (
          formattedValue
        )}
        <Modal isOpen={open} hideHeader>
          <ModalTemplate.Header title="Change Max CPC $" />

          <ModalTemplate.Body>
            <Label label="Amount">
              <NumberFormat
                thousandSeparator
                customInput={TextField}
                value={cpcAmount}
                onValueChange={(values: NumberFormatValues) =>
                  setCpcAmount(values.value)
                }
                prefix="$"
                name="amount"
              />
            </Label>
          </ModalTemplate.Body>

          <ModalTemplate.Footer>
            <Button color="secondary" onClick={handleToggleVisibility}>
              Cancel
            </Button>

            <Button onClick={handleSubmit} disabled={updateLoading}>
              Save
            </Button>
          </ModalTemplate.Footer>
        </Modal>
      </span>
    )
  }
)

export default CpcForm
