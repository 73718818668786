import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { SortByType } from "@framework/types/types"
import { CampaignReportDataType } from "@framework/types/account"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { sortCampaignReports } from "@pages/ManageCampaign/utils"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import { useHistory } from "react-router-dom"
import config from "@root/config"
import { useSelectedAdGroupsContext } from "@framework/prototypes/SelectedAdGroupsContext"
import AdGroupRow from "./AdGroupRow"
import AssetGroupRow from "./AssetGroupRow"

type CampaignRowProps = {
  data: CampaignReportDataType
  sortBy: SortByType
  mapper: MapperType[]
  className?: string
}

export const CampaignRow: React.FC<CampaignRowProps> = observer(
  ({ mapper, data, sortBy, className }) => {
    const history = useHistory()
    const id = data.Campaign.Id

    const adGroupsContext = useSelectedAdGroupsContext()

    const statusNode = data.Campaign.isDraftMode
      ? (() => {
          const editNode = config.HIDE_PMAX ? null : (
            <Icon name="pen" onClick={() => history.push(`./campaign/${id}`)} />
          )
          return (
            <>
              <Chip color="secondary" endIcon={editNode}>
                Draft
              </Chip>
            </>
          )
        })()
      : null

    const editable = data.Campaign.Status !== "REMOVED"

    const sortedAdGroups = React.useMemo(() => {
      if (!data.Adgroups?.length) return null

      const filtered = data.Adgroups.filter((it) =>
        adGroupsContext.selectedAdGroups.includes(it.Id.toString())
      )

      return sortCampaignReports(filtered, sortBy)
    }, [data.Adgroups, sortBy, adGroupsContext.selectedAdGroups])

    return (
      <ClosableRow
        id={id}
        emptyChildMessage="No Ad Groups found"
        className={className}
        component={
          <TypedRow
            label={data.Campaign.Name}
            status={statusNode}
            data={{
              ...data.Campaign,
              CampaignId: id,
              type: "campaign",
              editable,
            }}
            leftTip={<TypedTip data={campaignEntityDescription.campaign} />}
            mapper={mapper}
          />
        }
      >
        {sortedAdGroups?.map((props) => {
          const Component =
            data.Campaign.advertisingChannelType === "SEARCH"
              ? AdGroupRow
              : AssetGroupRow

          return (
            <Component
              id={props.Id}
              data={props}
              mapper={mapper}
              adGroupId={props.Id}
              editable={editable && props.Status !== "REMOVED"}
              sortBy={sortBy}
              key={props.Id}
            />
          )
        })}
      </ClosableRow>
    )
  }
)

export default CampaignRow
