import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Option, SortByType } from "@framework/types/types"
import Stack from "@components/ui/Stack/Stack"
import NoData from "@components/ui/NoData/NoData"
import { Button } from "@components/ui/Button"
import { useHistory } from "react-router-dom"
import config from "@root/config"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import OnHoverTooltip from "@components/ui/Tooltip/TooltipContainer/TooltipContainer"
import ComicBubble from "@components/ui/Tooltip/ComicBubble/ComicBubble"
import RowContainer from "./RowContainer"
import KeywordsCategoryRow from "./CategoryRow"
import Row from "./Row"

import styles from "./Table.module.scss"

export type TableMapper = {
  width?: string | number
  sortable?: boolean
  description?: string
} & Option

export interface TableHeaderProps {
  dataMapper: TableMapper[]
  onSort?: (value: string) => void
  sortBy: SortByType
}

export const TableHeader: React.FC<TableHeaderProps> = observer(
  ({ dataMapper, onSort, sortBy }) => (
    <div className={styles.header}>
      {dataMapper.map(({ label, sortable, value, description, width }) => {
        const style: React.CSSProperties = {
          width: width != null ? "auto" : "100%",
          minWidth: width,
          maxWidth: width,
        }
        return (
          <OnHoverTooltip
            style={style}
            placement="bottom"
            disabled={description == null}
            toolTip={(props) => (
              <ComicBubble justify="center" {...props}>
                {description}
              </ComicBubble>
            )}
            key={value}
          >
            <Typography
              onClick={() => sortable && onSort?.(value)}
              className={clsx(styles.column, styles.headerColumn)}
              type="subhead1"
              color="gray"
            >
              {label.toUpperCase()}
              {sortable && (
                <span className={styles.sortArrow}>
                  <Icon
                    name={sortBy.value === value ? "arrow-down" : "none"}
                    rotateAngle={sortBy.direction ? 180 : 0}
                  />
                </span>
              )}
            </Typography>
          </OnHoverTooltip>
        )
      })}
    </div>
  )
)

export interface TableBodyProps {
  accountId?: number | null
  dataMapper: TableMapper[]
  onClick: (accountId: number, keywordId: number) => void
  onChange: (keywordId: number, name: string, value: any) => void
}

export const TableBody: React.FC<TableBodyProps> = observer(
  ({ accountId, dataMapper, onClick, onChange }) => {
    const history = useHistory()

    const opportunitiesStore = useOpportunitiesStore()
    const {
      recommendedKeywords,
      sortedKeywords,
      calculator: { ctr },
    } = opportunitiesStore

    return (
      <div className={styles.list}>
        {!sortedKeywords.length ? (
          <NoData>
            {!recommendedKeywords.length
              ? "No attributes found"
              : "No attributes by filter"}
          </NoData>
        ) : (
          sortedKeywords.map((keyword, idx) => (
            <RowContainer
              key={keyword.id}
              variant="item"
              content={
                <Row
                  idx={idx}
                  data={keyword}
                  ctr={ctr}
                  mapper={dataMapper}
                  onStatusClick={onClick}
                  onChange={onChange}
                  accountId={accountId}
                />
              }
            >
              <RowFooter
                topKeywords={keyword.attachedKeywords.keywords}
                total={keyword.attachedKeywords.total}
                controlSocket={
                  config.ENABLE_OPPORTUNITIES_UPDATE ? (
                    <Button
                      onClick={() =>
                        history.push(
                          `${history.location.pathname}/insights/${keyword.id}`
                        )
                      }
                    >
                      See more insights
                    </Button>
                  ) : undefined
                }
              />
            </RowContainer>
          ))
        )}
      </div>
    )
  }
)

export const CategoryTableBody: React.FC<TableBodyProps> = observer(
  ({ accountId, dataMapper, onClick, onChange }) => {
    const history = useHistory()

    const opportunitiesStore = useOpportunitiesStore()
    const {
      recommendedKeywords,
      sortedCategories,
      calculator: { ctr },
    } = opportunitiesStore

    return (
      <div className={styles.list}>
        {!sortedCategories.length ? (
          <NoData>
            {!recommendedKeywords.length
              ? "No attributes found"
              : "No attributes by filter"}
          </NoData>
        ) : (
          sortedCategories.map((group) => (
            <RowContainer
              key={group.id}
              variant="group"
              content={
                <KeywordsCategoryRow
                  data={group}
                  ctr={ctr}
                  mapper={dataMapper}
                />
              }
            >
              {group.keywords.length
                ? group.keywords.map((keyword, idx) => (
                    <RowContainer
                      key={keyword.id}
                      variant="item"
                      content={
                        <Row
                          idx={idx}
                          data={keyword}
                          ctr={ctr}
                          mapper={dataMapper}
                          onStatusClick={onClick}
                          onChange={onChange}
                          accountId={accountId}
                        />
                      }
                    >
                      <RowFooter
                        topKeywords={keyword.attachedKeywords.keywords}
                        total={keyword.attachedKeywords.total}
                        controlSocket={
                          config.ENABLE_OPPORTUNITIES_UPDATE ? (
                            <Button
                              onClick={() =>
                                history.push(
                                  `${history.location.pathname}/insights/${keyword.id}`
                                )
                              }
                            >
                              See more insights
                            </Button>
                          ) : undefined
                        }
                      />
                    </RowContainer>
                  ))
                : null}
            </RowContainer>
          ))
        )}
      </div>
    )
  }
)

interface RowFooterProps {
  controlSocket?: React.ReactNode
  topKeywords: string[]
  total?: number
}

const RowFooter: React.FC<RowFooterProps> = React.memo(
  ({ topKeywords, total, controlSocket }) => {
    const content = topKeywords.length ? topKeywords.join(" • ") : null
    const otherCount = (total ?? topKeywords.length) - topKeywords.length
    return (
      <Stack direction="row" gutter="32" className={styles.rowFooter}>
        <Stack direction="row">
          <Typography color="gray" className={styles.keywordLabel}>
            Top keywords:
          </Typography>
          <Typography inline>
            {content != null ? (
              <>
                {content}
                {otherCount > 0 && (
                  <Typography
                    style={{ whiteSpace: "nowrap" }}
                    type="inherit"
                    color="primary100Color"
                    inline
                  >
                    {" +"}
                    {otherCount}
                    {" More Keywords"}
                  </Typography>
                )}
              </>
            ) : (
              "-"
            )}
          </Typography>
        </Stack>
        {controlSocket}
      </Stack>
    )
  }
)
