import React, { useEffect, useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import LineChart, {
  LineDataType,
} from "@components/ui/Charts/LineChart/LineChart"
import { useStore } from "@store/index"
import Box from "@components/ui/Box/Box"
import { darkColors } from "@components/ui/Charts/utils"
import makePerformanceChartConfig, {
  makePerformanceChartYAxisConfig,
  periodicityToScaleUnit,
} from "@components/ui/Charts/LineChart/config"
import { CampaignGroup, Platform } from "@framework/constants/report"
import { initArrayByLength } from "@utils/numberUtils"
import Loader from "@components/ui/Loader/Loader"
import { periodLength } from "@utils/date"
import Header from "./Header"
import ChartHeader from "./ChartHeader"
import { extractPlatformReportDataset, extractTSPRData } from "./utils"

import styles from "./index.module.sass"

type SpendChartProps = {
  className?: string
}

const SpendChart: React.FC<SpendChartProps> = observer(({ className }) => {
  const {
    reports: {
      mainFilter,
      filter,
      currPeriodPerformance: currPerf,
      prevPeriodPerformance: prevPerf,
    },
  } = useStore()

  const { accounts, campaignTypes, platforms, currPeriod, prevPeriod } =
    mainFilter.activeFilter
  const { activePeriodicity, activeMetric } = filter

  const accountIds = useMemo(() => accounts.map(Number), [accounts])

  useEffect(() => {
    if (!mainFilter.isActiveFilterValid) return
    currPerf.load(
      currPeriod.range,
      activePeriodicity,
      accountIds,
      campaignTypes as CampaignGroup[],
      platforms as Platform[]
    )
  }, [
    accountIds,
    activePeriodicity,
    campaignTypes,
    currPerf,
    currPeriod.range,
    platforms,
    mainFilter.isActiveFilterValid,
  ])

  useEffect(() => {
    if (!mainFilter.isActiveFilterValid) return
    prevPerf.load(
      prevPeriod.range,
      activePeriodicity,
      accountIds,
      campaignTypes as CampaignGroup[],
      platforms as Platform[]
    )
  }, [
    accountIds,
    activePeriodicity,
    campaignTypes,
    prevPerf,
    prevPeriod.range,
    platforms,
    mainFilter.isActiveFilterValid,
  ])

  const keys = useMemo(() => {
    const totalPoints = periodLength(
      currPeriod.range,
      periodicityToScaleUnit(activePeriodicity)
    )
    return initArrayByLength(totalPoints)
  }, [activePeriodicity, currPeriod.range])

  const currDataList = platforms.map(
    (pl) => currPerf.reports.get(pl as Platform)?.data
  )

  const prevDataList = platforms.map(
    (pl) => prevPerf.reports.get(pl as Platform)?.data
  )

  const currData: LineDataType = useMemo(
    () => ({
      dashed: false,
      dataset: platforms.map((platform) => {
        const instance = currPerf.reports.get(platform as Platform)
        return extractPlatformReportDataset(
          platform,
          activeMetric,
          instance?.data
            ? extractTSPRData(instance.data, activeMetric, keys)
            : []
        )
      }),
    }),
    [activeMetric, ...currDataList]
  )

  const prevData: LineDataType = useMemo(
    () => ({
      dashed: true,
      dataset: platforms.map((platform) => {
        const instance = prevPerf.reports.get(platform as Platform)
        return extractPlatformReportDataset(
          platform,
          activeMetric,
          instance?.data
            ? extractTSPRData(instance.data, activeMetric, keys)
            : [],
          `(${prevPeriod.label})`
        )
      }),
    }),
    [activeMetric, ...prevDataList]
  )

  const data: LineDataType[] = useMemo(() => {
    const res = [currData]
    if (mainFilter.isComparisonEnabled) res.push(prevData)
    return res
  }, [mainFilter.isComparisonEnabled, currData, prevData])

  const chartConfig = useMemo(
    () =>
      makePerformanceChartConfig(
        currPerf.labels,
        activePeriodicity,
        {
          y: makePerformanceChartYAxisConfig(activeMetric, "left"),
        },
        "unit"
      ),
    [currPerf.labels, activePeriodicity, activeMetric]
  )

  const isLoading = currPerf.isLoading || prevPerf.isLoading

  return (
    <Box className={clsx(styles.root, className)}>
      <Header />

      <ChartHeader />

      <Loader isLoading={isLoading} />

      <LineChart
        lineData={data}
        labels={currPerf.labels}
        colors={darkColors}
        options={chartConfig}
      />
    </Box>
  )
})

export default SpendChart
