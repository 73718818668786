/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"

import styles from "./Table.module.scss"

type HeaderProps = {
  selected?: boolean
  total?: number
  selectedCount?: number
  className?: string
  rootClassName?: string
  onSelect?: () => void
}

const Header: React.FC<HeaderProps> = ({
  className,
  rootClassName,
  selected = false,
  selectedCount = 0,
  total,
  children,
  onSelect,
}) => (
  <Typography
    className={clsx(styles.header, rootClassName)}
    type="caption3"
    color="black60Color"
    upperCase
  >
    <CheckBox
      checked={selected}
      partial={total != null ? total > selectedCount : undefined}
      onChange={onSelect}
    />

    <Typography
      type="inherit"
      color="inherit"
      className={clsx(styles.container, className)}
    >
      {children}
    </Typography>

    <Typography />
  </Typography>
)

export default Header
