import React, { useCallback, useEffect, useRef, useState } from "react"

import Button from "@components/ui/Button/Button"
import ContextMenu from "@components/ui/ContextMenu/ContextMenu"
import { NavOption } from "@components/ui/Tabs/types"
import { useStore } from "@store/index"

import styles from "./RuleActions.module.scss"

const options: NavOption[] = [
  {
    label: "Edit",
    icon: "pen",
    value: "edit",
  },
  {
    label: "Delete",
    icon: "trash",
    value: "delete",
  },
]

export interface RuleActionsPropsType {
  ruleId: number
}

export const RuleActions: React.FC<RuleActionsPropsType> = ({ ruleId }) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const rootRef = useRef<HTMLDivElement>(null)

  const { rulesStore, accountStore } = useStore()

  const { setRuleToEdit, removeRule } = rulesStore
  const { accountId } = accountStore

  const handleOpenMenu = useCallback(() => {
    setOpen((prev) => !prev)
  }, [setOpen])

  const handleAction = useCallback(
    (option: NavOption) => {
      if (option.value === "edit") setRuleToEdit(ruleId)
      if (option.value === "delete" && accountId) removeRule(accountId, ruleId)
      setOpen(false)
    },
    [setOpen, ruleId, accountId, setRuleToEdit]
  )

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (rootRef.current && rootRef.current.contains(e.target as Node)) {
        return
      }
      setOpen(false)
    },
    [rootRef]
  )

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [handleClick])

  return (
    <div className={styles.root} ref={rootRef}>
      <Button
        color={isOpen ? "secondary" : "primary"}
        startIcon={{ name: "three-dots" }}
        variant="ghost"
        onClick={handleOpenMenu}
        fit
      />
      {isOpen && (
        <ContextMenu
          options={options}
          onClick={handleAction}
          className={styles.contextMenu}
        />
      )}
    </div>
  )
}
