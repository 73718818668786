import { observable } from "mobx"

import QueriesStore from "./queries.store"
import ProductCampaignStore from "./product-campaigns.store"

class TopSearchQueriesStore {
  // INJECTIONS

  @observable campaignStore: ProductCampaignStore

  @observable queriesStore: QueriesStore

  // CONSTRUCTOR

  constructor(injections: {
    campaignStore: ProductCampaignStore
    queriesStore: QueriesStore
  }) {
    this.campaignStore = injections.campaignStore
    this.queriesStore = injections.queriesStore
  }

  // STATE
}

export default TopSearchQueriesStore
