import React from "react"
import { observer } from "mobx-react-lite"

import { printDateRangeWithDiff, printDateRange } from "@utils/date"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import DatePeriodOption from "@components/ui/DatePicker/DatePeriodOption"
import Select from "@components/ui/DropDown"
import { mbNoData } from "@services/utils"
import BaseField from "@components/ui/TextField/BaseField"
import Typography from "@components/ui/Typography/Typography"

export interface TimePeriodPickerProps {
  maxDate?: Date
  minDate?: Date
  range: DateRangeOption
  prev: DateRangeOption
  rangeOptions: DateRangeOption[]
  prevOptions: DateRangeOption[]
  onRangeChange?: (option: DateRangeOption) => void
  onPrevChange?: (option: DateRangeOption) => void
}

export const TimePeriodPicker: React.FC<TimePeriodPickerProps> = observer(
  ({
    range: currPeriod,
    prev: prevPeriod,
    rangeOptions,
    prevOptions,
    minDate,
    maxDate,
    onRangeChange,
    onPrevChange,
  }) => (
    <Select.DropDown
      keepOpened
      target={(isActive) => (
        <BaseField active={isActive}>
          <Typography type="inherit">
            {currPeriod?.range
              ? mbNoData(currPeriod?.range, printDateRange)
              : "Select date..."}
          </Typography>
        </BaseField>
      )}
    >
      <Select.Box>
        <Select.Group>
          <DatePeriodOption
            title="Date range"
            subtitle={
              currPeriod ? printDateRangeWithDiff(currPeriod.range) : "-"
            }
            value={currPeriod?.value}
            onSelect={(v) => onRangeChange?.(v)}
            options={rangeOptions}
            minDate={minDate}
            maxDate={maxDate}
          >
            {mbNoData(currPeriod?.label)}
          </DatePeriodOption>
        </Select.Group>
        <Select.Group>
          <DatePeriodOption
            title="Compare to"
            subtitle={
              prevPeriod ? printDateRangeWithDiff(prevPeriod.range) : "-"
            }
            value={prevPeriod?.value}
            onSelect={(v) => onPrevChange?.(v)}
            options={prevOptions}
          >
            {mbNoData(prevPeriod?.label)}
          </DatePeriodOption>
        </Select.Group>
      </Select.Box>
    </Select.DropDown>
  )
)

export default TimePeriodPicker
