import React from "react"

import Typography from "@components/ui/Typography/Typography"
import Trend from "@components/ui/Typography/Trend/Trend"
import { percentageFormatter } from "@services/utils"
import OnClickTooltip from "@components/ui/Tooltip/TooltipContainer/OnClickTooltipContainer"
import Icon from "@components/ui/Icon/Icon"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import { MetricNameType } from "@framework/types/metrics"
import { getMetricDescriptor } from "@framework/constants/metrics"

import styles from "./MetricsCard.module.sass"

export type MetricsCardProps = {
  name: MetricNameType
  amount?: number | string
  trend?: number
}

const MetricsCard: React.FC<MetricsCardProps> = ({
  name,
  amount = 0,
  trend = 0,
}) => {
  const metric = getMetricDescriptor(name)
  const trendFormatter = metric.trendFormat ?? positiveTransformDirection
  return (
    <div className={styles.root}>
      <Typography type="h3" color="gray" className={styles.title}>
        {metric.label}
        <OnClickTooltip
          placement="bottom"
          toolTip={
            <Typography className={styles.toolTip}>
              {metric.description}
            </Typography>
          }
        >
          <Typography color="lightGray">
            <Icon name="information-solid" />
          </Typography>
        </OnClickTooltip>
      </Typography>
      <Typography type="h1" color="dark" bold>
        {metric.formatter(amount)}
      </Typography>
      <Trend type="h5" {...trendFormatter(trend ?? 0)}>
        {percentageFormatter(trend)}
      </Trend>
    </div>
  )
}

export default MetricsCard
