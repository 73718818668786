import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom"
import capitalize from "lodash/capitalize"

import SettingsLayout from "@root/layouts/SettingsLayout/SettingsLayout"
import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import PrivateRoute from "@components/security/PrivateRoute"
import { NavOption } from "@components/ui/Tabs/types"
import ProfileSettings from "./ProfileSettings/ProfileSettings"
import CompanySetup from "./CompanySetup/CompanySetup"
import ChangePassword from "./ChangePassword/ChangePassword"
import AccessSettings from "./AccessSettings/AccessSettings"
import ContactUs from "./ContactUs/ContactUs"
import Subscription from "./Subscription/Subscription"
import UserManagement from "./UserManagement/UserManagement"
import SidebarMenu, {
  companySettingsOptions,
  otherOptions,
  personalSettingsOptions,
} from "./components/SidebarMenu/SidebarMenu"

import styles from "./Settings.module.scss"

export const SettingsMenu: React.FC = observer(() => {
  const location = useLocation()
  const history = useHistory()
  const {
    authStore: { fullName, user, isAdmin },
  } = useStore()

  const menuOptions: NavOption[] = useMemo(
    () => [
      ...personalSettingsOptions,
      ...(isAdmin ? companySettingsOptions : []),
      ...otherOptions,
    ],
    [isAdmin]
  )

  const activeRoute = useMemo(
    () =>
      menuOptions.find(
        ({ value }) =>
          !!matchPath(location.pathname, { path: `/settings/${value}` })
      ),
    [location.pathname, menuOptions]
  )

  const getNavHandler = (path: string) => () => {
    history.replace(`/settings/${path}`)
  }

  useEffect(() => {
    if (!activeRoute) getNavHandler(menuOptions[0].value)()
  }, [activeRoute, menuOptions])

  const accessLevelLabel = !isAdmin
    ? user?.level != null
      ? capitalize(user?.level)
      : "Unknown"
    : "Full"

  return (
    <SettingsLayout>
      <Stack gutter="24" align="stretch">
        <div className={styles.header}>
          <Stack direction="row" gutter="0">
            <Typography type="h2" color="darkBlue" weight="bold">
              {fullName} /&nbsp;
            </Typography>
            <Typography type="h2" weight="bold">
              {activeRoute?.label}
            </Typography>
          </Stack>
          <Stack direction="row" gutter="0">
            <Typography type="h4" color="gray">
              {accessLevelLabel} access level • {user?.Email}
            </Typography>
          </Stack>
        </div>

        <Stack direction="row" gutter="20">
          <Box className={styles.listBox}>
            <SidebarMenu activeRoute={activeRoute} />
          </Box>
          <Box className={styles.bodyBox}>
            <Switch>
              <Route path="/settings/profile" component={ProfileSettings} />
              <Route path="/settings/password" component={ChangePassword} />
              {/* <Route path="/settings/access" component={AccessSettings} /> */}

              {/* <PrivateRoute
                path="/settings/company"
                component={CompanySetup}
                accessFlag={isAdmin}
              /> */}
              {/* <PrivateRoute
                path="/settings/subscription"
                component={Subscription}
                accessFlag={isAdmin}
              /> */}
              <PrivateRoute
                path="/settings/management"
                component={UserManagement}
                accessFlag={isAdmin}
              />

              <Route path="/settings/support" component={ContactUs} />
            </Switch>
          </Box>
        </Stack>
      </Stack>
    </SettingsLayout>
  )
})

export default SettingsMenu
