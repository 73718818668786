/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import { useStore } from "@store/index"
import Typography from "@components/ui/Typography/Typography"
import Popover from "@components/ui/Popover/Popover"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import { moneyWithSignFormatter } from "./utils"
import PlannedAdSpend from "./components/PlannedAdSpend/PlannedAdSpend"

import styles from "./Checkbook.module.scss"

type CheckbookProps = {
  className?: string
}

const Checkbook: React.FC<CheckbookProps> = observer(({ className }) => {
  const {
    clientCheckbookStore: {
      loadingError,
      initialPlanedAdSpend,
      additionalAdSpend,
      totalAdSpend,
      actualAdSpend,
      remainAdSpend,
    },
  } = useStore()

  return (
    <div className={clsx(styles.root, className)}>
      <Typography type="h1" color="primary" className={styles.title} bold>
        Checkbook
      </Typography>
      <ServiceDownInterceptor />

      {loadingError && (
        <Typography type="h3" color="red" className={styles.empty}>
          {loadingError}
        </Typography>
      )}

      <PlannedAdSpend />

      <div className={styles.results}>
        <Popover
          key="initial"
          title="Initial Planned Ad Spend"
          subTitle={moneyWithSignFormatter(initialPlanedAdSpend)}
        />
        <Popover
          key="additional"
          title="Additional Ad Spend"
          subTitle={moneyWithSignFormatter(additionalAdSpend, true)}
        />
        <Popover
          key="total_planed"
          title="Total Planned Ad Spend"
          subTitle={moneyWithSignFormatter(totalAdSpend)}
        />
        <Popover
          key="actual"
          title="Actual Ad Spend to Date"
          subTitle={moneyWithSignFormatter(actualAdSpend)}
        />
        <Popover
          key="remaining"
          title="Remaining Ad Spend"
          subTitle={moneyWithSignFormatter(remainAdSpend)}
        />
      </div>
    </div>
  )
})

export default Checkbook
