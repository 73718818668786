import { ProductStatus } from "@framework/types/account"
import { BadgeOption } from "../components/Status/StatusBadge"

const statusMapper: Record<ProductStatus, BadgeOption> = {
  published: { color: "default", label: "Published" },
  new: { color: "success", label: "New" },
  updated: { color: "info", label: "Updated" },
  error: { color: "error", label: "Error" },
}

export const productStatusMapper = (status: ProductStatus | string) =>
  statusMapper[status as ProductStatus] ?? statusMapper.published

export default null
