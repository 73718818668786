import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import RulesList from "./components/RulesList/RulesList"
import EditRule from "./components/EditRule/EditRule"

import styles from "./Rules.module.scss"

type RulesProps = {
  className?: string
}

const Rules: React.FC<RulesProps> = observer(({ className }) => {
  const { rulesStore } = useStore()
  const { isEdit, setRuleToEdit } = rulesStore

  useEffect(() => () => setRuleToEdit(), [])

  return (
    <div className={clsx(styles.root, className)}>
      {isEdit ? <EditRule /> : <RulesList />}
    </div>
  )
})

export default Rules
