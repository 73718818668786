import React from "react"
import { useField } from "formik"
import { observer } from "mobx-react-lite"
import ReactSelect from "react-select/creatable"

import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"

import styles from "./FormTagSelector.module.scss"

interface Props {
  name: string
  unit?: string
  placeholder?: string
}

export const FromTagsSelector: React.FC<Props> = observer(
  ({ name, unit = "item", placeholder }) => {
    const [input, meta, handlers] = useField(name)
    return (
      <ReactSelect
        isMulti
        className={styles.root}
        classNamePrefix="selector"
        name={name}
        value={input.value}
        components={{
          DropdownIndicator: Arrow,
        }}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        isClearable
        formatCreateLabel={(value) => `Add new ${unit} "${value}"`}
        onChange={(value) => {
          handlers.setValue(value)
        }}
        onBlur={input.onBlur}
      />
    )
  }
)

export default FromTagsSelector

const Arrow: React.FC<{ isFocused: boolean }> = ({ isFocused, ...rest }) => (
  <IconButton {...rest}>
    <Icon name="arrow-down" rotateAngle={isFocused ? 180 : 0} />
  </IconButton>
)
