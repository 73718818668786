import React from "react"
import clsx from "clsx"

import { ButtonColorType, ButtonSizeType, ButtonVariantType } from "./types"

import styles from "./BasicButton.module.scss"

export interface BasicButtonProps extends React.HTMLAttributes<HTMLElement> {
  variant?: ButtonVariantType
  color?: ButtonColorType
  size?: ButtonSizeType
  disabled?: boolean
  className?: string
  as?: "button" | "a"
  type?: "button" | "submit"
  target?: string
  href?: string
  download?: boolean
  onClick?: React.MouseEventHandler
}

export const BasicButton: React.FC<BasicButtonProps> = ({
  children,
  disabled = false,
  variant = "contained",
  color = "default",
  size = "medium",
  className,
  as: Tagname = "button",
  target,
  href,
  download = false,
  type = "button",
  onClick,
  ...rest
}) => {
  const classNames = clsx(
    styles.root,
    styles[variant],
    styles.color,
    styles[`color-${color}`],
    styles[`size-${size}`],
    {
      [styles.disabled]: disabled,
    },
    className
  )
  return (
    <Tagname
      href={href}
      className={classNames}
      onClick={onClick}
      type={type}
      disabled={disabled}
      target={target}
      role="button"
      download={download}
      {...rest}
    >
      {children}
    </Tagname>
  )
}

export default BasicButton
