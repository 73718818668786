import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { TrendValue } from "@components/ui/DataChangeWidget/DataChangeWidget"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import Typography from "@components/ui/Typography/Typography"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import {
  PMaxProductReportType,
  PMaxProductReportVariantType,
} from "@framework/types/account"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import Row from "./Table/Row"
import Column from "./Table/Column"

import styles from "./InsightTable.module.scss"

export const ProductRow: React.FC<{
  data: PMaxProductReportType | PMaxProductReportVariantType
  mapper: MetricNameType[]
  open?: boolean
  onOpen?: React.MouseEventHandler
  highlighted?: boolean
  lineClamp?: number
}> = observer(
  ({
    lineClamp = 10,
    highlighted,
    data: item,
    mapper: columns,
    open,
    onOpen,
  }) => (
    <Row
      className={clsx(styles.productRow, { [styles.highlighted]: highlighted })}
    >
      <Column key="label">
        {onOpen != null && (
          <IconButton color={open ? "primary" : "secondary"} onClick={onOpen}>
            <Icon name="arrow-right" rotateAngle={open ? 90 : 0} />
          </IconButton>
        )}
      </Column>

      <Column key="label">
        <Tooltip text={item.Title} lineClamp={lineClamp}>
          {item.Title}
        </Tooltip>
      </Column>

      {columns.map((metricName) => {
        const metricValue = item[metricName]
        const value = metricValue?.Value ?? 0
        const delta = metricValue?.Change ?? 0
        const desc = getMetricDescriptor(metricName)
        return (
          <Column key={metricName}>
            <Typography className={styles.column}>
              <Typography>{desc.formatter(value) ?? value}</Typography>
              <TrendValue
                className={styles.trend}
                value={Math.ceil(delta * 100)}
                arrowAfter
                metricDir={(desc.trendFormat ?? positiveTransformDirection)(
                  delta
                )}
              />
            </Typography>
          </Column>
        )
      })}
    </Row>
  )
)

export default ProductRow
