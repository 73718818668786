import { MetricNameType } from "@framework/types/metrics"

export const metricsToDisplay: MetricNameType[] = [
  "Cost",
  "Impressions",
  "Clicks",
  "AverageCpc",
  "Cpm",
  "Ctr",
  "Conversions",
  "CostConv",
  "ConversionRate",
  "AOV",
  "Revenue",
  "Roas",
]

export default null
