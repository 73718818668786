/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import clsx from "clsx"
import Typography from "@components/ui/Typography/Typography"

import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import AlertPopupTemplate from "@components/ui/AlertPopup/AlertTemplate/AlertPopupTemplate"
import styles from "./DropZone.module.scss"

interface DocumentDropZoneProps {
  requirement?: string
  onDrop: (files: File[]) => void
  className?: string
  disabled?: boolean
  onReject?: () => any
  accepted?: DropzoneOptions["accept"]
  maxFiles?: DropzoneOptions["maxFiles"]
  validator?: DropzoneOptions["validator"]
}

const DocumentDropZone: React.FC<DocumentDropZoneProps> = ({
  onDrop,
  requirement = "",
  className = "",
  disabled = false,
  accepted,
  maxFiles,
  validator,
}) => {
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      disabled,
      accept: accepted,
      validator,
      maxFiles,
    })
  const containerClasses = clsx(
    styles.container,
    {
      [styles.active]: isDragActive,
      [styles.disabled]: disabled,
    },
    className
  )

  const fileRejectionItems = fileRejections.map(({ file, errors }, i) => (
    <div className={styles.rejectedFilesList} key={`key-${i + 1}`}>
      <Typography type="body1">
        {file.name} - {file.size} bytes
      </Typography>
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </div>
  ))

  return (
    <div {...getRootProps()} className={containerClasses}>
      <div className={styles.title}>
        <p>
          <Typography type="h3">
            {isDragActive
              ? "Drop here to attach"
              : "Click or drag file to this area"}
          </Typography>
          <Typography type="h3" color="gray">
            {requirement}
          </Typography>
        </p>
      </div>
      <input {...getInputProps()} />
      {fileRejections.length ? (
        <AlertPopupTemplate
          close={() => {}}
          style={{ margin: "10px" }}
          options={{ type: "error" }}
          message={
            <AlertMessage
              title="Upload rejected"
              description={fileRejectionItems}
            />
          }
        />
      ) : null}
    </div>
  )
}

export default DocumentDropZone
