import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import IndicatedProgressBar from "@components/ui/ProgressBar/IndicatedProgressBar/IndicatedProgressBar"
import Box from "@components/ui/Box/Box"
import { QuadSizeType } from "@components/utils/enums"
import { TabNames } from "../../CreateCampaign"

import styles from "./BuildingAccount.module.scss"

type BuildingAccountProps = {
  switchTab: (switchTab: TabNames) => void
}

const BuildingAccount: React.FC<BuildingAccountProps> = observer(
  ({ switchTab }) => {
    const [progress, setProgress] = useState(0)
    const [intervalId, setIntervalId] = useState<any>()

    const increaseProgress = () => {
      setProgress((current) => current + 1)
    }

    useEffect(() => {
      setIntervalId(setInterval(increaseProgress, 20))
    }, [])

    useEffect(() => {
      if (progress >= 100) {
        clearInterval(intervalId)
        switchTab("action")
      }
    }, [progress, intervalId, switchTab])

    return (
      <div className={styles.root}>
        <Typography type="h2" color="primary" weight="bold">
          Building Ad Words Account
        </Typography>
        <Box className={styles.progressWrapper}>
          <IndicatedProgressBar
            value={progress}
            size={QuadSizeType.small}
            className={styles.progress}
          />
        </Box>
        <Typography type="h4" color="dark">
          Building Adwords Account Structure
        </Typography>
      </div>
    )
  }
)

export default BuildingAccount
