import React from "react"
import capitalize from "lodash/capitalize"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { UserData } from "@framework/types/user"
import { mbNoData } from "@services/utils"
import { ColumnProps } from "./UserTable"

export const AccessLevelColumn: React.FC<ColumnProps<UserData>> = observer(
  ({ mapper, data: user = {} }) => {
    const access = user.permissions?.[0]?.objectType

    return (
      <Stack gutter="0" direction="row">
        <Typography>{mbNoData(access, capitalize)}</Typography>
      </Stack>
    )
  }
)

export default AccessLevelColumn
