import React from "react"
import clsx from "clsx"
import { AlertType } from "react-alert"

import Icon, { IconProps } from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import Button, { ButtonProps } from "@components/ui/Button/Button"

import styles from "./SystemMessage.module.sass"

interface AlertConfig {
  icon: IconProps["name"]
  buttonColor: ButtonProps["color"]
  colorClassName: string
}

const messageConfig: Record<AlertType, AlertConfig> = {
  success: {
    icon: "checkbox-circle",
    buttonColor: "green",
    colorClassName: styles.colorSuccess,
  },
  info: {
    icon: "information-outline",
    buttonColor: "yellow",
    colorClassName: styles.colorInfo,
  },
  error: {
    icon: "alert",
    buttonColor: "red",
    colorClassName: styles.colorError,
  },
}

export interface SystemMessageProps {
  type?: AlertType
  message: React.ReactNode
  className?: string
  cancelLabel?: string
  onCancel?: React.MouseEventHandler
  submitLabel?: string
  onSubmit?: React.MouseEventHandler
}

const SystemMessage: React.FC<SystemMessageProps> = ({
  type = "info",
  message,
  className,
  cancelLabel = "Cancel",
  submitLabel = "Try again",
  onCancel,
  onSubmit,
}) => {
  const config = messageConfig[type]
  const colorStyles: string = "mantaStyle"

  return (
    <div
      className={clsx(
        styles.alertCard,
        config.colorClassName,
        styles[colorStyles],
        className
      )}
    >
      <Icon name={config.icon} className={styles.icon} />
      <span className={styles.message}>
        <Typography type="body2" color="inherit">
          {message}
        </Typography>
      </span>
      <span className={styles.control}>
        {onCancel != null && (
          <Button variant="ghost" color={config.buttonColor} onClick={onCancel}>
            {cancelLabel}
          </Button>
        )}
        {onSubmit != null && (
          <Button
            variant="contained"
            className={styles.button}
            color={config.buttonColor}
            onClick={onSubmit}
          >
            {submitLabel}
          </Button>
        )}
      </span>
    </div>
  )
}

export default SystemMessage
