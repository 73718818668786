/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"

import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import { useStore } from "@store/index"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { campaignTypeOptions } from "@pages/Rules/components/EditRule/components/BudgetTypeRoleForm/options"
import { Condition } from "@framework/types/manageCampaign"
import CampaignRow from "./CampaignRow"

import styles from "./ConditionsTable.module.scss"

type ConditionRowProps = {
  label: string
  index: number
  data: Condition
  mapper: MapperType[]
}

const ConditionRow: React.FC<ConditionRowProps> = observer(
  ({ data, mapper, index, label }) => {
    const { rulesStore } = useStore()
    const { campaigns } = rulesStore

    const adGroupIds = useMemo(
      () =>
        !campaignTypeOptions.find(({ value }) => value === data.applied_area)
          ? data.applied_area.split(",")
          : null,
      [data]
    )

    const campaignItems = useMemo(
      () =>
        (adGroupIds &&
          campaigns?.filter(
            (campaign) =>
              campaign.Adgroups?.filter((adGroup) =>
                adGroupIds.includes(adGroup.Id.toString())
              ).length
          )) ??
        null,
      [adGroupIds, campaigns]
    )

    return (
      <ClosableRow
        id={index}
        component={
          <TypedRow
            label={label}
            data={data}
            mapper={mapper}
            containerClassName={styles.fatTable}
          />
        }
        key={data.id}
      >
        {campaignItems?.map((props, idx) => (
          <CampaignRow data={props} index={idx} adGroupIds={adGroupIds} />
        )) ?? null}
      </ClosableRow>
    )
  }
)

export default ConditionRow
