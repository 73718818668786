import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import styles from "./CardItem.module.sass"

type CardItemProps = {
  active?: boolean
  rounded?: boolean
  className?: string
  onClick?: React.MouseEventHandler
}

const CardItem: React.FC<CardItemProps> = observer(
  ({ active = false, rounded, onClick, className, children }) => (
    <button
      className={clsx(
        styles.root,
        {
          [styles.active]: active,
          [styles.rounded]: rounded,
        },
        className
      )}
      onClick={onClick}
      type="button"
      tabIndex={0}
    >
      {children}
    </button>
  )
)

export default CardItem
