/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { CampaignEntity } from "@framework/types/account"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"

type CampaignRowProps = {
  index: number
  data: CampaignEntity
  adGroupIds: string[] | null
}

const CampaignRow: React.FC<CampaignRowProps> = observer(
  ({ data, index, adGroupIds }) => {
    const adGroupItems = useMemo(
      () =>
        (adGroupIds &&
          data.Adgroups?.filter((adGroup) =>
            adGroupIds.includes(adGroup.Id.toString())
          )) ??
        null,
      [adGroupIds, data]
    )

    return (
      <ClosableRow
        id={index}
        component={
          <TypedRow
            label={data.Name}
            leftTip={<TypedTip data={campaignEntityDescription.campaign} />}
          />
        }
        key={data.Id}
      >
        {adGroupItems?.map((props, index) => (
          <ClosableRow
            id={index}
            component={
              <TypedRow
                label={props.Name}
                leftTip={<TypedTip data={campaignEntityDescription.groupAd} />}
              />
            }
            key={props.Id}
          />
        ))}
      </ClosableRow>
    )
  }
)

export default CampaignRow
