import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { Option } from "@framework/types/types"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import RowControl from "./RowControl"

import styles from "./Table.module.scss"

type RowProps = {
  options?: Option[]
  className?: string
  selected?: boolean
  onAction: (name: string) => void
  onSelect?: () => void
}

const Row: React.FC<RowProps> = observer(
  ({ className, children, options, selected, onAction, onSelect }) => {
    const {
      adCopyStore: { isEditingAvailable },
    } = useStore()

    return (
      <Box className={clsx(styles.row, { [styles.active]: selected })}>
        <CheckBox checked={selected} onChange={onSelect} />

        <Typography type="body2" className={clsx(styles.container, className)}>
          {children}
        </Typography>

        {!!options && (
          <RowControl
            disabled={!isEditingAvailable}
            options={options}
            onClick={onAction}
          />
        )}
      </Box>
    )
  }
)

export default Row
