import moment from "moment"

import { PeriodType } from "@components/forms/CheckbookForm/types"
import { initArrayByLength } from "@components/utils/numberUtils"
import { YEAR_MONTH_KEY_FORMAT } from "@framework/constants/moment-format"

export const getCheckbookSequence = (period: PeriodType) => {
  const startMoment = moment
    .utc(period.start, YEAR_MONTH_KEY_FORMAT)
    .startOf("month")
  const endMoment = moment
    .utc(period.end, YEAR_MONTH_KEY_FORMAT)
    .startOf("month")

  const months = Math.ceil(
    Math.abs(moment.duration(endMoment.diff(startMoment)).asMonths())
  )

  return initArrayByLength(months).map((_, idx) =>
    startMoment.clone().add(idx, "month").format(YEAR_MONTH_KEY_FORMAT)
  )
}

export default null
