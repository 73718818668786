import { observable, action } from "mobx"

import { ID } from "@framework/types/types"
import accountCampaignService, {
  AddAssetListingGroupFiltersDataType,
  AddAudienceRequestType,
  CreateAssetGroupRequestType,
  RemoveAssetListingGroupFiltersDataType,
  UpdateAssetGroupFinalUrlRequestType,
  UpdateAssetGroupImageNameRequestType,
  UpdateAssetGroupRequestType,
  UploadAssetGroupImagesRequestType,
  UploadAssetGroupYouTubeLinkRequestType,
} from "@services/account-campaign.service"
import {
  AssetGroupEntity,
  AudiencesEntity,
  ListingGroupEntity,
  ListingGroupOptionsEntity,
  PreviewLinkEntity,
} from "@framework/types/manageCampaign"

export class PMaxAssetGroupStore {
  @observable isLoading: boolean = false

  @observable error: string | null = null

  @observable updateError: string = ""

  @observable updateFinalUrlError: string = ""

  @observable uploadError: null | { error: string; name: string } = null

  @observable deleteError: null | { error: string; id: number } = null

  @observable addVideoLinkError: null | { error: string; url: string } = null

  @observable deleteVideoLinkError: null | { error: string; id: number } = null

  @observable propertyId: ID | "new" = "new"

  @observable data: Partial<AssetGroupEntity> = {}

  @observable listingGroups: ListingGroupEntity | null = null

  @observable listingGroupOptions: ListingGroupOptionsEntity | null = null

  @observable audiences: AudiencesEntity[] | null = null

  @observable previewLink: PreviewLinkEntity | null = null

  @observable updateLoading: boolean = false

  @action reset = () => {
    this.data = {}
    this.error = null
    this.isLoading = false
    this.propertyId = "new"
  }

  @action getAssetGroupById = async (
    accountId: number,
    assetGroupId: number
  ) => {
    try {
      this.updateLoading = true
      const response = await accountCampaignService.getAssetGroup(
        accountId,
        assetGroupId
      )
      this.data = response.data.data ?? {}
    } catch (error) {
      return "Unexpected error"
    } finally {
      this.updateLoading = false
    }
    return null
  }

  @action updateAssetGroup = async (
    accountId: number,
    assetGroupId: number,
    data: UpdateAssetGroupRequestType
  ) => {
    this.updateLoading = true
    this.updateError = ""
    try {
      await accountCampaignService.updateAssetGroup(accountId, assetGroupId, {
        ...data,
      })
    } catch (error: any) {
      this.updateError =
        error?.response?.data?.error || "Can't update the asset"
    } finally {
      this.updateLoading = false
    }
    return this.updateError
  }

  @action createAssetGroup = async (
    accountId: ID,
    campaignId: ID,
    data: CreateAssetGroupRequestType
  ) => {
    this.updateLoading = true
    this.error = ""
    try {
      await accountCampaignService.createAssetGroup(accountId, campaignId, {
        ...data,
      })
    } catch (error: any) {
      this.error =
        error?.response?.data?.error || "Can't create the asset group"
    } finally {
      this.updateLoading = false
    }
    return this.error
  }

  @action updateAssetGroupFinalUrl = async (
    accountId: number,
    assetGroupId: number,
    data: UpdateAssetGroupFinalUrlRequestType
  ) => {
    this.updateLoading = true
    this.updateFinalUrlError = ""
    try {
      await accountCampaignService.updateAssetGroupFinalUrl(
        accountId,
        assetGroupId,
        {
          ...data,
        }
      )
    } catch (error: any) {
      this.updateFinalUrlError =
        error?.response?.data?.error || "Can't update the FinalUrl field"
    } finally {
      this.updateLoading = false
    }
    return this.updateFinalUrlError
  }

  @action deleteAssetGroupImage = async (
    accountId: number,
    assetGroupId: number,
    imageId: number
  ) => {
    this.updateLoading = true
    this.deleteError = null
    try {
      await accountCampaignService.deleteAssetGroupImage(
        accountId,
        assetGroupId,
        imageId
      )

      return true
    } catch (error: any) {
      this.deleteError = error?.response.data.error
        ? { error: error?.response.data.error, id: imageId }
        : null
    } finally {
      this.updateLoading = false
    }
    return this.deleteError
  }

  @action updateAssetGroupImageName = async (
    accountId: number,
    assetGroupId: number,
    imageId: number,
    data: UpdateAssetGroupImageNameRequestType
  ) => {
    this.updateLoading = true
    this.updateError = ""
    try {
      await accountCampaignService.updateAssetGroupImageName(
        accountId,
        assetGroupId,
        imageId,
        data
      )
    } catch (error: any) {
      this.updateError =
        error?.response?.data?.error || "Can't update an image name"
    } finally {
      this.updateLoading = false
    }
    return this.updateError
  }

  @action uploadAssetGroupImages = async (
    accountId: number,
    assetGroupId: number,
    data: UploadAssetGroupImagesRequestType
  ) => {
    this.updateLoading = true
    this.uploadError = null
    try {
      await accountCampaignService.uploadAssetGroupImages(
        accountId,
        assetGroupId,
        {
          ...data,
        }
      )
    } catch (error: any) {
      this.uploadError = error?.response.data.error
        ? { error: error?.response.data.error, name: data.name }
        : null
    } finally {
      this.updateLoading = false
    }
    return this.uploadError
  }

  @action getAssetListingGroups = async (
    accountId: number,
    assetGroupId: number
  ) => {
    try {
      this.isLoading = true
      const response = await accountCampaignService.getAssetListingGroups(
        accountId,
        assetGroupId
      )
      this.listingGroups = response.data.data ?? null
    } catch (error) {
      return error || "Get asset listing group error"
    } finally {
      this.isLoading = false
    }
    return null
  }

  @action createAssetListingGroups = async (
    accountId: number,
    assetGroupId: number
  ) => {
    try {
      this.isLoading = true
      await accountCampaignService.createAssetListingGroups(
        accountId,
        assetGroupId
      )
    } catch (error) {
      return error || "Listing groups creation error"
    } finally {
      this.isLoading = false
    }
    return null
  }

  @action getAssetListingGroupOptions = async (
    accountId: number,
    assetGroupId: number,
    filterId: number
  ) => {
    try {
      this.isLoading = true
      const response = await accountCampaignService.getAssetListingGroupOptions(
        accountId,
        assetGroupId,
        filterId
      )
      this.listingGroupOptions = response.data.data ?? null
    } catch (error) {
      return error || "Get listing group options error"
    } finally {
      this.isLoading = false
    }
    return null
  }

  @action addAssetListingGroupFilter = async (
    accountId: number,
    assetGroupId: number,
    filterId: number,
    data: AddAssetListingGroupFiltersDataType
  ) => {
    this.isLoading = true
    this.updateError = ""
    try {
      await accountCampaignService.addAssetListingGroupFilters(
        accountId,
        assetGroupId,
        filterId,
        {
          ...data,
        }
      )
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Can't add a filter"
    } finally {
      this.isLoading = false
    }
    return this.updateError
  }

  @action setAssetListingGroupFilter = async (
    accountId: number,
    assetGroupId: number,
    filterId: number,
    data: AddAssetListingGroupFiltersDataType
  ) => {
    this.isLoading = true
    this.updateError = ""
    try {
      await accountCampaignService.setAssetListingGroupFilters(
        accountId,
        assetGroupId,
        filterId,
        {
          ...data,
        }
      )
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Can't set a filter"
    } finally {
      this.isLoading = false
    }
    return this.updateError
  }

  @action removeAssetListingGroupFilters = async (
    accountId: number,
    assetGroupId: number,
    filterId: number,
    data: RemoveAssetListingGroupFiltersDataType
  ) => {
    this.isLoading = true
    this.updateError = ""
    try {
      await accountCampaignService.removeAssetListingGroupFilters(
        accountId,
        assetGroupId,
        filterId,
        {
          ...data,
        }
      )
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Can't remove a filter"
    } finally {
      this.isLoading = false
    }
    return this.updateError
  }

  @action removeAssetListingGroup = async (
    accountId: number,
    assetGroupId: number
  ) => {
    this.updateLoading = true
    try {
      await accountCampaignService.removeAssetListingGroup(
        accountId,
        assetGroupId
      )
    } catch (error: any) {
      return error?.response?.data?.error || "Listing group remove error"
    } finally {
      this.updateLoading = false
    }
    return null
  }

  @action getAudiences = async (accountId: number, assetGroupId: number) => {
    try {
      this.isLoading = true
      const response = await accountCampaignService.getAudiences(
        accountId,
        assetGroupId
      )
      this.audiences = response.data.data ?? null
    } catch (error) {
      return "Unexpected error"
    } finally {
      this.isLoading = false
    }
    return null
  }

  @action addAudience = async (
    accountId: number,
    assetGroupId: number,
    data: AddAudienceRequestType
  ) => {
    this.isLoading = true
    this.updateError = ""
    try {
      await accountCampaignService.addAudience(accountId, assetGroupId, data)
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Can't add an audience"
    } finally {
      this.isLoading = false
    }
    return this.updateError
  }

  @action addYouTubeLink = async (
    accountId: number,
    assetGroupId: number,
    data: UploadAssetGroupYouTubeLinkRequestType
  ) => {
    this.updateLoading = true
    this.addVideoLinkError = null
    try {
      await accountCampaignService.uploadAssetGroupYouTubeLink(
        accountId,
        assetGroupId,
        data
      )
    } catch (error: any) {
      this.addVideoLinkError = error?.response?.data?.error
        ? { error: error?.response?.data?.error, url: data.url }
        : null
    } finally {
      this.updateLoading = false
    }
    return this.addVideoLinkError
  }

  @action deleteYouTubeLink = async (
    accountId: number,
    assetGroupId: number,
    videoId: number
  ) => {
    this.updateLoading = true
    this.deleteVideoLinkError = null
    try {
      await accountCampaignService.deleteAssetGroupYouTubeLink(
        accountId,
        assetGroupId,
        videoId
      )
    } catch (error: any) {
      this.deleteVideoLinkError = error?.response?.data?.error
        ? { error: error?.response?.data?.error, id: videoId }
        : null
    } finally {
      this.updateLoading = false
    }
    return this.deleteVideoLinkError
  }

  @action generatePreviewLink = async (
    accountId: number,
    assetGroupId: number
  ) => {
    try {
      this.isLoading = true
      const response = await accountCampaignService.generatePreview(
        accountId,
        assetGroupId
      )
      this.previewLink = response.data.data ?? null
    } catch (error) {
      return error || "Preview link generation error"
    } finally {
      this.isLoading = false
    }
    return null
  }
}

export default PMaxAssetGroupStore
