import { MetaCampaignReportData } from "@pages/MetaAdsChannel/types"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { ColumnMapper } from "../components/Table/types"
import MetricCell from "../components/Table/TableCell/MetricCell"
import LabelCell from "../components/Table/TableCell/LabelCell"
import { makeDescriptor } from "../components/Table/utils"

const defaultLabelCell = makeDescriptor(LabelCell)

const defaultMetricCell = makeDescriptor(MetricCell, {
  getMetric: getMetricDescriptor,
})

export const mapper: ColumnMapper<MetaCampaignReportData>[] = [
  {
    name: "name",
    label: "Creative",
    width: "250px",
    view: defaultLabelCell,
  },
  {
    name: "Ctr",
    label: "Ctr",
    width: "150px",
    view: defaultMetricCell,
  },
  {
    name: "Clicks",
    label: "Clicks",
    isSortable: "product_type",
    width: "240px",
    view: defaultMetricCell,
  },

  {
    name: "Revenue",
    label: "Revenue",
    width: "150px",
    view: defaultMetricCell,
  },
  {
    name: "Impressions",
    label: "Impressions",
    width: "140px",
    view: defaultMetricCell,
  },
  {
    name: "Cpm",
    label: "Cpm",
    width: "150px",
    view: defaultMetricCell,
  },
  {
    name: "AverageCpc",
    label: "Average",
    width: "140px",
    view: defaultMetricCell,
  },
]

export default Object.values(mapper)
