import { MetricNameType } from "@framework/types/metrics"

export const insightOptions: MetricNameType[] = [
  "Cost",
  "Clicks",
  "CostConv",
  "Revenue",
  "Roas",
  "Impressions",
  "SearchImpressionShare",
  "Ctr",
  "AverageCpc",
  "Conversions",
  "ConversionRate",
  "AOV",
]

export const insightPMaxOptions: MetricNameType[] = [
  "Cost",
  "Conversions",
  "CostConv",
  "Revenue",
  "Roas",
  "Impressions",
  "SearchImpressionShare",
  "Ctr",
  "ConversionRate",
  "Clicks",
  "AverageCpc",
  "AOV",
]

export default {}
