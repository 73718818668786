import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { transformEstimate } from "@store/opportunities/opportunities.transformer"
import { moneyFormatter } from "@services/utils"
import {
  useOpportunitiesController,
  useOpportunitiesStore,
} from "@store/opportunities/provider"
import Pair from "./Pair"

import styles from "./SelectedKeywordsList.module.scss"

export interface AmountSectionProps {}

const SelectedKeywordsList: React.FC<AmountSectionProps> = observer(() => {
  const { opportunities } = useOpportunitiesController()
  const opportunitiesStore = useOpportunitiesStore()

  const { accountStore } = useStore()
  const { shoppingCart, calculator } = opportunitiesStore
  const { accountId } = accountStore

  const { ctr } = calculator

  return (
    <>
      <div className={styles.labelsWrapper}>
        <Typography type="subhead1" upperCase>
          Keywords
        </Typography>
        <Typography type="subhead1" upperCase>
          Est Monthly Ad Spend
        </Typography>
      </div>
      <div className={styles.keywordContainer}>
        <div className={styles.keywordList}>
          {!shoppingCart.totalSelected ? (
            <Typography type="h3" color="gray">
              No keywords yet added
            </Typography>
          ) : (
            shoppingCart.selectedKeywords.map((item) => (
              <Pair
                id={item.id}
                accountId={accountId}
                label={item.displayName}
                value={transformEstimate(item, ctr)}
                onClose={opportunities.switchKeyword}
                renderValue={moneyFormatter}
                key={item.id}
              />
            ))
          )}
        </div>
      </div>
    </>
  )
})

export default SelectedKeywordsList
