/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { useHistory, useLocation } from "react-router-dom"

import Button, { ButtonProps } from "@components/ui/Button/Button"
import Stepper, { StepOptionsProps } from "@components/ui/Stepper/Stepper"
import { useStore } from "@store/index"
import useManageProfile from "@store/manageProfile/useManageProfile"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import PaidSearchFooter from "@root/layouts/PrivateLayout/components/PaidSeachFooter/PaidSearchFooter"
import config from "@root/config"
import Attributes from "./components/Attributes/Attributes"
import Checkbook from "./components/Checkbook/Checkbook"
import PaidSearchLinkedAccounts from "./components/LinkedAccounts/LinkedAccounts"
import AdCopy from "./components/AdCopy/AdCopy"

import styles from "./ManageProfile.module.scss"

export type StepOption = StepOptionsProps & {
  Component: React.FC
  name: string
}

const findStepIndex = (steps: StepOption[], name: string) =>
  steps.findIndex((item) => item.name === name)

const steps: StepOption[] = [
  {
    value: "1",
    name: "attributes",
    label: "Attributes",
    Component: Attributes,
  },
  { value: "2", name: "adCopy", label: "Ad Copy", Component: AdCopy },
  { value: "3", name: "checkbook", label: "Checkbook", Component: Checkbook },
  {
    value: "4",
    name: "linkedAccounts",
    label: "Linked Accounts",
    Component: PaidSearchLinkedAccounts,
  },
]

const ManageProfile: React.FC = observer(() => {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})

  const history = useHistory()
  const { pathname, search } = useLocation<any>()

  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  )

  useEffect(() => {
    const stepIndex = findStepIndex(steps, queryParams.alter)
    if (stepIndex >= 0) setActiveStep(stepIndex)
  }, [queryParams.alter])

  const handleChangeStep = useCallback(
    (callback: (oldStep: number) => number) => {
      setActiveStep((oldStep) => {
        const newStep = callback(oldStep)
        if (steps[newStep].name !== queryParams.alter) history.replace(pathname)
        return newStep
      })
    },
    [queryParams.alter, history, pathname]
  )

  const {
    cantGenerateKeywords,
    isAccountInfoValid,
    isAssignedAttributesValid,
    isCampaignLaunched,
    isAdCopyValid,
    isAccountLinked,
    handleStartGenerating,
  } = useManageProfile()

  const {
    manageProfileStore: {
      isEdit,
      keywordsGenerationStats,
      loadKeywordsGenerationStats,
      setIsEdit,
      submitFormCallback,
    },
    accountStore: { accountId },
  } = useStore()

  const handlePrevStep = useCallback(() => {
    handleChangeStep((prevStep) => {
      if (prevStep > 0) return prevStep - 1
      return prevStep
    })
    setIsEdit(false)
  }, [setIsEdit])

  const handleNextStep = useCallback(() => {
    handleChangeStep((prevStep) => {
      if (prevStep < steps.length) return prevStep + 1
      return prevStep
    })
    setIsEdit(false)
  }, [setIsEdit])

  const setStep = useCallback(
    (step: StepOptionsProps) => {
      handleChangeStep((prevStep) => {
        const index = steps.findIndex((item) => item.value === step.value)
        return index >= 0 ? index : prevStep
      })
      setIsEdit(false)
    },
    [setIsEdit]
  )

  const cantEdit = useMemo(
    () =>
      !!(
        keywordsGenerationStats &&
        keywordsGenerationStats?.running &&
        (activeStep === 0 || activeStep === 1)
      ),
    [keywordsGenerationStats, activeStep]
  )

  const editBtnProps = useMemo(() => {
    const children = isEdit ? (submitFormCallback ? "Save" : "Cancel") : "Edit"

    const onClick = isEdit
      ? submitFormCallback || setIsEdit.bind(null, false)
      : setIsEdit.bind(null, true)

    const variant: ButtonProps["variant"] = submitFormCallback
      ? "contained"
      : "outlined"

    return {
      disabled: cantEdit,
      children,
      variant,
      className: styles.editButton,
      onClick,
    }
  }, [isEdit, submitFormCallback, setIsEdit, cantEdit])

  useEffect(() => {
    if (accountId) loadKeywordsGenerationStats(accountId)
  }, [accountId, activeStep])

  const setStepCompleted = useCallback(
    (step: number, value: boolean) => {
      setCompleted((set) => ({
        ...set,
        [step]: value,
      }))
    },
    [setCompleted]
  )

  useEffect(() => {
    setStepCompleted(0, isAssignedAttributesValid)
  }, [isAssignedAttributesValid])

  useEffect(() => {
    setStepCompleted(1, isAdCopyValid)
  }, [isAdCopyValid])

  useEffect(() => {
    setStepCompleted(2, isCampaignLaunched)
  }, [isAssignedAttributesValid])

  useEffect(() => {
    setStepCompleted(3, isAccountLinked)
  }, [isAccountLinked])

  const { Component } = steps[activeStep]

  return (
    <div className={styles.root}>
      <ServiceDownInterceptor />

      {/* header */}
      <div className={styles.header}>
        <Stepper
          disableAfterIndex={isAccountInfoValid ? undefined : 0}
          activeStep={activeStep}
          complete={completed}
          steps={steps}
          className={styles.stepper}
          onClick={setStep}
        />

        <div className={styles.control}>
          <Button
            disabled={!cantGenerateKeywords}
            variant="contained"
            className={styles.button}
            onClick={handleStartGenerating}
          >
            Generate Keywords
          </Button>

          {isEdit ? (
            <>
              <Button
                color="secondary"
                className={styles.editButton}
                onClick={() => setIsEdit(false)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className={styles.editButton}
                onClick={submitFormCallback}
                disabled={!submitFormCallback}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              className={styles.editButton}
              onClick={() => setIsEdit(true)}
              disabled={cantEdit}
            >
              Edit
            </Button>
          )}
        </div>
      </div>

      {/* body */}
      {Component && <Component />}

      {/* footer */}
      <div className={styles.buttonsWrapper}>
        <Button
          hidden={activeStep - 1 < 0}
          className={styles.nextStepButton}
          variant="ghost"
          onClick={handlePrevStep}
          startIcon={{ name: "long-arrow-right", rotateAngle: 180 }}
        >
          {`Back to ${steps[activeStep - 1]?.label}`}
        </Button>
        <Button
          disabled={!isAccountInfoValid}
          hidden={activeStep >= steps.length - 1}
          className={styles.nextStepButton}
          variant="ghost"
          onClick={handleNextStep}
          endIcon={{ name: "long-arrow-right" }}
        >
          {`Go to ${steps[activeStep + 1]?.label}`}
        </Button>
      </div>

      {!!config.ENABLE_OPPORTUNITIES_UPDATE && (
        <PaidSearchFooter key={accountId} />
      )}
    </div>
  )
})

export default ManageProfile
