import { observable, action, computed } from "mobx"
import moment from "moment"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"

import {
  AdGroupReportDataType,
  CampaignReportDataType,
  KeywordEntityType,
  KeywordReportType,
} from "@framework/types/account"
import accountService from "@services/account.service"
import { ID } from "@framework/types/types"
import accountCampaignService, {
  UpdateCampaignBidStrategyRequestType,
} from "@services/account-campaign.service"
import { datePairToPeriod } from "@utils/date"
import KeywordsStore from "@store/account-dashboard/keywords.store"
import AdsStore from "@store/account-dashboard/ads.store"
import { EditAdCopyData } from "@framework/types/adCopy"
import { transformAdCopyDetails } from "@store/manageProfile/dataTransformers"
import EditAdCopyStore from "./edit-adCopy.store"
import EditAdCopyTask from "./EditAdCopyTask"
import DeleteAdCopyTask from "./DeleteAdCopyTask"

export type UpdateCampaignRequestType = {
  budgetAmount?: number
  budgetFrequency?: string
  status?: string
  type: "STATUS" | "BUDGET"
}

export type UpdateAdGroupRequestType = {
  cpcAmount?: number
  adgroupName: string
  status?: string
  type: "STATUS" | "MAX_CPC"
}

export type UpdateAdRequestType = {
  status?: string
  type: "STATUS"
}

export type UpdateKeywordRequestType = {
  cpcAmount?: number
  status?: string
  type: "STATUS" | "MAX_CPC"
}

export class ManageCampaignsStore {
  @observable adCopyEditor: EditAdCopyStore

  @observable keywordsStore: KeywordsStore<KeywordReportType>

  @observable negativeKeywordsStore: KeywordsStore<KeywordEntityType>

  @observable adsStore: AdsStore

  @observable campaignsReportLoading: boolean = false

  @observable campaignsReport: CampaignReportDataType[] | null = null

  @observable campaignsReportError: string | null = null

  @observable adGroupReportLoading: boolean = false

  @observable updateLoading: boolean = false

  @observable updateError: string | null = null

  @observable adGroupReport: Map<string, AdGroupReportDataType> = new Map()

  constructor() {
    this.adCopyEditor = new EditAdCopyStore()
    this.keywordsStore = new KeywordsStore()
    this.negativeKeywordsStore = new KeywordsStore()
    this.adsStore = new AdsStore()
  }

  @observable dateFilter: Date[] = [
    moment(Date.now()).startOf("month").toDate(),
    moment(Date.now()).toDate(),
  ]

  @action loadKeywordsList = async (
    accountId: ID,
    datePair: Date[],
    metric?: string,
    compareWith?: string
  ) => {
    const store = this.keywordsStore

    try {
      store.isLoading = true
      store.error = null

      const response = await accountCampaignService.getTopKeywordsReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
          metric,
          compareWith,
        }
      )

      const keywords = response.data?.data ?? []

      store.storeData(keywords)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  @action loadNegativeKeywordsList = async (
    accountId: ID,
    datePair: Date[]
  ) => {
    const store = this.negativeKeywordsStore

    try {
      store.isLoading = true
      store.error = null

      const response = await accountCampaignService.getNegativeKeywordsReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
        }
      )

      const keywords = response.data?.data ?? []

      store.storeData(keywords)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  @action loadAdsList = async (accountId: ID, datePair: Date[]) => {
    const store = this.adsStore

    try {
      store.isLoading = true
      store.error = null

      const response = await accountCampaignService.getAdCopyReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
        }
      )

      const adCopyList = response.data.data ?? []

      store.storeData(adCopyList)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  @action loadCampaignReportList = async (
    accountId: number,
    datePair: Date[]
  ) => {
    this.campaignsReportLoading = true
    this.campaignsReportError = null
    try {
      const response = await accountCampaignService.getCampaignReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
          includePmaxDrafts: true,
          includeAdGroups: true,
        }
      )
      this.campaignsReport = response.data.data ?? []
    } catch (error) {
      this.campaignsReportError = "Unexpected error"
    } finally {
      this.campaignsReportLoading = false
    }
    return this.campaignsReportError
  }

  @action loadAdGroupReportList = async (
    accountId: ID,
    adGroupId: ID,
    datePair: Date[]
  ) => {
    this.adGroupReportLoading = true
    try {
      const key = `${accountId}_${adGroupId}`
      const response = await accountService.getAdGroupsReport(
        accountId,
        adGroupId,
        datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT)
      )
      this.adGroupReport.set(key, response.data.data ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      this.adGroupReportLoading = false
    }
  }

  @computed get getAdGroupReport() {
    const { adGroupReport } = this
    return (accountId: ID, adGroupId: ID) =>
      adGroupReport.get(`${accountId}_${adGroupId}`)
  }

  @action updateCampaign = async (
    accountId: ID,
    campaignId: ID,
    data: UpdateCampaignRequestType
  ) => {
    this.updateLoading = true
    this.updateError = null
    try {
      await accountCampaignService.updateCampaign(accountId, {
        campaignId: Number(campaignId),
        ...data,
      })
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Can't update value"
    } finally {
      this.updateLoading = false
    }
    return this.updateError
  }

  @action updateCampaignBidStrategy = async (
    accountId: number,
    data: UpdateCampaignBidStrategyRequestType
  ) => {
    this.updateLoading = true
    this.updateError = null
    try {
      await accountCampaignService.updateCampaignBidStrategy(accountId, data)
    } catch (error: any) {
      this.updateError =
        error?.response?.data?.error ||
        "Unexpected error while updating bid strategy"
    } finally {
      this.updateLoading = false
    }
    return this.updateError
  }

  @action updateAdGroup = async (
    accountId: number,
    adGroupId: ID,
    data: UpdateAdGroupRequestType
  ) => {
    this.updateLoading = true
    this.updateError = null
    try {
      await accountCampaignService.updateAdGroup(accountId, {
        adgroupId: Number(adGroupId),
        ...data,
      })
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Can't update value"
    } finally {
      this.updateLoading = false
    }
    return this.updateError
  }

  @action updateAd = async (
    accountId: ID,
    adGroupId: ID,
    adId: ID,
    data: UpdateAdRequestType
  ) => {
    this.updateLoading = true
    this.updateError = null
    try {
      await accountCampaignService.updateAd(accountId, {
        adgroupId: Number(adGroupId),
        adgroupadId: Number(adId),
        ...data,
      })
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Can't update value"
    } finally {
      this.updateLoading = false
    }
    return this.updateError
  }

  @action addKeywords = async (
    accountId: ID,
    adgroupId: ID,
    keywords: string[],
    url?: string
  ) => {
    this.updateLoading = true
    this.updateError = null
    try {
      await accountCampaignService.addKeywords(accountId, {
        adgroupId: Number(adgroupId),
        keywords: keywords.join("\n"),
        url,
      })
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Unexpected error"
    } finally {
      this.updateLoading = false
    }
    return this.updateError
  }

  @action addNegativeKeywords = async (
    campaignId: ID,
    accountId: ID,
    keywords: string[],
    adgroupId?: ID
  ) => {
    this.updateLoading = true
    this.updateError = null
    try {
      await accountCampaignService.addNegativeKeywords(accountId, {
        campaignId: Number(campaignId),
        adgroupId: Number(adgroupId),
        keywords: keywords.join("\n"),
      })
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Unexpected error"
    } finally {
      this.updateLoading = false
    }
    return this.updateError
  }

  @action updateKeyword = async (
    accountId: ID,
    adgroupId: ID,
    keywordId: ID,
    data: UpdateKeywordRequestType
  ) => {
    this.updateLoading = true
    this.updateError = null
    try {
      await accountCampaignService.updateKeyword(accountId, {
        adgroupId: Number(adgroupId),
        criterionId: Number(keywordId),
        ...data,
      })
    } catch (error: any) {
      this.updateError = error?.response?.data?.error || "Can't update value"
    } finally {
      this.updateLoading = false
    }
    return this.updateError
  }

  @action createEditAdCopyTask = async (accountId: ID, adCopyId: ID) => {
    const task = new EditAdCopyTask(adCopyId)
    try {
      task.isLoading = true
      this.adCopyEditor.setAdCopyTask(task)

      const response = await accountService.getAdCopiesDetailsByGoogleIds(
        accountId,
        [adCopyId]
      )

      if (response.data.data == null) throw new Error("Incorrect response type")

      task.setAdCopies(response.data.data.map(transformAdCopyDetails))
    } catch (error) {
      task.error = "Unexpected error"
      return task.error
    } finally {
      task.isLoading = false
    }
    return null
  }

  @action createDeleteAdCopyTask = async (adCopyId: ID) => {
    this.adCopyEditor.setAdCopyTask(new DeleteAdCopyTask(adCopyId))
  }

  @action updateAdCopy = async (
    accountId: ID,
    adCopyId: ID,
    data: Partial<EditAdCopyData>
  ): Promise<string | null> => {
    try {
      this.updateLoading = true

      await accountService.updateAndPushAdCopy(accountId, [adCopyId], data)
    } catch (error) {
      return "Unexpected error while editing AdCopy"
    } finally {
      this.updateLoading = false
    }
    return null
  }

  @action deleteAdCopies = async (accountId: ID, adCopyId: ID) => {
    try {
      await accountService.removeAndPushAdCopies(accountId, [adCopyId])
    } catch (error) {
      return "Unexpected error"
    } finally {
      this.updateLoading = true
    }
    return null
  }

  @action setDateFilter = (date: Date[]) => {
    this.dateFilter = date
  }

  @action getCampaignById = (campaignId: number) =>
    this.campaignsReport?.find((it) => it.Campaign.Id === campaignId)
}

export default ManageCampaignsStore
