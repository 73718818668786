import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Icon from "@components/ui/Icon/Icon"
import Box from "@components/ui/Box/Box"
import useToggle from "@framework/hooks/useToggle"
import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import IconButton from "@components/ui/Button/IconButton"
import FinalURLExpansionSettingsForm from "./FinalURLExpansionSettingsForm"
import AdScheduleSettingsForm from "./AdScheduleSettingsForm"

import styles from "./index.module.scss"

const generalData = [
  { label: "Ad schedule", value: "schedule", editable: false },
  {
    label: "Final URL expansion",
    value: "finalURL",
    editable: false,
  },
]

export const MoreSettingsForm: React.FC = observer(() => {
  const sectionToggle = useToggle()
  const [activeForm, setActiveForm] = useState<string | null>(null)

  return (
    <Box className={styles.root}>
      <Stack gutter="16">
        <Typography
          color="black60Color"
          className={styles.header}
          onClick={sectionToggle.handleToggle}
        >
          <Typography
            type="h5"
            color="primary100Color"
            className={styles.title}
            weight="bold"
          >
            <Icon name="gear" /> More Settings
          </Typography>

          <Icon
            name="arrow-right"
            rotateAngle={sectionToggle.isOpened ? -90 : 90}
          />
        </Typography>

        {sectionToggle.isOpened && (
          <Table className={styles.table}>
            {generalData.map((it) => (
              <Row key={it.label}>
                <Column vAlign="top" width="25%">
                  {it.label}
                </Column>
                <Column width="65%">
                  {it.value === "schedule" && (
                    <AdScheduleSettingsForm editMode />
                  )}
                  {it.value === "finalURL" && (
                    <FinalURLExpansionSettingsForm editMode />
                  )}
                </Column>
                <Column vAlign="top" width="5%">
                  {it.editable && (
                    <IconButton
                      size="small"
                      title="Edit"
                      onClick={() => setActiveForm(it.value)}
                    >
                      <Icon name="pen" />
                    </IconButton>
                  )}
                </Column>
              </Row>
            ))}
          </Table>
        )}
      </Stack>
    </Box>
  )
})

export default MoreSettingsForm
