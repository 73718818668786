import React, { useCallback, useEffect, useState } from "react"
import { nanoid } from "nanoid"

import { AdCopyPreview } from "@framework/prototypes/AdCopy/Preview/types"
import MobileFrame from "../MobileFrame/MobileFrame"
import AdCard from "./AdCard/AdCard"
import Header from "./Header/Header"

import styles from "./Preview.module.scss"

const defaultAdProps: AdCopyPreview = {
  id: nanoid(),
  website: "your-website.com",
  headlines: ["Headline1", "Headline2", "Headline3"],
  descriptions: ["Description 1", "Description 2"],
}

export interface PreviewProps {
  adList: AdCopyPreview[]
}

const Preview: React.FC<PreviewProps> = ({ adList }) => {
  const [type, setType] = useState<string>("mobile")
  const [activeIdx, setActiveIdx] = useState(0)

  const handleChangeFrame = useCallback(
    (dir?: "left" | "right") => {
      setActiveIdx((prev) => {
        if (dir === "left") return prev > 0 ? prev - 1 : adList.length - 1
        if (dir === "right") return adList.length - 1 > prev ? prev + 1 : 0
        return 0
      })
    },
    [adList]
  )

  useEffect(() => {
    setActiveIdx(0)
  }, [adList])

  const adProps = adList[activeIdx] ?? defaultAdProps

  return (
    <div className={styles.root}>
      <Header
        title="Preview"
        currentType={type}
        onTypeChange={setType}
        onFrameChange={handleChangeFrame}
      />
      <div className={styles.body}>
        {type === "mobile" ? (
          <MobileFrame>
            <AdCard data={adProps} variant="mobile" />
          </MobileFrame>
        ) : (
          <AdCard data={adProps} variant="desktop" />
        )}
      </div>
      <div className={styles.footer}>
        {`This preview shows potential ads assembled using your assets. Not all
        combinations are shown. Assets can be shown in any order, so make sure
        that they make sense individually or in combination, and don't violate
        our policies or local law. Some shortening may also occur in some
        formats. You can make sure certain text appears in your ad.`}
      </div>
    </div>
  )
}

export default Preview
