import clsx from "clsx"
import React from "react"

import styles from "./CircleLoader.module.scss"

export interface CircleLoaderProps {
  className?: string
  size?: "big" | "inherit"
  color?: "primary" | "inherit"
}

const CircleLoader: React.FC<CircleLoaderProps> = ({
  className,
  size = "big",
  color = "primary",
}) => (
  <svg
    viewBox="0 0 80 80"
    className={clsx(
      styles.root,
      styles[`size-${size}`],
      styles[`color-${color}`],
      className
    )}
  >
    <clipPath id="clip">
      <path d="M79.7,40C79.7,62,62,79.7,40,79.7C18,79.7,0.3,62,0.3,40C0.3,18,18,0.3,40,0.3C62,0.3,79.7,18,79.7,40zM12.1,40c0,15.4,12.5,27.9,27.9,27.9c15.4,0,27.9-12.5,27.9-27.9c0-15.4-12.5-27.9-27.9-27.9C24.6,12.1,12.1,24.6,12.1,40z" />
    </clipPath>

    <foreignObject x="0" y="0" width="80" height="80" clipPath="url(#clip)">
      <div className={clsx(styles.circle)} />
    </foreignObject>

    <circle cx={40} cy={73.8} r={5.9} className={clsx(styles.tip)} />
  </svg>
)

export default CircleLoader
