import React from "react"
import { observer } from "mobx-react-lite"
import without from "lodash/without"
import findIndex from "lodash/findIndex"
import sortBy from "lodash/sortBy"

import { initArray, randomNumber } from "@utils/numberUtils"
import AdContent from "@framework/prototypes/AdCopy/Preview/AdCard/AdContent"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import LoaderProgressBar from "@components/ui/ProgressBar/LoaderProgressBar/LoaderProgressBar"
import useProgressTicker from "@pages/IndustryAnalysisDemo/useProgressTicker"
import { Button } from "@components/ui/Button"
import { useController, useStore } from "@store/index"
import { useAdCopyAIWizardContext } from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/AdCopyTable/AdCopyAIWizardContext"
import { ID } from "@framework/types/types"
import useToggle from "@framework/hooks/useToggle"
import { AdCopyPreview } from "@framework/prototypes/AdCopy/Preview/types"
import CardItem from "../components/CardItem"
import { FormComponentProps } from "."

import styles from "./ContentPreview.module.sass"

type ContentPreviewProps = {} & FormComponentProps

const ContentPreview: React.FC<ContentPreviewProps> = observer(
  ({ headerSlot, controlSlot, onSubmit, onCancel }) => {
    const { adCopyManagement } = useController()
    const {
      accountStore: { accountId },
    } = useStore()

    const wizardContext = useAdCopyAIWizardContext()

    const progress = useToggle(true)

    const [selected, setSelected] = React.useState<string[]>([])

    const [adCopyList, setAdCopyList] = React.useState<AdCopyPreview[]>([])

    const handleToggle = (id: string) => {
      setSelected((prev) =>
        prev.includes(id) ? without(prev, id) : [...prev, id]
      )
    }

    const handleAdCopyGeneration = async (accountId: ID, data: any) => {
      setSelected([])

      const res = await adCopyManagement.generateAdCopyWithAI(
        accountId,
        data.finalUrl,
        data
      )
      if (res.status === "FAILED") {
        wizardContext.setFatalError(res.message)
        return
      }

      setAdCopyList(res.data)
    }

    React.useEffect(() => {
      if (accountId) handleAdCopyGeneration(accountId, wizardContext.settings)
    }, [accountId])

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault()

      const sorted = sortBy(adCopyList, (it) => {
        const index = findIndex(selected, (id) => id === it.id)
        // earlier selected goes higher
        if (index >= 0) return index
        return selected.length + 1
      })

      wizardContext.saveGeneratedAdCopy(sorted)

      onSubmit()
    }

    if (adCopyList.length === 0 || progress.isOpened)
      return (
        <LoadingPlaceholder
          onCancel={onCancel}
          onDone={() => progress.setOpened(false)}
        />
      )

    return (
      <form className={styles.root} onSubmit={handleSubmit}>
        {headerSlot}

        <div className={styles.header}>
          <Typography type="h4" weight="semibold">
            Browse examples
          </Typography>
          <Typography type="body2" color="black70Color">
            You can select multiple examples from AI generated Ad Copies so we
            can tailor it to achieve the best results.
          </Typography>
        </div>

        <div className={styles.body}>
          <div className={styles.grid}>
            {adCopyList.map((item) => (
              <CardItem
                active={selected.includes(item.id)}
                onClick={() => handleToggle(item.id)}
              >
                <AdContent
                  website={item.website}
                  headlinesLimit={3}
                  headlines={item.headlines}
                  descriptions={item.descriptions}
                  variant="desktop"
                />
              </CardItem>
            ))}
          </div>
        </div>

        {controlSlot}
      </form>
    )
  }
)

const loadingSteps = [
  "Gathering data about products with AI Helper...",
  "Creating descriptions...",
  "Generating Ad Copy...",
]

const steps = initArray(900, () => randomNumber(9, 10))

const LoadingPlaceholder: React.FC<{
  onCancel?: () => void
  onDone?: () => void
}> = observer(({ onCancel, onDone }) => {
  const ticker = useProgressTicker({
    steps,
    onTick: () => {},
    onEnd: onDone,
  })

  const stepIndex = Math.floor(ticker.progress / 34)
  const stepMessage = loadingSteps[stepIndex]

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <Stack
          className={styles.placeholder}
          gutter="8"
          justify="center"
          align="center"
        >
          <Typography type="h3" weight="bold">
            {stepMessage ?? "In Progress"}
          </Typography>
          <LoaderProgressBar value={ticker.progress} />
          <Typography type="caption2">
            Content creation may take a few minutes, please wait
          </Typography>
        </Stack>
      </div>

      <div>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </div>
    </div>
  )
})

export default ContentPreview
