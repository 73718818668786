import moment from "moment"
import {
  DEF_YYYY_MM_DD_DATE_FORMAT,
  YEAR_MONTH_KEY_FORMAT,
  YEAR_MONTH_SLASH_KEY_FORMAT,
} from "@framework/constants/moment-format"
import {
  CheckbookMonthlyAmount,
  CheckbookUpdateFormRow,
} from "@services/account.service"
import { TimeSegmentedPerformanceReport } from "@framework/types/metrics"
import { MonthlyAmountMap } from "./checkbook"

export const transformCheckbookAmounts = (
  data: CheckbookMonthlyAmount[]
): MonthlyAmountMap =>
  Object.fromEntries(
    data.map(({ Month, Amount }) => {
      const monthId = moment.utc(Month).format(YEAR_MONTH_KEY_FORMAT)
      return [monthId, Amount.toString()]
    })
  )

export const transformCheckbookForm = (
  data: MonthlyAmountMap
): CheckbookUpdateFormRow[] =>
  Object.entries(data).map(([month, value]) => ({
    month: moment
      .utc(month, YEAR_MONTH_KEY_FORMAT)
      .format(YEAR_MONTH_SLASH_KEY_FORMAT),
    amount: Number.parseFloat(value),
  }))

export const transformAdAmount = (
  data: TimeSegmentedPerformanceReport[]
): MonthlyAmountMap =>
  Object.fromEntries(
    data.map(({ Period, Metrics }) => {
      const id = moment(Period, DEF_YYYY_MM_DD_DATE_FORMAT).format(
        YEAR_MONTH_KEY_FORMAT
      )
      return [id, (Metrics.Cost?.Value ?? 0).toString()]
    })
  )

export default null
