import React from "react"
import clsx from "clsx"

import styles from "./ToolBar.module.scss"

interface ToolBarProps {
  className?: string
}

export const ToolBar: React.FC<ToolBarProps> = ({ children, className }) => (
  <div className={clsx(styles.root, className)}>{children}</div>
)

export default ToolBar
