import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import interceptor from "@root/interceptors/auth.Interceptor"
import PlaceholderLoader from "@components/ui/Loader/PlaceholderLoader"

export interface SecurityProviderProps {}

const SecurityProvider: React.FC<SecurityProviderProps> = ({ children }) => {
  const rootStore = useStore()
  const { authStore } = rootStore
  const { isTokenChecked, isLoading, checkToken } = authStore

  useEffect(() => interceptor(rootStore), [rootStore])

  useEffect(() => {
    if (!isTokenChecked && !isLoading) {
      checkToken()
    }
  }, [isTokenChecked, isLoading, checkToken])

  return isTokenChecked ? <>{children}</> : <PlaceholderLoader />
}

export default observer(SecurityProvider)
