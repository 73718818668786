import { Option } from "@framework/types/types"
import { snakeCaseToTitle } from "./stringUtils"

export const shiftLeft = (array: any[], index: number) => {
  const start = [...array].splice(0, index)
  const end = [...array].splice(index)
  return [...end, ...start]
}

export const toMap = <T extends any = any>(
  array: T[],
  keyName: keyof T
): { [key: string]: T } =>
  Object.fromEntries(array.map((item) => [item[keyName], item]))

export const idArrayToOptions = <T extends string = string>(
  idList: T[],
  titleTransformer: (id: string) => string = snakeCaseToTitle
) =>
  idList.map<Option<T>>((text) => ({
    value: text,
    label: titleTransformer(text),
  }))

export const searchBy = <T>(
  list: T[],
  query: string | null | undefined,
  getter: (item: T) => string
): T[] => {
  if (!query) return [...list]
  return list.filter((it) =>
    getter(it).toLowerCase().includes(query.toLowerCase().trim())
  )
}
