import clsx from "clsx"
import React, { ReactNode } from "react"

import Typography from "@components/ui/Typography/Typography"

import styles from "./NoData.module.scss"

export interface NoDataProps {
  children?: ReactNode
  className?: string
}

export const NoData: React.FC<NoDataProps> = ({
  children = "No data provided",
  className,
}: NoDataProps) => (
  <Typography
    type="body1"
    color="gray"
    className={clsx(styles.root, className)}
  >
    {children}
  </Typography>
)

export default NoData
