import { AdCopyPreview } from "@framework/prototypes/AdCopy/Preview/types"
import { AdCopyPrompts } from "@framework/types/adCopy"
import { action, observable } from "mobx"

class AdCopyAIWizardStore {
  @observable settings: Partial<AdCopyPrompts> | null = null

  @observable generatedAdCopy: AdCopyPreview[] | null = null

  @observable activeStep = 0

  @observable completed: Record<string, boolean> = {}

  @observable fatalError: string | null = null

  @action setActiveStep = (value: (prev: number) => number) => {
    this.activeStep = value(this.activeStep)
  }

  @action setCompleted = (
    value: (prev: Record<string, boolean>) => Record<string, boolean>
  ) => {
    this.completed = value(this.completed)
  }

  @action updateSettings = (data: any) => {
    if (this.settings === null) this.settings = {}

    this.settings = { ...this.settings, ...data }
  }

  @action saveGeneratedAdCopy = (data: AdCopyPreview[]) => {
    this.generatedAdCopy = data
  }

  @action setFatalError = (error: string | null) => {
    this.fatalError = error
  }

  @action reset = () => {
    this.settings = null
    this.generatedAdCopy = null
    this.activeStep = 0
    this.completed = {}
    this.fatalError = null
  }
}

export default AdCopyAIWizardStore
