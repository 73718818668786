import React from "react"
import { action, computed, observable } from "mobx"
import { observer, useLocalStore } from "mobx-react-lite"

export class FormMetaContextStore {
  @computed get isEdited() {
    const { edited } = this
    return (fieldName: string) => edited.has(fieldName)
  }

  @observable edited = new Set<string>()

  @action setEditedFields = (edited: string[] = []) => {
    this.edited = new Set(edited)
  }
}

export const FormMetaContext = React.createContext<FormMetaContextStore | null>(
  null
)

export const FormMetaContextProvider: React.FC<{ editedFields?: string[] }> =
  observer(({ editedFields, children }) => {
    const context = useLocalStore(() => new FormMetaContextStore())

    React.useEffect(() => context.setEditedFields(editedFields), [editedFields])

    return (
      <FormMetaContext.Provider value={context}>
        {children}
      </FormMetaContext.Provider>
    )
  })

export const useFormMetaContext = () => {
  const context = React.useContext(FormMetaContext)
  return context
}
