import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Label from "@components/ui/CheckBox/Label/Label"
import Radio from "@components/ui/CheckBox/Radio/Radio"
import { Button } from "@components/ui/Button"
import { Option } from "@framework/types/types"

type OptionName = "on" | "off"

const optionMapper: Record<OptionName, Option<OptionName>> = {
  on: {
    value: "on",
    label: "On: Send traffic to the most relevant URLs on your site",
  },
  off: { value: "off", label: "Off: Send traffic to provided URLs only" },
}

interface FormProps {
  editMode?: boolean
}

export const FinalURLExpansionSettingsForm: React.FC<FormProps> = observer(
  ({ editMode = false }) => {
    const [value, setValue] = useState<OptionName>("on")

    if (!editMode) return <>{optionMapper[value]?.label ?? ""}</>
    return (
      <Stack gutter="16">
        <Typography type="caption2" color="black40Color">
          Use more targeted URLs when it’s likely to result in better
          performance
        </Typography>

        <Stack gutter="24">
          <Stack gutter="16">
            <Label text={optionMapper.on.label}>
              <Radio id="on" value={value} onChange={() => setValue("on")} />
            </Label>

            <Typography type="h5">
              This campaign is subdividing inventory using listing group. Final
              URL expansion will only send traffic to landing pages related to
              the campaign’s product inventory
            </Typography>

            {/* <Button size="small" variant="ghost" color="primary" disabled>
              Exclude URLs
            </Button> */}

            {/* <Label text={optionMapper.off.label}>
              <Radio id="off" value={value} onChange={() => setValue("off")} />
            </Label> */}
          </Stack>
        </Stack>
      </Stack>
    )
  }
)

export default FinalURLExpansionSettingsForm
