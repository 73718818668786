import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"

import AdContent from "./AdContent"
import { AdCopyPreview } from "../types"

import styles from "./AdCard.module.scss"

export interface PreviewProps {
  variant?: "mobile" | "desktop"
  data?: AdCopyPreview
}

const AdCard: React.FC<PreviewProps> = observer(
  ({ data = {}, variant = "desktop" }) => {
    const { website = "", headlines = [], descriptions = [] } = data

    const isValid = !!website && headlines.length > 1 && descriptions.length > 1
    return (
      <div className={clsx(styles.root)}>
        {isValid ? (
          <AdContent
            website={website}
            headlines={headlines}
            descriptions={descriptions}
            variant={variant}
          />
        ) : (
          <div className={styles.placeholder}>
            Preview mode can’t work correctly.
            <br />
            The Ad Copy is empty now.
          </div>
        )}
      </div>
    )
  }
)

export default AdCard
