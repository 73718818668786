/* eslint-disable no-unused-expressions */
import { observable, action } from "mobx"
import { ShopifyStatusUpdate } from "@framework/types/account"
import accountService from "@services/account.service"
import RootStore from "../RootStore"

export class ProductDashboardStore {
  @observable rootStore: RootStore

  @observable isStatusLoading: boolean = false

  @observable statusError: string | null = null

  @observable status: ShopifyStatusUpdate | null = null

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @action loadStatus = async (accountId: number) => {
    try {
      this.isStatusLoading = true

      const response = await accountService.fetchProductFeetStatusUpdate(
        accountId
      )

      if (response.data.data) {
        this.status = response.data.data
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isStatusLoading = false
    }
  }
}

export default ProductDashboardStore
