import { useMemo } from "react"
import moment from "moment"
import { useStore } from "@store/index"

const useCampaignDate = () => {
  const { checkbookStore } = useStore()
  const { currentCampaignDate, campaignDate, setCampaignDate } = checkbookStore

  const minCampaignDate: Date = useMemo(() => {
    const currMoment = moment.utc().startOf("day")
    return currMoment.toDate()
  }, [campaignDate])

  const maxCampaignDate: Date = useMemo(() => {
    const currMoment = moment.utc().add(1, "year").startOf("day")
    return currMoment.toDate()
  }, [currentCampaignDate])

  return { campaignDate, setCampaignDate, minCampaignDate, maxCampaignDate }
}

export default useCampaignDate
