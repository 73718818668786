import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { PlatformReport } from "@store/reports/types"
import NoData from "@components/ui/NoData/NoData"
import { useTableContext } from "./TableContext"
import { PlatformRow } from "./PlatformRow"
import { sortReports } from "./utils"

interface PlatformListProps {
  list: string[]
  mapper: MapperType[]
}

const PlatformList: React.FC<PlatformListProps> = observer(
  ({ list, mapper }) => {
    const {
      reports: { extendedReport },
    } = useStore()

    const context = useTableContext()

    const reports = useMemo(
      () =>
        sortReports(
          list
            .map((id) => extendedReport.platforms.get(id))
            .filter(Boolean) as PlatformReport[],
          context.sortBy
        ),
      [list, context.sortBy]
    )

    if (!reports.length) return <NoData>Not found</NoData>
    return (
      <>
        {reports.map((report) => (
          <PlatformRow data={report} mapper={mapper} key={report.id} />
        ))}
      </>
    )
  }
)

export default PlatformList
