import { computed } from "mobx"

export abstract class AbstractFilter {
  @computed abstract tempFiltersCount: number

  @computed abstract activeFiltersCount: number

  @computed abstract filtersCountDifference: number

  @computed abstract isSync: boolean

  abstract resetTemp: () => void

  abstract resetAll: () => void

  abstract applyFilter: () => void
}

export default AbstractFilter
