import React from "react"

import OnClickTooltip from "@components/ui/Tooltip/TooltipContainer/OnClickTooltipContainer"
import { ImageCarusele } from "./ImageCarusele"

import styles from "./ImagesColumn.module.scss"

interface ImagesViewColumnProps {
  list: string[]
}

const ImagesViewColumn: React.FC<ImagesViewColumnProps> = ({
  list,
  children,
}) => (
  <div className={styles.root}>
    <OnClickTooltip
      placement="top"
      toolTip={
        <div className={styles.tooltip}>
          <ImageCarusele list={list} />
        </div>
      }
    >
      {children}
    </OnClickTooltip>
  </div>
)

export default ImagesViewColumn
