/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { GoogleMap, LoadScript } from "@react-google-maps/api"
import { observer } from "mobx-react-lite"

import config from "@root/config"
import CustomMapMarker from "./CustomMapMarker"

const defaultMapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  styles: [
    {
      elementType: "labels",
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels",
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
}

type LocationsMapProps = {
  selected: any
  points: any[]
  center: any
  className?: string
  toolTip?: React.ReactNode
  getColor?: (item?: any) => string
  handleSelect: (item?: any) => void
}

const containerStyle = {
  width: "100%",
  height: "100%",
}

const LocationsMap: React.FC<LocationsMapProps> = observer(
  ({
    className,
    selected,
    points,
    center,
    toolTip,
    getColor,
    handleSelect,
  }) => (
    <div className={className}>
      <LoadScript googleMapsApiKey={config.GOOGLE_API_KEY}>
        <GoogleMap
          options={defaultMapOptions}
          mapContainerStyle={containerStyle}
          center={center}
          onClick={() => handleSelect()}
          zoom={14}
        >
          {points.map((item) => {
            const isSelected = selected && item.id === selected.id
            const color = getColor ? getColor(item) : "black"
            return (
              <CustomMapMarker
                item={item}
                color={color}
                isSelected={isSelected}
                handleSelect={handleSelect}
                key={item.id}
              />
            )
          })}
          {toolTip}
        </GoogleMap>
      </LoadScript>
    </div>
  )
)

export default React.memo(LocationsMap)
