/* eslint-disable react/no-array-index-key */
import React, { ReactNode, useState } from "react"
import clsx from "clsx"

import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import { TableMapper } from "./types"

import styles from "./Table.module.scss"

export type TableRowProps = {
  mapper: TableMapper[]
  dataset: any
  renderColumn: (data: any, mapper: TableMapper) => React.ReactNode
}

export const TableRow: React.FC<TableRowProps> = ({
  mapper,
  dataset,
  renderColumn,
}) => (
  <div className={styles.row}>
    {mapper.map((mapper) => (
      <Typography className={styles.item}>
        {renderColumn(dataset[mapper.name], mapper)}
      </Typography>
    ))}
  </div>
)

export type WrappableContainerProps = {
  withDetails?: boolean
  body: ReactNode
  children: ReactNode[]
  className?: string
}

export const WrappableRowContainer: React.FC<WrappableContainerProps> = ({
  body,
  children,
  withDetails = false,
  className,
}) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Box
      padding="none"
      className={clsx(
        styles.body,
        { [styles.withDetails]: withDetails },
        className
      )}
    >
      {body}
      {isOpen ? (
        <div className={styles.detailsContainer}>{children}</div>
      ) : null}
      {withDetails && !!children.length ? (
        <Icon
          name="arrow-down"
          className={styles.openButton}
          rotateAngle={isOpen ? 180 : 0}
          onClick={() => setOpen(!isOpen)}
        />
      ) : null}
    </Box>
  )
}

export default WrappableRowContainer
