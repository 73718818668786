/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { Legend } from "@components/ui/Charts/Legends/LegendList"
import { CampaignEntityName } from "@framework/types/manageCampaign"
import { getManageCampaignOption } from "@framework/constants/manageCampaign"
import { useStore } from "@store/index"
import NoData from "@components/ui/NoData/NoData"
import { CampaignData } from "@framework/types/adCopy"
import { useSearchContext } from "@framework/prototypes/SearchContext/index"
import CampaignsTable from "./CampaignsTable/CampaignsTable"

import styles from "./index.module.scss"

const legends: CampaignEntityName[] = ["campaign", "groupAd"]

type CampaignsListProps = {
  className?: string
}

const CampaignsList: React.FC<CampaignsListProps> = observer(
  ({ className }) => {
    const {
      adCopyStore: { campaignsCollection },
    } = useStore()

    const searchContext = useSearchContext()
    const [campaigns, setCampaigns] = useState<CampaignData[]>([])

    useEffect(() => {
      setCampaigns(
        campaignsCollection.searchCampaigns(searchContext.searchQuery)
      )
    }, [campaignsCollection.allCampaigns, searchContext.searchQuery])

    return (
      <Stack
        gutter="24"
        align="stretch"
        className={clsx(styles.root, className)}
      >
        <Stack direction="row" className={styles.header}>
          {legends.map((name) => {
            const item = getManageCampaignOption(name)
            return (
              <Legend
                label={item.label}
                color={item.color}
                key={item.shortName}
              />
            )
          })}
        </Stack>

        <div className={styles.body}>
          {campaigns.length > 0 ? (
            <CampaignsTable campaigns={campaigns} />
          ) : (
            !campaignsCollection.isLoading && (
              <NoData>No campaigns found</NoData>
            )
          )}
        </div>
      </Stack>
    )
  }
)

export default CampaignsList
