import { ITypographyProps } from "@components/ui/Typography/Typography"

export const formatCompetition = (value: number | undefined) => {
  if (!value || Number.isNaN(value)) return "-"
  if (value > 0.66) return "High"
  if (value >= 0.33) return "Med"
  return "Low"
}

/**
 * @deprecated
 */
export const getCompetitionColor = (
  value?: number
): ITypographyProps["color"] => {
  if (!value || Number.isNaN(value)) return "darkGray"
  if (value > 0.66) return "success100Color"
  if (value >= 0.33) return "info100Color"
  return "error100Color"
}
