import React from "react"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"

import styles from "./index.module.scss"

const Header: React.FC<{
  title: React.ReactNode
  subtitle?: React.ReactNode
}> = ({ title, subtitle }) => (
  <Stack gutter="4" className={styles.header}>
    <Typography type="h2" color="black100Color">
      {title}
    </Typography>
    {subtitle != null && (
      <Typography type="h5" color="black70Color" inline>
        {subtitle}
      </Typography>
    )}
  </Stack>
)

export default Header
