/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
import React from "react"
import clsx from "clsx"

import TabItem from "./TabItem"
import { NavOption } from "./types"

import styles from "./Tabs.module.scss"

export type NavBarProps = {
  options: NavOption[]
  currentValue?: NavOption | string
  underlined?: boolean
  fullWidth?: boolean
  disabled?: boolean
  className?: string
  direction?: "vertical" | "horizontal"
  children?: (option: NavOption, isActive: boolean) => React.ReactNode
  onChange?: (value: NavOption) => void
}

export const Tabs: React.FC<NavBarProps> = React.memo(
  ({
    className,
    options,
    currentValue,
    underlined = false,
    fullWidth = false,
    disabled = false,
    direction = "horizontal",
    children,
    onChange,
  }) => {
    const activeItem =
      typeof currentValue === "string"
        ? options.find(({ value }) => value === currentValue)
        : currentValue

    return (
      <div
        className={clsx(
          styles.root,
          { [styles.underlined]: underlined, [styles.fullWidth]: fullWidth },
          styles[`dir-${direction}`],
          className
        )}
      >
        {options.map((item) => {
          const { label, icon, iconSVG, value } = item
          const isActive = activeItem?.value === value
          return (
            <TabItem
              active={isActive}
              onClick={() => onChange && onChange(item)}
              disabled={disabled}
              before={icon}
              key={value}
              iconSVG={iconSVG}
            >
              {children ? children(item, isActive) : label}
            </TabItem>
          )
        })}
      </div>
    )
  }
)

export default Tabs
