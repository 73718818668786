import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import * as Yup from "yup"

import Box from "@components/ui/Box/Box"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import TextField from "@components/ui/TextField/TextField"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import IconButton from "@components/ui/Button/IconButton"
import Label from "@components/ui/Label/Label"

import styles from "./Row.module.scss"

type RowProps = {
  defValue?: string
  maxCharacterNumber: number
  canDelete?: boolean
  newRow?: boolean
  onSave: (newValue: string) => void
  onDelete: () => void
}

const Row: React.FC<RowProps> = observer(
  ({
    canDelete,
    defValue = "",
    maxCharacterNumber: maxChars,
    newRow = false,
    onSave,
    onDelete,
  }) => {
    const { manageProfileStore } = useStore()
    const { isEdit } = manageProfileStore

    const [editMode, setEditMode] = useState(false)
    const [value, setValue] = useState("")
    const [error, setError] = useState()

    const validationScheme = useMemo(
      () =>
        Yup.string()
          .required("Required")
          .test(
            "length",
            `Must be less or equal to ${maxChars} symbols`,
            (val) => (val ?? "").length <= maxChars
          ),
      [maxChars]
    )

    useEffect(() => {
      validationScheme
        .validate(value)
        .then(() => setError(undefined))
        .catch((error) => setError(error.message))
    }, [value, validationScheme])

    const handleSave = useCallback(() => {
      if (value && value.length <= maxChars) {
        onSave(value)
        setEditMode(false)
      }
    }, [onSave, setEditMode, value, maxChars])

    const handleCancel = useCallback(() => {
      setValue(defValue)
      setEditMode(false)
      if (newRow) onDelete()
    }, [setValue, setEditMode, onDelete, defValue])

    const handleDelete = useCallback(() => {
      setValue(defValue)
      setEditMode(false)
      onDelete()
    }, [setValue, setEditMode, onDelete, defValue])

    const isTouched = defValue !== value
    const isValid = isTouched && !error

    useEffect(() => {
      setValue(defValue)
      setEditMode(!defValue.length)
    }, [defValue])

    return (
      <Box className={clsx(styles.row, { [styles.gridTemplate]: !isEdit })}>
        {isEdit && editMode ? (
          <Label error={isTouched ? error : undefined}>
            <TextField
              value={value}
              onChange={(e) => setValue(e.target.value)}
              error={isTouched && !!error}
            />
          </Label>
        ) : (
          <Typography type="h5">{defValue}</Typography>
        )}
        <Typography className={styles.textRight} type="h5" color="gray">
          {`${value.length}/${maxChars}`}
        </Typography>
        <ActionButton
          show={isEdit}
          isValid={isValid}
          editMode={editMode}
          canDelete={canDelete}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={handleDelete}
          onSwitchEditMode={setEditMode}
        />
      </Box>
    )
  }
)

type ActionButtonProps = {
  editMode: boolean
  show: boolean
  isValid: boolean
  canDelete?: boolean
  onSave: () => void
  onCancel: () => void
  onDelete: () => void
  onSwitchEditMode: (value: boolean) => void
}

const ActionButton: React.FC<ActionButtonProps> = ({
  show,
  isValid,
  editMode,
  canDelete = true,
  onSave,
  onCancel,
  onDelete,
  onSwitchEditMode,
}) => {
  if (!show) return null
  return editMode ? (
    <div className={styles.actions}>
      <Button variant="outlined" onClick={isValid ? onSave : onCancel}>
        {isValid ? "Save" : "Cancel"}
      </Button>
    </div>
  ) : (
    <div className={styles.actions}>
      <IconButton
        variant="ghost"
        color="primary"
        title="Edit"
        onClick={() => onSwitchEditMode(true)}
        // className={styles.buttonWithIcon}
      >
        <Icon name="pen" />
      </IconButton>
      {canDelete && (
        <IconButton
          variant="ghost"
          color="primary"
          title="Delete"
          onClick={onDelete}
          // className={styles.buttonWithIcon}
        >
          <Icon name="trash" />
        </IconButton>
      )}
    </div>
  )
}

export default Row
