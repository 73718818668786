import { action, observable } from "mobx"

abstract class Loadable<T = unknown, E extends any = string> {
  @observable data: T | null = null

  @observable isLoading: boolean = false

  @observable error: E | null = null
}

export default Loadable
