import React from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import { diff } from "deep-object-diff"
import isEmpty from "lodash/isEmpty"

import {
  AdCopyDetailsData,
  AdCopyInstance,
  AdCopyPrompts,
  EditAdCopyData,
} from "@framework/types/adCopy"
import { ID } from "@framework/types/types"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import Typography from "@components/ui/Typography/Typography"
import { SECOND } from "@framework/constants/time"
import { DeepPartial } from "@framework/types/utils"
import useToggle from "@framework/hooks/useToggle"
import { adCopyPromptsValidationSchema } from "@components/EditAdCopyModal/AdCopyWizardModal/validation"
import Footer from "./Footer"
import Header from "./Header"
import EditAdCopyMultiForm from "./EditAdCopyMultiForm"
import { EditAdCopyFormData, editAdCopyValidationSchema } from "./utils"
import RegenerateAdCopySidebar from "../GenerateAdCopySidebar"
import { PromptsContext } from "./PromptsContext"
import { useEditAdCopyContext } from "./EditAdCopyContext"

import styles from "./EditAdCopyForm.module.scss"

const validationSchema = editAdCopyValidationSchema

export interface EditAdCopyFormProps {
  adCopyIds: ID[]
  initialValues?: DeepPartial<AdCopyDetailsData>
  initialPrompts?: AdCopyPrompts
  withAISupport?: boolean
}

export const EditAdCopyForm: React.FC<EditAdCopyFormProps> = observer(
  ({
    adCopyIds = [],
    withAISupport = false,
    initialValues = {},
    initialPrompts,
  }) => {
    const editContext = useEditAdCopyContext()

    const alert = useAlert()

    const total = adCopyIds.length ?? 0

    const regenerateSidebar = useToggle()
    const [actualPrompts, setActualPrompts] = React.useState<
      AdCopyPrompts | undefined
    >(initialPrompts)

    const isPromptsValid = React.useMemo(
      () => adCopyPromptsValidationSchema.isValidSync(actualPrompts),
      [actualPrompts]
    )

    const handleRegenerate = () => {
      regenerateSidebar.setOpened(true)
    }

    const afterSubmit = (error?: string | null) => {
      if (error)
        alert.error(<AlertMessage title="Error occurred" description={error} />)
      if (!error) editContext.onClose()
    }

    const handleSubmit = async (form: EditAdCopyData) => {
      // edit new
      if (editContext.isEdit) {
        const data = transformEditAdCopyForm(
          validationSchema.cast(form, { stripUnknown: true }),
          { ...validationSchema.getDefault(), ...initialValues }
        )

        if (isEmpty(data)) {
          afterSubmit(null)
          return
        }

        const error = await editContext.onUpdate({
          ...data,
          promptSettings: actualPrompts,
        })

        afterSubmit(error)
      } else {
        // add new
        const data = transformAddAdCopyForm(
          validationSchema.cast(form, { stripUnknown: true })
        )

        const error = await editContext.onCreate({
          ...data,
          promptSettings: actualPrompts,
        })

        afterSubmit(error)
      }
    }

    const formik = useFormik<any>({
      initialValues: {
        ...validationSchema.getDefault(),
        ...initialValues,
      },
      validationSchema,
      onSubmit: handleSubmit,
    })

    React.useEffect(() => {
      const errorsList = Object.entries(formik.errors)
      if (formik.submitCount > 0 && !formik.isSubmitting && errorsList.length) {
        alert.error(
          <AlertMessage
            title="Validation failed"
            description={
              <>
                {errorsList.map(([_, message]) => {
                  if (typeof message !== "string") return null
                  return <Typography color="error80Color">{message}</Typography>
                })}
              </>
            }
          />,
          { timeout: SECOND * 30 }
        )
      }
    }, [formik.submitCount, formik.isSubmitting])

    return (
      <PromptsContext.Provider
        value={isPromptsValid ? actualPrompts : undefined}
      >
        {!regenerateSidebar.isOpened ? (
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className={styles.root}>
              <Header total={total} withAISupport={withAISupport} />

              <EditAdCopyMultiForm />

              <Footer onRegenerate={handleRegenerate} />
            </form>
          </FormikProvider>
        ) : (
          <RegenerateAdCopySidebar
            isOpened={regenerateSidebar.isOpened}
            initialData={actualPrompts}
            onClose={() => regenerateSidebar.setOpened(false)}
            onSubmit={({ prompts, adCopyDetails }) => {
              regenerateSidebar.setOpened(false)
              const { finalUrl, mobileUrl, ...rest } = adCopyDetails
              formik.setValues({ ...formik.values, ...rest })
              setActualPrompts(prompts)
            }}
          />
        )}
      </PromptsContext.Provider>
    )
  }
)

export default EditAdCopyForm

const transformAddAdCopyForm = ({
  adGroup,
  campaign,
  ...rest
}: EditAdCopyFormData): AdCopyInstance => ({
  ...rest,
  campaign_id: Number(campaign),
  ad_group_id: Number(adGroup),
})

const transformEditAdCopyForm = (
  data: EditAdCopyFormData,
  initialData: DeepPartial<AdCopyDetailsData>
): Partial<EditAdCopyData> => {
  const { campaign, adGroup, ...rest } = diff(
    initialData,
    data
  ) as Partial<EditAdCopyFormData>
  return {
    ...rest,
  }
}
