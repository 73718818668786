/* eslint-disable no-useless-escape */
import keyBy from "lodash/keyBy"
import moment from "moment"

import {
  TimeSegmentedPerformanceReport,
  PerformanceReport,
  metricNamesList,
  MetricNameType,
} from "@framework/types/metrics"
import { DataSetType as LineChartDataSetType } from "@components/ui/Charts/LineChart/LineChart"
import { DataSetType as BarChartDataSetType } from "@components/ui/Charts/BarChart/BarChart"
import { DEF_YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import {
  extractTimeSegmentedPerformanceValues,
  periodicityToDateFormat,
} from "@framework/data-transformers/metrics"
import { getMetricDescriptor } from "@framework/constants/metrics"

export const transformValue = (value: number | string): number =>
  typeof value === "string" ? Number(value.replace(/([^0-9^\.])/g, "")) : value

const getEmptyMetric = (): PerformanceReport =>
  Object.fromEntries(
    metricNamesList.map((name) => [name, { Value: 0 }])
  ) as PerformanceReport

export const extractKPIData = (
  metrics: MetricNameType[],
  performances: PerformanceReport[],
  suffix: string = ""
): LineChartDataSetType[] =>
  metrics.map((metricName, idx) => {
    const metric = getMetricDescriptor(metricName)
    if (metric == null) throw new Error(`Metric ${metricName} is not supported`)
    return {
      key: metric.name,
      label: `${metric.label} ${suffix}`,
      yAxisID: `y${idx + 1}`,
      data: extractTimeSegmentedPerformanceValues(metric.name, performances),
    }
  })

export const normalizePrevYearMetrics = (
  currYearLabels: string[],
  metrics: TimeSegmentedPerformanceReport[],
  periodicity: PerformanceReportPeriodicity
): TimeSegmentedPerformanceReport[] => {
  const keyFormat = periodicityToDateFormat(periodicity)
  const metricsMap = keyBy(metrics, (it) =>
    moment(it.Period, DEF_YYYY_MM_DD_DATE_FORMAT)
      .add(1, "year")
      .format(keyFormat)
  )
  return currYearLabels.map((label) => {
    const key = moment(label, DEF_YYYY_MM_DD_DATE_FORMAT).format(keyFormat)
    return metricsMap[key] ?? { Period: label, Metrics: getEmptyMetric() }
  })
}

export const extractMonthlyAdSpend = (
  performanceList: TimeSegmentedPerformanceReport[],
  date: Date
): BarChartDataSetType => ({
  label: moment.utc(date).format("YYYY"),
  data: performanceList.reduce((acc, metricData) => {
    const month = moment(metricData.Period, DEF_YYYY_MM_DD_DATE_FORMAT).month()
    acc[month] = metricData.Metrics?.Cost?.Value ?? 0
    return acc
  }, new Array(12).fill(0)),
})
