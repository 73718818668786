import React from "react"
import clsx from "clsx"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import AreaChart from "@components/ui/Charts/AreaChart/AreaChart"

import styles from "./SearchChart.module.scss"

export const SearchChart: React.FC<{
  className?: string
  data: number[]
  labels: string[]
}> = ({ className, data, labels }) => (
  <Box className={clsx(styles.root, className)}>
    <Typography type="h3" weight="bold">
      Search
    </Typography>
    <Typography type="h5" color="black60Color">
      36 month trend
    </Typography>
    <div className={styles.body}>
      <AreaChart data={data} labels={labels} />
    </div>
  </Box>
)

export default SearchChart
