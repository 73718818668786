import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { reportLevelDescription } from "@framework/constants/report"
import { MetaCampaignReport } from "@store/reports/types"
import MetaAdSetReportList from "./MetaAdSetReportList"

type MetaCampaignRowProps = {
  data: MetaCampaignReport
  mapper: MapperType[]
}

const MetaCampaignRow: React.FC<MetaCampaignRowProps> = observer(
  ({ data: report, mapper }) => (
    <ClosableRow
      id={report.id}
      component={
        <TypedRow
          label={report?.label ?? "Campaign"}
          data={report}
          leftTip={<TypedTip data={reportLevelDescription.campaign} />}
          mapper={mapper}
        />
      }
    >
      <MetaAdSetReportList list={report.adSetIds} mapper={mapper} />
    </ClosableRow>
  )
)

export default MetaCampaignRow
