import React from "react"
import clsx from "clsx"

import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import { observer } from "mobx-react-lite"
import { useController, useStore } from "@store/index"
import { EditAdCopyTaskType } from "@store/manageProfile/edit-adCopy.store"
import Typography from "@components/ui/Typography/Typography"
import Header from "./Header"
import HeaderColumn from "./HeaderColumn"

import styles from "./index.module.scss"

type AdCopyHeaderProps = {
  selected?: boolean
  selectedCount?: number
  total?: number
  onSelect?: () => void
}

const AdCopyHeader: React.FC<AdCopyHeaderProps> = observer(
  ({ selectedCount = 0, total, selected = false, onSelect }) => {
    const { adCopyManagement } = useController()
    const {
      accountStore: { accountId },
      adCopyStore: { header, activeAdCopies, isEditingAvailable },
    } = useStore()

    const handleTask = (task: EditAdCopyTaskType) => async () => {
      if (accountId == null) return
      const ids = await activeAdCopies.getSelectedAdCopies()
      if (task === "edit") adCopyManagement.createEditAdCopyTask(accountId, ids)
      if (task === "duplicate") adCopyManagement.createDuplicateAdCopyTask(ids)
      if (task === "delete") adCopyManagement.createDeleteAdCopyTask(ids)
      if (task === "restore") adCopyManagement.createRestoreAdCopyTask(ids)
    }

    const showControl = selectedCount > 1

    return (
      <Header
        total={total}
        selectedCount={selectedCount}
        selected={selected}
        onSelect={onSelect}
        className={styles.header}
        rootClassName={clsx({
          [styles.withControl]: showControl,
        })}
      >
        {showControl ? (
          <Stack
            direction="row"
            gutter="12"
            align="stretch"
            className={styles.control}
          >
            <Typography className={styles.counter}>{selectedCount}</Typography>

            <Button
              color="secondary"
              startIcon={{ name: "pen" }}
              onClick={handleTask("edit")}
              disabled={!isEditingAvailable}
            >
              Edit
            </Button>

            <Button
              color="secondary"
              startIcon={{ name: "file-copy" }}
              onClick={handleTask("duplicate")}
              disabled={!isEditingAvailable}
            >
              Duplicate
            </Button>

            <Button
              color="secondary"
              startIcon={{ name: "trash" }}
              onClick={handleTask("delete")}
              disabled={!isEditingAvailable}
            >
              Delete
            </Button>

            <Button
              color="secondary"
              startIcon={{ name: "reset" }}
              onClick={handleTask("restore")}
              disabled={!isEditingAvailable}
            >
              Restore
            </Button>
          </Stack>
        ) : (
          <>
            <HeaderColumn
              name="name"
              value={header.order}
              onSort={adCopyManagement.sortBy}
            >
              Headline text
            </HeaderColumn>

            <HeaderColumn
              name="status"
              value={header.order}
              onSort={adCopyManagement.sortBy}
            >
              Status
            </HeaderColumn>

            <HeaderColumn
              name="adGroup"
              value={header.order}
              onSort={adCopyManagement.sortBy}
            >
              Ad Group
            </HeaderColumn>

            <HeaderColumn
              name="condition"
              value={header.order}
              onSort={adCopyManagement.sortBy}
            >
              Edited
            </HeaderColumn>
          </>
        )}
      </Header>
    )
  }
)

export default AdCopyHeader
