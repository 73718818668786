import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"

import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Loader from "@components/ui/Loader/Loader"
import { useStore } from "@store/index"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import FacebookAccountCredentialsForm, {
  FacebookAccountCredentialsFormData,
  validationSchema,
} from "./FacebookAccountCredentialsForm"

import styles from "./LinkFacebookAccountModal.module.sass"

type FormData = FacebookAccountCredentialsFormData

type EditMemberModalProps = {
  isOpen: boolean
  onSubmit?: () => void
  onClose: () => void
}

const LinkFacebookAccountModal: React.FC<EditMemberModalProps> = observer(
  ({ isOpen, onClose, onSubmit }) => {
    const alert = useAlert()
    const {
      accountLinkingStore,
      accountStore: { accountId },
    } = useStore()

    const [isLoading, setLoading] = useState(false)

    const handleSubmit = async (form: FormData) => {
      try {
        setLoading(true)

        if (accountId == null) throw new Error("Account id is required")

        const data = validationSchema.cast(form, {
          stripUnknown: true,
        })

        const validationError =
          await accountLinkingStore.validateMetaAccountCredentials(
            accountId,
            data.adAccountId
          )

        if (validationError) {
          alert.error(
            <AlertMessage
              title="Validation failed"
              description={validationError}
            />
          )
          return
        }

        const linkingError = await accountLinkingStore.linkMetaAdsAccount(
          accountId,
          data.name,
          Number(data.adAccountId)
        )

        if (linkingError) {
          alert.error(
            <AlertMessage title="Linking failed" description={linkingError} />
          )
          return
        }

        onSubmit?.()
      } catch (error) {
        alert.error(
          <AlertMessage title="Linking failed" description="Unexpected error" />
        )
      } finally {
        setLoading(false)
      }
    }

    const formik = useFormik<FormData>({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      onSubmit: handleSubmit,
    })

    return (
      <Modal isOpen={isOpen} onClose={onClose} hideHeader>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <Stack gutter="24" align="stretch" className={styles.root}>
              <Typography type="h2">
                Add Meta Account
                <Loader isLoading={isLoading} />
              </Typography>

              <FacebookAccountCredentialsForm />

              {/* footer */}
              <Stack direction="row" justify="flex-end">
                <Button
                  color="secondary"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={isLoading}>
                  Connect
                </Button>
              </Stack>
            </Stack>
          </FormikProvider>
        </form>
      </Modal>
    )
  }
)

export default LinkFacebookAccountModal
