import clsx from "clsx"
import React, { useContext } from "react"

import { SortByType } from "@framework/types/types"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { MapperType } from "./types"

import styles from "./WaterfallTable.module.scss"
import { TableOpenContext } from "./WaterfallTable"

export interface HeaderProps {
  label: string
  isLabelSortable?: boolean
  mapper?: MapperType[]
  labelClassName?: string
  className?: string
  sortBy?: SortByType
  onSort?: (value: string) => void
}

export const Header: React.FC<HeaderProps> = ({
  className,
  labelClassName,
  label,
  isLabelSortable,
  onSort,
  sortBy,
  mapper,
}) => {
  const { withTip } = useContext(TableOpenContext)

  return (
    <div
      className={clsx(styles.header, { [styles.withTip]: withTip }, className)}
    >
      <div className={styles.label}>
        <HeaderColumn
          name="label"
          className={labelClassName}
          label={label}
          sortable={isLabelSortable}
          onSort={onSort}
          sortBy={sortBy}
        />
      </div>
      {mapper?.map((item) => (
        <HeaderColumn
          name={item.name}
          label={item.label}
          sortable={item.sortable}
          onSort={onSort}
          sortBy={sortBy}
          widthBasis={item.widthBasis}
          key={item.name}
        />
      ))}
    </div>
  )
}

export interface HeaderColumnProps {
  name: string
  className?: string
  label: string
  widthBasis?: string
  sortable?: boolean
  sortBy?: SortByType
  onSort?: (value: string) => void
}
export const HeaderColumn: React.FC<HeaderColumnProps> = ({
  name,
  className,
  label,
  sortable,
  onSort,
  sortBy,
  widthBasis,
}) => (
  <Typography
    type="subhead1"
    color="gray"
    className={clsx(styles.column, className, {
      [styles.sortable]: sortable,
    })}
    style={{ flexBasis: widthBasis, minWidth: widthBasis }}
    upperCase
    key={label}
    onClick={() => sortable && onSort?.(name)}
  >
    {label}
    {sortBy && sortable && (
      <span className={styles.sortArrow}>
        <Icon
          name={sortBy.value === name ? "arrow-down" : "none"}
          rotateAngle={sortBy.direction ? 180 : 0}
        />
      </span>
    )}
  </Typography>
)

export default Header
