import { action, computed, observable } from "mobx"
import { AdsReportType } from "@framework/types/account"
import { ID } from "@framework/types/types"

class AdsStore {
  // INJECTIONS

  // CONSTRUCTOR

  // STATE

  @observable data: AdsReportType[] | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  // ACTIONS

  @action storeData = (data: AdsReportType[]) => {
    this.data = data
  }

  @computed get dataIndex() {
    return new Map(
      this.data?.map((it) => [
        this.getKey(it.Id, it.AdGroupId, it.CampaignId),
        it,
      ])
    )
  }

  getKey = (adId: ID, adGroupId: ID, campaignId: ID) =>
    `${adId}_${adGroupId}_${campaignId}`

  @computed get getByID() {
    const { dataIndex } = this
    return (adId: ID, adGroupId: ID, campaignId: ID) =>
      dataIndex.get(this.getKey(adId, adGroupId, campaignId))
  }
}

export default AdsStore
