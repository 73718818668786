import React, { useEffect } from "react"
import { nanoid } from "nanoid"

import { GlobalScrollContext } from "."

export const useGlobalScrollSwitch = (
  disableOnMount: boolean = false
): [boolean, (value: boolean) => void] => {
  const [id] = React.useState(nanoid())
  const context = React.useContext(GlobalScrollContext)
  if (context == null) throw new Error("Can't find any GlobalScrollContext")

  const toggle = React.useCallback(
    (value: boolean) => context.toggleValue(id, value),
    [context.toggleValue]
  )

  useEffect(() => {
    if (disableOnMount) toggle(true)
    return () => toggle(false)
  }, [disableOnMount])

  return [context.isDisabled, toggle]
}

export default useGlobalScrollSwitch
