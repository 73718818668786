import React, { CSSProperties, useLayoutEffect, useRef, useState } from "react"

export interface StickynessProps {
  topOffset?: number
  bottomOffset?: number
  containerStyles?: CSSProperties
  stickyStyle?: CSSProperties
  className?: string
}

export const Sticky: React.FC<StickynessProps> = ({
  children,
  topOffset,
  bottomOffset,
  containerStyles = {},
  stickyStyle = {},
  className,
}) => {
  const scrollContainer = useRef<any>()

  const [fixStyle, setFixStyle] = useState<CSSProperties>({})
  const [isFixed, setFixed] = useState(false)

  scrollContainer.current = window

  const containerRef = useRef<HTMLDivElement>(null)
  const inner = useRef<HTMLDivElement>(null)

  const handleScroll = (e?: Event) => {
    const { current: scrollShape } = scrollContainer
    const { current: staticShape } = containerRef
    const { current: innerShape } = inner

    if (staticShape && scrollShape && innerShape) {
      const bb = scrollShape.innerHeight // bottom boundary
      const containerRect = staticShape.getBoundingClientRect()

      if (bottomOffset !== undefined) {
        const bp = containerRect.bottom // bottom pos
        if (bp - bb + bottomOffset < 0) {
          setFixStyle({
            left: containerRect.left,
            width: containerRect.width,
            height: innerShape.clientHeight,
          })
          setFixed(true)
        } else {
          setFixStyle({})
          setFixed(false)
        }
      }
      if (topOffset !== undefined) {
        const tp = containerRect.top // top pos
        if (tp + topOffset < 0) {
          setFixStyle({
            left: containerRect.left,
            width: containerRect.width,
            height: innerShape.clientHeight,
          })
          setFixed(true)
        } else {
          setFixStyle({})
          setFixed(false)
        }
      }
    }
  }

  useLayoutEffect(() => {
    handleScroll()
    scrollContainer.current?.addEventListener("scroll", handleScroll)

    return () => {
      scrollContainer.current?.removeEventListener("scroll", handleScroll)
    }
  }, [scrollContainer, topOffset])

  useLayoutEffect(() => {
    scrollContainer.current?.addEventListener("resize", handleScroll)

    return () => {
      scrollContainer.current?.removeEventListener("resize", handleScroll)
    }
  }, [scrollContainer, topOffset])

  const style: CSSProperties = {
    ...stickyStyle,
    ...fixStyle,
    position: "fixed",
  }

  return (
    <div
      ref={containerRef}
      style={{
        ...(isFixed ? { ...containerStyles, height: fixStyle.height } : null),
        pointerEvents: "none",
      }}
    >
      <div
        ref={inner}
        style={{ ...(isFixed ? style : null), pointerEvents: "all" }}
        className={className}
      >
        {children}
      </div>
    </div>
  )
}

export default Sticky
