import React from "react"
import { observer } from "mobx-react-lite"

import FormTextField, {
  FormFieldProps,
} from "@framework/prototypes/FormTextField"
import Stack from "@components/ui/Stack/Stack"
import WrappableContainer from "./WrappableContainer"

import styles from "./EditAdCopyForm.module.scss"

export interface LinksFormProps {}

export const LinksForm: React.FC<LinksFormProps> = observer(() => {
  const handleUrlFieldBlur: FormFieldProps["afterBlur"] = (e, fieldContext) => {
    const [field, _, helpers] = fieldContext

    const value: any = field?.value

    if (typeof value === "string" && !value.match(/^(http|https):\/\//)) {
      let newValue: string = value

      newValue = `http://${value}`

      helpers.setValue(newValue)
    }
  }

  return (
    <WrappableContainer label="URLs" defaultOpened>
      <Stack gutter="12" align="stretch" className={styles.sectionBody}>
        <FormTextField
          label="Final URL"
          name="finalUrl"
          placeholder="-"
          afterBlur={handleUrlFieldBlur}
        />

        <FormTextField
          label="Mobile URL"
          name="mobileUrl"
          placeholder="-"
          afterBlur={handleUrlFieldBlur}
        />
      </Stack>
    </WrappableContainer>
  )
})

export default LinksForm
