import React from "react"
import clsx from "clsx"

import { LabelMapper, Option } from "@framework/types/types"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import { observer } from "mobx-react-lite"
import { useController, useStore } from "@store/index"
import { AdCopyStatus } from "@framework/types/adCopy"
import { useAlert } from "react-alert"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import Row from "./Row"
import StatusSwitch from "./StatusSwitch"
import UpdateMark from "../UpdateMark"
import DeletedMark from "../DeletedMark"

import styles from "./index.module.scss"

type AdCopyRowProps = {
  id: string
  className?: string
  name?: string
  checked?: boolean
  onAction: (name: string) => void
  onChange?: () => void
}

const AdCopyRow: React.FC<AdCopyRowProps> = observer(
  ({ id, className, name, checked, onAction, onChange }) => {
    const alert = useAlert()
    const { adCopyStore, accountStore } = useStore()
    const { adCopyManagement } = useController()

    const data = adCopyStore.getAdCopyByLocalID(id)

    const adGroup = adCopyStore.getAdGroupByID(data?.adGroupID!)

    const handleStatusChange = async (status: string) => {
      if (!accountStore.accountId) return

      const error = await adCopyManagement.updateStatus(
        accountStore.accountId,
        id,
        status as AdCopyStatus
      )

      if (error)
        alert.error(<AlertMessage title="Error occurred" description={error} />)
    }

    const options =
      data?.modified === "delete"
        ? deletedContextMenuOptions
        : contextMenuOptions

    const isUpdated = data?.modified === "edit" || data?.modified === "new"
    const isDeleted = data?.modified === "delete"

    return (
      <Row
        options={options}
        selected={checked}
        onAction={onAction}
        onSelect={onChange}
        className={clsx(styles.row, className)}
      >
        <Tooltip
          wrapperClassName={clsx({ [styles.disabled]: isDeleted })}
          text={name}
          type="inherit"
          color="black70Color"
        >
          {data?.name ?? "N/A"}
        </Tooltip>

        <StatusSwitch
          disabled={isDeleted}
          value={data?.status ?? "paused"}
          onChange={handleStatusChange}
          options={statusOptions}
        />

        <Tooltip
          wrapperClassName={clsx({ [styles.disabled]: isDeleted })}
          type="body2"
        >
          {adGroup?.name ?? "Unknown AdGroup"}
        </Tooltip>

        {isDeleted ? <DeletedMark /> : <UpdateMark hide={!isUpdated} />}
      </Row>
    )
  }
)

export default AdCopyRow

const statusOptions: LabelMapper[] = [
  { name: "enabled", color: "green", label: "Enabled", shortName: "E" },
  { name: "paused", color: "yellow", label: "Paused", shortName: "P" },
]

const contextMenuOptions: Option[] = [
  { value: "edit", label: "Edit", icon: "pen" },
  { value: "duplicate", label: "Duplicate", icon: "file-copy" },
  { value: "delete", label: "Delete", icon: "trash" },
]

const deletedContextMenuOptions: Option[] = [
  { value: "restore", label: "Restore", icon: "reset" },
]
