/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"

import Typography from "@components/ui/Typography/Typography"

import styles from "./OptionGroup.module.scss"

export interface OptionGroupProps {
  label?: React.ReactNode
}

const OptionGroup: React.FC<OptionGroupProps> = ({ label, children }) => (
  <ul className={clsx(styles.root)}>
    {label != null && (
      <Typography className={styles.title} type="h5" color="black60Color">
        {label}
      </Typography>
    )}
    {children}
  </ul>
)

export default OptionGroup
