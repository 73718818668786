import React from "react"
import clsx from "clsx"

import styles from "./Divider.module.scss"

interface DividerProps {
  noLine?: boolean
  className?: string
}

export const Divider: React.FC<DividerProps> = ({ noLine, className }) => (
  <hr className={clsx(styles.root, { [styles.noLine]: noLine }, className)} />
)

export default Divider
