import React, { useLayoutEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import set from "lodash/set"
import { useAlert } from "react-alert"

import { useStore } from "@store/index"
import Modal, { ModalProps } from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import InlineLabel from "@components/ui/CheckBox/Label/Label"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import SystemMessage from "@components/ui/SystemMessage/SystemMessage"
import TextField from "@components/ui/TextField/TextField"
import { Button } from "@components/ui/Button"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import FormNumberField from "@framework/prototypes/FormNumberField"
import {
  CampaignBidStrategyType,
  CampaignLocationType,
} from "@framework/types/manageCampaign"
import {
  defaultBillingOptions,
  getBillingStrategy,
  getCampaignLocationLabel,
  pMaxBillingOptions,
} from "@framework/constants/manageCampaign"
import { ID } from "@framework/types/types"
import { UpdateCampaignBidStrategyRequestType } from "@services/account-campaign.service"
import { CampaignReportType } from "@framework/types/account"
import ModalTemplate from "@components/ui/Modal/ModalTemplate"

import styles from "./EditBidStrategyModal.module.sass"

const adsLocationOptions: CampaignLocationType[] = [
  "ANYWHERE_ON_PAGE",
  "TOP_OF_PAGE",
  "ABSOLUTE_TOP_OF_PAGE",
]

const renderBillingOption = (value: string) => getBillingStrategy(value).label

const isTargetOptional = (value: string | undefined) =>
  value != null &&
  [
    "MAXIMIZE_CONVERSION_VALUE",
    "MAXIMIZE_CONVERSIONS",
    "TARGET_SPEND",
  ].includes(value)

const validationSchema = yup.object({
  biddingStrategyType: yup
    .string()
    .oneOf(defaultBillingOptions)
    .transform((v) => (defaultBillingOptions.includes(v) ? v : undefined))
    .required(),

  targetValue: yup
    .number()
    .label("Target value")
    .when(["biddingStrategyType", "target"], {
      is: (strategy: string, target: boolean) =>
        strategy === "TARGET_IMPRESSION_SHARE" || !!target,
      otherwise: (scheme) => scheme.strip().optional(),
      then: (scheme) =>
        scheme.required().test("Should be positive", (v) => Number(v) >= 0),
    }),

  location: yup
    .string()
    .transform((v) => (v == null ? "UNKNOWN" : v))
    .label("Location")
    .when("biddingStrategyType", {
      is: "TARGET_IMPRESSION_SHARE",
      otherwise: (scheme) => scheme.strip().optional(),
      then: (scheme) => scheme.required().oneOf(adsLocationOptions),
    }),

  impressionShare: yup
    .number()
    .label("Impression Share")
    .when("biddingStrategyType", {
      is: "TARGET_IMPRESSION_SHARE",
      then: (scheme) =>
        scheme.test("Should be positive", (v) => Number(v) >= 0).required(),
      otherwise: (scheme) => scheme.strip().optional(),
    }),

  target: yup.bool().default(false),
})

type FormData = yup.InferType<typeof validationSchema>

export interface EditBidStrategyModalProps extends ModalProps {
  campaignId: ID
}

export const EditBidStrategyModal: React.FC<EditBidStrategyModalProps> =
  observer(({ campaignId, ...rest }) => {
    const { manageCampaignStore, accountStore } = useStore()
    const alert = useAlert()

    const campaign = useMemo(
      () => manageCampaignStore.getCampaignById(Number(campaignId)),
      [campaignId]
    )

    const isPMax =
      campaign?.Campaign.advertisingChannelType === "PERFORMANCE_MAX"
    const billingOptions = isPMax ? pMaxBillingOptions : defaultBillingOptions

    const initValue = useMemo(
      () =>
        ({
          ...validationSchema.getDefault(),
          ...initTargetValue(
            campaign?.Campaign,
            campaign?.Campaign?.BiddingStrategyType,
            billingOptions
          ),
        } as FormData),
      [campaign, billingOptions]
    )

    const handleSubmit = async (form: FormData) => {
      if (!accountStore.accountId) return
      const { target, ...data } = validationSchema.cast(form, {
        context: { target: isTargetRequired },
      })

      const errorMessage = await manageCampaignStore.updateCampaignBidStrategy(
        accountStore.accountId,
        {
          campaignId: Number(campaignId),
          ...data,
        } as UpdateCampaignBidStrategyRequestType
      )
      if (errorMessage) {
        alert.error(errorMessage)
      } else rest.onClose?.()
    }

    const formik = useFormik<FormData>({
      initialValues: initValue,
      validate: (v: FormData) => {
        try {
          validationSchema.validateSync(v, {
            abortEarly: false,
            context: { target: isTargetRequired },
          })
        } catch (error: any) {
          if (error.name !== "ValidationError") throw error
          return error.inner.reduce(
            (errors: any, currentError: any) =>
              set(errors, currentError.path, currentError.message),
            {}
          )
        }
        return {}
      },
      onSubmit: handleSubmit,
    })

    const isTargetRequired = formik.values.target
    const focusOn = formik.values.biddingStrategyType
    const billingStrategy = getBillingStrategy(focusOn)

    useLayoutEffect(() => {
      formik.resetForm({
        values: {
          ...validationSchema.getDefault(),
          ...initTargetValue(campaign?.Campaign, focusOn, billingOptions),
        } as FormData,
      })
    }, [focusOn, billingOptions])

    const isLoading = manageCampaignStore.updateLoading

    return (
      <Modal {...rest} hideHeader>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Stack
              className={styles.root}
              gutter="0"
              direction="column"
              align="stretch"
            >
              <ModalTemplate.Header
                title="Bidding"
                subtitle={
                  <>
                    Campaign:{" "}
                    <Typography
                      type="inherit"
                      color="inherit"
                      weight="bold"
                      inline
                    >
                      {campaign?.Campaign.Name}
                    </Typography>
                  </>
                }
              />

              <ModalTemplate.Body>
                <Stack direction="column" gutter="16" align="stretch">
                  <FormSimpleSelectField
                    label="What do you want to focus on?"
                    name="biddingStrategyType"
                    options={billingOptions}
                    renderValue={renderBillingOption}
                  />

                  {isTargetOptional(focusOn) && (
                    <InlineLabel
                      text="Set a target return on ad spend (optional)"
                      color="black60Color"
                    >
                      <CheckBox
                        name="target"
                        checked={formik.values.target}
                        onChange={formik.handleChange}
                      />
                    </InlineLabel>
                  )}

                  {isTargetRequired &&
                    focusOn === "MAXIMIZE_CONVERSION_VALUE" && (
                      <span>
                        <FormNumberField
                          label="Target ROAS %"
                          name="targetValue"
                          decimalScale={0}
                          customInput={TextField}
                          thousandSeparator
                          suffix="%"
                        />

                        <Typography type="caption3" color="black60Color">
                          Your recommended target ROAS is 300% based on your
                          account’s average return on ad spend
                        </Typography>
                      </span>
                    )}

                  {isTargetRequired && focusOn === "MAXIMIZE_CONVERSIONS" && (
                    <span>
                      <FormNumberField
                        label="Target CPA"
                        name="targetValue"
                        decimalScale={2}
                        customInput={TextField}
                        thousandSeparator
                        prefix="$"
                      />

                      <Typography type="caption3" color="black60Color">
                        Your recommended target CPA is $25.00 based on your
                        account’s average cost per conversion
                      </Typography>
                    </span>
                  )}

                  {isTargetRequired && focusOn === "TARGET_SPEND" && (
                    <span>
                      <FormNumberField
                        label="Maximum CPC bid limit"
                        name="targetValue"
                        decimalScale={2}
                        customInput={TextField}
                        thousandSeparator
                        prefix="$"
                      />

                      <Typography type="caption3" color="black60Color">
                        Your recommended target CPC is $25.00 based on your
                        account’s averege cost per click
                      </Typography>
                    </span>
                  )}

                  {focusOn === "TARGET_IMPRESSION_SHARE" && (
                    <>
                      <FormSimpleSelectField
                        label="Where do you want your ads to appear"
                        name="location"
                        options={adsLocationOptions}
                        renderValue={getCampaignLocationLabel}
                      />

                      <FormNumberField
                        label="Percent (%) impression share to target"
                        name="impressionShare"
                        decimalScale={0}
                        customInput={TextField}
                        thousandSeparator
                        suffix="%"
                      />

                      <FormNumberField
                        label="Maximum CPC bid limit"
                        name="targetValue"
                        decimalScale={2}
                        customInput={TextField}
                        thousandSeparator
                        prefix="$"
                      />
                    </>
                  )}

                  <SystemMessage
                    type="success"
                    message={billingStrategy.message}
                  />
                </Stack>
              </ModalTemplate.Body>

              <ModalTemplate.Footer>
                <Button
                  size="big"
                  color="secondary"
                  disabled={isLoading}
                  onClick={rest.onClose}
                >
                  Cancel
                </Button>
                <Button size="big" type="submit" disabled={isLoading}>
                  Apply
                </Button>
              </ModalTemplate.Footer>
            </Stack>
          </form>
        </FormikProvider>
      </Modal>
    )
  })

export default EditBidStrategyModal

const initTargetValue = (
  campaign?: CampaignReportType,
  strategy:
    | CampaignBidStrategyType
    | string
    | undefined = campaign?.BiddingStrategyType,
  allowedStrategy: string[] = []
): Partial<FormData> => {
  const form: Partial<FormData> = {
    biddingStrategyType: strategy,
  }

  if (!strategy || !allowedStrategy.includes(strategy)) return {}

  if (strategy === "MAXIMIZE_CONVERSION_VALUE") {
    const potentialTargetValue = (campaign?.CampaignBiddingStrategy as any)
      ?.TargetRoas
    form.targetValue =
      potentialTargetValue != null ? potentialTargetValue * 100 : undefined
  }

  if (strategy === "MAXIMIZE_CONVERSIONS")
    form.targetValue =
      (campaign?.CampaignBiddingStrategy as any)?.TargetCpa ?? undefined

  if (strategy === "TARGET_SPEND")
    form.targetValue =
      (campaign?.CampaignBiddingStrategy as any)?.CpcBidCeiling ?? undefined

  if (strategy === "TARGET_IMPRESSION_SHARE") {
    form.targetValue =
      (campaign?.CampaignBiddingStrategy as any)?.CpcBidCeiling ?? undefined
    form.location =
      (campaign?.CampaignBiddingStrategy as any)?.Location ?? undefined
    const potentialImpShare =
      (campaign?.CampaignBiddingStrategy as any)?.LocationFraction ?? undefined
    form.impressionShare =
      potentialImpShare != null ? potentialImpShare : undefined
  }

  form.target = form.targetValue != null

  return form
}
