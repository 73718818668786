/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { useHistory, useLocation } from "react-router-dom"

import Button from "@components/ui/Button/Button"
import Stepper, { StepOptionsProps } from "@components/ui/Stepper/Stepper"
import { useStore } from "@store/index"
import useManageProfile from "@store/manageProfile/useManageProfile"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import AccountInformation from "./components/AccountInformation/AccountInformation"
import LinkedAccounts from "./components/LinkedAccounts/LinkedAccounts"

import styles from "./ManageProfile.module.scss"

export type StepOption = StepOptionsProps & {
  Component: React.FC
  name: string
}

const findStepIndex = (steps: StepOption[], name: string) =>
  steps.findIndex((item) => item.name === name)

const restricted = ["linkedAccounts"]

const stepOptions: StepOption[] = [
  {
    value: "1",
    name: "accountInfo",
    label: "Account Information",
    Component: AccountInformation,
  },
  {
    value: "2",
    name: "linkedAccounts",
    label: "Linked Accounts",
    Component: LinkedAccounts,
  },
]

const ManageProfile: React.FC = observer(() => {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})

  const history = useHistory()
  const { pathname, search, state } = useLocation<any>()

  const { isAccountInfoValid, isAccountLinked } = useManageProfile()

  const {
    appStore: { showEverything },
    manageProfileStore: {
      isEdit,
      keywordsGenerationStats,
      loadKeywordsGenerationStats,
      setIsEdit,
      submitFormCallback,
    },
    accountStore: { accountId },
  } = useStore()

  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  )

  const steps = useMemo(
    () =>
      stepOptions.filter(
        ({ name }) => showEverything || !restricted.includes(name)
      ),
    [showEverything]
  )

  useEffect(() => {
    const stepIndex = findStepIndex(steps, queryParams.alter)
    if (stepIndex >= 0) setActiveStep(stepIndex)
  }, [queryParams.alter])

  const handleChangeStep = useCallback(
    (callback: (oldStep: number) => number) => {
      setActiveStep((oldStep) => {
        const newStep = callback(oldStep)
        if (steps[newStep].name !== queryParams.alter) history.replace(pathname)
        return newStep
      })
    },
    [queryParams.alter, history, pathname]
  )

  const handlePrevStep = useCallback(() => {
    handleChangeStep((prevStep) => {
      if (prevStep > 0) return prevStep - 1
      return prevStep
    })
    setIsEdit(false)
  }, [setIsEdit])

  const handleNextStep = useCallback(() => {
    handleChangeStep((prevStep) => {
      if (prevStep < steps.length) return prevStep + 1
      return prevStep
    })
    setIsEdit(false)
  }, [setIsEdit])

  const setStep = useCallback(
    (step: StepOptionsProps) => {
      handleChangeStep((prevStep) => {
        const index = steps.findIndex((item) => item.value === step.value)
        return index >= 0 ? index : prevStep
      })
      setIsEdit(false)
    },
    [setIsEdit]
  )

  const canEdit = useMemo(
    () =>
      !(
        keywordsGenerationStats &&
        keywordsGenerationStats?.running &&
        (activeStep === 0 || activeStep === 1)
      ),
    [keywordsGenerationStats, activeStep]
  )

  useEffect(() => {
    if (accountId) loadKeywordsGenerationStats(accountId)
  }, [accountId, activeStep])

  const setStepCompleted = useCallback(
    (step: number, value: boolean) => {
      setCompleted((set) => ({
        ...set,
        [step]: value,
      }))
    },
    [setCompleted]
  )

  useEffect(() => {
    setStepCompleted(0, isAccountInfoValid)
  }, [isAccountInfoValid])

  useEffect(() => {
    setStepCompleted(1, isAccountLinked)
  }, [isAccountLinked])

  useEffect(() => {
    if (state?.reason === "EDIT" && canEdit) {
      history.replace(pathname)
      setIsEdit(true)
    }
  }, [state?.reason])

  const { Component } = steps[activeStep]

  return (
    <div className={styles.root}>
      <ServiceDownInterceptor />
      {/* header */}
      <div className={styles.header}>
        <Stepper
          disableAfterIndex={isAccountInfoValid ? undefined : 0}
          activeStep={activeStep}
          complete={completed}
          steps={steps}
          className={styles.stepper}
          onClick={setStep}
        />

        {isEdit ? (
          <div className={styles.control}>
            <Button
              color="secondary"
              className={styles.editButton}
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className={styles.editButton}
              onClick={submitFormCallback}
              disabled={!submitFormCallback}
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
            variant="outlined"
            className={styles.editButton}
            onClick={() => setIsEdit(true)}
            disabled={!canEdit}
          >
            Edit
          </Button>
        )}
      </div>

      {/* body */}
      {Component && <Component />}

      {/* footer */}
      <div className={styles.buttonsWrapper}>
        <Button
          hidden={activeStep - 1 < 0}
          className={styles.nextStepButton}
          variant="ghost"
          onClick={handlePrevStep}
          startIcon={{ name: "long-arrow-right", rotateAngle: 180 }}
        >
          {`Back to ${steps[activeStep - 1]?.label}`}
        </Button>
        <Button
          disabled={!isAccountInfoValid}
          hidden={activeStep >= steps.length - 1}
          className={styles.nextStepButton}
          variant="ghost"
          onClick={handleNextStep}
          endIcon={{ name: "long-arrow-right" }}
        >
          {`Go to ${steps[activeStep + 1]?.label}`}
        </Button>
      </div>
    </div>
  )
})

export default ManageProfile
