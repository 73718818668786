import { useCallback, useEffect, useState } from "react"
import { PreviewMode } from "../types"

export const useHeaderControl = (total: number) => {
  const [activeIdx, setActiveIdx] = useState(0)

  const handleChangeFrame = useCallback(
    (dir?: "left" | "right") => {
      setActiveIdx((prev) => {
        if (dir === "left") return prev > 0 ? prev - 1 : total - 1
        if (dir === "right") return total - 1 > prev ? prev + 1 : 0
        return 0
      })
    },
    [total]
  )

  useEffect(() => {
    setActiveIdx(0)
  }, [total])

  return {
    activeIdx,
    onFrameChange: handleChangeFrame,
  }
}

export default useHeaderControl
