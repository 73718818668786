export const MM_DD_YYYY_DATE_FORMAT = "MM/DD/YYYY"
export const YYYY_MM_DD_DATE_FORMAT = "YYYY/MM/DD"
export const DEF_YYYY_MM_DD_DATE_FORMAT = "YYYY-MM-DD"

export const YEAR_MONTH_DAY_KEY_FORMAT = "YYYYMMDD"

export const YEAR_MONTH_KEY_FORMAT = "YYYY-MM"
export const YEAR_WEEK_KEY_FORMAT = "YYYY-ww"
export const YEAR_MONTH_SLASH_KEY_FORMAT = "YYYY/MM"

export const MON_YY_DATE_FORMAT = "MMM 'YY"
