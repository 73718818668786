import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import { useFormikContext } from "formik"
import { LabelMapper } from "@framework/types/types"
import useCampaignOptions from "@store/manageProfile/useCampaignOptions"
import useAdGroupOptions from "@store/manageProfile/useAdGroupOptions"
import WrappableContainer from "./WrappableContainer"
import RowContainer from "./RowContainer"
import { EditAdCopyFormData } from "./utils"
import useCampaignStatusOptionList from "../useCampaignStatusOptionList"
import { useEditAdCopyContext } from "./EditAdCopyContext"

import styles from "./EditAdCopyForm.module.scss"

export interface MainOptionsFormProps {}

export const MainOptionsForm: React.FC<MainOptionsFormProps> = observer(() => {
  const context = useEditAdCopyContext()

  const formik = useFormikContext<EditAdCopyFormData>()
  const campId = formik.values.campaign

  const statusOptionProps = useCampaignStatusOptionList(statusOptions)
  const campaignOptionProps = useCampaignOptions()
  const adGroupOptionProps = useAdGroupOptions(campId)

  useEffect(() => {
    if (!context.isEdit) formik.setFieldValue("adGroup", undefined, false)
  }, [campId, context.isEdit])

  return (
    <WrappableContainer label="Main options" defaultOpened>
      <Stack gutter="12" align="stretch" className={styles.sectionBody}>
        <RowContainer label="Status">
          <FormSimpleSelectField
            name="status"
            placement="bottom-end"
            {...statusOptionProps}
          />
        </RowContainer>

        <RowContainer label="Campaign">
          <FormSimpleSelectField
            name="campaign"
            placement="bottom-end"
            placeholder="Select campaign..."
            disabled={context.isEdit}
            {...campaignOptionProps}
          />
        </RowContainer>

        <RowContainer label="Ad Group">
          <FormSimpleSelectField
            name="adGroup"
            placement="bottom-end"
            placeholder="Select ad group..."
            disabled={!formik.values.campaign || context.isEdit}
            {...adGroupOptionProps}
          />
        </RowContainer>
      </Stack>
    </WrappableContainer>
  )
})

export default MainOptionsForm

const statusOptions: LabelMapper[] = [
  { name: "enabled", color: "green", label: "Enabled", shortName: "E" },
  { name: "paused", color: "yellow", label: "Paused", shortName: "P" },
]
