import { observable, action } from "mobx"

import accountService from "@services/account.service"
import { ID } from "@framework/types/types"
import AccountStore from "@store/account/account"
import accountCampaignService from "@services/account-campaign.service"
import { AssetGroupEntity } from "@framework/types/manageCampaign"
import EditPMaxCampaignStore from "./edit-pmax-campaigns"
import { FormStepName, transformCampaignDraftForm } from "./validation"
import Languages from "./languages.store"
import AssetGroupsStore from "./asset-groups.store"
import LocationsSuggestionsStore from "./locations-suggestions.store"
import { biddingAndBudgetValidationSchema } from "./validation/budget"
import { campaignSettingsValidationSchema } from "./validation/settings"

export class EditCampaignControllerStore {
  @observable editPMaxCampaignStore: EditPMaxCampaignStore

  @observable languagesStore: Languages

  @observable locationSuggestionsStore: LocationsSuggestionsStore

  @observable accountStore: AccountStore

  @observable assetGroupsStore: AssetGroupsStore

  constructor(config: {
    languagesStore: Languages
    locationSuggestionsStore: LocationsSuggestionsStore
    editPMaxCampaignStore: EditPMaxCampaignStore
    accountStore: AccountStore
    assetGroupsStore: AssetGroupsStore
  }) {
    this.editPMaxCampaignStore = config.editPMaxCampaignStore
    this.accountStore = config.accountStore
    this.languagesStore = config.languagesStore
    this.assetGroupsStore = config.assetGroupsStore
    this.locationSuggestionsStore = config.locationSuggestionsStore
  }

  @action loadCampaign = async (campaignId: ID) => {
    const store = this.editPMaxCampaignStore

    try {
      store.reset()
      store.isLoading = true
      store.error = null

      const accountId = this.accountStore.getAccountId()

      if (campaignId === "new") {
        store.storeData(campaignId, {}, [])
        return null
      }

      const response = await accountCampaignService.getPMaxCampaignById(
        accountId,
        campaignId
      )

      const assets = await this.loadAssetGroups(campaignId)

      const campaignData = transformCampaignDraftForm(response.data.data ?? {})

      const validSteps = await this.validateForm(campaignData, assets.data)

      store.storeData(campaignId, campaignData, validSteps)
    } catch (error: any) {
      store.error = error.response?.data?.error ?? "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  @action validateForm = async (
    campaign: any,
    assets?: AssetGroupEntity[] | null
  ) => {
    const res: FormStepName[] = []

    if (biddingAndBudgetValidationSchema.isValidSync(campaign))
      res.push("budget")
    if (campaignSettingsValidationSchema.isValidSync(campaign))
      res.push("settings")
    if (assets?.length) res.push("assets")
    if (res.length >= 3) res.push("summary") // FIXME

    return res
  }

  @action loadLanguages = async () => {
    const store = this.languagesStore
    try {
      store.isLoading = true
      store.error = null
      const response = await accountService.getLanguagesList()

      store.data = response.data.data
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
  }

  @action searchLocations = async (query: string) => {
    const { locationSuggestionsStore: store } = this
    const { locationsStore } = store
    try {
      store.isLoading = true
      store.error = null
      store.storeData([])

      const response = await accountCampaignService.locationSuggestions({
        location: query,
      })

      const locations = response.data.data

      locationsStore.storeData(locations)
      store.storeData(locations.map((it) => it.id))
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
  }

  @action dropLocationSuggestions = async () => {
    this.locationSuggestionsStore.locationsStore.storeData([])
  }

  @action loadLocations = async (locationIds: ID[]) => {
    const { locationSuggestionsStore } = this
    try {
      const response = await accountCampaignService.locationSuggestions({
        locationIds: locationIds.map((it) => Number(it)),
      })

      locationSuggestionsStore.locationsStore.storeData(response.data.data)
    } catch (error) {
      // TODO
    }
  }

  @action saveDraft = async (
    step: FormStepName,
    campaignData: any,
    valid: boolean = true
  ) => {
    const store = this.editPMaxCampaignStore
    try {
      store.isLoading = true
      store.error = null

      const accountId = this.accountStore.getAccountId()

      store.setCampaignValid(step, valid)

      if (store.campaignId === "new") {
        const response = await accountCampaignService.createPMaxCampaign(
          accountId,
          campaignData
        )

        store.campaignId = response.data.data.campaignId
      } else {
        await accountCampaignService.updatePMaxCampaignById(
          accountId,
          store.campaignId,
          campaignData
        )
      }
    } catch (error: any) {
      store.setCampaignValid(step, false)
      store.error = error.response?.data?.error ?? "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  @action loadAssetGroups = async (campaignId: ID) => {
    const store = this.assetGroupsStore
    try {
      store.isLoading = true
      store.error = null

      const accountId = this.accountStore.getAccountId()

      const response = await accountCampaignService.getAssetGroups(
        accountId,
        campaignId
      )

      store.data = response.data.data
      return { data: store.data, status: "SUCCESS" }
    } catch (error: any) {
      store.error = error.response?.data?.error ?? "Unexpected error"
      store.data = null
    } finally {
      store.isLoading = false
    }
    return { error: store.error, status: "FAILED" }
  }

  @action resetCampaignData = async () => {
    const store = this.editPMaxCampaignStore
    store.reset()
  }
}

export default EditCampaignControllerStore
