import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "@root/store"
import CreateAssetGroupForm from "@pages/PMax/components/CreateAssetGroupPage/CreateAssetGroupForm"

const CreateAssetGroupPage: FC = observer(() => {
  const { accountStore, editPMaxCampaignStore, pMaxAssetGroupStore } =
    useStore()
  const accountId = accountStore.getAccountId()
  const { campaignId } = editPMaxCampaignStore
  const { createAssetGroup } = pMaxAssetGroupStore

  return (
    <CreateAssetGroupForm
      campaignId={campaignId}
      accountId={accountId}
      onCreate={createAssetGroup}
    />
  )
})

export default CreateAssetGroupPage
