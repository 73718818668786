import React from "react"
import { observer } from "mobx-react-lite"

import { BaseCellProps } from "@components/ui/WaterfallTable/types"
import { AdsReportType } from "@framework/types/account"
import { statusToLabel } from "@utils/stringUtils"

export interface DefaultStatusCellProps extends BaseCellProps {
  data: AdsReportType
}

export const DefaultStatusCell: React.FC<DefaultStatusCellProps> = observer(
  ({ value }) => <>{statusToLabel(value)}</>
)

export default DefaultStatusCell
