import React from "react"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import { scheduleDaySchema } from "@store/manage-campaigns/validation/settings"
import { renderSchedulesEventRow } from "@framework/constants/manageCampaign"
import TimePeriodRow from "./TimePeriodRow"

interface FormProps {
  editMode?: boolean
}

export const AdScheduleSettingsForm: React.FC<FormProps> = observer(
  ({ editMode = false }) => {
    const formik = useFormikContext<any>()
    const list: any[] = formik.values.adSchedules

    const addRow = () => {
      formik.setFieldValue("adSchedules", [
        ...(list ?? []),
        scheduleDaySchema.getDefault(),
      ])
    }

    const removeRow = (idx: number) => () => {
      formik.setFieldValue(
        "adSchedules",
        list.filter((_, itx) => itx !== idx)
      )
    }

    // React.useEffect(() => {
    //   if (editMode && !list.length) addRow()
    // }, [editMode])

    if (!editMode)
      return <>{list.map(renderSchedulesEventRow).join(", ") || "N/A"}</>

    return (
      <Stack gutter="8">
        {formik.values.adSchedules?.map((row: any, idx: number) => (
          <TimePeriodRow
            name={`adSchedules[${idx}]`}
            onDelete={removeRow(idx)}
            deletable
          />
        ))}

        <Button
          onClick={addRow}
          size="small"
          variant="ghost"
          startIcon={{ name: "plus" }}
        >
          Add
        </Button>
      </Stack>
    )
  }
)

export default AdScheduleSettingsForm
