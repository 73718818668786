import clsx from "clsx"
import React from "react"

import styles from "./Loader.module.scss"

export interface LoaderProps {
  isLoading?: boolean
  animationSpeed?: string
  className?: string
  height?: "small" | "medium" | "big"
}

const Loader: React.FC<LoaderProps> = ({
  isLoading = true,
  animationSpeed = "8s",
  height = "small",
  className,
}) => (
  <div
    className={clsx(
      styles.root,
      { [styles.visible]: isLoading },
      styles[`variant-${height}`],
      className
    )}
    style={{ animationDuration: animationSpeed }}
  />
)

export default Loader
