/* eslint-disable no-debugger */
import React, { useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Tabs from "@components/ui/Tabs/Tabs"
import { Sticky } from "@components/ui/Sticky/Sticky"
import Breadcrumbs from "@components/ui/Breadcrumbs/Breadcrumbs"
import { useStore } from "@store/index"
import { Option } from "@framework/types/types"
import ToolBar from "@components/ui/ToolBar/ToolBar"
import { NavOption } from "@components/ui/Tabs/types"
import clsx from "clsx"
import ProfileMenu from "./components/ProfileMenu/ProfileMenu"
import Notifications from "./components/Notifications/Notifications"
import SideBar from "./components/SideBar/SideBar"

import styles from "./PrivateLayout.module.scss"

// TODO relocate as props
const crampsSequence: Option[] = [
  {
    value: "agency",
    label: "Agency",
  },
  {
    value: "client",
    label: "Client",
  },
  {
    value: "account",
    label: "Account",
  },
  {
    value: "channel",
    label: "Channel",
  },
]

const getCrampsSequence = (name: string, seq: Option[]): Option[] => {
  const index = seq.findIndex((item) => item.value === name)
  if (index > 0) return seq.slice(0, index + 1)
  return []
}

export interface NavBarProps {
  title: string
  options: NavOption[]
  currentTab?: NavOption
  onSelect?: (selected: NavOption) => void
}

export interface PrivateLayoutProps {
  onSearch?: (value: string) => void
  scope: string
  disabledHeader?: boolean
  disableNavigation?: boolean
  disableProfile?: boolean
  navBar?: NavBarProps
  sideBar?: NavBarProps
  embedded?: boolean
}

export const STICKY_ROOT = "sticky-root"

export const PrivateLayout: React.FC<PrivateLayoutProps> = observer(
  ({
    children,
    navBar,
    sideBar,
    scope,
    disableNavigation = false,
    disabledHeader = false,
    disableProfile = false,
    embedded = false,
  }) => {
    const history = useHistory()
    const location = useLocation()

    const {
      clientStore: { client },
      accountStore: { account },
      agencyStore: { agency },
    } = useStore()

    const crumps: Option[] = useMemo(() => {
      if (disableNavigation) return []
      return getCrampsSequence(scope, crampsSequence).map((item) => {
        if (item.value === "agency" && agency) {
          return {
            value: "/",
            label: "",
            icon: "home",
          }
        }

        if (item.value === "client" && client) {
          return {
            value: `/client/${client.id}/`,
            label: client.name,
          }
        }

        if (item.value === "account" && account) {
          return {
            value: `/account/${account.id}/`,
            label: account.name,
          }
        }

        if (item.value === "channel" && account) {
          if (location.pathname.startsWith("/google-ads"))
            return {
              value: `/google-ads/${account.id}/`,
              label: "Google Ads",
            }
          if (location.pathname.startsWith("/demo"))
            return {
              value: `/demo`,
              label: "Demo",
            }
          if (location.pathname.startsWith("/shopping"))
            return {
              value: `/shopping/${account.id}/`,
              label: "Product Feed",
            }
        }

        return item
      })
    }, [scope, agency, client, account, location, disableNavigation])

    return (
      <div
        className={clsx(styles.root, { [styles.maxScreen]: embedded })}
        id="scroll-container"
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <Sticky stickyStyle={{ zIndex: 5 }} topOffset={0}>
              <ToolBar
                className={clsx(styles.mainHeader, {
                  [styles.disabled]: disabledHeader,
                })}
              >
                <div className={styles.search}>
                  {!disableNavigation && (
                    <Breadcrumbs
                      items={crumps}
                      onClick={(item) => history.push(item.value)}
                    />
                  )}
                </div>
                <div className={styles.profile}>
                  {!disableProfile && (
                    <>
                      <Notifications />
                      <ProfileMenu />
                    </>
                  )}
                </div>
              </ToolBar>
            </Sticky>
            {navBar ? (
              <ToolBar className={styles.mainHeader}>
                <Typography type="h2" color="primary100Color">
                  {navBar.title}
                </Typography>

                <Tabs
                  className={styles.menu}
                  options={navBar.options}
                  currentValue={navBar.currentTab}
                  onChange={navBar.onSelect}
                />
              </ToolBar>
            ) : null}
            <div className={styles.sidebar}>
              <SideBar
                options={sideBar?.options ?? []}
                currentValue={sideBar?.currentTab}
                onChange={sideBar?.onSelect}
              />
            </div>
          </div>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    )
  }
)

export default PrivateLayout
