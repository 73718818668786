import React from "react"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"
import capitalize from "lodash/capitalize"

import Stack from "@components/ui/Stack/Stack"
import FormTextField from "@framework/prototypes/FormTextField"
import { Button } from "@components/ui/Button"
import { gaolOptions } from "@components/EditAdCopyModal/AdCopyWizardModal/validation"
import RowContainer from "./RowContainer"
import { GenerateAdCopyFormData } from "./RegenerateAdCopyForm"

import styles from "./RegenerateAdCopyForm.module.scss"

export interface RequiredSettingsFormProps {}

export const RequiredSettingsForm: React.FC<RequiredSettingsFormProps> =
  observer(() => {
    const formik = useFormikContext<GenerateAdCopyFormData>()
    return (
      <Stack gutter="24" align="stretch" className={styles.sectionBody}>
        <RowContainer label="Text Tone">
          <Stack gutter="8" direction="row" wrappable>
            {gaolOptions.map((it) => {
              const active = formik.values.goal === it
              return (
                <Button
                  onClick={() => formik.setFieldValue("goal", it)}
                  variant={active ? "outlined" : "contained"}
                  color={active ? "primary" : "secondary"}
                  key={it}
                >
                  {capitalize(it)}
                </Button>
              )
            })}
          </Stack>
        </RowContainer>

        <RowContainer label="Selling Products">
          <FormTextField name="products" placeholder="Type your products" />
        </RowContainer>

        <RowContainer label="About the products">
          <FormTextField
            name="productDescription"
            placeholder="Type the unique selling points"
          />
        </RowContainer>

        <RowContainer label="Company description">
          <FormTextField
            name="companyDescription"
            placeholder="Type the company description"
          />
        </RowContainer>
      </Stack>
    )
  })

export default RequiredSettingsForm
