/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable } from "mobx"
import _sortBy from "lodash/sortBy"

import accountService from "@services/account.service"
import { ID } from "@framework/types/types"
import DemoFormManager from "@pages/IndustryAnalysisDemo/tasks/FormManager"
import { transformKeywords } from "./opportunities.transformer"
import OpportunitiesStore from "./opportunities"
import CreateCampaignsStore from "./created-campaign.store"

class OpportunitiesController {
  @observable opportunitiesStore: OpportunitiesStore

  @observable createdCampaign: CreateCampaignsStore

  @observable demoManager: DemoFormManager

  @observable publishCampaign?: () => void = () => {
    this.demoManager.publishCampaign()
  }

  // constructor

  constructor(options: {
    createdCampaign: CreateCampaignsStore
    opportunitiesStore: OpportunitiesStore
    demoManager: DemoFormManager
  }) {
    this.opportunitiesStore = options.opportunitiesStore
    this.createdCampaign = options.createdCampaign
    this.demoManager = options.demoManager
  }

  // computed

  // actions

  @action loadRecommendedKeywords = async (accountId: ID) => {
    const { keywordsList } = this.opportunitiesStore
    try {
      keywordsList.isLoading = true
      keywordsList.error = null

      const { status, data: payload } =
        await accountService.getKeywordsByAccountId(accountId)

      if (status === 200) {
        const recKeywords = transformKeywords(payload.data.records)

        keywordsList.keywordsMap = new Map(
          recKeywords.map((it) => [it.id.toString(), it])
        )

        keywordsList.keywordsIds = Array.from(keywordsList.keywordsMap.keys())
      }
    } catch (error) {
      keywordsList.error = "Unexpected error"
    } finally {
      keywordsList.isLoading = false
    }
  }

  @action loadInsights = async () => null

  @action switchKeyword = async (accountId: ID, keywordId: ID) => {
    const { shoppingCart } = this.opportunitiesStore

    const activeStatus = shoppingCart.isSelected(keywordId)

    const newStatus = !activeStatus

    shoppingCart.selectKeyword(keywordId, newStatus)
  }

  @action changeBudgetFraction = (keywordId: ID, value: number) => {
    if (Number.isNaN(value)) return

    const { keywordsList } = this.opportunitiesStore

    const keyword = keywordsList.getKeywordById(keywordId)
    if (keyword) keyword.budgetFraction = value
  }

  @action setPublishCampaignHandler = (handler?: () => void) => {
    this.publishCampaign = handler
  }
}

export default OpportunitiesController
