import { action, computed, observable } from "mobx"
import { BaseCampaignReportType } from "@framework/types/account"
import { ID } from "@framework/types/types"

class TopCampaignStore<
  T extends BaseCampaignReportType = BaseCampaignReportType
> {
  // INJECTIONS

  // CONSTRUCTOR

  // STATE

  @observable data: T[] | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  // ACTIONS

  @action storeData = (data: T[]) => {
    this.data = data
  }

  @computed get dataIndex() {
    return new Map(this.data?.map((it) => [it.Id.toString(), it]))
  }

  @computed get getById() {
    const { dataIndex } = this
    return (campaignId: ID) => dataIndex.get(campaignId.toString())
  }
}

export default TopCampaignStore
