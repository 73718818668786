import React from "react"

import Image from "@components/ui/Image/Image"
import { ViewCellProps } from "../../types"

import styles from "./TableCell.module.scss"

export const ImageCell: React.FC<ViewCellProps> = React.memo(
  ({ name, data }) => {
    const value = data[name]
    const imageSrc = !value || typeof value !== "string" ? undefined : value
    return <Image className={styles.imageCell} src={imageSrc} alt={name} />
  }
)

export default ImageCell
