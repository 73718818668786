/* eslint-disable no-duplicate-case */
import capitalize from "lodash/capitalize"

export const maskString = (value: string, pattern: string) => {
  let i = 0
  const v = value.toString()
  /* eslint-disable no-plusplus */
  return pattern.replace(/#/g, (_) => v[i++] ?? "")
}

export const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1)

export const getFileExtension = (fileName: string) =>
  fileName.split(".").pop()?.toLocaleLowerCase()

const words = ["bip", "bop", "bup", "pop"]

export const generateText = (wordLength: number = 10) => {
  let x = wordLength
  const text = []
  do {
    text.push(words[Math.floor(Math.random() * words.length)])
  } while (--x)
  return text.join(" ")
}

export const snakeCaseToTitle = (value: string) =>
  capitalize((value as string).replaceAll("_", " "))

export const kebabCaseToTitle = (value: string) =>
  capitalize((value as string).replaceAll("-", " "))

export const numberWithDiff = (value: number, diff: number) => {
  if (diff === 0) return value.toString()
  if (value === 0 && diff !== 0) return `(${numberWithSign(diff)})`
  return `${value} (${numberWithSign(diff)})`
}

export const numberWithSign = (
  value: number,
  renderCallback?: (value: number) => string
) => {
  if (value === 0) return "0"
  const rendered = renderCallback ? renderCallback(value) : value.toString()
  return value > 0 ? `+${rendered}` : rendered
}

export const countedFormat = (value: string, count: number) =>
  `${value}${count === 1 ? "" : "s"}`

export const formatCustomerID = (value: string | number): string => {
  const valueStr = value.toString()
  if (valueStr.length < 10) return valueStr
  return maskString(valueStr, "###-###-####")
}

export const isStringIncludesQuery = (value: string = "", query: string = "") =>
  value.toLocaleLowerCase().includes(query.toLocaleLowerCase())

/**
 * @description converts UPPER-SNAKE-CASE status constant to beautiful label
 * @example "SOME_STATUS_CONSTANT" => "Some status constant"
 */
export const statusToLabel = (status?: string | null) =>
  status
    ? capitalizeFirstLetter(status.replaceAll("_", " ").toLowerCase())
    : null

export const renderShortEnumeration = (row: string[], showOnly: number = 2) => {
  const overflow = row.length - showOnly
  return `${row.slice(0, showOnly).join(", ")}${
    overflow > 0 ? ` + ${overflow} more` : ""
  }`
}

export const normalizeUrl = (url?: string) => {
  if (typeof url !== "string") return url
  if (url.match(/^(http(s)?:\/\/)(.)/)) return url
  return `http://${url}`
}
