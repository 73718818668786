import React from "react"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import { useStore } from "@store/index"
import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import Loader from "@components/ui/Loader/Loader"
import { capitalizeFirstLetter } from "@utils/stringUtils"

import styles from "./PublishToGMCModal.module.scss"

const IMPORT_IMAGE_SRC = "/images/import_products_box.png"
const DONE_IMAGE_SRC = "/images/import_products_done.png"

type PublishToGMCModalProps = {
  isOpen: boolean
  straightMount?: boolean
}

const PublishToGMCModal: React.FC<PublishToGMCModalProps> = observer(
  ({ isOpen, straightMount }) => {
    const history = useHistory()
    const {
      accountStore: { accountId },
      productFeedStore: { isProductsImportLoading, GMC },
    } = useStore()

    const {
      isInited,
      exportStatus,
      exportStatusError,
      isExportStatusLoading,
      publishLoading,
      publishError,
    } = GMC

    const render = () => {
      if (!isInited && isExportStatusLoading) {
        return (
          <div className={styles.container}>
            Loading...
            <Loader />
          </div>
        )
      }

      if (publishError || exportStatus?.running === "failed") {
        return (
          <div className={styles.container}>
            <Icon
              name="alert"
              className={clsx(styles.icon, styles.alertIcon)}
            />
            <Typography type="h2" color="dark">
              Failed
            </Typography>
            <Typography color="gray">
              {capitalizeFirstLetter(
                publishError || exportStatus?.message?.error || "Unknown reason"
              )}
            </Typography>

            <Button
              color="secondary"
              disabled={accountId == null}
              onClick={GMC.finalizePublishing}
            >
              Ok
            </Button>
          </div>
        )
      }

      if (exportStatus == null || exportStatusError) {
        return (
          <div className={styles.container}>
            <Icon
              name="alert"
              className={clsx(styles.icon, styles.alertIcon)}
            />
            <Typography type="h2" color="dark">
              Service temporary unavailable
            </Typography>
            <Typography color="gray">Try again later</Typography>

            <Button
              color="secondary"
              disabled={accountId == null}
              onClick={() => history.push(`./dashboard`)}
            >
              Ok
            </Button>
          </div>
        )
      }

      if (
        isProductsImportLoading ||
        publishLoading ||
        exportStatus.running === "running"
      ) {
        return (
          <div className={styles.container}>
            <img
              src={IMPORT_IMAGE_SRC}
              className={styles.icon}
              height={112}
              width={112}
              alt=""
            />
            <Typography type="h2" color="dark">
              GMC Publishing is running
            </Typography>
            <Loader />
            <Typography color="gray">
              Present page will be blocked till the process end
            </Typography>
            <Button
              color="secondary"
              disabled={accountId == null}
              onClick={() => history.push(`./dashboard`)}
            >
              Go To Dashboard
            </Button>
          </div>
        )
      }

      return (
        <div className={styles.container}>
          <img
            src={DONE_IMAGE_SRC}
            className={styles.icon}
            height={112}
            width={112}
            alt=""
          />
          <Typography type="h2" color="dark">
            GMC Publishing is finished
          </Typography>
          <div className={styles.points}>
            <Typography color="darkGray">
              Adoro products for publishing: <b>{exportStatus.fetched}</b>
            </Typography>
            <Typography color="darkGray">
              Successfully published products: <b>{exportStatus.imported}</b>
            </Typography>
            <Typography color="darkGray">
              Deleted products: <b>{exportStatus.deleted}</b>
            </Typography>
            <Typography color="darkGray">
              Error Products: <b>{exportStatus.errored ?? 0}</b>
            </Typography>
          </div>

          <Button onClick={GMC.finalizePublishing}>Ok</Button>
        </div>
      )
    }

    return (
      <Modal isOpen={isOpen} hideHeader straightMount={straightMount}>
        {render()}
      </Modal>
    )
  }
)

export default PublishToGMCModal
