import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { useStore } from "@store/index"
import BaseSidebarFilter from "@components/prototypes/BaseSidebarFilter/BaseSidebarFilter"
import MetricsList from "./MetricsList"

export interface SidebarFilterProps {}

export const SidebarFilter: React.FC<SidebarFilterProps> = observer(() => {
  const {
    userStore: { userId },
    reports: { settings },
  } = useStore()

  const alert = useAlert()

  const handleCancel = settings.cancelChange

  const handleApply = async () => {
    if (!userId) return
    const error = await settings.applyChange(userId)
    if (error) alert.error(error)
    else settings.showSettings(false)
  }

  const handleReset = async () => {
    if (userId) {
      const error = await settings.resetAll(userId)
      if (error) alert.error(error)
    }
  }

  useEffect(() => () => settings.showSettings(false), [])

  return (
    <BaseSidebarFilter
      title="Column Headers"
      isOpened={settings.isSettingsShown}
      onClose={() => settings.showSettings(false)}
      canReset={!settings.isLoading}
      hasChanges={settings.hasDifferent && !settings.isLoading}
      onReset={handleReset}
      onApply={handleApply}
      onCancel={handleCancel}
    >
      <MetricsList />
    </BaseSidebarFilter>
  )
})

export default SidebarFilter
