import React from "react"
import { observer } from "mobx-react-lite"
import _get from "lodash/get"
import _sortBy from "lodash/sortBy"

import Stack from "@components/ui/Stack/Stack"
import { initArray } from "@utils/numberUtils"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import { SortByType } from "@framework/types/types"
import { useFormikContext } from "formik"
import { Button } from "@components/ui/Button"
import Icon from "@components/ui/Icon/Icon"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import { useController, useStore } from "@store/index"
import { useAlert } from "react-alert"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import WrappableContainer from "./WrappableContainer"
import RowContainer from "./RowContainer"
import LimitLengthTextField from "./LimitLengthTextField"
import ColumnHeaderCell from "./ColumnHeaderCell"
import { usePromptsContext } from "./PromptsContext"

import styles from "./EditAdCopyForm.module.scss"

const rows = initArray(15, (index) => ({
  index,
  name: `headline${1 + index}`,
  label: `Headline ${index + 1}`,
}))

const positionOptions: string[] = ["0", "1", "2", "3"]

export interface HeadlinesFormProps {}

export const HeadlinesForm: React.FC<HeadlinesFormProps> = observer(() => {
  const formik = useFormikContext<any>()
  const [orderBy, setSortOrder] = React.useState<SortByType | null>(null)

  const handleSortClick = (columnName: string) => {
    setSortOrder((v) => {
      if (v == null || v.value !== columnName)
        return { value: columnName, direction: false }
      if (!v.direction) return { value: columnName, direction: true }
      return null
    })
  }

  const sortedRows = React.useMemo(() => {
    if (orderBy != null) {
      if (orderBy.value === "position")
        return _sortBy(rows, (row) => {
          const value = Number(_get(formik.values, `${row.name}Position`, 0))
          if (!value) return 999
          return value * (orderBy.direction ? 1 : -1)
        })
      if (orderBy.value === "headline") {
        const newRows = _sortBy(rows, (row) =>
          _get(formik.values, row.name, "")
        )
        return orderBy.direction ? newRows : newRows.reverse()
      }
      return orderBy.direction ? rows : [...rows].reverse()
    }
    return rows
  }, [orderBy, formik.values])

  return (
    <WrappableContainer label="Headlines" defaultOpened>
      <Stack gutter="12" align="stretch" className={styles.sectionBody}>
        <RowContainer
          label={
            <ColumnHeaderCell
              name="index"
              onSort={handleSortClick}
              label="#"
              orderBy={orderBy}
            />
          }
        >
          <Stack className={styles.headlineRow}>
            <ColumnHeaderCell
              name="headline"
              onSort={handleSortClick}
              label="Headline text"
              orderBy={orderBy}
            />

            <ColumnHeaderCell
              name="position"
              onSort={handleSortClick}
              label="Position"
              orderBy={orderBy}
            />
          </Stack>
        </RowContainer>

        {sortedRows.map((it) => (
          <RowContainer label={it.label} key={it.name}>
            <Stack className={styles.headlineRow}>
              <div className={styles.inputWithControl}>
                <LimitLengthTextField
                  name={it.name}
                  placeholder="-"
                  maxChars={30}
                />

                <RephraseHeadline
                  label={it.label}
                  value={formik.values[it.name]}
                  onChange={(v) => formik.setFieldValue(it.name, v)}
                />
              </div>

              <FormSimpleSelectField
                name={`${it.name}Position`}
                placement="bottom-end"
                placeholder="-"
                renderValue={(it) => (it === "0" ? "-" : it)}
                options={positionOptions}
              />
            </Stack>
          </RowContainer>
        ))}
      </Stack>
    </WrappableContainer>
  )
})

export default HeadlinesForm

const RephraseHeadline: React.FC<{
  label: string
  value: unknown
  onChange: (value: string) => void
}> = observer(({ label, value, onChange }) => {
  const alert = useAlert()
  const prompts = usePromptsContext()

  const {
    accountStore: { accountId },
  } = useStore()
  const { adCopyManagement } = useController()

  const [isLoading, setLoading] = React.useState(false)

  if (prompts == null || accountId == null) return null

  const isValid = !!value && typeof value === "string"

  const handleRephrase = async () => {
    if (!value || typeof value !== "string")
      throw new Error("Handler should not be called with invalid value")

    setLoading(true)

    const res = await adCopyManagement.rephraseAdCopyWithAI(
      accountId,
      prompts,
      {
        headline: value,
      }
    )

    setLoading(false)

    if (res.status === "FAILED") {
      alert.error(
        <AlertMessage
          title={`Failed to rephrase ${label}`}
          description="Try again later"
        />
      )
      return
    }

    onChange?.(res.data.headline ?? "")
  }

  return (
    <Button
      title={`Rephrase ${label}`}
      size="big"
      color="secondary"
      className={styles.refreshButton}
      disabled={!isValid || isLoading}
      onClick={handleRephrase}
    >
      {isLoading ? (
        <CircleLoader className={styles.spinner} />
      ) : (
        <Icon name="refresh" />
      )}
    </Button>
  )
})
