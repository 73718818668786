import React from "react"
import RootStore from "./RootStore"
import RootController from "./RootController"

export interface ContextProps {
  store: RootStore
  controller: RootController
}

const StoreContext = React.createContext<ContextProps | null>(null)

// StoreProvider to inject root store using react context
export const StoreProvider: React.FC<ContextProps> = (
  props: React.PropsWithChildren<ContextProps>
): React.ReactElement => {
  const { children, store, controller } = props
  return (
    <StoreContext.Provider value={{ store, controller }}>
      {children}
    </StoreContext.Provider>
  )
}

// Hook to use store in any functional component
export const useStore = (): RootStore => {
  const context = React.useContext(StoreContext)
  if (context == null) throw new Error("Can't find store context")
  return context.store
}

export const useController = (): RootController => {
  const context = React.useContext(StoreContext)
  if (context == null) throw new Error("Can't find store context")
  return context.controller
}
