import React from "react"
import clsx from "clsx"

import styles from "./Table.module.scss"

export interface MenuItemProps {}

interface ColumnProps {}

export const Column: React.FC<ColumnProps> = ({ children }) => (
  <div className={clsx(styles.col)}>{children}</div>
)

export default Column
