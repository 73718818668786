import React, { useEffect, useRef } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import ImportProductsModal from "@components/modals/ImportProductsModal/ImportProductsModal"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import Container from "@root/layouts/Container/Container"
import { useHistory, useLocation } from "react-router-dom"
import { useStore } from "@store/index"
import useToggle from "@framework/hooks/useToggle"
import { IssuesTable } from "./components/IssuesTable/IssuesTable"
import { Statistics } from "./components/Statistics/Statistics"
import OptimizationProduct from "./components/OptimizationProduct/OptimizationProduct"
import GoogleShoppingLayout from "../DefaultLayout"

import styles from "./Dashboard.module.scss"

export type DashboardProps = {
  className?: string
}

const Dashboard: React.FC<DashboardProps> = observer(({ className }) => {
  const location = useLocation<{ autoImport?: boolean }>()
  const history = useHistory()
  const rootRef = useRef<HTMLDivElement>(null)
  const autoImport = Boolean(location.state?.autoImport)

  const {
    productFeedStore: {
      wasImportLunched,
      isImportStatusLoading,
      importShopifyProducts,
    },
    accountStore: { accountId },
  } = useStore()

  useEffect(() => {
    if (autoImport && accountId && !isImportStatusLoading) {
      importShopifyProducts(accountId)
      history.replace(location.pathname)
    }
  }, [autoImport, accountId])

  return (
    <GoogleShoppingLayout>
      <Container className={clsx(styles.root, className)} ref={rootRef}>
        <div className={styles.body}>
          <ServiceDownInterceptor />
          <OptimizationProduct />
          {/* <Statistics /> */}
          {/* <IssuesTable /> */}
        </div>

        <ImportProductsModal
          isOpen={autoImport || wasImportLunched}
          straightMount
        />
      </Container>
    </GoogleShoppingLayout>
  )
})

export default Dashboard
