import { randomNumber } from "@components/utils/numberUtils"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import { TableColumnProps, TableMapperItem } from "./Table"

const makeMetricMapper = (metric: MetricNameType) => {
  const desc = getMetricDescriptor(metric)
  return {
    name: desc.name,
    label: desc.shortLabel,
    renderLabel: desc.formatter,
    transformDirection: desc.trendFormat,
  }
}

export const accountTableMapperPart: TableMapperItem[] = [
  makeMetricMapper("Impressions"),
  makeMetricMapper("SearchImpressionShare"),
  makeMetricMapper("Clicks"),
  makeMetricMapper("Ctr"),
  makeMetricMapper("AverageCpc"),
  makeMetricMapper("Cost"),
  makeMetricMapper("Conversions"),
  makeMetricMapper("ConversionRate"),
  makeMetricMapper("Revenue"),
  makeMetricMapper("AOV"),
  makeMetricMapper("CostConv"),
  makeMetricMapper("Roas"),
]

export const accountTableMapper: TableMapperItem[] = [...accountTableMapperPart]

export const randomNumbersArray = (mapper: TableMapperItem[]) =>
  Object.fromEntries(
    mapper.map<[string, TableColumnProps]>(({ name }) => [
      name,
      {
        label: randomNumber(0, 10000),
        subLabel: randomNumber(-100, 100),
      },
    ])
  )

// export const segmentationDetailsMock: TableDatasetType[] = [
//   {
//     name: { label: "Brand" },
//     ...randomNumbersArray(accountTableMapperPart),
//   },
//   {
//     name: { label: "Upen Hospital" },
//     ...randomNumbersArray(accountTableMapperPart),
//   },
//   {
//     name: { label: "Airport Hotel" },
//     ...randomNumbersArray(accountTableMapperPart),
//   },
// ]
