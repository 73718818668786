import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import TextField from "@components/ui/TextField/TextField"
import { Button } from "@components/ui/Button"
import AssetImage from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetImage/AssetImage"
import RenameImageModal from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/RenameImageModal"
import DeleteImageModal from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/DeleteImageModal"
import CropImageModal from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/CropImageModal"
import { ImageType } from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/index"
import { convertBase64 } from "@services/utils"
import { useStore } from "@root/store"
import { useAlert } from "react-alert"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import { AutoCroppedFile } from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/AssetImagesUpload"
import styles from "./AssetGroupImagesSidebar.module.scss"

interface AssetImagesListProps {
  onAddImage: () => void
  images: ImageType[]
  onDeleteImage: (ids: number[]) => void
  onRenameImage: (name: string, imageId: number) => void
  onClose: () => void
  accountId: number
  assetGroupId: number
}

const AssetImagesList: FC<AssetImagesListProps> = observer(
  ({
    onAddImage,
    images,
    accountId,
    assetGroupId,
    onDeleteImage,
    onRenameImage,
    onClose,
  }) => {
    const { pMaxAssetGroupStore } = useStore()
    const {
      deleteAssetGroupImage,
      uploadAssetGroupImages,
      getAssetGroupById,
      updateLoading,
    } = pMaxAssetGroupStore
    const alert = useAlert()
    const [isRenameModalVisible, setIsRenameModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isCropModalVisible, setIsCropModalVisible] = useState(false)
    const [imagesForDeletion, setImagesForDeletion] = useState<number[]>([])
    const [renamedImage, setRenamedImage] = useState<{
      name: string
      imageId: number
    }>({ name: "", imageId: 0 })
    const [croppedImage, setCroppedImage] = useState<ImageType>()
    const [files, setFiles] = useState<ImageType[]>(images)
    const [searchString, setSearchString] = useState<string>("")

    useEffect(() => {
      setFiles(images)
    }, [images])

    const onCloseRenameModal = () => {
      setRenamedImage({ name: "", imageId: 0 })
      setIsRenameModalVisible(false)
    }

    const onOpenRenameModal = (name: string, imageId: number) => {
      setIsRenameModalVisible(true)
      setRenamedImage({ name, imageId })
    }

    const onOpenDeleteModal = (ids: number[]) => {
      setIsDeleteModalVisible(true)
      setImagesForDeletion(ids)
    }

    const onCloseDeleteModal = () => {
      setImagesForDeletion([])
      setIsDeleteModalVisible(false)
    }

    const onOpenCropModal = (id: number) => {
      const res = files?.find((i) => i.id === id)
      if (res) {
        setCroppedImage(res)
        setIsCropModalVisible(true)
      }
      setIsCropModalVisible(true)
    }

    const onCloseCropModal = () => {
      setIsCropModalVisible(false)
    }

    const handleCroppedImages = async (files: AutoCroppedFile[]) => {
      const { id } = files[0]
      await deleteAssetGroupImage(accountId, assetGroupId, id as number)

      async function addImagesHandler(callback: any) {
        async function* generateSequence() {
          let results
          // eslint-disable-next-line no-restricted-syntax
          for (const item of files) {
            const img: File = new File([item.file], item.name, {
              type: item.file.type,
            })
            // eslint-disable-next-line no-await-in-loop
            const base64 = await convertBase64(img)
            // @ts-ignore
            const base64Url = base64.replace("data:", "").replace(/^.+,/, "")
            // @ts-ignore
            results = yield uploadAssetGroupImages(accountId, assetGroupId, {
              aspectRatio: item.aspect,
              name: item.name,
              data: base64Url,
              type: "image",
            })
          }
          return results
        }
        const generator = generateSequence()
        let result
        while (!result || !result.done) {
          // eslint-disable-next-line no-await-in-loop
          result = await generator.next(result && result.value)
          callback(result)
        }
      }
      addImagesHandler(
        (
          result: {
            value: { name: string; error: string }
            done: boolean
          } | null
        ) => {
          if (result?.value && !result.done) {
            alert.error(
              <div>
                <div>File: {result.value.name}</div>
                <div>Error: {result.value.error}</div>
              </div>
            )
          }
          if (!result?.value && result?.done) {
            alert.success("Images uploaded")
            getAssetGroupById(accountId, assetGroupId)
          }
        }
      )
      setImagesForDeletion([])
      onCloseCropModal()
    }

    const handleRenameImage = (name: string, imageId: number) => {
      onRenameImage(name, imageId)
      setIsRenameModalVisible(false)
      setRenamedImage({ name: "", imageId: 0 })
    }

    const handleDeleteImage = () => {
      if (imagesForDeletion.length === 0) return
      onDeleteImage(imagesForDeletion)
      setImagesForDeletion([])
      setIsDeleteModalVisible(false)
    }

    const handleCheckedImage = (id: number) => {
      setImagesForDeletion((prevState) => {
        if (prevState.includes(id))
          return [...prevState.filter((img) => img !== id)]
        return [...prevState, id]
      })
    }

    const onSearch = (str: string) => {
      setSearchString(str)
      const filteredItems = images?.filter((img) =>
        img.name.toLowerCase().includes(str.toLowerCase())
      )
      setFiles(filteredItems)
    }

    return (
      <div className={styles.uploadedImagesList}>
        <Typography className={styles.title} type="h2">
          {files?.length}/20 images added to your Ad
        </Typography>
        <Typography className={styles.title} type="h2" weight="bold">
          Asset group
        </Typography>
        <div className={styles.header}>
          <TextField
            before={<Icon name="search" key="search" />}
            after={
              searchString ? (
                <IconButton onClick={() => onSearch("")}>
                  <Icon name="cross" />
                </IconButton>
              ) : null
            }
            placeholder="Search"
            onChange={(e) => onSearch(e.target.value)}
            value={searchString}
          />
          <Button
            color="primary"
            before={<Icon name="plus" />}
            onClick={() => onAddImage()}
          >
            Add image
          </Button>
        </div>

        {updateLoading && (
          <div className={styles.loader}>
            <CircleLoader />
          </div>
        )}

        <div className={styles.imagesList}>
          {files?.map((image) => (
            <AssetImage
              src={image.url}
              name={image.name}
              id={image.id}
              checked={imagesForDeletion.includes(image.id)}
              onCheck={handleCheckedImage}
              onRename={onOpenRenameModal}
              onDelete={onOpenDeleteModal}
              onCrop={onOpenCropModal}
              key={`${image.id}`}
            />
          ))}
        </div>

        <RenameImageModal
          image={renamedImage}
          isOpen={isRenameModalVisible}
          onClose={onCloseRenameModal}
          onRename={handleRenameImage}
        />

        <DeleteImageModal
          isOpen={isDeleteModalVisible}
          onClose={onCloseDeleteModal}
          onDelete={handleDeleteImage}
          imagesAmount={imagesForDeletion?.length}
        />

        <CropImageModal
          isOpen={isCropModalVisible}
          onClose={onCloseCropModal}
          file={croppedImage}
          handleCroppedImages={handleCroppedImages}
        />

        <div className={styles.sidebarFooter}>
          <Button
            color="red"
            onClick={() => {
              if (imagesForDeletion.length === 0) return
              onOpenDeleteModal(imagesForDeletion)
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    )
  }
)

export default AssetImagesList
