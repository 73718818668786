import React from "react"
import { ProductFeedInstance } from "@framework/types/account"
import OnClickTooltip from "@components/ui/Tooltip/TooltipContainer/OnClickTooltipContainer"
import { numberWithCommas } from "@components/utils/numberUtils"
import {
  ColumnMapper,
  makeDescriptor,
  Mapper,
  RenderCallbackType,
} from "./types"
import SequenceViewColumn from "./SequenceViewColumn/SequenceViewColumn"
import SelectViewCell from "./CompareFeedSidebar/TableCell/SelectViewCell"
import ImageCell from "./CompareFeedSidebar/TableCell/ImageCell"
import {
  CategoryDropDownCell,
  DropDownCell,
  NumberCell,
  TextAreaCell,
  TextCell,
} from "./CompareFeedSidebar/FormFiledColumn"
import HintPopUp from "./HintPopUp/HintPopUp"
import LinkCell from "./CompareFeedSidebar/TableCell/LinkCell"
import ImagesCell from "./CompareFeedSidebar/TableCell/ImagesCell"
import SimpleTextCell from "./CompareFeedSidebar/TableCell/SimpleTextCell"

const withHintDecorator =
  <T extends unknown = unknown>(
    renderViewCell: RenderCallbackType<T>
  ): RenderCallbackType<T> =>
  (value, options) =>
    (
      <OnClickTooltip placement="right" toolTip={<HintPopUp />}>
        {renderViewCell(value, options)}
      </OnClickTooltip>
    )

const renderList: RenderCallbackType<any> = (value) => (
  <SequenceViewColumn list={value} />
)

/// MAPPER
const defaultViewImageDescriptor = makeDescriptor(ImageCell)
const defaultViewImageListDescriptor = makeDescriptor(ImagesCell)
const defaultViewSelectDescriptor = makeDescriptor(SelectViewCell)
const defaultViewLinkDescriptor = makeDescriptor(LinkCell)

const defaultSimpleTextEditor = makeDescriptor(TextCell)
const defaultReachTextEditor = makeDescriptor(TextAreaCell)
const defaultDropDownDescriptor = makeDescriptor(DropDownCell)
const defaultCurrencyDescriptor = makeDescriptor(NumberCell, {
  suffix: "$",
  thousandSeparator: true,
  decimalScale: 2,
})

export const productEditFormConfig: Mapper<ProductFeedInstance> = {
  image: {
    name: "image",
    label: "Image",
    view: defaultViewImageDescriptor,
    // edit: readonly
  },
  title: {
    name: "title",
    label: "Title",
    // renderCallback: getColumnRendered(
    //   getEditableColumnRendered(TextAreaColumn)
    // ),
    edit: defaultSimpleTextEditor,
  },
  product_id: {
    name: "product_id",
    label: "Id",
    // edit: readonly
  },
  availability: {
    name: "availability",
    label: "Availability",
    // getValueCallback: (data, { name }) => {
    //   const value = data[name]
    //   return typeof value === "string" ? underscoreCaseToTitle(value) : "-"
    // },
    view: defaultViewSelectDescriptor,
    // edit: readonly
  },
  priceValue: {
    name: "priceValue",
    label: "Price",
    view: makeDescriptor(SimpleTextCell, {
      unitName: "priceCurrency",
      valueFormatter: (v: any) => numberWithCommas(Number(v), 2),
    }),
    // getValueCallback: (data) =>
    //   data.priceValue && `${data.priceValue} ${data.priceCurrency}`,
    // edit: readonly
  },
  salePriceValue: {
    name: "salePriceValue",
    label: "Sale price",
    view: makeDescriptor(SimpleTextCell, {
      unitName: "priceCurrency",
      valueFormatter: (v: any) => numberWithCommas(Number(v), 2),
    }),
    // getValueCallback: (data) =>
    //   data.salePriceValue && `${data.salePriceValue} ${data.priceCurrency}`,
    // edit: { Component: NumberColumn, defaultProps: currencyStaticProps },
    // edit: readonly
  },
  category: {
    name: "category",
    label: "Category",
    edit: makeDescriptor(CategoryDropDownCell),
  },
  productType: {
    name: "productType",
    label: "Product type",
    getValueCallback: (data) => {
      if (!data.productType) return [""]
      return data.productType.split(">")
    },
    renderCallback: renderList,
    edit: defaultSimpleTextEditor,
  },
  brand: {
    name: "brand",
    label: "Brand",
    // edit: unknown,
  },
  description: {
    name: "description",
    label: "Product Description",
    edit: defaultReachTextEditor,
  },
  itemGroupId: {
    name: "itemGroupId",
    label: "Item group id",
    // edit: readonly
  },
  link: {
    name: "link",
    label: "Link",
    view: defaultViewLinkDescriptor,
    // edit: readonly
  },
  images: {
    name: "images",
    label: "Images",
    view: defaultViewImageListDescriptor,
    // edit: readonly
  },
  gtin: {
    name: "gtin",
    label: "GTIN",
    // TODO ?? is it editable
    // edit: defaultSimpleTextEditor,
  },
  mpn: {
    name: "mpn",
    label: "MPN",
    // edit: readonly
  },
  identifierExists: {
    name: "identifierExists",
    label: "Identifier exists",
    view: defaultViewSelectDescriptor,
    edit: defaultDropDownDescriptor,
  },
  condition: {
    name: "condition",
    label: "Condition",
    // edit: readonly
  },
  adult: {
    name: "adult",
    label: "Adult",
    view: defaultViewSelectDescriptor,
    edit: defaultDropDownDescriptor,
  },
  ageGroup: {
    name: "ageGroup",
    label: "Age group",
    view: defaultViewSelectDescriptor,
    edit: defaultDropDownDescriptor,
  },
  color: {
    name: "color",
    label: "Color",
    edit: defaultSimpleTextEditor,
  },
  gender: {
    name: "gender",
    label: "Gender",
    view: defaultViewSelectDescriptor,
    edit: defaultDropDownDescriptor,
  },
  material: {
    name: "material",
    label: "Material",
    edit: defaultSimpleTextEditor,
  },
  pattern: {
    name: "pattern",
    label: "Pattern",
    edit: defaultSimpleTextEditor,
  },
  size: {
    name: "size",
    label: "Size",
    edit: defaultSimpleTextEditor,
  },
  sizeType: {
    name: "sizeType",
    label: "Size type",
    view: defaultViewSelectDescriptor,
    edit: defaultDropDownDescriptor,
  },
  sizeSystem: {
    name: "sizeSystem",
    label: "Size system",
    view: defaultViewSelectDescriptor,
    // edit: readonly
  },
  customLabel0: {
    name: "customLabel0",
    label: "Custom label 0",
    edit: defaultSimpleTextEditor,
  },
  customLabel1: {
    name: "customLabel1",
    label: "Custom label 1",
    edit: defaultSimpleTextEditor,
  },
  customLabel2: {
    name: "customLabel2",
    label: "Custom label 2",
    edit: defaultSimpleTextEditor,
  },
  customLabel3: {
    name: "customLabel3",
    label: "Custom label 3",
    edit: defaultSimpleTextEditor,
  },
  customLabel4: {
    name: "customLabel4",
    label: "Custom label 4",
    edit: defaultSimpleTextEditor,
  },
  promotionIds: {
    name: "promotionIds",
    label: "Promotion IDs",
    // TODO ?? is it editable? how?
    // edit: defaultSimpleTextEditor,
  },
  availabilityDate: {
    name: "availabilityDate",
    label: "Availability date",
    // edit: readonly
    // getValueCallback: (data, { name }) =>
    //   moment(data[name]).format(MM_DD_YYYY_DATE_FORMAT),
  },
  additionalImageLink: {
    name: "additionalImageLink",
    label: "Additional image link",
    view: defaultViewLinkDescriptor,
    // edit: readonly
  },
  mobileLink: {
    name: "mobileLink",
    label: "Mobile link",
    view: defaultViewLinkDescriptor,
    // edit: readonly
  },
  costOfGoodsSold: {
    name: "costOfGoodsSold",
    label: "Cost of goods sold",
    // edit: readonly
    // renderCallback: getColumnRendered(
    //   getEditableColumnRendered(NumberColumn, currencyStaticProps)
    // ),
  },
  googleExpirationDate: {
    name: "googleExpirationDate",
    label: "Expiration date",
    // edit: readonly
  },
  salePriceEffectiveDate: {
    name: "salePriceEffectiveDate",
    label: "Sale price effective date",
    // edit: readonly
  },
  unitPricingMeasure: {
    name: "unitPricingMeasure",
    label: "Unit pricing measure",
    // edit: readonly
  },
  unitPricingBaseMeasure: {
    name: "unitPricingBaseMeasure",
    label: "Unit pricing base measure",
    // edit: readonly
  },
  installment: {
    name: "installment",
    label: "Installment",
    // edit: readonly
  },
  subscriptionCostAmount: {
    name: "subscriptionCostAmount",
    label: "Subscription cost",
    // edit: readonly
  },
  loyalty_points: {
    name: "loyalty_points",
    label: "Loyalty points",
    // edit: readonly
  },
  multipack: {
    name: "multipack",
    label: "Multipack",
    // edit: readonly
  },
  isBundle: {
    name: "isBundle",
    label: "Bundle",
    view: defaultViewSelectDescriptor,
    // TODO is editable?
    // edit: readonly
  },
  energyEfficiencyClass: {
    name: "energyEfficiencyClass",
    label: "Energy efficiency class",
    view: defaultViewSelectDescriptor,
    // edit: readonly
  },
  minEnergyEfficiencyClass: {
    name: "minEnergyEfficiencyClass",
    label: "Minimum energy efficiency class",
    view: defaultViewSelectDescriptor,
    // edit: readonly
  },
  maxEnergyEfficiencyClass: {
    name: "maxEnergyEfficiencyClass",
    label: "Maximum energy efficiency class",
    view: defaultViewSelectDescriptor,
    // edit: readonly
  },
  productLengthValue: {
    name: "productLengthValue",
    label: "Product length",
    // edit: readonly
  },
  productWidthValue: {
    name: "productWidthValue",
    label: "Product width",
    // edit: readonly
  },
  productHeightValue: {
    name: "productHeightValue",
    label: "Product height",
    // edit: readonly
  },
  productWeightValue: {
    name: "productWeightValue",
    label: "Product weight",
    // edit: readonly
  },
  product_detail: {
    name: "product_detail",
    label: "Product detail",
    // edit: readonly
  },
  productHighlights: {
    name: "productHighlights",
    label: "Product highlight",
    // edit: readonly
  },
  adsRedirect: {
    name: "adsRedirect",
    label: "Ads redirect",
  },
  excludedDestinations: {
    name: "excludedDestinations",
    label: "Excluded destination",
    // edit: readonly
  },
  includedDestinations: {
    name: "includedDestinations",
    label: "Included destination",
    // edit: readonly
  },
  shoppingAdsExcludedCountries: {
    name: "shoppingAdsExcludedCountries",
    label: "Excluded countries for Shopping ads",
    // edit: readonly
  },
  shipping: {
    name: "shipping",
    label: "Shipping",
    // edit: readonly
  },
  shippingLabel: {
    name: "shippingLabel",
    label: "Shipping label",
    // edit: readonly
  },
  shippingWeightValue: {
    name: "shippingWeightValue",
    label: "Shipping weight",
    // edit: readonly
  },
  shippingLengthValue: {
    name: "shippingLengthValue",
    label: "Shipping length",
    // edit: readonly
  },
  shippingWidthValue: {
    name: "shippingWidthValue",
    label: "Shipping width",
    // edit: readonly
  },
  shippingHeightValue: {
    name: "shippingHeightValue",
    label: "Shipping height",
    // edit: readonly
  },
  ships_from_country: {
    name: "ships_from_country",
    label: "Ships from country",
    // edit: readonly
  },
  transitTimeLabel: {
    name: "transitTimeLabel",
    label: "Transit time label",
    // edit: readonly
  },
  maxHandlingTime: {
    name: "maxHandlingTime",
    label: "Maximum handling time",
    // edit: readonly
  },
  minHandlingTime: {
    name: "minHandlingTime",
    label: "Minimum handling time",
    // edit: readonly
  },
  tax: {
    name: "tax",
    label: "Tax",
    // edit: readonly
  },
  taxCategory: {
    name: "taxCategory",
    label: "Tax category",
    // edit: readonly
  },
}

export const productEditColumnsMapper: ColumnMapper<ProductFeedInstance>[] =
  Object.values(productEditFormConfig) as ColumnMapper<ProductFeedInstance>[]

export const editableProductFeedMapper: ColumnMapper<ProductFeedInstance>[] =
  productEditColumnsMapper.filter((item) => item?.edit != null)

export default productEditFormConfig
