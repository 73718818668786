/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable } from "mobx"
import _sortBy from "lodash/sortBy"

import { RecommendedKeyword } from "@framework/types/opportunities"
import { ID } from "@framework/types/types"
import { transformEstimate } from "./opportunities.transformer"
import Calculator from "./calculator"
import KeywordsListStore from "./keywords-list"

class ShoppingCartStore {
  @observable calculatorStore: Calculator

  @observable keywordsList: KeywordsListStore

  // constructor

  constructor(options: {
    calculatorStore: Calculator
    keywordsList: KeywordsListStore
  }) {
    this.calculatorStore = options.calculatorStore
    this.keywordsList = options.keywordsList
  }

  // state

  @observable recommendedKeywords: RecommendedKeyword[] = []

  @observable selected: Set<string> = new Set()

  // computed

  @computed get selectedKeywordIDs(): ID[] {
    return Array.from(this.selected.values())
  }

  @computed get selectedKeywords(): RecommendedKeyword[] {
    return this.selectedKeywordIDs.map(
      (kid) => this.keywordsList.getKeywordById(kid)!
    )
  }

  @computed get totalAmount(): number {
    return this.selectedKeywords.reduce(
      (acc, kid) => acc + transformEstimate(kid, this.calculatorStore.ctr),
      0
    )
  }

  @computed get totalSelected(): number {
    return this.selected.size
  }

  @computed get calculatedAvgCpc() {
    const { selectedKeywords } = this
    const res = selectedKeywords.reduce<number>(
      (accumulator, kid) => accumulator + kid.avgCpc,
      0
    )
    const cpc = res ? res / selectedKeywords.length : 0
    return Number(cpc.toFixed(2))
  }

  @computed get isSelected() {
    const { selected } = this
    return (kid: ID) => selected.has(kid.toString())
  }

  // actions

  @action selectKeyword = (kid: ID, value: boolean) => {
    if (value) this.selected.add(kid.toString())
    else this.selected.delete(kid.toString())
  }
}

export default ShoppingCartStore
