import React, { useState } from "react"

import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import Image from "@components/ui/Image/Image"

import styles from "./ImageCarusele.module.scss"

const roundArray = <T extends unknown = unknown>(
  array: T[],
  count: number = 1
): T[] => {
  const newArray = [...array]
  return newArray.concat(newArray.splice(0, count))
}

export interface ImageCaruseleProps {
  list: string[]
}

export const ImageCarusele: React.FC<ImageCaruseleProps> = React.memo(
  ({ list }) => {
    const [activeIdx, setActiveIdx] = useState<number>(0)

    const shownList = roundArray(list, activeIdx).slice(0, 3)

    const handleShiftBack = () => {
      setActiveIdx((prevIdx) =>
        prevIdx - 1 < 0 ? list.length - 1 : prevIdx - 1
      )
    }

    const handleShiftForward = () => {
      setActiveIdx((prevIdx) => (prevIdx + 1 >= list.length ? 0 : prevIdx + 1))
    }

    const canShiftBack = activeIdx > 0
    const canShiftForward = activeIdx < list.length - 1

    const renderControl = () => (
      <div className={styles.control}>
        <Icon
          name="arrow-down"
          rotateAngle={90}
          onClick={canShiftBack ? handleShiftBack : undefined}
          className={canShiftBack ? undefined : styles.disabled}
        />
        <Icon
          name="arrow-down"
          rotateAngle={-90}
          onClick={canShiftForward ? handleShiftForward : undefined}
          className={canShiftForward ? undefined : styles.disabled}
        />
      </div>
    )

    return (
      <div className={styles.root}>
        <section className={styles.header}>
          <Typography color="gray" upperCase>
            Images • {list.length}
          </Typography>
          {list.length > 3 && renderControl()}
        </section>
        <section className={styles.list}>
          {shownList.map((imageSrc, idx) => (
            <Image src={imageSrc} className={styles.image} key={imageSrc} />
          ))}
        </section>
      </div>
    )
  }
)
