import { Channel } from "./account-linking"

export const channelOptions: Channel[] = [
  {
    label: "Google Ads",
    value: "googleAds",
    iconSrc: "/images/googleAdwords.svg",
  },
  {
    label: "Meta Ads",
    value: "facebookAds",
    iconSrc: "/images/facebook_icon.svg",
  },
]

export default null
