/* eslint-disable no-underscore-dangle */
import { action, computed, observable, runInAction } from "mobx"
import moment from "moment"
import keyBy from "lodash/keyBy"
import { nanoid } from "nanoid"

import { accountKPIDropDownListMock } from "@components/ui/KPIsWidget/mockData"
import {
  generateTimeAxisLabels,
  periodLength,
  periodicityToUnit,
} from "@components/utils/date"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import { NamedAdGroupSegmentedPerformanceReport } from "@framework/types/account"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import accountService from "@services/account.service"
import {
  TimeSegmentedPerformanceReport,
  PerformanceReport,
  MetricNameType,
} from "@framework/types/metrics"
import RootStore from "../RootStore"
import { transformSegmentedReport } from "./dataTransformers"
import AccountMonthlyReportStore from "./monthly-report.store"
import AccountGoalsPerformanceStore from "./account-goals-performance"
import CampaignInsightsStore from "./campaign-insights.store"
import AccountSegmentationStore from "./account-segmentation"

class AccountDashboardStore {
  // INJECTIONS

  @observable monthlyPerformanceStore: AccountMonthlyReportStore

  @observable goals: AccountGoalsPerformanceStore

  @observable segmentation: AccountSegmentationStore

  @observable campaignInsightsStore: CampaignInsightsStore

  // CONSTRUCTOR

  constructor(root: RootStore) {
    this.monthlyPerformanceStore = new AccountMonthlyReportStore(root)
    this.goals = new AccountGoalsPerformanceStore()
    this.segmentation = new AccountSegmentationStore()
    this.campaignInsightsStore = new CampaignInsightsStore()

    const currentDate = new Date()
    this.dateRange = [currentDate, currentDate]
  }

  // STATE

  @observable dateRange: [Date, Date]

  @observable periodicity: PerformanceReportPeriodicity = "daily"

  @observable currPeriodPerformance: PerformanceReport[] | null = null

  @observable prevPeriodPerformance: PerformanceReport[] | null = null

  @observable performance: PerformanceReport | null = null

  @observable performanceSegmented: NamedAdGroupSegmentedPerformanceReport[] =
    []

  @observable overallKPILoadError: string | null = null

  @observable error: string | null = null

  @observable segmentedReportError: string | null = null

  @observable activeMetrics: MetricNameType[] = [
    accountKPIDropDownListMock[0],
    accountKPIDropDownListMock[1],
  ]

  @observable isHistoricalPerformanceLoading: boolean = false

  @observable loadingPerformance: boolean = false

  @observable loadingSegmentedPerformance: boolean = false

  @observable currPeriodLabels: string[] = []

  @observable prevPeriodLabels: string[] = []

  @computed get currPeriod() {
    return { from: this.dateRange[0], to: this.dateRange[1] }
  }

  @computed get prevPeriod() {
    return {
      from: moment(this.dateRange[0]).subtract(1, "year").toDate(),
      to: moment(this.dateRange[1]).subtract(1, "year").toDate(),
    }
  }

  // ACTIONS

  @action setDateRange = (date: [Date, Date]) => {
    this.dateRange = date.sort((d1, d2) => d1.getTime() - d2.getTime())
  }

  @action setPeriodicity = (value: PerformanceReportPeriodicity) => {
    this.periodicity = value
  }

  @action setMetric = (index: number, metricName: MetricNameType) => {
    this.activeMetrics[index] = metricName
  }

  @observable lastRequestId: string = nanoid()

  @action loadHistoricalPerformance = async (accountId: number) => {
    try {
      const requestId = nanoid()

      this.isHistoricalPerformanceLoading = true

      const totalChatPoints = periodLength(
        [this.currPeriod.from, this.currPeriod.to],
        periodicityToUnit(this.periodicity)
      )

      const currPeriodLabels = generateTimeAxisLabels(
        this.currPeriod.from,
        totalChatPoints,
        this.periodicity
      )

      const prevPeriodLabels = generateTimeAxisLabels(
        this.prevPeriod.from,
        totalChatPoints,
        this.periodicity
      )

      // reset current state
      runInAction(() => {
        this.lastRequestId = requestId

        this.currPeriodPerformance = null
        this.currPeriodLabels = currPeriodLabels

        this.prevPeriodPerformance = null
        this.prevPeriodLabels = prevPeriodLabels

        this.error = null
      })

      // load data
      const [current, previous] = await Promise.all([
        this._loadPerformancePeriodReport(
          accountId,
          this.currPeriod.from,
          this.currPeriod.to
        ),
        this._loadPerformancePeriodReport(
          accountId,
          this.prevPeriod.from,
          this.prevPeriod.to
        ),
      ])

      // ignore outdated response
      if (this.lastRequestId !== requestId) return

      const currPeriodPerformance = transformHistoricalTrendData(
        this.currPeriodLabels,
        current
      )

      const prevPeriodPerformance = transformHistoricalTrendData(
        this.prevPeriodLabels,
        previous
      )

      // set new state
      runInAction(() => {
        this.currPeriodLabels = currPeriodLabels
        this.currPeriodPerformance = currPeriodPerformance

        this.prevPeriodLabels = prevPeriodLabels
        this.prevPeriodPerformance = prevPeriodPerformance

        this.isHistoricalPerformanceLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.error = "Unexpected error while loading historical KPI trends"
        this.isHistoricalPerformanceLoading = false
      })
    }
  }

  private _loadPerformancePeriodReport = async (
    accountId: number,
    from: Date,
    to: Date
  ) =>
    accountService
      .getPerformancePeriodReport(accountId, {
        from: moment(from).format(YYYY_MM_DD_DATE_FORMAT),
        to: moment(to).format(YYYY_MM_DD_DATE_FORMAT),
        periodicity: this.periodicity,
      })
      .then((response) => response.data.data)

  @action loadPerformance = async (accountId: number, from: Date, to: Date) => {
    this.loadingPerformance = true
    try {
      this.performance = null
      this.overallKPILoadError = null
      const response = await accountService
        .getPerformance(accountId, {
          from: moment(from).format(YYYY_MM_DD_DATE_FORMAT),
          to: moment(to).format(YYYY_MM_DD_DATE_FORMAT),
          compare: true,
        })
        .then((response) => response.data.data)
      this.performance = response
    } catch (error) {
      this.overallKPILoadError = "error"
    }
    this.loadingPerformance = false
  }

  @action loadPerformanceSegmentedReport = async (
    accountId: number,
    from: Date,
    to: Date
  ) => {
    this.loadingSegmentedPerformance = true
    try {
      this.performanceSegmented = []
      this.segmentedReportError = null
      const response = await accountService.getPerformanceSegmentedReport(
        accountId,
        {
          from: moment(from).format(YYYY_MM_DD_DATE_FORMAT),
          to: moment(to).format(YYYY_MM_DD_DATE_FORMAT),
          compare: true,
        }
      )
      this.performanceSegmented = transformSegmentedReport(response.data.data)
    } catch (error) {
      this.segmentedReportError = "error"
    }
    this.loadingSegmentedPerformance = false
  }
}

export default AccountDashboardStore

const transformHistoricalTrendData = (
  labels: string[],
  data: TimeSegmentedPerformanceReport[]
): PerformanceReport[] => {
  const dataMap = keyBy(data, (it) => it.Period)
  return labels.map((pointKey) => dataMap[pointKey]?.Metrics ?? {})
}
