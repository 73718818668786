import React from "react"
import clsx from "clsx"
import { AlertTemplateProps, AlertType } from "react-alert"

import Button from "@components/ui/Button/Button"
import Icon, { IconProps } from "@components/ui/Icon/Icon"

import styles from "./AlertPopupTemplate.module.sass"

interface AlertConfig {
  icon: IconProps["name"]
  colorClassName: string
}

const messageConfig: Record<AlertType, AlertConfig> = {
  success: {
    icon: "checkbox-circle",
    colorClassName: styles.colorSuccess,
  },
  info: {
    icon: "information-outline",
    colorClassName: styles.colorInfo,
  },
  error: {
    icon: "alert",
    colorClassName: styles.colorError,
  },
}

export interface AlertPopupTemplateProps extends AlertTemplateProps {
  message: React.ReactNode
  className?: string
}

const AlertPopupTemplate: React.FC<AlertPopupTemplateProps> = ({
  options,
  message,
  style,
  className,
  close,
}) => {
  const config = options.type ? messageConfig[options.type] : messageConfig.info
  const colorStyles: string = "mantaStyle"
  const buttonVariant = colorStyles === "mantaStyle" ? "ghost" : "contained"

  return (
    <div style={style}>
      <div
        className={clsx(
          styles.alertCard,
          config.colorClassName,
          styles[colorStyles],
          className
        )}
      >
        <Icon name={config.icon} className={styles.icon} />
        <span>{message}</span>
        <Button
          variant={buttonVariant}
          className={styles.closeButton}
          color="secondary"
          onClick={close}
        >
          Ok
        </Button>
      </div>
    </div>
  )
}

export default AlertPopupTemplate
