import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import { Button } from "@components/ui/Button"
import Template from "@components/ui/Templates"
import { useStore } from "@store/index"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import { useTableContext } from "@framework/prototypes/report/MetricsTable/TableContext"
import { CampaignGroup, Platform } from "@framework/constants/report"
import Label from "@components/ui/CheckBox/Label/Label"
import { ReportGroupName } from "@framework/types/report"
import config from "@root/config"
import useCampaignStatusOptionList from "./useCampaignStatusOptionList"

import styles from "./Header.module.sass"

export const Header: React.FC = observer(() => {
  const {
    reports: { settings, mainFilter, extendedReport },
  } = useStore()

  const {
    isActiveFilterValid,
    activeFilter: { currPeriod, accounts, platforms, campaignTypes },
  } = mainFilter

  const context = useTableContext()

  const downloadLink = useMemo(() => {
    if (!isActiveFilterValid) return null
    return extendedReport.getExportCSVDownloadLink(
      currPeriod.range,
      accounts.map(Number),
      platforms as Platform[],
      campaignTypes as CampaignGroup[]
    )
  }, [currPeriod, accounts, platforms, campaignTypes, isActiveFilterValid])

  const statusProps = useCampaignStatusOptionList()

  const showStatusDropdown =
    context.viewType !== "Months" && context.viewType !== "Weeks"

  const handleSelectView = (value: ReportGroupName) => {
    if (value === "Months" || value === "Weeks")
      mainFilter.toggleCampaignStatus("ANY")
    context.setViewType(value)
  }

  return (
    <Template.Header
      left={
        <>
          {/* <TextField
            placeholder="Search"
            className={styles.filed}
            withIcon
            showLabel={false}
            onChange={(e) => context.setSearchQuery(e.target.value)}
            value={context.searchQuery}
          /> */}
          <Label text="Group By" textPosition="before">
            <SimpleSelect
              value={context.viewType}
              options={context.viewTypeOptions}
              onSelect={handleSelectView}
              className={styles.filed}
            />
          </Label>

          {!!config.REPORT_TABLE_STATUS_FILTER && showStatusDropdown && (
            <Label text="Campaign status" textPosition="before">
              <SimpleSelect
                value={mainFilter.campaignStatus}
                onSelect={mainFilter.toggleCampaignStatus}
                {...statusProps}
                className={styles.filed}
              />
            </Label>
          )}
        </>
      }
      right={
        <>
          <Button onClick={() => settings.showSettings(true)} color="secondary">
            Modify Columns
          </Button>
          {downloadLink != null && (
            <Button
              as="a"
              href={downloadLink}
              target="_blank"
              download
              color="secondary"
            >
              Export to CSV
            </Button>
          )}
        </>
      }
    />
  )
})

export default Header
