/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { WrappableContainer } from "@components/ui/WrappableContainer/WrappableContainer"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import { Node } from "@store/product-feed/types"

import styles from "./SidebarFilter.module.scss"

export interface WaterfallFilterProps {}

export const WaterfallFilter: React.FC<WaterfallFilterProps> = observer(() => {
  const {
    productFeedStore: {
      filter: {
        filters: { byCategories: filter },
      },
    },
  } = useStore()

  return (
    <WrappableContainer
      label={<div className={clsx(styles.row, styles.wrapTitle)}>Category</div>}
      className={styles.section}
      titleClassName={styles.wrapHeader}
      open
    >
      <MultilevelFilter
        path={filter.activePath}
        node={filter.categoryMap}
        activeNode={filter.temp}
        onOpen={filter.setActivePath}
        onSelect={filter.selectCategory}
      />
    </WrappableContainer>
  )
})

interface MultilevelFilterProps {
  path: string[]
  node: Record<string, any>
  activeNode: Node | undefined
  onOpen: (name: string[]) => void
  onSelect: (name: string[]) => void
}

const MultilevelFilter: React.FC<MultilevelFilterProps> = observer(
  ({ node, path, activeNode, onSelect, onOpen }) => {
    const list = useMemo(() => Object.keys(node ?? {}), [node])
    const [current, ...otherPath] = path
    if (node == null || !list.length) return <Loader />
    return (
      <>
        {list.map((groupName) => {
          const nextNode = node[groupName]
          const nextList = Object.keys(nextNode ?? {})
          const isOpen = current === groupName

          const nextActiveNode = activeNode?.get(groupName)
          const isActive = !!nextActiveNode
          const nextSelectedSize = nextActiveNode?.size ?? 0

          const handleOpenNext = (path: string[]) =>
            onOpen([groupName, ...path])

          const handleSelectNext = (path: string[]) =>
            onSelect([groupName, ...path])

          const handleOpen = () => {
            if (!isOpen) return onOpen([groupName])
            return onOpen([])
          }

          const handleSelect = () => {
            onSelect([groupName])
          }

          const label = `${groupName}${
            nextList.length ? ` • ${nextSelectedSize}` : ""
          }`

          return (
            <WrappableContainer
              titleClassName={styles.wrapHeader}
              containerClassName={styles.wrapContainer}
              open={isOpen}
              label={
                <div
                  className={styles.row}
                  onClick={
                    nextNode == null || nextList.length ? handleOpen : undefined
                  }
                >
                  <CheckBox
                    checked={isActive}
                    onChange={handleSelect}
                    onClick={(e) => e.stopPropagation()}
                  />
                  {label}
                </div>
              }
              key={groupName}
            >
              <MultilevelFilter
                node={nextNode}
                path={otherPath}
                activeNode={nextActiveNode}
                onOpen={handleOpenNext}
                onSelect={handleSelectNext}
              />
            </WrappableContainer>
          )
        })}
      </>
    )
  }
)

export default WaterfallFilter
