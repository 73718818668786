import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import MetricsCard from "@components/ui/KPIsWidget/MetricCard/MetricCard"
import Loader from "@components/ui/Loader/Loader"
import { metricsToDisplay } from "@store/reports/constants"
import { Platform, CampaignGroup } from "@framework/constants/report"

import styles from "./index.module.sass"

type MetricsGridProps = {
  className?: string
}

const MetricsGrid: React.FC<MetricsGridProps> = observer(({ className }) => {
  const {
    reports: { summaryPerformance, mainFilter },
  } = useStore()
  const { prevPeriod, currPeriod, accounts, platforms, campaignTypes } =
    mainFilter.activeFilter

  useEffect(() => {
    if (!mainFilter.isActiveFilterValid) return
    summaryPerformance.loadPerformanceSummary(
      currPeriod.range,
      prevPeriod.range,
      accounts.map(Number),
      platforms as Platform[],
      campaignTypes as CampaignGroup[]
    )
  }, [
    prevPeriod,
    currPeriod,
    accounts,
    platforms,
    campaignTypes,
    mainFilter.isActiveFilterValid,
  ])

  return (
    <div className={clsx(styles.root, className)}>
      <Typography type="h2">Here’s how your ad have been performing</Typography>
      <Loader isLoading={summaryPerformance.isLoading} />
      <div className={styles.container}>
        {metricsToDisplay.map((metricName) => {
          const props = summaryPerformance.data?.[metricName]
          return (
            <MetricsCard
              amount={props?.Value}
              trend={(props?.Change ?? 0) * 100}
              name={metricName}
              key={metricName}
            />
          )
        })}
      </div>
    </div>
  )
})

export default MetricsGrid
