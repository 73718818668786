import React from "react"
import clsx from "clsx"

import UIPagination, { ReactPaginateProps } from "react-paginate"
import btnStyles from "@components/ui/Button/BasicButton.module.scss"
import { ButtonColorType, ButtonSizeType } from "../Button/types"
import styles from "./index.module.sass"

export interface PaginationProps extends ReactPaginateProps {
  className?: string
  disabled?: boolean
  color?: ButtonColorType
  size?: ButtonSizeType
}

export const Pagination: React.FC<PaginationProps> = ({
  className,
  pageLinkClassName,
  activeLinkClassName,
  previousLinkClassName,
  nextLinkClassName,
  breakLinkClassName,
  marginPagesDisplayed = 1,
  disabled,
  color = "secondary",
  size = "medium",
  ...rest
}) => {
  const btnClassName = clsx(
    btnStyles.root,
    btnStyles.contained,
    btnStyles.color,
    btnStyles[`color-${color}`],
    btnStyles[`size-${size}`],
    {
      [btnStyles.disabled]: disabled,
    }
  )
  return (
    <UIPagination
      className={clsx(styles.root, className)}
      activeLinkClassName={clsx(btnStyles.active, activeLinkClassName)}
      pageLinkClassName={clsx(btnClassName, pageLinkClassName)}
      previousLinkClassName={clsx(btnClassName, previousLinkClassName)}
      nextLinkClassName={clsx(btnClassName, nextLinkClassName)}
      breakLinkClassName={clsx(btnClassName, breakLinkClassName)}
      marginPagesDisplayed={marginPagesDisplayed}
      previousLabel="Prev"
      nextLabel="Next"
      {...rest}
    />
  )
}

export default React.memo(Pagination)
