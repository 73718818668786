import { action, observable } from "mobx"
import { Option } from "@framework/types/types"
import FilterGroup from "@framework/classes/FilterGroup"
import FilterByCategoriesStore from "./filter-by-category"
import OpportunitiesStore from "./opportunities"
import FilterByAttributes from "./filter-by-attributes"

const filterTabs: Option[] = [
  { label: "Category", value: "byCategory" },
  { label: "Other", value: "byAttribute" },
]

type KeywordsFilterType = {
  byAttributes: FilterByAttributes
  byCategories: FilterByCategoriesStore
}

export class KeywordsFilterStore extends FilterGroup<KeywordsFilterType> {
  @observable opportunitiesStore: OpportunitiesStore

  @observable filterTabs: Option[] = [...filterTabs]

  @observable activeFilterTab: Option = filterTabs[0]

  @observable isOpened: boolean = false

  // constructor

  constructor(opportunitiesStore: OpportunitiesStore) {
    super({
      byAttributes: new FilterByAttributes(opportunitiesStore),
      byCategories: new FilterByCategoriesStore(opportunitiesStore),
    })
    this.opportunitiesStore = opportunitiesStore
  }

  // actions

  @action toggleFilter = (open: boolean = !this.isOpened) => {
    this.isOpened = open
  }

  @action setActiveTab = (activeTab: Option) => {
    this.activeFilterTab = activeTab
  }
}

export default KeywordsFilterStore
