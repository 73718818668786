import React from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"

import { adCopyPromptsValidationSchema } from "@components/EditAdCopyModal/AdCopyWizardModal/validation"
import Loader from "@components/ui/Loader/Loader"
import Footer from "./Footer"
import Header from "./Header"
import RegenerateAdCopyMultiForm from "./RegenerateAdCopyMultiForm"

import styles from "./RegenerateAdCopyForm.module.scss"

export const validationSchema = adCopyPromptsValidationSchema

export type GenerateAdCopyFormData = yup.InferType<typeof validationSchema>

export interface RegenerateAdCopyFormProps {
  loading?: boolean
  initialValues?: Partial<GenerateAdCopyFormData>
  onSubmit?: (data: GenerateAdCopyFormData) => void
  onCancel?: () => void
}

export const RegenerateAdCopyForm: React.FC<RegenerateAdCopyFormProps> =
  observer(({ initialValues = {}, loading, onCancel, onSubmit }) => {
    const handleSubmit = async (form: GenerateAdCopyFormData) => {
      onSubmit?.(validationSchema.cast(form, { stripUnknown: true }))
    }

    const formik = useFormik<any>({
      initialValues: {
        ...validationSchema.getDefault(),
        ...initialValues,
      },
      validationSchema,
      onSubmit: handleSubmit,
    })

    return (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={styles.root}>
          <Header />

          <RegenerateAdCopyMultiForm />

          <Loader isLoading={loading} />

          <Footer loading={loading} onClose={onCancel} />
        </form>
      </FormikProvider>
    )
  })

export default RegenerateAdCopyForm
