import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import useToggle from "@framework/hooks/useToggle"
import Stack from "@components/ui/Stack/Stack"

import styles from "./RowContainer.module.scss"

interface RowContainerProps {
  variant?: "group" | "item"
  content?: React.ReactNode
}

export const RowContainer: React.FC<RowContainerProps> = observer(
  ({ content, children, variant = "item" }) => {
    const { isOpened, handleToggle } = useToggle(false)

    const collapsible = children != null

    return (
      <Stack
        className={clsx(styles.root, styles[variant], {
          [styles.opened]: isOpened,
        })}
        align="stretch"
      >
        {collapsible && (
          <Icon
            name="arrow-right"
            data-active={isOpened}
            rotateAngle={isOpened ? 90 : 0}
            className={styles.arrow}
            onClick={handleToggle}
          />
        )}

        <Stack gutter="12" align="stretch" className={styles.container}>
          <div className={styles.header}>{content}</div>

          {isOpened && collapsible && (
            <Stack gutter="12" align="stretch" className={styles.body}>
              {children}
            </Stack>
          )}
        </Stack>
      </Stack>
    )
  }
)

export default RowContainer
