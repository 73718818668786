import union from "lodash/union"
import { action, computed, observable } from "mobx"
import { ID } from "@framework/types/types"
import { UserData, UserEntity } from "@framework/types/user"
import userService from "@services/user.service"

export class UserListStore {
  @observable isLoading: boolean = false

  @observable error: string | null = null

  @observable list: UserData[] | null = null

  @computed get getUserById() {
    const users = this.list
    return (id: ID) =>
      users?.find(({ ID }) => ID.toString() === id.toString()) ?? undefined
  }

  @action load = async () => {
    if (this.isLoading) return
    try {
      this.isLoading = true
      this.error = null
      const { data } = await userService.getUserList({ withPermissions: true })
      this.list = data.data?.map(normalizeUserData) ?? []
    } catch (error) {
      console.error(error)
      this.error = "Failed to load users"
    } finally {
      this.isLoading = false
    }
  }

  @action update = async (data: Partial<UserEntity>) => {
    const { id, ...payload } = data
    this.isLoading = true
    this.error = null
    try {
      if (id) {
        await userService.updateUser(id, payload)
        return id
      }
      const response = await userService.createUser(payload)
      return response.data?.data?.ID
    } catch (error) {
      this.error = "Failed to update User"
    } finally {
      this.isLoading = false
    }
    return null
  }

  @action deleteUser = async (userId: ID) => {
    if (this.isLoading) return this.error
    try {
      this.isLoading = true
      this.error = null
      await userService.removeUser(userId)
    } catch (error) {
      this.error = "Failed to delete user"
    } finally {
      this.isLoading = false
    }
    return this.error
  }
}

export default UserListStore

export const normalizeUserData = (user: UserData): UserData => ({
  ...user,
  permissions:
    user.permissions?.map((permission) => ({
      ...permission,
      accessLevel: user.isDirector ? "director" : permission.accessLevel,
      objectId: union(permission.objectId),
    })) ?? undefined,
})
