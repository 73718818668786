import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { StatusType } from "./Stepper"

import styles from "./Stepper.module.scss"

type StepProps = {
  status: StatusType[]
  label: string
  disabled?: boolean
  onClick?: (e: any) => void
}

const Step: React.FC<StepProps> = ({
  status,
  label,
  disabled = false,
  onClick,
}) => {
  const isActive = status.includes("active")
  const isCompleted = status.includes("complete")
  const isSkipped = status.includes("skipped")
  return (
    <button
      type="button"
      className={clsx(styles.wrapper, {
        [styles.active]: isActive,
        [styles.skipped]: isSkipped,
        [styles.completed]: isCompleted,
        [styles.clickable]: onClick != null,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon
        className={styles.stepIcon}
        name={
          isActive || isCompleted || isSkipped
            ? isCompleted
              ? "checkbox-circle-fill1"
              : "checkbox-blank-circle-fill"
            : "checkbox-blank-circle"
        }
      />
      <Typography type="body1" className={styles.label}>
        {label}
      </Typography>
    </button>
  )
}

export default Step
