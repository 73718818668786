import { useMemo } from "react"
import { Option } from "@framework/types/types"

export const useOptionList = <T extends string>(
  list: Option<T>[]
): { options: T[]; renderValue: (name: T) => React.ReactNode } => {
  const mapper = useMemo(
    () => new Map<T, Option<T>>(list.map((it) => [it.value, it])),
    [list]
  )
  const options = useMemo(() => list.map((it) => it.value), [list])

  return {
    options,
    renderValue: (name) => mapper.get(name)?.label ?? "",
  }
}

export default useOptionList
