import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useLocation } from "react-router-dom"
import ReactDOM from "react-dom"

import Templates from "@components/ui/Templates"
import { useStore } from "@store/index"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import KeywordGenerationProgress from "./KeywordGenerationProgress/KeywordGenerationProgress"
import KeywordsShoppingCartControl from "./KeywordsShoppingCartControl/KeywordsShoppingCartControl"

import styles from "./PaidSearchFooter.module.scss"

export type PaidSearchFooterProps = {
  className?: string
}

export const PaidSearchFooter: React.FC<PaidSearchFooterProps> = observer(
  ({ className }) => {
    const { pathname } = useLocation()

    const opportunitiesStore = useOpportunitiesStore()
    const {
      accountStore: { accountId },
      manageProfileStore,
    } = useStore()

    const showKeywordsGeneration =
      !!["opportunities", "manage-profile"].find((item) =>
        pathname.includes(item)
      ) && manageProfileStore.showBar

    const showControl =
      !!["opportunities", "demo"].find((item) => pathname.includes(item)) &&
      !opportunitiesStore.view.sidebarFixed

    useEffect(() => {
      if (accountId) manageProfileStore.loadKeywordsGenerationStats(accountId)
    }, [accountId])

    if (!showKeywordsGeneration && !showControl) return null
    return ReactDOM.createPortal(
      <div className={clsx(styles.root, className)}>
        <div className={styles.footer}>
          <Templates.Header
            offset="none"
            left={showKeywordsGeneration ? <KeywordGenerationProgress /> : null}
            right={
              showControl && !opportunitiesStore.view.showSidebar ? (
                <KeywordsShoppingCartControl />
              ) : null
            }
          />
        </div>
      </div>,
      document.body
    )
  }
)

export default PaidSearchFooter
