import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { MetaCampaignReport } from "@store/reports/types"
import NoData from "@components/ui/NoData/NoData"
import { useTableContext } from "./TableContext"
import { sortReports } from "./utils"
import MetaCampaignRow from "./MetaCampaignRow"

interface MetaCampaignReportListProps {
  list: string[]
  mapper: MapperType[]
}

const MetaCampaignReportList: React.FC<MetaCampaignReportListProps> = observer(
  ({ list, mapper }) => {
    const {
      reports: { extendedReport },
    } = useStore()

    const context = useTableContext()

    const reports = useMemo(
      () =>
        sortReports(
          list.reduce<MetaCampaignReport[]>((list, id) => {
            const item = extendedReport.metaCampaignReports.get(id)
            if (item != null) list.push(item)
            return list
          }, []),
          context.sortBy
        ),
      [list, context.sortBy, extendedReport.metaCampaignReports]
    )

    if (!reports.length) return <NoData>Not found</NoData>
    return (
      <>
        {reports.map((report) => (
          <MetaCampaignRow data={report} mapper={mapper} key={report.id} />
        ))}
      </>
    )
  }
)

export default MetaCampaignReportList
