import { AxiosResponse } from "axios"
import castArray from "lodash/castArray"
import HttpService from "./http.service"
import config from "../config"
import {
  PermissionEntity,
  PreSignupEntity,
  UserData,
  UserEntity,
  UserPermissionData,
} from "../framework/types/user"
import { ID } from "../framework/types/types"
import { DefaultOkResponse } from "./common"

export type UpdatePasswordRequestPayload = {
  password: string
  passwordConfirm: string
}

export type RestorePasswordRequestPayload = {
  email: string
}

export interface GetUserResponse {
  data: UserData
}

export interface CreateUserResponse {
  data: UserData
}

export interface RemoveUserResponse extends DefaultOkResponse {
  //
}

export interface GetUserListResponse {
  data: UserData[]
}

export interface GetUserPermissionListResponse {
  data: UserPermissionData[]
}

export interface UpdateUserPermissionResponse extends DefaultOkResponse {
  //
}

export interface AssignUserPermissionResponse extends DefaultOkResponse {
  //
}

export interface RemoveUserPermissionResponse extends DefaultOkResponse {
  //
}

class UserAPI extends HttpService {
  getUser = (): Promise<AxiosResponse<GetUserResponse>> => this.get("user")

  createUser = (
    data: Partial<UserEntity>
  ): Promise<AxiosResponse<CreateUserResponse>> =>
    this.post("user", data, {}, true, {
      link: `${window.location.origin}/login`,
    })

  preRegister = (data: PreSignupEntity): Promise<AxiosResponse<any>> =>
    this.post("demo/registration", data, {}, "75098363de514e69b4370b56d56e3c96")

  removeUser = (userId: ID): Promise<AxiosResponse<RemoveUserResponse>> =>
    this.delete(`user/${userId}`)

  updateUser = (
    id: ID,
    data: Partial<UserEntity>
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.put(`user/${id}/update`, data)

  changePassword = (
    data: UpdatePasswordRequestPayload
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.put(`users/update-password`, data)

  forgotPassword = (
    data: RestorePasswordRequestPayload
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.post(`user/auth/forgot-password`, data, {}, false, {
      link: window.location.origin,
    })

  resetPassword = (
    token: string,
    data: UpdatePasswordRequestPayload
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.put(`user/auth/reset-password`, data, { token })

  getUserList = (
    params: {
      withPermissions?: boolean
    } = {}
  ): Promise<AxiosResponse<GetUserListResponse>> =>
    this.get("users/list", params)

  getUserPermissionsList = (
    userId: ID
  ): Promise<AxiosResponse<GetUserPermissionListResponse>> =>
    this.get(`users/${userId}/permissions`)

  updateUserPermission = (
    userId: ID,
    data: PermissionEntity
  ): Promise<AxiosResponse<UpdateUserPermissionResponse>> =>
    this.put(`users/${userId}/permissions`, castArray(data))

  assignUserPermission = (
    userId: ID,
    data: PermissionEntity
  ): Promise<AxiosResponse<AssignUserPermissionResponse>> =>
    this.post(`users/${userId}/permissions`, castArray(data))

  removeUserPermissions = (
    userId: ID,
    permissionId: ID
  ): Promise<AxiosResponse<RemoveUserPermissionResponse>> =>
    this.delete(`users/${userId}/permissions/${permissionId}`)
}

export default new UserAPI({
  API_URL: config.API_ROOT,
})
