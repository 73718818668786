import React from "react"

import Typography from "@components/ui/Typography/Typography"

import styles from "./TableCell.module.scss"

export interface SimpleTextCellProps {
  text?: string
}

export const SimpleTextCell: React.FC<SimpleTextCellProps> = React.memo(
  ({ text }) => (
    <Typography
      type="inherit"
      color="black70Color"
      className={styles.defaultPaddings}
    >
      {text}
    </Typography>
  )
)

export default SimpleTextCell
