import React, { FC } from "react"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import ListingGroupsSidebar from "@pages/PMax/components/EditAssetGroupPage/ListingGroups/ListingGroupsSidebar"
import { observer } from "mobx-react-lite"
import { useStore } from "@root/store"
import styles from "./ListingGroups.module.scss"

interface ListingGroupProps {}

const ListingGroup: FC<ListingGroupProps> = observer(() => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { accountStore } = useStore()
  const gmcMerchantId = accountStore.account?.gmcMerchantId
  if (!gmcMerchantId || gmcMerchantId === 0) return null
  return (
    <div className={styles.listingGroup}>
      <Typography type="h2" className={styles.listingTitle}>
        Listing groups
      </Typography>
      <Typography type="body1" className={styles.listingSubtitle}>
        Merchant center account: {gmcMerchantId}
      </Typography>
      <Typography type="body1" color="gray" className={styles.listingSubtitle}>
        Choose with products to show in your ads. Some of your ads will use
        images, headlines and descriptions from Merchant Center.
      </Typography>
      <Button
        color="primary"
        before={<Icon name="pen" key="pen" />}
        variant="ghost"
        className={styles.buttonIcon}
        onClick={() => setIsOpen(true)}
      >
        Edit
      </Button>

      <ListingGroupsSidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
})

export default ListingGroup
