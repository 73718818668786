/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store/index"
import DuplicateAdCopyModal from "@components/modals/AdCopyModal/DuplicateAdCopyModal"
import DeleteAdCopyModal from "@components/modals/AdCopyModal/DeleteAdCopyModal"
import Loader from "@components/ui/Loader/Loader"
import EditAdCopyTask from "@store/manageProfile/EditAdCopyTask"
import DuplicateAdCopyTask from "@store/manageProfile/DuplicateAdCopyTask"
import DeleteAdCopyTask from "@store/manageProfile/DeleteAdCopyTask"
import RestoreAdCopyTask from "@store/manageProfile/RestoreAdCopyTask"
import RestoreAdCopyModal from "@components/modals/AdCopyModal/RestoreAdCopyModal"
import {
  SearchContext,
  SearchContextStore,
} from "@framework/prototypes/SearchContext/index"
import Header from "./Header"
import AdCopyTable from "./AdCopyTable"
import CampaignsList from "./CampaignsList"
import EditCopySidebar from "./EditCopySidebar/EditCopySidebar"

import styles from "./AdCopy.module.scss"

const searchContext = new SearchContextStore()

type AdCopyManagementProps = {
  className?: string
}

const AdCopyManagement: React.FC<AdCopyManagementProps> = observer(
  ({ className }) => {
    const { adCopyManagement } = useController()
    const {
      adCopyStore,
      accountStore: { accountId },
    } = useStore()

    const { editor, isEditingAvailable } = adCopyStore

    useEffect(() => {
      if (!accountId) return undefined

      adCopyManagement.startLoadAdCopiesWorker(accountId)
      adCopyManagement.loadCampaigns(accountId)

      return () => {
        adCopyManagement.stopLoadAdCopiesWorker()
      }
    }, [accountId])

    return (
      <SearchContext.Provider value={searchContext}>
        <div
          className={clsx(
            styles.root,
            { [styles.disabled]: adCopyStore.isPublishing },
            className
          )}
        >
          <Header />

          <Loader isLoading={adCopyStore.isLoading} height="big" />

          <div className={styles.body}>
            <div className={styles.section}>
              <CampaignsList />
            </div>
            <div className={styles.section}>
              <AdCopyTable />
            </div>
          </div>

          {isEditingAvailable && editor.task instanceof EditAdCopyTask && (
            <EditCopySidebar task={editor.task} isOpened />
          )}

          {isEditingAvailable && editor.task instanceof DuplicateAdCopyTask && (
            <DuplicateAdCopyModal
              isOpened
              onClose={editor.closeTask}
              task={editor.task}
            />
          )}

          {isEditingAvailable && editor.task instanceof DeleteAdCopyTask && (
            <DeleteAdCopyModal
              isOpened
              onClose={editor.closeTask}
              task={editor.task}
            />
          )}

          {isEditingAvailable && editor.task instanceof RestoreAdCopyTask && (
            <RestoreAdCopyModal
              isOpened
              onClose={editor.closeTask}
              task={editor.task}
            />
          )}
        </div>
      </SearchContext.Provider>
    )
  }
)

export default AdCopyManagement
