import React, { useEffect, useCallback } from "react"
import { observer } from "mobx-react-lite"

import Box from "@components/ui/Box/Box"
import { useStore } from "@store/index"
import { Sticky } from "@components/ui/Sticky/Sticky"
import KeywordsMapModal from "@components/modals/KeywordsMapModal/KeywordsMapModal"
import useToggle from "@framework/hooks/useToggle"
import {
  useOpportunitiesController,
  useOpportunitiesStore,
} from "@store/opportunities/provider"
import ControlHeader from "./ControlHeader/ControlHeader"
import { CategoryTableBody, TableBody, TableHeader } from "./Table/Table"
import {
  keywordsByCategoryTableMapper,
  keywordsTableMapper,
} from "../../mock.temp"

import styles from "./RecommendedKeywords.module.scss"

type RecommendedKeywordsProps = {
  stickyOffset?: number
  headerSocket?: React.ReactNode
}

const RecommendedKeywords: React.FC<RecommendedKeywordsProps> = observer(
  ({ headerSocket, stickyOffset = 0 }) => {
    const { opportunities } = useOpportunitiesController()
    const { view, calculator } = useOpportunitiesStore()
    const { accountStore } = useStore()
    const { accountId, account } = accountStore

    useEffect(() => {
      if (accountId) {
        opportunities.loadRecommendedKeywords(accountId)
      }
    }, [accountId])

    useEffect(() => {
      if (account) {
        calculator.parseAccountData(account)
      }
    }, [account, calculator])

    const handleChangeValue = useCallback(
      (id: number, name: string, value: any) => {
        if (name === "budgetFraction") {
          opportunities.changeBudgetFraction(id, value)
        }
      },
      [opportunities.changeBudgetFraction]
    )

    const TableComponent =
      view.activeView === "keywords" ? TableBody : CategoryTableBody
    const tableMapper =
      view.activeView === "keywords"
        ? keywordsTableMapper
        : keywordsByCategoryTableMapper

    return (
      <Box className={styles.root}>
        {headerSocket}
        <Sticky
          topOffset={-stickyOffset}
          stickyStyle={{ top: `${stickyOffset}px`, zIndex: 1 }}
        >
          <Header />
        </Sticky>

        <div className={styles.body}>
          <TableComponent
            accountId={accountId}
            dataMapper={tableMapper}
            onClick={opportunities.switchKeyword}
            onChange={handleChangeValue}
          />
        </div>
      </Box>
    )
  }
)

export default RecommendedKeywords

export const Header: React.FC = observer(() => {
  const { view, sortBy, setSortBy } = useOpportunitiesStore()

  const { isOpened, handleToggle } = useToggle(false)

  const tableMapper =
    view.activeView === "keywords"
      ? keywordsTableMapper
      : keywordsByCategoryTableMapper

  return (
    <ControlHeader className={styles.sticky} handleShowMap={handleToggle}>
      <TableHeader
        dataMapper={tableMapper}
        onSort={setSortBy}
        sortBy={sortBy}
      />
      <KeywordsMapModal isOpen={isOpened} onClose={handleToggle} />
    </ControlHeader>
  )
})
