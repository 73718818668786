import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useStore } from "@root/store"
import useURLSearchParams from "@framework/hooks/useURLSearchParams"
import { ListingGroupEntity } from "@framework/types/manageCampaign"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import Typography from "@components/ui/Typography/Typography"
import ListingGroupsOptionsModal from "@pages/PMax/components/EditAssetGroupPage/ListingGroups/ListingGroupsOptionsModal"
import { AddAssetListingGroupFiltersDataType } from "@services/account-campaign.service"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import clsx from "clsx"
import styles from "./ListingGroupsTable.module.scss"

interface ListingGroupsTableProps {
  onSaveFilters: (
    deleteOptions: number[] | null,
    addOptions: AddAssetListingGroupFiltersDataType | null,
    selectedResourceId: number
  ) => void
  onSetNewOptionFilters: (
    addOptions: AddAssetListingGroupFiltersDataType,
    selectedResourceId: number
  ) => void
}

// function IndeterminateCheckbox({
//   indeterminate,
//   className = "",
//   onChange,
//   ...rest
// }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
//   const ref = React.useRef<HTMLInputElement>(null!)
//
//   React.useEffect(() => {
//     if (typeof indeterminate === "boolean") {
//       ref.current.indeterminate = !rest.checked && indeterminate
//     }
//   }, [ref, indeterminate])
//
//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (onChange) onChange(event)
//   }
//
//   return (
//     <CheckBox
//       checked={rest.checked}
//       ref={ref}
//       className={`${className} cursor-pointer`}
//       onChange={handleChange}
//     />
//   )
// }

const ListingGroupsTable: FC<ListingGroupsTableProps> = observer(
  ({ onSaveFilters, onSetNewOptionFilters }) => {
    const [data, setData] = useState<ListingGroupEntity[]>([])
    const [expanded, setExpanded] = useState<ExpandedState>({})
    const [isOptionsModalVisible, setIsOptionsModalVisible] =
      useState<boolean>(false)
    const [selectedResource, setSelectedResource] =
      useState<ListingGroupEntity | null>()
    const params = useURLSearchParams()
    const assetGroupId: number = Number(params.get("assetGroupId"))
    const { pMaxAssetGroupStore, accountStore } = useStore()
    const {
      getAssetListingGroupOptions,
      listingGroupOptions,
      listingGroups,
      isLoading,
    } = pMaxAssetGroupStore
    const accountId = accountStore.getAccountId()

    const onCloseOptionsModal = () => {
      setIsOptionsModalVisible(false)
      setSelectedResource(null)
    }

    const onOpenOptionsModal = async (
      filterId: number,
      resource: ListingGroupEntity
    ) => {
      await getAssetListingGroupOptions(accountId, assetGroupId, filterId)
      setSelectedResource({ ...resource })
      setIsOptionsModalVisible(true)
    }

    useEffect(() => {
      if (listingGroups) setData([listingGroups])
    }, [listingGroups])

    const columns = React.useMemo<ColumnDef<ListingGroupEntity>[]>(
      () => [
        {
          accessorFn: (row) => row.CaseValue.Name,
          id: "name",
          header: ({ table }) => <>Listing group</>,
          cell: ({ row, getValue }) => (
            <div style={{ paddingLeft: `${row.depth * 30}px` }}>
              <div className={styles.dataRow}>
                {/* <IndeterminateCheckbox */}
                {/*  {...{ */}
                {/*    checked: row.getIsSelected(), */}
                {/*    indeterminate: row.getIsSomeSelected(), */}
                {/*    onChange: row.getToggleSelectedHandler(), */}
                {/*  }} */}
                {/* /> */}
                <i
                  className={clsx(styles.listingGroupIcon, {
                    [styles.unitIncluded]:
                      row.original.Type === "UNIT_INCLUDED",
                    [styles.unitExcluded]:
                      row.original.Type === "UNIT_EXCLUDED",
                    [styles.subdivision]: row.original.Type === "SUBDIVISION",
                  })}
                />
                {row.getCanExpand() ? (
                  <IconButton
                    size="small"
                    variant="ghost"
                    onClick={row.getToggleExpandedHandler()}
                  >
                    {row.getIsExpanded() ? (
                      <Icon name="arrow-down" />
                    ) : (
                      <Icon name="arrow-right" />
                    )}
                  </IconButton>
                ) : (
                  ""
                )}
                <Typography type="body1">{getValue<boolean>()}</Typography>
                {row.original.Subdivisions?.length ? (
                  <IconButton
                    size="small"
                    variant="ghost"
                    color="secondary"
                    onClick={() =>
                      onOpenOptionsModal(row.original.Id, row.original)
                    }
                  >
                    <Icon name="pen" />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    variant="ghost"
                    color="secondary"
                    onClick={() =>
                      onOpenOptionsModal(row.original.Id, row.original)
                    }
                  >
                    <Icon name="plus" />
                  </IconButton>
                )}
              </div>
            </div>
          ),
        },
      ],
      []
    )

    const table = useReactTable({
      data,
      columns,
      state: {
        expanded,
      },
      onExpandedChange: setExpanded,
      getSubRows: (row) => row.Subdivisions,
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      debugTable: true,
    })

    if (isLoading) {
      return (
        <div>
          <CircleLoader />
        </div>
      )
    }

    return (
      <div className={styles.tableWrap}>
        <table className={styles.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={styles.th}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {listingGroups && listingGroupOptions && selectedResource ? (
          <ListingGroupsOptionsModal
            isOpen={isOptionsModalVisible}
            onClose={onCloseOptionsModal}
            listingGroupOptions={listingGroupOptions}
            selectedResource={selectedResource}
            onSaveFilters={onSaveFilters}
            onSetNewOptionFilters={onSetNewOptionFilters}
          />
        ) : null}
      </div>
    )
  }
)

export default ListingGroupsTable
