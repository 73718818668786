import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import set from "lodash/set"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"

import Stack from "@components/ui/Stack/Stack"
import Templates from "@components/ui/Templates"
import { Button } from "@components/ui/Button"
import {
  transformPeriodDataFormForAPI,
  biddingAndBudgetValidationSchema as validationSchema,
} from "@store/manage-campaigns/validation/budget"
import { pMaxBillingOptions } from "@framework/constants/manageCampaign"
import { useController, useStore } from "@store/index"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import Loader from "@components/ui/Loader/Loader"
import { FormStepName } from "@store/manage-campaigns/validation"
import BiddingSection from "./BiddingSection"
import BudgetSection from "./BudgetSection"
import CampaignInfoSection from "./CampaignInfoSection"
import FormStepLayout from "../FormStepLayout"

import styles from "./index.module.scss"

export const billingOptions = pMaxBillingOptions

const STEP_NAME: FormStepName = "budget"

export type BudgetFormProps = {
  className?: string
  onSubmit?: React.MouseEventHandler
  onCancel?: React.MouseEventHandler
}

const BudgetAndBiddingForm: React.FC<BudgetFormProps> = observer(
  ({ onSubmit, onCancel, className }) => {
    const alert = useAlert()
    const { editPMaxCampaignStore: store } = useStore()
    const { editPMaxCampaignController } = useController()

    const handleSubmit = async (form: any) => {
      if (formik.dirty || store.campaignId === "new") {
        const campaignData = validationSchema.cast(form)

        const error = await editPMaxCampaignController.saveDraft(
          STEP_NAME,
          transformPeriodDataFormForAPI(campaignData),
          true
        )

        if (error == null) store.updateData(campaignData)
        else {
          alert.error(
            <AlertMessage title="Failed to save draft" description={error} />
          )
          return
        }
      }

      onSubmit?.(form)
    }

    const formik = useFormik<any>({
      initialValues: validationSchema.cast(store.data, {
        stripUnknown: true,
      }),
      validate: (form: FormData) => {
        try {
          validationSchema.validateSync(form, {
            abortEarly: false,
            context: { target: isTargetRequired },
          })
        } catch (error: any) {
          if (error.name !== "ValidationError") throw error
          return error.inner.reduce(
            (errors: any, currentError: any) =>
              set(errors, currentError.path, currentError.message),
            {}
          )
        }
        return {}
      },
      onSubmit: handleSubmit,
    })

    const isTargetRequired = formik.values.target
    const focusOn = formik.values.biddingStrategyType

    React.useLayoutEffect(() => {
      formik.setValues(
        {
          ...validationSchema.getDefault(),
          ...formik.values,
        } as FormData,
        false
      )
    }, [focusOn])

    return (
      <FormikProvider value={formik}>
        <FormStepLayout
          step={STEP_NAME}
          className={clsx(styles.root, className)}
        >
          <form onSubmit={formik.handleSubmit}>
            <Stack gutter="24" align="stretch">
              <CampaignInfoSection />

              <BudgetSection />

              <BiddingSection />

              {store.isLoading ? (
                <Loader />
              ) : (
                <Templates.Header
                  right={
                    <>
                      <Button
                        size="big"
                        color="secondary"
                        onClick={onCancel}
                        disabled={store.isLoading}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        size="big"
                        disabled={store.isLoading}
                      >
                        Continue
                      </Button>
                    </>
                  }
                />
              )}
            </Stack>
          </form>
        </FormStepLayout>
      </FormikProvider>
    )
  }
)

export default BudgetAndBiddingForm
