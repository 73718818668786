import React from "react"
import { observer, useLocalStore } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"
import TextField from "@components/ui/TextField/TextField"
import Icon from "@components/ui/Icon/Icon"
import { useKeywordInsightsContext } from "@pages/Opportunities/KeywordInsightsContext"
import { useStore } from "@store/index"
import { RecommendedKeyword } from "@framework/types/opportunities"
import {
  SearchContext,
  SearchContextStore,
} from "@framework/prototypes/SearchContext"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import NoData from "@components/ui/NoData/NoData"
import config from "@root/config"
import QuestionsTable from "./QuestionsTable"
import { useKeywordManagementContext } from "../KeywordQuestion/KeywordManagementContext"

import styles from "./index.module.scss"

const hideAnswers = !!config.DISABLE_OPPORTUNITIES_ANSWERS

export const AllQuestionsSection: React.FC<{
  keyword: RecommendedKeyword | null
}> = observer(({ keyword }) => {
  const {
    accountStore: { accountId },
  } = useStore()

  const {
    store: { relatedQuestionsStore },
    controller,
  } = useKeywordInsightsContext()

  const context = useKeywordManagementContext()

  const searchStore = useLocalStore(() => new SearchContextStore())

  React.useEffect(() => {
    if (accountId && keyword?.id)
      controller.loadRelatedQuestions(accountId, keyword?.id)
  }, [accountId, keyword?.id])

  return (
    <SearchContext.Provider value={searchStore}>
      <div className={styles.root}>
        <Templates.Header
          offset="medium"
          left={
            <Typography type="h1" weight="bold">
              All Questions
            </Typography>
          }
          right={
            <TextField
              placeholder="Search"
              before={<Icon name="search" />}
              value={searchStore.searchQuery}
              onChange={(e) => searchStore.setSearchQuery(e.target.value)}
            />
          }
        />

        {relatedQuestionsStore.questionSetNames.length ? (
          <>
            <div className={styles.body}>
              {relatedQuestionsStore.questionSetNames.map((setName) => (
                <QuestionsTable questionsSetName={setName} />
              ))}
            </div>

            {!hideAnswers && (
              <div className={styles.footer}>
                <Button
                  onClick={() =>
                    context.showQuestionDetails(
                      relatedQuestionsStore.questionSetNames[0]
                    )
                  }
                >
                  Show more
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className={styles.placeholder}>
            {relatedQuestionsStore.isLoading ? (
              <CircleLoader />
            ) : (
              <NoData>{relatedQuestionsStore.error}</NoData>
            )}
          </div>
        )}
      </div>
    </SearchContext.Provider>
  )
})

export default AllQuestionsSection
