import React from "react"
import clsx from "clsx"

import styles from "./Table.module.scss"

export interface MenuItemProps {}

interface ColumnProps {
  variant?: "header" | "body"
  width?: number | string
  minWidth?: number | string
  vAlign?: "top" | "middle" | "bottom"
  align?: "start" | "center" | "end"
  colSpan?: number
}

export const Column: React.FC<ColumnProps> = ({
  width,
  minWidth,
  variant = "body",
  vAlign = "middle",
  align = "start",
  colSpan,
  children,
}) => {
  const Component = variant === "body" ? "td" : "th"
  return (
    <Component
      style={{ width, minWidth }}
      className={clsx(
        styles.col,
        styles[`vAlign-${vAlign}`],
        styles[`hAlign-${align}`]
      )}
      colSpan={colSpan}
    >
      {children}
    </Component>
  )
}

export default Column
