import React from "react"
import StatusBadge, {
  BadgeOption,
} from "@pages/GoogleShoppingChannel/components/Status/StatusBadge"
import { CampaignStatusType, campaignStatusList } from "@store/reports/types"

export const useKeywordStatusOptionList = (
  options: CampaignStatusType[] = [...campaignStatusList]
) => ({
  options,
  renderValue: (name: string) => (
    <StatusBadge value={name} mapper={keywordStatusMapper} variant="simple" />
  ),
  statusMapper: keywordStatusMapper,
})

export const statusMapper: Record<CampaignStatusType, BadgeOption> = {
  ENABLED: { color: "green", label: "Enabled" },
  PAUSED: { color: "yellow", label: "Paused" },
  REMOVED: { color: "red", label: "Removed" },
  ANY: { color: "default", label: "Unknown" },
}

export const keywordStatusMapper = (status: string) =>
  statusMapper[status as CampaignStatusType] ?? statusMapper.ANY

export default useKeywordStatusOptionList
