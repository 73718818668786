/* eslint-disable jsx-a11y/label-has-associated-control */
import { observer } from "mobx-react-lite"
import React from "react"

import FilterSection from "@components/prototypes/BaseSidebarFilter/FilterSection"
import FilterList from "@components/prototypes/BaseSidebarFilter/FilterList"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import RangeFilter from "./CPCFilter"

interface SimpleFilterProps {}

const AttributeFilter: React.FC<SimpleFilterProps> = observer(() => {
  const { keywordsFilter } = useOpportunitiesStore()

  const {
    status: statusFilter,
    competitive: competitiveFilter,
    cpc: cpcFilter,
  } = keywordsFilter.group.byAttributes.group

  return (
    <>
      <FilterSection
        label="Status"
        openByDefault={!!statusFilter.tempFiltersCount}
      >
        <FilterList
          list={statusFilter.options}
          isSelected={(item) => statusFilter.isSelected(item.value)}
          onSelect={(item) => statusFilter.toggleOption(item.value)}
        />
      </FilterSection>

      <FilterSection label="CPC" openByDefault={!!cpcFilter.tempFiltersCount}>
        <RangeFilter
          initialValues={cpcFilter.temp}
          onChange={cpcFilter.setValue}
        />
      </FilterSection>

      <FilterSection
        label="Competitive level"
        openByDefault={!!competitiveFilter.tempFiltersCount}
      >
        <FilterList
          list={competitiveFilter.options}
          isSelected={(item) => competitiveFilter.isSelected(item.value)}
          onSelect={(item) => competitiveFilter.toggleOption(item.value)}
        />
      </FilterSection>
    </>
  )
})

export default AttributeFilter
