/* eslint-disable prettier/prettier */
import React from "react"
import {
  ComponentDescriptor,
  NullableRecord,
  FCProps,
  Option,
} from "@framework/types/types"

export type GetValueCallbackType<T extends unknown = unknown> = (
  data: T,
  mapper: ColumnMapper
) => any

export type RenderCallbackOptions<T extends unknown = unknown> = {
  data: T
  rowMapper: ColumnMapper<T>
  columnMapper: Option
} & Record<string, unknown>

export type RenderCallbackType<T extends unknown = any> = (
  value: any,
  options: RenderCallbackOptions<T>
) => React.ReactNode

export const makeDescriptor = <
  T extends React.FC<any>,
  N = NullableRecord<FCProps<T>>
>(
  Component: T,
  props: N = {} as N
): ComponentDescriptor<T, N> => ({ Component, defaultProps: props })

export interface ViewCellProps<N extends string = string, D = any, C = any> {
  name: N
  data: D
  changeData?: C
}

export interface EditCellProps<N extends string = string, D = any> {
  name: N
  data: D
}

export type ColumnMapper<
  K extends unknown = unknown,
  V extends ViewCellProps = any,
  C extends EditCellProps = any
> = {
  name: keyof K
  label: string
  width?: string
  isSortable?: boolean | string

  view?: ComponentDescriptor<React.FC<V>>
  edit?: ComponentDescriptor<React.FC<C>>

  /*
   * @deprecated
   */
  getValueCallback?: GetValueCallbackType<K>
  /*
   * @deprecated
   */
  renderCallback?: RenderCallbackType<K>
}

export type Mapper<T extends any = any> = {
  [key in keyof T]?: ColumnMapper<T>
}

export type ViewType = "simple" | "comparison"
