import React from "react"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import { AssetGroupEntity } from "@framework/types/manageCampaign"

import styles from "./AssetCard.module.scss"

export const logos = [
  "youtubeLogo",
  "gmailLogo",
  "googleLogo",
  "discoverLogo",
  "displayLogo",
]

interface HeaderProps {
  data: AssetGroupEntity
  onSelect?: React.MouseEventHandler
  selected?: boolean
}

export const Header: React.FC<HeaderProps> = ({ data, selected, onSelect }) => (
  <Templates.Header
    offset="none"
    className={styles.header}
    left={<Typography type="h3">{data.name}</Typography>}
    right={
      <Stack direction="row" justify="flex-end" align="center">
        <Typography type="caption2" color="black80Color">
          Ready ads in
        </Typography>

        {logos.map((logo) => (
          <img src={`/images/${logo}.svg`} alt="" key={logo} />
        ))}
        {/* <Button
          size="medium"
          variant={selected ? "outlined" : "contained"}
          endIcon={{ name: selected ? "checkbox-circle-fill" : "add" }}
          onClick={onSelect}
        >
          Choose asset
        </Button> */}
      </Stack>
    }
  />
)

export default Header
