import React from "react"
import SuccessModal, {
  SuccessModalProps,
} from "@components/modals/ConfirmModal/SuccessModal"

export type MessageType = {
  title: SuccessModalProps["title"]
  message: SuccessModalProps["message"]
}

export type Options = {
  onApply?: () => void
}

export const useNotificationModal = ({ onApply }: Options = {}) => {
  const [message, setMessage] = React.useState<MessageType | null>(null)

  const show = (value: MessageType) => {
    setMessage(value)
  }

  const close = () => {
    setMessage(null)
  }

  const handleOk = () => {
    onApply?.()
    close()
  }

  const isOpen = message != null

  return {
    show,
    close,
    isOpen: message != null,
    Node: (
      <SuccessModal
        isOpen={isOpen}
        title={message?.title}
        message={message?.message}
        onClose={() => {}}
        onApply={handleOk}
      />
    ),
  }
}

export default useNotificationModal
