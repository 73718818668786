/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from "mobx-react-lite"
import React, { useState } from "react"

import { useStore } from "@store/index"
import { CustomerType } from "@store/account-linking/account-linking"
import { capitalizeFirstLetter, maskString } from "@utils/stringUtils"
import Box from "@components/ui/Box/Box"
import Button from "@components/ui/Button/Button"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Loader from "@components/ui/Loader/Loader"
import Modal from "@components/ui/Modal/Modal"
import NoData from "@components/ui/NoData/NoData"
import Typography from "@components/ui/Typography/Typography"

import styles from "./SelectCustomerModal.module.scss"

type SelectCustomerModalProps = {
  isOpen: boolean
  isLoading: boolean
  onSubmit?: (form: CustomerType) => void
  onClose: () => void
}

const mapper = [
  {
    name: "AccountName",
    label: "Account Name",
    renderCallback: (value: string) => value || "(No Name)",
  },
  {
    name: "AccountID",
    label: "Customer ID",
    renderCallback: (value: string) => maskString(value, "###-###-####"),
  },
]

const SelectCustomerModal: React.FC<SelectCustomerModalProps> = observer(
  ({ isOpen, isLoading, onSubmit, onClose }) => {
    const { accountLinkingStore } = useStore()
    const { customerList, customerListLoading, customerError } =
      accountLinkingStore

    const [selected, setSelected] = useState<CustomerType>()

    const handleSubmit = () => {
      if (selected && onSubmit) onSubmit?.(selected)
    }

    const customerLoading = isLoading || customerListLoading

    return (
      <Modal
        isOpen={isOpen}
        onClose={() => onClose()}
        title={
          <Typography type="h2" bold color="primary">
            Select Google Accounts
          </Typography>
        }
      >
        <div className={styles.container}>
          {customerError && (
            <Typography className={styles.error} color="red">
              {capitalizeFirstLetter(customerError)}
            </Typography>
          )}
          <div className={styles.header}>
            {!!customerList?.length &&
              mapper.map((item) => (
                <Typography
                  className={styles.column}
                  type="h5"
                  color="gray"
                  upperCase
                  key={item.name}
                >
                  {item.label}
                </Typography>
              ))}
          </div>
          <div className={styles.body}>
            {customerLoading ? (
              <Loader />
            ) : customerList?.length ? (
              customerList.map((item: any) => (
                <Box
                  className={styles.row}
                  onClick={() => setSelected(item)}
                  padding="10px 20px"
                  key={item.name}
                >
                  <CheckBox checked={item.AccountID === selected?.AccountID} />
                  {mapper.map((mapper) => (
                    <Typography
                      className={styles.column}
                      type="h4"
                      key={mapper.name}
                    >
                      {mapper?.renderCallback?.(item[mapper.name]) ??
                        item[mapper.name]}
                    </Typography>
                  ))}
                </Box>
              ))
            ) : (
              <NoData>No accounts found</NoData>
            )}
          </div>
          <div className={styles.footer}>
            <Button
              disabled={!selected || customerLoading}
              onClick={handleSubmit}
            >
              Link
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
)

export default SelectCustomerModal
