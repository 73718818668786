import React from "react"
import clsx from "clsx"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import Stack from "@components/ui/Stack/Stack"

import styles from "./ReportGroup.module.sass"

interface ReportGroupProps {
  title: string
  total: number
  showAll?: boolean
  className?: string
  children?: React.ReactNode
  onToggleClick?: React.MouseEventHandler
}

export const ReportGroup: React.FC<ReportGroupProps> = ({
  title,
  total,
  showAll = false,
  className,
  onToggleClick: onToggle,
  children,
}) => {
  const rootRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (showAll)
      rootRef.current?.scrollIntoView({ block: "start", behavior: "smooth" })
  }, [showAll])

  return (
    <Box ref={rootRef} className={clsx(styles.root, className)} elevation={1}>
      <div className={styles.header}>
        <Stack direction="row" align="center" gutter="4">
          <Typography type="h2">{title}</Typography>
          <Typography type="h5" color="black40Color">
            ({total})
          </Typography>
        </Stack>

        <Button color="secondary" size="small" onClick={onToggle}>
          {showAll ? "Back to overview" : "View adds details"}
        </Button>
      </div>

      <div className={styles.body}>{children}</div>
    </Box>
  )
}

export default ReportGroup
