import React from "react"
import clsx from "clsx"

import styles from "./DropDownContainer.module.scss"

export interface ContainerProps {
  position?: "x" | "y"
}

export const Container: React.FC<ContainerProps> = ({
  position = "y",
  children,
}) => (
  <div className={clsx(styles.dropdownContainer, styles[`pos-${position}`])}>
    {children}
  </div>
)

export default Container
