import { ReactNode } from "react"

import { numberWithCommas } from "@components/utils/numberUtils"
import { AccountData } from "@framework/types/account"
import { Client } from "@framework/types/client"
import moment from "moment"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"

export const sha256 = (str: string) => {
  const buffer = new TextEncoder().encode(str)
  return crypto.subtle.digest("SHA-256", buffer).then((hash) => hex(hash))
}

export const hex = (buffer: ArrayBuffer) => {
  let digest = ""
  const view = new DataView(buffer)
  for (let i = 0; i < view.byteLength; i += 4) {
    const value = view.getUint32(i)
    const stringValue = value.toString(16)
    const padding = "00000000"
    const paddedValue = (padding + stringValue).slice(-padding.length)
    digest += paddedValue
  }
  return digest
}

export const mbNoData = <T>(
  value: T | undefined,
  renderCallback?: (value: T) => ReactNode,
  placeholder: string = "-"
) => {
  if (value !== undefined && !(typeof value === "string" && value === ""))
    return renderCallback ? renderCallback(value) : value
  return placeholder
}

export const mbNoDataWithUnit = (
  value: any | undefined,
  unit: string | undefined,
  renderCallback?: (value: any) => ReactNode
) => {
  if (value !== undefined && !(typeof value === "string" && value === "")) {
    return `${renderCallback?.(value) ?? value} ${unit}`.trim()
  }
  return "-"
}

export const preciseMoneyFormatter = (
  value: string | number,
  fixed: number = 2
) => `$${numberWithCommas(value, fixed)}`

export const moneyFormatter = (value: string | number, fixed: number = 0) =>
  preciseMoneyFormatter(value, fixed)

export const precisePercentageFormatter = (
  value: string | number,
  fixed: number = 2
) => `${numberWithCommas(value, fixed)}% `

export const percentageFormatter = (value: string | number) =>
  precisePercentageFormatter(value, 0)

export const parseAddress = (data: AccountData | Client): string =>
  [data.street, data.city, data.state, data.postal]
    .filter((it) => !!it)
    .join(", ")

export const apiDateFormatter = (date: Date) =>
  moment(date).format(YYYY_MM_DD_DATE_FORMAT)

export const convertBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
