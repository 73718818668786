import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import RowWithLabel from "@components/forms/CheckbookForm/RowWithLabel/RowWithLabel"
import SimpleColumn from "@components/forms/CheckbookForm/Column/SimpleColumn/SimpleColumn"
import { mbNoData, preciseMoneyFormatter } from "@services/utils"
import { MapperOption } from "@components/forms/CheckbookForm/types"
import HeaderColumn from "@components/forms/CheckbookForm/Column/HeaderColumn/HeaderColumn"
import { monthFullNamesMapper } from "@framework/constants/common"

import styles from "./CheckbookTable.module.scss"

export type CheckbookTableProps = {
  className?: string
  mapper: MapperOption[]
  data: { [key: string]: any }
}

export const SimpleCheckbookTable: React.FC<CheckbookTableProps> = observer(
  ({ className, mapper, data }) => (
    <div className={clsx(styles.root, className)}>
      <RowWithLabel key="header" mapper={mapper}>
        {({ name, index }) => (
          <HeaderColumn key={name}>{monthFullNamesMapper[index]}</HeaderColumn>
        )}
      </RowWithLabel>

      <RowWithLabel label="Actual" key="actual" mapper={mapper} withBorder>
        {({ name }) => (
          <SimpleColumn key={name}>
            {mbNoData(data.actual?.[name], preciseMoneyFormatter)}
          </SimpleColumn>
        )}
      </RowWithLabel>

      <RowWithLabel label="Planned" key="planned" mapper={mapper} withBorder>
        {(item) => (
          <SimpleColumn key={item.name}>
            {mbNoData(data.planned?.[item.name], preciseMoneyFormatter)}
          </SimpleColumn>
        )}
      </RowWithLabel>
    </div>
  )
)

export default SimpleCheckbookTable
