import clsx from "clsx"
import React from "react"
import { Change, diffChars, diffWords } from "diff"

import styles from "./TextDiff.module.scss"

export interface TextDiffProps {
  oldText: string
  newText: string
  by?: "words" | "chars"
  className?: string
}

export const TextDiff: React.FC<TextDiffProps> = ({
  oldText,
  newText,
  by = "chars",
  className,
}) => {
  const comparator = by === "words" ? diffWords : diffChars
  const diffText = comparator(oldText, newText)

  const renderPart = (item: Change) => {
    if (item.removed)
      return <marker className={styles.removed}>{item.value}</marker>
    if (item.added)
      return <marker className={styles.added}>{item.value}</marker>
    return item.value
  }

  return (
    <div className={clsx(styles.root, className)}>
      {diffText.map(renderPart)}
    </div>
  )
}

export default TextDiff
