/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { Button } from "@components/ui/Button"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import SuccessModal from "@components/modals/ConfirmModal/SuccessModal"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"

import styles from "./Segmentation.module.scss"

type SegmentationProps = {
  className?: string
}

const Segmentation: React.FC<SegmentationProps> = observer(({ className }) => {
  const alert = useAlert()

  const { accountStore, accountDashboardStore } = useStore()
  const { accountId } = accountStore

  const [configURL, setConfigUrl] = React.useState<string>()
  const [showError, setShowError] = React.useState(false)

  const { segmentation } = accountDashboardStore

  const { isLoading } = segmentation

  const openURL = (url: string) => window.open(url, "_blank")

  const handleLinkClick = async () => {
    if (accountId == null) return

    if (configURL != null) {
      openURL(configURL)
      return
    }

    const response = await segmentation.getSegmentationConfigLink(accountId)

    if (response.status === "SUCCESS") {
      const configUrl = response.data
      setConfigUrl(configUrl)
      openURL(configUrl)
    }

    if (response.status === "FAILED") alert.error(response.message)
  }

  const handleSync = async () => {
    if (accountId == null) return
    const response = await segmentation.syncSegmentationConfig(accountId)

    if (response.status === "FAILED") {
      setShowError(true)
      return
    }

    setConfigUrl(undefined)
    alert.success(
      <AlertMessage
        title="Synchronisation succeeded"
        description="Segmentation and Funnel settings successfully updated"
      />
    )
  }

  return (
    <Box className={clsx(styles.root, className)}>
      <Typography type="h2">Segments and Funnels</Typography>

      <div className={styles.container}>
        <div className={styles.row}>
          <Typography color="black70Color">
            Edit Segments and Funnels for all the Ad Groups in the Account.
          </Typography>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={handleLinkClick}
          >
            Edit Ad Groups
          </Button>
        </div>

        <div className={styles.row}>
          <Typography color="black70Color">
            Save Segments and Funnels data from Google Sheet to the Adoro DB.
          </Typography>
          <Button
            variant="outlined"
            before={
              isLoading ? <CircleLoader size="inherit" color="inherit" /> : null
            }
            onClick={handleSync}
            disabled={isLoading}
          >
            Sync Ad Groups
          </Button>
        </div>
      </div>

      <SuccessModal
        title="Error"
        message={
          <>
            It was not possible to sync the data from Google Sheet to Adoro DB.
            Please check if the structure of the file (columns and rows) is the
            same as the{" "}
            <a
              href="https://docs.google.com/spreadsheets/d/1HJfyiE6ejtJuAI-HgZakq7yt6S6GQp4nTGRyLRBVZNg/edit#gid=0"
              target="_blank"
              rel="noreferrer"
            >
              Table
            </a>
          </>
        }
        isOpen={showError}
        onClose={() => setShowError(false)}
      />
    </Box>
  )
})

export default Segmentation
