import React from "react"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import Label from "@components/ui/Label/Label"
import FormSimpleMultiSelectField from "@framework/prototypes/FormSimpleMultiSelectField"
import { useStore } from "@store/index"
import { ID } from "@framework/types/types"
import { useActiveSection } from "@pages/PMax/ActiveSectionContext"

import styles from "./index.module.scss"

export const LanguagesForm: React.FC = observer(() => {
  const { languagesStore } = useStore()

  const formik = useFormikContext<any>()

  const options =
    languagesStore.data?.map((it) => it.criterionId.toString()) ?? []

  const selectedList = formik.values?.languageIds as ID[]

  const isAllSelected = options.length === formik.values.languageIds?.length

  const selectAll = () =>
    formik.setFieldValue("languageIds", isAllSelected ? [] : [...options])

  const isSelected = (value: ID) =>
    selectedList.find((it) => it.toString() === value.toString()) != null

  const select = (name: ID, value?: boolean) => {
    const newList =
      value ?? !isSelected(name)
        ? [...selectedList, name]
        : selectedList.filter((it) => it !== name)
    return formik.setFieldValue("languageIds", newList)
  }

  const renderValue = (id: string) =>
    languagesStore.findById(id)?.name ?? `Unknown (${id})`

  const { props } = useActiveSection<HTMLDivElement>("languages")

  return (
    <Stack gutter="16" {...props}>
      <Typography type="h2">Languages</Typography>
      <Stack gutter="8">
        <Label label="Select the languages your customers speak">
          <FormSimpleMultiSelectField
            name="languageIds"
            isSelected={isSelected}
            options={options}
            isAllSelected={isAllSelected}
            onSelect={(v) => select(v)}
            onAllSelect={() => selectAll()}
            renderValue={renderValue}
            className={styles.input}
          />
        </Label>
        <Stack gutter="4" direction="row" wrappable>
          {isAllSelected ? (
            <Chip
              color="primary"
              type="outlined"
              endIcon={<Icon name="cross" onClick={() => selectAll()} />}
            >
              All languages
            </Chip>
          ) : selectedList?.length ? (
            selectedList?.map((it: ID) => (
              <Chip
                className={styles.langChip}
                color="primary"
                type="outlined"
                endIcon={
                  <Icon name="cross" onClick={() => select(it, false)} />
                }
              >
                {languagesStore.findById(it)?.name ?? `Unknown (${it})`}
              </Chip>
            ))
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  )
})

export default LanguagesForm
