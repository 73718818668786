import { useEffect, useMemo, useState } from "react"
import { Option } from "@framework/types/types"
import useOptionList from "./useOptionList"

interface Options<T extends string> {
  list: Option<T>[]
  defaultQuery?: string
  minQueryLength?: number
}

interface Returns<T> {
  query: string
  options: T[]
  total: number
  renderValue: (name: T) => React.ReactNode
  onSearch: (query: string) => void
}

export const useOptionSearchList = <T extends string>({
  list,
  defaultQuery = "",
  minQueryLength = 0,
}: Options<T>): Returns<T> => {
  const [query, setQuery] = useState(defaultQuery)

  const filteredList = useMemo(
    () =>
      minQueryLength > query.length
        ? []
        : list.filter((it) =>
            it.label.toLowerCase().includes(query.toLowerCase())
          ),
    [list, query, minQueryLength]
  )

  const listProps = useOptionList(filteredList)

  useEffect(() => {
    setQuery(defaultQuery)
  }, [defaultQuery])

  return {
    ...listProps,
    total: list.length,
    query,
    onSearch: setQuery,
  }
}

export default useOptionSearchList
