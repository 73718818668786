import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { reportLevelDescription } from "@framework/constants/report"
import { AccountReport } from "@store/reports/types"
import { useTableContext } from "./TableContext"
import PlatformList from "./PlatformList"
import PeriodReportList from "./PeriodReportList"
import CampaignTypeRowList from "./CampaignTypeRowList"
import MetaCampaignReportList from "./MetaCampaignReportList"

type AccountRowProps = {
  data: AccountReport
  mapper: MapperType[]
}

const AccountRow: React.FC<AccountRowProps> = observer(
  ({ data: report, mapper }) => {
    const context = useTableContext()

    const renderChild = () => {
      if (context.viewType === "Platform") {
        if (report.campaignGroupReportIds?.length) {
          return (
            <CampaignTypeRowList
              list={report.campaignGroupReportIds}
              mapper={mapper}
            />
          )
        }

        if (report.metaCampaignsReportIds?.length) {
          return (
            <MetaCampaignReportList
              list={report.metaCampaignsReportIds}
              mapper={mapper}
            />
          )
        }
      }

      if (context.viewType === "Account" && report.platformReportIds?.length)
        return <PlatformList list={report.platformReportIds} mapper={mapper} />

      if (context.viewType === "Weeks" && report.weeklyReportIds?.length)
        return (
          <PeriodReportList list={report.weeklyReportIds} mapper={mapper} />
        )

      if (context.viewType === "Months" && report.monthlyReportIds?.length)
        return (
          <PeriodReportList list={report.monthlyReportIds} mapper={mapper} />
        )

      return null
    }

    return (
      <ClosableRow
        id={report.id}
        component={
          <TypedRow
            label={report?.label}
            data={report}
            leftTip={<TypedTip data={reportLevelDescription.account} />}
            mapper={mapper}
          />
        }
      >
        {renderChild()}
      </ClosableRow>
    )
  }
)

export default AccountRow
