import React from "react"

import Button, { ButtonProps } from "@components/ui/Button/Button"

import styles from "./index.module.scss"

interface TableButtonProps {
  color?: ButtonProps["color"]
  onClick?: ButtonProps["onClick"]
}

export const TableButton: React.FC<TableButtonProps> = ({
  children,
  color = "default",
  ...rest
}) => (
  <Button
    {...rest}
    size="small"
    className={styles.linkButton}
    color={color}
    fit
    variant="ghost"
  >
    {children}
  </Button>
)

export default TableButton
