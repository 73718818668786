import React from "react"
import clsx from "clsx"

import { observer } from "mobx-react-lite"
import RowGroup from "./RowGroup"
import HeaderCell from "./TableCell/HeaderCell"
import { ColumnMapper } from "./types"

import styles from "./Row.module.scss"

interface HeaderProps<T extends object = any> {
  staticBefore?: number
  staticAfter?: number
  mapper: ColumnMapper<T>[]
  className?: string
}

const Header = observer(
  <T extends object>({
    mapper,
    staticBefore = 0,
    className,
  }: HeaderProps<T>) => {
    if (staticBefore < 0) throw new Error("staticBefore filed should be >= 0 ")

    const beforeMapper = mapper.slice(0, staticBefore)
    const middleMapper = mapper.slice(staticBefore, mapper.length)

    const renderCell = (mapper: ColumnMapper<T>) => (
      <div className={clsx(styles.cell, styles.headerCell)}>
        <HeaderCell label={mapper.label} />
      </div>
    )

    return (
      <div className={clsx(styles.header, className)}>
        <RowGroup
          name="static"
          mapper={beforeMapper}
          className={clsx(styles.group, styles.staticGroup)}
          renderCell={renderCell}
        />
        <RowGroup
          name="main"
          mapper={middleMapper}
          className={styles.group}
          renderCell={renderCell}
        />
      </div>
    )
  }
)

export default Header
