export enum QuadSizeType {
  tiny = "tiny",
  small = "small",
  medium = "medium",
  big = "big",
}

export enum OrientationType {
  horizontal = "horizontal",
  vertical = "vertical",
}

/**
 * @deprecated
 */
export enum TextType {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  subhead1 = "subhead1",
  subhead2 = "subhead2",
  body1 = "body1",
  body2 = "body2",
  caption1 = "caption1",
  caption2 = "caption2",
  caption3 = "caption3",
  // deprecated
  title = "title",
  subtitle = "subtitle",
  inherit = "inherit",
}

/**
 * @deprecated
 */
export enum TextWeightType {
  bold = "bold",
  normal = "normal",
}

/**
 * @deprecated
 */
export enum TextColorType {
  primary = "primary",
  dark = "dark",
  light = "light",
  gray = "gray",
  red = "red",
  darkBlue = "darkBlue",
  mediumDarkBlue = "mediumDarkBlue",
  lightGreen = "lightGreen",
  lightGray = "lightGray",
  darkGray = "darkGray",
  inherit = "inherit",
}

export type TypographyVariantType = keyof typeof TextType
export type TypographyColorType = keyof typeof TextColorType
