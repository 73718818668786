import React from "react"
import clsx from "clsx"

import Typography, {
  ITypographyProps,
} from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { MetricDirection } from "@framework/types/metrics"

import styles from "./Trend.module.scss"

interface TrendProps extends ITypographyProps, MetricDirection {}

export const Trend: React.FC<TrendProps> = ({
  children,
  direction,
  className,
  type = "h5",
  color,
  ...rest
}) => (
  <Typography
    {...rest}
    type={type}
    color={color}
    className={clsx(styles.root, className)}
  >
    {direction !== "none" && (
      <Icon name="arrow-down" rotateAngle={direction === "up" ? 180 : 0} />
    )}
    <span>{children}</span>
  </Typography>
)

export default Trend
