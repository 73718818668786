/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import { observer } from "mobx-react-lite"

import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { Option } from "@framework/types/types"

import styles from "./FilterList.module.scss"

interface FilterListProps {
  list: Option[]
  isSelected: (option: Option) => boolean
  onSelect: (option: Option) => void
}

const FilterList: React.FC<FilterListProps> = observer(
  ({ list, onSelect, isSelected }) => (
    <>
      {list.map((item) => {
        const { value, label } = item
        return (
          <label className={styles.row} key={value}>
            <CheckBox
              checked={isSelected?.(item) ?? false}
              onChange={() => onSelect(item)}
              onClick={(e) => e.stopPropagation()}
            />
            {label}
          </label>
        )
      })}
    </>
  )
)

export default FilterList
