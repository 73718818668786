import React from "react"

import Templates from "@components/ui/Templates"

import styles from "./index.module.scss"

const Footer: React.FC<{
  additionalSlot?: React.ReactNode
}> = ({ children, additionalSlot: cancelSlot }) => (
  <Templates.Header
    offset="none"
    className={styles.footer}
    left={cancelSlot}
    right={children}
  />
)

export default Footer
