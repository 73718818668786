import React from "react"
import { observer } from "mobx-react-lite"

import WaterfallTable from "@components/ui/WaterfallTable/WaterfallTable"
import { CampaignData } from "@framework/types/adCopy"
import NoData from "@components/ui/NoData/NoData"
import CampaignRow from "./CampaignRow"
import AdGroupRow from "./AdGroupRow"

import styles from "./CampaignsTable.module.scss"

export type CampaignsTableProps = {
  campaigns: CampaignData[]
}

export const CampaignsTable: React.FC<CampaignsTableProps> = observer(
  ({ campaigns = [] }) => (
    <div className={styles.root}>
      <WaterfallTable withTip rootClassName={styles.tableRoot} hideHeader>
        {campaigns.map(({ id, groupIds }, idx) => (
          <CampaignRow id={id} index={idx} key={id}>
            {groupIds == null ? null : groupIds.length ? (
              groupIds.map((adGroupId, idx) => (
                <AdGroupRow id={adGroupId} index={idx} key={adGroupId} />
              ))
            ) : (
              <NoData>No Ad Groups found</NoData>
            )}
          </CampaignRow>
        ))}
      </WaterfallTable>
    </div>
  )
)

export default CampaignsTable
