import { action, computed, observable } from "mobx"
import { KeywordEntityType } from "@framework/types/account"
import { ID } from "@framework/types/types"

class KeywordsStore<T extends KeywordEntityType> {
  // INJECTIONS

  // CONSTRUCTOR

  // STATE

  @observable data: T[] | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  // ACTIONS

  @action storeData = (data: T[]) => {
    this.data = data
  }

  @computed get dataIndex() {
    return new Map(
      this.data?.map((it) => [
        this.getKey(it.Id, it.AdgroupId, it.CampaignId),
        it,
      ])
    )
  }

  getKey = (keywordId: ID, adGroupId: ID, campaignId: ID) =>
    `${keywordId}_${adGroupId}_${campaignId}`

  @computed get getByID() {
    const { dataIndex } = this
    return (keywordId: ID, adGroupId: ID, campaignId: ID) =>
      dataIndex.get(this.getKey(keywordId, adGroupId, campaignId))
  }
}

export default KeywordsStore
