import React from "react"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/types"

import styles from "./StatusCard.module.sass"

export interface StatusCardProps {
  children?: React.ReactNode
  label?: string
  icon?: IconName
}

const StatusCard: React.FC<StatusCardProps> = ({
  icon = "pin-marker",
  children,
  label,
}) => (
  <div className={styles.root}>
    <div className={styles.tip}>
      <Icon name={icon} />
    </div>

    <div className={styles.body}>
      <Typography type="h4" weight="semibold" color="dark">
        {label}
      </Typography>
      <Typography type="h1" weight="bold">
        {children}
      </Typography>
    </div>
  </div>
)

export default StatusCard
