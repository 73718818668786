import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import NumberFormat, { NumberFormatValues } from "react-number-format"

import { moneyFormatter } from "@services/utils"
import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import TextField from "@components/ui/TextField/TextField"
import Label from "@components/ui/Label/Label"
import { useStore } from "@store/index"
import Typography from "@components/ui/Typography/Typography"
import { frequencyOptions } from "@framework/constants/manageCampaign"
import { BaseCellProps } from "@components/ui/WaterfallTable/types"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import useOptionList from "@framework/prototypes/useOptionList"
import useToggle from "@framework/hooks/useToggle"
import ModalTemplate from "@components/ui/Modal/ModalTemplate"
import { useAlert } from "react-alert"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import TableButton from "./TableButton"

import styles from "./index.module.scss"

export interface BudgetProps extends BaseCellProps {
  campaignId: string
  editable: boolean
}

export const Budget: React.FC<BudgetProps> = observer(
  ({ value, campaignId, editable }) => {
    const alert = useAlert()
    const modal = useToggle()

    const [amount, setAmount] = useState(value)
    const [frequency, setFrequency] = useState(frequencyOptions[0].value)

    const { manageCampaignStore, accountStore } = useStore()
    const { updateLoading, updateCampaign } = manageCampaignStore
    const { accountId } = accountStore

    const campaign = React.useMemo(
      () => manageCampaignStore.getCampaignById(Number(campaignId)),
      [campaignId]
    )

    const handleToggleVisibility = (e: any) => {
      modal.setOpened(!modal.isOpened)
      e.stopPropagation()
    }

    const handleSubmit = async () => {
      if (!accountId) return

      const error = await updateCampaign(accountId, campaignId, {
        budgetAmount: Number(amount) || 0,
        budgetFrequency: frequency,
        type: "BUDGET",
      })

      if (error) {
        alert.error(
          <AlertMessage
            title="Failed to update Budget value"
            description={error}
          />
        )
        return
      }

      alert.success("Budget value was successfully updated")

      if (modal.isOpened) modal.handleToggle()
    }

    const freqOptionsProps = useOptionList(frequencyOptions)

    const formattedValue = `${moneyFormatter(value)}/Day`

    return (
      <span>
        {editable ? (
          <TableButton onClick={handleToggleVisibility}>
            {formattedValue}
          </TableButton>
        ) : (
          formattedValue
        )}
        <Modal isOpen={modal.isOpened} hideHeader>
          <ModalTemplate.Header
            title="Change Budget"
            subtitle={
              <>
                Campaign:{" "}
                <Typography type="inherit" color="inherit" weight="bold" inline>
                  {campaign?.Campaign.Name}
                </Typography>
              </>
            }
          />

          <ModalTemplate.Body>
            <div className={styles.row}>
              <Label label="Amount">
                <NumberFormat
                  thousandSeparator
                  customInput={TextField}
                  value={amount}
                  onValueChange={(values: NumberFormatValues) =>
                    setAmount(values.value)
                  }
                  prefix="$"
                  name="amount"
                />
              </Label>
              <span>per</span>
              <Label label="Frequency">
                <SimpleSelect
                  value={frequency}
                  onSelect={setFrequency}
                  {...freqOptionsProps}
                />
              </Label>
            </div>
          </ModalTemplate.Body>

          <ModalTemplate.Footer>
            <Button color="secondary" onClick={handleToggleVisibility}>
              Cancel
            </Button>

            <Button onClick={handleSubmit} disabled={updateLoading}>
              Save
            </Button>
          </ModalTemplate.Footer>
        </Modal>
      </span>
    )
  }
)

export default Budget
