/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"

import { InfoWindow } from "@react-google-maps/api"
import Typography from "@components/ui/Typography/Typography"

import { AccountData } from "@framework/types/account"
import { parseAddress } from "@services/utils"

import styles from "./MapTooltip.module.scss"

export interface AccountTooltipProps {
  account: AccountData | null
  data: any
  onCloseClick?: () => void
}

export const AccountLocationTooltip: React.FC<AccountTooltipProps> = ({
  account,
  data,
  onCloseClick,
}) => {
  if (!account) return null
  return (
    <InfoWindow position={data.location} onCloseClick={onCloseClick}>
      <div className={styles.root}>
        <Typography type="h3" bold>
          {data.name}
        </Typography>

        <div className={styles.row}>
          <Typography type="h5" upperCase color="gray">
            Address:
          </Typography>
          &nbsp;
          <Typography type="h3">{parseAddress(account)}</Typography>
        </div>
      </div>
    </InfoWindow>
  )
}

export default AccountLocationTooltip
