import { action, observable } from "mobx"
import { delay } from "@utils/promise"
import { numberWithCommas } from "@utils/numberUtils"
import { SECOND } from "@framework/constants/time"

type StatusType = {
  stage: string
  label: string
}

export class KeywordScrappingTask {
  @observable websiteURL: string

  constructor(options: { websiteName: string }) {
    this.websiteURL = options.websiteName
  }

  @observable isCompleted: boolean = false

  @observable totalSteps = 8

  @observable totalKeywords = 622094

  @observable startedAt = Date.now()

  @observable status: StatusType = {
    stage: "NOT_STARTED",
    label: `Starting analysis`,
  }

  @action skipTask = () => {
    this.isCompleted = true
  }

  @action runTask = async () => {
    this.isCompleted = false
    this.isCompleted = await this.handleTask()
  }

  handleTask = async () => {
    this.startedAt = Date.now()

    await delay(500)

    for (let i = 0; i < 14; i += 1) {
      this.status = {
        stage: "WEBSITE_ANALYZING",
        label: `Analyzing the website ${this.websiteURL}`,
      }
      // eslint-disable-next-line no-await-in-loop
      await delay((7 * SECOND) / 14)
    }

    this.status = {
      stage: "WEBSITE_ANALYZING_COMPLETED",
      label: "Website analysis completed",
    }

    await delay(2 * SECOND)

    this.status = {
      stage: "RETRIEVING_KEYWORDS",
      label: "Retrieving keyword data from Google",
    }

    await delay(7 * SECOND)

    this.status = {
      stage: "KEYWORDS_INDEXING",
      label: "Adoro keywords AI indexing process started",
    }

    await delay(2 * SECOND)

    for (let i = 0; i < 14; i += 1) {
      const fraction = ((i + 1) / 14) * this.totalKeywords

      this.status = {
        stage: "KEYWORDS_IDENTIFYING",
        label: `${numberWithCommas(fraction)} Keywords identified`,
      }

      // eslint-disable-next-line no-await-in-loop
      await delay((7 * SECOND) / 14)
    }

    this.status = {
      stage: "KEYWORDS_IDENTIFYING",
      label: `${numberWithCommas(this.totalKeywords)} Keywords identified`,
    }

    await delay(1 * SECOND)

    this.status = {
      stage: "KEYWORDS_OPTIMIZING",
      label: "Adoro Keywords optimization started",
    }

    await delay(5 * SECOND)

    this.status = {
      stage: "KEYWORDS_INDEXING_FINISHED",
      label: "Adoro Keywords optimization started",
    }

    await delay(2 * SECOND)

    return true
  }
}

export default KeywordScrappingTask
