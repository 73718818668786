import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import Stack from "@components/ui/Stack/Stack"
import {
  Age,
  AssetGroupEntity,
  Gender,
  HouseholdIncome,
  ParentStatus,
} from "@framework/types/manageCampaign"
import { renderShortEnumeration, statusToLabel } from "@utils/stringUtils"

import styles from "./AudienceSignalTable.module.scss"

export const AudienceSignalTable: React.FC<{ data: AssetGroupEntity }> =
  observer(({ data }) => {
    const { audience } = data
    const audienceSegment = data.audience?.dimension?.audienceSegment
    const bestPractices = React.useMemo(() => {
      const res: string[] = []
      if (audienceSegment == null) return res
      if (
        audienceSegment?.userInterests?.length ||
        audienceSegment?.lifeEvents?.length ||
        audienceSegment?.detailedDemographics?.length
      )
        res.push("Use interests & detailed demographics")

      if (audienceSegment?.userLists?.length) res.push("Use your data")

      return res
    }, [audienceSegment])

    const personal = React.useMemo(() => {
      const res: string[] = []
      if (audienceSegment == null) return res

      if (audience?.dimension?.gender?.genders) {
        res.push(renderGender(audience?.dimension?.gender))
      }

      if (audience?.dimension?.age?.age_ranges) {
        res.push(renderAge(audience?.dimension?.age))
      }

      if (audience?.dimension?.parentalStatus?.parental_statuses) {
        res.push(renderParentStatus(audience.dimension.parentalStatus))
      }

      if (audience?.dimension?.householdIncome?.income_ranges) {
        res.push(renderIncome(audience.dimension.householdIncome))
      }

      return res
    }, [audience])

    if (audienceSegment == null || !audience?.name)
      return <Typography color="black70Color">No signals provided</Typography>

    const userList = (audienceSegment.userLists ?? []).map((it) => it.name)

    const custom = [
      ...(audienceSegment.detailedDemographics ?? []),
      ...(audienceSegment.customAudiences ?? []),
    ].map((it) => it.name)

    const interests = [
      ...(audienceSegment.userInterests ?? []),
      ...(audienceSegment.lifeEvents ?? []),
    ].map((it) => it.name)

    return (
      <Typography className={styles.root} type="body2" color="black80Color">
        <Typography type="caption2">{audience.name ?? "No name"}</Typography>

        <div className={styles.body}>
          <div className={clsx(styles.section, styles.content)}>
            {personal.length > 0 && (
              <Stack direction="row" gutter="8" align="center">
                <Icon name="user" />
                {renderShortEnumeration(personal, personal.length)}
              </Stack>
            )}

            {userList.length > 0 && (
              <Stack direction="row" gutter="8" align="center">
                <Icon name="repeat" />
                {renderShortEnumeration(userList)}
              </Stack>
            )}

            {interests.length > 0 && (
              <Stack direction="row" gutter="8" align="center">
                <Icon name="heart" /> {renderShortEnumeration(interests)}
              </Stack>
            )}

            {custom.length > 0 && (
              <Stack direction="row" gutter="8" align="center">
                <Icon name="shapes" /> {renderShortEnumeration(custom)}
              </Stack>
            )}
          </div>

          <div className={clsx(styles.sidebar, styles.section)}>
            <Typography type="inherit">Eligible</Typography>
            <Typography type="inherit">
              {bestPractices.length}/2 best practices
            </Typography>
          </div>
        </div>
      </Typography>
    )
  })

export default AudienceSignalTable

// GENDER
const renderGender = (data: Gender) => {
  const enumeration: string[] = [...(data.genders ?? [])]

  if (data?.include_undetermined) enumeration.push("UNKNOWN")

  return renderShortEnumeration(
    enumeration.map((it) => statusToLabel(it) ?? ""),
    1
  )
}

// AGE
const renderAge = (data: Age) => {
  const minAge = data.age_ranges?.[0].min_age ?? null
  const minIndex = ageRanges.findIndex((it) => it.min === minAge)

  const maxAge = data.age_ranges?.[0].max_age ?? null
  const maxIndex = ageRanges.findIndex((it) => maxAge === it.max)

  if (maxIndex === -1 || minIndex === -1) return ""

  const diapasons = maxIndex - minIndex + 1

  if (diapasons === ageRanges.length) return ""

  const enumeration = ageRanges.slice(minIndex, maxIndex + 1)

  if (data.include_undetermined) enumeration.push({ min: 0, max: 0 })

  return renderShortEnumeration(
    enumeration.map((it) => renderAgeRange(it) ?? ""),
    1
  )
}

const renderAgeRange = ({
  min,
  max,
}: {
  min: number | null
  max: number | null
}) => {
  if (min == null || max == null) return "65+"
  if (min === 0 || max === 0) return "Unknown"
  return `${min} - ${max}`
}

const ageRanges = [
  {
    min: 18,
    max: 24,
  },
  {
    min: 25,
    max: 34,
  },
  {
    min: 35,
    max: 44,
  },
  {
    min: 45,
    max: 54,
  },
  {
    min: 55,
    max: 64,
  },
  { min: null, max: null },
]

// HOUSEHOLD INCOME
const renderIncome = (data: HouseholdIncome) => {
  const enumeration = [...data.income_ranges]
  enumeration.reverse()

  if (data.include_undetermined) enumeration.push("UNKNOWN")

  if (enumeration.length === Object.keys(houseHoldIncomeRanges).length + 1)
    return ""

  return renderShortEnumeration(
    enumeration.map((it) => renderIncomeRange(it) ?? ""),
    1
  )
}

const renderIncomeRange = (income: string) =>
  houseHoldIncomeRanges[income as keyof typeof houseHoldIncomeRanges] ??
  statusToLabel(income)

const houseHoldIncomeRanges = {
  INCOME_RANGE_0_50: "Lower 50%",
  INCOME_RANGE_50_60: "41% - 50%",
  INCOME_RANGE_60_70: "31% - 40%",
  INCOME_RANGE_70_80: "21% - 30%",
  INCOME_RANGE_80_90: "11% - 20%",
  INCOME_RANGE_90_UP: "Top 10%",
}

// PARENT STATUS

const renderParentStatus = (data: ParentStatus) => {
  const enumeration = [...data.parental_statuses]

  if (data.include_undetermined) enumeration.push("UNKNOWN")

  if (enumeration.length === parentStatuses.length + 1) return ""

  return renderShortEnumeration(
    enumeration.map((it) => statusToLabel(it) ?? ""),
    1
  )
}

const parentStatuses = ["PARENT", "NOT_A_PARENT"]
