import { observable, action } from "mobx"
import { Option } from "@framework/types/types"
import {
  ProductAttribute,
  ProductCategoryOptionData,
} from "@framework/types/account"
import accountService from "@services/account.service"
import RootStore from "../RootStore"

export type ProductFeedFieldsOptionsMap = {
  [key in ProductAttribute | string]?: Option[]
}

export class ProductOptionsStore {
  @observable rootStore: RootStore

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @observable isCategoriesLoading: boolean = false

  @observable categories: ProductCategoryOptionData[] = []

  @action loadCategories = async (accountId: number, query: string) => {
    try {
      this.categories = []
      this.isCategoriesLoading = true
      const response = await accountService.getCategories(accountId, query)

      if (response.data.data) {
        this.categories = response.data.data
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isCategoriesLoading = false
    }
  }
}

export default ProductOptionsStore
