import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import { TrendValue } from "@components/ui/DataChangeWidget/DataChangeWidget"
import { MetricDescription } from "@framework/types/metrics"
import Label from "@components/ui/CheckBox/Label/Label"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"

import styles from "./GoalWidgetCard.module.scss"

export const GoalWidgetCard: React.FC<{
  goalValue: number
  actualValue: number
  delta: number
  metric: MetricDescription
  className?: string
  inline?: boolean
}> = React.memo(
  ({
    goalValue,
    actualValue,
    delta,
    metric: desc,
    className,
    inline = false,
  }) => (
    <div className={clsx(styles.root, { [styles.inline]: inline }, className)}>
      <Typography type="h2" weight="semibold" className={styles.title}>
        {desc.label}
      </Typography>

      <Stack direction="row" gutter="16" className={styles.content}>
        <Label
          text="GOAL"
          type="h6"
          color="black70Color"
          className="label"
          textPosition="before"
          orientation={inline ? "horizontal" : "vertical"}
        >
          <Typography className={styles.metric} type="h3" weight="bold">
            {desc.formatter(goalValue)}
          </Typography>
        </Label>

        <Label
          text="ACTUAL"
          type="h6"
          color="black70Color"
          className="label"
          textPosition="before"
          orientation={inline ? "horizontal" : "vertical"}
        >
          <Typography className={styles.metric} type="h3" weight="bold">
            {desc.formatter(actualValue)}
          </Typography>
        </Label>

        <Label
          text="DELTA"
          type="h6"
          color="black70Color"
          className="label"
          textPosition="before"
          orientation={inline ? "horizontal" : "vertical"}
        >
          <TrendValue
            className={styles.metric}
            typographyType="h3"
            weight="bold"
            value={delta}
            arrowAfter
            renderValue={(v) => `${Math.round(v * 100)}%`}
            metricDir={(desc?.trendFormat ?? positiveTransformDirection)(delta)}
          />
        </Label>
      </Stack>
    </div>
  )
)
export default GoalWidgetCard
