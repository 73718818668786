import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import Image from "@components/ui/Image/Image"
import ImageTopper from "@components/ui/Image/Topper"
import AssetImagesSidebar from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar"
import { AssetGroupEntity } from "@framework/types/manageCampaign"
import styles from "./Assets.module.scss"

interface ImagesProps {
  images: AssetGroupEntity["imageAssets"]
}
const Images: FC<ImagesProps> = observer(({ images }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const imagesArray = images && images.length ? images : []
  return (
    <div className={styles.section}>
      <Typography className={styles.headTitle} type="h2">
        Images
      </Typography>
      <Typography type="subtitle" color="gray">
        {imagesArray.length} images added
      </Typography>
      <div className={styles.images}>
        {imagesArray.slice(0, 3).map((img, i) => (
          <Image src={img.url} className={styles.image} key={`${i + 1}-logo`} />
        ))}
        <ImageTopper className={styles.image} message="+">
          <Image key="last-image" />
        </ImageTopper>
      </div>

      <Button
        color="primary"
        before={<Icon name="plus" key="plus" />}
        variant="ghost"
        className={styles.buttonIcon}
        onClick={() => setIsOpen(true)}
      >
        Image
      </Button>

      <AssetImagesSidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
})

export default Images
