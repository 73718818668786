/* eslint-disable no-unused-expressions */
import { observable, action, computed } from "mobx"
import { randomNumber } from "@components/utils/numberUtils"
import { AccountData } from "@framework/types/account"
import accountService from "@services/account.service"
import { ID } from "@framework/types/types"
import RootStore from "../RootStore"

export class AccountStore {
  @observable accountId: number | null = null

  @observable rootStore: RootStore

  @observable clientAccountList: AccountData[] = []

  @observable accountList: AccountData[] | null = null

  @observable totalAccountCount: number = 50

  @observable account: AccountData | null = null

  @observable isLoading: boolean = false

  @observable isAccountListLoading: boolean = false

  @observable accountListError: string | null = null

  @observable accountUpdating: boolean = false

  @computed get loading() {
    return this.isAccountListLoading || this.isLoading
  }

  @computed get accountIds() {
    return this.accountList?.map((it) => it.id) ?? []
  }

  @computed get accounts() {
    return new Map(this.accountList?.map((it) => [it.id, it]) ?? [])
  }

  @action getAccountId() {
    const { accountId } = this
    if (accountId == null) throw new Error("Account is not initialized")
    return accountId
  }

  @computed get getAccountById() {
    const { accounts } = this
    return (id?: ID) => (id == null ? null : accounts.get(Number(id)))
  }

  @observable sortFilter: { value: string; direction: boolean } = {
    value: "none",
    direction: false,
  }

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @action setAccountId = (accountId: number | null) => {
    this.accountId = accountId
  }

  @action loadAccountList = async () => {
    this.isAccountListLoading = true
    this.accountListError = null
    this.accountList = null
    try {
      const response = await accountService.fetchAccountList()
      this.accountList = response.data.data.records ?? []
    } catch (error) {
      this.accountListError = "Account list loading failed"
    } finally {
      this.isAccountListLoading = false
    }
  }

  @action loadClientsAccounts = async (clientId: number) => {
    this.isLoading = true
    try {
      const response = await accountService.fetchAccountListByClientId(clientId)
      this.clientAccountList = response.data.data.records ?? []
      this.totalAccountCount = response.data.data.total
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  @action loadAccount = async (accountId?: ID) => {
    this.isLoading = true
    try {
      const id = accountId || this.accountId
      if (id) {
        const response = await accountService.getAccount(id)
        await accountService.getAccountGoalOptions()
        this.account = response.data.data
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  @action updateAccountInfo = async (accountId: ID, data: any) => {
    this.accountUpdating = true
    try {
      const response = await accountService.updateAccountInfo(accountId, data)
      this.account = response.data.data
    } catch (error) {
      return "Unexpected error"
    } finally {
      this.accountUpdating = false
    }
    return null
  }

  @action loadSortedAccountList = async (
    clientId: number,
    sortFilter: {
      value: string
      direction: boolean
    } = { value: "profile", direction: false },
    page: number = 0,
    size: number = 99999
  ) => {
    this.isLoading = true
    const dir =
      sortFilter.value === "profile" && sortFilter.direction ? "DESC" : "ASC"
    const response = await accountService.fetchAccountListByClientId(
      clientId,
      dir,
      page * size,
      size
    )
    this.clientAccountList = response.data.data.records ?? []
    this.totalAccountCount = response.data.data.total
    this.isLoading = false
  }

  @action sortBy = (sortFilter: { value: string; direction: boolean }) => {
    this.sortFilter = sortFilter
  }

  @computed get clientAccounts() {
    return this.clientAccountList.map((account) => {
      const {
        id,
        name,
        Industry,
        yearlyAdBudget,
        status,
        campaignStatus,
        adAccountId,
        metaCredentials,
      } = account

      return {
        id,
        profileName: name,
        industry: Industry?.name,
        totalAdSpend: yearlyAdBudget ? Number(yearlyAdBudget) : 0,
        profileStatus: status,
        profileCompetition: `${randomNumber(0, 100)}%`,
        linkedAdwordsAccount: adAccountId ? "Yes" : "No",
        campaignStatus,
        metaCredentials,
      }
    })
  }
}

export default AccountStore
