import { action, computed, observable } from "mobx"

import { TopicEntity } from "@framework/types/opportunities"

type TopicGroup = "rising" | "top"

export class RelatedTopicsStore {
  @observable data: Record<TopicGroup, TopicEntity[]> = {
    rising: [],
    top: [],
  }

  @observable isLoading: boolean = false

  @observable error: string | null = null

  @observable activeGroup: TopicGroup = "top"

  @observable options: TopicGroup[] = ["top", "rising"]

  @computed get activeList() {
    return this.data[this.activeGroup]
  }

  @action setActiveGroup = (activeGroup: TopicGroup) => {
    this.activeGroup = activeGroup
  }
}

export default RelatedTopicsStore
