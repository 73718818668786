import { ID } from "@framework/types/types"
import { nanoid } from "nanoid"
import { AdCopyTask } from "./edit-adCopy.store"

class DeleteAdCopyTask implements AdCopyTask {
  constructor(adCopyId: ID) {
    this.id = nanoid()
    this.adCopyId = adCopyId
  }

  readonly id: string

  readonly type = "delete"

  readonly adCopyId: ID
}

export default DeleteAdCopyTask
