import React from "react"

interface DisplayIconProps {
  width?: number
  height?: number
}

const DisplayIcon = ({ width, height }: DisplayIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 33}
    height={height || 32}
    fill="none"
  >
    <path
      fill="#38A752"
      fillRule="evenodd"
      d="M1.5 0a1 1 0 0 0-1 1v23a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-26Zm2 7a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-13Zm17 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-5Zm-18 10a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-13a1 1 0 0 1-1-1v-5Z"
      clipRule="evenodd"
    />
  </svg>
)
export default DisplayIcon
