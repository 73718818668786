import React from "react"

import styles from "./MobileFrame.module.scss"

export interface MobileFrameProps {}

const MobileFrame: React.FC<MobileFrameProps> = ({ children }) => (
  <div className={styles.root}>
    <span className={styles.camera} />
    <div className={styles.content}>{children}</div>
  </div>
)

export default MobileFrame
