import { action, computed, observable, onBecomeObserved, reaction } from "mobx"
import moment from "moment"

import {
  getPlatformOptions,
  platformOptions,
} from "@framework/constants/report"
import RootStore from "@store/RootStore"
import SelectableOptions from "@store/common/SelectableOptions"
import { AccountData } from "@framework/types/account"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import SelectableList from "@store/common/SelectableList"
import { Option } from "@framework/types/types"
import {
  getCurrentPeriodOptions,
  getPreviousPeriodOptions,
  isDateRangeOptionsEqual,
  isOptionsListEqual,
} from "./utils"
import { CampaignStatusType } from "./types"

export type FilterData = {
  accounts: string[]
  platforms: string[]
  campaignTypes: string[]
  currPeriod: DateRangeOption
  prevPeriod: DateRangeOption
}

export class ReportFilterStore {
  root: RootStore

  constructor(root: RootStore) {
    this.root = root

    this.maxDate = this.initMaxDate()

    this.currPeriod = this.initCurrentPeriod(this.maxDate)

    this.prevPeriod = this.initPrevPeriod(this.currPeriod.selectedOptions[0])

    this.accounts = this.initAccounts(this.root.accountStore.accountList ?? [])

    this.platforms = this.initPlatforms()

    this.campaignTypes = this.initCampaignTypes(this.platforms.selectedList)

    this.activeFilter = {
      accounts: [],
      platforms: [...this.platforms.selectedList],
      campaignTypes: [...this.campaignTypes.selectedList],
      currPeriod: this.currPeriod.selectedOptions[0],
      prevPeriod: this.prevPeriod.selectedOptions[0],
    }

    reaction(
      () => this.platforms.selectedList,
      (selectedPlatforms) => {
        this.campaignTypes = this.initCampaignTypes(selectedPlatforms)
      }
    )
    reaction(
      () => this.currPeriod.selectedOptions[0],
      (currPeriod) => {
        if (currPeriod) this.prevPeriod = this.initPrevPeriod(currPeriod)
      }
    )

    onBecomeObserved(this, "maxDate", () => {
      this.maxDate = this.initMaxDate()
    })
  }

  @observable campaignStatus: CampaignStatusType = "ANY"

  @observable isComparisonEnabled = false

  @action toggleComparison = () => {
    this.isComparisonEnabled = !this.isComparisonEnabled
  }

  @observable maxDate: Date

  @observable currPeriod: SelectableOptions<DateRangeOption>

  @observable prevPeriod: SelectableOptions<DateRangeOption>

  @observable accounts: SelectableList

  @observable platforms: SelectableList

  @observable campaignTypes: SelectableList

  @observable activeFilter: FilterData

  @computed get disabledAccounts(): string[] {
    return (
      this.root.accountStore.accountList
        ?.filter((it) => !it.adAccountId)
        .map((it) => it.id.toString()) ?? []
    )
  }

  @computed get isActiveFilterValid(): boolean {
    const { accounts, campaignTypes, platforms } = this.activeFilter
    return (
      accounts.length > 0 && campaignTypes.length > 0 && platforms.length > 0
    )
  }

  @computed get tempFilter(): FilterData {
    return {
      accounts: this.accounts.selectedList,
      platforms: this.platforms.selectedList,
      campaignTypes: this.campaignTypes.selectedList,
      prevPeriod: this.prevPeriod.selectedOptions[0],
      currPeriod: this.currPeriod.selectedOptions[0],
    }
  }

  @computed get isSync() {
    return (
      this.isAccountsSync &&
      this.isPlatformsSync &&
      this.isCampaignTypesSync &&
      this.isCurrPeriodSync &&
      this.isPrevPeriodSync
    )
  }

  @computed get isCurrPeriodSync() {
    const date1 = this.tempFilter.currPeriod
    const date2 = this.activeFilter.currPeriod
    return (
      date1.value === date2.value &&
      isDateRangeOptionsEqual(date1.range, date2.range)
    )
  }

  @computed get isPrevPeriodSync() {
    const date1 = this.tempFilter.prevPeriod
    const date2 = this.activeFilter.prevPeriod
    return (
      date1.value === date2.value &&
      isDateRangeOptionsEqual(date1.range, date2.range)
    )
  }

  @computed get isCampaignTypesSync() {
    return isOptionsListEqual(
      this.tempFilter.campaignTypes,
      this.activeFilter.campaignTypes
    )
  }

  @computed get isAccountsSync() {
    return isOptionsListEqual(
      this.tempFilter.accounts,
      this.activeFilter.accounts
    )
  }

  @computed get isPlatformsSync() {
    return isOptionsListEqual(
      this.tempFilter.platforms,
      this.activeFilter.platforms
    )
  }

  @action toggleCampaignStatus = (value: string) => {
    this.campaignStatus =
      this.campaignStatus === value ? "ANY" : (value as CampaignStatusType)
  }

  @action cancelFilter = () => {
    if (!this.isCurrPeriodSync)
      this.currPeriod.updateOption(this.activeFilter.currPeriod, true)
    if (!this.isPrevPeriodSync)
      this.prevPeriod.updateOption(this.activeFilter.prevPeriod, true)
    if (!this.isAccountsSync)
      this.accounts.setSelected(this.activeFilter.accounts)
    if (!this.isPlatformsSync)
      this.platforms.setSelected(this.activeFilter.platforms)
    if (!this.isCampaignTypesSync)
      this.campaignTypes.setSelected(this.activeFilter.campaignTypes)
  }

  @action applyFilters = () => {
    this.activeFilter = {
      accounts: [...this.accounts.selectedList],
      platforms: [...this.platforms.selectedList],
      campaignTypes: [...this.campaignTypes.selectedList],
      prevPeriod: this.prevPeriod.selectedOptions[0],
      currPeriod: this.currPeriod.selectedOptions[0],
    }
  }

  @action resetAccounts = (accountList: AccountData[], selected?: string[]) => {
    this.accounts = this.initAccounts(accountList ?? [], selected)
    this.activeFilter.accounts = [...this.accounts.selectedList]
  }

  @action initAccounts = (accounts: AccountData[], selected?: string[]) => {
    const options = accounts.map(({ id }) => id.toString()) ?? []

    return new SelectableList({
      options,
      selected: selected ?? options,
    })
  }

  @action initPlatforms = () => {
    const selectedPlatforms: string[] = platformOptions
    return new SelectableList({
      options: selectedPlatforms,
      selected: selectedPlatforms,
    })
  }

  @action initCampaignTypes = (platforms: string[]) => {
    const options = getPlatformOptions(platforms) as Option[]

    const selected = options.map((it) => it.value)

    return new SelectableList({
      options: selected,
      selected,
    })
  }

  @action initMaxDate = () => moment().endOf("day").toDate()

  @action initCurrentPeriod = (maxDate: Date) => {
    const options = getCurrentPeriodOptions(maxDate)
    return new SelectableOptions<DateRangeOption>({
      options,
      selected: [options[0].value],
      multiselect: false,
    })
  }

  @action initPrevPeriod = (currPeriod: DateRangeOption) => {
    const prevPeriodOptions = getPreviousPeriodOptions(currPeriod.range)

    const selected = this.prevPeriod?.selected?.size
      ? Array.from(this.prevPeriod.selected)
      : [prevPeriodOptions[0].value]

    return new SelectableOptions<DateRangeOption>({
      options: prevPeriodOptions,
      selected,
      multiselect: false,
    })
  }
}

export default ReportFilterStore
