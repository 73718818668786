import React from "react"

import { BaseCellProps } from "@components/ui/WaterfallTable/types"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType, MetricType } from "@framework/types/metrics"
import { mbNoData } from "@services/utils"

interface MetricColumnProps extends BaseCellProps {
  value: MetricType
  name: MetricNameType
}

export const MetricCell: React.FC<MetricColumnProps> = ({ value, name }) => {
  const mapper = getMetricDescriptor(name)
  return <span>{mbNoData(value?.Value, mapper.formatter)}</span>
}

export default MetricCell
