/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable } from "mobx"
import _get from "lodash/get"
import { ID } from "@framework/types/types"
import OpportunitiesStore from "./opportunities"

type ActiveChart = {
  id: ID
  type: "category" | "attribute"
}

class AttributeChart {
  @observable activeChart: ActiveChart | null = null

  @observable opportunitiesStore: OpportunitiesStore

  @computed get activeAttribute() {
    const { activeChart } = this
    if (activeChart == null) return null

    if (activeChart.type === "attribute")
      return (
        this.opportunitiesStore.recommendedKeywords.find(
          (item) => item.id === activeChart.id
        ) ?? null
      )

    return (
      this.opportunitiesStore.categoryGroups.find(
        ({ id }) => id === activeChart.id
      ) ?? null
    )
  }

  @computed get isOpened() {
    return this.activeChart != null
  }

  constructor(opportunitiesStore: OpportunitiesStore) {
    this.opportunitiesStore = opportunitiesStore
  }

  @action showChart = (type: "category" | "attribute", id: ID) => {
    this.activeChart = { type, id }
  }

  @action hideChart = () => {
    this.activeChart = null
  }
}

export default AttributeChart
