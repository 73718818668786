import React from "react"
import { ID } from "@framework/types/types"
import useToggle from "@framework/hooks/useToggle"
import AddNegativeKeywordsModal from "@components/modals/AddNegativeKeywordsModal/AddNegativeKeywordsModal"

const useAddNegativeKeywordsModal = (options?: {
  adGroupId?: ID
  campaignId?: ID
}) => {
  const modal = useToggle()

  const Modal = modal.isOpened ? (
    <AddNegativeKeywordsModal
      isOpen
      onClose={() => modal.setOpened(false)}
      campaignId={options?.campaignId}
      adGroupId={options?.adGroupId}
    />
  ) : null
  return {
    open: () => modal.setOpened(true),
    Modal,
    ...modal,
  }
}

export default useAddNegativeKeywordsModal
