import React from "react"
import RootController from "@store/RootController"
import GlobalScrollContextProvider from "@components/prototypes/GlobalScrollContext"
import AlertProvider from "./components/ui/AlertPopup/AlertProvider"
import { StoreProvider } from "./store"
import RootStore from "./store/RootStore"

const store = new RootStore()

const controller = new RootController(store)

const AppProvider: React.FC = ({ children }) => (
  <GlobalScrollContextProvider>
    <StoreProvider store={store} controller={controller}>
      <AlertProvider>{children}</AlertProvider>
    </StoreProvider>
  </GlobalScrollContextProvider>
)

export default AppProvider
