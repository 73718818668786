import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"

import styles from "./ReportTitle.module.sass"

interface ReportTitleProps {
  children?: string
  textClassName?: string
}

export const ReportTitle: React.FC<ReportTitleProps> = ({
  children,
  textClassName,
}) => (
  <div className={styles.root}>
    <Icon name="link" />
    &nbsp;
    <Typography
      inline
      type="inherit"
      className={clsx(styles.label, textClassName)}
      title={children}
    >
      {children}
    </Typography>
  </div>
)

export default ReportTitle
