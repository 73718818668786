import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { AccountReport } from "@store/reports/types"
import NoData from "@components/ui/NoData/NoData"
import { useTableContext } from "./TableContext"
import { sortReports } from "./utils"
import AccountRow from "./AccountRow"

interface AccountReportListProps {
  list: string[]
  mapper: MapperType[]
}

const AccountReportList: React.FC<AccountReportListProps> = observer(
  ({ list, mapper }) => {
    const {
      reports: { extendedReport },
    } = useStore()

    const context = useTableContext()

    const reports = useMemo(
      () =>
        sortReports(
          list.reduce<AccountReport[]>((list, id) => {
            const item = extendedReport.accounts.get(id)
            if (item != null) list.push(item)
            return list
          }, []),
          context.sortBy
        ),
      [list, context.sortBy, extendedReport.accounts]
    )

    if (!reports.length) return <NoData>Not found</NoData>
    return (
      <>
        {reports.map((report) => (
          <AccountRow data={report} mapper={mapper} key={report.id} />
        ))}
      </>
    )
  }
)

export default AccountReportList
