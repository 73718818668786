/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"

import { Option } from "@framework/types/types"
import { TableMapper } from "@pages/ClientsList/ClientsList"

import styles from "./ChannelTable.module.scss"

type HeaderProps = {
  checked?: boolean
  mapper: TableMapper[]
  onChange?: () => void
  onSort?: (sortBy: { value: string; direction: boolean }) => void
}

const Header: React.FC<HeaderProps> = ({ mapper, onSort }) => {
  const [sortBy, setSortBy] = useState(() => ({
    value: "none",
    direction: false,
  }))

  const handleSort = (item: Option) => {
    setSortBy((current) => {
      const newValue = {
        value: item.value.toString(),
        direction: current.value === item.value && !current.direction,
      }
      onSort?.(newValue)
      return newValue
    })
  }

  return (
    <div className={styles.wrapper}>
      {mapper.map((option) => (
        <Typography
          onClick={option.isSortable ? () => handleSort(option) : undefined}
          className={clsx(styles.column, {
            [styles.sortable]: option.isSortable,
          })}
          type="h6"
          color="gray"
          upperCase
          key={option.value}
        >
          {option.label}
          <span className={styles.sortArrow}>
            {option.isSortable ? (
              <Icon
                name={sortBy.value === option.value ? "arrow-down" : "none"}
                rotateAngle={sortBy.direction ? 180 : 0}
              />
            ) : null}
          </span>
        </Typography>
      ))}
    </div>
  )
}

export default Header
