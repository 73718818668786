/* eslint-disable react/jsx-props-no-spreading */
import clsx from "clsx"
import React, { useMemo } from "react"
import { OrientationType, QuadSizeType } from "@utils/enums"
import Icon from "@components/ui/Icon/Icon"
import BaseProgressBar from "../BaseProgressBar/BaseProgressBar"
import styles from "./FileUploadProgress.module.scss"

export interface IFileUploadProgressProps {
  value: number
  fileName: string
  fileExtension?: string
  className?: string
  onCancel?: () => void
}

export const FileUploadProgress: React.FC<IFileUploadProgressProps> = ({
  value,
  fileName,
  fileExtension = "unknown_file",
  className = "",
  onCancel,
  ...rest
}: IFileUploadProgressProps) => {
  const rootStyles = useMemo(() => clsx(styles.root, className), [className])

  const validValue = useMemo(
    () =>
      !Number.isNaN(value) && value <= 100 ? (value >= 0 ? value : 0) : 100,
    [value]
  )

  return (
    <div className={rootStyles}>
      <span className={styles.pdfImage}>
        <img height="30em" src={`/images/${fileExtension}.svg`} alt="icon" />
        <span>{fileName}</span>
      </span>
      <span className={styles.percent}>{value}%</span>
      <BaseProgressBar
        value={validValue}
        {...rest}
        size={QuadSizeType.big}
        gradient={OrientationType.horizontal}
      />
      <Icon name="cross" className={styles.closeIcon} onClick={onCancel} />
    </div>
  )
}

export default FileUploadProgress
