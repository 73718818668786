import React, { useState } from "react"
import { PaginationProps } from "@components/ui/Pagination"
import { CounterProps } from "@components/ui/Pagination/Counter"

export type PaginatorRowProps = {
  options: CounterProps
  controllerProps: PaginationProps
}

export type Options = {
  total: number
  pageSize?: number
  totalPages?: number
  activeIndex?: number
}

const usePagination = (options: Options): PaginatorRowProps => {
  const { total = 0, pageSize = 1, totalPages, activeIndex } = options
  const [page, setPage] = useState(activeIndex ?? 0)

  const pageCount = totalPages ?? Math.ceil(total / pageSize)

  React.useLayoutEffect(() => {
    if (page + 1 > pageCount) setPage(pageCount > 0 ? pageCount - 1 : 0)
  }, [pageCount])

  const onPageChange: PaginationProps["onPageChange"] = React.useCallback(
    (it) => setPage(it.selected),
    []
  )

  const onClick: PaginationProps["onClick"] = React.useCallback(
    ({ selected, nextSelectedPage, isPrevious, isNext }) => {
      if (nextSelectedPage != null) return nextSelectedPage
      if (isPrevious && selected === 0) return pageCount - 1
      if (isNext && selected === pageCount - 1) return 0
      return selected
    },
    [pageCount]
  )

  return {
    options: {
      page,
      pageSize,
      total,
    },
    controllerProps: {
      forcePage: page,
      pageCount,
      onPageChange,
      onClick,
    },
  }
}

export default usePagination
