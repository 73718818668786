import React, { useEffect, useState } from "react"

import DatePeriodMenuItem, {
  DatePeriodMenuItemProps,
} from "@components/ui/DatePicker/DatePeriodMenuItem"
import Container from "@components/ui/DropDown/Container"
import DatePickerCard from "@components/ui/DatePicker/DatePickerCard"
import useActiveElement from "@framework/hooks/useIsActiveElement"
import Select from "@components/ui/DropDown"
import { DateRangeOption } from "./types"
import SimpleOption from "../DropDown/Option"

interface DatePeriodOptionProps extends DatePeriodMenuItemProps {
  value?: string
  options?: DateRangeOption[]
  minDate?: Date
  maxDate?: Date
  onSelect?: (option: DateRangeOption, e: any) => void
}

export const DatePeriodOption: React.FC<DatePeriodOptionProps> = ({
  value,
  options = [],
  onSelect,
  title,
  children,
  minDate,
  maxDate,
  ...rest
}) => {
  const [isActive, setActive, ref] = useActiveElement()
  const [editing, setEditable] = useState<DateRangeOption>()

  const handleSelect = (option: DateRangeOption) => (e: React.MouseEvent) => {
    if (option.editable) {
      setEditable(option)
      e.stopPropagation()
      e.preventDefault()
      return
    }
    if (onSelect) onSelect(option, e)
    setActive(false)
  }

  const handleApply = (range: [Date, Date], e: React.MouseEvent) => {
    if (editing && onSelect) onSelect({ ...editing, range }, e)
    setEditable(undefined)
    setActive(false)
  }

  const handleCancel = (e: React.MouseEvent) => {
    setEditable(undefined)
  }

  useEffect(() => {
    if (!isActive) setEditable(undefined)
  }, [isActive])

  return (
    <span ref={ref}>
      <DatePeriodMenuItem
        {...rest}
        title={title}
        isSelected={isActive}
        onClick={() => setActive(!isActive)}
      >
        {children}
      </DatePeriodMenuItem>

      {isActive && (
        <Container position="x">
          {editing == null ? (
            <Select.Box>
              <Select.Group label={title}>
                {options?.map((it) => {
                  const isSelected = value != null && it.value === value
                  return (
                    <SimpleOption
                      onClick={handleSelect(it)}
                      isSelected={isSelected}
                      key={it.value}
                    >
                      {it.label}
                    </SimpleOption>
                  )
                })}
              </Select.Group>
            </Select.Box>
          ) : (
            <Select.Box>
              <Select.Group label="Select competing period">
                <DatePickerCard
                  minDate={minDate}
                  maxDate={maxDate}
                  defValue={editing.range}
                  onCancel={handleCancel}
                  onApply={handleApply}
                />
              </Select.Group>
            </Select.Box>
          )}
        </Container>
      )}
    </span>
  )
}

export default DatePeriodOption
