/* eslint-disable jsx-a11y/no-autofocus */
import React from "react"

import SimpleOption from "@components/ui/DropDown/Option"
import Select from "@components/ui/DropDown"
import NoData from "@components/ui/NoData/NoData"
import { capitalizeFirstLetter } from "@utils/stringUtils"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import BaseField from "@components/ui/TextField/BaseField"
import Typography from "@components/ui/Typography/Typography"
import { PopperPlacement } from "./usePopper"

export interface SimpleSelectProps<T extends string = string> {
  value?: T
  options: T[]
  className?: string
  placement?: PopperPlacement
  placeholder?: string
  disabled?: boolean
  withSearch?: boolean
  query?: string
  edited?: boolean
  error?: boolean
  hideArrow?: boolean
  onSearch?: (value: string, e?: React.ChangeEvent) => void
  onSelect?: (value: T, e?: React.MouseEvent) => void
  isSelected?: (value: T, selected?: T) => boolean
  renderBefore?: (value: T) => React.ReactNode
  renderValue?: (value: T) => React.ReactNode
  renderOption?: (value: T) => React.ReactNode
}

const SimpleSelect = <T extends string = string>({
  options,
  value,
  className,
  placement,
  placeholder = "Select...",
  disabled,
  withSearch = false,
  query,
  edited,
  error,
  hideArrow = false,
  onSearch,
  isSelected = (value, selected) => selected === value,
  onSelect,
  renderBefore,
  renderValue = capitalizeFirstLetter,
  renderOption = renderValue,
}: React.PropsWithChildren<SimpleSelectProps<T>>) => {
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    onSearch?.(e.target.value, e)

  return (
    <Select.DropDown
      disabled={disabled}
      placement={placement}
      target={(isActive) => (
        <BaseField
          error={error}
          edited={edited}
          active={isActive}
          className={className}
          disabled={disabled}
          after={
            !hideArrow && (
              <IconButton>
                <Icon name="arrow-down" rotateAngle={isActive ? 180 : 0} />
              </IconButton>
            )
          }
        >
          {withSearch && isActive ? (
            <input
              autoFocus
              value={query}
              placeholder={isActive ? "Search..." : placeholder}
              onChange={handleSearch}
            />
          ) : value ? (
            <Typography type="inherit">{renderValue(value)}</Typography>
          ) : (
            <Typography color="black70Color" type="body1">
              {placeholder}
            </Typography>
          )}
        </BaseField>
      )}
    >
      <Select.Box>
        <Select.ItemsList>
          {options.length > 0 ? (
            <Select.Group>
              {options.map((it) => {
                const selected = isSelected(it, value)
                return (
                  <SimpleOption
                    isSelected={selected}
                    onClick={(e) => onSelect?.(it, e)}
                    before={renderBefore?.(it)}
                    key={it}
                  >
                    {renderOption(it)}
                  </SimpleOption>
                )
              })}
            </Select.Group>
          ) : (
            <NoData>List is empty</NoData>
          )}
        </Select.ItemsList>
      </Select.Box>
    </Select.DropDown>
  )
}

export default SimpleSelect
