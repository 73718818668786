import { action, observable } from "mobx"

class ProductCampaignStore {
  // INJECTIONS

  // CONSTRUCTOR

  // STATE

  @observable data: string[] | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  // ACTIONS

  @action storeData = (data: string[]) => {
    this.data = data
  }
}

export default ProductCampaignStore
