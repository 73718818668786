/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react"

import _throttle from "lodash/throttle"
import ToggleButton from "@components/ui/CheckBox/ToggleButton/ToggleButton"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"

import styles from "./RuleStatus.module.scss"

export interface RulesStatusProps {
  checked: boolean
  ruleId: number
}

export const RuleStatusSwitch: React.FC<RulesStatusProps> = ({
  checked,
  ruleId,
}) => {
  const [isChecked, setChecked] = useState<boolean>(checked ?? false)

  const { rulesStore, accountStore } = useStore()
  const { updateRuleActivity } = rulesStore
  const { accountId } = accountStore

  const handleToggle = useCallback(
    _throttle((newValue: boolean) => {
      if (accountId)
        updateRuleActivity(accountId, ruleId, newValue).then((success) =>
          setChecked((currentValue) => (success ? newValue : currentValue))
        )
    }, 1000),
    [ruleId, accountId]
  )

  const handleClick = useCallback(() => {
    handleToggle(!isChecked)
  }, [isChecked, handleToggle])

  useEffect(() => {
    setChecked(checked)
  }, [checked])

  return (
    <label className={styles.root}>
      <ToggleButton checked={isChecked} onChange={handleClick} />
      <Typography color={isChecked ? "primary" : "gray"}>
        {isChecked ? "Active" : "Inactive"}
      </Typography>
    </label>
  )
}
