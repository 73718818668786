import React from "react"
import { observer } from "mobx-react-lite"

import { BaseCellProps } from "@components/ui/WaterfallTable/types"
import useToggle from "@framework/hooks/useToggle"
import UpdateBiddingStatusModal, {
  RecommendedBudgetOption,
} from "@components/modals/UpdateBiddingStatusModal/UpdateBiddingStatusModal"
import {
  CampaignBiddingStatus,
  CampaignBiddingStatusReason,
  CampaignReportType,
} from "@framework/types/account"
import { statusToLabel } from "@utils/stringUtils"
import OnHoverTooltip from "@components/ui/Tooltip/TooltipContainer/TooltipContainer"
import ComicBubble from "@components/ui/Tooltip/ComicBubble/ComicBubble"
import TableButton from "./TableButton"

export interface BidStrategyProps extends BaseCellProps {
  data: CampaignReportType
}

export const BiddingStatus: React.FC<BidStrategyProps> = observer(
  ({ data }) => {
    const modal = useToggle()

    const handleClick = (e: any) => {
      modal.handleToggle()
      e.stopPropagation()
    }

    const status = data.PrimaryStatus
    const reason = data.PrimaryStatusReason

    const options = React.useMemo(() => {
      const res: RecommendedBudgetOption[] = []

      if (data.budgetOptions == null) return res

      const { current, options } = data.budgetOptions

      if (current == null) return res

      if (options != null)
        options.forEach((it, idx) => {
          res.push({
            name: `other_${idx}`,
            label: it.isRecommended ? "Recommended" : undefined,
            budget: it.budget,
            conversions: it.conversions - current.conversions,
            impressions: it.impressions - current.impressions,
            clicks: it.clicks - current.clicks,
            cost: it.cost - current.cost,
          })
        })

      res.push({
        name: "current",
        label: "Current",
        budget: current.budget,
        conversions: 0,
        impressions: 0,
        clicks: 0,
        cost: 0,
      })

      return res
    }, [data])

    const renderBiddingStatus = (
      status?: CampaignBiddingStatus | null,
      reason?: CampaignBiddingStatusReason | null
    ) => {
      if (!status) return ""
      const statusLabel = statusToLabel(status)

      if (!reason || reason === "UNKNOWN") return statusLabel

      const reasonLabelNode =
        (reason === "BUDGET_CONSTRAINED" || reason === "LIMITED_BY_BUDGET") &&
        options.length > 1 ? (
          <TableButton color="default" onClick={handleClick}>
            {renderReasonAsStatus(reason)}
          </TableButton>
        ) : (
          renderReasonAsStatus(reason)
        )

      const reasonMessage = getReasonMessage(reason)

      return (
        <OnHoverTooltip
          toolTip={
            reasonMessage
              ? (props) => (
                  <ComicBubble justify="center" {...props}>
                    {reasonMessage}
                  </ComicBubble>
                )
              : undefined
          }
        >
          {reasonLabelNode}
        </OnHoverTooltip>
      )
    }

    return (
      <>
        {renderBiddingStatus(status, reason)}

        {modal.isOpened && (
          <UpdateBiddingStatusModal
            isOpen
            onClose={modal.handleToggle}
            campaignId={data.Id}
            options={options}
          />
        )}
      </>
    )
  }
)

export default BiddingStatus

const renderReasonAsStatus = (reason: string) => {
  if (reason === "BUDGET_CONSTRAINED") return "Limited by budget"

  if (reason === "LIMITED_BY_BUDGET") return "Limited by budget"

  if (reason === "BUDGET_MISCONFIGURED") return "Misconfigured (budget)"

  if (reason === "BIDDING_STRATEGY_MISCONFIGURED")
    return "Misconfigured (bid strategy)"

  if (reason === "BIDDING_STRATEGY_LIMITED")
    return "Limited by bidding strategy"

  if (reason === "BIDDING_STRATEGY_LEARNING") return "Learning"

  if (reason === "BIDDING_STRATEGY_CONSTRAINED")
    return "Limited by bidding strategy"

  return statusToLabel(reason)
}

const getReasonMessage = (reason: CampaignBiddingStatusReason) => {
  if (reason === "LIMITED_BY_AVAILABLE_SEARCH_VOLUME")
    return (
      <>
        Your bid strategy is limited by available search volume and your ads are
        only eligible to show on a limited number of searches
        <br />
        <strong>Recommendation:</strong> expand your targeting by adding
        relevant keywords, Dynamic Search Ads, broad match keywords, or other
        forms of targeting
      </>
    )
  if (reason === "LIMITED_BY_BUDGET")
    return (
      <>
        Your average daily budget is lower than the recommended amount
        <br />
        <strong>Recommendation:</strong> increase the budgets associated with
        this strategy
      </>
    )
  if (reason === "BID_LIMIT")
    return (
      <>
        Maximum and/or minimum bid limits are preventing your bids from being
        fully optimized
        <br />
        <strong>Recommendation:</strong> increase the maximum bid limit and/or
        decrease the minimum bid limit
      </>
    )
  return null
}
