import {
  CampaignBidStrategyType,
  CampaignEntityName,
  CampaignLocationType,
  PMaxDayOfWeekOption,
  PMaxMinuteOption,
  RuleEntityName,
} from "@framework/types/manageCampaign"
import { Option, LabelMapper, ID } from "@framework/types/types"
import { initArray } from "@utils/numberUtils"
import groupBy from "lodash/groupBy"
import memoize from "lodash/memoize"

export const makeManageCampaignOption = (
  name: CampaignEntityName
): LabelMapper => {
  switch (name) {
    case "campaign":
      return {
        name: "campaign",
        label: "Campaign",
        shortName: "C",
        color: "#17899e",
      }
    case "groupAd":
      return {
        name: "groupAd",
        label: "Ad Group",
        shortName: "AG",
        color: "#92cbd6",
      }
    case "assetGroup":
      return {
        name: "assetGroup",
        label: "Asset Group",
        shortName: "AG",
        color: "#92cbd6",
      }
    case "keywords":
      return {
        name: "keywords",
        label: "Keywords",
        shortName: "K",
        color: "#93d692",
      }
    case "ad":
      return {
        name: "ad",
        label: "Ad",
        shortName: "A",
        color: "#5cbd9f",
      }
    default:
      return {
        name,
        shortName: "U",
        label: "Unknown",
        color: "#000",
      }
  }
}

export const campaignEntityDescription: Record<
  CampaignEntityName,
  LabelMapper
> = {
  campaign: makeManageCampaignOption("campaign"),
  groupAd: makeManageCampaignOption("groupAd"),
  assetGroup: makeManageCampaignOption("assetGroup"),
  keywords: makeManageCampaignOption("keywords"),
  ad: makeManageCampaignOption("ad"),
}

export const getManageCampaignOption = memoize(makeManageCampaignOption)

export const mergeLabelMappersByColor = (items: LabelMapper[]) =>
  Object.values(groupBy(items, (it) => it.color)).map((arr) => ({
    ...arr[0],
    label: arr.map((it) => it.label).join(" / "),
  }))

export const ruleEntityDescription: Record<RuleEntityName, LabelMapper> = {
  ...campaignEntityDescription,
  ruleCondition: {
    name: "ruleCondition",
    label: "Rule Condition",
    shortName: "R",
    color: "#004450",
  },
}

export const frequencyOptions: Option[] = [
  { label: "Day", value: "DAILY" },
  { label: "Month", value: "MONTHLY" },
  { label: "Week", value: "WEEKLY" },
]

export const defaultBillingOptions: CampaignBidStrategyType[] = [
  "MAXIMIZE_CONVERSION_VALUE",
  "MAXIMIZE_CONVERSIONS",
  "TARGET_SPEND",
  "TARGET_IMPRESSION_SHARE",
]

export const pMaxBillingOptions: CampaignBidStrategyType[] = [
  "MAXIMIZE_CONVERSION_VALUE",
  "MAXIMIZE_CONVERSIONS",
]

const billingStrategyMap = {
  MAXIMIZE_CONVERSION_VALUE: {
    label: "Conversion value",
    message:
      "This campaign will use the Maximize conversion value bid strategy to help you get the most conversion value for your budget",
  },
  MAXIMIZE_CONVERSIONS: {
    label: "Conversions",
    message:
      "This campaign will use the Maximize conversions bid strategy to help you get the most conversions for your budget and target CPA",
  },
  TARGET_SPEND: {
    label: "Clicks",
    message:
      "This campaign will use the Maximize clicks bid strategy to bring you the most clicks possible within your budget",
  },
  TARGET_IMPRESSION_SHARE: {
    label: "Impression Share",
    message:
      "This campaign will use the Maximize impression share bid strategy that sets bids with the goal of showing your ad on the absolute top of the page, on the top of the page, or anywhere on the page of Google search results",
  },
  OTHER: {
    label: "Select",
    message: "Choose a strategy that suits you",
  },
}

export const getBillingStrategy = (value?: string) =>
  billingStrategyMap[value as keyof typeof billingStrategyMap] ??
  billingStrategyMap.OTHER

const campaignLocationMap: Record<CampaignLocationType, string> = {
  UNKNOWN: "Unknown",
  UNSPECIFIED: "Unspecified",
  ANYWHERE_ON_PAGE: "Anywhere on results page",
  TOP_OF_PAGE: "Top of results page",
  ABSOLUTE_TOP_OF_PAGE: "Absolute top of results page",
}

export const getCampaignLocationLabel = (value?: string) =>
  campaignLocationMap[value as keyof typeof campaignLocationMap] ??
  campaignLocationMap.UNKNOWN

export const hoursOptions = initArray(24, (idx) => idx.toString())

export const dayOfWeekOptions: PMaxDayOfWeekOption[] = [
  "ALL",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
]

export const dayOptions: Record<PMaxDayOfWeekOption, string> = {
  ALL: "All days",
  MONDAY: "Mondays",
  TUESDAY: "Tuesdays",
  WEDNESDAY: "Wednesdays",
  THURSDAY: "Thursdays",
  FRIDAY: "Fridays",
  SATURDAY: "Saturdays",
  SUNDAY: "Sundays",
}

export const minutesOptionsLabels: Record<PMaxMinuteOption, string> = {
  ZERO: "00",
  FIFTEEN: "15",
  THIRTY: "30",
  FORTY_FIVE: "45",
}

export const minutesOptions: PMaxMinuteOption[] = [
  "ZERO",
  "FIFTEEN",
  "THIRTY",
  "FORTY_FIVE",
]

export const allCampaignViewOptions = [
  "Campaigns",
  "Keywords",
  "Negative Keywords",
  "Ad Copy",
] as const

export type CampaignViewType = (typeof allCampaignViewOptions)[number]

export const allCampaignTypeOptions = [
  "SEARCH",
  "PERFORMANCE_MAX",
  "VIDEO",
  "DISPLAY",
  "DEMAND_GEN",
  "SHOPPING",
] as const

export type CampaignType = (typeof allCampaignTypeOptions)[number]

export const renderHours = (idx: string | number) => `0${idx}`.slice(-2)

export const renderDay = (name: PMaxDayOfWeekOption) =>
  dayOptions[name] ?? dayOptions.ALL

export const renderMinutes = (name: keyof typeof minutesOptionsLabels) =>
  minutesOptionsLabels[name] ?? minutesOptionsLabels.ZERO

export const renderSchedulesEventRow = (schedulesEvent: any) => {
  const { dayOfWeek, startHour, startMinute, endHour, endMinute } =
    schedulesEvent
  return `${renderDay(dayOfWeek)} ${renderHours(startHour)}:${renderMinutes(
    startMinute
  )} to ${renderHours(endHour)}:${renderMinutes(endMinute)}`
}

export const GOOGLE_ADS_ASSETS_URL = "https://ads.google.com/aw/assetgroup"

export const makeAssetEditingLink = (campaignId: ID, assetGroupId: ID) =>
  `${GOOGLE_ADS_ASSETS_URL}/edit?campaignId=${campaignId}&assetgroupId=${assetGroupId}`

export const makeAssetCreatingLink = (campaignId: ID) =>
  `${GOOGLE_ADS_ASSETS_URL}/new?campaignId=${campaignId}`
