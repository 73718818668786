/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { AdGroupReportType } from "@framework/types/account"
import { useStore } from "@store/index"
import { SortByType } from "@framework/types/types"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"
import { MapperType } from "@components/ui/WaterfallTable/types"

type AssetGroupRowProps = {
  id: number
  data: AdGroupReportType
  adGroupId: number
  editable?: boolean
  sortBy?: SortByType
  mapper: MapperType[]
}

const AssetGroupRow: React.FC<AssetGroupRowProps> = observer(
  ({ id, data, adGroupId, mapper, editable, sortBy }) => {
    // const [opened, setOpened] = useState(false)

    const { accountStore } = useStore()
    const { accountId } = accountStore

    if (accountId == null) throw new Error("Account required")

    return (
      <ClosableRow
        id={id}
        // onToggle={setOpened}
        component={
          <TypedRow
            label={data.Name}
            data={{ ...data, AdgroupId: data.Id, type: "adGroup", editable }}
            leftTip={<TypedTip data={campaignEntityDescription.assetGroup} />}
            mapper={mapper}
          />
        }
      />
    )
  }
)

export default AssetGroupRow
