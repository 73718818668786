import React from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Typography from "@components/ui/Typography/Typography"
import { numberWithCommas } from "@components/utils/numberUtils"

import styles from "./Pair.module.scss"

export interface PairProps {
  id?: number
  accountId?: number | null
  label: string
  className?: string
  value: number
  strong?: boolean
  onClose?: (accountId: number, keywordId: number) => void
  renderValue?: (value: number | string) => string
}

export const Pair: React.FC<PairProps> = ({
  id = 0,
  label,
  value,
  strong = false,
  className,
  onClose,
  renderValue,
  accountId,
}) => (
  <div className={clsx(styles.pair, { [styles.closable]: onClose }, className)}>
    {onClose ? (
      <Icon
        name="cross"
        className={styles.closeButton}
        onClick={() => accountId && onClose(accountId, id)}
      />
    ) : null}
    <span className={styles.column}>
      <Tooltip text={label} lineClamp={2} type="h4">
        <Typography type="h4" color={strong ? "dark" : "gray"} bold={strong}>
          {label}
        </Typography>
      </Tooltip>
    </span>
    <span className={styles.column}>
      <Typography type="h4" bold={strong}>
        {renderValue ? renderValue(value) : numberWithCommas(value)}
      </Typography>
    </span>
  </div>
)

export default Pair
