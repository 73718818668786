import { getPlatformOption } from "@framework/constants/report"
import { MetricNameType, TSPerfReportMap } from "@framework/types/metrics"
import { DataSetType as LineChartDataSetType } from "@components/ui/Charts/LineChart/LineChart"

export const extractPlatformReportDataset = (
  platform: string,
  metric: MetricNameType,
  data: number[],
  suffix: string = ""
): LineChartDataSetType => {
  const plOption = getPlatformOption(platform)
  return {
    key: metric,
    label: `${plOption.label} ${suffix}`,
    backgroundColor: plOption.color,
    borderColor: plOption.color,
    yAxisID: "y",
    data,
  }
}

export const extractTSPRData = (
  performances: TSPerfReportMap,
  metric: MetricNameType,
  keys: number[]
): number[] =>
  keys.map((key) => performances[key]?.Metrics?.[metric]?.Value ?? 0)

export default null
