import React from "react"

import clamp from "lodash/clamp"
import { getTrendGradientColor, gradient } from "../utils"

import styles from "./TempScale.module.sass"

interface TempScaleProps {
  // from -1 to 1
  value?: number
}

export const TempScale: React.FC<TempScaleProps> = React.memo(
  ({ value = 0 }) => {
    const normalValue = clamp(value, -1, 1)

    return (
      <div
        className={styles.root}
        title={`Different from average ${(value * 100).toFixed(2)}%`}
      >
        <div className={styles.scale}>
          {gradient.map((color) => (
            <section style={{ background: color }} key={color} />
          ))}
        </div>

        <span
          className={styles.knob}
          style={{
            top: `${10 + ((-normalValue + 1) / 2) * 80}%`,
            background: getTrendGradientColor(normalValue),
          }}
        />
      </div>
    )
  }
)

export default TempScale
