import { action, computed, observable } from "mobx"
import { observer, useLocalStore } from "mobx-react-lite"
import React, { useContext } from "react"
import { RelatedQuestionSetName } from "@framework/types/opportunities"
import KeywordAnswerFormModal from "./KeywordAnswerFormModal"
import KeywordManagementSidebar from "./KeywordManagementSidebar"

export class KeywordManagementContextStore {
  @observable isAnswerEditorShown = false

  @observable questionsDetailsSetName: RelatedQuestionSetName | null = null

  @computed get showQuestionsDetailsSetName() {
    return this.questionsDetailsSetName != null
  }

  @action showQuestionDetails = (value: RelatedQuestionSetName) => {
    this.questionsDetailsSetName = value
  }

  @action hideQuestionDetails = () => {
    this.questionsDetailsSetName = null
  }

  @action showAnswerEditor = (value: boolean = !this.isAnswerEditorShown) => {
    this.isAnswerEditorShown = value
  }
}

export const KeywordManagementContext =
  React.createContext<KeywordManagementContextStore | null>(null)

export const KeywordManagementContextProvider: React.FC = observer(
  ({ children }) => {
    const context = useLocalStore(() => new KeywordManagementContextStore())
    return (
      <KeywordManagementContext.Provider value={context}>
        {children}

        <KeywordAnswerFormModal
          title="Are mental health days legal"
          isOpen={context.isAnswerEditorShown}
          onClose={() => context.showAnswerEditor(false)}
        />

        <KeywordManagementSidebar
          isOpen={context.showQuestionsDetailsSetName}
          onClose={() => context.hideQuestionDetails()}
        />
      </KeywordManagementContext.Provider>
    )
  }
)

export const useKeywordManagementContext = () => {
  const context = useContext(KeywordManagementContext)
  if (context == null)
    throw new Error("Can't find KeywordManagementContext context")
  return context
}
