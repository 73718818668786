/* eslint-disable no-underscore-dangle */
import { action, observable } from "mobx"

import accountService from "@services/account.service"
import { FailedResult, SuccessResult } from "@store/manageProfile/types"

class AccountSegmentationStore {
  @observable isLoading: boolean = false

  @observable error: string | null = null

  @action getSegmentationConfigLink = async (
    accountId: number
  ): Promise<SuccessResult<string> | FailedResult> => {
    try {
      this.isLoading = true
      this.error = null

      const response = await accountService.generateSegmentationConfigURL(
        accountId
      )

      return { status: "SUCCESS", data: response.data.data.SheetUrl }
    } catch (error) {
      this.error =
        "Failed to generate configuration link due to unexpected error"

      return { status: "FAILED", message: this.error }
    } finally {
      this.isLoading = false
    }
  }

  @action syncSegmentationConfig = async (
    accountId: number
  ): Promise<SuccessResult<null> | FailedResult> => {
    try {
      this.isLoading = true
      this.error = null

      await accountService.syncSegmentationConfig(accountId)

      return { status: "SUCCESS", data: null }
    } catch (error) {
      this.error = "Failed to synchronize configuration due to unexpected error"

      return { status: "FAILED", message: this.error }
    } finally {
      this.isLoading = false
    }
  }
}

export default AccountSegmentationStore
