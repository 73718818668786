import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Stack from "@components/ui/Stack/Stack"
import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import ScrollShadow from "@components/ui/ScrollShadow/ScrollShadow"
import MainOptionsForm from "./MainOptionsForm"
import LinksForm from "./LinksForm"
import HeadlinesForm from "./HeadlinesForm"
import DescriptionsForm from "./DescriptionsForm"
import AdPreview from "./AdPreview"

import styles from "./EditAdCopyForm.module.scss"

export interface EditAdCopyMultiFormProps {}

export const EditAdCopyMultiForm: React.FC<EditAdCopyMultiFormProps> = observer(
  () => (
    <div className={clsx(styles.container, styles.sections)}>
      <section className={styles.section}>
        <MainOptionsForm />
      </section>

      <ScrollShadow className={styles.scrollContainer}>
        {(ref) => (
          <ScrollContainer
            ref={ref}
            type="secondary"
            className={styles.bodyContainer}
          >
            <Stack gutter="0">
              <section className={styles.section}>
                <HeadlinesForm />
              </section>
              <section className={styles.section}>
                <DescriptionsForm />
              </section>
              <section className={styles.section}>
                <LinksForm />
              </section>
              <section className={styles.section}>
                <AdPreview />
              </section>
            </Stack>
          </ScrollContainer>
        )}
      </ScrollShadow>
    </div>
  )
)

export default EditAdCopyMultiForm
