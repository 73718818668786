import { useMemo } from "react"
import { initArray } from "@components/utils/numberUtils"
import { makeAdCopyPreviews } from "@framework/prototypes/AdCopy/Preview/utils"
import { AdCopyPreview } from "@framework/prototypes/AdCopy/Preview/types"
import { EditAdCopyFormData } from "./utils"

export interface PropType {
  headlines: string[]
  descriptions: string[]
  website: string
}

export const useAdPreviewGenerator = (
  mode: "mobile" | "desktop",
  form: EditAdCopyFormData
): AdCopyPreview[] =>
  useMemo(() => {
    const { finalUrl, mobileUrl } = form

    const validHeadlines = initArray(
      15,
      (idx) => form[`headline${1 + idx}` as keyof EditAdCopyFormData] as string
    ).filter((it) => !!it)

    const validDescriptions = initArray(
      4,
      (idx) =>
        form[`description${1 + idx}` as keyof EditAdCopyFormData] as string
    ).filter((it) => !!it)

    const website = mode === "desktop" ? finalUrl : mobileUrl

    if (validHeadlines.length < 3 || validDescriptions.length < 3 || !website)
      return []

    return makeAdCopyPreviews(
      website,
      validHeadlines,
      validDescriptions,
      Math.min(validDescriptions.length, 6)
    )
  }, [form, mode])

export default useAdPreviewGenerator
