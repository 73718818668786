import { observable, action } from "mobx"
import { ID, ILoadable } from "@framework/types/types"
import accountService from "@services/account.service"
import { ProductTempChangeData } from "@framework/types/account"
import { transformTempChange } from "./utils"

export class ProductChange implements ILoadable<ProductTempChangeData | null> {
  // constructor() {
  //   autorun(() => console.log(toJS(this.data)))
  // }

  @observable data: ProductTempChangeData | null = null

  @observable isLoading: boolean = false

  @observable error = ""

  @observable initialized = false

  @action load = async (accountId: ID, productId: ID) => {
    if (this.isLoading) return
    if (!accountId || !productId)
      throw new Error(`Can't load changes as accountId or productId is missing`)

    try {
      this.isLoading = true

      const response = await accountService.getProductTemporaryChanges(
        accountId,
        productId
      )
      this.loadChangesSuggestions(accountId, productId)
      const changeData = response.data.data
      this.data = changeData ? transformTempChange(changeData) : null
      this.initialized = true
    } catch (e) {
      this.error = `Can't load change for product: ${productId}`
    } finally {
      this.isLoading = false
    }
  }

  @observable suggestions: any = {}

  @observable isSuggestionLoading = false

  @observable suggestionError = ""

  @action loadChangesSuggestions = async (
    accountId: string | number,
    productId: string | number
  ) => {
    this.isSuggestionLoading = true
    this.suggestionError = ""
    try {
      const response = await accountService.getProductChangeSuggestion(
        accountId,
        productId
      )

      this.suggestions = response.data.data
      return true
    } catch (error) {
      this.suggestionError = "Unknown error while loading change suggestions"
    } finally {
      this.isSuggestionLoading = false
    }
    return false
  }
}

export default ProductChange
