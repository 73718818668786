import React from "react"
import { observer } from "mobx-react-lite"

import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import Stack from "@components/ui/Stack/Stack"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import SelectedKeywordsList from "../SelectedKeywordsList"
import Header from "./Header"
import Footer from "./Footer"

import styles from "./index.module.scss"

export interface FloatingAmountSectionProps {}

export const FloatingAmountSection: React.FC<FloatingAmountSectionProps> =
  observer(() => {
    const { view } = useOpportunitiesStore()
    return (
      <SidebarContainer isOpen={view.showSidebar} onClose={view.toggleSidebar}>
        <Stack gutter="0" align="stretch" className={styles.root}>
          <Header />

          <div className={styles.body}>
            <SelectedKeywordsList />
          </div>

          <Footer />
        </Stack>
      </SidebarContainer>
    )
  })

export default FloatingAmountSection
