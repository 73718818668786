/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import styles from "./index.module.sass"

export type HeaderOffset = "none" | "small" | "medium" | "big"
export type HeaderSize = "auto" | "small" | "medium" | "big" | "huge"

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  left?: React.ReactNode
  right?: React.ReactNode
  offset?: HeaderOffset
  height?: HeaderSize
}

export const Header: React.FC<HeaderProps> = ({
  left,
  right,
  className,
  offset = "medium",
  height = "auto",
  onClick,
  ...rest
}) => {
  const clickable = onClick != null
  return (
    <section
      className={clsx(
        styles.root,
        styles[`offset-${offset}`],
        styles[`height-${height}`],
        { [styles.clickable]: clickable },
        className
      )}
      role={clickable ? "button" : undefined}
      tabIndex={clickable ? 0 : undefined}
      onClick={onClick}
      {...rest}
    >
      <div className={styles.left}>{left}</div>
      <div className={styles.right}>{right}</div>
    </section>
  )
}

export default Header
