import React from "react"
import clsx from "clsx"

import styles from "./Image.module.scss"

const DEFAULT_IMAGE = "/images/image-placeholder.svg"

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  topperSocket?: React.ReactNode
}

export const Image: React.FC<ImageProps> = ({
  className,
  topperSocket,
  src = DEFAULT_IMAGE,
  ...props
}) => (
  <img className={clsx(styles.root, className)} alt="" src={src} {...props} />
)

export default Image
