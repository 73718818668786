import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import merge from "lodash/merge"
import * as yup from "yup"
import { useAlert } from "react-alert"

import useToggle from "@framework/hooks/useToggle"
import FormTextField from "@framework/prototypes/FormTextField"
import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { Button } from "@components/ui/Button"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import TextField from "@components/ui/TextField/TextField"
import {
  emailValidator,
  firstNameValidator,
  lastNameValidator,
} from "@components/utils/validators"
import Label from "@components/ui/Label/Label"

import styles from "./ProfileSettings.module.scss"

const SUCCESS_MESSAGE = "Your profile data has been successfully updated"

export const validationSchema = yup.object({
  name: firstNameValidator.clone().trim(),
  surname: lastNameValidator.clone().trim(),
  // email: emailValidator.clone().trim().default("").required("Can not be empty")
})

type FormType = yup.InferType<typeof validationSchema>

const initialValues: FormType = validationSchema.getDefault()

export const ProfileSettingsPage: React.FC = observer(() => {
  const {
    userStore: { isLoading, data: user, update },
  } = useStore()
  const alert = useAlert()

  const { isOpened, handleToggle } = useToggle()

  const handleSubmit = async (form: FormType) => {
    const error = await update(form)
    if (error) {
      alert.error(
        <AlertMessage
          title="Failed to update your profile settings"
          description={error}
        />
      )
      return
    }
    alert.success(SUCCESS_MESSAGE)
    formik.resetForm()
  }

  const formik = useFormik<FormType>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    if (user)
      formik.resetForm({
        values: merge(initialValues, {
          name: user?.Name,
          surname: user?.Surname,
          // email: user?.Email,
        }),
      })
  }, [user])

  const valid = Object.keys(formik.touched).length && formik.isValid

  return (
    <FormikProvider value={formik}>
      <Stack gutter="32" align="stretch">
        <Stack gutter="24" align="stretch">
          <FormTextField label="First Name" name="name" />

          <FormTextField label="Last Name" name="surname" />

          <Label label="Email">
            <TextField value={user?.Email} name="email" disabled />
          </Label>
        </Stack>

        {/* <Stack gutter="16">
          <Typography type={"h3"} bold>
            Notification
          </Typography>
          <label htmlFor="notification" className={styles.label}>
            <CheckBox
              id="notification"
              checked={isOpened}
              onChange={handleToggle}
            />
            <Typography>I want to get Adoro’s news to email</Typography>
          </label>
        </Stack> */}

        <Stack direction="row" justify="flex-end">
          <Button disabled={isLoading || !valid} onClick={formik.submitForm}>
            Save changes
          </Button>
        </Stack>
      </Stack>
    </FormikProvider>
  )
})

export default ProfileSettingsPage
