/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { observer } from "mobx-react-lite"

import Stepper, { StepOptionsProps } from "@components/ui/Stepper/Stepper"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import Opportunities from "@pages/Opportunities/Opportunities"
import IntroSubForm from "./IntroSubFormV2"
import KeywordsGenerationSubForm from "./KeywordsGenerationSubForm"
import KeywordScrappingSubForm from "./KeywordScrappingSubFormV2"
import KeywordsClusteringSubForm from "./KeywordsClusteringSubForm"
import CustomizingAttributesSubForm from "./CustomizingAttributesSubForm"
import { useDemoMultiformContext } from "./tasks/FormManager"

import styles from "./DemoMultiForm.module.scss"

export type StepOption = StepOptionsProps & {
  Component: React.FC
  name: string
}

const steps: StepOption[] = [
  {
    value: "1",
    name: "intro",
    label: "Intro",
    Component: IntroSubForm,
  },
  {
    value: "2",
    name: "keyword-scrapping",
    label: "Keyword scrapping",
    Component: KeywordScrappingSubForm,
  },
  {
    value: "3",
    name: "keywords-clustering",
    label: "Keyword Clustering",
    Component: KeywordsClusteringSubForm,
  },
  {
    value: "4",
    name: "customizing-attributes",
    label: "Customizing Attributes",
    Component: CustomizingAttributesSubForm,
  },
  {
    value: "5",
    name: "keywords-generation",
    label: "Keyword Generation",
    Component: KeywordsGenerationSubForm,
  },
  {
    value: "6",
    name: "publish-campaign",
    label: "Campaign Opportunities",
    Component: Opportunities,
  },
]

const DemoMultiForm: React.FC = observer(() => {
  const manager = useDemoMultiformContext()

  const { Component } = steps[manager.activeStep]

  const completed = [
    manager.isIntroValid,
    manager.isKeywordsScrappingValid,
    manager.isKeywordsClusteringValid,
    manager.isAttributesValid,
    manager.isKeywordsGenerationValid,
    manager.isCampaignPublished,
  ]

  const firstIncomplete = completed.findIndex((it) => !it)

  return (
    <div className={styles.root}>
      <ServiceDownInterceptor />

      {/* header */}
      <div className={styles.header}>
        <Stepper
          disableAfterIndex={
            firstIncomplete < 0 ? completed.length - 1 : firstIncomplete
          }
          activeStep={manager.activeStep}
          complete={completed}
          steps={steps}
          className={styles.stepper}
          onClick={(_, idx) => manager.setActiveStep(idx)}
        />
      </div>

      <div className={styles.body}>{Component && <Component />}</div>
    </div>
  )
})

export default DemoMultiForm
