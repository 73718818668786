import { observable, action, reaction, computed } from "mobx"
import { Client } from "@framework/types/client"
import clientService from "@services/client.service"
import RootStore from "../RootStore"

export class ClientStore {
  @observable clientId: number | null = null

  @observable client: Client | null = null

  @observable rootStore: RootStore

  @observable agencyClients: any[] = []

  @observable clientList: Client[] | null = null

  @observable checkedClient: number | null = null

  @observable loading: boolean = false

  @observable isClientsLoading: boolean = false

  @observable clientsListError: string | null = null

  constructor(root: RootStore) {
    this.rootStore = root

    reaction(
      () => this.rootStore.accountStore.account,
      async (account) => {
        if (account && account.clientId !== this.client?.id)
          await this.loadClient(account.clientId)
      }
    )
  }

  @action setClientId = (clientId: number | null) => {
    this.clientId = clientId
  }

  @action loadAgencyClientList = async (agencyId: number) => {
    this.loading = true
    try {
      const response = await clientService.fetchAgencyClients(agencyId)
      this.agencyClients = response.data.data
      this.rootStore.agencyStore.setCheckedAgencyId(agencyId)
    } catch (e) {
      console.log(e)
    }
    this.loading = false
  }

  @action loadClientList = async () => {
    this.isClientsLoading = true
    this.clientsListError = null
    this.clientList = null
    try {
      const response = await clientService.fetchClients()
      this.clientList = response.data.data
    } catch (e) {
      this.clientsListError = "Clients list loading failed"
    } finally {
      this.isClientsLoading = false
    }
  }

  @action loadClient = async (clientId?: number) => {
    this.loading = true
    try {
      const id = clientId || this.clientId
      if (!id) return
      const response = await clientService.fetchClientById(id)

      if (response.status < 300) {
        this.client = response.data.data
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

  @action setCheckedClient(id: number) {
    this.checkedClient = id
  }

  @computed get filteredClients() {
    const { showEverything, allowedEmail } = this.rootStore.appStore
    return (
      this.clientList?.filter(
        ({ contactEmail }) => showEverything || allowedEmail(contactEmail)
      ) ?? []
    )
  }
}

export default ClientStore
