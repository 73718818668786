/* eslint-disable no-unused-expressions */
import { observable, action, computed } from "mobx"
import { ID } from "@framework/types/types"
import { SelectionMode } from "@services/common"

export class InfiniteSelected {
  constructor({
    selected = [],
    mode = "include",
    total = 0,
  }: {
    selected?: string[]
    mode?: SelectionMode
    total?: number
  } = {}) {
    this.mode = mode
    this.total = total
    this.data = new Set(selected)
  }

  @observable mode: SelectionMode = "include"

  @observable data: Set<string>

  @observable total: number = 0

  @computed get selectedCount() {
    return this.mode === "include"
      ? this.data.size
      : this.total - this.data.size
  }

  @computed get isSelected() {
    const { mode, data } = this
    const isIncluding = mode === "include"
    return (productId: ID) =>
      data.has(productId.toString()) ? isIncluding : !isIncluding
  }

  @computed get isAnySelected() {
    const { mode, data: selectedProducts } = this
    return mode === "include"
      ? selectedProducts.size > 0
      : selectedProducts.size < this.total
  }

  @computed get isAllSelected() {
    const { mode, data: selectedProducts } = this
    return mode === "include"
      ? selectedProducts.size === this.total
      : selectedProducts.size === 0
  }

  @action selectAll = async (value: boolean = !this.isAnySelected) => {
    const newMode = value ? "exclude" : "include"
    this.mode = newMode
    this.data = new Set()
  }

  @action select = (productId: string) => {
    if (this.data.has(productId.toString())) this.delete(productId)
    else this.add(productId.toString())
  }

  @action delete = (...productId: string[]) => {
    for (let i = 0; i < productId.length; i += 1)
      this.data.delete(productId[i].toString())
  }

  @action add = (...productId: string[]) => {
    for (let i = 0; i < productId.length; i += 1)
      this.data.add(productId[i].toString())
  }

  @action setTotal = (total: number) => {
    this.total = total
  }
}

export default InfiniteSelected
