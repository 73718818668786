import React from "react"

interface DiscoverIconProps {
  width?: number
  height?: number
}

const DiscoverIcon = ({ width, height }: DiscoverIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 33}
    height={height || 32}
    fill="none"
  >
    <path
      fill="#FBBC01"
      d="M3.131 5.603 9.427 11.9H.5v4.193h8.927l3.04-3.019V8.859L6.171 2.563l-3.04 3.04Z"
    />
    <path
      fill="#EB4435"
      d="m12.414 13.052 7.237-1.145 6.295-6.296-3.04-3.04-6.296 6.296V0h-4.193l-.003 13.052Z"
    />
    <path
      fill="#38A752"
      d="m3.086 22.385 3.04 3.042 6.296-6.299V28h4.193v-8.872l-4.2-6.076-9.33 9.333Z"
    />
    <path
      fill="#4886F4"
      d="m12.392 14.92 10.492 10.488 3.042-3.04-6.298-6.296h8.87V11.88H13.849c-1.971.375-1.94 1.896-1.457 3.04Z"
    />
  </svg>
)
export default DiscoverIcon
