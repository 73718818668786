/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"

import { Option } from "@framework/types/types"
import Icon from "@components/ui/Icon/Icon"
import Stack from "@components/ui/Stack/Stack"
import IconButton from "@components/ui/Button/IconButton"

type RowControlProps = {
  options: Option[]
  disabled?: boolean
  onClick?: (name: string) => void
}

const RowControl: React.FC<RowControlProps> = ({
  options,
  disabled,
  onClick,
}) => (
  <Stack gutter="0" direction="row">
    {options.map((option) => {
      const { value, label, icon } = option
      return (
        <IconButton
          disabled={disabled}
          onClick={() => onClick && onClick(value)}
          title={label}
        >
          {icon != null && <Icon name={icon} primary />}
        </IconButton>
      )
    })}
  </Stack>
)

export default RowControl
