import * as yup from "yup"

import { PMaxCampaignEntity } from "@framework/types/account"

export const CANADA_INDEX = 2124
export const USA_INDEX = 2840

const geoTargetTypes = ["PRESENCE", "PRESENCE_OR_INTEREST"]

const parseLocationTarget = (
  locations: number[] | undefined,
  excludeLocationIds: number[] | undefined
) => {
  if (excludeLocationIds?.length) return "ANOTHER"
  if (!locations?.length) return "ALL"
  if (locations.includes(USA_INDEX)) {
    if (locations.length === 1) return "USA"
    if (locations.length === 2 && locations.includes(CANADA_INDEX))
      return "USA_CANADA"
  }
  return "ANOTHER"
}

const locationsValidationSchema = yup.object({
  locationTarget: yup.string(),
  positiveGeoTargetType: yup
    .string()
    .transform((it) => (geoTargetTypes.includes(it) ? it : undefined))
    .default("PRESENCE"),
  locationIds: yup
    .array(yup.number().required())
    .transform((it) => (it === null ? undefined : it))
    .default([]),
  excludeLocationIds: yup
    .array(yup.number().required())
    .transform((it) => (it === null ? undefined : it))
    .default([]),
})

export type LocationsValidationSchema = yup.InferType<
  typeof locationsValidationSchema
>

const languagesValidationSchema = yup.object({
  languageIds: yup
    .array(yup.number().required())
    .label("Languages")
    .min(1)
    .transform((it) => (it === null ? undefined : it))
    .default([]),
})

export const scheduleDaySchema = yup.object({
  dayOfWeek: yup.string().required().default("ALL"),
  startHour: yup.number().required().default(0),
  startMinute: yup.string().required().default("ZERO"),
  endHour: yup.number().required().default(23),
  endMinute: yup.string().required().default("FORTY_FIVE"),
})

const scheduleValidationSchema = yup.object({
  adSchedules: yup
    .array(scheduleDaySchema.required())
    .transform((it) => (it === null ? undefined : it))
    .default([]),
})

export const campaignSettingsValidationSchema = yup
  .object()
  .concat(locationsValidationSchema)
  .concat(languagesValidationSchema)
  .concat(scheduleValidationSchema)

export type CampaignSettingsValidationSchema = yup.InferType<
  typeof campaignSettingsValidationSchema
>

export const transformCampaignSettingsForm = (
  campaign: Partial<PMaxCampaignEntity>
) => ({
  ...transformLocationsData(campaign),
  ...languagesValidationSchema.cast(campaign, { stripUnknown: true }),
  ...scheduleValidationSchema.cast(campaign, { stripUnknown: true }),
})

export const transformLocationsData = (
  campaignData: Partial<PMaxCampaignEntity>
) => {
  const { locationIds, excludedLocationIds } = campaignData
  return {
    locationTarget: parseLocationTarget(locationIds, excludedLocationIds),
    locationIds,
    excludeLocationIds: excludedLocationIds,
  }
}
