import { action, observable } from "mobx"
import { ID } from "@framework/types/types"
import { PermissionEntity } from "@framework/types/user"
import userService from "@services/user.service"

export class UserPermissionsStore {
  @observable isLoading: boolean = false

  @observable error: string | null = null

  @action update = async (userId: ID, data: PermissionEntity) => {
    this.isLoading = true
    this.error = null
    try {
      await userService.updateUserPermission(userId, data)
    } catch (error: any) {
      this.error =
        error?.response?.data?.error ??
        "Unknown error while granting user permissions"
    } finally {
      this.isLoading = false
    }
    return this.error
  }
}

export default UserPermissionsStore
