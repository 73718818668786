import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"

import styles from "./HeaderColumn.module.scss"

export type HeaderColumnProps = {
  className?: string
}

export const HeaderColumn: React.FC<HeaderColumnProps> = ({
  className,
  children,
}) => (
  <div className={clsx(styles.root, className)}>
    <Typography type="h6" color="gray" upperCase>
      {children}
    </Typography>
  </div>
)

export default HeaderColumn
