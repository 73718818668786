import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import { useController, useStore } from "@store/index"
import NoData from "@components/ui/NoData/NoData"
import AdCopyHeader from "./AdCopyHeader"
import AdCopyRow from "./AdCopyRow"

import styles from "./index.module.scss"

export const AdTableBody: React.FC = observer(() => {
  const {
    accountStore: { accountId },
    adCopyStore: { activeAdCopies, getCampaignByID },
  } = useStore()
  const { adCopyManagement } = useController()

  const selectable = activeAdCopies.selectedCopies

  const handleAction = (rowId: string) => (value: string) => {
    if (accountId == null) return
    if (value === "edit")
      adCopyManagement.createEditAdCopyTask(accountId, [rowId])
    if (value === "duplicate")
      adCopyManagement.createDuplicateAdCopyTask([rowId])
    if (value === "delete") adCopyManagement.createDeleteAdCopyTask([rowId])
    if (value === "restore") adCopyManagement.createRestoreAdCopyTask([rowId])
  }

  const campaigns = activeAdCopies.frameCopiesGroups

  return (
    <Stack gutter="8" align="stretch" className={styles.body}>
      {campaigns?.length ? (
        <>
          <AdCopyHeader
            total={activeAdCopies.total}
            selectedCount={selectable.selectedCount}
            selected={selectable.isAnySelected}
            onSelect={() => selectable.selectAll()}
          />

          {campaigns.map(([campId, copies]) => {
            const camp = getCampaignByID(campId)
            return (
              <>
                <Typography type="h5">
                  {camp?.name ?? "Unknown campaign"}
                </Typography>

                {copies.map((id) => (
                  <AdCopyRow
                    id={id}
                    checked={selectable.isSelected(id)}
                    onChange={() => selectable.select(id)}
                    onAction={handleAction(id)}
                    key={id}
                  />
                ))}
              </>
            )
          })}
        </>
      ) : (
        <NoData>Select an Ad Group(s) from campaigns on the left</NoData>
      )}
    </Stack>
  )
})

export default AdTableBody
