import React, { useContext } from "react"

import {
  useOpportunitiesController,
  useOpportunitiesStore,
} from "@store/opportunities/provider"
import { observer, useLocalStore } from "mobx-react-lite"
import KeywordInsightsStore from "@store/opportunities/keyword-insights.store"
import KeywordInsightsController from "@store/opportunities/keyword-insights.controller"
import RelatedTopicsStore from "@store/opportunities/related-topics.store"
import TopKeywordsStore from "@store/opportunities/top-keyword.store"
import { RecommendedKeyword } from "@framework/types/opportunities"
import RelatedQuestionsStore from "@store/opportunities/related-questions.store"

export interface KeywordInsightsContextStore {
  store: KeywordInsightsStore
  controller: KeywordInsightsController
}

export const KeywordInsightsContext =
  React.createContext<KeywordInsightsContextStore | null>(null)

export const KeywordInsightsContextProvider: React.FC<{
  keyword: RecommendedKeyword
}> = observer(({ keyword, children }) => {
  const controller = useOpportunitiesController()
  const opportunitiesStore = useOpportunitiesStore()

  const context = useLocalStore(() => {
    const store = new KeywordInsightsStore({
      keyword,
      opportunitiesStore,
      relatedTopicsStore: new RelatedTopicsStore(),
      topKeywordsStore: new TopKeywordsStore(),
      relatedQuestionsStore: new RelatedQuestionsStore(),
    })
    return {
      store,
      controller: new KeywordInsightsController({
        opportunitiesController: controller.opportunities,
        keywordsInsightsStore: store,
      }),
    }
  })

  return (
    <KeywordInsightsContext.Provider value={context}>
      {children}
    </KeywordInsightsContext.Provider>
  )
})

export const useKeywordInsightsContext = () => {
  const context = useContext(KeywordInsightsContext)
  if (context == null)
    throw new Error("Can't find KeywordInsightsContext context")
  return context
}
