import clsx from "clsx"
import React from "react"

import styles from "./StatusBadge.module.scss"

export type BadgeOption = {
  label: string
  color?:
    | "success"
    | "info"
    | "error"
    | "default"
    | "secondary"
    | "red"
    | "yellow"
    | "green"
}

const defaultStatus: BadgeOption = { label: "Unknown", color: "default" }
const defaultMapper = () => defaultStatus

export interface StatusBadgeProps {
  value?: string
  variant?: "simple" | "default"
  mapper?: (status: string) => BadgeOption
}

const StatusBadge: React.FC<StatusBadgeProps> = ({
  value: status,
  variant = "default",
  mapper = defaultMapper,
}) => {
  if (status == null) return <></>

  const statusMapper = mapper(status)

  return (
    <div
      className={clsx(
        styles.root,
        statusMapper.color ? styles[statusMapper.color] : "",
        styles[`variant-${variant}`]
      )}
    >
      {statusMapper.label}
    </div>
  )
}

export default StatusBadge
