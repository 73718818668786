import React from "react"
import StatusBadge, {
  BadgeOption,
} from "@pages/GoogleShoppingChannel/components/Status/StatusBadge"
import { CampaignStatusType, campaignStatusList } from "@store/reports/types"

export const useCampaignStatusOptionList = (
  options: CampaignStatusType[] = [...campaignStatusList]
) => ({
  options,
  renderValue: (name: string) => (
    <StatusBadge value={name} mapper={campaignStatusMapper} variant="simple" />
  ),
})

export const statusMapper: Record<CampaignStatusType, BadgeOption> = {
  ENABLED: { color: "success", label: "Active" },
  PAUSED: { color: "info", label: "Paused" },
  REMOVED: { color: "default", label: "Removed" },
  ANY: { color: "secondary", label: "All" },
}

export const campaignStatusMapper = (status: string) =>
  statusMapper[status as CampaignStatusType] ?? statusMapper.ANY

export default useCampaignStatusOptionList
