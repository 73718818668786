import React from "react"
import clsx from "clsx"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { randomNumber } from "@utils/numberUtils"
import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import Templates from "@components/ui/Templates"

import styles from "./TopQuestionsTable.module.scss"

export const TopQuestionsTable: React.FC<{ className?: string }> = ({
  className,
}) => (
  <Box className={clsx(styles.root, className)}>
    <Templates.Header
      offset="none"
      height="big"
      left={
        <Typography type="h3" weight="bold">
          Top 5 Questions
        </Typography>
      }
    />

    <Typography type="body1">
      <Table hideBorder hideHorizontalOffset fixedLayout spacing="small">
        <Row>
          {tableMapper.map((it) => (
            <Column variant="header" width={it.width} key={it.name}>
              <Typography type="caption2" color="black60Color">
                {it.label}
              </Typography>
            </Column>
          ))}
        </Row>

        {mockData.map((it) => (
          <Row key={it.keywords}>
            <Column>
              <Typography type="inherit" weight="semibold">
                {it.searchVolume}
              </Typography>
            </Column>
            <Column>
              <Typography type="inherit" weight="semibold">
                {it.cpc}
              </Typography>
            </Column>
            <Column>{it.keywords}</Column>
          </Row>
        ))}
      </Table>
    </Typography>
  </Box>
)

export default TopQuestionsTable

const tableMapper = [
  {
    name: "searchVolume",
    label: "Search Vol.",
    width: "100px",
  },
  {
    name: "cpc",
    label: "CPC",
    width: "60px",
  },
  {
    name: "keywords",
    label: "Keywords",
  },
]

const mockData = [
  {
    searchVolume: randomNumber(10, 1000),
    cpc: `$${randomNumber(100, 10000) / 100}`,
    keywords: "Are mental health days legal",
  },
  {
    searchVolume: randomNumber(10, 1000),
    cpc: `$${randomNumber(100, 10000) / 100}`,
    keywords: "Can mental health affect physical health",
  },
  {
    searchVolume: randomNumber(10, 1000),
    cpc: `$${randomNumber(100, 10000) / 100}`,
    keywords: "How mental health affects physical health",
  },
  {
    searchVolume: randomNumber(10, 1000),
    cpc: `$${randomNumber(100, 10000) / 100}`,
    keywords: "Where mental health came from",
  },
  {
    searchVolume: randomNumber(10, 1000),
    cpc: `$${randomNumber(100, 10000) / 100}`,
    keywords: "What mental health disorders qualify for disability",
  },
]
