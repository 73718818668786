import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { useController, useStore } from "@store/index"
import { AdCopyDetails, AdCopyPrompts } from "@framework/types/adCopy"
import { adCopyPreviewsToDetails } from "@framework/prototypes/AdCopy/Preview/utils"
import { FormMetaContextProvider } from "@framework/prototypes/FormMetaContext"
import useIsMounted from "@framework/hooks/useIsMounted"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import RegenerateAdCopyForm, {
  GenerateAdCopyFormData,
} from "./RegenerateAdCopyForm"

export type ReturnData = {
  prompts: AdCopyPrompts
  adCopyDetails: Partial<AdCopyDetails>
}

export interface RegenerateAdCopySidebarProps {
  isOpened?: boolean
  initialData?: Partial<AdCopyPrompts>
  onSubmit?: (data: ReturnData) => void
  onClose?: () => void
}

export const RegenerateAdCopySidebar: React.FC<RegenerateAdCopySidebarProps> =
  observer(({ initialData, onSubmit, onClose }) => {
    const alert = useAlert()
    const { adCopyManagement } = useController()
    const {
      accountStore: { accountId },
    } = useStore()

    const [isLoading, setLoading] = React.useState(false)
    const isMounted = useIsMounted()

    const handleSubmit = async (form: GenerateAdCopyFormData) => {
      if (!accountId || isLoading) return

      setLoading(true)

      const res = await adCopyManagement.generateAdCopyWithAI(
        accountId,
        "no url provided",
        form
      )

      // ignore results if unmounted
      if (!isMounted()) return

      if (res.status === "FAILED") {
        alert.error(
          <AlertMessage
            title="Failed to generate ad copy"
            description={res.message}
          />
        )
      }

      if (res.status === "SUCCESS") {
        const adCopyDetails = adCopyPreviewsToDetails(res.data)

        onSubmit?.({
          prompts: form,
          adCopyDetails,
        })
      }

      setLoading(false)
    }

    return (
      <FormMetaContextProvider editedFields={[]}>
        <RegenerateAdCopyForm
          loading={isLoading}
          initialValues={initialData}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      </FormMetaContextProvider>
    )
  })

export default RegenerateAdCopySidebar
