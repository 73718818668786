import React from "react"

import styles from "./TableCell.module.scss"

export const LinkCell: React.FC<any> = React.memo(({ name, data }) => {
  const value = data[name]
  if (!value) return null
  return (
    <a className={styles.link} href={value}>
      {value}
    </a>
  )
})

export default LinkCell
