import React from "react"
import { useField } from "formik"
import NumberFormat, {
  NumberFormatValues,
  NumberFormatProps,
} from "react-number-format"

import TextField, { TextFieldProps } from "@components/ui/TextField/TextField"
import Label, { LabelProps } from "@components/ui/Label/Label"

export interface FormFieldProps extends NumberFormatProps<TextFieldProps> {
  name: string
  label?: LabelProps["label"]
  floatingError?: LabelProps["floatingError"]
}

const FormNumberField: React.FC<FormFieldProps> = ({
  name,
  label,
  floatingError,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name)

  const error = meta.touched ? meta.error : undefined

  return (
    <Label label={label} error={error} floatingError={floatingError}>
      <NumberFormat
        name={name}
        customInput={TextField}
        value={field.value ?? ""}
        onValueChange={(values: NumberFormatValues) =>
          helpers.setValue(values.value === "" ? undefined : values.value)
        }
        valid={meta.value !== meta.initialValue && meta.error == null}
        error={!!error}
        onBlur={field.onBlur}
        {...rest}
      />
    </Label>
  )
}

export default FormNumberField
