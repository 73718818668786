import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"

import { useStore } from "@store/index"
import Tabs from "@components/ui/Tabs/Tabs"
import { numberWithDiff } from "@components/utils/stringUtils"
import BaseSidebarFilter from "@components/prototypes/BaseSidebarFilter/BaseSidebarFilter"
import SimpleFilter from "./SimpleFilter"
import WaterfallFilter from "./WaterfallFilter"

import styles from "./SidebarFilter.module.scss"

export interface SidebarFilterProps {
  show: boolean
  onClose: () => void
}

export const SidebarFilter: React.FC<SidebarFilterProps> = observer(
  ({ show, onClose }) => {
    const {
      accountStore: { accountId },
      productFeedStore: {
        isInited,
        isFeedAvailable,
        filter: {
          filters,
          filterTabs,
          activeFilterTab,
          isSync,
          resetTemp,
          resetAll,
          applyFilter,
          setActiveTab,
        },
      },
    } = useStore()

    const handleApply = () => {
      applyFilter()
      onClose()
    }

    useEffect(() => {
      if (accountId && isFeedAvailable && isInited) {
        filters.byCategories.loadBaseCategories(accountId)
      }
    }, [accountId && isFeedAvailable && isInited])

    const distribution: Record<string, string> = {
      byAttribute: numberWithDiff(
        filters.byAttributes.activeFiltersCount,
        filters.byAttributes.filtersCountDifference
      ),
      byCategory: numberWithDiff(
        filters.byCategories.activeFiltersCount,
        filters.byCategories.filtersCountDifference
      ),
    }

    const totalFiltersSelected =
      filters.byAttributes.activeFiltersCount +
      filters.byCategories.activeFiltersCount +
      filters.byAttributes.filtersCountDifference +
      filters.byCategories.filtersCountDifference

    return (
      <BaseSidebarFilter
        title={`Filters Selected • ${totalFiltersSelected}`}
        isOpened={show}
        onClose={onClose}
        onReset={resetAll}
        hasChanges={!isSync}
        onApply={handleApply}
        onCancel={resetTemp}
        switchSocket={
          <Tabs
            options={filterTabs}
            currentValue={activeFilterTab}
            onChange={setActiveTab}
            underlined
            fullWidth
          >
            {({ value, label }) => `${label} • ${distribution[value] ?? 0}`}
          </Tabs>
        }
      >
        {activeFilterTab.value === "byAttribute" ? (
          <SimpleFilter />
        ) : (
          <WaterfallFilter />
        )}
      </BaseSidebarFilter>
    )
  }
)

export default SidebarFilter
