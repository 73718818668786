import React from "react"
import { observer } from "mobx-react-lite"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import FormTextField from "@framework/prototypes/FormTextField"
import DatePicker from "@components/ui/DatePicker/DatePicker"
import Label from "@components/ui/Label/Label"
import InlineLabel from "@components/ui/CheckBox/Label/Label"
import Radio from "@components/ui/CheckBox/Radio/Radio"
import { CampaignInfoValidationSchema } from "@store/manage-campaigns/validation/budget"
import { useStore } from "@store/index"
import { useFormikContext } from "formik"
import { useActiveSection } from "@pages/PMax/ActiveSectionContext"

import styles from "./index.module.scss"

export const CampaignInfoSection: React.FC = observer(() => {
  const { editPMaxCampaignStore: store } = useStore()

  const formik = useFormikContext<CampaignInfoValidationSchema>()

  const minDate = new Date()

  const { props } = useActiveSection<HTMLDivElement>("info")

  return (
    <Box className={styles.section} {...props}>
      <Stack gutter="16" direction="column">
        <Typography type="h2">Campaign name</Typography>

        <FormTextField
          className={styles.field}
          label="Enter a campaign name"
          placeholder="Enter name"
          name="name"
        />

        <Label label="Start date" error={formik.errors.startDate}>
          <DatePicker
            name="startDate"
            minDate={minDate}
            defValue={formik.values.startDate}
            onChange={(v) => formik.setFieldValue("startDate", v)}
            className={styles.input}
            disabled={store.campaignId !== "new"}
          />
        </Label>
        <Label label="End date" error={formik.errors.endDate}>
          <InlineLabel text="None">
            <Radio
              id="false"
              checked={!formik.values.withEndDate}
              onChange={() => formik.setFieldValue("withEndDate", false, true)}
            />
          </InlineLabel>
          <InlineLabel
            text={
              <DatePicker
                disabled={!formik.values.withEndDate}
                name="endDate"
                defValue={formik.values.endDate}
                onChange={(v) => formik.setFieldValue("endDate", v)}
                minDate={minDate}
                className={styles.input}
              />
            }
          >
            <Radio
              id="true"
              checked={!!formik.values.withEndDate}
              onChange={() => formik.setFieldValue("withEndDate", true, false)}
            />
          </InlineLabel>
        </Label>
      </Stack>
    </Box>
  )
})

export default CampaignInfoSection
