/* eslint-disable jsx-a11y/label-has-associated-control */
import { observer } from "mobx-react-lite"
import React from "react"

import FilterList from "@components/prototypes/BaseSidebarFilter/FilterList"
import { useOpportunitiesStore } from "@store/opportunities/provider"

interface SimpleFilterProps {}

const CategoryFilter: React.FC<SimpleFilterProps> = observer(() => {
  const { keywordsFilter } = useOpportunitiesStore()
  const categoryFilter = keywordsFilter.group.byCategories

  return (
    <FilterList
      list={categoryFilter.options}
      isSelected={(item) => categoryFilter.isSelected(item.value)}
      onSelect={(item) => categoryFilter.toggleOption(item.value)}
    />
  )
})

export default CategoryFilter
