import clsx from "clsx"
import React from "react"

import { Option } from "@framework/types/types"
import Step from "./Step"

import styles from "./Stepper.module.scss"

export type StepOptionsProps = {} & Option

export type StatusType = "complete" | "active" | "skipped"

export type StepperProps = {
  orientation?: "vertical" | "horizontal"
  className?: string
  activeStep: number
  complete: Record<number, boolean> | boolean[]
  steps: StepOptionsProps[]
  disableAfterIndex?: number
  onClick?: (step: StepOptionsProps, index: number) => void
}

const Stepper: React.FC<StepperProps> = ({
  orientation = "horizontal",
  className,
  activeStep,
  steps,
  disableAfterIndex = steps.length,
  complete,
  onClick,
}) => {
  const getStatus = (index: number, activeStep: number) => {
    const status: StatusType[] = []
    if (complete[index]) status.push("complete")
    if (index === activeStep) status.push("active")
    if (index < activeStep) status.push("skipped")
    return status
  }

  return (
    <div className={clsx(styles.root, styles[`ori-${orientation}`], className)}>
      {steps.length > 1 &&
        steps.map((option, index: number) => {
          const status = getStatus(index, activeStep)
          return (
            <div className={styles.section} key={option.value}>
              {index > 0 && (
                <div
                  className={clsx(styles.line, {
                    [styles.active]: activeStep >= index,
                  })}
                />
              )}
              <Step
                disabled={index > disableAfterIndex}
                status={status}
                label={option.label}
                onClick={onClick?.bind(null, option, index)}
              />
            </div>
          )
        })}
    </div>
  )
}

export default React.memo(Stepper)
