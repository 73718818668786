import { TopKeywordEntity } from "@framework/types/opportunities"
import { observable } from "mobx"

export class TopKeywordsStore {
  @observable data: TopKeywordEntity[] = []

  @observable isLoading: boolean = false

  @observable error: string | null = null
}

export default TopKeywordsStore
