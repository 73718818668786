import React from "react"
import clsx from "clsx"
import Icon from "@components/ui/Icon/Icon"

import styles from "./Alert.module.scss"

type AlertProps = {
  className?: string
}

const Alert: React.FC<AlertProps> = ({ className, children }) => (
  <div className={clsx(styles.root, className)}>
    <span className={styles.label}>
      <Icon name="shield-check-fill" />
    </span>
    <div className={styles.content}>{children}</div>
  </div>
)

export default Alert
