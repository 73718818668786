import React, { FC, ReactElement } from "react"

interface SVGIconWrapperProps {
  children: ReactElement
}

const SVGIconWrapper: FC<SVGIconWrapperProps> = ({ children }) => (
  <>{children}</>
)

export default SVGIconWrapper
