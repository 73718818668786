import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import useQueryParams from "@framework/hooks/useQueryParams"
import MetricsGrid from "./components/MetricsGrid/MetricsGrid"
import SpendChart from "./components/SpendChart/SpendChart"
import ReportsControlHeader from "./components/ReportsControlHeader/ReportsControlHeader"
import MetricsTable from "./components/MetricsTable/MetricsTable"
import SidebarFilter from "./components/SidebarFilter/TableSettingsSidebar"

import styles from "./index.module.sass"

type ReportsPageProps = {
  className?: string
}

const ReportsPage: React.FC<ReportsPageProps> = observer(({ className }) => {
  const {
    accountStore,
    userStore: { userId },
    reports: { mainFilter, settings },
  } = useStore()

  const { account } = useQueryParams()

  useEffect(() => {
    if (!accountStore.accountList) return
    if (account == null) {
      mainFilter.resetAccounts(accountStore.accountList)
      return
    }
    const activeAccount = accountStore.accountList.find(
      (it) => it.id.toString() === account.toString()
    )
    if (activeAccount != null) {
      mainFilter.resetAccounts(accountStore.accountList, [
        activeAccount.id.toString(),
      ])
    }
  }, [account, accountStore.accountList])

  useEffect(() => {
    if (userId) settings.loadUserReportTableSettings(userId)
  }, [userId])

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.container}>
        <ReportsControlHeader />
        <MetricsGrid />
        <SpendChart />
        <MetricsTable />
      </div>

      <SidebarFilter />
    </div>
  )
})

export default ReportsPage
