import React from "react"
import { observer } from "mobx-react-lite"

import Preview from "@framework/prototypes/AdCopy/Preview"
import { useFormikContext } from "formik"
import useAdPreviewGenerator from "./useAdPreviewGenerator"
import { EditAdCopyFormData } from "./utils"

import styles from "./AdPreview.module.scss"

export interface EditAdCopyFormProps {}

export const AdPreview: React.FC<EditAdCopyFormProps> = observer(() => {
  const formik = useFormikContext<EditAdCopyFormData>()

  const modeControl = Preview.useModeControl()
  const list = useAdPreviewGenerator(modeControl.mode, formik.values)
  const headerControl = Preview.useCaruselControl(list.length)

  const adProps = list[headerControl.activeIdx]

  return (
    <div className={styles.root}>
      <Preview.Header title="Preview" {...headerControl} {...modeControl} />
      <div className={styles.body}>
        {modeControl.mode === "mobile" ? (
          <Preview.MobileFrame>
            <Preview.AdCard data={adProps} variant="mobile" />
          </Preview.MobileFrame>
        ) : (
          <Preview.AdCard data={adProps} variant="desktop" />
        )}
      </div>
    </div>
  )
})

export default AdPreview
