import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { AdGroupEntity } from "@framework/types/account"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"
import AdGroupRow from "./AdGroupRow"
import { SelectedAdGroupsMap } from "./CustomCampaignsTable"

import styles from "./CustomCampaignsTable.module.scss"

export type CampaignRowProps = {
  id: string
  label: string
  index: number
  adGroups: AdGroupEntity[]
  selectedAdGroups: SelectedAdGroupsMap
  onChangeAdGroups: (id: string, selected: SelectedAdGroupsMap) => void
}

export const CampaignRow: React.FC<CampaignRowProps> = observer(
  ({
    id: campaignId,
    label,
    index,
    adGroups,
    selectedAdGroups,
    onChangeAdGroups,
  }) => {
    const [allSelected, setAllSelected] = useState<boolean>(false)

    const handleCheckCampaign = useCallback(() => {
      if (adGroups.length) {
        setAllSelected((oldValue) => {
          if (oldValue) {
            onChangeAdGroups(campaignId, {})
            return false
          }

          onChangeAdGroups(
            campaignId,
            Object.fromEntries(adGroups.map(({ Id }) => [Id, true]))
          )
          return true
        })
      }
    }, [campaignId, adGroups, onChangeAdGroups])

    const handleCheckAdGroup = useCallback(
      (adGroupId: string, newValue?: boolean) => {
        const { [adGroupId]: value, ...rest } = selectedAdGroups
        onChangeAdGroups(campaignId, {
          ...rest,
          [adGroupId]: newValue === undefined ? !value : newValue,
        })
      },
      [selectedAdGroups, onChangeAdGroups, campaignId]
    )

    useEffect(() => {
      if (adGroups.length) {
        const foundUnselected = adGroups.find(({ Id }) => !selectedAdGroups[Id])
        if (!foundUnselected !== allSelected) {
          setAllSelected(!foundUnselected)
        }
      }
    }, [selectedAdGroups, adGroups])

    return (
      <span className={styles.checkedRowContainer}>
        <CheckBox
          className={styles.checkbox}
          checked={allSelected}
          onChange={handleCheckCampaign}
        />
        <ClosableRow
          id={index}
          component={
            <TypedRow
              label={label}
              leftTip={<TypedTip data={campaignEntityDescription.campaign} />}
            />
          }
        >
          {adGroups?.map((props, idx) => (
            <AdGroupRow
              id={props.Id.toString()}
              isSelected={selectedAdGroups[props.Id]}
              onChange={handleCheckAdGroup}
              label={props.Name}
              index={idx}
              key={props.Id}
            />
          ))}
        </ClosableRow>
      </span>
    )
  }
)

export default CampaignRow
