/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"

import styles from "./WrappableContainer.module.scss"

export interface WrappableContainerProps {
  label: React.ReactNode
  className?: string
  titleClassName?: string
  containerClassName?: string
  open?: boolean
  onClick?: () => void
}

/**
 * @deprecated
 */
export const WrappableContainer: React.FC<WrappableContainerProps> = observer(
  ({
    label,
    className,
    titleClassName,
    containerClassName,
    children,
    open,
    onClick,
  }) => (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.title, titleClassName)} onClick={onClick}>
        {label}{" "}
        {!!onClick && <Icon name="arrow-right" rotateAngle={open ? 90 : -90} />}
      </div>

      {open && (
        <div className={clsx(styles.container, containerClassName)}>
          {children}
        </div>
      )}
    </div>
  )
)

export default WrappableContainer
