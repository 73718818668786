/* eslint-disable no-useless-escape */
import { Client } from "@framework/types/client"
import {
  ClientInformationFormType,
  ContractDetailsFormType,
  ContractsFormType,
} from "@framework/types/clientProfile"

export const initialClientProfileInfo: ClientInformationFormType = {
  name: "",
  street: "",
  city: "",
  state: "",
  postal: "",
  website: "",
  industry: "",
}

export const initialClientContractsInfo: ContractsFormType = {
  contactName: "",
  contactEmail: "",
  contactProfession: "",
  contactPhone: "",
}

export const initialClientContractDetailsInfo: ContractDetailsFormType = {
  yearlyAdBudget: "",
  contractStartDate: "",
  contractEndDate: "",
  contractInformation: "",
}

export const getInitialClientInfoForm = (
  data?: Client | null
): ClientInformationFormType => {
  if (!data) return { ...initialClientProfileInfo }

  return Object.keys(
    initialClientProfileInfo
  ).reduce<ClientInformationFormType>(
    (acc, key) => {
      const value = data[key as keyof Client]
      if (value) acc[key as keyof ClientInformationFormType] = value.toString()
      return acc
    },
    { ...initialClientProfileInfo }
  )
}

export const getInitialClientContractsForm = (
  data?: Client | null
): ContractsFormType => {
  if (!data) return { ...initialClientContractsInfo }

  return Object.keys(initialClientContractsInfo).reduce<ContractsFormType>(
    (acc, key) => {
      const value = data[key as keyof Client]
      if (value) acc[key as keyof ContractsFormType] = value.toString()
      return acc
    },
    { ...initialClientContractsInfo }
  )
}

export const getInitialClientContractsDetailsForm = (
  data?: Client | null
): ContractDetailsFormType => {
  if (!data) return { ...initialClientContractDetailsInfo }

  return Object.keys(
    initialClientContractDetailsInfo
  ).reduce<ContractDetailsFormType>(
    (acc, key) => {
      const value = data[key as keyof Client]
      if (value) acc[key as keyof ContractDetailsFormType] = value.toString()
      return acc
    },
    { ...initialClientContractDetailsInfo }
  )
}
