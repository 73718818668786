import React from "react"
import { useHistory } from "react-router-dom"

import Icon from "@components/ui/Icon/Icon"
import IconButton, { IconButtonProps } from "@components/ui/Button/IconButton"

interface BackButtonProps extends IconButtonProps {
  className?: string
  onClick?: React.MouseEventHandler
}

const BackButton: React.FC<BackButtonProps> = ({
  className,
  onClick,
  ...rest
}) => {
  const history = useHistory()

  const handleBack =
    onClick ??
    (() => (history.length > 2 ? history.goBack() : history.push("/")))

  return (
    <IconButton
      size="big"
      variant="contained"
      color="secondary"
      title="Go Back"
      className={className}
      onClick={handleBack}
      {...rest}
    >
      <Icon name="long-arrow-right" rotateAngle={180} />
    </IconButton>
  )
}

export default BackButton
