import { computed, observable } from "mobx"

import { RelatedQuestionSetName } from "@framework/types/opportunities"
import RelatedQuestionsSetStore from "./related-questions-collection.store"

export class RelatedQuestionsStore {
  @observable collections: Map<RelatedQuestionSetName, RelatedQuestionsSetStore>

  @observable isLoading: boolean = false

  @observable error: string | null = null

  constructor() {
    this.collections = new Map()
  }

  @computed get getCollectionBySetName() {
    const { collections } = this

    return (name: RelatedQuestionSetName) => {
      const res =
        collections.get(name) ?? new RelatedQuestionsSetStore({ name })

      collections.set(name, res)

      return res
    }
  }

  @computed get questionSetNames() {
    const { collections } = this
    return [...collections.keys()]
  }
}

export default RelatedQuestionsStore
