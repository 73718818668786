import React from "react"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import InterruptionConfirmModal from "@components/modals/ConfirmModal/InterruptionConfirmModal"
import useToggle from "@framework/hooks/useToggle"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { pMaxFormValidators } from "@store/manage-campaigns/validation"
import { useController, useStore } from "@store/index"
import type { ConfirmModalProps } from "./type"
import ConfirmModal from "../ConfirmModal/ConfirmModal"

export interface SaveCampaignDraftModalProps extends ConfirmModalProps {}

export const SaveCampaignDraftModal: React.FC<SaveCampaignDraftModalProps> =
  observer(({ onConfirm }) => {
    const alert = useAlert()
    const { editPMaxCampaignStore: store } = useStore()
    const { editPMaxCampaignController } = useController()

    const completeToggle = useToggle()

    const draft = store.pendingDraft

    if (draft == null)
      throw new Error("SaveCampaignDraftModal requires pending draft")

    const handleSave = async () => {
      const validationSchema = pMaxFormValidators[draft.step]

      if (validationSchema == null) throw new Error("Incorrect PMax form step")

      const data = validationSchema.cast(draft.data)

      const error = await editPMaxCampaignController.saveDraft(
        draft.step,
        data,
        false
      )

      if (error == null) store.updateData(data)
      else {
        alert.error(
          <AlertMessage title="Failed to save draft" description={error} />
        )
        handleCancel()
        return
      }

      completeToggle.setOpened(true)
    }

    const handleDrop = () => onConfirm(true)

    const handleCancel = () => onConfirm(false)

    const handleDone = () => onConfirm(true)

    if (completeToggle.isOpened)
      return (
        <ConfirmModal
          title="Draft save"
          message="This campaign is saved with draft status. You can find it in the list with all campaigns and can continue working with it anytime."
          isOpen
          applyText="Back to Campaigns"
          cancelText="Continue Editing"
          onClose={handleDone}
          onApply={handleDone}
          onCancel={handleCancel}
        />
      )

    const massageProps =
      draft.cause === "interrupt"
        ? {
            title: "Process Interrupt",
            message:
              "Are you sure want to interrupt Performant Max Campaign creating ?",
          }
        : {
            title: "Campaign draft save",
            message: "Are you sure want to save this campaign?",
          }

    return (
      <InterruptionConfirmModal
        isOpen
        onClose={handleCancel}
        onCancel={handleCancel}
        onSkip={handleDrop}
        onApply={handleSave}
        skipText="Don’t save draft"
        applyText="Save draft"
        {...massageProps}
      />
    )
  })

export default SaveCampaignDraftModal
