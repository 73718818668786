/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable } from "mobx"
import _sortBy from "lodash/sortBy"
import { KeywordsViewType } from "@framework/types/opportunities"

class OpportunitiesViewStore {
  @observable activeView: KeywordsViewType = "keywords"

  @observable showCalculator = true

  @observable sidebarFixed = true

  @observable showSidebar = false

  @computed get isFullView(): boolean {
    return !this.sidebarFixed && !this.showCalculator
  }

  @action toggleCalculator = () => {
    this.showCalculator = !this.showCalculator
  }

  @action toggleFullScreen = () => {
    const newValue = this.isFullView
    this.sidebarFixed = newValue
    this.showCalculator = newValue
    this.showSidebar = false
  }

  @action toggleFixedSidebar = () => {
    this.sidebarFixed = !this.sidebarFixed
    this.showSidebar = false
  }

  @action toggleSidebar = () => {
    this.showSidebar = !this.showSidebar
  }

  // constructor

  // computed

  // actions

  @action setView = (newView: KeywordsViewType) => {
    this.activeView = newView
  }
}

export default OpportunitiesViewStore
