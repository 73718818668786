import React from "react"
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom"
import { observer } from "mobx-react-lite"

import PreRegistrationPage from "@pages/PreRegistration/PreRegistration"
import MessagePage from "@pages/PreRegistration/MessagePage"
import LinkAccountRedirect from "@framework/prototypes/LinkAccount/LinkAccountRedirect"
import useConfirmModal from "@components/modals/GlobalConfirmModal/useConfirmModal"
import config from "@root/config"
import IndustryAnalysisDemoV1 from "@pages/IndustryAnalysisDemo/IndustryAnalysisDemoV1"
import IndustryAnalysisDemoV2 from "@pages/IndustryAnalysisDemo/IndustryAnalysisDemoV2"
import MetaAdsChannelRoot from "@pages/MetaAdsChannel/MetaAdsChannel"
import Login from "./pages/Login/Login"
import RestorePassword from "./pages/RestorePassword/RestorePassword"
import AccountRoot from "./pages/Account/AccountRoot"
import PrivateRoute from "./components/security/PrivateRoute"
import ClientsList from "./pages/ClientsList/ClientsList"
import ClientRoot from "./pages/Client/ClientRoot"
import PaidSearchRoot from "./pages/PaidSearchChannel/PaidSearchChannel"
import GoogleShoppingRoot from "./pages/GoogleShoppingChannel/GoogleShoppingRoot"
import SignUp from "./pages/SignUp/SignUp"
import { useStore } from "./store"
import SettingsRoot from "./pages/Settings/SettingsRoot"
import ResetPassword from "./pages/RestorePassword/ResetPassword"

const RootRoutes: React.FC = observer(() => {
  const {
    productFeedStore: { GMC },
  } = useStore()
  const location = useLocation()

  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/accounts" />
      </Route>

      <PrivateRoute path="/demo" component={IndustryAnalysisDemoV1} />

      <Route path="/industry-analysis" component={IndustryAnalysisDemoV2} />

      <PrivateRoute path="/clients" component={ClientsList} />

      <Route path="/account/link/redirect" component={LinkAccountRedirect} />

      <PrivateRoute path="/client/:id" component={ClientRoot} />

      <PrivateRoute path="/account/:id" component={AccountRoot} />

      <PrivateRoute path="/google-ads/:id" component={PaidSearchRoot} />

      {!!config.ENABLE_META_CHANNEL && (
        <PrivateRoute path="/meta-ads/:id" component={MetaAdsChannelRoot} />
      )}

      {!config.HIDE_SHOPIFY && GMC.isConnected && (
        <PrivateRoute path="/shopping/:id" component={GoogleShoppingRoot} />
      )}

      {!config.HIDE_SHOPIFY && <Route path="/signup" component={SignUp} />}

      <PrivateRoute path="/settings" component={SettingsRoot} />

      <Route path="/login" component={Login} />

      <Route path="/forgot-password" component={RestorePassword} />

      <Route path="/reset-password" component={ResetPassword} />

      <Route path="/pre-registration/result" component={MessagePage} />

      <Route path="/pre-registration" component={PreRegistrationPage} />

      {/* TEMP typo fix */}
      <Route path="/reset-pasword">
        <Redirect to={`/reset-password${location.search}`} />
      </Route>

      <Route path="/**">
        <Redirect to="/clients" />
      </Route>
    </Switch>
  )
})

const AppRouter: React.FC = () => {
  const { ModalNode, handleConfirm } = useConfirmModal()
  return (
    <BrowserRouter getUserConfirmation={handleConfirm}>
      {ModalNode}
      <RootRoutes />
    </BrowserRouter>
  )
}

export default AppRouter
