/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { mbNoData, parseAddress } from "@services/utils"
import { Option } from "@framework/types/types"
import Label from "@components/ui/Label/Label"
import { ClientInformationFormType } from "@framework/types/clientProfile"
import FormTextField from "@framework/prototypes/FormTextField"
import FormNumberField from "@framework/prototypes/FormNumberField"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import useOptionList from "@framework/prototypes/useOptionList"

import styles from "./Information.module.scss"

type InformationProps = {
  className?: string
}

const Information: React.FC<InformationProps> = observer(({ className }) => {
  const {
    clientProfileStore: {
      industries,
      industriesLoading,
      isEdit,
      fetchIndustries,
    },
    clientStore: { client },
  } = useStore()

  const currIndustry: Option | null = useMemo(
    () =>
      industries?.find((item) => item.label === client?.industry.toString()) ??
      null,
    [client?.industry, industries]
  )

  useEffect(() => {
    if (!industries && !industriesLoading) fetchIndustries()
  }, [industries])

  const formik = useFormikContext<ClientInformationFormType>()

  const inductryOptions = useOptionList(industries ?? [])

  return (
    <Box className={clsx(styles.root, className)}>
      <Typography type="h2">Information</Typography>
      {isEdit ? (
        <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
          <FormTextField label="Name" name="name" />

          <FormTextField name="street" label="Address" />

          <div className={styles.fieldsWrapper}>
            <FormTextField name="city" label="City" />

            <FormTextField name="state" label="State" />
          </div>

          <FormNumberField label="Zip Code" name="postal" />

          <div className={styles.fieldsWrapper}>
            <FormTextField name="website" label="Website" />

            <FormSimpleSelectField
              label="Industry"
              name="industry"
              {...inductryOptions}
            />
          </div>
        </form>
      ) : (
        client && (
          <div className={styles.container}>
            <div>
              <Label label="Name" className={styles.wrapper}>
                <Typography>{mbNoData(client.name)}</Typography>
              </Label>
              <Label label="Address" className={styles.wrapper}>
                <Typography>
                  {mbNoData(client ? parseAddress(client) : null)}
                </Typography>
              </Label>
            </div>

            <div>
              <Label label="Website" className={styles.wrapper}>
                <Typography>{mbNoData(client.website)}</Typography>
              </Label>

              <Label label="Industry" className={styles.wrapper}>
                <Typography>
                  {mbNoData(currIndustry?.label ?? client.industry)}
                </Typography>
              </Label>
            </div>
          </div>
        )
      )}
    </Box>
  )
})

export default Information
