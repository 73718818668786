import axios, { AxiosError, AxiosResponse } from "axios"
import { action, computed, observable } from "mobx"
import { useLocalStore } from "mobx-react-lite"
import { useEffect } from "react"

const interceptor = (errorsStore: SystemErrorsStore) => {
  const instance = axios.interceptors.response.use(
    (response: AxiosResponse) => {
      if (errorsStore.isAnyServiceDown && response.config.url) {
        errorsStore.deleteError(response.config.url)
      }
      return response
    },
    (error: AxiosError) => {
      if (error?.response?.status === 503 && error.config.url) {
        errorsStore.addError(error.config.url, error.response.data)
      }
      return Promise.reject(error)
    }
  )
  return () => axios.interceptors.response.eject(instance)
}

class SystemErrorsStore {
  @observable errors: Map<string, any> = new Map()

  @computed get isAnyServiceDown() {
    return this.errors.size > 0
  }

  @action addError = (key: string, error: any) => {
    this.errors.set(key, error)
  }

  @action deleteError = (key: string) => {
    this.errors.delete(key)
  }
}

const useServiceDownInterceptor = () => {
  const errorsStore = useLocalStore(() => new SystemErrorsStore())
  useEffect(() => interceptor(errorsStore), [errorsStore])
  return errorsStore
}

export default useServiceDownInterceptor
