/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { useHistory, useLocation } from "react-router-dom"

import Button, { ButtonProps } from "@components/ui/Button/Button"
import Stepper, { StepOptionsProps } from "@components/ui/Stepper/Stepper"
import { useStore } from "@store/index"
import ProfileInformation, {
  clientInfoValidationSchema,
} from "./components/ProfileInformation/ProfileInformation"
import LinkedAccounts from "./components/LinkedAccounts/LinkedAccounts"
import ContractDetails, {
  clientContractDetailsValidationSchema,
} from "./components/ContractDetails/ContractDetails"
import ContractsInfo, {
  clientContractsValidationSchema,
} from "./components/Contracts/ContractsInfo"

import styles from "./Profile.module.scss"

export type StepOption = StepOptionsProps & {
  Component: React.FC
  name: string
  required: boolean
}

const findStepIndex = (steps: StepOption[], name: string) =>
  steps.findIndex((item) => item.name === name)

const restrictedSteps = ["linkedAccounts", "contractDetails"]

const stepsOptions: StepOption[] = [
  {
    value: "1",
    name: "information",
    label: "Information",
    Component: ProfileInformation,
    required: true,
  },
  {
    value: "2",
    name: "contacts",
    label: "Contacts",
    Component: ContractsInfo,
    required: true,
  },
  {
    value: "3",
    name: "contractDetails",
    label: "Contract Details",
    Component: ContractDetails,
    required: false,
  },
  {
    value: "4",
    name: "linkedAccounts",
    label: "Linked Accounts",
    Component: LinkedAccounts,
    required: false,
  },
]

const Profile: React.FC = observer(() => {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState<{ [key: string]: boolean }>({})

  const history = useHistory()

  const { pathname, search } = useLocation<any>()

  const {
    appStore: { showEverything },
    accountStore: { clientAccountList: accounts, loadClientsAccounts },
    clientStore: { clientId },
    clientProfileStore: {
      isEdit,
      editFileListLoading,
      clientInfo,
      clientContracts,
      clientContractDetails,
      setIsEdit,
      submitFormCallback,
      loadClientFiles,
    },
  } = useStore()

  const steps = useMemo(
    () =>
      stepsOptions.filter(
        ({ name }) => showEverything || !restrictedSteps.includes(name)
      ),
    [showEverything]
  )

  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  )

  const handleChangeStep = useCallback(
    (callback: (oldStep: number) => number) => {
      setActiveStep((oldStep) => {
        const newStep = callback(oldStep)
        if (steps[newStep].name !== queryParams.alter) history.replace(pathname)
        return newStep
      })
    },
    [queryParams.alter, history, pathname, completed, steps]
  )

  const handlePrevStep = useCallback(() => {
    handleChangeStep((prevStep) => {
      if (prevStep > 0) return prevStep - 1
      return prevStep
    })
    setIsEdit(false)
  }, [setIsEdit])

  const handleNextStep = useCallback(() => {
    handleChangeStep((prevStep) => {
      if (prevStep < steps.length) return prevStep + 1
      return prevStep
    })
    setIsEdit(false)
  }, [setIsEdit, steps])

  const setStep = useCallback(
    (step: StepOptionsProps) => {
      handleChangeStep((prevStep) => {
        const index = steps.findIndex((item) => item.value === step.value)
        return index >= 0 ? index : prevStep
      })
      setIsEdit(false)
    },
    [setIsEdit, steps]
  )

  const setStepCompleted = useCallback(
    (step: string, value: boolean) => {
      setCompleted((set) => ({
        ...set,
        [step]: value,
      }))
    },
    [setCompleted]
  )

  useEffect(() => {
    if (clientId) loadClientsAccounts(clientId)
  }, [clientId])

  useEffect(() => {
    const stepIndex = findStepIndex(steps, queryParams.alter)
    if (stepIndex >= 0) setActiveStep(stepIndex)
  }, [queryParams.alter])

  useEffect(() => {
    if (clientId && !editFileListLoading) loadClientFiles(clientId)
  }, [clientId, editFileListLoading])

  const { Component, required: isCurrentStepRequired } = steps[activeStep]

  useEffect(() => {
    clientInfoValidationSchema.isValid(clientInfo).then((value) => {
      setStepCompleted("0", value)
    })
  }, [clientInfo, setStepCompleted])

  useEffect(() => {
    clientContractsValidationSchema.isValid(clientContracts).then((value) => {
      setStepCompleted("1", value)
    })
  }, [clientContracts, setStepCompleted])

  useEffect(() => {
    clientContractDetailsValidationSchema
      .isValid(clientContractDetails)
      .then((value) => {
        setStepCompleted("2", value)
      })
  }, [clientContractDetails, setStepCompleted])

  useEffect(() => {
    const hasLinkedAccounts = !!accounts.filter(
      ({ adAccountId }) => !!adAccountId
    ).length
    setStepCompleted("3", hasLinkedAccounts)
  }, [accounts, setStepCompleted])

  const nextStepDescription = steps[activeStep + 1]

  const isNextStepExist = !!nextStepDescription
  const canGoForward = !isCurrentStepRequired || !!completed[activeStep]

  return (
    <div className={styles.root}>
      {/* header */}
      <div className={styles.header}>
        <Stepper
          activeStep={activeStep}
          complete={completed}
          steps={steps}
          disableAfterIndex={canGoForward ? undefined : activeStep}
          className={styles.stepper}
          onClick={setStep}
        />

        <div className={styles.control}>
          {isEdit ? (
            <>
              <Button
                color="secondary"
                className={styles.editButton}
                onClick={() => setIsEdit(false)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className={styles.editButton}
                onClick={submitFormCallback}
                disabled={!submitFormCallback}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              className={styles.editButton}
              onClick={() => setIsEdit(true)}
            >
              Edit
            </Button>
          )}
        </div>
      </div>

      {/* body */}
      {Component && <Component />}

      {/* footer */}
      <div className={styles.buttonsWrapper}>
        <Button
          hidden={activeStep - 1 < 0}
          className={styles.nextStepButton}
          variant="ghost"
          onClick={handlePrevStep}
          startIcon={{ name: "long-arrow-right", rotateAngle: 180 }}
        >
          {`Back to ${steps[activeStep - 1]?.label}`}
        </Button>

        <Button
          hidden={!isNextStepExist}
          disabled={!canGoForward}
          className={styles.nextStepButton}
          variant="ghost"
          onClick={handleNextStep}
          endIcon={{ name: "long-arrow-right" }}
        >
          {`Go to ${nextStepDescription?.label}`}
        </Button>
      </div>
    </div>
  )
})

export default Profile
