/* eslint-disable camelcase */
import _groupBy from "lodash/groupBy"
import { action, computed, observable } from "mobx"
import { CampaignEntity } from "@framework/types/account"
import accountCampaignService, {
  UpdateRuleRequestDataType,
} from "@services/account-campaign.service"
import {
  CampaignType,
  ConditionType,
  Rule,
} from "@framework/types/manageCampaign"

export class AccountRulesStore {
  @observable ruleToEdit?: number | null

  @observable rulesList: Rule[] | null = null

  @observable campaigns: CampaignEntity[] | null = null

  @observable rulesListLoading: boolean = false

  @observable createRuleWithConditionsLoading: boolean = false

  @observable createRuleLoading: boolean = false

  @observable updateRuleLoading: boolean = false

  @observable deleteRuleLoading: boolean = false

  @observable editRuleError: string | null = null

  @observable conditionErrors: any | null = null

  @computed get editRuleLoading() {
    return (
      this.createRuleLoading ||
      this.updateRuleLoading ||
      this.deleteRuleLoading ||
      this.createRuleWithConditionsLoading
    )
  }

  @computed get isEdit() {
    return this.ruleToEdit === null || Boolean(this.ruleToEdit)
  }

  @action setRuleToEdit = (value?: number | null) => {
    this.ruleToEdit = value
  }

  @action loadRuleList = async (accountId: number) => {
    this.rulesListLoading = true
    try {
      const response = await accountCampaignService.getRules(accountId)
      if (response.status < 300 && response.data.data)
        this.rulesList = response.data.data
    } catch (error) {
      console.log(error)
    } finally {
      this.rulesListLoading = false
    }
  }

  @action createRuleWithConditions = async (
    accountId: number,
    rule: any,
    conditions: ConditionType[],
    ruleId?: number
  ) => {
    this.createRuleWithConditionsLoading = true
    this.editRuleError = null
    try {
      const newRuleId = ruleId
        ? await this.updateRule(accountId, ruleId, rule)
        : await this.createRule(accountId, rule)

      if (!newRuleId) {
        return false
      }

      const groups = _groupBy(conditions, "status")

      const createResult = await Promise.all(
        groups.CREATE?.map((condition) =>
          this.createConditions(accountId, newRuleId, condition)
        ) ?? []
      )

      const deleteResult = await Promise.all(
        groups.DELETE?.map((condition) =>
          this.removeRuleConditions(accountId, newRuleId, condition.id)
        ) ?? []
      )

      if (createResult.includes(false) || deleteResult.includes(false)) {
        this.editRuleError = "Failed while saving conditions"
        return false
      }

      return ruleId
    } catch (error) {
      console.log(error)
      this.editRuleError = "error"
    } finally {
      this.createRuleWithConditionsLoading = false
    }
    return false
  }

  @action createRule = async (accountId: number, rule: any) => {
    this.createRuleLoading = true
    this.editRuleError = null
    try {
      const response = await accountCampaignService.addRule(accountId, rule)
      const { id: ruleId } = response.data.data
      return ruleId
    } catch (error) {
      console.log(error)
      this.editRuleError = "error"
    } finally {
      this.createRuleLoading = false
    }
    return false
  }

  @action updateRule = async (
    accountId: number,
    ruleId: number,
    data: UpdateRuleRequestDataType
  ) => {
    this.updateRuleLoading = true
    try {
      await accountCampaignService.updateRule(accountId, ruleId, data)

      return ruleId
    } catch (error: any) {
      console.log(error)
      this.editRuleError = error.response?.data?.error ?? "Updating rule failed"
    } finally {
      this.updateRuleLoading = false
    }
    return false
  }

  @action updateRuleActivity = async (
    accountId: number,
    ruleId: number,
    active: boolean
  ) => this.updateRule(accountId, ruleId, { active })

  @action removeRule = async (accountId: number, ruleId: number) => {
    this.deleteRuleLoading = true
    try {
      await accountCampaignService.deleteRule(accountId, ruleId)

      return true
    } catch (error) {
      console.log(error)
    } finally {
      this.deleteRuleLoading = false
    }
    return false
  }

  @action removeRuleConditions = async (
    accountId: number,
    ruleId: number,
    conditionsId: string
  ) => {
    try {
      await accountCampaignService.deleteRuleCondition(
        accountId,
        ruleId,
        conditionsId
      )

      return true
    } catch (error) {
      console.log(error)
    }
    return false
  }

  @action createConditions = async (
    accountId: number,
    ruleId: number,
    condition: ConditionType
  ) => {
    try {
      const { id, applied, status, applied_area, ...rest } = condition
      await accountCampaignService.addRuleCondition(accountId, ruleId, {
        ...rest,
        applied_area:
          applied_area === "CUSTOM"
            ? (applied.join(",") as CampaignType)
            : applied_area,
      })

      return true
    } catch (error: any) {
      if (this.conditionErrors == null) {
        this.conditionErrors = {}
      }
      if (!error.response?.data.error) {
        this.conditionErrors[condition.id] = "Failed to add condition"
        return false
      }
      const ruleNames = (error.response?.data.data as any[])
        ?.map(({ RuleName }) => RuleName)
        .join(", ")
      this.conditionErrors[
        condition.id
      ] = `${error.response?.data?.error}. Conflicting conditions applied to Rule: ${ruleNames}`
    }
    return false
  }

  @action loadCampaignReports = async (accountId: number) => {
    try {
      const response = await accountCampaignService.getCampaignsList(accountId)
      this.campaigns = response.data.data

      return true
    } catch (error) {
      console.error(error)
    }
    return false
  }
}

export default AccountRulesStore
