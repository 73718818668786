import React from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"

import styles from "./DragHandle.module.sass"

export const DragHandle = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => (
  <div className={clsx(props.className, styles.root)} {...props} ref={ref}>
    <Icon name="menu" />
  </div>
))

export default DragHandle
