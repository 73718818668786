import React from "react"
import clsx from "clsx"

import styles from "./TextAreaField.module.scss"

export interface TextAreaFieldProps
  extends React.HTMLProps<HTMLTextAreaElement> {
  error?: string
  className?: string
  withIcon?: boolean
  withValidate?: boolean
  resizable?: boolean
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  error = "",
  className = "",
  withIcon,
  withValidate = false,
  value,
  resizable = false,
  ...rest
}) => (
  <textarea
    className={clsx(
      styles.wrapper,
      {
        [styles.valid]: withValidate && !error,
        [styles.error]: error,
        [styles.withIcon]: withIcon,
        [styles.resizable]: resizable,
      },
      className
    )}
    value={value}
    {...rest}
  />
)

export default TextAreaField
