import sumBy from "lodash/sumBy"
import { action, computed, observable } from "mobx"
import { Option } from "@framework/types/types"
import RootStore from "../RootStore"
import FilterByAttributesStore from "./filter-by-attributes"
import FilterByCategoriesStore from "./filter-by-category"
import FilterByQueryStore from "./filter-by-query"

const filterTabs: Option[] = [
  { label: "Attributes", value: "byAttribute" },
  { label: "Categories", value: "byCategory" },
]

export class ProductFilterStore {
  @observable filterTabs: Option[] = [...filterTabs]

  @observable activeFilterTab: Option = filterTabs[0]

  @observable rootStore: RootStore

  @observable filters: {
    byAttributes: FilterByAttributesStore
    byCategories: FilterByCategoriesStore
  }

  search: FilterByQueryStore

  // constructor

  constructor(root: RootStore) {
    this.rootStore = root
    this.filters = {
      byAttributes: new FilterByAttributesStore(root),
      byCategories: new FilterByCategoriesStore(root),
    }
    this.search = new FilterByQueryStore(root)
  }

  // computed

  @computed get active(): Record<string, string[]> {
    return {
      ...this.filters.byAttributes.active,
      category: this.filters.byCategories.active,
      q: [this.search.query],
    }
  }

  @computed get filtersList() {
    return Object.values(this.filters)
  }

  @computed get filtersCountDifference() {
    return sumBy(this.filtersList, "filtersCountDifference")
  }

  @computed get isSync() {
    return this.filters.byAttributes.isSync && this.filters.byCategories.isSync
  }

  @computed get activeFilterCount() {
    return sumBy(this.filtersList, "activeFiltersCount")
  }

  // active

  @action setActiveTab = (activeTab: Option) => {
    this.activeFilterTab = activeTab
  }

  @action resetAll = () => {
    this.filtersList.forEach((item) => item.resetAll())
    this.rootStore.productFeedStore.list.reloadProductFeed(true)
  }

  @action applyFilter = () => {
    this.filtersList.forEach((item) => item.applyFilter())
    this.rootStore.productFeedStore.list.reloadProductFeed(true)
  }

  @action resetTemp = () => {
    this.filtersList.forEach((item) => item.resetTemp())
  }
}

export default ProductFilterStore
