import { observable } from "mobx"
import FilterGroup from "@framework/classes/FilterGroup"
import OptionsFilter from "@framework/classes/OptionsFilter"
import SimpleFilter from "@framework/classes/SimpleFilter"
import { RangeType } from "@framework/types/types"
import OpportunitiesStore from "./opportunities"

const statusOptions = [
  { value: "active", label: "Active" },
  { value: "not-active", label: "Not Active" },
]

const competitiveOptions = [
  { value: "High", label: "High Level" },
  { value: "Med", label: "Mid Level" },
  { value: "Low", label: "Low Level" },
]

type AttributeFilterGroup = {
  status: OptionsFilter
  cpc: SimpleFilter<RangeType>
  competitive: OptionsFilter
}

export class FilterByAttributes extends FilterGroup<AttributeFilterGroup> {
  @observable opportunitiesStore: OpportunitiesStore

  constructor(opportunitiesStore: OpportunitiesStore) {
    super({
      status: new OptionsFilter(statusOptions),
      competitive: new OptionsFilter(competitiveOptions),
      cpc: new SimpleFilter<RangeType>(),
    })
    this.opportunitiesStore = opportunitiesStore
  }
}

export default FilterByAttributes
