import React from "react"
import { observer } from "mobx-react-lite"
import { useHistory, useLocation } from "react-router-dom"

import useQueryParams from "@framework/hooks/useQueryParams"
import { useStore } from "@store/index"
import BuildingAccount from "./components/BuildingAccount/BuildingAccount"
import SuccessfulCreated from "./components/SuccessfulCreated/SuccessfulCreated"
import GoogleAdwordsLink from "./components/GoogleAdwordsLink/GoogleAdwordsLink"
import FinalSuccess from "./components/FinalSuccess/FinalSuccess"

import styles from "./CreateCampaign.module.scss"

const tabs = ["building", "action", "link", "success"] as const

export type TabNames = (typeof tabs)[number]

const CreateCampaign = observer(() => {
  const location = useLocation()
  const history = useHistory()

  const { createdCampaign } = useStore()

  const params = useQueryParams()

  const handleSwitchTab = (tabName: TabNames) => {
    history.replace(`${location.pathname}?phase=${tabName}`)
  }

  const tab = params.phase

  React.useEffect(() => {
    if (createdCampaign.isLoading) handleSwitchTab("action")
    if (params.phase == null || !tabs.includes(params.phase))
      handleSwitchTab("building")
    else handleSwitchTab(tab)
  }, [params.phase])

  return (
    <div className={styles.root}>
      <div className={styles.tabsWrapper}>
        {tab === "building" && <BuildingAccount switchTab={handleSwitchTab} />}
        {tab === "action" && <SuccessfulCreated switchTab={handleSwitchTab} />}
        {tab === "link" && <GoogleAdwordsLink switchTab={handleSwitchTab} />}
        {tab === "success" && <FinalSuccess switchTab={handleSwitchTab} />}
      </div>
    </div>
  )
})

export default CreateCampaign
