import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import { useFormikContext } from "formik"

import { initArray } from "@utils/numberUtils"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Icon from "@components/ui/Icon/Icon"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import { useController, useStore } from "@store/index"
import WrappableContainer from "./WrappableContainer"
import LimitLengthTextField from "./LimitLengthTextField"
import RowContainer from "./RowContainer"
import { usePromptsContext } from "./PromptsContext"

import styles from "./EditAdCopyForm.module.scss"

const fields = initArray(4, (idx) => ({
  name: `description${1 + idx}`,
  label: `Description ${idx + 1}`,
}))

export interface DescriptionsFormProps {}

export const DescriptionsForm: React.FC<DescriptionsFormProps> = observer(
  () => {
    const formik = useFormikContext<any>()
    return (
      <WrappableContainer label="Descriptions" defaultOpened>
        <Stack gutter="16" align="stretch" className={styles.sectionBody}>
          {fields.map((it) => (
            <RowContainer label={it.label} key={it.name}>
              <Stack className={styles.descriptionRow}>
                <div className={styles.inputWithControl}>
                  <LimitLengthTextField
                    name={it.name}
                    placeholder="-"
                    maxChars={90}
                    after={
                      <Button
                        size="big"
                        color="secondary"
                        className={styles.refreshButton}
                        title="Rephrase headline"
                      >
                        <Icon name="refresh" />
                      </Button>
                    }
                    key={it.name}
                  />

                  <RephraseDescription
                    label={it.label}
                    value={formik.values[it.name]}
                    onChange={(v) => formik.setFieldValue(it.name, v)}
                  />
                </div>
              </Stack>
            </RowContainer>
          ))}
        </Stack>
      </WrappableContainer>
    )
  }
)

export default DescriptionsForm

const RephraseDescription: React.FC<{
  label: string
  value: unknown
  onChange: (value: string) => void
}> = observer(({ label, value, onChange }) => {
  const alert = useAlert()
  const prompts = usePromptsContext()

  const {
    accountStore: { accountId },
  } = useStore()
  const { adCopyManagement } = useController()

  const [isLoading, setLoading] = React.useState(false)

  if (prompts == null || accountId == null) return null

  const isValid = !!value && typeof value === "string"

  const handleRephrase = async () => {
    if (!value || typeof value !== "string")
      throw new Error("Handler should not be called with invalid value")

    setLoading(true)

    const res = await adCopyManagement.rephraseAdCopyWithAI(
      accountId,
      prompts,
      {
        description: value,
      }
    )

    setLoading(false)

    if (res.status === "FAILED") {
      alert.error(
        <AlertMessage
          title={`Failed to rephrase ${label}`}
          description="Try again later"
        />
      )
      return
    }

    onChange?.(res.data.description ?? "")
  }

  return (
    <Button
      title={`Rephrase ${label}`}
      size="big"
      color="secondary"
      className={styles.refreshButton}
      disabled={!isValid || isLoading}
      onClick={handleRephrase}
    >
      {isLoading ? (
        <CircleLoader className={styles.spinner} />
      ) : (
        <Icon name="refresh" />
      )}
    </Button>
  )
})
