import React from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import { FormikProvider, useFormik } from "formik"

import Stack from "@components/ui/Stack/Stack"
import Label from "@components/ui/Label/Label"
import FormTextField from "@framework/prototypes/FormTextField"
import { websiteURLValidator } from "@utils/validators"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import FromTagsSelector from "@framework/prototypes/FromTagsSelector"
import FormTextAreaField from "@framework/prototypes/FormTextAreaField"

import styles from "./IntroForm.module.scss"

const MAX_COMPETITORS = 3

export const validationSchema = yup.object({
  website: websiteURLValidator.clone().label("Website").default("").required(),
  offer: yup
    .string()
    .label("Offer")
    .default("")
    .required()
    .test(
      "max words",
      "50 words maximum",
      (val) => val.split(" ").length <= 50
    ),
  competitors: yup
    .array()
    .of(websiteURLValidator.clone().required().label("Competitor"))
    .default([""])
    .required(),
  seedKeywords: yup.array().of(yup.object()).default([]),
})

export type FormData = yup.InferType<typeof validationSchema>

type IntroFormProps = {
  initialValues?: Partial<FormData>
  loading?: boolean
  onSubmit: (data: FormData) => void
}

const IntroForm: React.FC<IntroFormProps> = observer(
  ({ initialValues, loading, onSubmit }) => {
    const formik = useFormik<FormData>({
      initialValues: {
        ...validationSchema.getDefault(),
        ...validationSchema.cast(initialValues),
      },
      validationSchema,
      onSubmit,
    })

    const addCompetitor = () => {
      formik.setValues(
        {
          ...formik.values,
          competitors: [...formik.values.competitors, ""],
        },
        false
      )
    }

    const deleteCompetitor = (index: number) => {
      formik.setValues(
        {
          ...formik.values,
          competitors: (formik.values.competitors as string[]).filter(
            (_, itIdx) => itIdx !== index
          ),
        },
        false
      )
    }

    return (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gutter="20" align="stretch">
            <Label label="Your website">
              <FormTextField
                name="website"
                placeholder="Enter your website URL"
              />
            </Label>

            <Label label="What do you sell? (up to 50 words)">
              <FormTextAreaField
                name="offer"
                placeholder="example: ”We offer sleek, urban electric bicycles designed for daily commuting and leisure rides. Our range also includes accessories like helmets, locks, and lights, along with a variety of spare parts for maintenance.”"
                rows={6}
              />
            </Label>

            {formik.values.competitors.map(
              (it: string, idx: number, list: any[]) => {
                const isLastIndex = list.length - 1 === idx
                const label = `Competitor ${list.length > 1 ? idx + 1 : ""}`
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={styles.row} key={idx}>
                    <Label label={label}>
                      <FormTextField
                        name={`competitors[${idx}]`}
                        placeholder="Enter your competitor's website URL"
                      />
                    </Label>

                    <div className={styles.rowControl}>
                      {idx !== 0 && (
                        <IconButton
                          title={`Delete ${label}`}
                          color="primary"
                          onClick={() => deleteCompetitor(idx)}
                        >
                          <Icon name="trash" />
                        </IconButton>
                      )}

                      {isLastIndex &&
                        idx + 1 < MAX_COMPETITORS &&
                        !(formik.errors?.competitors as string[])?.at(idx) &&
                        !!(formik.values?.competitors as any[])?.at(idx) && (
                          <IconButton
                            title="Add Competitor"
                            color="primary"
                            onClick={addCompetitor}
                          >
                            <Icon name="plus" />
                          </IconButton>
                        )}
                    </div>
                  </div>
                )
              }
            )}

            <Label label="Keywords Seed">
              <FromTagsSelector
                name="seedKeywords"
                placeholder="Enter additional keywords seed"
                unit="seed"
              />
            </Label>

            <Button type="submit" disabled={loading}>
              Start keyword indexing
            </Button>
          </Stack>
        </form>
      </FormikProvider>
    )
  }
)

export default IntroForm
