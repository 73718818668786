import React from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"

import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import Stack from "@components/ui/Stack/Stack"
import FormSimpleMultiSelectField from "@framework/prototypes/FormSimpleMultiSelectField"
import FormTextField from "@framework/prototypes/FormTextField"
import { useAdCopyAIWizardContext } from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/AdCopyTable/AdCopyAIWizardContext"
import { capitalize } from "lodash"
import FieldRow from "../components/FieldRow"
import { FormComponentProps } from "."
import {
  advancedSettingsValidationSchema as validationSchema,
  toneOptions,
  occasionOptions,
  ageOptions,
  customerTypeOptions,
} from "./validation"

import styles from "./AdvancedSettings.module.sass"

type FormData = yup.InferType<typeof validationSchema>

type AdvancedSettingsProps = {} & FormComponentProps

const AdvancedSettings: React.FC<AdvancedSettingsProps> = observer(
  ({ headerSlot, controlSlot, onSubmit }) => {
    const wizardContext = useAdCopyAIWizardContext()

    const handleSubmit = (data: any) => {
      wizardContext.updateSettings(data)

      onSubmit()
    }

    const formik = useFormik<FormData>({
      initialValues: validationSchema.cast(wizardContext.settings ?? {}, {
        stripUnknown: true,
      }),
      validationSchema,
      onSubmit: handleSubmit,
    })

    return (
      <FormikProvider value={formik}>
        <form className={styles.root} onSubmit={formik.handleSubmit}>
          {headerSlot}

          <div className={styles.header}>
            <Typography type="h4" weight="bold">
              Fill Advanced Settings (optional)
            </Typography>
            <Typography type="body2" color="black70Color">
              This is optional step, you can skip it if you want
            </Typography>
          </div>

          <div className={styles.body}>
            <Stack gutter="32" align="stretch">
              <FieldRow
                title="Text Tone"
                subtitle="Specify text tone in which you want to speak with customers"
              >
                <Stack gutter="8" direction="row" wrappable>
                  {toneOptions.map((it) => {
                    const active = formik.values.toneType === it
                    return (
                      <Button
                        onClick={() => formik.setFieldValue("toneType", it)}
                        variant={active ? "outlined" : "contained"}
                        color={active ? "primary" : "secondary"}
                        key={it}
                      >
                        {capitalize(it)}
                      </Button>
                    )
                  })}
                </Stack>
              </FieldRow>

              <FieldRow
                title="Occasion"
                subtitle="Attract customers with a favorable offer on some occasion"
              >
                <Stack gutter="8" direction="row" wrappable>
                  {occasionOptions.map((it) => {
                    const active = formik.values.occasion === it
                    return (
                      <Button
                        onClick={() => formik.setFieldValue("occasion", it)}
                        variant={active ? "outlined" : "contained"}
                        color={active ? "primary" : "secondary"}
                        key={it}
                      >
                        {capitalize(it)}
                      </Button>
                    )
                  })}
                </Stack>
              </FieldRow>

              <FieldRow
                title="Audience Age"
                subtitle="Select the age of your target audience"
              >
                <FormSimpleMultiSelectField
                  name="audienceAges"
                  options={ageOptions}
                  renderValue={capitalize}
                />
              </FieldRow>

              <FieldRow
                title="Customer Type"
                subtitle="Attract certain groups of customers"
              >
                <FormSimpleMultiSelectField
                  name="customerTypes"
                  options={customerTypeOptions}
                  renderValue={capitalize}
                />
              </FieldRow>

              <FieldRow
                title="Purchase Terms (optional)"
                subtitle="Select your company purchase terms"
              >
                <FormTextField
                  name="promotionOffer"
                  placeholder="Description"
                />
              </FieldRow>
            </Stack>
          </div>

          {controlSlot}
        </form>
      </FormikProvider>
    )
  }
)

export default AdvancedSettings
