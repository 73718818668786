import React, { FC } from "react"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import AssetImagesCrop from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupLogosSidebar/AssetImagesCrop"
import { PreviewFile } from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/AssetImagesUpload"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import styles from "../AssetGroupImagesSidebar/AssetGroupImagesSidebar.module.scss"

interface CropImageModalProps {
  isOpen: boolean
  onClose: () => void
  localFile?: PreviewFile
  file?: { url: string; name: string; id: number; type: string }
  isFirstImage?: boolean
  handleCroppedImages: (
    list: {
      name: string
      aspect: string
      file: any
      id?: number
    }[]
  ) => void
}

const CropImageModal: FC<CropImageModalProps> = observer(
  ({ onClose, isOpen, localFile, file, handleCroppedImages, isFirstImage }) => (
    <Modal
      title="Crop image"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.cropImageModal}
    >
      <Stack align="center" direction="column" justify="center">
        {isFirstImage && (
          <Typography type="body2">
            Other logos can be cropped manually from a context menu
          </Typography>
        )}
        <AssetImagesCrop
          localFile={localFile}
          file={file}
          setCroppedImagesList={handleCroppedImages}
          onClose={onClose}
        />
      </Stack>
    </Modal>
  )
)

export default CropImageModal
