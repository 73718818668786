import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import HeaderColumn from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/AdCopyTable/HeaderColumn"
import { Option } from "@framework/types/types"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import { useTableSortContext } from "@framework/prototypes/TableSortContext"
import Column from "./Table/Column"
import Row from "./Table/Row"
import EditHeaderDropDown from "./EditHeaderDropDown"
import { insightOptions } from "./constants"

import styles from "./InsightTable.module.scss"

const options: Option[] = insightOptions.map((name) => {
  const it = getMetricDescriptor(name)
  return {
    value: it.name,
    label: it.label,
  }
})

interface Props {
  nameLabel?: string
  columns: MetricNameType[]
  onChangeColumn: (name: string, idx: number) => void
}

export const Header: React.FC<Props> = observer(
  ({ nameLabel = "Name", onChangeColumn, columns }) => {
    const sortingContext = useTableSortContext()

    return (
      <Row className={styles.queryRow}>
        <Column key="label">
          <Typography type="caption3" color="black60Color">
            <HeaderColumn
              name="label"
              value={sortingContext.sortBy}
              onSort={sortingContext.sort}
            >
              {nameLabel}
            </HeaderColumn>
          </Typography>
        </Column>

        <Column key="match-types">
          <Typography type="caption3" color="black60Color">
            <HeaderColumn
              name="MatchTypes"
              value={sortingContext.sortBy}
              onSort={sortingContext.sort}
            >
              Match Types
            </HeaderColumn>
          </Typography>
        </Column>

        {columns.map((name, idx) => {
          const it = getMetricDescriptor(name)
          return (
            <Column key={name}>
              <Typography type="caption3" color="black60Color">
                <HeaderColumn
                  name={it.name}
                  value={sortingContext.sortBy}
                  onSort={sortingContext.sort}
                >
                  <EditHeaderDropDown
                    value={it.name}
                    options={options}
                    onSelect={(name) => onChangeColumn(name, idx)}
                  />
                  {it.shortLabel}
                </HeaderColumn>
              </Typography>
            </Column>
          )
        })}
      </Row>
    )
  }
)

export default Header
