/* eslint-disable jsx-a11y/no-autofocus */
import React from "react"
import identity from "lodash/identity"

import SimpleOption from "@components/ui/DropDown/Option"
import Select from "@components/ui/DropDown"
import NoData from "@components/ui/NoData/NoData"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import BaseField from "@components/ui/TextField/BaseField"
import Typography from "@components/ui/Typography/Typography"
import { PopperPlacement } from "./usePopper"
import Chip from "../Chip/Chip"

export interface SimpleMultiselectProps {
  value?: string[]
  allDisabled?: string[]
  options: string[]
  unit?: string
  isAllSelected?: boolean
  disabled?: boolean
  withSearch?: boolean
  className?: string
  placement?: PopperPlacement
  placeholder?: string
  query?: string
  total?: number
  onSearch?: (value: string, e?: React.ChangeEvent) => void
  renderValue?: (value: string) => React.ReactNode
  onSelect?: (value: string, e?: React.MouseEvent) => void
  onAllSelect?: React.MouseEventHandler
  isSelected?: (value: string, selected?: string[]) => boolean
}

const SimpleMultiselect: React.FC<SimpleMultiselectProps> = ({
  options,
  value,
  unit = "item",
  className,
  allDisabled = [],
  placement,
  disabled,
  isAllSelected,
  withSearch = false,
  placeholder = `Select ${unit}s...`,
  query,
  total = options.length,
  onSearch,
  renderValue = identity,
  isSelected = (value, selected) => selected?.includes(value) ?? false,
  onSelect,
  onAllSelect,
}) => {
  const disabledSet = new Set(allDisabled)

  const counter = value?.length
    ? value.length === total
      ? `All ${unit}s`
      : `${value.length} selected`
    : null

  const renderBeforeNode = (isActive: boolean) =>
    withSearch && isActive ? <Icon name="search" /> : null

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    onSearch?.(e.target.value, e)

  return (
    <Select.DropDown
      keepOpened
      placement={placement}
      disabled={disabled}
      className={className}
      target={(isActive) => (
        <BaseField
          active={isActive}
          disabled={disabled}
          before={renderBeforeNode}
          after={
            <>
              {withSearch && counter ? (
                <Chip color="secondary">{counter}</Chip>
              ) : null}
              <IconButton>
                <Icon name="arrow-down" rotateAngle={isActive ? 180 : 0} />
              </IconButton>
            </>
          }
        >
          {withSearch ? (
            <input
              autoFocus
              value={query}
              placeholder={isActive ? `Search...` : placeholder}
              onChange={handleSearch}
            />
          ) : (
            <Typography type="inherit">{counter ?? placeholder}</Typography>
          )}
        </BaseField>
      )}
    >
      <Select.Box>
        <Select.ItemsList>
          {options.length > 0 ? (
            <>
              {onAllSelect != null && (
                <Select.Group>
                  <SimpleOption
                    isSelected={isAllSelected}
                    onClick={onAllSelect}
                    key="all"
                  >
                    All {unit}s
                  </SimpleOption>
                </Select.Group>
              )}
              <Select.Group>
                {options.map((it) => (
                  <SimpleOption
                    disabled={disabledSet.has(it)}
                    isSelected={isSelected(it, value)}
                    onClick={(e) => onSelect?.(it, e)}
                    key={it}
                  >
                    {renderValue(it)}
                  </SimpleOption>
                ))}
              </Select.Group>
            </>
          ) : (
            <NoData>List is empty</NoData>
          )}
        </Select.ItemsList>
      </Select.Box>
    </Select.DropDown>
  )
}

export default SimpleMultiselect
