import React from "react"
import clsx from "clsx"
import { useFormikContext } from "formik"
import isEqual from "lodash/isEqual"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Typography from "@components/ui/Typography/Typography"
import { ProductStatus } from "@framework/types/account"
import StatusBadge from "../../components/Status/StatusBadge"
import { productStatusMapper } from "../utils"

import styles from "./CompareFeedSidebar.module.scss"

export interface HeaderProps {
  className?: string
  status: ProductStatus
  changes?: number
  isEdit: boolean
  isLoading?: boolean
  onToggleEdit?: () => void
}

export const Header: React.FC<HeaderProps> = observer(
  ({ isEdit, status, changes, isLoading = false, className, onToggleEdit }) => {
    const formik = useFormikContext<any>()

    const isTouched = !isEqual(formik.values, formik.initialValues)

    return (
      <div className={clsx(styles.header, className)}>
        <Typography type="h2" bold className={styles.control}>
          Transformed data row
          <StatusBadge value={status} mapper={productStatusMapper} />
          {!!changes && (
            <span className={styles.changesBadge}>{changes} Changes</span>
          )}
        </Typography>
        <div className={styles.control}>
          {isEdit ? (
            <>
              <Button
                onClick={onToggleEdit}
                color="secondary"
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={formik.submitForm}
                disabled={isLoading || !isTouched}
              >
                Apply
              </Button>
            </>
          ) : (
            <Button
              disabled={isLoading}
              onClick={onToggleEdit}
              color="secondary"
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    )
  }
)
export default Header
