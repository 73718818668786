/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable } from "mobx"
import _sortBy from "lodash/sortBy"

import { BaseCampaignReportType } from "@framework/types/account"

const searchFilter = (query: string, value: BaseCampaignReportType) =>
  !query || value.Name.toLocaleLowerCase().includes(query.toLocaleLowerCase())

class SearchCampaignsStore {
  @observable searchFiler: string

  // constructor

  constructor(config?: {
    defaultQuery?: string
    campaigns?: BaseCampaignReportType[]
  }) {
    this.searchFiler = config?.defaultQuery ?? ""
    this.campaigns = config?.campaigns ?? []
  }

  // computed

  @observable campaigns: BaseCampaignReportType[] = []

  @computed get filteredCategories(): BaseCampaignReportType[] {
    const { campaigns, searchFiler } = this

    return campaigns.filter((category) => searchFilter(searchFiler, category))
  }

  // actions

  @action setSearchFilter = (value: string) => {
    this.searchFiler = value
  }

  @action setCampaigns = (value: BaseCampaignReportType[]) => {
    this.campaigns = value
  }
}

export default SearchCampaignsStore
