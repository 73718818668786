import React from "react"
import {
  Provider,
  transitions,
  positions,
  AlertProviderProps,
} from "react-alert"

import AlertPopupTemplate from "./AlertTemplate/AlertPopupTemplate"

const options: AlertProviderProps = {
  containerStyle: { bottom: 30, right: 60, zIndex: 9999 },
  position: positions.BOTTOM_RIGHT,
  timeout: 10000,
  offset: "3px 0",
  transition: transitions.SCALE,
  template: AlertPopupTemplate,
}

const AlertProvider: React.FC<any> = ({ children }) => (
  <Provider {...options}>{children}</Provider>
)

export default AlertProvider
