import { observable, action } from "mobx"
import RootStore from "@store/RootStore"

export class FilterByQueryStore {
  @observable rootStore: RootStore

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @observable query: string = ""

  @action setQuery = async (value: string = "") => {
    this.query = value
  }
}

export default FilterByQueryStore
