/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from "react"
import clsx from "clsx"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"
import Container from "@root/layouts/Container/Container"
import { Option } from "@framework/types/types"
import { NavOption } from "@components/ui/Tabs/types"
import Loader from "@components/ui/Loader/Loader"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import PrivateLayout from "@root/layouts/PrivateLayout/PrivateLayout"
// import Button from "@components/ui/Button/Button"
// import { ButtonColor, ButtonType } from "@components/utils/enums"
import ListItem from "./components/ListItem/ListItem"
import getListItems from "./MockData"
import ColumnNames from "./components/ColumnNames/ColumnNames"
import { agencyListNavItems, clientListNavItems } from "../navOptions"

import styles from "./ClientsList.module.scss"

const CHUNK_SIZE = 10

export type TableMapper = Option & {
  isSortable?: boolean
}

const tableMapper: TableMapper[] = [
  { label: "Client", value: "name", isSortable: true },
  { label: "Industry", value: "industry", isSortable: false },
  { label: "№ of Accounts", value: "accountNumber", isSortable: false }, // ??
  { label: "Total Ad Spend", value: "totalAdSpend", isSortable: false }, // ??
  { label: "Contact", value: "contactName", isSortable: false },
  { label: "Contract Start", value: "contractStartDate", isSortable: false },
  {
    label: "Contract End",
    value: "contractEndDate",
    isSortable: false,
  },
  {
    label: "Months Remaining",
    value: "remaining", // ??
    isSortable: false,
  },
]

type ClientsListProps = {
  className?: string
}

const itemList = getListItems()

const ClientsList: React.FC<ClientsListProps> = observer(({ className }) => {
  const history = useHistory()

  const [isAllChecked, setIsAllChecked] = useState(false)
  const [sortBy, setSortBy] = useState({ value: "none", direction: false })
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const [page, setPage] = useState(1)

  const {
    clientStore: { filteredClients: clients, isClientsLoading, loadClientList },
  } = useStore()

  useEffect(() => {
    // loadClientList(sortBy, page - 1, CHUNK_SIZE)
    loadClientList()
  }, [loadClientList, sortBy, page])

  const handleAction = useCallback(
    (clientId: number) => (navOption: NavOption) => {
      history.push(`/client/${clientId}${navOption.value}`)
    },
    [history]
  )

  const handleCheckAll = useCallback(() => {
    if (!isAllChecked) {
      const ids = itemList.map((item) => item.id)
      setCheckedItems(ids)
      setIsAllChecked(true)
    } else {
      setIsAllChecked(false)
      setCheckedItems([])
    }
  }, [isAllChecked])

  const handleCheck = useCallback(
    (id: string) => {
      const hasId = checkedItems.includes(id)
      hasId
        ? setCheckedItems((prev) => prev.filter((item) => item !== id))
        : setCheckedItems((prev) => [...prev, id])
    },
    [setCheckedItems, checkedItems]
  )

  return (
    <PrivateLayout
      scope="agency"
      // sideBar={{
      //   currentTab: clientListNavItems[0],
      //   options: agencyListNavItems,
      //   title: "",
      // }}
    >
      <Container>
        <div className={clsx(styles.root, className)}>
          <Typography type="h2" color="primary" className={styles.title} bold>
            Clients
          </Typography>

          <div>
            <ColumnNames
              mapper={tableMapper}
              checked={isAllChecked}
              onChange={handleCheckAll}
              onSort={setSortBy}
            />
            <Loader isLoading={isClientsLoading} />
            {clients?.map((data: any) => (
              <ListItem
                {...data}
                checked={checkedItems.includes(data.id)}
                onChange={() => handleCheck(data.id)}
                options={clientListNavItems}
                onAction={handleAction(data.id)}
                key={data.id}
              />
            ))}
          </div>
          {/* <div className={styles.pagination}>
            <Pagination
              currentPage={page}
              onChange={setPage}
              total={Math.ceil(clients.length ?? 0)}
            />
          </div> */}
        </div>
      </Container>
    </PrivateLayout>
  )
})

export default ClientsList
