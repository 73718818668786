import React from "react"

import { Button } from "@components/ui/Button"
import { moneyFormatter } from "@services/utils"
import {
  useOpportunitiesController,
  useOpportunitiesStore,
} from "@store/opportunities/provider"
import Pair from "../Pair"

import styles from "./index.module.scss"

interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  const { shoppingCart } = useOpportunitiesStore()
  const { opportunities } = useOpportunitiesController()

  const isSubmittable =
    shoppingCart.selected.size > 0 && opportunities.publishCampaign != null

  return (
    <div className={styles.footer}>
      <Pair
        label="Total Ad Spend"
        value={shoppingCart.totalAmount}
        renderValue={moneyFormatter}
        className={styles.totalAmount}
        strong
      />
      <Button
        onClick={opportunities.publishCampaign}
        disabled={!isSubmittable}
        variant="contained"
        color="primary"
        fullWidth
      >
        Confirm and Create Campaigns
      </Button>
    </div>
  )
}

export default Footer
