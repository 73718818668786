import React, { useRef } from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"

import Box from "@components/ui/Box/Box"

import styles from "./ChannelTable.module.scss"

type ListItemProps = {
  checked?: boolean
  className?: string
  data: any
  disabled?: boolean
  onClick?: () => void
}

const ListItem: React.FC<ListItemProps> = ({
  className,
  data,
  disabled,
  onClick,
}) => {
  const rootNode = useRef<HTMLDivElement>(null)

  const handleClick = disabled ? undefined : onClick

  return (
    <Box className={clsx(styles.row, className)} padding="none" ref={rootNode}>
      <div className={styles.wrapper}>
        <Typography
          type="h5"
          color="dark"
          className={clsx(styles.column, { [styles.clickable]: !!handleClick })}
          onClick={handleClick}
        >
          <img src={data.iconSrc} alt="" className={styles.badge} />
          {data.title}
        </Typography>
        <Typography type="h5" color="dark" className={styles.column}>
          {data.status}
        </Typography>
        <Typography type="h5" color="dark" className={styles.column}>
          {data.linked?.map(({ name, value }: any) => (
            <>
              <Typography color="gray" type="h5">
                {name}:
              </Typography>
              {value}
            </>
          ))}
        </Typography>
      </div>
    </Box>
  )
}

export default ListItem
