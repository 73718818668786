import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import { Legend } from "@components/ui/Charts/Legends/LegendList"
import {
  campaignEntityDescription,
  mergeLabelMappersByColor,
} from "@framework/constants/manageCampaign"
import CreateCampaignModal from "@components/CreateCampaignModal/CreateCampaignModal"
import useToggle from "@framework/hooks/useToggle"
import Templates from "@components/ui/Templates"
import Icon from "@components/ui/Icon/Icon"
import SimpleMultiselect from "@components/ui/DropDown/SimpleMultiselect"
import { snakeCaseToTitle } from "@utils/stringUtils"
import SelectableList from "@store/common/SelectableList"
import Stack from "@components/ui/Stack/Stack"

import styles from "./Header.module.scss"

const campaignTypeItems = mergeLabelMappersByColor(
  Object.values(campaignEntityDescription)
)

export type CampaignsTableControlProps = {
  campaignTypeSelectable: SelectableList
  className?: string
}

export const CampaignsTableControl: React.FC<CampaignsTableControlProps> =
  observer(({ campaignTypeSelectable, className }) => {
    const { isOpened, handleToggle } = useToggle()

    return (
      <>
        <Templates.Header
          left={
            <Stack direction="row" gutter="32" align="center">
              {campaignTypeItems.map((props) => (
                <Legend {...props} key={props.label} />
              ))}

              <SimpleMultiselect
                renderValue={(it) => snakeCaseToTitle(it)}
                options={campaignTypeSelectable.options}
                value={campaignTypeSelectable.selectedList}
                onSelect={(o) => campaignTypeSelectable.select(o)}
                isAllSelected={campaignTypeSelectable.isAllSelected}
                onAllSelect={() => campaignTypeSelectable.selectAll()}
                className={styles.selector}
                unit="campaign type"
              />
            </Stack>
          }
          right={
            <Button
              onClick={handleToggle}
              before={<Icon name="plus" />}
              variant="outlined"
              size="big"
            >
              Add Campaign
            </Button>
          }
          className={clsx(className)}
        />

        <CreateCampaignModal isOpen={isOpened} onClose={handleToggle} />
      </>
    )
  })

export default CampaignsTableControl
