/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import capitalize from "lodash/capitalize"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import AreaChart from "@components/ui/Charts/AreaChart/AreaChart"
import { transformPointToChartData } from "@store/opportunities/opportunities.transformer"
import { useOpportunitiesStore } from "@store/opportunities/provider"

import styles from "./LineChartModal.module.scss"

type LineChartModalProps = {}

const LineChartModal: React.FC<LineChartModalProps> = observer(() => {
  const { attributeChartStore } = useOpportunitiesStore()

  const { activeChart, activeAttribute } = attributeChartStore

  const name = activeAttribute?.displayName

  const { data, labels } = useMemo(
    () => transformPointToChartData(activeAttribute?.graphPoints ?? []),
    [activeAttribute?.graphPoints]
  )

  if (!activeChart) return null
  return (
    <Modal
      isOpen={attributeChartStore.isOpened}
      className={styles.root}
      onClose={attributeChartStore.hideChart}
      title={
        <Typography type="h2" bold color="primary">
          {capitalize(activeChart?.type)} ”{name}”
        </Typography>
      }
    >
      <div className={styles.body}>
        <Typography type="h2">Monthly trend</Typography>
        <AreaChart data={data} labels={labels} className={styles.chart} />
      </div>
    </Modal>
  )
})

export default LineChartModal
