/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { forwardRef, MouseEventHandler, MutableRefObject } from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"

import styles from "./CheckBox.module.scss"

type CheckBoxProps = {
  id?: string
  name?: string
  value?: string
  disabled?: boolean
  checked?: boolean
  partial?: boolean
  className?: string
  onChange?: React.ChangeEventHandler
  onClick?: MouseEventHandler
}
type Ref = HTMLInputElement
const CheckBox = forwardRef<Ref, CheckBoxProps>(
  (
    {
      className,
      disabled = false,
      checked = false,
      partial = false,
      onChange,
      onClick,
      name,
      value,
      id,
    }: CheckBoxProps,
    ref
  ) => (
    <label
      htmlFor={id}
      className={clsx(styles.root, className)}
      onClick={onClick}
    >
      <input
        // @ts-ignore
        ref={ref}
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={styles.input}
      />
      <Icon
        className={styles.mark}
        name={
          checked
            ? partial
              ? "checkbox-indeterminate-fill"
              : "checkbox-fill"
            : "checkbox-blank"
        }
      />
    </label>
  )
)
export default CheckBox
