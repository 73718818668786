import clsx from "clsx"
import React from "react"

import Icon from "@components/ui/Icon/Icon"
import { AdCopyPreview } from "@framework/prototypes/AdCopy/Preview/types"

import styles from "./AdCard.module.scss"

export interface PreviewProps {
  variant?: "mobile" | "desktop"
  data: AdCopyPreview
}

const AdCard: React.FC<PreviewProps> = ({ data, variant = "desktop" }) => {
  const { website, headlines, descriptions } = data
  return (
    <div className={clsx(styles.root, styles[variant])}>
      <div className={styles.title}>
        <span className={styles.prefix}>
          Ad <span>·</span>
        </span>
        {website}
        {variant === "desktop" && (
          <Icon className={styles.arrowDown} name="arrow-down" />
        )}
        {variant === "mobile" && (
          <Icon className={styles.info} name="information-solid" />
        )}
      </div>
      <div className={styles.header}>{headlines.join(" | ")}</div>
      <div className={styles.description}>{descriptions.join(". ")}</div>
    </div>
  )
}

export default AdCard
