import React from "react"
import { observer, useLocalStore } from "mobx-react-lite"
import InfiniteLoader from "react-window-infinite-loader"
import { List, AutoSizer } from "react-virtualized"
import sortBy from "lodash/sortBy"

import { useController, useStore } from "@store/index"
import NoData from "@components/ui/NoData/NoData"
import { MetricNameType } from "@framework/types/metrics"
import {
  TableSortContext,
  TableSortContextStore,
} from "@framework/prototypes/TableSortContext"
import Label from "@components/ui/CheckBox/Label/Label"
import Templates from "@components/ui/Templates"
import SearchInput from "@framework/prototypes/SearchContext/SearchInput"
import {
  SearchContext,
  SearchContextStore,
} from "@framework/prototypes/SearchContext"
import { SortByType } from "@framework/types/types"
import CampaignsSelect from "@framework/prototypes/CampaignsSelect/CampaignsSelect"
import { useSelectedCampaignsContext } from "@framework/prototypes/SelectedCampaignsContext"
import { KeywordEntityType } from "@framework/types/account"
import { insightOptions } from "./constants"
import Header from "./KeywordsTableHeader"
import useSortedKeywords from "./useSortedKeywords"
import KeywordRow from "./KeywordRow"
import CampaignInsightsTemplate from "./CampaignInsightsTemplate"
import Table from "./Table/Table"
import ContentMenuTemplate from "./ContentMenuTemplate"
import useFilteredCampaignChild from "./useFilteredCampaignChild"

import styles from "./InsightTable.module.scss"

const defaultSortOption: SortByType = { value: "Cost", direction: false }

interface TopKeywordsTableProps {
  sidebarSlot?: React.ReactNode
}

export const TopKeywordsTable: React.FC<TopKeywordsTableProps> = observer(
  ({ sidebarSlot }) => {
    const {
      accountStore: { accountId },
      accountDashboardStore: {
        dateRange,
        campaignInsightsStore: {
          topCampaignStore,
          topKeywordsStore: { keywordsStore, campaignStore },
        },
      },
    } = useStore()

    const { accountDashboardController: controller } = useController()

    const searchStore = useLocalStore(() => new SearchContextStore())
    const sortStore = useLocalStore(
      () =>
        new TableSortContextStore({
          defaultValue: defaultSortOption,
        })
    )

    const [columns, setColumns] = React.useState<MetricNameType[]>(
      insightOptions.slice(0, 5)
    )

    const { selectedCampaigns, setCampaigns } = useSelectedCampaignsContext()

    const valuableCampaigns = React.useMemo(
      () => new Set(campaignStore.data?.map((it) => it.Id.toString())),
      [campaignStore.data]
    )

    const campaignOptions = React.useMemo(
      () =>
        sortBy(topCampaignStore.data ?? [], (it) =>
          valuableCampaigns.has(it.Id.toString()) ? 0 : 1
        ),
      [topCampaignStore.data, valuableCampaigns]
    )

    const handleChangeColumn = (name: string, idx: number) => {
      setColumns((prev: MetricNameType[]) => {
        const newValue = [...prev]
        const swapIdx = prev.findIndex((it) => it === name)
        newValue[idx] = name as MetricNameType
        if (swapIdx >= 0) newValue[swapIdx] = prev[idx]
        return newValue
      })
    }

    React.useEffect(() => {
      if (accountId == null) return
      controller.loadTopKeywordsList(accountId, dateRange)
    }, [accountId, dateRange])

    const { filteredCollection: filteredKeywords, inProgress: isFiltering } =
      useFilteredCampaignChild<KeywordEntityType>({
        collection: keywordsStore.data,
        adGroups: selectedCampaigns,
      })

    const { sortedKeywords: keywords, inProgress: isSorting } =
      useSortedKeywords({
        keywords: filteredKeywords,
        sortBy: sortStore.sortBy,
        searchQuery: searchStore.searchQuery,
      })

    const total = keywords.length ?? 0
    const isLoading = keywordsStore.isLoading || isFiltering || isSorting

    return (
      <SearchContext.Provider value={searchStore}>
        <CampaignInsightsTemplate
          loading={isLoading}
          controlSlot={
            <Label text="Campaigns" textPosition="before">
              <CampaignsSelect
                isOptionValuable={(id) => !valuableCampaigns.has(id)}
                options={campaignOptions}
                value={selectedCampaigns}
                onChange={setCampaigns}
                className={styles.dropdown}
              />
            </Label>
          }
        >
          <ContentMenuTemplate sidebarSlot={sidebarSlot}>
            <Templates.Header offset="small" left={<SearchInput />} />

            <TableSortContext.Provider value={sortStore}>
              <Table className={styles.table}>
                <Header
                  nameLabel="Keyword"
                  columns={columns}
                  onChangeColumn={handleChangeColumn}
                />

                <div className={styles.tableBody}>
                  {total > 0 ? (
                    <InfiniteLoader
                      isItemLoaded={() => true}
                      loadMoreItems={() => {}}
                      threshold={10}
                      minimumBatchSize={10}
                      itemCount={total}
                    >
                      {({ onItemsRendered, ref }) => (
                        <AutoSizer>
                          {(size) => (
                            <List
                              ref={ref}
                              onItemsRendered={onItemsRendered}
                              rowHeight={68}
                              rowRenderer={({ index, style, key }) => {
                                const item = keywords[index]
                                return (
                                  <div style={style} key={key}>
                                    <KeywordRow data={item} mapper={columns} />
                                  </div>
                                )
                              }}
                              height={size.height}
                              width={size.width}
                              rowCount={total}
                            />
                          )}
                        </AutoSizer>
                      )}
                    </InfiniteLoader>
                  ) : (
                    <NoData className={styles.placeholder}>
                      {isLoading
                        ? "Loading..."
                        : selectedCampaigns.length
                        ? "No data found"
                        : "No campaigns selected"}
                    </NoData>
                  )}
                </div>
              </Table>
            </TableSortContext.Provider>
          </ContentMenuTemplate>
        </CampaignInsightsTemplate>
      </SearchContext.Provider>
    )
  }
)

export default TopKeywordsTable
