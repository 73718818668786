import axios, { AxiosRequestConfig, Method } from "axios"
import config from "../config"
import { AUTH_TOKEN_KEY } from "../framework/constants/auth"

interface RequestProps<Headers, Auth, Data, Params> {
  headers?: Headers
  auth?: Auth
  url: string
  method: Method
  data?: Data
  params?: Params
}

interface Config {
  API_URL: string
  headers?: any
}

class HttpService {
  client = axios.create()

  config: Config = {
    API_URL: config.API_ROOT,
    headers: {
      Accept: "application/json",
    },
  }

  constructor(params: Config) {
    this.config = { ...this.config, ...params }
  }

  protected getAuthHeader = (auth: string | boolean) => {
    if (typeof auth === "string") return { "X-API-KEY": auth }
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    if (auth === true && token) {
      return { "X-API-KEY": token }
    }
    return null
  }

  getHeaders = <Headers, Auth extends string | boolean>(
    headers: Headers,
    auth: Auth
  ) => ({ ...this.config.headers, ...headers, ...this.getAuthHeader(auth) })

  getApiUrl = (url: string) => `${this.config.API_URL}/api/${url}`

  getBaseUrl = (url: string) => `${this.config.API_URL}/${url}`

  request = <Headers, Auth extends string | boolean, Data, Params>(
    props: RequestProps<Headers, Auth, Data, Params>
  ) => {
    const headers = this.getHeaders(props.headers, props.auth ?? true)
    const url = this.getApiUrl(props.url)

    const requestParams: AxiosRequestConfig = {
      url,
      headers,
      method: props.method,
      data: props.data,
      params: props.params,
    }

    return axios.request(requestParams)
  }

  post = <Data, Params, Headers>(
    url: string,
    data: Data,
    headers?: Headers,
    auth: string | boolean = true,
    params?: Params
  ) =>
    this.request({
      url,
      data,
      auth,
      headers,
      params,
      method: "post",
    })

  put = <Data, Params, Headers>(
    url: string,
    data: Data,
    params?: Params,
    auth: boolean = true,
    headers?: Headers
  ) =>
    this.request({
      url,
      data,
      auth,
      headers,
      method: "put",
      params,
    })

  patch = <Data, Params, Headers>(
    url: string,
    data: Data,
    params?: Params,
    auth: boolean = true,
    headers?: Headers
  ) =>
    this.request({
      url,
      data,
      auth,
      headers,
      params,
      method: "patch",
    })

  get = <Params, Headers>(
    url: string,
    params?: Params,
    auth: string | boolean = true,
    headers?: Headers
  ) =>
    this.request({
      url,
      params,
      auth,
      headers,
      method: "get",
    })

  delete = <Data, Params, Headers>(
    url: string,
    data?: Data,
    params?: Params,
    auth: boolean = true,
    headers?: Headers
  ) =>
    this.request({
      url,
      data,
      auth,
      params,
      headers,
      method: "delete",
    })
}

export default HttpService
