/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { CSSProperties } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import NumberFormat, { NumberFormatValues } from "react-number-format"

import Button from "@components/ui/Button/Button"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Typography, {
  ITypographyProps,
} from "@components/ui/Typography/Typography"
import { numberWithCommas } from "@components/utils/numberUtils"
import { RecommendedKeyword } from "@framework/types/opportunities"
import {
  transformEstimate,
  transformPointToChartData,
} from "@store/opportunities/opportunities.transformer"
import TextField from "@components/ui/TextField/TextField"
import SimpleAreaChart from "@components/ui/Charts/AreaChart/SimpleAreaChart"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import { percentageFormatter } from "@services/utils"
import { TableMapper } from "./Table"
import { formatCompetition } from "./utils"

import styles from "./Table.module.scss"

const { useState, useEffect } = React

export interface RowProps {
  idx: number
  data: RecommendedKeyword
  mapper: TableMapper[]
  onStatusClick: (accountId: number, keywordId: number) => void
  onChange: (keywordId: number, name: string, value: any) => void
  ctr?: number
  accountId?: number | null
}

export const Row: React.FC<RowProps> = observer((props) => {
  const { idx, data, mapper, onStatusClick, onChange, accountId, ctr } = props

  const { attributeChartStore, shoppingCart } = useOpportunitiesStore()

  const {
    id,
    displayName: name,
    graphPoints,
    avgSearchVolume,
    distance,
    inUse,
    avgCpc,
    category,
    budgetFraction,
    relevanceScope,
    competition,
    funnelStage,
  } = data

  const estimate = transformEstimate(data, ctr)

  const [graphData, setGraphData] = useState<number[]>([])

  useEffect(() => {
    // For performance improvement purpose.
    // Consider to change to more elegant lazy loading algorithm
    const delay = Math.ceil(idx / 10) * 10
    const timeout = setTimeout(() => {
      setGraphData(transformPointToChartData(graphPoints).data)
    }, delay)
    return () => clearTimeout(timeout)
  }, [graphPoints, idx])

  const openChart = () => {
    attributeChartStore.showChart("attribute", id)
  }

  const active = shoppingCart.isSelected(id)

  const renderColumn = (item: TableMapper) => {
    const { value: columnName, width } = item
    const style: CSSProperties = {
      width: width != null ? "auto" : "100%",
      minWidth: width,
      maxWidth: width,
    }
    const defaultProps = { style, key: columnName }
    switch (columnName) {
      case "name":
        return (
          <Column {...defaultProps}>
            <Tooltip lineClamp={2} text={name}>
              {name}
            </Tooltip>
          </Column>
        )

      case "categoryName":
        return (
          <Column {...defaultProps}>
            {category.displayName || category.name}
          </Column>
        )

      case "avgSearchVolume":
        return (
          <Column {...defaultProps}>{numberWithCommas(avgSearchVolume)}</Column>
        )

      // case "funnelStage":
      //   return <Column {...defaultProps}>{funnelStage || "-"}</Column>

      case "graphPoints":
        return (
          <Column {...defaultProps} color="gray">
            {graphData.length > 0 ? (
              <span onClick={openChart} className={styles.chartContainer}>
                <SimpleAreaChart data={graphData} />
              </span>
            ) : (
              <span className={styles.centered}>(No data)</span>
            )}
          </Column>
        )

      case "avgCpc":
        return (
          <Column {...defaultProps}>{`$${numberWithCommas(avgCpc, 2)}`}</Column>
        )

      case "estimate":
        return (
          <Column {...defaultProps}>{`$${numberWithCommas(estimate)}`}</Column>
        )

      case "budgetFraction":
        return (
          <Column {...defaultProps}>
            <NumberFormat
              key="fraction"
              decimalScale={2}
              customInput={TextField}
              thousandSeparator
              suffix="%"
              value={budgetFraction}
              className={styles.shotInput}
              onValueChange={(values: NumberFormatValues) =>
                onChange(id, "budgetFraction", values.value)
              }
            />
          </Column>
        )

      case "competition":
        return (
          <Column {...defaultProps}>{formatCompetition(competition)}</Column>
        )

      case "relevanceScope":
        return (
          <Column {...defaultProps}>
            {relevanceScope != null
              ? percentageFormatter(relevanceScope * 100)
              : "-"}
          </Column>
        )

      case "distance":
        return (
          <Column {...defaultProps}>
            {distance >= 0 ? numberWithCommas(distance, 2) : "-"}
          </Column>
        )

      case "action": {
        const handleClick = accountId
          ? () => onStatusClick(accountId, id)
          : undefined

        return (
          <Column {...defaultProps} className={styles.buttonContainer}>
            {inUse ? (
              <>Active</>
            ) : (
              <Button
                variant={active ? "ghost" : "outlined"}
                color="primary"
                fullWidth
                onClick={handleClick}
              >
                {active ? "Remove" : "Add"}
              </Button>
            )}
          </Column>
        )
      }

      default:
        return <Column {...defaultProps} />
    }
  }

  return <div className={styles.body}>{mapper.map(renderColumn)}</div>
})

export default Row

interface ColumnProps extends ITypographyProps {}

const Column: React.FC<ColumnProps> = ({ children, className, ...rest }) => (
  <Typography
    type="h3"
    className={clsx(styles.column, styles.rowColumn, className)}
    {...rest}
  >
    {children}
  </Typography>
)
