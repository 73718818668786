import React from "react"

import Icon from "@components/ui/Icon/Icon"
import BaseListItem, { ListItemProps } from "@components/ui/DropDown/ListItem"

export interface DatePeriodMenuItemProps extends ListItemProps {
  isSelected?: boolean
}

const DatePeriodMenuItem = React.forwardRef<
  HTMLLIElement,
  DatePeriodMenuItemProps
>(({ children, isSelected, ...restProps }, ref) => (
  <BaseListItem
    {...restProps}
    ref={ref}
    after={
      <Icon
        name="calendar"
        primary={isSelected}
        style={{ fontSize: "1.6em" }}
      />
    }
  >
    {children}
  </BaseListItem>
))

export default DatePeriodMenuItem
