import * as yup from "yup"
import moment from "moment"
import { pMaxBillingOptions } from "@framework/constants/manageCampaign"
import { PMaxCampaignEntity } from "@framework/types/account"
import { printDate } from "@utils/date"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"

const billingOptions = pMaxBillingOptions

export const nameValidationSchema = yup.object({
  name: yup.string().label("Campaign Name").required(),
})

const infoValidationSchema = yup
  .object({
    startDate: yup
      .date()
      .label("Start Date")
      .typeError("Should be a valid Date")
      .transform((it) => (it != null ? it : undefined))
      // .test(
      //   "start-in-past",
      //   `Start Date should be ${printDate(new Date())} or later`,
      //   (startDate) => {
      //     if (!startDate) return true
      //     return moment().startOf("day").unix() < moment(startDate).unix()
      //   }
      // )
      .default(new Date())
      .required(),

    withEndDate: yup.bool().default(false),

    endDate: yup
      .date()
      .label("End Date")
      .transform((it) => (it != null ? it : undefined))
      .typeError("Should be a valid Date")
      .test(
        "end-before-start",
        "End Date can not be before Start Date",
        (endDate, ctx) => {
          const { startDate } = ctx.parent
          if (!endDate || !startDate) return true
          return moment(endDate).unix() > moment(startDate).unix()
        }
      )
      .when(["withEndDate"], {
        is: (target: boolean) => !!target,
        then: (scheme) => scheme.required(),
        otherwise: (scheme) => scheme.strip().optional(),
      }),
  })
  .concat(nameValidationSchema)

const budgetValidationSchema = yup.object({
  amount: yup.number().label("Budget").positive().required().default(undefined),
})

const biddingValidationSchema = yup.object({
  biddingStrategyType: yup
    .string()
    .label("Bidding strategy")
    .oneOf(billingOptions)
    .transform((v) => (billingOptions.includes(v) ? v : undefined))
    .required(),

  targetValue: yup
    .number()
    .label("Target value")
    .when(["target"], {
      is: (target: boolean) => !!target,
      otherwise: (scheme) => scheme.strip().optional(),
      then: (scheme) =>
        scheme.required().test("Should be positive", (v) => Number(v) >= 0),
    }),

  target: yup.bool().default(false),
})

export type CampaignInfoValidationSchema = yup.InferType<
  typeof infoValidationSchema
>

export const biddingAndBudgetValidationSchema = yup
  .object()
  .concat(infoValidationSchema)
  .concat(budgetValidationSchema)
  .concat(biddingValidationSchema)

export const validateBudgetBiddingForm = (form: any) =>
  biddingAndBudgetValidationSchema.isValidSync(form)

export const transformBiddingForm = (
  campaign: Partial<PMaxCampaignEntity>
): Partial<any> => {
  const form: Partial<any> = {}

  if (campaign?.biddingStrategyType) {
    form.biddingStrategyType = campaign.biddingStrategyType
  }

  if (form.biddingStrategyType === "MAXIMIZE_CONVERSION_VALUE") {
    const potentialTargetValue = (campaign?.campaignBiddingStrategy as any)
      ?.TargetRoas
    form.targetValue =
      potentialTargetValue != null ? potentialTargetValue * 100 : undefined
  }

  if (form.biddingStrategyType === "MAXIMIZE_CONVERSIONS")
    form.targetValue =
      (campaign?.campaignBiddingStrategy as any)?.TargetCpa ?? undefined

  form.target = form.targetValue != null

  return biddingValidationSchema.cast(form, { stripUnknown: true })
}

export const transformPeriodData = (
  campaignData: Partial<PMaxCampaignEntity>
) => {
  const { endDate, startDate, ...rest } = campaignData
  return infoValidationSchema.cast(
    {
      startDate: startDate ? moment(startDate).toDate() : undefined,
      endDate: endDate ? moment(endDate).toDate() : undefined,
      withEndDate: endDate != null,
      ...rest,
    },
    { stripUnknown: true }
  )
}
export const transformBiddingAndBudgetForm = (
  campaign: Partial<PMaxCampaignEntity>
) => ({
  ...budgetValidationSchema.cast(campaign, { stripUnknown: true }),
  ...transformBiddingForm(campaign),
  ...transformPeriodData(campaign),
})

export const transformCreateFormForAPI = (campaignData: any) => {
  const { amount, target, ...rest } = campaignData
  return {
    budget_amount: amount,
    isDraftMode: true,
    ...rest,
  }
}

export const transformUpdateFormForAPI = (campaignData: any) => {
  const { amount, target, biddingStrategyType, targetValue, ...rest } =
    campaignData

  return {
    budget_amount: amount,
    ...transformBiddingData(biddingStrategyType, targetValue),
    ...rest,
  }
}

const transformBiddingData = (
  biddingStrategyType: string | undefined,
  targetValue: number | undefined
) => {
  if (biddingStrategyType == null) return {}
  return {
    bidding: {
      biddingStrategyType,
      targetValue,
    },
  }
}

export const transformPeriodDataFormForAPI = (
  campaignData: Partial<CampaignInfoValidationSchema>
) => {
  const { endDate, startDate, withEndDate, ...rest } = campaignData

  return {
    startDate: printDate(startDate, YYYY_MM_DD_DATE_FORMAT),
    endDate: withEndDate ? printDate(endDate, YYYY_MM_DD_DATE_FORMAT) : null,
    ...rest,
  }
}
