import { action, observable } from "mobx"

import { ID } from "@framework/types/types"
import accountCampaignService from "@services/account-campaign.service"

export class CreateCampaignsStore {
  createdCampaigns: {
    campaignsNumber: number
    timestamp: number
  } | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  @action createCampaigns = async (accountId: ID) => {
    try {
      this.createdCampaigns = null
      this.isLoading = true
      this.error = null
      const response = await accountCampaignService.createCampaign(accountId)

      this.createdCampaigns = {
        campaignsNumber: response.data.data.Campaigns,
        timestamp: Date.now(),
      }
    } catch (error: any) {
      this.error = error.response.data.error ?? "Unexpected Error"
    } finally {
      this.isLoading = false
    }
    return this.error
  }
}

export default CreateCampaignsStore
