import React from "react"

import BaseField, { BaseFieldProps } from "./BaseField"

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
  valid?: BaseFieldProps["valid"]
  before?: BaseFieldProps["before"]
  after?: BaseFieldProps["after"]
  edited?: BaseFieldProps["edited"]
}

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      error,
      value,
      valid,
      disabled = false,
      before,
      after,
      edited,
      className,
      ...rest
    },
    ref
  ) => (
    <BaseField
      ref={ref}
      disabled={disabled}
      valid={valid}
      error={!!error}
      before={before}
      after={after}
      className={className}
      edited={edited}
    >
      <input value={value} {...rest} />
    </BaseField>
  )
)

export default TextField
