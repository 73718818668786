import React from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { Option } from "@framework/types/types"
import Label from "@components/ui/CheckBox/Label/Label"
import InputLabel from "@components/ui/Label/Label"
import Radio from "@components/ui/CheckBox/Radio/Radio"
import useToggle from "@framework/hooks/useToggle"
import { Button } from "@components/ui/Button"
import { useFormikContext } from "formik"

const targetOptions: Option[] = [
  {
    value: "PRESENCE",
    label:
      "Presence or interest: People in, regularly in, who’ve shown interest in your targeted locations (recommended)",
  },
  {
    value: "PRESENCE_OR_INTEREST",
    label: "Presence: People in or regularly in your targeted locations",
  },
]

export const ExtendedSettings: React.FC = observer(() => {
  const formik = useFormikContext<any>()

  const { isOpened, handleToggle } = useToggle()

  return (
    <Stack gutter="16">
      <Button
        variant="ghost"
        color="primary"
        size="small"
        startIcon={{ name: "arrow-right", rotateAngle: isOpened ? 90 : -90 }}
        onClick={handleToggle}
      >
        More settings
      </Button>

      {isOpened && (
        <Stack gutter="16">
          <InputLabel label="Target">
            {targetOptions.map((item) => (
              <Label text={item.label}>
                <Radio
                  id={item.value}
                  name={item.value}
                  value={formik.values.positiveGeoTargetType}
                  onChange={() =>
                    formik.setFieldValue("positiveGeoTargetType", item.value)
                  }
                />
              </Label>
            ))}
          </InputLabel>
        </Stack>
      )}
    </Stack>
  )
})

export default ExtendedSettings
