/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { SortByType } from "@framework/types/types"

import styles from "./Table.module.scss"

export type HeaderColumnProps = {
  name: string
  value?: SortByType
  onSort?: (name: string) => void
}

const HeaderColumn: React.FC<HeaderColumnProps> = ({
  name,
  value,
  children,
  onSort,
}) => (
  <Typography
    type="inherit"
    color="inherit"
    upperCase
    onClick={() => onSort?.(name)}
    className={styles.headerColumn}
  >
    {children}
    <Icon
      name={value?.value === name ? "arrow-right" : "none"}
      rotateAngle={value?.direction ? -90 : 90}
    />
  </Typography>
)

export default HeaderColumn
