/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"

import Box from "@components/ui/Box/Box"
import TextField from "@components/ui/TextField/TextField"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import Label from "@components/ui/Label/Label"
import {
  mbNoData,
  moneyFormatter,
  precisePercentageFormatter,
} from "@services/utils"
import FormNumberField from "@framework/prototypes/FormNumberField"

import styles from "./WebsiteKPIs.module.scss"

type WebsiteKPIsProps = {
  className?: string
}

const WebsiteKPIs: React.FC<WebsiteKPIsProps> = observer(({ className }) => {
  const { manageProfileStore, accountStore } = useStore()
  const { isEdit } = manageProfileStore
  const { account } = accountStore

  return (
    <Box className={clsx(styles.root, className)}>
      <Typography type="h2">Website KPIs</Typography>
      {isEdit ? (
        <form className={styles.container}>
          <FormNumberField
            label="Average order value"
            name="aov"
            decimalScale={0}
            customInput={TextField}
            thousandSeparator
            prefix="$"
          />

          <FormNumberField
            label="Conversion rate"
            name="conversionRate"
            decimalScale={2}
            customInput={TextField}
            thousandSeparator
            suffix="%"
          />

          <FormNumberField
            label="Reservation Click %"
            name="resClick"
            decimalScale={2}
            customInput={TextField}
            thousandSeparator
            suffix="%"
          />

          <FormNumberField
            label="ROAS $"
            name="roas"
            decimalScale={0}
            customInput={TextField}
            thousandSeparator
            prefix="$"
          />
        </form>
      ) : (
        account && (
          <div className={styles.container}>
            <Label label="Average order value">
              <Typography>{mbNoData(account.aov, moneyFormatter)}</Typography>
            </Label>
            <Label label="Conversion rate">
              <Typography>
                {mbNoData(account.conversionRate, precisePercentageFormatter)}
              </Typography>
            </Label>
            <Label label="Reservation Click %">
              <Typography>
                {mbNoData(account.resClick, precisePercentageFormatter)}
              </Typography>
            </Label>
            <Label label="ROAS $">
              <Typography>{mbNoData(account.roas, moneyFormatter)}</Typography>
            </Label>
          </div>
        )
      )}
    </Box>
  )
})

export default WebsiteKPIs
