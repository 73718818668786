import clsx from "clsx"
import React, { CSSProperties } from "react"

import { LabelMapper } from "@framework/types/types"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"

import styles from "./WaterfallTable.module.scss"

export interface TypedTipProps {
  data: LabelMapper
}

export const TypedTip: React.FC<TypedTipProps> = React.memo(
  ({ data = campaignEntityDescription.campaign }) => (
    <span
      title={data.label}
      className={clsx(styles.nose)}
      style={{ "--group-color": data.color } as CSSProperties}
    >
      {data.shortName}
    </span>
  )
)

export default TypedTip
