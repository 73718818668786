/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import clsx from "clsx"
import { useField, useFormikContext } from "formik"
import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo, useState } from "react"
import { NumberFormatProps } from "react-number-format"
import ReactSelect, { components, MultiValueRemoveProps } from "react-select"
import * as R from "ramda"
import throttle from "lodash/throttle"

import DatePicker from "@components/ui/DatePicker/DatePicker"
import Icon from "@components/ui/Icon/Icon"
import TextAreaField from "@components/ui/TextAreaField/TextAreaField"
import TextField, { TextFieldProps } from "@components/ui/TextField/TextField"
import { idArrayToOptions } from "@components/utils/arrayUtils"
import { ProductCategoryOptionData } from "@framework/types/account"
import { Option } from "@framework/types/types"
import { useStore } from "@store/index"
import { transformCategoryOption } from "@store/product-feed/utils"
import FormTextField from "@framework/prototypes/FormTextField"
import FormNumberField from "@framework/prototypes/FormNumberField"
import useOptionList from "@framework/prototypes/useOptionList"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import { EditCellProps } from "../types"

import styles from "./FormFiledColumn.module.scss"

export const DropDownCell: React.FC<EditCellProps> = observer(
  ({ data, name }) => {
    const values = data?.[name]?.values
    const options = useMemo(() => idArrayToOptions(values ?? []), [values])

    const [field, _, helpers] = useField<any>(name)

    const optionsProps = useOptionList(options)

    return (
      <SimpleSelect
        {...optionsProps}
        value={field.value}
        onSelect={(value) => helpers.setValue(value)}
      />
    )
  }
)

export const findSamePath = (path1: any[], path2: any[]) => {
  const path3 = []
  for (let i = 0; i < path1.length; i += 1) {
    if (path1[i] === path2[i]) path3[i] = path1[i]
    else break
  }
  return path3
}

const MultiValueRemove: React.FC<MultiValueRemoveProps> = (props) => {
  const [over, setOver] = useState(false)
  return (
    <div
      style={{ display: "contents" }}
      onMouseOver={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
    >
      <components.MultiValueRemove {...props}>
        <Icon
          name={over ? "cross" : "arrow-right"}
          style={{ fontSize: "0.8em" }}
        />
      </components.MultiValueRemove>
    </div>
  )
}

export const MAX_OPTIONS = 100

export const CategoryDropDownCell: React.FC<EditCellProps> = observer(
  ({ name }) => {
    const [search, setSearch] = useState("")

    const {
      accountStore: { accountId },
      productFeedStore: { options },
    } = useStore()
    const formik = useFormikContext<any>()

    const loadOptions = useMemo(
      () => throttle(options.loadCategories, 700),
      [options.loadCategories]
    )

    const path = formik.values[name]

    const rootOptions = options.categories

    const pathList = useMemo(() => {
      if (typeof path !== "string" || !path) return []
      const values = path.split(" > ").filter((v: string) => !!v)
      return idArrayToOptions(values, (x) => x)
    }, [path])

    const query = useMemo(
      () => pathList.map(({ label }) => label).join(" > "),
      [pathList]
    )

    const searchedOptions = useMemo(() => {
      const prefix = pathList.map(({ label }) => label).join(" > ")
      const transducer = R.compose(
        R.when(
          () => prefix.length > 0,
          R.filter(
            (category: ProductCategoryOptionData) =>
              prefix.length < category.google_category.length &&
              category.google_category.startsWith(prefix)
          )
        ),
        R.when(
          () => search.length > 0,
          R.filter((category: ProductCategoryOptionData) =>
            category.google_category
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          )
        ),
        R.take<ProductCategoryOptionData>(MAX_OPTIONS)
      )
      return R.map(transformCategoryOption, R.into([], transducer)(rootOptions))
    }, [pathList, rootOptions, search])

    useEffect(() => {
      if (accountId) loadOptions(accountId, `${query} > ${search}`)
    }, [accountId, search, query])

    const onSelect = (items: any) => {
      const newValue =
        items.length < pathList.length
          ? (findSamePath(items, pathList) as Option[])
              .map(({ label }) => label)
              .join(" > ")
          : items?.[items.length - 1]?.label ?? ""
      formik.setFieldValue(name, newValue)
    }

    const isLoading = options.isCategoriesLoading

    return (
      <ReactSelect
        className={styles.selector}
        value={pathList}
        options={searchedOptions}
        isMulti
        components={{ MultiValueRemove }}
        closeMenuOnSelect={false}
        isClearable={false}
        onInputChange={(e) => setSearch(e)}
        onChange={onSelect}
        noOptionsMessage={() =>
          isLoading ? "Searching..." : "No options found"
        }
      />
    )
  }
)

export const NumberCell: React.FC<
  EditCellProps & NumberFormatProps & TextFieldProps
> = React.memo(({ name, value, ...rest }) => (
  <FormNumberField customInput={TextField} name={name} {...rest} />
))

export const TextCell: React.FC<EditCellProps> = React.memo(({ name }) => (
  <FormTextField name={name} />
))

export const TextAreaCell: React.FC<EditCellProps> = React.memo(({ name }) => {
  const formik = useFormikContext<any>()
  return (
    <TextAreaField
      name={name}
      className={clsx(styles.field, styles.textAreaField)}
      value={formik.values[name]}
      onInput={formik.handleChange}
      withValidate={!!formik.touched[name]}
      onBlur={formik.handleBlur}
      error={formik.touched[name] ? formik.errors[name]?.toString() : ""}
    />
  )
})

export const DatePickerCell: React.FC<EditCellProps> = React.memo(
  ({ name }) => {
    const formik = useFormikContext<any>()
    return (
      <DatePicker
        className={styles.field}
        name={name}
        defValue={formik.values[name]}
        onBlur={formik.handleBlur}
        // error={formik.touched[name] ? formik.errors[name] : ""}
      />
    )
  }
)
