import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { useStore } from "@store/index"
import {
  UpdateAdGroupRequestType,
  UpdateAdRequestType,
  UpdateCampaignRequestType,
  UpdateKeywordRequestType,
} from "@store/manage-campaigns/manage-campaigns"
import { CampaignStatusType } from "@framework/types/account"
import { BaseCellProps } from "@components/ui/WaterfallTable/types"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { ID } from "@framework/types/types"
import StatusCell from "./StatusCell"

export type CampaignStatusProps = {
  value: CampaignStatusType
  entityType: "campaign"
  campaignId: ID
}

export type AdGroupStatusProps = {
  value: CampaignStatusType
  entityType: "adGroup"
  adgroupName: string
  adGroupId: ID
}

export type KeywordStatusProps = {
  value: CampaignStatusType
  entityType: "keyword"
  adGroupId: ID
  keywordId: ID
}

export type AdStatusProps = {
  value: CampaignStatusType
  entityType: "ad"
  adGroupId: ID
  adId: ID
}

export type StatusProps = BaseCellProps & {
  value: CampaignStatusType
  entityType: "campaign" | "adGroup" | "ad" | "keyword"
  adgroupName?: string
  campaignId?: ID
  adGroupId?: ID
  keywordId?: ID
  adId?: ID
  editable?: boolean
} & (
    | CampaignStatusProps
    | AdGroupStatusProps
    | KeywordStatusProps
    | AdStatusProps
  )

export const Status: React.FC<StatusProps> = observer(
  ({
    value: status,
    campaignId,
    adGroupId,
    keywordId,
    adId,
    entityType,
    adgroupName,
    editable = true,
  }) => {
    const alert = useAlert()
    const { manageCampaignStore, accountStore } = useStore()
    const {
      updateLoading,
      updateCampaign,
      updateAdGroup,
      updateKeyword,
      updateAd,
    } = manageCampaignStore
    const { accountId } = accountStore

    const [value, setValue] = React.useState<CampaignStatusType>(status)

    const handleSubmit = (status: CampaignStatusType) => {
      if (accountId) {
        const data:
          | UpdateCampaignRequestType
          | UpdateAdGroupRequestType
          | UpdateAdRequestType
          | UpdateKeywordRequestType = {
          status,
          type: "STATUS",
        }

        const oldValue = value
        setValue(status)

        const promise =
          (entityType === "campaign" &&
            updateCampaign(
              accountId,
              campaignId,
              data as UpdateCampaignRequestType
            )) ||
          (entityType === "adGroup" &&
            updateAdGroup(accountId, adGroupId, {
              ...data,
              adgroupName,
            } as UpdateAdGroupRequestType)) ||
          (entityType === "keyword" &&
            updateKeyword(
              accountId,
              adGroupId,
              keywordId,
              data as UpdateKeywordRequestType
            )) ||
          (entityType === "ad" &&
            updateAd(
              accountId,
              adGroupId,
              adId,
              data as UpdateAdRequestType
            )) ||
          null

        promise?.then((error) => {
          if (error) {
            setValue(oldValue)
            alert.error(
              <AlertMessage
                title="Failed to update status"
                description={error}
              />
            )
          } else alert.success("Status was successfully changed")
        })
      }
    }

    React.useEffect(() => {
      setValue(status)
    }, [status])

    return (
      <StatusCell
        loading={updateLoading}
        editable={editable}
        status={value}
        onChange={handleSubmit}
      />
    )
  }
)

export default Status
