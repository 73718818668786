import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"

import { Channel } from "@store/account-linking/account-linking"

import styles from "./ConnectedChannels.module.scss"

export type ConnectedChannelsProps = {
  className?: string
  items?: Channel[]
}

const ConnectedChannels: React.FC<ConnectedChannelsProps> = ({
  className,
  items,
}) => (
  <div className={clsx(styles.root, className)}>
    {items?.length ? (
      items.map(({ value, iconSrc }) => (
        <img src={iconSrc} alt={value} key={value} className={styles.badge} />
      ))
    ) : (
      <Typography type="h5" color="dark">
        No Channels
      </Typography>
    )}
  </div>
)

export default ConnectedChannels
