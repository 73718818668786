/* eslint-disable react/no-array-index-key */
import React, { useMemo, useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import { accountTableMapper } from "./MockData"
import TableRowBox from "./Table"

import styles from "./CampaignSegmentationTable.module.scss"

type CampaignSegmentationProps = {
  className?: string
}

const CampaignSegmentation: React.FC<CampaignSegmentationProps> = observer(
  ({ className }) => {
    const history = useHistory()

    const {
      clientStore: { clientId },
      clientDashboardStore: { clientsPerformanceReport, loadingPerformance },
      accountStore: { clientAccounts: accountList, loadSortedAccountList },
    } = useStore()

    useEffect(() => {
      if (clientId) loadSortedAccountList(clientId)
    }, [loadSortedAccountList, clientId])

    const accounts = useMemo(
      () =>
        clientsPerformanceReport ? Object.keys(clientsPerformanceReport) : null,
      [clientsPerformanceReport]
    )

    const accountsMap = useMemo(
      () =>
        accountList?.length && accounts?.length
          ? Object.fromEntries(
              accountList
                .filter(({ profileName }) => accounts.includes(profileName))
                .map(({ profileName, id }) => [
                  profileName,
                  `/account/${id}/dashboard`,
                ])
            )
          : {},
      [accountList, accounts]
    )

    return (
      <div className={clsx(styles.root, className)}>
        <div className={clsx(styles.header)}>
          <Typography
            key="title"
            className={styles.item}
            type="subhead1"
            color="gray"
          />
          {accountTableMapper.map(({ label }, idx) => (
            <Typography
              key={idx}
              className={styles.item}
              type="subhead1"
              color="gray"
            >
              {label.toUpperCase()}
            </Typography>
          ))}
        </div>
        <Loader isLoading={loadingPerformance} />
        <div className={styles.bodyWrapper}>
          {(clientsPerformanceReport &&
            accounts?.map((account) => {
              const report = clientsPerformanceReport[account]
              const link = accountsMap[account]
              if (!report) return null
              return (
                <TableRowBox
                  onTitleClick={link ? () => history.push(link) : undefined}
                  title={account}
                  dataset={report}
                  mapper={accountTableMapper}
                />
              )
            })) ??
            (!loadingPerformance && <NoData />)}
        </div>
      </div>
    )
  }
)

export default CampaignSegmentation
