import React, { useState } from "react"
import clsx from "clsx"

import { monthFullNamesMapper } from "@framework/constants/common"
import { initArrayByLength } from "@components/utils/numberUtils"
import { mbNoData, preciseMoneyFormatter } from "@services/utils"
import { MapperOption } from "@components/forms/CheckbookForm/types"
import RowWithLabel from "@components/forms/CheckbookForm/RowWithLabel/RowWithLabel"
import SimpleColumn from "@components/forms/CheckbookForm/Column/SimpleColumn/SimpleColumn"
import AmountFiledColumn from "@components/forms/CheckbookForm/AmountFiledColumn/AmountFiledColumn"
import ToggleButtonColumn from "@components/forms/CheckbookForm/Column/ButtonColumn/ButtonColumn"
import HeaderColumn from "@components/forms/CheckbookForm/Column/HeaderColumn/HeaderColumn"

import styles from "./CheckbookTable.module.scss"

export type CheckbookTableProps = {
  className?: string
  mapper: MapperOption[]
  data: any
  isEdit?: boolean
}

export const EditableCheckbookTable: React.FC<CheckbookTableProps> = ({
  className,
  data,
  mapper,
  isEdit = false,
}) => {
  const [support, setSupport] = useState<{ [key: string]: boolean }>(
    Object.fromEntries(initArrayByLength(12).map((index) => [index, false]))
  )

  const handleToggleSupport = (monthId: number) => {
    if (support[monthId] !== undefined) {
      setSupport((form) => ({ ...form, [monthId]: !support[monthId] }))
    }
  }

  const { planned, actual } = data

  return (
    <div className={clsx(styles.root, className)}>
      <RowWithLabel key="header" mapper={mapper}>
        {({ name, index }) => (
          <HeaderColumn key={name}>{monthFullNamesMapper[index]}</HeaderColumn>
        )}
      </RowWithLabel>

      <RowWithLabel label="Actual" key="actual" mapper={mapper} withBorder>
        {({ name }) => (
          <SimpleColumn key={name}>
            {mbNoData(actual?.[name], preciseMoneyFormatter)}
          </SimpleColumn>
        )}
      </RowWithLabel>

      <RowWithLabel
        label="Planned"
        key="planned"
        mapper={mapper}
        withBorder={!isEdit}
      >
        {({ name }) =>
          isEdit ? (
            <AmountFiledColumn
              name={name}
              initialValue={planned?.[name]}
              key={name}
            />
          ) : (
            <SimpleColumn key={name}>
              {mbNoData(planned?.[name], preciseMoneyFormatter)}
            </SimpleColumn>
          )
        }
      </RowWithLabel>

      <RowWithLabel key="footer" mapper={mapper}>
        {({ index, name }) => (
          <ToggleButtonColumn
            checked={support[index]}
            onClick={() => handleToggleSupport(index)}
            key={name}
          >
            {support[index] ? "Support needed" : "Add support"}
          </ToggleButtonColumn>
        )}
      </RowWithLabel>
    </div>
  )
}

export default EditableCheckbookTable
