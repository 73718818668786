import React, { FC } from "react"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import Image from "@components/ui/Image/Image"
import ImageTopper from "@components/ui/Image/Topper"
import { observer } from "mobx-react-lite"
import AssetLogosSidebar from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupLogosSidebar"
import { AssetGroupEntity } from "@framework/types/manageCampaign"
import styles from "./Assets.module.scss"

interface LogosProps {
  logos: AssetGroupEntity["logoAssets"]
}
const Logos: FC<LogosProps> = observer(({ logos }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const logosArray = logos && logos.length ? logos : []
  return (
    <div className={styles.section}>
      <Typography className={styles.headTitle} type="h2">
        Logos
      </Typography>
      <Typography type="subtitle" color="gray">
        {logosArray.length} logos added
      </Typography>
      <div className={styles.images}>
        {logosArray.slice(0, 3).map((logo, i) => (
          <Image
            src={logo.url}
            className={styles.image}
            key={`${i + 1}-logo`}
          />
        ))}
        <ImageTopper className={styles.image} message="+">
          <Image key="last-image" />
        </ImageTopper>
      </div>

      <Button
        color="primary"
        before={<Icon name="plus" key="plus" />}
        variant="ghost"
        className={styles.buttonIcon}
        onClick={() => setIsOpen(true)}
      >
        Logo
      </Button>
      <AssetLogosSidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
})

export default Logos
