import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import ConfirmModal from "@components/modals/ConfirmModal/ConfirmModal"
import { countedFormat } from "@utils/stringUtils"

type PublishToGMCConfirmModalProps = {
  isOpen: boolean
  onClose: () => void
}

const PublishToGMCConfirmModal: React.FC<PublishToGMCConfirmModalProps> =
  observer(({ isOpen, onClose }) => {
    const {
      accountStore: { accountId },
      productFeedStore: { GMC, list },
    } = useStore()

    const handlePublishAll = async () => {
      if (!accountId) return
      onClose()
      GMC.publishProducts(accountId)
    }

    const { isAllSelected, selectedCount } = list.selected

    return (
      <ConfirmModal
        title="Publishing at GMC"
        message={`Do you want to publish ${
          isAllSelected && selectedCount > 1 ? "all" : selectedCount
        } ${countedFormat(
          "product",
          selectedCount
        )} at Google Merchant Center?`}
        isOpen={isOpen}
        onClose={onClose}
        applyText="Publish"
        onApply={handlePublishAll}
      />
    )
  })

export default PublishToGMCConfirmModal
