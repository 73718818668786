import clsx from "clsx"
import React, { useEffect, useRef, useState } from "react"

import isEqual from "lodash/isEqual"

import styles from "./ScrollShadow.module.scss"

interface ScrollShadowProps extends React.HTMLProps<HTMLDivElement> {
  showShadow?: boolean
  className?: string
  children?: (ref: any) => React.ReactNode
}

const ScrollShadow: React.FC<ScrollShadowProps> = ({
  showShadow = true,
  children,
  className,
  ...props
}) => {
  const nodeRef = useRef<HTMLElement | null>(null)
  const [shadow, setShadow] = useState<any>(null)

  useEffect(() => {
    const node = nodeRef.current
    if (!node) return undefined
    if (!showShadow) return setShadow(null)

    const handleScroll = async () => {
      const {
        scrollTop = 0,
        scrollLeft = 0,
        offsetWidth = 0,
        scrollWidth = 0,
        offsetHeight = 0,
        scrollHeight = 0,
      } = node

      const shadows = {
        bottom: scrollTop > 1,
        top: scrollTop + offsetHeight < scrollHeight - 1,
        right: scrollLeft > 1,
        left: scrollLeft + offsetWidth < scrollWidth - 1,
      }

      setShadow((prev: any) => {
        if (!!prev && isEqual(shadows, prev)) return prev
        return shadows
      })
    }

    node.addEventListener("scroll", handleScroll)
    handleScroll()
    return () => node.removeEventListener("scroll", handleScroll)
  }, [showShadow])

  return (
    <div className={clsx(styles.root, className)} {...props}>
      {children?.(nodeRef)}
      {shadow?.top && <span className={styles.topShadow} />}
      {shadow?.right && <span className={styles.rightShadow} />}
      {shadow?.bottom && <span className={styles.bottomShadow} />}
      {shadow?.left && <span className={styles.leftShadow} />}
    </div>
  )
}

export default ScrollShadow
