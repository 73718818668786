import React from "react"
import { SortByType } from "@framework/types/types"
import {
  searchPMaxProducts,
  sortPMaxProducts,
} from "@pages/ManageCampaign/utils"
import { PMaxProductReportType } from "@framework/types/account"

const emptyArray: PMaxProductReportType[] = []

export const useSortedProducts = (config: {
  products: PMaxProductReportType[] | null
  sortBy: SortByType
  searchQuery?: string
}) => {
  const { products, sortBy, searchQuery } = config

  const [inProgress, setInProgress] = React.useState(false)

  const [data, setData] = React.useState<PMaxProductReportType[]>(emptyArray)

  const update = async (
    list: PMaxProductReportType[],
    sortBy?: SortByType,
    searchQuery?: string
  ) => {
    setInProgress(true)

    const filtered = await searchProducts(list, searchQuery)
    const res = await sortProducts(filtered, sortBy)

    setData(res)
    setInProgress(false)
  }

  React.useEffect(() => {
    update(products ?? emptyArray, sortBy, searchQuery)
  }, [products, sortBy, searchQuery])

  return { data, inProgress }
}

const sortProducts = async (
  list: PMaxProductReportType[],
  sortBy?: SortByType
) => {
  if (list.length === 0 || sortBy == null) return list

  const res = await sortPMaxProducts(list, sortBy)
  return res
}

const searchProducts = async (
  list: PMaxProductReportType[],
  searchQuery: string = ""
) => {
  const res = await searchPMaxProducts(list, searchQuery)
  return res
}

export default useSortedProducts
