import { action, computed, observable } from "mobx"

export class SelectableList<TOption extends string = string> {
  @observable multiselect: boolean

  constructor(config?: {
    options?: TOption[]
    selected?: string[]
    multiselect?: boolean
  }) {
    this.optionsMap = new Map(config?.options?.map((it) => [it, it]))
    this.selected = new Set(config?.selected)
    this.multiselect = config?.multiselect ?? true
  }

  @observable optionsMap: Map<string, TOption>

  @observable selected: Set<string>

  @computed get isAnySelected() {
    return this.selected.size > 0
  }

  @computed get selectedList() {
    return Array.from(this.selected)
  }

  @computed get isAllSelected() {
    return this.isAnySelected && this.selected.size === this.options.length
  }

  @computed get options(): TOption[] {
    return Array.from(this.optionsMap.values())
  }

  @computed get selectedOptions(): TOption[] {
    return this.selectedList
      .map((it) => this.optionsMap.get(it))
      .filter(Boolean) as TOption[]
  }

  @action select = (value: string, select = !this.selected.has(value)) => {
    if (select) {
      if (!this.multiselect) this.selected.clear()
      this.selected.add(value)
    } else this.selected.delete(value)
  }

  @action selectAll = (select = this.isAllSelected || !this.multiselect) => {
    if (select) this.selected = new Set()
    else this.selected = new Set(this.options)
  }

  @action setOptions = (options: TOption[]) => {
    this.optionsMap = new Map(options.map((it) => [it, it]))
  }

  @action setSelected = (selected: string[]) => {
    this.selected = new Set(selected)
  }

  @action updateOption = (option: TOption, select = false) => {
    this.optionsMap.set(option, option)
    this.select(option, select)
  }
}

export default SelectableList
