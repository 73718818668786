import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"

import { useAlert } from "react-alert"
import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { ID } from "@framework/types/types"
import { useStore } from "@store/index"

import styles from "./RemoveMemberModal.module.sass"

type RemoveMemberModalProps = {
  userId: ID
  onSubmit: () => void
  onClose: () => void
}

const RemoveMemberModal: React.FC<RemoveMemberModalProps> = observer(
  ({ userId, onClose, onSubmit }) => {
    const {
      settings: { userList },
    } = useStore()

    const alert = useAlert()

    const user = useMemo(() => userList.getUserById(userId), [userId, userList])

    const nameNode = (
      <b>{user?.Name ? `${user.Name} ${user.Surname}` : "(NO NAME)"}</b>
    )

    const handleRemove = async () => {
      const error = await userList.deleteUser(userId)
      if (error) {
        alert.error(<AlertMessage title="Oops!" description={error} />)
        onClose()
        return
      }
      alert.success(
        <AlertMessage
          title="Alright!"
          description={<>User {nameNode} was successfully deleted</>}
        />
      )
      userList.load()
      onSubmit()
    }

    useEffect(() => {
      if (!user) onClose()
    }, [user])

    return (
      <Modal isOpen onClose={onClose} hideHeader>
        <Stack gutter="24" align="flex-end" className={styles.root}>
          <Stack gutter="16">
            <Typography type="h2">Remove member</Typography>
            <Typography type="body1">
              Do you want to remove member {nameNode} from company?
            </Typography>
          </Stack>
          <Stack direction="row" justify="flex-end">
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleRemove}>Remove</Button>
          </Stack>
        </Stack>
      </Modal>
    )
  }
)

export default RemoveMemberModal
