/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { mbNoData, parseAddress, moneyFormatter } from "@services/utils"
import { Option } from "@framework/types/types"
import Label from "@components/ui/Label/Label"
import { AccountInfoType } from "@framework/types/manageProfile"
import countryList from "@components/utils/countryList"
import FormNumberField from "@framework/prototypes/FormNumberField"
import FormTextField from "@framework/prototypes/FormTextField"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import useOptionList from "@framework/prototypes/useOptionList"

import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import styles from "./Information.module.scss"

type InformationProps = {
  className?: string
}

const Information: React.FC<InformationProps> = observer(({ className }) => {
  const {
    manageProfileStore: {
      industries,
      industriesLoading,
      isEdit,
      fetchIndustries,
    },
    accountStore: { account },
  } = useStore()

  const currIndustry: Option | null = useMemo(
    () =>
      industries?.find(
        (item) => item.value === account?.industryId.toString()
      ) ?? null,
    [account?.industryId, industries]
  )

  useEffect(() => {
    if (!industries && !industriesLoading) fetchIndustries()
  }, [industries])

  const countries = useMemo(
    () =>
      countryList.map((countryName) => ({
        value: countryName,
        label: countryName,
      })),
    []
  )

  const formik = useFormikContext<AccountInfoType>()

  const inductryOptions = useOptionList(industries ?? [])
  const countryOptions = useOptionSearchList({ list: countries ?? [] })

  return (
    <Box className={clsx(styles.root, className)}>
      <Typography type="h2">Account Information</Typography>
      {isEdit ? (
        <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
          <FormTextField label="Name" name="name" />

          <FormTextField label="Address" name="street" />
          <div className={styles.fieldsWrapper}>
            <FormTextField label="City" name="city" />

            <FormTextField label="State" name="state" />
          </div>
          <div className={styles.fieldsWrapper}>
            <FormNumberField label="Zip Code" name="postal" />

            <FormSimpleSelectField
              label="Country"
              name="country"
              withSearch
              {...countryOptions}
            />
          </div>
          <div className={styles.fieldsWrapper}>
            <FormTextField label="Website" name="website" />

            <FormSimpleSelectField
              label="Industry"
              name="industryId"
              {...inductryOptions}
            />
          </div>
          <FormNumberField
            label="Contract Yearly Ad Spend"
            name="yearlyAdBudget"
            decimalScale={0}
            thousandSeparator
            prefix="$"
          />
        </form>
      ) : (
        account && (
          <div className={styles.container}>
            <div>
              <Label label="Name" className={styles.wrapper}>
                <Typography>{mbNoData(account.name)}</Typography>
              </Label>
              <Label label="Address" className={styles.wrapper}>
                <Typography>
                  {mbNoData(account ? parseAddress(account) : null)}
                </Typography>
              </Label>
            </div>

            <div>
              <Label label="Website" className={styles.wrapper}>
                <Typography>{mbNoData(account.website)}</Typography>
              </Label>

              <Label label="Industry" className={styles.wrapper}>
                <Typography>
                  {mbNoData(currIndustry?.label ?? account.Industry?.name)}
                </Typography>
              </Label>
            </div>

            <Label label="Contract Yearly Ad Spend" className={styles.wrapper}>
              <Typography>
                {mbNoData(account.yearlyAdBudget, moneyFormatter)}
              </Typography>
            </Label>
          </div>
        )
      )}
    </Box>
  )
})

export default Information
