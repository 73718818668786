/* eslint-disable no-unused-expressions */
import { computed, observable } from "mobx"
import RootStore from "../RootStore"

// temp
const blackList: string[] = [
  "test1@example.com",
  "test2@example.com",
  "test3@example.com",
]

export class AppStore {
  @observable rootStore: RootStore

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @computed get showEverything() {
    const email = this.rootStore.authStore.user?.Email
    if (!email || blackList.includes(email.toLowerCase())) return false
    return true
  }

  @computed get allowedEmail() {
    const email = this.rootStore.authStore.user?.Email ?? ""
    return (contactEmail: string) =>
      contactEmail.toLowerCase().startsWith(email.split("@")[0])
  }
}

export default AppStore
