/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useCallback, useEffect, useState } from "react"

import Button from "@components/ui/Button/Button"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import {
  MIN_ROWS,
  headlineKeys,
  descriptionKeys,
  MAX_DESC_ROWS,
  MAX_ADS_HL_ROWS,
} from "@store/manageProfile/manageProfile"
import EditableList from "./components/EditableList/EditableList"
import Preview from "./components/Preview/Preview"
import useAdPreviewGenerator from "./hooks/useAdPreviewGenerator"

import styles from "./AdCopy.module.scss"

type AdCopyProps = {
  className?: string
}

const AdCopy: React.FC<AdCopyProps> = observer(({ className }) => {
  const [headlines, setHeadlines] = useState<[string, string][]>([])
  const [descriptions, setDescriptions] = useState<[string, string][]>([])
  const [isTouched, setTouched] = useState(false)

  const { manageProfileStore, accountStore } = useStore()
  const {
    isEdit,
    defaultHeadlineEntries,
    defaultDescriptionEntries,
    setSubmitCallback,
    submitFormCallback,
    setIsEdit,
  } = manageProfileStore

  const { account, accountId, accountUpdating, updateAccountInfo } =
    accountStore

  useEffect(() => {
    setSubmitCallback()
    setTouched(false)
    if (defaultHeadlineEntries && defaultDescriptionEntries) {
      setHeadlines(defaultHeadlineEntries)
      setDescriptions(defaultDescriptionEntries)
    }
  }, [defaultHeadlineEntries, defaultDescriptionEntries, isEdit])

  const handleSubmit = useCallback(() => {
    if (accountId) {
      const allHeadlines = headlineKeys.map((adsHl, idx) => [
        adsHl,
        headlines[idx]?.[1] ?? "",
      ])
      const allDescriptions = descriptionKeys.map((adsDesc, idx) => [
        adsDesc,
        descriptions[idx]?.[1] ?? "",
      ])
      const data = {
        ...Object.fromEntries(allHeadlines),
        ...Object.fromEntries(allDescriptions),
      }
      updateAccountInfo(accountId, data)
    }
  }, [accountId, headlines, descriptions, updateAccountInfo])

  useEffect(() => {
    const v1 =
      descriptions.filter(([, text]) => text.length > 0).length >= MIN_ROWS
    const v2 =
      headlines.filter(([, text]) => text.length > 0).length >= MIN_ROWS
    if (v1 && v2 && isTouched) setSubmitCallback(handleSubmit)
  }, [headlines, descriptions, isTouched, setSubmitCallback, handleSubmit])

  useEffect(() => {
    if (accountUpdating && submitFormCallback) setIsEdit(false)
  }, [accountUpdating])

  const items = useAdPreviewGenerator({
    headlines,
    descriptions,
    website: account?.website ?? "N/A",
  })

  const getChangeHandler = (setList: any) => (list: [string, string][]) => {
    setTouched(true)
    setList(list)
  }

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.header}>
        <Typography type="h2">Ad copy</Typography>
        <Button className={styles.circleButton}>?</Button>
      </div>

      <div className={styles.body}>
        <div className={styles.list}>
          <EditableList
            title="Headlines (*3 are required)"
            label="Headline Text"
            maxLength={30}
            defaultList={headlines}
            onChange={getChangeHandler(setHeadlines)}
            isEdit={isEdit}
            minRows={MIN_ROWS}
            maxRows={MAX_ADS_HL_ROWS}
            className={styles.section}
          />

          <EditableList
            title="Descriptions (*3 are required)"
            label="Description Text"
            maxLength={90}
            defaultList={descriptions}
            onChange={getChangeHandler(setDescriptions)}
            isEdit={isEdit}
            minRows={MIN_ROWS}
            maxRows={MAX_DESC_ROWS}
            className={styles.section}
          />
        </div>

        <div className={styles.preview}>
          <Preview adList={items} />
        </div>
      </div>
    </div>
  )
})

export default AdCopy
