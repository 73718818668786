import * as yup from "yup"

const createAssetGroupSchema = yup.object({
  headline: yup
    .array()
    .of(yup.string())
    .max(15)
    .min(3)
    .transform((value) => (value === null ? undefined : value))
    .default([]),
  longHeadline: yup
    .array()
    .of(yup.string())
    .max(5)
    .min(1)
    .transform((value) => (value === null ? undefined : value))
    .default([]),
  description: yup
    .array()
    .of(yup.string())
    .max(5)
    .min(2)
    .transform((value) => (value === null ? undefined : value))
    .default([]),
  businessName: yup
    .string()
    .min(10)
    .transform((value) => (value === null ? undefined : value))
    .required(),
  finalUrl: yup.string().required(),
  name: yup
    .string()
    .transform((value) => (value === null ? undefined : value))
    .required(),
})

export default createAssetGroupSchema
