import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import Stack from "@components/ui/Stack/Stack"
import { moneyFormatter } from "@services/utils"
import {
  useOpportunitiesController,
  useOpportunitiesStore,
} from "@store/opportunities/provider"

import styles from "./KeywordsShoppingCartControl.module.scss"

export type KeywordsShoppingCartControlProps = {
  className?: string
}

export const KeywordsShoppingCartControl: React.FC<KeywordsShoppingCartControlProps> =
  observer(({ className }) => {
    const { shoppingCart, view } = useOpportunitiesStore()
    const { opportunities } = useOpportunitiesController()

    const isSubmittable =
      shoppingCart.selected.size > 0 &&
      opportunities.publishCampaign != null &&
      opportunities != null

    return (
      <Stack
        direction="row"
        gutter="32"
        align="center"
        className={clsx(styles.root, className)}
      >
        <Stack gutter="0" className={styles.details}>
          <Typography>
            Selected keywords:{" "}
            <Typography weight="bold" inline>
              {shoppingCart.totalSelected}
            </Typography>
          </Typography>
          <Typography>
            Total Initial Ad Spend:{" "}
            <Typography weight="bold" inline>
              {moneyFormatter(shoppingCart.totalAmount)}
            </Typography>
          </Typography>
        </Stack>
        <Stack direction="row" gutter="24" className={styles.control}>
          <Button
            size="big"
            color="secondary"
            startIcon={{ name: "menu" }}
            onClick={view.toggleSidebar}
          >
            Details
          </Button>
          <Button
            size="big"
            onClick={opportunities.publishCampaign}
            disabled={!isSubmittable}
          >
            Confirm and Create Campaigns
          </Button>
        </Stack>
      </Stack>
    )
  })

export default KeywordsShoppingCartControl
