import { Color, Plugin } from "chart.js"
import uniqueId from "lodash/uniqueId"
import { drawLine } from "../utils"

export const makeLevelLinePlugin = (
  level: number,
  color: Color
): Plugin<"bar"> => ({
  id: `rateLevelLine_${uniqueId()}`,
  afterDatasetDraw(chart) {
    const { ctx, scales, chartArea } = chart

    const pixelY = scales.y.getPixelForValue(level)

    drawLine(ctx, chartArea.left, pixelY, chartArea.right, pixelY, color)
  },
})

export default null
