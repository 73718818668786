import { observable, action } from "mobx"

import { ID } from "@framework/types/types"

export type EditAdCopyTaskType = "delete" | "edit"

export interface AdCopyTask {
  readonly type: EditAdCopyTaskType

  readonly adCopyId: ID

  readonly id: string
}

export class EditAdCopyStore {
  @observable task: AdCopyTask | null = null

  @action setAdCopyTask = (task: AdCopyTask) => {
    this.task = task
  }

  @action closeTask = () => {
    this.task = null
  }
}

export default EditAdCopyStore
