import { observer } from "mobx-react-lite"
import React from "react"

import Tooltip from "@components/ui/Tooltip/Tooltip"
import Typography from "@components/ui/Typography/Typography"
import { UserData } from "@framework/types/user"
import { useStore } from "@store/index"
import { ColumnProps } from "./UserTable"

const renderFullName = (data: UserData) => {
  if (data.Name)
    return (
      <>
        {data.Name} {data.Surname}
      </>
    )
  return <>NO NAME</>
}

export const UserMetaColumn: React.FC<ColumnProps<UserData>> = observer(
  ({ mapper, data = {} as UserData }) => {
    const { userStore } = useStore()
    const isCurrentUser = userStore.data?.ID === data.ID
    const node = (
      <>
        <Typography>
          {renderFullName(data)} {isCurrentUser ? "(You)" : ""}
        </Typography>
        <Typography type="body2" color="gray">
          {data.Email}
        </Typography>
      </>
    )

    return (
      <Tooltip text={node} overflowWidth="auto" lineClamp={2}>
        {node}
      </Tooltip>
    )
  }
)

export default UserMetaColumn
