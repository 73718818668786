/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from "react"
import clsx from "clsx"

import { observer } from "mobx-react-lite"
import {
  ProductFeedInstance,
  ProductTempChangeData,
} from "@framework/types/account"
import Box from "@components/ui/Box/Box"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import OnClickTooltip from "@components/ui/Tooltip/TooltipContainer/OnClickTooltipContainer"
import ProductChange from "@store/product-feed/ProductChange"
import { countChangesCount } from "@store/product-feed/utils"
import ChangesBadge from "../ChangesBadge/ChangesBadge"
import HintPopUp from "../../ProductFeed/HintPopUp/HintPopUp"
import {
  ColumnMapper,
  GetValueCallbackType,
  makeDescriptor,
  RenderCallbackType,
  ViewType,
} from "../../ProductFeed/types"
import SimpleTextCell from "../../ProductFeed/CompareFeedSidebar/TableCell/SimpleTextCell"
import RowGroup from "./RowGroup"

import styles from "./Row.module.scss"

export const DefaultCellDescriptor = makeDescriptor(SimpleTextCell)

interface RowProps<T extends object = any, C extends ProductChange = any> {
  staticBefore?: number
  staticAfter?: number
  mapper: ColumnMapper<T>[]
  data: T
  change: C
  viewType?: ViewType
  selected?: boolean
  onSelect?: () => void
  onClick?: () => void
  defaultRenderCallback?: RenderCallbackType<T>
  defaultGetValueCallback?: GetValueCallbackType<T>
}

const Row: React.FC<any> = observer(
  <T extends ProductFeedInstance, C extends ProductChange>({
    mapper,
    staticBefore = 0,
    data,
    change,
    viewType = "simple",
    selected = false,
    onSelect,
    onClick,
  }: RowProps<T, C>) => {
    if (Number.isNaN(staticBefore))
      throw new Error("staticBefore filed is not a number")
    if (staticBefore < 0) throw new Error("staticBefore filed should be >= 0 ")
    const beforeMapper = mapper.slice(0, staticBefore)
    const middleMapper = mapper.slice(staticBefore, mapper.length)

    const { data: changeData, isLoading: changeLoading } = change

    const renderDataCell = (item: ColumnMapper) => {
      if (!data) return <Skeleton count={2} />
      return <Cell rowMapper={item} data={data} />
    }

    const renderCompareCell = (item: ColumnMapper) => {
      if (changeLoading) return <Skeleton count={2} />
      if (changeData && data) {
        return <Cell rowMapper={item} data={data} changes={changeData} />
      }
      return renderDataCell(item)
    }

    const renderColumn = (item: ColumnMapper) => (
      <>
        <div className={styles.cell}>{renderCompareCell(item)}</div>
        {viewType === "comparison" && (
          <div className={styles.cell}>{renderDataCell(item)}</div>
        )}
      </>
    )

    const changesList = useMemo(
      () => (changeData != null ? countChangesCount(data, changeData) : []),
      [changeData, data]
    )

    const changesNum = changeData?.meta?.changes ?? 0

    return (
      <Box className={styles.row}>
        <div className={styles.before}>
          <CheckBox checked={selected} onChange={onSelect} />
        </div>
        <RowGroup
          name="before"
          mapper={beforeMapper}
          className={clsx(styles.group, styles.staticGroup, {
            [styles.clickable]: onClick,
          })}
          renderCell={renderColumn}
          onClick={onClick}
          key="before"
        />
        <RowGroup
          name="middle"
          mapper={middleMapper}
          className={styles.group}
          renderCell={renderColumn}
          withShadow
          key="middle"
        />
        <div className={styles.after}>
          <ChangesBadge total={changesNum} changes={changesList} />
        </div>
      </Box>
    )
  }
)

interface CellProps {
  rowMapper: ColumnMapper
  data: ProductFeedInstance
  changes?: ProductTempChangeData
}

const Cell: React.FC<CellProps> = observer(({ rowMapper, data, changes }) => {
  const props = {
    name: rowMapper.name,
    data,
  }

  if (changes) {
    const { Component, defaultProps } = rowMapper.view ?? DefaultCellDescriptor
    return (
      // <OnClickTooltip placement="right" toolTip={<HintPopUp />}>
      <Component {...defaultProps} {...props} changeData={changes} />
      // </OnClickTooltip>
    )
  }

  const { Component, defaultProps } = rowMapper.view ?? DefaultCellDescriptor
  return <Component {...defaultProps} {...props} />
})

export default Row
