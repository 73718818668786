import { action, observable } from "mobx"
import React, { useContext } from "react"

import { SortByType } from "@framework/types/types"

export class TableSortContextStore {
  @observable sortBy: SortByType

  constructor(config?: { defaultValue?: SortByType }) {
    this.sortBy = config?.defaultValue ?? {
      value: "none",
      direction: false,
    }
  }

  @action sort = (name: string) => {
    const shouldOff = this.sortBy.value === name && this.sortBy.direction
    this.sortBy = {
      value: shouldOff ? "none" : name,
      direction: this.sortBy.value === name,
    }
  }

  @action setValue = (value: SortByType) => {
    this.sortBy = { ...value }
  }
}

export const TableSortContext =
  React.createContext<TableSortContextStore | null>(null)

export const useTableSortContext = () => {
  const context = useContext(TableSortContext)
  if (context == null) throw new Error("Can't find TableSortContext")
  return context
}
