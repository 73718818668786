import React from "react"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import Stack from "@components/ui/Stack/Stack"
import { ID } from "@framework/types/types"
import { LocationsValidationSchema } from "@store/manage-campaigns/validation/settings"
import { useController, useStore } from "@store/index"
import LocationTable from "./LocationTable"
import SearchLocation from "./SearchLocation/SearchLocation"

import styles from "./index.module.scss"

export const OtherLocations: React.FC = observer(() => {
  const { locationsStore: store } = useStore()
  const { editPMaxCampaignController: controller } = useController()

  const formik = useFormikContext<LocationsValidationSchema>()

  const target = formik.values.locationIds
  const exclude = formik.values.excludeLocationIds

  const handleSelect = (action: string, id: ID) => {
    if (action === "target")
      formik.setFieldValue("locationIds", [...target, id])

    if (action === "exclude")
      formik.setFieldValue("excludeLocationIds", [...exclude, id])
  }

  const handleUnselect = (action: "target" | "exclude") => (id: ID) => {
    const prev = (action === "target" ? target : exclude).filter(
      (prevID) => prevID.toString() !== id.toString()
    )

    if (action === "target") formik.setFieldValue("locationIds", prev)
    if (action === "exclude") formik.setFieldValue("excludeLocationIds", prev)
  }

  const handleUnselectAll = (action: string) => () => {
    if (action === "target") formik.setFieldValue("locationIds", [])
    if (action === "exclude") formik.setFieldValue("excludeLocationIds", [])
  }

  const checkLocations = (locationIds: ID[]) => {
    locationIds.filter((id) => store.getById(id) == null)
    if (locationIds.length) controller.loadLocations(locationIds)
  }

  React.useEffect(() => {
    checkLocations(target)
  }, [target])

  React.useEffect(() => {
    checkLocations(exclude)
  }, [exclude])

  return (
    <Stack gutter="16" className={styles.subsection}>
      {target.length > 0 && (
        <LocationTable
          title="Targeted locations"
          list={target}
          onDelete={handleUnselect("target")}
          onDeleteAll={handleUnselectAll("target")}
        />
      )}
      {exclude.length > 0 && (
        <LocationTable
          title="Excluded locations"
          list={exclude}
          onDelete={handleUnselect("exclude")}
          onDeleteAll={handleUnselectAll("exclude")}
        />
      )}
      <Stack align="center" direction="row" gutter="8">
        <SearchLocation className={styles.input} onSelect={handleSelect} />
        {/* <Button variant="ghost">Advanced search</Button> */}
      </Stack>
    </Stack>
  )
})

export default OtherLocations
