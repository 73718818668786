import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import Typography from "@components/ui/Typography/Typography"
import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import Templates from "@components/ui/Templates"

import styles from "./BaseSidebarFilter.module.scss"

export interface BaseSidebarFilterProps {
  title?: string
  isOpened?: boolean
  hasChanges?: boolean
  canReset?: boolean
  switchSocket?: React.ReactNode
  onCancel?: React.MouseEventHandler
  onApply?: React.MouseEventHandler
  onReset?: React.MouseEventHandler
  onClose?: React.MouseEventHandler
}

export const BaseSidebarFilter: React.FC<BaseSidebarFilterProps> = observer(
  ({
    title = "Filter",
    isOpened = false,
    hasChanges = true,
    canReset = true,
    switchSocket,
    children,
    onApply,
    onClose,
    onCancel,
    onReset,
  }) => (
    <SidebarContainer
      className={styles.root}
      isOpen={isOpened}
      onClose={onClose}
    >
      <Templates.Header
        offset="none"
        className={styles.header}
        left={
          <Typography type="h2" bold>
            {title}
          </Typography>
        }
        right={
          <Button
            onClick={onReset}
            startIcon={{ name: "reset" }}
            color="secondary"
            disabled={!canReset}
          >
            Reset all
          </Button>
        }
      />

      <div className={styles.body}>
        {switchSocket != null && (
          <div className={styles.navContainer}>{switchSocket}</div>
        )}
        <ScrollContainer className={styles.bodyContainer} type="secondary">
          {children}
        </ScrollContainer>
      </div>

      <Templates.Header
        offset="none"
        className={styles.footer}
        left={
          <Button color="secondary" onClick={onCancel} disabled={!hasChanges}>
            Cancel
          </Button>
        }
        right={
          <Button color="primary" onClick={onApply} disabled={!hasChanges}>
            Apply
          </Button>
        }
      />
    </SidebarContainer>
  )
)

export default BaseSidebarFilter
