import React, { useEffect, useLayoutEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useHistory, useParams } from "react-router-dom"

import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"
import BackButton from "@framework/prototypes/BackButton"
import StatusCard, {
  StatusCardProps,
} from "@components/ui/KPIsWidget/StatusCard/StatusCard"
import {
  useOpportunitiesController,
  useOpportunitiesStore,
} from "@store/opportunities/provider"
import { RecommendedKeyword } from "@framework/types/opportunities"
import { preciseMoneyFormatter } from "@services/utils"
import { numberWithCommas } from "@utils/numberUtils"
import PlaceholderLoader from "@components/ui/Loader/PlaceholderLoader"
import config from "@root/config"
import { useStore } from "@store/index"
import NoData from "@components/ui/NoData/NoData"
import { ID } from "@framework/types/types"
import AllQuestionsSection from "./components/AllQuestionsSection"
import StatisticsSection from "./components/StatisticsSection"
import { KeywordManagementContextProvider } from "./components/KeywordQuestion/KeywordManagementContext"
import KeywordSelect from "./KeywordSelect"
import { KeywordInsightsContextProvider } from "./KeywordInsightsContext"

import styles from "./Insights.module.scss"

const hideAnswers = !!config.DISABLE_OPPORTUNITIES_ANSWERS

type InsightsProps = {}

const InsightsPage: React.FC<InsightsProps> = observer(() => {
  const { kid } = useParams<{ kid: string }>()

  const history = useHistory()

  const { opportunities } = useOpportunitiesController()
  const { keywordsList } = useOpportunitiesStore()

  const keywords = keywordsList.recommendedKeywords
  const keyword = keywordsList.getKeywordById(kid)

  const {
    accountStore: { accountId },
  } = useStore()

  const highestPerformKeyword = React.useMemo(
    () =>
      keywords.reduce<RecommendedKeyword | null>((acc, it) => {
        if (
          Math.max(it.avgSearchVolume ?? 0, acc?.avgSearchVolume ?? 0) ===
          it.avgSearchVolume
        )
          return it
        return acc
      }, null),
    [keywords]
  )

  const changeKeyword = (keywordId: ID) =>
    history.replace(
      `/google-ads/${accountId}/opportunities/insights/${keywordId}`
    )

  const handleBack = () =>
    history.replace(`/google-ads/${accountId}/opportunities`)

  useLayoutEffect(() => {
    if (accountId) {
      opportunities.loadRecommendedKeywords(accountId)
    }
  }, [accountId])

  useEffect(() => {
    if (kid || !accountId) return

    if (keywordsList.isLoading) return

    if (highestPerformKeyword == null) {
      handleBack()
      return
    }

    changeKeyword(highestPerformKeyword.id)
  }, [kid, accountId, highestPerformKeyword, keywordsList.isLoading])

  const statistic = useMemo(() => extractStatistic(keyword ?? {}), [keyword])

  return (
    <div className={styles.root}>
      <ServiceDownInterceptor />

      <Templates.TripleHeader
        left={
          <>
            <BackButton onClick={handleBack} />
            <KeywordSelect value={kid} onSelect={changeKeyword} />
          </>
        }
      >
        <Typography type="h1" weight="bold">
          Attribute Opportunities
        </Typography>
      </Templates.TripleHeader>

      {keyword == null ? (
        keywordsList.isLoading ? (
          <PlaceholderLoader />
        ) : (
          <NoData>Attribute was deleted or never existed</NoData>
        )
      ) : (
        <KeywordInsightsContextProvider keyword={keyword} key={kid}>
          <KeywordManagementContextProvider>
            <div className={styles.preview}>
              {statistic.map((it) => (
                <StatusCard {...it} key={it.label} />
              ))}
            </div>

            <StatisticsSection keyword={keyword} />

            <AllQuestionsSection keyword={keyword} />
          </KeywordManagementContextProvider>
        </KeywordInsightsContextProvider>
      )}
    </div>
  )
})

export default InsightsPage

const extractStatistic = (
  keyword: Partial<RecommendedKeyword> = {}
): StatusCardProps[] => [
  {
    label: "Search volume",
    children: numberWithCommas(keyword?.avgSearchVolume ?? 0),
    icon: "search",
  },
  {
    label: "Search difficulty",
    children: numberWithCommas((keyword?.competition ?? 0) * 100),
    icon: "loader",
  },
  {
    label: "Cost per Click (CPC)",
    children: preciseMoneyFormatter(keyword?.avgCpc ?? 0),
    icon: "money-dollar-box",
  },
]
