/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import _chunk from "lodash/chunk"
import StackGrid from "react-stack-grid"

import { useStore } from "@store/index"
import AttributesList from "../AttributesList/AttributesList"

import styles from "./AttributesInfo.module.scss"

type AttributesInfoProps = {
  className?: string
}

const AttributesInfo: React.FC<AttributesInfoProps> = observer(
  ({ className }) => {
    const { manageProfileStore } = useStore()
    const { categories, attributes, assignedAttributes } = manageProfileStore

    const assignedAttributeGroups = useMemo(() => {
      if (attributes) {
        return Object.fromEntries(
          Object.entries(attributes).map(([key, list]) => [
            key,
            list.filter((attr) => !!assignedAttributes?.has(attr.id)),
          ])
        )
      }
      return null
    }, [assignedAttributes, attributes])

    return (
      <div className={clsx(styles.root, className)}>
        {categories && (
          <StackGrid
            columnWidth="50%"
            gutterWidth={30}
            gutterHeight={24}
            duration={0}
          >
            {categories.map((category) => (
              <AttributesList
                maxColumns={2}
                className={styles.list}
                attributes={assignedAttributeGroups?.[category.id] ?? []}
                title={category.displayName || category.name}
                key={category.id}
              />
            ))}
          </StackGrid>
        )}
      </div>
    )
  }
)

export default AttributesInfo
