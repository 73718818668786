import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Table from "@components/ui/Table/Table"
import { mbNoData, moneyFormatter } from "@services/utils"
import { useEditorFormRedirect } from "@pages/PMax/ActiveSectionContext"
import Cortege from "./Cortege"

import styles from "./FormSummary.module.scss"

export const getBillingStrategy = (value?: string) => {
  if (!value) return null
  return (
    billingStrategyDescMap[value as keyof typeof billingStrategyDescMap] ??
    value
  )
}
const billingStrategyDescMap: Record<string, React.ReactNode> = {
  MAXIMIZE_CONVERSION_VALUE: "Conversion Value (Target ROAS)",
  MAXIMIZE_CONVERSIONS: "Conversions (Target CPA)",
}
export const BudgetBiddingTable = observer(() => {
  const { editPMaxCampaignStore: store } = useStore()

  const editBudgetForm = useEditorFormRedirect("budget", "budget")
  const editBiddingForm = useEditorFormRedirect("budget", "bidding")

  return (
    <Stack gutter="16" direction="column">
      <Typography type="h2">Budget and bidding</Typography>
      <Box className={styles.section}>
        <Table>
          <Cortege label="Budget" onEdit={editBudgetForm}>
            {mbNoData(store.data.amount, moneyFormatter)}/day
          </Cortege>

          <Cortege label="Bidding" onEdit={editBiddingForm}>
            Maximize{" "}
            {mbNoData(store.data.biddingStrategyType, getBillingStrategy)}
          </Cortege>
        </Table>
      </Box>
    </Stack>
  )
})

export default BudgetBiddingTable
