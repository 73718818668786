import React from "react"
import clsx from "clsx"

import Templates from "../Templates"
import Icon from "../Icon/Icon"
import BasicButton from "../Button/BasicButton"
import Typography, { ITypographyProps } from "../Typography/Typography"

import styles from "./CollapsableHeader.module.sass"

export interface CollapsableProps {
  open?: boolean
  className?: string
  rootClassName?: string
  label: React.ReactNode
  type?: ITypographyProps["type"]
  weight?: ITypographyProps["weight"]
  onClick?: React.MouseEventHandler
}

export const CollapsibleHeader: React.FC<CollapsableProps> = ({
  open = true,
  label,
  className,
  rootClassName,
  type = "h2",
  weight,
  onClick,
}) => (
  <BasicButton
    variant="ghost"
    className={clsx(styles.root, rootClassName)}
    onClick={onClick}
  >
    <Typography type={type} weight={weight}>
      <Templates.Header
        offset="none"
        left={label}
        right={<Icon name="arrow-right" rotateAngle={open ? -90 : 90} />}
        className={className}
      />
    </Typography>
  </BasicButton>
)

export default React.memo(CollapsibleHeader)
