import * as yup from "yup"

const editAssetGroupSchema = yup.object({
  headline: yup
    .array()
    .of(yup.string())
    .max(15)
    .min(3)
    .transform((value) => (value === null ? undefined : value))
    .default([]),
  longHeadline: yup
    .array()
    .of(yup.string())
    .max(5)
    .min(1)
    .transform((value) => (value === null ? undefined : value))
    .default([]),
  description: yup
    .array()
    .of(yup.string())
    .max(5)
    .min(2)
    .transform((value) => (value === null ? undefined : value))
    .default([]),
  businessName: yup
    .string()
    .min(10)
    .transform((value) => (value === null ? undefined : value))
    .required(),
  finalUrl: yup.string().required(),
  siteLinks: yup
    .array()
    .of(
      yup.object().shape(
        {
          description1: yup.string().when("description2", {
            is: (desc: string) => !!desc,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema,
          }),
          description2: yup.string().when("description1", {
            is: (desc: string) => !!desc,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema,
          }),
          // startDate: yup.string(),
          // endDate: yup.string(),
          linkText: yup.string(),
          finalUrl: yup.string().required(),
        },
        [["description2", "description1"]]
      )
    )
    .max(20),
  youtubeVideos: yup
    .array()
    .of(yup.object({ url: yup.string().required() }))
    .max(5)
    .transform((value) => (value === null ? undefined : value)),
})

export default editAssetGroupSchema
