import { observer } from "mobx-react-lite"
import React from "react"

import { Option } from "@framework/types/types"
import { useStore } from "@store/index"
import FilterSection from "@components/prototypes/BaseSidebarFilter/FilterSection"
import FilterList from "@components/prototypes/BaseSidebarFilter/FilterList"

interface SimpleFilterProps {}

const SimpleFilter: React.FC<SimpleFilterProps> = observer(() => {
  const {
    productFeedStore: {
      filter: {
        filters: { byAttributes: filter },
      },
    },
  } = useStore()

  const createSelectClick = (filterName: string) => (option: Option) =>
    filter.setTempFilter(filterName, option)

  return (
    <>
      {filter.filterOptions.map(({ name, label, options }) => (
        <FilterSection
          label={`${label} • ${filter.filtersSelected(name)}`}
          key={name}
        >
          <FilterList
            list={options}
            isSelected={filter.isSelected(name)}
            onSelect={createSelectClick(name)}
          />
        </FilterSection>
      ))}
    </>
  )
})

export default SimpleFilter
