import LogoutConfirmModal from "@components/modals/ConfirmModal/LogoutConfirmModal"
import { observer } from "mobx-react-lite"
import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import Button from "@components/ui/Button/Button"
import ContextMenu from "@components/ui/ContextMenu/ContextMenu"
import useToggle from "@framework/hooks/useToggle"
import { Option } from "@framework/types/types"
import { useStore } from "@store/index"

import styles from "./ProfileMenu.module.scss"

const optionsSmall: Option[] = [
  { value: "settings", label: "Settings", icon: "gear" },
  { value: "logout", label: "Log Out", icon: "share-circle" },
]

export interface ProfileMenuProps {}

export const ProfileMenu: React.FC<ProfileMenuProps> = () => {
  const history = useHistory()
  const { authStore } = useStore()

  const name = authStore.fullName

  const { isOpened: isLogout, setOpened: showLogout } = useToggle()

  const rootNode = useRef<HTMLDivElement>(null)
  const [opened, setOpen] = useState(false)
  const handleClick = (e: MouseEvent) => {
    if (rootNode.current && rootNode.current.contains(e.target as Node)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const handlePick = (option: Option) => {
    if (option.value === "logout") showLogout(true)
    if (option.value === "settings") history.push("/settings")
  }

  return (
    <div ref={rootNode} className={styles.root}>
      <Button
        variant="ghost"
        color="primary"
        onClick={() => setOpen((value) => !value)}
        startIcon={{ name: "user", primary: true }}
        endIcon={{
          name: "arrow-right",
          className: styles.arrowIcon,
          rotateAngle: opened ? -90 : 90,
        }}
        fit
      >
        {name}
      </Button>
      {opened && (
        <div className={styles.menuContainer}>
          <ContextMenu options={optionsSmall} onClick={handlePick} />
        </div>
      )}

      <LogoutConfirmModal isOpen={isLogout} onClose={() => showLogout(false)} />
    </div>
  )
}

export default observer(ProfileMenu)
