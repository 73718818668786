/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import Label from "@components/ui/Label/Label"
import { mbNoData } from "@services/utils"
import FormNumberField from "@framework/prototypes/FormNumberField"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { AccountProfileFormData } from "@utils/validators"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import { AccountGoalSettingsOption } from "@framework/types/account"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import GoalPicker from "./GoalPicker"

import styles from "./Goals.module.scss"

type GoalsProps = {
  className?: string
}

const Goals: React.FC<GoalsProps> = observer(({ className }) => {
  const { manageProfileStore, accountStore, accountDashboardStore } = useStore()
  const { isEdit } = manageProfileStore
  const { accountId } = accountStore

  const { goals } = accountDashboardStore

  const formik = useFormikContext<AccountProfileFormData>()

  React.useEffect(() => {
    if (accountId) goals.loadGoalsSettings(accountId)
  }, [accountId])

  const deleteGoal = (goalName: string) => {
    formik.setValues(
      {
        ...formik.values,
        goals: formik.values.goals.filter(
          (it: AccountGoalSettingsOption) => it.type !== goalName
        ),
      },
      true
    )
  }

  const addGoal = (goalName: string) => {
    formik.setValues(
      {
        ...formik.values,
        goals: [...formik.values.goals, { type: goalName, targetValue: "" }],
      },
      false
    )
  }

  const allowedGoals = React.useMemo(() => {
    const existing = formik.values.goals.map(
      (it: AccountGoalSettingsOption) => it.type
    )
    return goals.options?.filter((it) => !existing.includes(it)) ?? []
  }, [formik.values.goals, goals.options])

  const canAddMore = formik.values.goals.length < 3

  const goalsError =
    typeof formik.errors.goals === "string" ? formik.errors.goals : null

  return (
    <Box className={clsx(styles.root, className, { [styles.onEdit]: isEdit })}>
      <Typography type="h2">Goals</Typography>
      <Loader isLoading={goals.isLoading} />

      {isEdit && <Typography color="error100Color">{goalsError}</Typography>}

      {goals.settings == null ? (
        <NoData>Failed to load account goals</NoData>
      ) : isEdit ? (
        <div className={styles.container}>
          {formik.values.goals.map((goal: any, idx: number) => {
            const desc = getMetricDescriptor(goal.type)

            return (
              <FormNumberField
                label={desc.label}
                floatingError
                name={`goals[${idx}].targetValue`}
                thousandSeparator
                decimalScale={desc.unit?.decimalScale ?? 0}
                suffix={desc.unit?.suffix}
                prefix={desc.unit?.prefix}
                after={
                  <IconButton onClick={() => deleteGoal(goal.type)}>
                    <Icon name="trash" />
                  </IconButton>
                }
              />
            )
          })}
          {canAddMore && (
            <GoalPicker
              options={allowedGoals}
              renderLabel={(name) =>
                getMetricDescriptor(name)?.label ?? "Unknown"
              }
              onPick={addGoal}
            />
          )}
        </div>
      ) : goals.settings?.length ? (
        <div className={styles.container}>
          {goals.settings.map((goal) => {
            const desc = getMetricDescriptor(goal.type)
            if (desc == null) return null
            return (
              <Label label={desc.label}>
                <Typography>
                  {mbNoData(goal.targetValue, desc.formatter)}
                </Typography>
              </Label>
            )
          })}
        </div>
      ) : (
        <NoData>No goals added</NoData>
      )}
    </Box>
  )
})

export default Goals
