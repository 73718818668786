/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { AdGroupReportType } from "@framework/types/account"
import { useStore } from "@store/index"
import { ID, SortByType } from "@framework/types/types"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { sortCampaignReports } from "@pages/ManageCampaign/utils"
import { Button } from "@components/ui/Button"
import Icon from "@components/ui/Icon/Icon"
import KeywordRow from "./KeywordRow"
import AdRow from "./AdRow"
import useAddKeywordsModal from "./useAddKeywordsModal"

type AdGroupRowProps = {
  id: number
  data: AdGroupReportType
  adGroupId: number
  editable?: boolean
  sortBy?: SortByType
  mapper: MapperType[]
}

const AdGroupRow: React.FC<AdGroupRowProps> = observer(
  ({ id, data, adGroupId, mapper, editable, sortBy }) => {
    const [opened, setOpened] = React.useState(false)

    const { manageCampaignStore, accountStore } = useStore()
    const { accountId } = accountStore
    const {
      dateFilter,
      adGroupReportLoading,
      updateLoading,
      getAdGroupReport,
      loadAdGroupReportList,
    } = manageCampaignStore

    if (accountId == null) throw new Error("Account required")

    const adGroupReport = getAdGroupReport(accountId, adGroupId)

    React.useEffect(() => {
      if (accountId && adGroupId && opened)
        loadAdGroupReportList(accountId, adGroupId, dateFilter)
    }, [
      accountId,
      adGroupId,
      dateFilter,
      opened,
      updateLoading,
      loadAdGroupReportList,
    ])

    const sortedKeywords = React.useMemo(() => {
      if (!adGroupReport?.Keywords) return []
      if (!sortBy) return adGroupReport.Keywords
      return sortCampaignReports(adGroupReport?.Keywords, sortBy)
    }, [sortBy, adGroupReport])

    const sortedAds = React.useMemo(() => {
      if (!adGroupReport?.Ads || !sortBy) return []
      if (!sortBy) return adGroupReport.Ads
      return sortCampaignReports(adGroupReport?.Ads, sortBy)
    }, [sortBy, adGroupReport])

    return (
      <ClosableRow
        id={id}
        onToggle={setOpened}
        isChildLoading={adGroupReportLoading}
        emptyChildMessage="No Ads or Keywords found"
        component={
          <TypedRow
            label={data.Name}
            data={{ ...data, AdgroupId: data.Id, type: "adGroup", editable }}
            leftTip={<TypedTip data={campaignEntityDescription.groupAd} />}
            mapper={mapper}
          />
        }
      >
        {!!sortedKeywords.length && editable && (
          <AddKeywordRow campaignId={data.CampaignId} adGroupId={id} />
        )}

        {sortedKeywords.map((props) => (
          <KeywordRow
            data={{
              ...props,
              AdgroupId: id,
              AdGroupName: data.Name,
              CampaignId: data.CampaignId,
              CampaignName: "Unknown",
              MatchType: "UNKNOWN",
            }}
            editable={editable}
            mapper={mapper}
            key={`keyword_${props.Id}`}
          />
        ))}

        {sortedAds.map((props) => (
          <AdRow
            data={{
              ...props,
              AdGroupId: id,
              AdGroupName: data.Name,
              CampaignId: data.CampaignId,
              CampaignName: "Unknown",
              AdStatus: "UNKNOWN",
              AdStrength: "",
              AdType: "",
            }}
            editable={editable}
            mapper={mapper}
            key={`ad_${props.Id}`}
          />
        ))}
      </ClosableRow>
    )
  }
)

export default AdGroupRow

const AddKeywordRow: React.FC<{
  adGroupId?: ID
  campaignId?: ID
}> = ({ campaignId, adGroupId }) => {
  const modal = useAddKeywordsModal({
    adGroupId,
    campaignId,
  })

  return (
    <>
      <ClosableRow
        id="add_keyword"
        component={
          <TypedRow
            onClick={modal.open}
            label={
              <Button variant="ghost" before={<Icon name="plus" />}>
                Add Keywords
              </Button>
            }
          />
        }
      />

      {modal.Modal}
    </>
  )
}
