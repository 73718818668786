import React from "react"
import { useFormikContext } from "formik"

import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import Typography from "@components/ui/Typography/Typography"
import { ID } from "@framework/types/types"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import { useStore } from "@store/index"
import { mbNoData } from "@services/utils"
import { numberWithCommas } from "@utils/numberUtils"
import { LocationsValidationSchema } from "@store/manage-campaigns/validation/settings"

import styles from "./SearchLocation.module.scss"

export interface SelectLocationTableProps {
  title: string
  list: ID[]
  onAction?: (action: string, option: ID) => void
}

export const SelectLocationTable: React.FC<SelectLocationTableProps> = ({
  list,
  title,
  onAction,
}) => {
  const { locationsStore: store } = useStore()

  const formik = useFormikContext<LocationsValidationSchema>()

  return (
    <Typography type="body2" color="black100Color" className={styles.table}>
      <Table hideBorder>
        <Row sticky>
          <Column variant="header" width="40%">
            <Typography type="caption3">{title}</Typography>
          </Column>
          <Column variant="header" width="50%" align="end">
            <Typography type="caption3">Reach</Typography>
          </Column>
          <Column variant="header" width="30%" />
        </Row>

        {list.map((id) => {
          const item = store.getById(id)

          const isTarget = formik.values.locationIds.includes(Number(id))
          const isExcluded = formik.values.excludeLocationIds.includes(
            Number(id)
          )
          return (
            <Row hoverable key={id}>
              <Column>{item?.name ?? "Unknown"}</Column>
              <Column align="end">
                {mbNoData(item?.reach, numberWithCommas)}
              </Column>
              <Column>
                <Stack direction="row" gutter="8">
                  <Button
                    disabled={isTarget}
                    size="small"
                    variant="ghost"
                    onClick={() => onAction?.("target", id)}
                  >
                    Target
                  </Button>
                  <Button
                    disabled={isExcluded}
                    size="small"
                    variant="ghost"
                    onClick={() => onAction?.("exclude", id)}
                  >
                    Exclude
                  </Button>
                  {/* <Button size="small" variant="ghost">
                  Nerdy
                </Button> */}
                </Stack>
              </Column>
            </Row>
          )
        })}
      </Table>
    </Typography>
  )
}

export default SelectLocationTable
