import React from "react"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import clsx from "clsx"
import styles from "./CreateCampaignModal.module.sass"

interface OptionCardProps {
  label: string
  description: string
  imageSrc: string
  name: string
  value?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler
}

export const OptionCard: React.FC<OptionCardProps> = ({
  label,
  description,
  imageSrc,
  name,
  value,
  disabled = false,
  onClick,
}) => {
  const isActive = value != null && value === name
  return (
    <Box
      elevation={0}
      className={clsx(styles.option, {
        [styles.active]: isActive,
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <Stack gutter="12" direction="row" align="center">
        <img src={imageSrc} alt="" height={36} width={36} />

        <Stack gutter="4">
          <Typography type="h4">{label}</Typography>
          <Typography type="body2" color="black60Color">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

export default OptionCard
