import moment from "moment"

import { YEAR_MONTH_DAY_KEY_FORMAT } from "@framework/constants/moment-format"
import {
  Condition,
  ConditionFrequencyType,
  TimeConditionFormType,
  BudgetConditionFormType,
  RuleType,
  OperatorType,
  RuleActionType,
  CampaignType,
  ConditionsStatus,
  TimeConditionType,
  BudgetConditionType,
  ConditionType,
  BaseConditionType,
} from "@framework/types/manageCampaign"
import { campaignTypeOptions } from "@pages/Rules/components/EditRule/components/BudgetTypeRoleForm/options"

export const transformConditionsList = (
  list?: Condition[]
): Condition[] | undefined =>
  list?.sort(
    ({ start_date: d1 }, { start_date: d2 }) =>
      moment(d1).unix() - moment(d2).unix()
  )

export const toTimeCondition = (
  id: string,
  item: TimeConditionFormType,
  status: ConditionsStatus
): TimeConditionType => ({
  id,
  trigger: "DATE",
  start_date: moment(item.period[0]).format(YEAR_MONTH_DAY_KEY_FORMAT),
  end_date: moment(item.period[1]).format(YEAR_MONTH_DAY_KEY_FORMAT),
  applied_area: item.appliedArea,
  applied: item.campaignIds,
  status,
})

export const toBudgetCondition = (
  id: string,
  item: BudgetConditionFormType,
  frequency: ConditionFrequencyType,
  status: ConditionsStatus
): BudgetConditionType => ({
  id,
  trigger: "BUDGET",
  operator: item.operator,
  value: item.value,
  action: item.action,
  applied_area: item.appliedArea,
  applied: item.campaignIds,
  frequency,
  status,
})

export const toTypeConditionsList = (list: Condition[]): ConditionType[] =>
  list.map(toTypedCondition)

export const toTypedCondition = (item: Condition): ConditionType => {
  const hasApplied = !!campaignTypeOptions.find(
    ({ value }) => value === item.applied_area
  )
  const basic: BaseConditionType = {
    id: item.id.toString(),
    trigger: item.trigger as RuleType,
    applied_area: hasApplied ? "CUSTOM" : (item.applied_area as CampaignType),
    applied: hasApplied ? item.applied_area.split(",") : [],
    status: "OLD",
  }

  if (basic.trigger === "DATE") {
    return {
      ...basic,
      start_date: moment(item.start_date).format(YEAR_MONTH_DAY_KEY_FORMAT),
      end_date: moment(item.end_date).format(YEAR_MONTH_DAY_KEY_FORMAT),
    }
  }

  return {
    ...basic,
    operator: item.operator as OperatorType,
    value: item.value,
    action: item.action as RuleActionType,
    frequency: item.frequency as ConditionFrequencyType,
  }
}

export default {}
