/* eslint-disable prefer-destructuring */
import { action, observable } from "mobx"
import sortBy from "lodash/sortBy"
import moment from "moment"

import { TSPerfReportMap } from "@framework/types/metrics"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { toTSPerfReportMap } from "@framework/data-transformers/metrics"
import clientService from "@services/client.service"
import { DEF_YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import { periodicityToScaleUnit } from "@components/ui/Charts/LineChart/config"
import { apiDateFormatter } from "@services/utils"
import { ReportFilterRequest } from "./types"

export class PlatformTimeSegmentedPerformanceStore {
  @observable data: TSPerfReportMap = {}

  @observable isLoading = false

  @observable loadingError: string | null = null

  @observable labels: string[] = []

  @action load = async (
    period: [Date, Date],
    periodicity: PerformanceReportPeriodicity,
    request: ReportFilterRequest
  ) => {
    try {
      this.isLoading = true
      this.data = {}
      this.labels = []

      const [labels, data] = await this.loadByPeriod(
        period,
        periodicity,
        request
      )
      this.data = data
      this.labels = sortBy(labels)
    } catch (error) {
      this.loadingError = "Unexpected error"
    } finally {
      this.isLoading = false
    }
    return this.loadingError
  }

  loadByPeriod = async (
    period: [Date, Date],
    periodicity: PerformanceReportPeriodicity,
    request: ReportFilterRequest
  ): Promise<[string[], TSPerfReportMap]> => {
    const data = await clientService
      .getReportHistoricalKPIs(
        {
          from: apiDateFormatter(period[0]),
          to: apiDateFormatter(period[1]),
          periodicity,
        },
        request
      )
      .then((res) => res.data.data)

    const unit = periodicityToScaleUnit(periodicity)
    const startDate = moment(period[0])
      .startOf(unit)
      .format(DEF_YYYY_MM_DD_DATE_FORMAT)

    return [
      data.map((it) => it.Period),
      toTSPerfReportMap(startDate, data, unit),
    ]
  }
}

export default PlatformTimeSegmentedPerformanceStore
