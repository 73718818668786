import AccountDashboardController from "./account-dashboard/account-dashboard.controller"
import EditCampaignControllerStore from "./manage-campaigns/edit-campaign.controller"
import AdCopyManagementController from "./manageProfile/ad-copy-management.controller"
import RootStore from "./RootStore"

export default class RootController {
  adCopyManagement: AdCopyManagementController

  editPMaxCampaignController: EditCampaignControllerStore

  accountDashboardController: AccountDashboardController

  constructor(root: RootStore) {
    this.adCopyManagement = new AdCopyManagementController(root)
    this.editPMaxCampaignController = new EditCampaignControllerStore(root)
    this.accountDashboardController = new AccountDashboardController(root)
  }
}
