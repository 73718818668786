import React from "react"

import { MetricDescription, MetricType } from "@framework/types/metrics"
import { mbNoData } from "@services/utils"
import { getTrendGradientColor } from "./utils"

export const MetricValueChip: React.FC<{
  metric: MetricDescription
  value?: MetricType | null
  className?: string
}> = ({ metric, value, className }) => (
  <div
    className={className}
    style={{
      background: value?.Value
        ? getTrendGradientColor(value?.Change)
        : undefined,
    }}
    title={
      value?.Change
        ? `Different from average ${(value?.Change * 100).toFixed(2)}%`
        : undefined
    }
  >
    {mbNoData(value?.Value, metric.formatter)}
  </div>
)

export default MetricValueChip
