import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"
import * as yup from "yup"

import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import FormTextField from "@framework/prototypes/FormTextField"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import Stack from "@components/ui/Stack/Stack"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { useStore } from "@store/index"
import PublicLayout from "@root/layouts/public/PublicLayout"
import { emailValidator } from "@components/utils/validators"

import styles from "./index.module.scss"

export const validationSchema = yup.object({
  email: emailValidator.clone().trim().required("Can not be empty").default(""),
})

type FormType = yup.InferType<typeof validationSchema>

const initialValues: FormType = validationSchema.getDefault()

const RestorePassword: React.FC = observer(() => {
  const [isSubmitted, setSubmitted] = useState<boolean>(false)
  const history = useHistory()
  const alert = useAlert()
  const { userStore } = useStore()

  const handleLoginRedirect = () => history.push("/login")

  const handleSubmit = async (form: FormType) => {
    const error = await userStore.restorePassword(validationSchema.cast(form))
    if (error) {
      alert.error(
        <AlertMessage
          title="Failed to request password reset"
          description={error}
        />
      )
      return
    }
    setSubmitted(true)
  }

  const formik = useFormik<FormType>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <PublicLayout
      after={
        <Button variant="ghost" color="primary" onClick={handleLoginRedirect}>
          Login
        </Button>
      }
    >
      <FormikProvider value={formik}>
        <div className={styles.root}>
          {!isSubmitted ? (
            <form style={{ minWidth: "333px" }} onSubmit={formik.handleSubmit}>
              <Stack align="stretch" gutter="16">
                <Typography type="title" color="dark" bold>
                  Forgot password
                </Typography>

                <FormTextField name="email" label="Email" />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={styles.control}
                  disabled={userStore.isLoading}
                >
                  Send
                </Button>
              </Stack>
            </form>
          ) : (
            <div style={{ maxWidth: "580px" }}>
              <Stack align="stretch">
                <Typography type="title" color="dark" bold>
                  Success
                </Typography>
                <Typography type="h3" color="dark">
                  Instructions on how to reset your password was sent to{" "}
                  <b>{formik.values.email}</b>. Please, check your inbox.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.control}
                  onClick={handleLoginRedirect}
                >
                  Back to Sign In Page
                </Button>
              </Stack>
            </div>
          )}
        </div>
      </FormikProvider>
    </PublicLayout>
  )
})

export default RestorePassword
