import React from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import { useAlert } from "react-alert"

import { useStore } from "@store/index"
import Modal, { ModalProps } from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import Radio from "@components/ui/CheckBox/Radio/Radio"
import FormNumberField from "@framework/prototypes/FormNumberField"
import { numberWithCommas } from "@utils/numberUtils"
import { preciseMoneyFormatter } from "@services/utils"
import { TrendValue } from "@components/ui/DataChangeWidget/DataChangeWidget"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import { numberWithSign } from "@utils/stringUtils"
import ModalTemplate from "@components/ui/Modal/ModalTemplate"

import styles from "./UpdateBiddingStatusModal.module.sass"

const validationSchema = yup.object({
  row: yup.string().required().default("current"),
  customBudget: yup.number().when("row", {
    is: "custom",
    then: yup.number().label("Budget").positive(),
  }),
})

type FormData = yup.InferType<typeof validationSchema>

export interface UpdateBiddingStatusModalProps extends ModalProps {
  campaignId: number
  options: RecommendedBudgetOption[]
}

export const UpdateBiddingStatusModal: React.FC<UpdateBiddingStatusModalProps> =
  observer(({ campaignId, options, ...rest }) => {
    const { manageCampaignStore, accountStore } = useStore()
    const { accountId } = accountStore

    const alert = useAlert()

    const campaign = React.useMemo(
      () => manageCampaignStore.getCampaignById(Number(campaignId)),
      [campaignId]
    )

    const handleSubmit = async (form: FormData) => {
      if (!accountId || form.row === "current") {
        rest.onClose?.()
        return
      }

      const budgetAmount =
        form.row === "custom"
          ? Number(form.customBudget)
          : options.find((it) => it.name === form.row)?.budget ?? 0

      const error = await manageCampaignStore.updateCampaign(
        accountId,
        campaignId,
        {
          budgetAmount,
          budgetFrequency: "DAILY",
          type: "BUDGET",
        }
      )

      if (error) {
        alert.error(error)
        return
      }

      rest.onClose?.()
    }

    const formik = useFormik<FormData>({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      onSubmit: handleSubmit,
    })

    const isLoading = manageCampaignStore.updateLoading

    const changeRowHandler = (id: string) => () => {
      formik.setFieldValue("row", id)
    }

    return (
      <Modal {...rest} hideHeader>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Stack
              className={styles.root}
              gutter="0"
              direction="column"
              align="stretch"
            >
              <ModalTemplate.Header
                title="Raise your budget"
                subtitle={
                  <>
                    Your campaign{" "}
                    <Typography
                      type="inherit"
                      color="inherit"
                      weight="bold"
                      inline
                    >
                      &quot;{campaign?.Campaign.Name}&quot;
                    </Typography>{" "}
                    is limited by its average daily budget, preventing your ads
                    from showing as often as they could.
                  </>
                }
              />

              <ModalTemplate.Body>
                <Stack direction="column" gutter="8" align="stretch">
                  <Typography type="h3" weight="semibold">
                    Weekly estimates for your new daily budget:
                  </Typography>

                  <Typography
                    type="body2"
                    color="black100Color"
                    className={styles.table}
                  >
                    <Table fixedLayout>
                      <Row>
                        <Column variant="header" width="20px" />
                        <Column variant="header" width="200px">
                          <Typography type="caption3">
                            Change Daily Budget
                          </Typography>
                        </Column>
                        <Column variant="header">
                          <Typography type="caption3">
                            Weekly Conversions
                          </Typography>
                        </Column>
                        <Column variant="header">
                          <Typography type="caption3">
                            Weekly Impressions
                          </Typography>
                        </Column>
                        <Column variant="header">
                          <Typography type="caption3">Weekly Clicks</Typography>
                        </Column>
                        <Column variant="header">
                          <Typography type="caption3">Weekly Cost</Typography>
                        </Column>
                      </Row>

                      {options.map((it) => {
                        const isChecked = formik.values.row === it.name
                        return (
                          <Row
                            hoverable
                            onClick={changeRowHandler(it.name)}
                            key={it.name}
                          >
                            <Column>
                              <Radio name="row" checked={isChecked} />
                            </Column>
                            <Column>
                              {preciseMoneyFormatter(it.budget)}{" "}
                              {it.label ? <strong>({it.label})</strong> : null}
                            </Column>
                            <Column>
                              <Typography type="inherit" color="lightGreen">
                                <TrendValue
                                  hideArrow
                                  className={styles.justifyStart}
                                  value={it.conversions}
                                  renderValue={(v) =>
                                    numberWithSign(v, numberWithCommas)
                                  }
                                  metricDir={positiveTransformDirection(
                                    it.conversions
                                  )}
                                />
                              </Typography>
                            </Column>
                            <Column>
                              <Typography type="inherit" color="lightGreen">
                                <TrendValue
                                  hideArrow
                                  className={styles.justifyStart}
                                  value={it.impressions}
                                  renderValue={(v) =>
                                    numberWithSign(v, numberWithCommas)
                                  }
                                  metricDir={positiveTransformDirection(
                                    it.impressions
                                  )}
                                />
                              </Typography>
                            </Column>
                            <Column>
                              {numberWithSign(it.clicks, numberWithCommas)}
                            </Column>
                            <Column>
                              {numberWithSign(it.cost, preciseMoneyFormatter)}
                            </Column>
                          </Row>
                        )
                      })}
                      <Row hoverable onClick={changeRowHandler("custom")}>
                        <Column>
                          <Radio
                            name="row"
                            checked={formik.values.row === "custom"}
                          />
                        </Column>
                        <Column>
                          <FormNumberField
                            prefix="$"
                            name="customBudget"
                            thousandSeparator
                          />
                        </Column>
                        <Column />
                        <Column />
                        <Column />
                        <Column />
                      </Row>
                    </Table>
                  </Typography>
                </Stack>
              </ModalTemplate.Body>

              <ModalTemplate.Footer>
                <Button
                  size="big"
                  color="secondary"
                  disabled={isLoading}
                  onClick={rest.onClose}
                >
                  Cancel
                </Button>
                <Button size="big" type="submit" disabled={isLoading}>
                  Apply
                </Button>
              </ModalTemplate.Footer>
            </Stack>
          </form>
        </FormikProvider>
      </Modal>
    )
  })

export type RecommendedBudgetOption = {
  name: string
  label?: string
  budget: number
  clicks: number
  cost: number
  conversions: number
  impressions: number
}

export default UpdateBiddingStatusModal
