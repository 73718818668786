import React from "react"

import useToggle from "@framework/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import { TextFieldProps } from "./TextField"

export const usePasswordFiledToggle = (): TextFieldProps => {
  const { isOpened: hidePass, handleToggle: togglePass } = useToggle(true)

  return {
    type: hidePass ? "password" : "text",
    after: (
      <IconButton color={hidePass ? "secondary" : "primary"}>
        <Icon name="eye" onClick={togglePass} />
      </IconButton>
    ),
  }
}

export default usePasswordFiledToggle
