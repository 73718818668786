/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useEffect, useCallback } from "react"
import clsx from "clsx"
import { observer, useLocalStore } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { Legend } from "@components/ui/Charts/Legends/LegendList"
import {
  Platform,
  CampaignGroup,
  getReportGroupLevelDescriptions,
} from "@framework/constants/report"
import Table from "@framework/prototypes/report/MetricsTable"
import { transformMetricsToTableMapper } from "@framework/prototypes/report/MetricsTable/utils"
import { useStore } from "@store/index"
import {
  TableContext,
  TableContextStore,
} from "@framework/prototypes/report/MetricsTable/TableContext"
import Loader from "@components/ui/Loader/Loader"
import { MapperType } from "@components/ui/WaterfallTable/types"
import StatusBadge from "@pages/GoogleShoppingChannel/components/Status/StatusBadge"
import { Header } from "./Header"
import { campaignStatusMapper } from "./useCampaignStatusOptionList"

import styles from "./index.module.sass"

const defaultMapper: MapperType[] = [
  {
    name: "status",
    label: "Status",
    widthBasis: "10ch",
    sortable: true,
    render: {
      Component: StatusBadge,
      props: { mapper: campaignStatusMapper, variant: "simple" },
    },
  },
]

type MetricsTableProps = {
  className?: string
}

const MetricsTable: React.FC<MetricsTableProps> = observer(({ className }) => {
  const { reports } = useStore()
  const {
    settings: { sequence },
  } = reports

  const mapper = useMemo(
    () => [
      ...defaultMapper,
      ...transformMetricsToTableMapper(sequence.allActive),
    ],
    [sequence.allActive]
  )

  const {
    reports: { extendedReport, mainFilter },
  } = useStore()

  const { campaignStatus } = mainFilter
  const { prevPeriod, currPeriod, accounts, platforms, campaignTypes } =
    mainFilter.activeFilter

  const handleLoadReport = useCallback(
    () =>
      extendedReport.loadReport(
        currPeriod.range,
        prevPeriod.range,
        accounts.map(Number),
        platforms as Platform[],
        campaignTypes as CampaignGroup[],
        campaignStatus
      ),
    [prevPeriod, currPeriod, accounts, platforms, campaignTypes, campaignStatus]
  )

  useEffect(() => {
    if (!mainFilter.isActiveFilterValid) return
    handleLoadReport()
  }, [mainFilter.isActiveFilterValid, handleLoadReport])

  const context = useLocalStore(() => new TableContextStore())

  return (
    <TableContext.Provider value={context}>
      <div className={clsx(styles.root, className)}>
        <Header />

        <Loader isLoading={extendedReport.isLoading} />

        <Stack direction="row">
          {getReportGroupLevelDescriptions(context.viewType).map((item) => (
            <Legend
              label={item.label}
              color={item.color}
              key={item.shortName}
            />
          ))}
        </Stack>

        <Table mapper={mapper} className={styles.container} />
      </div>
    </TableContext.Provider>
  )
})

export default MetricsTable
