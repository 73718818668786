import React from "react"
import clsx from "clsx"

import Typography, {
  ITypographyProps,
} from "@components/ui/Typography/Typography"

import styles from "./Label.module.scss"

interface LabelProps extends ITypographyProps {
  textPosition?: "before" | "after"
  orientation?: "vertical" | "horizontal"
  text?: React.ReactNode
}

export const Label: React.FC<LabelProps> = ({
  type = "body2",
  color = "black100Color",
  textPosition = "after",
  orientation = "horizontal",
  text,
  className,
  children,
  ...rest
}) => (
  <Typography
    {...rest}
    className={clsx(
      styles.root,
      styles[`orientation-${orientation}`],
      className
    )}
    type={type}
    color={color}
    as="label"
  >
    {textPosition === "after" ? (
      <>
        {children} <span className={styles.label}>{text}</span>
      </>
    ) : (
      <>
        <span className={styles.label}>{text}</span> {children}
      </>
    )}
  </Typography>
)

export default Label
