import React from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"

import Stack from "@components/ui/Stack/Stack"
import FormTextField from "@framework/prototypes/FormTextField"
import {
  emailValidator,
  firstNameValidator,
  lastNameValidator,
} from "@utils/validators"
import Label from "@components/ui/Label/Label"

export const userFormValidationSchema = yup
  .object({
    id: yup.number(),
    name: firstNameValidator.clone().default(""),
    surname: lastNameValidator.clone().default(""),
    email: emailValidator
      .clone()
      .required("Email is a required field")
      .default(""),
  })
  .camelCase()

export type UserFormData = yup.InferType<typeof userFormValidationSchema>

type UserFormProps = {
  isEdit?: boolean
}

const UserForm: React.FC<UserFormProps> = observer(({ isEdit }) => (
  <Stack gutter="24" align="stretch">
    <Label label="First name (optional)">
      <FormTextField
        name="user.name"
        placeholder="Enter first name"
        autoComplete="firstName"
      />
    </Label>

    <Label label="Last name (optional)">
      <FormTextField
        name="user.surname"
        placeholder="Enter last name"
        autoComplete="lastName"
      />
    </Label>

    <Label label="Email">
      <FormTextField
        name="user.email"
        placeholder="Enter user email"
        autoComplete="email"
      />
    </Label>
  </Stack>
))

export default UserForm
