import React from "react"
import { observer } from "mobx-react-lite"

import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { useStore } from "@store/index"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import DragHandle from "@components/ui/DragHandle/DragHandle"
import { DraggableProvided } from "react-beautiful-dnd"

import styles from "./MetricItem.module.scss"

interface MetricItemProps {
  id: MetricNameType
  provided: DraggableProvided
  isDragging?: boolean
}

export const MetricItem: React.FC<MetricItemProps> = observer(
  ({ id, provided, isDragging }) => {
    const {
      reports: {
        settings: { editableSequence },
      },
    } = useStore()

    const metric = getMetricDescriptor(id)

    return (
      <label
        htmlFor={id}
        className={styles.root}
        data-drag={isDragging}
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <CheckBox
          id={id}
          checked={editableSequence.isActive(id)}
          onChange={() => editableSequence.selectMetric(id)}
        />
        <div className={styles.label}>{metric.label}</div>
        <DragHandle
          {...provided.dragHandleProps}
          onClick={(e) => e.preventDefault()}
        />
      </label>
    )
  }
)

export default MetricItem
