import Box from "./Box"
import ItemsList from "./ItemsList"
import SimpleOption from "./Option"
import Group from "./OptionGroup"
import DropDown from "./DropDownContainer"

const Select = {
  Box,
  Option: SimpleOption,
  Group,
  DropDown,
  ItemsList,
}

export default Select
