import React from "react"
import LoaderProgressBar from "@components/ui/ProgressBar/LoaderProgressBar/LoaderProgressBar"
import { QuadSizeType } from "@utils/enums"

import styles from "./ProgressAnumation.module.scss"

export const ProgressAnimation: React.FC<{ value?: number }> = ({
  value = 0,
}) => (
  <div className={styles.root}>
    <img
      src="/images/adoro-main-logo.svg"
      alt="Logo"
      height="50"
      className={styles.logo}
    />
    {/* <LogoLoader className={styles.loader} /> */}
    <LoaderProgressBar
      value={value}
      barTransitionTime=".5s"
      size={QuadSizeType.small}
    />
  </div>
)

export default ProgressAnimation
