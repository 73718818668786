import clsx from "clsx"
import React from "react"
import Typography, {
  ITypographyProps,
} from "@components/ui/Typography/Typography"

import styles from "./Label.module.scss"

export interface LabelProps extends ITypographyProps {
  label?: React.ReactNode
  error?: React.ReactNode
  floatingError?: boolean
  style?: React.CSSProperties
  errorTextType?: ITypographyProps["type"]
  labelClassName?: string
}

export const Label: React.FC<LabelProps> = ({
  label,
  error,
  children,
  type = "caption3",
  errorTextType = "caption3",
  color = "black70Color",
  className,
  labelClassName,
  style,
  floatingError,
  ...rest
}) => (
  <div className={clsx(styles.root, className)} style={style}>
    {label != null && (
      <Typography
        className={clsx(styles.label, labelClassName)}
        type={type}
        color={color}
        {...rest}
      >
        {label}
      </Typography>
    )}
    {children}
    {error != null && (
      <Typography
        color="red"
        type={errorTextType}
        className={clsx({ [styles.floating]: floatingError })}
      >
        {error}
      </Typography>
    )}
  </div>
)

export default Label
