import React from "react"
import clsx from "clsx"

import styles from "./Table.module.scss"

export interface MenuItemProps {}

interface RowProps {
  sticky?: boolean
  hoverable?: boolean
  className?: string
  onClick?: React.MouseEventHandler
}

export const Row: React.FC<RowProps> = ({
  sticky,
  hoverable,
  className,
  onClick,
  children,
}) => (
  <tr
    onClick={onClick}
    className={clsx(
      styles.row,
      { [styles.hoverable]: hoverable, [styles.sticky]: sticky },
      className
    )}
  >
    {children}
  </tr>
)

export default Row
