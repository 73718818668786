import moment from "moment"
import { MonthlySearchVolume } from "@framework/types/account"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import { initArrayByLength } from "@components/utils/numberUtils"
import { periodLength } from "@utils/date"

export const reportTotMap = (
  reports: MonthlySearchVolume[]
): Record<string, number> =>
  Object.fromEntries(
    reports.map((item) => [
      moment.utc(item.Month).format(YYYY_MM_DD_DATE_FORMAT),
      item.SearchVolume ?? 0,
    ])
  )

export const adjustToPeriod = (
  reports: Record<string, number>,
  from: Date,
  to: Date
): Record<string, number> => {
  const fromMoment = moment(from).startOf("month")
  const points = periodLength([from, to], "month")

  return Object.fromEntries(
    initArrayByLength(points).map((idx) => {
      const month = fromMoment.clone().add(idx, "month")
      const key = month.format(YYYY_MM_DD_DATE_FORMAT)
      return [key, reports[key] ?? 0]
    })
  )
}
