import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import { useField } from "formik"

import Stack from "@components/ui/Stack/Stack"
import { permissionLevelPriority, permissionsType } from "@framework/types/user"
import { useStore } from "@store/index"
import usePrevious from "@framework/hooks/usePrevious"
import {
  getPermissionLevelOption,
  permissionLevels,
  permissionsTypeOptions,
} from "@pages/Settings/components/Table/utils"
import Typography from "@components/ui/Typography/Typography"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import useOptionList from "@framework/prototypes/useOptionList"
import FormSimpleMultiSelectField from "@framework/prototypes/FormSimpleMultiSelectField"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"

export const permissionFormValidationSchema = yup.object({
  accessLevel: yup
    .string()
    .required("Field is required")
    .oneOf([...permissionLevelPriority], "Required")
    .default(""),
  objectType: yup
    .string()
    .required("Field is required")
    .oneOf([...permissionsType], "Required")
    .default(""),
  objectId: yup
    .array()
    .of(yup.number().required())
    .min(1, "Select at least 1 item")
    .default([]),
})

export type PermissionFormData = yup.InferType<
  typeof permissionFormValidationSchema
>

type PermissionFormProps = {}

const PermissionForm: React.FC<PermissionFormProps> = observer(() => {
  const { settings } = useStore()

  const [typeFiled] = useField("permission.objectType")
  const oIdField = useField("permission.objectId")

  const prevObjType = usePrevious(typeFiled.value)

  const options = useMemo(
    () =>
      Object.values(
        typeFiled.value ? settings.getMapper(typeFiled.value) ?? {} : {}
      ),
    [typeFiled.value, settings.getMapper]
  )

  useEffect(() => {
    if (prevObjType != null) oIdField[2].setValue([])
  }, [typeFiled?.value])

  const accessLvlOptions = useOptionList(permissionsTypeOptions)
  const accessDetailsOptions = useOptionSearchList({ list: options })

  return (
    <Stack gutter="24" align="stretch">
      <FormSimpleSelectField
        name="permission.objectType"
        label="Access Level"
        placeholder="Select access level..."
        {...accessLvlOptions}
      />

      <FormSimpleMultiSelectField
        name="permission.objectId"
        label="Access details"
        placeholder="Select access details..."
        disabled={!typeFiled.value}
        withSearch
        {...accessDetailsOptions}
      />

      <FormSimpleSelectField
        name="permission.accessLevel"
        label="Permission"
        options={permissionLevels}
        placeholder="Select permissions..."
        disabled={!typeFiled.value}
        renderValue={(value) =>
          getPermissionLevelOption(value)?.label ?? "Unknown"
        }
        renderOption={(value) => {
          const option = getPermissionLevelOption(value)
          if (!option) return null
          return (
            <Stack gutter="4">
              <Typography type="h3" color="inherit">
                {option.label}
              </Typography>
              <Typography type="body2" color="inherit">
                {option.description}
              </Typography>
            </Stack>
          )
        }}
      />
    </Stack>
  )
})

export default PermissionForm
