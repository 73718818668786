/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useEffect } from "react"
import ReactDOM from "react-dom"

import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import useGlobalScrollSwitch from "@components/prototypes/GlobalScrollContext/useGlobalScrollSwitch"
import IconButton from "../Button/IconButton"
import BlurContainer from "./BlurContainer"

import styles from "./Modal.module.scss"

export interface ModalProps {
  isOpen: boolean
  title?: ReactNode
  className?: string
  hideHeader?: boolean
  straightMount?: boolean
  onClose?: () => void
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  title,
  children,
  className,
  hideHeader = false,
  straightMount,
  onClose,
}) => {
  const [_, toggleValue] = useGlobalScrollSwitch()

  useEffect(() => toggleValue(isOpen), [isOpen])

  if (!isOpen) return null

  const node = (
    <BlurContainer blur={isOpen}>
      <Box className={styles.root}>
        {!hideHeader && (
          <div className={styles.header}>
            {title ? (
              typeof title === "string" ? (
                <Typography type="h3">{title}</Typography>
              ) : (
                title
              )
            ) : null}
            {!!onClose && (
              <IconButton className={styles.closeIcon}>
                <Icon name="cross" onClick={onClose} />
              </IconButton>
            )}
          </div>
        )}
        <div className={className}>{children}</div>
      </Box>
    </BlurContainer>
  )

  if (straightMount) return node
  return ReactDOM.createPortal(node, document.body)
}

export default Modal
