import { nanoid } from "nanoid"
import { action, observable } from "mobx"

import { ID } from "@framework/types/types"
import { AdCopyDetailsData } from "@framework/types/adCopy"
import { DeepPartial } from "@framework/types/utils"
import { AdCopyTask } from "./edit-adCopy.store"

class EditAdCopyTask implements AdCopyTask {
  constructor(adCopyId: ID) {
    this.adCopyId = adCopyId
    this.type = "edit"
  }

  readonly id: string = nanoid()

  readonly type: "edit"

  readonly adCopyId: ID

  @observable adCopyDetails: DeepPartial<AdCopyDetailsData>[] | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  @action setAdCopies = (data: DeepPartial<AdCopyDetailsData>[]) => {
    this.adCopyDetails = data
  }
}

export default EditAdCopyTask
