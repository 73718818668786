import React from "react"

import Icon from "@components/ui/Icon/Icon"
import OnClickTooltip from "@components/ui/Tooltip/TooltipContainer/OnClickTooltipContainer"

import styles from "./SequenceViewColumn.module.scss"

export interface SequenceViewColumnProps {
  list: string[]
}

export const SequenceViewColumn: React.FC<SequenceViewColumnProps> = ({
  list,
}) => {
  const last = list[list.length - 1]
  return (
    <div className={styles.root}>
      <OnClickTooltip
        placement="top"
        toolTip={
          <div className={styles.toolTip}>
            {list.map((item: string, idx: number) => (
              <div className={styles.item}>
                {item}
                {idx < list.length - 1 && (
                  <Icon
                    className={styles.arrow}
                    name="arrow-right"
                    rotateAngle={90}
                  />
                )}
              </div>
            ))}
          </div>
        }
      >
        <div className={styles.link}>{last}</div>
      </OnClickTooltip>
    </div>
  )
}

export default SequenceViewColumn
