import React, { useEffect } from "react"
import clsx from "clsx"
import { observer, useLocalStore } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import Stack from "@components/ui/Stack/Stack"
import usePagination from "@components/ui/Pagination/usePagination"
import PaginationRow from "@components/ui/Pagination/PaginationRow"
import { useController, useStore } from "@store/index"
import CreateAdCopyModal from "@components/EditAdCopyModal/CreateAdCopyModal"
import useToggle from "@framework/hooks/useToggle"
import AdCopyWizardModal from "@components/EditAdCopyModal/AdCopyWizardModal/index"
import AdCopyAIWizardStore from "@components/EditAdCopyModal/AdCopyWizardModal/ad-copy-wizard.store"
import { AdCopyDetailsData } from "@framework/types/adCopy"
import { DeepPartial } from "@framework/types/utils"
import ConfirmModal from "@components/modals/ConfirmModal/ConfirmModal"
import useNotificationModal from "@components/modals/ConfirmModal/useNotificationModal"
import { AdCopyAIWizardContextProvider } from "./AdCopyAIWizardContext"
import AdTableBody from "./AdTableBody"

import styles from "./index.module.scss"

type AdCopiesProps = {
  className?: string
}

const AdCopiesTable: React.FC<AdCopiesProps> = observer(({ className }) => {
  const {
    accountStore: { accountId },
    adCopyStore: { activeAdCopies, isEditingAvailable },
  } = useStore()
  const { adCopyManagement } = useController()

  const createAdCopyModal = useToggle(false)
  const adCopyWizardModal = useToggle(false)
  const wizardNotificationModal = useToggle(false)

  const adCopyWizardStore = useLocalStore(() => new AdCopyAIWizardStore())

  const notificationModal = useNotificationModal()

  const handleAddClick = () => createAdCopyModal.setOpened(true)

  const handleManualAdd = () => {
    if (accountId == null) return
    adCopyManagement.createEditAdCopyTask(accountId, [])
  }

  const handleAddByAI = (form: DeepPartial<AdCopyDetailsData>) => {
    adCopyWizardStore.reset()
    adCopyManagement.createAddByAIAdCopyTask(form)
    adCopyWizardModal.setOpened(false)
  }

  const handleAIAdd = () => adCopyWizardModal.setOpened(true)

  const pagination = usePagination({
    total: activeAdCopies.total,
    pageSize: 10,
  })

  useEffect(() => {
    adCopyManagement.updateFrame(pagination.options.page, 10)
  }, [pagination.options.page])

  useEffect(() => {
    if (
      !adCopyWizardModal.isOpened &&
      Object.values(adCopyWizardStore.completed).some((it) => it)
    )
      wizardNotificationModal.setOpened(true)
  }, [adCopyWizardModal.isOpened])

  useEffect(() => {
    if (adCopyWizardStore.fatalError != null) {
      adCopyWizardStore.reset()
      adCopyWizardModal.setOpened(false)
      notificationModal.show({
        title: "Error",
        message: "Sorry, something went wrong. Please try again later.",
      })
    }
  }, [adCopyWizardStore.fatalError])

  return (
    <AdCopyAIWizardContextProvider value={adCopyWizardStore}>
      <Stack
        gutter="16"
        align="stretch"
        className={clsx(styles.root, className)}
      >
        <Templates.Header
          offset="none"
          left={<Typography type="h2">Ad Copy</Typography>}
          right={
            <Button
              variant="outlined"
              startIcon={{ name: "plus" }}
              onClick={handleAddClick}
              disabled={!isEditingAvailable}
            >
              Add new
            </Button>
          }
        />

        <AdTableBody />

        {pagination.controllerProps.pageCount > 1 && (
          <PaginationRow {...pagination} />
        )}

        <CreateAdCopyModal
          isOpen={createAdCopyModal.isOpened}
          onClose={() => createAdCopyModal.setOpened(false)}
          onManualClick={handleManualAdd}
          onAIClick={handleAIAdd}
        />

        <AdCopyWizardModal
          isOpen={adCopyWizardModal.isOpened}
          onClose={() => adCopyWizardModal.setOpened(false)}
          onCancel={() => {
            adCopyWizardStore.reset()
            adCopyWizardModal.setOpened(false)
            createAdCopyModal.setOpened(true)
          }}
          onSubmit={handleAddByAI}
        />

        <ConfirmModal
          isOpen={wizardNotificationModal.isOpened}
          message="Are you sure, you want to quit the Ad Copy generation process? All data previously entered within the process will not be saved."
          title="Notification"
          cancelText="No"
          onClose={() => {
            wizardNotificationModal.setOpened(false)
            adCopyWizardModal.setOpened(true)
          }}
          applyText="Yes"
          onApply={() => {
            adCopyWizardStore.reset()
            wizardNotificationModal.setOpened(false)
          }}
        />

        {notificationModal.Node}
      </Stack>
    </AdCopyAIWizardContextProvider>
  )
})

export default AdCopiesTable
