import React from "react"
import "./App.scss"

import AppRouter from "./AppRouter"
import AppProvider from "./AppProvider"
import SecurityProvider from "./components/security/SecureProvider"

const App: React.FC = () => (
  <AppProvider>
    <SecurityProvider>
      <AppRouter />
    </SecurityProvider>
  </AppProvider>
)

export default App
