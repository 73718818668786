/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import styles from "./Table.module.scss"

interface RowProps {
  className?: string
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler
}

export const Row: React.FC<RowProps> = ({
  className,
  children,
  style,
  onClick,
}) => (
  <div onClick={onClick} className={clsx(styles.row, className)} style={style}>
    {children}
  </div>
)

export default Row
