/* eslint-disable no-restricted-syntax */
import { useMemo } from "react"
import { BaseSchema } from "yup"
import useURLSearchParams from "./useURLSearchParams"

const useQueryParams = <T extends any>(validator?: BaseSchema): T | null => {
  const params = useURLSearchParams()

  return useMemo(() => {
    const parsed: T = Array.from(params.entries()).reduce<any>(
      (acc, [key, value]) => {
        acc[key] =
          value === "" || Number.isNaN(Number(value)) ? value : Number(value)
        return acc
      },
      {}
    )
    if (validator != null && !validator.isValidSync(parsed)) return null
    return parsed
  }, [params, validator])
}

export default useQueryParams
