import React from "react"
import { observer } from "mobx-react-lite"

import LinkedAccounts from "@pages/Account/ManageProfile/components/LinkedAccounts/LinkedAccounts"

const PaidSearchLinkedAccounts: React.FC = observer(() => (
  <LinkedAccounts linkCase="google-ads" />
))

export default PaidSearchLinkedAccounts
