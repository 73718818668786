import React from "react"
import { observer } from "mobx-react-lite"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Templates from "@components/ui/Templates"
import { Button } from "@components/ui/Button"
import { TableMapperItem } from "@framework/types/types"
import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import { numberWithCommas } from "@utils/numberUtils"
import { useKeywordInsightsContext } from "@pages/Opportunities/KeywordInsightsContext"
import { RelatedQuestionSetName } from "@framework/types/opportunities"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { mbNoData } from "@services/utils"
import { useSearchContext } from "@framework/prototypes/SearchContext"
import { searchBy } from "@utils/arrayUtils"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import NoData from "@components/ui/NoData/NoData"
import config from "@root/config"
import { useKeywordManagementContext } from "../KeywordQuestion/KeywordManagementContext"

import styles from "./QuestionsTable.module.scss"

const hideAnswers = !!config.DISABLE_OPPORTUNITIES_ANSWERS

const ROWS_TO_SHOW = 5

export interface QuestionsTableProps {
  questionsSetName: RelatedQuestionSetName
}

export const QuestionsTable: React.FC<QuestionsTableProps> = observer(
  ({ questionsSetName }) => {
    const context = useKeywordManagementContext()
    const {
      store: { relatedQuestionsStore },
    } = useKeywordInsightsContext()

    const collection =
      relatedQuestionsStore.getCollectionBySetName(questionsSetName)

    const searchContext = useSearchContext()

    const dataSlice = React.useMemo(
      () =>
        searchBy(
          collection.data,
          searchContext.searchQuery,
          (it) => it.description
        ).slice(0, ROWS_TO_SHOW),
      [collection.data, searchContext.searchQuery]
    )

    const tableMapper: TableMapperItem[] = React.useMemo(() => {
      const res = [
        {
          name: "searchVolume",
          label: "Search Vol.",
          width: "100px",
        },
        {
          name: "cpc",
          label: "CPC",
          width: "55px",
        },
        {
          name: "question",
          label: "Question",
        },
      ]

      if (!hideAnswers)
        res.push({
          name: "action",
          label: "",
          width: "135px",
        })

      return res
    }, [])

    const cpcDesc = getMetricDescriptor("AverageCpc")

    const { isLoading } = relatedQuestionsStore

    return (
      <Box className={styles.root}>
        <Templates.Header
          offset="none"
          height="big"
          left={
            <Typography type="h3" weight="bold">
              {questionsSetName.toUpperCase()}
            </Typography>
          }
          right={
            <Button
              size="big"
              color="secondary"
              onClick={() => context.showQuestionDetails(questionsSetName)}
            >
              Show All ({collection.total})
            </Button>
          }
        />

        <Typography type="body1">
          {dataSlice.length ? (
            <Table hideBorder hideHorizontalOffset fixedLayout spacing="small">
              <Row>
                {tableMapper.map((it) => (
                  <Column variant="header" width={it.width} key={it.name}>
                    <Typography type="caption2" color="black60Color">
                      {it.label}
                    </Typography>
                  </Column>
                ))}
              </Row>

              {dataSlice.map((it) => (
                <Row key={it.id}>
                  <Column vAlign="top">
                    <Typography type="inherit" weight="semibold">
                      {mbNoData(it.searchVolume, numberWithCommas)}
                    </Typography>
                  </Column>

                  <Column vAlign="top">
                    <Typography type="inherit" weight="semibold">
                      {mbNoData(it.cpc, cpcDesc.formatter)}
                    </Typography>
                  </Column>
                  <Column vAlign="top">{it.description}</Column>

                  {!hideAnswers && (
                    <Column vAlign="top" align="end">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => context.showAnswerEditor(true)}
                      >
                        Generate answer
                      </Button>
                    </Column>
                  )}
                </Row>
              ))}
            </Table>
          ) : (
            <div className={styles.placeholder}>
              {isLoading ? (
                <CircleLoader />
              ) : (
                <NoData>
                  No Questions found{" "}
                  {searchContext.searchQuery ? " by query" : ""}
                </NoData>
              )}
            </div>
          )}
        </Typography>
      </Box>
    )
  }
)

export default QuestionsTable
