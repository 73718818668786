import { action, observable } from "mobx"

import { PerformanceReport } from "@framework/types/metrics"
import clientService from "@services/client.service"
import {
  Platform,
  CampaignGroup,
  bundlePlatformParams,
} from "@framework/constants/report"
import { apiDateFormatter } from "@services/utils"

export class SummaryPerformanceStore {
  @observable data: PerformanceReport | null = null

  @observable isLoading = false

  @observable loadingError: string | null = null

  @action loadPerformanceSummary = async (
    period: [Date, Date],
    comparePeriod: [Date, Date],
    accounts: number[],
    platforms: Platform[],
    channels: CampaignGroup[]
  ) => {
    try {
      this.loadingError = null
      this.isLoading = true

      const response = await clientService.getReportKPIs(
        {
          from: apiDateFormatter(period[0]),
          to: apiDateFormatter(period[1]),
          compareFrom: apiDateFormatter(comparePeriod[0]),
          compareTo: apiDateFormatter(comparePeriod[1]),
        },
        {
          accounts,
          channels: bundlePlatformParams(platforms, channels),
          platforms,
        }
      )

      this.data = response.data.data
    } catch (error) {
      this.loadingError = "Unexpected error"
      this.data = null
    } finally {
      this.isLoading = false
    }
    return this.loadingError
  }
}

export default SummaryPerformanceStore
