import { observable, action, computed } from "mobx"
import RootStore from "@store/RootStore"
import AbstractFilter from "@framework/classes/AbstractFilter"
import { Option } from "@framework/types/types"
import { countFilters } from "./utils"

export interface FilterOption {
  label: string
  name: string
  options: Option[]
}

type FilterState = Record<string, string[]>

export class FilterByAttributesStore extends AbstractFilter {
  @observable rootStore: RootStore

  @observable filterOptions: FilterOption[] = []

  @observable baseCategories: string[] = []

  @observable isFilterLoading = true

  @observable temp: FilterState = {}

  @observable active: FilterState = {}

  // constructor

  constructor(root: RootStore) {
    super()
    this.rootStore = root
  }

  // computed

  @computed get activeFiltersCount() {
    return countFilters(this.active)
  }

  @computed get tempFiltersCount() {
    return countFilters(this.temp)
  }

  @computed get filtersCountDifference() {
    return this.tempFiltersCount - this.activeFiltersCount
  }

  @computed get isSelected() {
    const filter = this.temp
    return (filterName: string) =>
      ({ value }: Option) =>
        filter[filterName]?.includes(value)
  }

  @computed get filtersSelected() {
    const filter = this.temp
    return (filterName: string) => filter[filterName]?.length ?? 0
  }

  @computed get isSync() {
    return !hasDifference(this.active, this.temp)
  }

  // active

  @action setFilters = (filters: FilterOption[]) => {
    this.filterOptions = filters
  }

  @action resetTemp = () => {
    this.temp = { ...this.active }
  }

  @action resetAll = () => {
    this.active = {}
    this.temp = {}
  }

  @action applyFilter = () => {
    this.active = { ...this.temp }
  }

  @action setTempFilter = (filterName: string, { value }: Option) => {
    if (this.temp[filterName] == null) this.temp[filterName] = []
    const values = this.temp[filterName]

    if (values.includes(value))
      this.temp[filterName] = this.temp[filterName].filter((v) => v !== value)
    else this.temp[filterName] = [...this.temp[filterName], value]
  }
}

export default FilterByAttributesStore

const hasDifference = (obj1: any, obj2: any): boolean => {
  /* eslint-disable eqeqeq */
  if (obj1 == obj2) return false
  if (obj1 == null || obj2 == null) return true

  if (Array.isArray(obj1) || Array.isArray(obj2)) {
    if (!(Array.isArray(obj1) && Array.isArray(obj2))) return true
    if (obj1.length !== obj2.length) return true

    for (let i = 0; i < obj1.length; i += 1) {
      if (!obj1.includes(obj2[i])) return true
    }

    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) return true

  for (let i = 0; i < keys1.length; i += 1) {
    if (!keys1.includes(keys2[i])) return true
    return hasDifference(obj1[keys1[i]], obj2[keys2[i]])
  }

  return false
}
