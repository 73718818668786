import React, {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useState,
} from "react"
import clsx from "clsx"
import { JustificationType, PlacementType } from "../types"

import styles from "./ComicBubble.module.scss"

export type ComicBubbleProps = {
  className?: string
  containerClassName?: string
  coords: any
  placement?: PlacementType
  justify?: JustificationType
  hideTip?: boolean
  hidden?: boolean
}

export const ComicBubble = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<ComicBubbleProps>
>(
  (
    {
      children,
      className,
      coords,
      placement = "top",
      justify = "center",
      hideTip = false,
      hidden = false,
      containerClassName,
    },
    ref
  ) => {
    const [opacity, setOpacity] = useState(0)
    const [display, setDisplay] = useState("none")

    const style: CSSProperties = coords
      ? {
          display,
          opacity,
          left: coords?.x ?? 0,
          top: coords?.y ?? 0,
          zIndex: 999,
        }
      : { display, opacity }

    useEffect(() => {
      if (hidden) {
        setOpacity(0)
        const displayTimer = setTimeout(() => setDisplay("none"), 200)
        return () => clearTimeout(displayTimer)
      }
      setDisplay("block")
      const opacityTimer = setTimeout(() => setOpacity(1), 200)
      return () => clearTimeout(opacityTimer)
    }, [hidden])

    return (
      <div
        className={clsx(
          styles.root,
          styles[placement],
          styles[justify],
          containerClassName
        )}
        style={style}
        ref={ref}
      >
        {!hideTip && <span className={clsx(styles.tip)} />}
        <div className={clsx(styles.body, className)}>{children}</div>
      </div>
    )
  }
)

export default ComicBubble
