import React from "react"
import { SortByType } from "@framework/types/types"
import { searchQueries, sortQueriesReports } from "@pages/ManageCampaign/utils"
import { QueryReportType } from "@framework/types/account"

const emptyArray: QueryReportType[] = []

export const useSortedSearchQueries = (config: {
  list: QueryReportType[] | null
  sortBy: SortByType
  searchQuery?: string
}) => {
  const { list, sortBy, searchQuery } = config

  const [inProgress, setInProgress] = React.useState(false)

  const [sortedList, setSortedList] =
    React.useState<QueryReportType[]>(emptyArray)

  const update = async (
    list: QueryReportType[],
    sortBy?: SortByType,
    searchQuery?: string
  ) => {
    setInProgress(true)

    const filtered = await searchList(list, searchQuery)
    const res = await sortList(filtered, sortBy)

    setSortedList(res)
    setInProgress(false)
  }

  React.useEffect(() => {
    update(list ?? emptyArray, sortBy, searchQuery)
  }, [list, sortBy, searchQuery])

  return { sortedList, inProgress }
}

const sortList = async (list: QueryReportType[], sortBy?: SortByType) => {
  if (list.length === 0) return list

  const res = await sortQueriesReports(list, sortBy)
  return res
}

const searchList = async (
  list: QueryReportType[],
  searchQuery: string = ""
) => {
  const res = await searchQueries(list, searchQuery)
  return res
}

export default useSortedSearchQueries
