import clsx from "clsx"
import React from "react"

import styles from "./TextLoader.module.scss"

export interface TextLoaderProps {
  className?: string
}

const TextLoader: React.FC<TextLoaderProps> = ({
  children = "Loading",
  className,
}) => <div className={clsx(styles.root, className)}>{children}</div>

export default TextLoader
