import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import ConfirmModal from "@components/modals/ConfirmModal/ConfirmModal"
import { useController, useStore } from "@store/index"
import { AdCopyTask } from "@store/manageProfile/edit-adCopy.store"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"

type RestoreAdCopyModalProps = {
  isOpened: boolean
  task?: AdCopyTask | null
  onClose: () => void
}

const RestoreAdCopyModal: React.FC<RestoreAdCopyModalProps> = observer(
  ({ isOpened, task, onClose }) => {
    const alert = useAlert()
    const { adCopyManagement } = useController()
    const {
      accountStore: { accountId },
    } = useStore()

    const handleDelete = async () => {
      if (!accountId || !task?.adCopyIds) return

      const error = await adCopyManagement.restoreDeletedAdCopies(
        accountId,
        task?.adCopyIds
      )

      if (error)
        alert.error(
          <AlertMessage title="Failed to restore Ad Copy" description={error} />
        )
      else onClose()
    }

    const total = task?.adCopyIds?.length ?? 0
    const noneSelected = total === 0

    const message = noneSelected
      ? "There are no deleted Ad Copy selected, nothing to restore"
      : total > 1
      ? `Are you sure want to restore ${total} deleted ad copy?`
      : "Are you sure want to restore deleted ad copy?"

    return (
      <ConfirmModal
        title="Restore Ad Copy"
        message={message}
        isOpen={isOpened}
        onClose={onClose}
        applyText="Confirm"
        disableApply={noneSelected}
        onApply={handleDelete}
      />
    )
  }
)

export default RestoreAdCopyModal
