import React from "react"
import clsx from "clsx"
import { observer, useLocalStore } from "mobx-react-lite"
import { useHistory, useLocation } from "react-router-dom"

import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import GoogleAdsMainLayout from "@pages/PaidSearchChannel/GoogleAdsLayout"
import Container from "@root/layouts/Container/Container"
import {
  allCampaignTypeOptions,
  allCampaignViewOptions,
} from "@framework/constants/manageCampaign"
import {
  SearchContext,
  SearchContextStore,
} from "@framework/prototypes/SearchContext"
import { SelectedCampaignsContext } from "@framework/prototypes/SelectedCampaignsContext"
import EditGoogleAdCopySidebar from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/EditCopySidebar/EditGoogleAdCopySidebar"
import { useController, useStore } from "@store/index"
import EditAdCopyTask from "@store/manage-campaigns/EditAdCopyTask"
import DeleteAndPublishAdCopyModal from "@components/modals/AdCopyModal/DeleteAndPublishAdCopyModal"
import DeleteAdCopyTask from "@store/manage-campaigns/DeleteAdCopyTask"
import SelectableList from "@store/common/SelectableList"
import { SelectedAdGroupsContext } from "@framework/prototypes/SelectedAdGroupsContext"
import LowPerformanceKeywords from "@pages/ManageCampaign/components/CampaignTable/LowPerformanceKeywords"
import CampaignTable from "./components/CampaignTable/CampaignTable"
import Header from "./components/Header/Header"
import KeywordsTable from "./components/CampaignTable/KeywordsTable"
import AdsTable from "./components/CampaignTable/AdsTable"
import CampaignsTableControl from "./components/Header/CampaignsTableControl"
import KeywordsTableControl from "./components/Header/KeywordsTableControl"

import styles from "./ManageCampaign.module.scss"

const campaignViewOptions = [...allCampaignViewOptions]
const campaignTypeOptions = [...allCampaignTypeOptions]

type DashboardProps = {
  className?: string
}

const ManageCampaign: React.FC<DashboardProps> = observer(({ className }) => {
  const {
    manageCampaignStore,
    accountStore: { accountId },
  } = useStore()
  const { adCopyManagement } = useController()

  const history = useHistory()
  const location = useLocation()

  const [view, setView] = React.useState<string>(campaignViewOptions[0])

  const [selectedCampaigns, setCampaigns] = React.useState<string[]>([])
  const [selectedAdGroups, setAdGroups] = React.useState<string[]>([])

  const [showNegativeKeywords, setNegativeKeywords] =
    React.useState<boolean>(false)

  const campaignTypeSelectable = useLocalStore(
    () =>
      new SelectableList<string>({
        options: campaignTypeOptions,
        selected: campaignTypeOptions,
        multiselect: true,
      })
  )

  const campaignContext = React.useMemo(
    () => ({ selectedCampaigns, setCampaigns }),
    [selectedCampaigns, setCampaigns]
  )

  const adGroupsContext = React.useMemo(
    () => ({ selectedAdGroups, setAdGroups }),
    [selectedAdGroups, setAdGroups]
  )

  React.useEffect(() => {
    history.replace(`${location.pathname}${location.search}`)
  }, [])

  React.useEffect(() => {
    if (accountId) adCopyManagement.loadCampaigns(accountId)
  }, [accountId])

  React.useEffect(() => {
    if (view === "Negative Keywords") {
      setNegativeKeywords(true)
    } else {
      setNegativeKeywords(false)
    }
  }, [view])

  const searchContext = useLocalStore(() => new SearchContextStore())

  return (
    <SelectedCampaignsContext.Provider value={campaignContext}>
      <SelectedAdGroupsContext.Provider value={adGroupsContext}>
        <SearchContext.Provider value={searchContext}>
          <GoogleAdsMainLayout embedded>
            <Container embedded>
              <div className={clsx(styles.root, className)}>
                <ServiceDownInterceptor />

                <Header
                  className={styles.header}
                  viewOptions={campaignViewOptions}
                  view={view}
                  onViewChange={setView}
                />

                {view === "Campaigns" && (
                  <CampaignsTableControl
                    campaignTypeSelectable={campaignTypeSelectable}
                  />
                )}
                {(view === "Keywords" || view === "Negative Keywords") && (
                  <KeywordsTableControl
                    isNegativeKeyword={showNegativeKeywords}
                    onKeywordsSwitch={setNegativeKeywords}
                  />
                )}
                {(view === "Keywords" || view === "Negative Keywords") && (
                  <LowPerformanceKeywords />
                )}

                <div className={styles.body}>
                  {view === "Campaigns" && (
                    <CampaignTable
                      campaignTypes={campaignTypeSelectable.selectedList}
                    />
                  )}
                  {(view === "Keywords" || view === "Negative Keywords") && (
                    <KeywordsTable negativeKeyword={showNegativeKeywords} />
                  )}
                  {view === "Ad Copy" && <AdsTable />}
                </div>
              </div>

              {!manageCampaignStore.updateLoading &&
                manageCampaignStore.adCopyEditor.task instanceof
                  EditAdCopyTask && (
                  <EditGoogleAdCopySidebar
                    task={manageCampaignStore.adCopyEditor.task}
                    isOpened
                  />
                )}

              {!manageCampaignStore.updateLoading &&
                manageCampaignStore.adCopyEditor.task instanceof
                  DeleteAdCopyTask && (
                  <DeleteAndPublishAdCopyModal
                    task={manageCampaignStore.adCopyEditor.task}
                    onClose={manageCampaignStore.adCopyEditor.closeTask}
                    isOpened
                  />
                )}
            </Container>
          </GoogleAdsMainLayout>
        </SearchContext.Provider>
      </SelectedAdGroupsContext.Provider>
    </SelectedCampaignsContext.Provider>
  )
})

export default ManageCampaign
