import intersection from "lodash/intersection"
import memoize from "lodash/memoize"

import { darkColors } from "@components/ui/Charts/utils"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { ReportGroupName, ReportLevelName } from "@framework/types/report"
import { LabelMapper, Option } from "@framework/types/types"

export const reportLevelDescription: Record<ReportLevelName, LabelMapper> = {
  platform: {
    name: "platform",
    label: "Platform",
    shortName: "P",
    color: "#17899e",
  },
  account: {
    name: "account",
    label: "Account",
    shortName: "A",
    color: "#92cbd6",
  },
  campaignType: {
    name: "campaignType",
    label: "Campaign Type",
    shortName: "CT",
    color: "#93d692",
  },
  campaign: {
    name: "campaign",
    label: "Campaign",
    shortName: "CP",
    color: "#5cbd9f",
  },
  week: {
    name: "week",
    label: "Week",
    shortName: "W",
    color: "#58B7C9",
  },
  month: {
    name: "month",
    label: "Month",
    shortName: "M",
    color: "#58B7C9",
  },
  adSet: {
    name: "adSet",
    label: "Ad Set",
    shortName: "AS",
    color: "#58B7C9",
  },
  unknown: {
    name: "unknown",
    label: "Unknown",
    shortName: "?",
    color: "#000",
  },
}

export const reportGroupOptions: ReportGroupName[] = [
  "Account",
  "Platform",
  "Months",
  "Weeks",
]

export const reportGroupsLevels: Record<ReportGroupName, ReportLevelName[]> = {
  Account: ["account", "platform", "campaignType", "campaign", "adSet"],
  Platform: ["platform", "account", "campaignType", "campaign", "adSet"],
  Months: ["account", "month", "platform"],
  Weeks: ["account", "week", "platform"],
}

export const getReportGroupLevelDescriptions = (group: ReportGroupName) =>
  reportGroupsLevels[group].map(
    (levelName) => reportLevelDescription[levelName]
  )

export type Platform = "googleAds" | "metaAds" // | "bing"

export type CampaignGroup =
  | "paidSearch"
  | "pMax"
  | "video"
  | "display"
  | "demandGen"
  | "shopping"
  | "all"

const makePlatformOption = <T extends string = Platform>(
  platform: T
): { value: T; label: string; color: string } => {
  switch (platform) {
    case "googleAds":
      return {
        value: platform,
        label: "Google",
        color: darkColors[0],
      }
    case "metaAds":
      return {
        value: platform,
        label: "Meta",
        color: darkColors[1],
      }
    // case "bing":
    //   return {
    //     value: platform,
    //     label: "Bing",
    //     color: darkColors[2],
    //   }
    default:
      return {
        value: platform,
        label: "Unknown",
        color: "#000",
      }
  }
}

export const getPlatformOption = memoize(makePlatformOption)

const makeCampaignGroupOption = <T extends string = CampaignGroup>(
  platform: T
): { value: T; label: string; color: string } => {
  switch (platform) {
    case "paidSearch":
      return {
        value: platform,
        label: "Google Ads - Paid Search",
        color: darkColors[0],
      }
    case "pMax":
      return {
        value: platform,
        label: "Google Ads - PMax",
        color: darkColors[0],
      }
    case "video":
      return {
        value: platform,
        label: "Google Ads - Video",
        color: darkColors[0],
      }
    case "display":
      return {
        value: platform,
        label: "Google Ads - Display",
        color: darkColors[0],
      }
    case "demandGen":
      return {
        value: platform,
        label: "Google Ads - Demand Gen",
        color: darkColors[0],
      }
    case "shopping":
      return {
        value: platform,
        label: "Google Ads - Shopping",
        color: darkColors[0],
      }
    case "all":
      return {
        value: platform,
        label: "Meta Ads - All Campaigns",
        color: darkColors[1],
      }
    default:
      return {
        value: platform,
        label: "Unknown",
        color: "#000",
      }
  }
}

export const getCampaignGroupOption = memoize(makeCampaignGroupOption)

export const platformOptions: Platform[] = [
  "googleAds",
  "metaAds",
  // "bing",
]

export const platformCampaignTypeMap: Partial<
  Record<Platform, CampaignGroup[]>
> = {
  googleAds: [
    "paidSearch",
    "pMax",
    "video",
    "display",
    "demandGen",
    "shopping",
  ],
  metaAds: ["all"],
}

export const getPlatformOptions = (platforms: string[]) =>
  platforms.flatMap((pla) =>
    (platformCampaignTypeMap[pla as Platform] ?? []).map((it) =>
      getCampaignGroupOption(it)
    )
  )

export const bundlePlatformParams = (
  platforms: Platform[],
  channels: CampaignGroup[]
): Partial<Record<Platform, CampaignGroup[]>> =>
  platforms.reduce<Partial<Record<Platform, CampaignGroup[]>>>(
    (filter, platform) => {
      const channelGroups = intersection(
        platformCampaignTypeMap[platform],
        channels
      )

      if (channelGroups.length) return { ...filter, [platform]: channelGroups }

      return filter
    },
    {}
  )

export const periodicityOptions: Option<PerformanceReportPeriodicity>[] = [
  {
    value: "daily",
    label: "Day",
  },
  {
    value: "weekly",
    label: "Week",
  },
  {
    value: "monthly",
    label: "Month",
  },
]
