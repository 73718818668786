/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from "react"
import { useField } from "formik"
import { observer } from "mobx-react-lite"

import Label from "@components/ui/Label/Label"
import SimpleMultiselect, {
  SimpleMultiselectProps,
} from "@components/ui/DropDown/SimpleMultiselect"

interface FormMultiSelectFieldProps extends SimpleMultiselectProps {
  name: string
  label?: string
}

export const FormSimpleMultiSelectField: React.FC<FormMultiSelectFieldProps> =
  observer(({ name, label, disabled, ...rest }) => {
    const [field, meta, helpers] = useField(name)

    const withValidate = !!meta.touched

    const handleSelect = (value: string, some: any) => {
      const has = field.value.includes(value)
      helpers.setValue(
        has
          ? field.value.filter((it: string) => it !== value)
          : [...field.value, value]
      )
    }

    return (
      <Label
        label={label}
        error={withValidate && meta.touched ? meta.error : null}
      >
        <SimpleMultiselect
          value={field.value}
          onSelect={handleSelect}
          disabled={disabled}
          {...rest}
        />
      </Label>
    )
  })

export default FormSimpleMultiSelectField
