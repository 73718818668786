import React from "react"
import { nanoid } from "nanoid"
import { FormikProvider, useFormik } from "formik"

import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import { AnswerForm } from "./AnswerForm"

import styles from "./AnswersList.module.scss"

interface AnswersListProps {}

export const AnswersList: React.FC<AnswersListProps> = () => {
  const formik = useFormik({
    initialValues: {
      list: [...data],
    },
    onSubmit: () => {},
  })
  return (
    <FormikProvider value={formik}>
      <div className={styles.root}>
        <ScrollContainer className={styles.scrollable}>
          <div className={styles.list}>
            {data.map((it, idx) => (
              <AnswerForm index={idx} key={it.id} />
            ))}
          </div>
        </ScrollContainer>
      </div>
    </FormikProvider>
  )
}

export default AnswersList

export const data = [
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
  {
    id: nanoid(),
    question: "Are mental health days legal",
    answer:
      "Mental health days are legal. The legalize were confirmed 3 years ago by Parliament. Mental health days are legal. The legalize were confirmed 3 years ago by Parliament.",
  },
]
