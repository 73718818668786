import { observable, action, computed, reaction } from "mobx"
import sortBy from "lodash/sortBy"

import moment from "moment"
import { CheckbookReport } from "@framework/types/clientCheckbook"
import clientService from "@services/client.service"
import { extractUniqYears, transformCheckbookReport } from "./dataTransformers"
import ClientStore from "../client/client"

export class ClientCheckbookStore {
  // INJECTIONS

  @observable clientStore: ClientStore

  // STATE

  @observable summaryReport: CheckbookReport | null = null

  @observable accountNumber: number | null = null

  @observable accountReportList: CheckbookReport[] | null = null

  @observable reportLoading: boolean = false

  @observable loadingError: string | null = null

  @observable additionalAdSpend = 0

  @observable activeYearIndex: number = 0

  // COMPUTED

  @computed get years(): number[] {
    if (!this.summaryReport?.checkbooks) return []
    return sortBy(extractUniqYears(this.summaryReport.checkbooks))
  }

  @computed get activeYear(): number | undefined {
    return this.years[this.activeYearIndex]
  }

  @computed get activeYearCheckbook() {
    if (this.summaryReport?.checkbooks != null && this.activeYear != null)
      return this.summaryReport.checkbooks.filter(
        ({ month }) => moment(month).year() === this.activeYear
      )
    return []
  }

  @computed get initialPlanedAdSpend() {
    return this.activeYearCheckbook.reduce(
      (acc, { planned }) =>
        (!Number.isNaN(Number(planned)) ? Number(planned) : 0) + acc,
      0
    )
  }

  @computed get actualAdSpend() {
    return this.activeYearCheckbook.reduce(
      (acc, { actual }) =>
        (!Number.isNaN(Number(actual)) ? Number(actual) : 0) + acc,
      0
    )
  }

  @computed get contractAdSpend() {
    return this.clientStore.client?.yearlyAdBudget ?? 0
  }

  @computed get totalAdSpend(): number {
    return this.additionalAdSpend + this.initialPlanedAdSpend
  }

  @computed get remainAdSpend(): number {
    return this.contractAdSpend - this.actualAdSpend
  }

  // CONSTRUCTOR

  constructor(clientStore: ClientStore) {
    this.clientStore = clientStore

    reaction(
      () => this.clientStore.clientId,
      () => this.cleanUp()
    )

    reaction(
      () => this.years,
      (years) => {
        const currentYear = moment().startOf("year").year()
        const currYearIdx = years.findIndex((year) => year === currentYear)
        this.setActiveYearIndex(
          currYearIdx < 0 ? this.years.length - 1 : currYearIdx
        )
      }
    )
  }

  // ACTIONS

  @action cleanUp = () => {
    this.summaryReport = null
    this.accountNumber = null
    this.accountReportList = null
    this.loadingError = null
  }

  @action loadClientCheckbookReport = async (clientId: number) => {
    this.reportLoading = true
    this.loadingError = null
    try {
      const response = await clientService.getClientCheckbook(clientId)
      const summaryReport = await transformCheckbookReport(
        response.data.data.summary
      )

      const accountReportList = await Promise.all(
        response.data.data.allCheckbooks.map(transformCheckbookReport)
      )
      this.summaryReport = summaryReport
      this.accountReportList = accountReportList
      this.accountNumber = response.data.data.numberOfAccounts
    } catch (e) {
      this.loadingError = "Can't load data"
    } finally {
      this.reportLoading = false
    }
  }

  @action setActiveYearIndex = (index: number) => {
    this.activeYearIndex = index
  }
}

export default ClientCheckbookStore
