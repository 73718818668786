import { observable } from "mobx"
import OpportunitiesStore from "@store/opportunities/opportunities"
import { RecommendedKeyword } from "@framework/types/opportunities"
import TopKeywordsStore from "./top-keyword.store"
import RelatedTopicsStore from "./related-topics.store"
import RelatedQuestionsStore from "./related-questions.store"

export class KeywordInsightsStore {
  @observable readonly opportunitiesStore: OpportunitiesStore

  @observable readonly topKeywordsStore: TopKeywordsStore

  @observable readonly relatedTopicsStore: RelatedTopicsStore

  @observable readonly relatedQuestionsStore: RelatedQuestionsStore

  @observable readonly keyword: RecommendedKeyword

  constructor(dependencies: {
    keyword: RecommendedKeyword
    relatedTopicsStore: RelatedTopicsStore
    topKeywordsStore: TopKeywordsStore
    opportunitiesStore: OpportunitiesStore
    relatedQuestionsStore: RelatedQuestionsStore
  }) {
    this.opportunitiesStore = dependencies.opportunitiesStore
    this.topKeywordsStore = dependencies.topKeywordsStore
    this.relatedTopicsStore = dependencies.relatedTopicsStore
    this.keyword = dependencies.keyword
    this.relatedQuestionsStore = dependencies.relatedQuestionsStore
  }
}

export default KeywordInsightsStore
