import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"

import styles from "./TableCell.module.scss"

interface HeaderCellProps {
  label?: string
  onSort?: any
  isSorted?: boolean
  sortDirection?: boolean
  className?: string
}

const HeaderCell: React.FC<HeaderCellProps> = ({
  onSort,
  isSorted,
  sortDirection,
  label = "",
  className,
}) => {
  const isSortable = !!onSort
  return (
    <Typography
      onClick={onSort}
      className={clsx(styles.defaultPaddings, className, {
        [styles.clickable]: isSortable,
      })}
      type="h5"
      color="black70Color"
    >
      {label}
      <span className={styles.sortArrow}>
        {isSortable ? (
          <Icon
            name={isSorted ? "arrow-right" : "none"}
            rotateAngle={sortDirection ? 90 : -90}
          />
        ) : null}
      </span>
    </Typography>
  )
}

export default HeaderCell
