import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import ConfirmModal from "@components/modals/ConfirmModal/ConfirmModal"

type LogoutConfirmModalProps = {
  isOpen: boolean
  onClose: () => void
}

const LogoutConfirmModal: React.FC<LogoutConfirmModalProps> = observer(
  ({ isOpen, onClose }) => {
    const {
      authStore: { logout },
    } = useStore()

    return (
      <ConfirmModal
        title="Log Out"
        message="Are you sure want to log out from the Adoro?"
        isOpen={isOpen}
        onClose={onClose}
        applyText="Log Out"
        onApply={logout}
      />
    )
  }
)

export default LogoutConfirmModal
