import React from "react"
import { observer, useLocalStore } from "mobx-react-lite"
import { AutoSizer, List } from "react-virtualized"
import InfiniteLoader from "react-window-infinite-loader"

import { useStore } from "@store/index"
import NoData from "@components/ui/NoData/NoData"
import { MetricNameType } from "@framework/types/metrics"
import {
  TableSortContext,
  TableSortContextStore,
} from "@framework/prototypes/TableSortContext"
import Label from "@components/ui/CheckBox/Label/Label"
import Templates from "@components/ui/Templates"
import {
  SearchContext,
  SearchContextStore,
} from "@framework/prototypes/SearchContext/index"
import SearchInput from "@framework/prototypes/SearchContext/SearchInput"
import { SortByType } from "@framework/types/types"
import CampaignsSelect from "@framework/prototypes/CampaignsSelect/CampaignsSelect"
import { useSelectedCampaignsContext } from "@framework/prototypes/SelectedCampaignsContext"
import Table from "./Table/Table"
import { insightOptions } from "./constants"
import CampaignRow from "./CampaignRow"
import useCampaignsReports from "./useCampaignsReports"
import Header from "./CampaignTableHeader"
import CampaignInsightsTemplate from "./CampaignInsightsTemplate"
import ContentMenuTemplate from "./ContentMenuTemplate"

import styles from "./InsightTable.module.scss"

const defaultSortOption: SortByType = { value: "Cost", direction: false }

export const CampaignsTable: React.FC<{
  sidebarSlot?: React.ReactNode
}> = observer(({ sidebarSlot }) => {
  const {
    accountDashboardStore: {
      campaignInsightsStore: { topCampaignStore: campaignsStore },
    },
  } = useStore()

  const searchStore = useLocalStore(() => new SearchContextStore())
  const sortStore = useLocalStore(
    () =>
      new TableSortContextStore({
        defaultValue: defaultSortOption,
      })
  )

  const [columns, setColumns] = React.useState<MetricNameType[]>(
    insightOptions.slice(0, 5)
  )

  const { selectedCampaigns, setCampaigns } = useSelectedCampaignsContext()

  const campaigns = campaignsStore.data ?? []

  const handleChangeColumn = (name: string, idx: number) => {
    setColumns((prev: MetricNameType[]) => {
      const newValue = [...prev]
      const swapIdx = prev.findIndex((it) => it === name)
      newValue[idx] = name as MetricNameType
      if (swapIdx >= 0) newValue[swapIdx] = prev[idx]
      return newValue
    })
  }

  const { sortedCampaigns, inProgress } = useCampaignsReports({
    campaigns: selectedCampaigns,
    sortBy: sortStore.sortBy,
    searchQuery: searchStore.searchQuery,
  })

  const total = sortedCampaigns.length ?? 0
  const isLoading = campaignsStore.isLoading || inProgress

  return (
    <SearchContext.Provider value={searchStore}>
      <CampaignInsightsTemplate
        loading={isLoading}
        controlSlot={
          <Label text="Campaigns" textPosition="before">
            <CampaignsSelect
              options={campaigns}
              value={selectedCampaigns}
              onChange={setCampaigns}
              className={styles.dropdown}
            />
          </Label>
        }
      >
        <ContentMenuTemplate sidebarSlot={sidebarSlot}>
          <Templates.Header offset="small" left={<SearchInput />} />

          <TableSortContext.Provider value={sortStore}>
            <Table>
              <Header
                options={insightOptions}
                nameLabel="Campaign Name"
                columns={columns}
                onChangeColumn={handleChangeColumn}
              />

              <div className={styles.tableBody}>
                {total > 0 ? (
                  <InfiniteLoader
                    isItemLoaded={() => true}
                    loadMoreItems={() => {}}
                    threshold={10}
                    minimumBatchSize={10}
                    itemCount={total}
                  >
                    {({ onItemsRendered, ref }) => (
                      <AutoSizer>
                        {(size) => (
                          <List
                            ref={ref}
                            onItemsRendered={onItemsRendered}
                            rowHeight={68}
                            rowRenderer={({ index, style }) => {
                              const item = sortedCampaigns[index]
                              return (
                                <div style={style} key={item.Id}>
                                  <CampaignRow data={item} mapper={columns} />
                                </div>
                              )
                            }}
                            height={size.height}
                            width={size.width}
                            rowCount={total}
                          />
                        )}
                      </AutoSizer>
                    )}
                  </InfiniteLoader>
                ) : (
                  <NoData className={styles.placeholder}>
                    {isLoading
                      ? "Loading..."
                      : selectedCampaigns.length
                      ? "No data found"
                      : "No campaigns selected"}
                  </NoData>
                )}
              </div>
            </Table>
          </TableSortContext.Provider>
        </ContentMenuTemplate>
      </CampaignInsightsTemplate>
    </SearchContext.Provider>
  )
})

export default CampaignsTable
