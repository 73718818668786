import React from "react"

import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Stack from "@components/ui/Stack/Stack"

import styles from "./RegenerateAdCopyForm.module.scss"

interface FooterProps {
  loading?: boolean
  onClose?: React.MouseEventHandler
}

export const Footer: React.FC<FooterProps> = ({ loading, onClose }) => (
  <Templates.Header
    offset="none"
    className={styles.footer}
    left={
      <Stack direction="row">
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    }
    right={
      <Button disabled={loading} type="submit" color="primary">
        Regenerate
      </Button>
    }
  />
)

export default Footer
