import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Typography from "@components/ui/Typography/Typography"
import EditMemberModal from "@components/modals/EditMemberModal/EditMemberModal"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import Table from "../components/Table/UserTable"

export const UserManagement: React.FC = observer(() => {
  const {
    settings: {
      managementStore,
      userList,
      isContentLoading,
      isContentReady,
      loadContent,
    },
  } = useStore()

  useEffect(() => {
    if (!isContentLoading && !isContentReady) loadContent()
  }, [])

  return (
    <Stack gutter="32" align="stretch">
      <Stack gutter="16">
        <Typography type="h2">{userList?.list?.length} active users</Typography>

        {isContentLoading ? (
          <Loader />
        ) : isContentReady ? (
          <Table users={userList?.list ?? []} />
        ) : (
          <NoData />
        )}
      </Stack>

      <Stack gutter="16">
        <Stack gutter="8">
          <Typography type="h2">Add new member</Typography>
          <Typography color="darkGray">
            As a Agency you can invite a new team member of your company
          </Typography>
        </Stack>

        <Button color="secondary" onClick={managementStore.createUser}>
          Add new member
        </Button>
      </Stack>

      {!!managementStore.isUserInEdit && (
        <EditMemberModal isOpen onClose={managementStore.dropUser} />
      )}
    </Stack>
  )
})

export default UserManagement
