import { useFormikContext } from "formik"
import React, { useEffect, useMemo } from "react"
import { useStore } from "@store/index"

interface FormControllerPropsType {}

const FormController: React.FC<FormControllerPropsType> = () => {
  const formik = useFormikContext<any>()

  const {
    manageProfileStore: { setSubmitCallback },
    checkbookStore: { campaignDate, currentCampaignDate },
  } = useStore()

  const isDateTouched =
    campaignDate?.toString() !== currentCampaignDate?.toString()

  const isTouched = useMemo(
    () => isDateTouched || (formik.dirty && Object.keys(formik.touched).length),
    [formik.dirty, formik.touched, isDateTouched]
  )

  useEffect(() => {
    setSubmitCallback(
      isTouched && campaignDate && formik?.submitForm
        ? formik?.submitForm
        : undefined
    )
  }, [isTouched, campaignDate, formik?.submitForm, setSubmitCallback])

  return null
}

export default FormController
