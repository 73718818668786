import {
  IReactionDisposer,
  action,
  autorun,
  computed,
  observable,
  reaction,
  when,
} from "mobx"

import RootStore from "@store/RootStore"
import {
  AdCopyCondition,
  AdCopyDetails,
  AdCopyDetailsData,
  AdCopyInstance,
  AdCopyPrompts,
  AdCopyStatus,
  EditAdCopyData,
} from "@framework/types/adCopy"
import accountService from "@services/account.service"
import { ID } from "@framework/types/types"
import accountCampaignService from "@services/account-campaign.service"
import { AdCopyPreview } from "@framework/prototypes/AdCopy/Preview/types"
import { makeAdCopyPreviews } from "@framework/prototypes/AdCopy/Preview/utils"
import { DeepPartial } from "@framework/types/utils"
import AdCopyStore from "./ad-copy-management.store"
import {
  transformAdCopiesResponse,
  transformAdCopyDetails,
  transformCampaignsResponse,
} from "./dataTransformers"
import EditAdCopyTask from "./EditAdCopyTask"
import DuplicateAdCopyTask from "./DuplicateAdCopyTask"
import DeleteAdCopyTask from "./DeleteAdCopyTask"
import RestoreAdCopyTask from "./RestoreAdCopyTask"
import { FailedResult, SuccessResult } from "./types"

const DEFAULT_ERROR = "Unexpected error"

export class AdCopyManagementController {
  adCopyStore: AdCopyStore

  @computed get copiesCollection() {
    return this.adCopyStore.copiesCollection
  }

  @computed get campaignsCollection() {
    return this.adCopyStore.campaignsCollection
  }

  @computed get activeAdCopies() {
    return this.adCopyStore.activeAdCopies
  }

  @computed get header() {
    return this.adCopyStore.header
  }

  constructor(root: RootStore) {
    this.adCopyStore = root.adCopyStore
  }

  @observable loadAdCopiesWorker: IReactionDisposer | null = null

  @action startLoadAdCopiesWorker = async (accountId: ID) => {
    this.stopLoadAdCopiesWorker()

    this.loadAdCopiesWorker = reaction(
      () => this.activeAdCopies.selectedAdGroups,
      this.copiesCollection.requestReload
    )

    this.loadAdCopiesWorker = autorun(() => {
      if (!this.copiesCollection.isSync)
        this.loadAdCopies(accountId, this.activeAdCopies.selectedAdGroups)
    })
  }

  @action stopLoadAdCopiesWorker = async () => {
    if (this.loadAdCopiesWorker != null) this.loadAdCopiesWorker()
  }

  @action loadCampaigns = async (accountId: ID) => {
    try {
      this.campaignsCollection.startLoading()
      this.activeAdCopies.resetSelected()

      const response = await accountCampaignService.getCampaignsList(accountId)

      const { campaigns, campAdGroups } = await transformCampaignsResponse(
        response.data.data
      )

      this.campaignsCollection.setCampaigns(campaigns)
      this.campaignsCollection.setAdGroups(campAdGroups)
      this.campaignsCollection.endLoading()
    } catch (error) {
      this.campaignsCollection.endLoading(DEFAULT_ERROR)
    }
  }

  @action loadAdCopies = async (accountId: ID, adGroupIds: ID[]) => {
    const store = this.copiesCollection
    try {
      store.isLoading = true
      store.error = null
      store.isSync = false
      store.isGoogleSync = false

      if (!adGroupIds.length) {
        this.copiesCollection.setAdCopies([])
        this.activeAdCopies.updateActiveCampaignCopiesIndex()
        store.isGoogleSync = true
        this.updateFrame()
        return null
      }

      const groupsIds = adGroupIds.map((it) => Number(it))

      const copiesResponse = await accountService.getAdCopiesList(
        accountId,
        groupsIds
      )

      store.setAdCopies(
        transformAdCopiesResponse(copiesResponse.data.data.records)
      )

      this.activeAdCopies.updateActiveCampaignCopiesIndex()
      this.updateFrame()
      store.isSync = true

      const compareResponse = await accountService.getAdCopiesCompareList(
        accountId,
        groupsIds
      )

      this.copiesCollection.updateAdCopies(
        transformAdCopiesResponse(compareResponse.data.data.records)
      )

      store.isGoogleSync = true
    } catch (error) {
      store.setAdCopies([])
      this.updateFrame()
      store.error = DEFAULT_ERROR
    } finally {
      store.isLoading = false
      store.isSync = true
    }
    return store.error
  }

  @action createAdCopy = async (accountId: ID, data: AdCopyInstance) => {
    try {
      await accountService.createAdCopy(accountId, data)
    } catch (error) {
      return DEFAULT_ERROR
    } finally {
      this.copiesCollection.requestReload()
    }
    return null
  }

  @action duplicateAdCopy = async (
    accountId: ID,
    campaignId: ID,
    adGroup: ID[],
    adCopies: ID[]
  ) => {
    try {
      await accountService.cloneAdCopy(
        accountId,
        adGroup.map((id) => ({
          adGroupId: Number(id),
          campaignId: Number(campaignId),
        })),
        adCopies.map((id) => Number(id))
      )
    } catch (error) {
      return DEFAULT_ERROR
    } finally {
      this.copiesCollection.requestReload()
    }
    return null
  }

  @action deleteAdCopies = async (accountId: ID, adCopies: ID[]) => {
    try {
      await accountService.removeAdCopies(accountId, adCopies)
    } catch (error) {
      return DEFAULT_ERROR
    } finally {
      this.copiesCollection.requestReload()
    }
    return null
  }

  @action restoreDeletedAdCopies = async (accountId: ID, adCopies: ID[]) => {
    try {
      await accountService.restoreAdCopies(accountId, adCopies)
    } catch (error) {
      return DEFAULT_ERROR
    } finally {
      this.copiesCollection.requestReload()
    }
    return null
  }

  @action publishChanges = async (accountId: ID) => {
    try {
      this.adCopyStore.isPublishing = true

      await accountService.publishAdCopiesChanges(accountId)
    } catch (error) {
      return `An error occurred during data transmission. Please try again later.`
    } finally {
      this.adCopyStore.isPublishing = false
      this.copiesCollection.requestReload()
    }
    return null
  }

  @action resetChanges = async (accountId: ID) => {
    try {
      this.adCopyStore.isPublishing = true

      await accountService.resetAdCopiesChanges(accountId)
    } catch (error) {
      return DEFAULT_ERROR
    } finally {
      this.adCopyStore.isPublishing = false
      this.copiesCollection.requestReload()
    }
    return null
  }

  @action selectCampaign = async (campaignId: string, value: boolean) => {
    try {
      this.activeAdCopies.selectCampaign(campaignId, value)
    } catch (error: any) {
      if (error?.message === "NONE_AD_GROUPS")
        return "You can’t select the Campaign because it doesn’t have any Ad Groups"
      return "Unexpected error"
    }
    return null
  }

  @action selectAdGroup = async (adGroupId: string, value: boolean) => {
    this.activeAdCopies.selectAdGroup(adGroupId, value)
  }

  @action updateFrame = async (
    page: number = this.activeAdCopies.frame?.page ?? 0,
    pageSize: number = this.activeAdCopies.frame?.pageSize ?? 10
  ) => {
    await this.activeAdCopies.updateGroups(
      this.activeAdCopies.calcFrame(page, pageSize)
    )
  }

  @action sortBy = async (name: string) => {
    this.header.sortBy(name)
    await this.activeAdCopies.updateGroups()
  }

  @action updateAdCopy = async (
    accountId: ID,
    adCopyIds: ID[],
    data: Partial<EditAdCopyData>
  ): Promise<string | null> => {
    try {
      await accountService.updateAdCopy(
        accountId,
        adCopyIds.map((it) => Number(it)),
        data
      )
    } catch (error) {
      return "Unexpected error while editing AdCopy"
    } finally {
      this.copiesCollection.requestReload()
    }
    return null
  }

  @action updateStatus = async (
    accountId: ID,
    adCopyId: ID,
    value: AdCopyStatus
  ): Promise<string | null> => {
    try {
      await accountService.updateAdCopy(accountId, [Number(adCopyId)], {
        status: value,
      })

      const adCopy = this.adCopyStore.getAdCopyByLocalID(adCopyId.toString())
      if (!adCopy) throw new Error("")
      adCopy.status = value as AdCopyCondition
    } catch (error) {
      return "Unexpected error while updating AdCopy status"
    } finally {
      this.copiesCollection.requestReload()
    }
    return null
  }

  @action createEditAdCopyTask = async (accountId: ID, adCopyIds: ID[]) => {
    const validForEditing = adCopyIds.filter((it) => {
      const adCopy = this.adCopyStore.getAdCopyByLocalID(it.toString())
      return adCopy != null && adCopy.modified !== "delete"
    })

    if (validForEditing.length !== adCopyIds.length)
      throw new Error("Ad Copy was deleted")

    const mode = adCopyIds.length > 0 ? "edit" : "add"
    const task = new EditAdCopyTask(validForEditing, mode)
    try {
      task.isLoading = true
      this.adCopyStore.editor.setAdCopyTask(task)

      if (!validForEditing.length || mode === "add") return null

      const response = await accountService.getAdCopiesDetailsByLocalIds(
        accountId,
        validForEditing.map((it) => Number(it))
      )

      if (response.data.data == null) throw new Error("Incorrect response type")

      task.setAdCopies(response.data.data.map(transformAdCopyDetails))
    } catch (error) {
      task.error = DEFAULT_ERROR
      return task.error
    } finally {
      task.isLoading = false
    }
    return null
  }

  @action createAddByAIAdCopyTask = (data: DeepPartial<AdCopyDetailsData>) => {
    const task = new EditAdCopyTask([], "add", true)
    try {
      task.isLoading = true

      task.setAdCopies([data])

      this.adCopyStore.editor.setAdCopyTask(task)
    } catch (error) {
      task.error = DEFAULT_ERROR
      return task.error
    } finally {
      task.isLoading = false
    }
    return null
  }

  @action generateAdCopyWithAI = async (
    accountId: ID,
    website: string,
    data: AdCopyPrompts
  ): Promise<SuccessResult<AdCopyPreview[]> | FailedResult> => {
    try {
      const response = await accountService.generateAdCopyWithAI(
        accountId,
        data
      )

      if (response.data.data == null) throw new Error("Unexpected response")

      if (
        response.data.data?.descriptions?.length < 1 ||
        response.data.data?.headlines?.length < 1
      )
        throw new Error("Invalid response")

      const res = response.data.data

      const adCopyPreviews = makeAdCopyPreviews(
        website,
        res.headlines,
        res.descriptions
      )

      return {
        status: "SUCCESS",
        data: adCopyPreviews,
      }
    } catch (error) {
      return { status: "FAILED", message: "Unexpected error" }
    }
  }

  @action rephraseAdCopyWithAI = async (
    accountId: ID,
    prompts: AdCopyPrompts,
    data:
      | {
          headline: string
        }
      | {
          description: string
        }
  ): Promise<
    SuccessResult<{ headline?: string; description?: string }> | FailedResult
  > => {
    try {
      const response = await accountService.rephraseAdCopyWithAI(accountId, {
        ...prompts,
        rephraseHeadlines: "headline" in data ? [data.headline] : [],
        rephraseDescriptions: "description" in data ? [data.description] : [],
      })

      const result = response.data?.data

      if (result == null) throw new Error("Unexpected response")

      if ("headline" in data && !result.headlines?.length)
        throw new Error("Unexpected response")

      if ("description" in data && !result.descriptions?.length)
        throw new Error("Unexpected response")

      return {
        status: "SUCCESS",
        data: {
          headline: result.headlines?.[0],
          description: result.descriptions?.[0],
        },
      }
    } catch (error) {
      return { status: "FAILED", message: "Unexpected error" }
    }
  }

  @action createDeleteAdCopyTask = async (adCopyIds: ID[]) => {
    const validForDeleting = adCopyIds.filter(
      (it) =>
        this.adCopyStore.getAdCopyByLocalID(it.toString())?.modified !==
        "delete"
    )
    this.adCopyStore.editor.setAdCopyTask(
      new DeleteAdCopyTask(validForDeleting)
    )
  }

  @action createDuplicateAdCopyTask = async (adCopyIds: ID[]) => {
    const validForDuplicating = adCopyIds.filter(
      (it) =>
        this.adCopyStore.getAdCopyByLocalID(it.toString())?.modified !==
        "delete"
    )
    this.adCopyStore.editor.setAdCopyTask(
      new DuplicateAdCopyTask(validForDuplicating)
    )
  }

  @action createRestoreAdCopyTask = async (adCopyIds: ID[]) => {
    const validForRestore = adCopyIds.filter(
      (it) =>
        this.adCopyStore.getAdCopyByLocalID(it.toString())?.modified ===
        "delete"
    )
    this.adCopyStore.editor.setAdCopyTask(
      new RestoreAdCopyTask(validForRestore)
    )
  }
}

export default AdCopyManagementController
