import React, { useEffect, useRef, useState } from "react"
import { Line } from "react-chartjs-2"
import clsx from "clsx"
import {
  Chart as ChartJS,
  CategoryScale,
  LineElement,
  ChartData,
  Filler,
} from "chart.js"

import { simpleChartOptions } from "./config"
import { NULL } from "../utils"
import { createLineGradient, createRadialGradient } from "./utils"

import styles from "./AreaChart.module.scss"

ChartJS.register(CategoryScale, LineElement, Filler)

export type AreaChartProps = {
  className?: string
  data: number[]
}

export const chartDataProvider = (
  data: number[],
  chart: ChartJS | null
): ChartData<"line"> => ({
  labels: data.map((_, idx) => idx.toString()),
  datasets: [
    {
      type: "line" as const,
      data,
      borderWidth: 2,
      tension: 0.5,
      borderColor: createLineGradient(chart?.ctx, chart?.chartArea),
      pointBackgroundColor: "white",
      fill: {
        target: "origin",
        above: createRadialGradient(chart?.ctx, chart?.chartArea),
      },
    },
  ],
})

const SimpleAreaChart: React.FC<AreaChartProps> = React.memo(
  ({ data, className }) => {
    const chartRef = useRef<ChartJS<"line">>(null)

    const [chartData, setChartData] = useState<ChartData<"line">>({
      datasets: [],
    })

    useEffect(() => {
      setChartData(chartDataProvider(data, chartRef.current))
    }, [data])

    return (
      <div className={clsx(styles.root, className)}>
        <Line
          ref={chartRef}
          data={chartData}
          options={simpleChartOptions}
          height={NULL}
          width={NULL}
        />
      </div>
    )
  }
)

export default SimpleAreaChart
