/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
import React from "react"
import clsx from "clsx"

import { Option } from "@framework/types/types"
import Icon from "@components/ui/Icon/Icon"

import styles from "./Breadcrumbs.module.scss"

export type BreadcrumbsProps = {
  items?: Option[]
  className?: string
  onClick?: (value: Option) => void
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  items = [],
  className,
  onClick,
}) => (
  <div className={clsx(styles.root, className)}>
    {items.map((item, idx) => {
      const { label, value, icon } = item
      return (
        <div
          className={styles.item}
          onClick={() => onClick?.(item)}
          key={value}
        >
          {icon && <Icon name={icon} />}
          {label}
          {idx + 1 !== items.length ? <>&nbsp;{">"}&nbsp;</> : ""}
        </div>
      )
    })}
  </div>
)

export default Breadcrumbs
