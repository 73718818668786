import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import ProgressSubsection from "@pages/PMax/components/EditAssetGroupPage/Assets/ProgressSubsection"
import Label from "@components/ui/Label/Label"
import Headlines from "@pages/PMax/components/EditAssetGroupPage/Assets/Headlines"
import LongHeadlines from "@pages/PMax/components/EditAssetGroupPage/Assets/LongHeadlines"
import Description from "@pages/PMax/components/EditAssetGroupPage/Assets/Description"
import Preview from "@pages/PMax/components/EditAssetGroupPage/Assets/Preview"
import FormTextField from "@framework/prototypes/FormTextField"
import { AssetGroupEntity } from "@framework/types/manageCampaign"
import BusinessName from "@pages/PMax/components/EditAssetGroupPage/Assets/BusinessName"
import styles from "../EditAssetGroupPage/Assets/Assets.module.scss"

interface AssetsProps {
  data: Pick<
    AssetGroupEntity,
    "headlineCount" | "longHeadlineCount" | "descriptionCount"
  >
}
const Assets: FC<AssetsProps> = observer(({ data }) => {
  const headlines = data.headlineCount || []
  const longHeadlines = data.longHeadlineCount || []
  return (
    <section className={styles.assetsWrapper}>
      <Typography type="h2" className={styles.listingTitle}>
        Assets
      </Typography>
      <ProgressSubsection
        images={[]}
        description={data.descriptionCount || []}
        video={[]}
        products={[]}
        headlines={[...headlines, ...longHeadlines]}
      />
      <div className={styles.assetsSections}>
        <div>
          <div className={styles.finalUrl}>
            <Typography type="h2">Final URL</Typography>
            <Typography type="body1" color="gray">
              URL expansion is on
            </Typography>
            <Label label="Final URL" type="body2" className={styles.inputUrl}>
              <FormTextField name="finalUrl" />
            </Label>
          </div>

          <Headlines />
          <LongHeadlines />
          <Description />
          <BusinessName />
        </div>
        <Preview />
      </div>
    </section>
  )
})

export default Assets
