import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import OptionCard from "./OptionCard"

import styles from "./CreateCampaignModal.module.sass"

const campaignOptions = [
  {
    name: "paidSearch",
    label: "Search",
    description:
      "Get in front of high-intent customers at the right time on Google Search",
    logoSrc: "/images/googleAdPaidSearchLogo.svg",
  },
  {
    name: "pMax",
    label: "Performance Max",
    description: "Reach audiences across all of Google with a single campaign",
    logoSrc: "/images/googleAdPMaxLogo.svg",
  },
]

type CreateCampaignModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CreateCampaignModal: React.FC<CreateCampaignModalProps> = observer(
  ({ isOpen, onClose }) => {
    const history = useHistory()

    const [activeCard, setActiveCard] = useState<string | undefined>()

    const handleSubmit = () => {
      if (activeCard === "pMax") history.push("./campaign/new")
      if (activeCard === "paidSearch") history.push("./opportunities")
      onClose()
    }

    const handleSelect = (value: string) => {
      setActiveCard((prev) => (value !== prev ? value : undefined))
    }

    const title = "Create a new campaign"
    const isValid = activeCard != null

    return (
      <Modal title={title} isOpen={isOpen} onClose={onClose} hideHeader>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Stack gutter="24" align="stretch" className={styles.root}>
            <Typography type="h3">{title}</Typography>

            <Stack gutter="4" align="stretch">
              <Typography
                className={styles.caption}
                type="caption2"
                color="black70Color"
              >
                Select a campaign type
              </Typography>
              <Stack gutter="16" align="stretch">
                {campaignOptions.map((item) => (
                  <OptionCard
                    name={item.name}
                    value={activeCard}
                    label={item.label}
                    description={item.description}
                    imageSrc={item.logoSrc}
                    onClick={() => handleSelect(item.name)}
                    key={item.name}
                  />
                ))}
              </Stack>
            </Stack>

            <Stack direction="row" justify="flex-end">
              <Button color="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid}>
                Create
              </Button>
            </Stack>
          </Stack>
        </form>
      </Modal>
    )
  }
)

export default CreateCampaignModal
