import React from "react"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

import SettingsLayout from "@root/layouts/SettingsLayout/SettingsLayout"

import styles from "./PrivacyPolicy.module.scss"

export const PrivacyPolicy: React.FC = observer(() => {
  const history = useHistory()
  return (
    <SettingsLayout onBack={() => history.replace("/settings")}>
      <div className={styles.root}>
        <h1>Privacy policy</h1>
        <h5 className={styles.subtitle}>
          Notice last updated: August 1st, 2022
        </h5>

        <h2>1. Information on how we use cookies</h2>
        <p>
          This Cookies Policy describes the various cookies and similar
          technologies that Adoro, LLC. (<b>“Adoro”</b>,<b>“we”</b>,<b>“us”</b>
          or <b>“our”</b>) uses on{" "}
          <a href="https://www.google.com/url?q=http://www.metadata.io/&amp;sa=D&amp;source=editors&amp;ust=1675259680537731&amp;usg=AOvVaw2J3SPNedkovI7QleD-Y4Xx">
            www.adoro.us
          </a>{" "}
          (<b>“website”</b> or <b>“site”</b>) which allow us to distinguish you
          from other users of our website, which helps us to provide you with a
          good experience when you browse our website and also allows us to
          improve our site.
        </p>
        <p>
          We use cookies to provide the content and functionality of this site
          (strictly necessary cookies) and, with your permission, to personalize
          the content and advertisements that appear when you visit our site and
          to analyze the traffic on the site. In addition, we share information
          about your use of our site with our advertising partners and analysts,
          who may combine this information with other data that you provide to
          them or that they have collected when you use their services. You have
          the right to withdraw your consent at any time.
        </p>
        <p>
          You do not need to allow cookies to visit most of the website.
          However, enabling cookies may allow for a more tailored browsing
          experience and is required for certain parts of the website to work.
          In the majority of cases, a cookie does not provide us with any of
          your personal information.
        </p>
        <p>
          For further information about this Cookies Policy, please contact us
          at <b>privacy@adoro.us</b>.
        </p>
        <p>
          If you are in the European Economic Area (<b>“EEA”</b>) or the United
          Kingdom (<b>“UK”</b>
          ), you can change the cookie settings that will be placed when you
          visit our website by changing the settings on your browser or by
          visiting the Cookie Preference Centre by clicking on the cookie button
          at the bottom of this policy or on any page on our website. If you are
          outside the EEA or the UK, you can delete, block or disable cookies
          via your browser (see ”Changing your cookie settings” below).
        </p>
        <h3>Key concepts</h3>
        <p>
          <u>First-party cookies</u> are those set by a website that is being
          visited by the user at the time (e.g. cookies placed by www.adoro.us).
        </p>
        <p>
          <u>Third-party cookies</u> are cookies that are set by a domain other
          than that of the website being visited by the user. If a user visits a
          website and another entity sets a cookie through that website, this
          would be a third-party cookie.
        </p>
        <p>
          <u>Persistent cookies</u> are cookies that remain on a user”s device
          for the period of time specified in the cookie. They are activated
          each time that the user visits the website that created that
          particular cookie.
        </p>
        <p>
          <u>Session cookies</u> allow website operators to link the actions of
          a user during a browser session. A browser session starts when a user
          opens the browser window and finishes when they close the browser
          window. Session cookies are created temporarily. Once you close the
          browser, all session cookies are deleted.
        </p>

        <h2>2. Other technologies</h2>
        <p>
          In addition to cookies, our Sites and Apps may use other technologies
          to collect information automatically, such as:
        </p>
        <ul>
          <li>
            <b>Browser Web Storage. </b> We may use browser web storage
            (including via HTML5), also known as locally stored objects (
            <b>“LSOs”</b> ), for similar purposes as cookies. Browser web
            storage enables the storage of a larger amount of data than cookies.
            Your web browser may provide functionality to clear your browser web
            storage.
          </li>
          <li>
            <b>Flash Technology.</b> We may use Flash cookies (which are also
            known as Flash Local Shared Object (<b>“Flash LSOs”</b>
            )) on our Sites to collect and store information about your use of
            our Sites. Unlike other cookies, Flash cookies cannot be removed or
            rejected via your browser settings. If you do not want Flash LSOs
            stored on your computer or mobile device, you can adjust the
            settings of your Flash player to block Flash LSO storage using the
            tools contained in the Website Storage Settings Panel. You can also
            control Flash LSOs by going to the Global Storage Settings Panel and
            following the instructions. Please note that setting the Flash
            Player to restrict or limit acceptance of Flash LSOs may reduce or
            impede the functionality of some Flash applications, including,
            potentially, Flash applications used in connection with our Sites.
          </li>
          <li>
            <b>Web Beacons.</b> We may also use web beacons (which are also
            known as pixel tags and clear GIFs) on our Sites and in our HTML
            formatted emails to track the actions of users on our Sites and
            interactions with our emails. Unlike cookies, which are stored on
            the hard drive of your computer or mobile device by a website, pixel
            tags are embedded invisibly on webpages or within HTML formatted
            emails. Pixel tags are used to demonstrate that a webpage was
            accessed or that certain content was viewed, typically to measure
            the success of our marketing campaigns or engagement with our emails
            and to compile statistics about usage of the Sites, so that we can
            manage our content more effectively.
          </li>
        </ul>

        <h2>3. Changing your cookie settings</h2>
        <p>
          You can change your cookie preferences when you visit our website by
          clicking on the floating cookie on the page. You can also block
          cookies by activating the setting on your browser that allows you to
          refuse the setting of all or some cookies. However, if you use your
          browser settings to block all cookies (including strictly necessary
          cookies), you may not be able to access all or parts of our site.
        </p>
        <p>
          Please note that internet browsers allow you to change your cookie
          settings. These settings are usually found in the “options” or
          “preferences” menu of your internet browser. In order to understand
          these settings, the following links may be helpful. Otherwise you
          should use the “Help” option in your internet browser for more
          details:
        </p>
        <ul>
          <li>
            <a href="https://www.google.com/url?q=https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d&amp;sa=D&amp;source=editors&amp;ust=1675259680540544&amp;usg=AOvVaw34wSpQuuyvxiOBWEWi9Z0E">
              Cookie settings in Internet Explorer / Microsoft Edge
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale%3Den-US%26redirectslug%3DCookies&amp;sa=D&amp;source=editors&amp;ust=1675259680541168&amp;usg=AOvVaw0nITxXy-cQVFuryEnGZ8LH">
              Cookie settings in Firefox
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1675259680541619&amp;usg=AOvVaw3ORHgHVj5_E7PT8RP7MKdX">
              Cookie settings in Chrome
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://support.apple.com/en-us/HT201265&amp;sa=D&amp;source=editors&amp;ust=1675259680542050&amp;usg=AOvVaw0dAKiaxAXQCMBrGdad903e">
              Cookie settings in Safari
            </a>
          </li>
        </ul>
        <p>
          You can limit our use of your information for interest-based
          advertising by:
        </p>
        <ul>
          <li>
            <b>Blocking cookies in your browser.</b> Most browsers let you
            remove or reject cookies. To do this, follow the instructions in
            your browser settings. Many browsers accept cookies by default until
            you change your settings. Please note that if you set your browser
            to disable cookies, the website may not work properly. For more
            information about cookies, including how to manage and delete
            cookies on your device, visit{" "}
            <a href="https://www.google.com/url?q=http://www.allaboutcookies.org/&amp;sa=D&amp;source=editors&amp;ust=1675259680542632&amp;usg=AOvVaw3e8lIqZWScgV5GcInrAKap">
              www.allaboutcookies.org
            </a>
            . If you do not accept our cookies, you may experience some
            inconvenience in your use of the website. For example, we may not be
            able to recognize your computer and you may need to log in every
            time you visit the website.
          </li>
          <li>
            <b>Privacy browsers/plug-ins. </b>
            By using privacy browsers or ad-blocking browser plug-ins, you may
            be able to block certain tracking technologies.
          </li>
          <li>
            <b>Platform settings. </b>
            You can opt-out of interest-based advertising through certain third
            parties by using such third party”s opt-out tool or by otherwise
            contacting such third party. For example, Google and Facebook offer
            opt-out features that let you opt-out of use of your information for
            interest-based advertising:
            <ul>
              <li>
                <b>Google: </b>
                <a href="https://www.google.com/url?q=https://adssettings.google.com/&amp;sa=D&amp;source=editors&amp;ust=1675259680543431&amp;usg=AOvVaw38fXTsuHYdgkTbe5DDPDU4">
                  https://adssettings.google.com/
                </a>
              </li>
              <li>
                <b>Facebook: </b>
                <a href="https://www.google.com/url?q=https://www.facebook.com/about/ads&amp;sa=D&amp;source=editors&amp;ust=1675259680544064&amp;usg=AOvVaw0XiU3jLFOqZZvpRRSU5wkn">
                  https://www.facebook.com/about/ads
                </a>
              </li>
            </ul>
          </li>
          <li>
            <b>Ad industry tools. </b>
            You may opt out of interest-based ads from companies participating
            in the following industry opt-out programs:
            <ul>
              <li>
                <b>Network Advertising Initiative: </b>
                <a href="https://www.google.com/url?q=http://www.networkadvertising.org/managing/opt_out.asp&amp;sa=D&amp;source=editors&amp;ust=1675259680544884&amp;usg=AOvVaw1hkeP6tGdr3-HsfB8lHFGo">
                  http://www.networkadvertising.org/managing/opt_out.asp
                </a>
              </li>
              <li>
                <b>Digital Advertising Alliance: </b>
                <a href="https://www.google.com/url?q=https://optout.aboutads.info/?c%3D2%26lang%3DEN&amp;sa=D&amp;source=editors&amp;ust=1675259680545544&amp;usg=AOvVaw3bjQ0h8lMkOVgnmUD2nClQ">
                  https://optout.aboutads.info/?c=2&amp;lang=EN
                </a>
                .
              </li>
            </ul>
          </li>
        </ul>
        <p>
          You will need to apply these opt-out settings on each device from
          which you wish to opt-out.
        </p>
        <p>
          We cannot offer any assurances as to whether the companies we work
          with participate in the opt-out programs described above.
        </p>
        <p>
          <b>Do Not Track.</b> Some Internet browsers may be configured to send
          “Do Not Track” signals to the online services that you visit. We
          currently do not respond to “Do Not Track” or similar signals. To find
          out more about “Do Not Track,” please visit{" "}
          <a href="https://www.google.com/url?q=http://www.allaboutdnt.com/&amp;sa=D&amp;source=editors&amp;ust=1675259680546505&amp;usg=AOvVaw1d-RWmq_KyIlzdESrfB5qH">
            http://www.allaboutdnt.com
          </a>
          .
        </p>

        <h2>4. Your consent</h2>
        <p>Strictly necessary cookies do not require your consent.</p>
        <p>
          For performance, functional and targeting cookies, we request your
          consent before placing them on your device if you are in the EEA or
          UK. You can give your consent by clicking on the appropriate button on
          the banner displayed to you, or by going to the Cookie Preference
          Centre.
        </p>

        <h2>5. Can I withdraw my consent?</h2>
        <p>
          If you do not wish to give consent or wish to withdraw your consent to
          any performance, functionality or targeting cookies at any time, you
          will need to delete, and block or disable cookies via your browser
          settings; see below for more information on how to do this or by
          changing your settings in the Cookie Preference Centre. Please note
          that disabling these cookies may affect the functionality of the
          website and may prevent you from being able to access certain features
          on the website.
        </p>

        <h2>6. What cookies do we use and why?</h2>
        <p>
          We use “strictly necessary”, “performance”, “functional” and
          “targeting” cookies:
        </p>
        <h3>6.1 Strictly Necessary cookies:</h3>
        <p>
          These cookies are necessary for the website to function and cannot be
          switched off in our systems. They are usually only set in response to
          actions made by you which amount to a request for services, such as
          setting your privacy preferences, logging in or filling in forms. You
          can set your browser to block or alert you about these cookies, but
          some parts of the site will not then work. These cookies do not store
          any personally identifiable information.
        </p>
        <p>
          We use these strictly necessary cookies to identify you as being
          logged in to the website and to authenticate you, to make sure you
          connect to the right service on the website when we make any changes
          to the way it works and for security purposes.
        </p>
        <h3>6.2 Performance cookies:</h3>
        <p>
          These cookies allow us to count visits and traffic sources so we can
          measure and improve the performance of our site. They help us to know
          which pages are the most and least popular and see how visitors move
          around the site and gather information on error messages. All
          information these cookies collect is aggregated and therefore
          anonymous. If you do not allow these cookies we will not know when you
          have visited our site, and will not be able to monitor its
          performance.
        </p>
        <p>
          We use performance cookies to perform web analytics and for error
          management purposes, by measuring any errors that may occur.
        </p>
        <h3>6.3 Functional cookies:</h3>
        <p>
          These cookies enable the website to provide enhanced functionality and
          personalisation. They may be set by us or by third party providers
          whose services we have added to our pages. If you do not allow these
          cookies then some or all of these services may not function properly.
        </p>
        <p>
          We use functional cookies to enable us to provide you with enhanced
          functionality and personalization. These cookies may be set by third
          party providers whose services we have added to our pages.
        </p>
        <h3>6.4 Targeting cookies:</h3>
        <p>
          These cookies record your visit to our website, the pages you have
          visited and the links you have followed. We will use this information
          to make our website and the advertising displayed on it more relevant
          to your interests. We may also share this information with third
          parties for this purpose. These cookies may be set through our site by
          our advertising partners. They may be used by those companies to build
          a profile of your interests and show you relevant adverts on other
          sites. They do not store directly personal information, but are based
          on uniquely identifying your browser and internet device. If you do
          not allow these cookies, you will experience less targeted
          advertising.
        </p>

        <h2>7. Retention</h2>
        <p>
          A cookie or similar technology will exist for different periods of
          time depending on the purpose of the cookie. For example, a session
          cookie which enables the website to operate will only exist on your
          device whilst you browse our website, but other cookies will remain on
          your device for longer such as some functionality cookies which need
          to stay on your device so that the website can recognise you when you
          return. For more information about how long a cookie remains on your
          device, please see the cookie table below.
        </p>

        <br />

        <h1>Cookie List</h1>
        <p className={styles.caption}>
          A cookie is a small piece of data (text file) that a website - when
          visited by a user - asks your browser to store on your device in order
          to remember information about you, such as your language preference or
          login information. Those cookies are set by us and called first-party
          cookies. We also use third-party cookies - which are cookies from a
          domain different than the domain of the website you are visiting - for
          our advertising and marketing efforts. More specifically, we use
          cookies and other tracking technologies for the following purposes:
        </p>

        <h2>Strictly Necessary Cookies</h2>
        <p>
          These cookies are necessary for the website to function and cannot be
          switched off in our systems. They are usually only set in response to
          actions made by you which amount to a request for services, such as
          setting your privacy preferences, logging in or filling in forms. You
          can set your browser to block or alert you about these cookies, but
          some parts of the site will not then work. These cookies do not store
          any personally identifiable information. We use these strictly
          necessary cookies to identify you as being logged in to the website
          and to authenticate you, to make sure you connect to the right service
          on the website when we make any changes to the way it works and for
          security purposes.
        </p>
      </div>
    </SettingsLayout>
  )
})

export default PrivacyPolicy
