import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import TextField from "@components/ui/TextField/TextField"
import { numberWithCommas } from "@components/utils/numberUtils"
import Button from "@components/ui/Button/Button"
import Stack from "@components/ui/Stack/Stack"
import { ButtonGroup } from "@components/ui/Button"
import Icon from "@components/ui/Icon/Icon"
import { viewTypeOptions } from "@pages/Opportunities/mock.temp"
import IconButton from "@components/ui/Button/IconButton"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import useOptionList from "@framework/prototypes/useOptionList"
import Templates from "@components/ui/Templates"
import Label from "@components/ui/CheckBox/Label/Label"
import config from "@root/config"
import { useOpportunitiesStore } from "@store/opportunities/provider"

import styles from "./ControlHeader.module.scss"

interface ControlProps {
  className?: string
  handleShowMap: () => void
}

export const ControlHeader: React.FC<ControlProps> = observer(
  ({ handleShowMap, className, children }) => {
    const {
      totalSearchVolumeAvailable,
      keywordsWithLocations,
      keywordsFilter,
      view,
      setSearchFilter,
    } = useOpportunitiesStore()

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setSearchFilter(value)
    }

    const viewOptionsProps = useOptionList(viewTypeOptions)

    return (
      <Box className={clsx(styles.root, className)}>
        <Templates.Header
          offset="none"
          left={
            <Typography type="h2" color="dark">
              Keyword Search Data
            </Typography>
          }
          right={
            <>
              <TextField
                className={styles.input}
                placeholder="Search"
                before={<Icon name="search" />}
                onChange={handleSearch}
              />

              <Label text="View" textPosition="before" className={styles.label}>
                <SimpleSelect
                  className={styles.input}
                  value={view.activeView}
                  onSelect={(value) => view.setView(value)}
                  {...viewOptionsProps}
                />
              </Label>

              <ButtonGroup>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={{ name: "filter" }}
                  onClick={() => keywordsFilter.toggleFilter()}
                >
                  Filter • {keywordsFilter.activeFiltersCount}
                </Button>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={keywordsFilter.resetAll}
                  title="Reset filters"
                >
                  <Icon name="reset" />
                </IconButton>
              </ButtonGroup>

              {/* <ButtonGroup>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={toggleCalculator}
                >
                  <Icon
                    name="arrow-up-double"
                    rotateAngle={showCalculator ? 0 : 180}
                  />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={toggleFixedSidebar}
                >
                  <Icon
                    name="arrow-up-double"
                    rotateAngle={sidebarFixed ? 90 : -90}
                  />
                </IconButton>
              </ButtonGroup> */}

              {!!config.ENABLE_OPPORTUNITIES_UPDATE && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  title="Full View"
                  onClick={view.toggleFullScreen}
                >
                  <Icon
                    rotateYAngle={180}
                    name={
                      view.isFullView
                        ? "picture-in-picture-enter"
                        : "picture-in-picture-exit"
                    }
                  />
                </IconButton>
              )}
            </>
          }
        />

        <Stack direction="row" align="stretch">
          <Stack
            direction="row"
            align="center"
            justify="space-between"
            className={styles.amountCard}
          >
            <Typography type="h3" color="dark">
              Total Search Volume Available
            </Typography>
            <Typography type="h2" color="primary" weight="bold">
              {numberWithCommas(totalSearchVolumeAvailable)}
            </Typography>
          </Stack>
          <Stack direction="row" align="center" justify="flex-end">
            {!!keywordsWithLocations.length && (
              <Button
                startIcon={{
                  name: "pin-marker",
                }}
                variant="ghost"
                onClick={handleShowMap}
              >
                View Map
              </Button>
            )}
          </Stack>
        </Stack>

        {children}
      </Box>
    )
  }
)

export default ControlHeader
