import React from "react"
import { Marker } from "@react-google-maps/api"

const defaultIconProps = {
  path: `M15,0C6.7,0,0,6.7,0,15c0,8,13.6,26.6,14.2,27.4l0.5,0.7c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.1l0.5-0.7
        C16.4,41.6,30,23,30,15C30,6.7,23.3,0,15,0z M15,9.6c3,0,5.4,2.4,5.4,5.4c0,3-2.4,5.4-5.4,5.4c-3,0-5.4-2.4-5.4-5.4
        C9.6,12,12,9.6,15,9.6z`,
  // eslint-disable-next-line no-undef
  anchor: { x: 15, y: 44 } as google.maps.Point,
}

type CustomMapMarkerProps = {
  item: any
  isSelected: boolean
  color?: string
  handleSelect: (item?: any) => void
}

const CustomMapMarker: React.FC<CustomMapMarkerProps> = ({
  item,
  color = "white",
  isSelected,
  handleSelect,
}) => {
  const iconProps = {
    ...defaultIconProps,
    fillOpacity: 1,
    fillColor: color,
    strokeColor: isSelected ? "black" : "#6b6b6b",
    strokeWeight: isSelected ? 1.5 : 1,
    scale: isSelected ? 0.9 : 0.7,
  }
  return (
    <Marker
      position={item.location}
      onClick={() => handleSelect(item)}
      opacity={isSelected ? 1 : 0.9}
      zIndex={isSelected ? 100 : 0}
      icon={iconProps}
    />
  )
}

export default React.memo(CustomMapMarker)
