import React from "react"
import { observer } from "mobx-react-lite"
import { InlineWidget } from "react-calendly"

import Typography from "@components/ui/Typography/Typography"
import { numberWithCommas } from "@utils/numberUtils"
import { Button } from "@components/ui/Button"
import { SECOND } from "@framework/constants/time"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import useToggle from "@framework/hooks/useToggle"
import ProgressAnimation from "./ProgressAnimation"
import { useDemoMultiformContext } from "./tasks/FormManager"

import styles from "./ProgressSubForm.module.scss"

const APPROXIMATE_TASK_DURATION = 34 * SECOND
const CALENDLY_URL = "https://calendly.com/ivan-serenko/30min"

const KeywordScrappingSubForm: React.FC = observer(() => {
  const store = useDemoMultiformContext()
  const task = store.keywordScrappingTask!

  const scheduleModal = useToggle(false)

  const progress = task.isCompleted
    ? 0
    : ((Date.now() - task.startedAt) / APPROXIMATE_TASK_DURATION) * 100

  const onComplete = () => {
    store.startKeywordsClustering()
    store.nextStep()
  }

  const timerRef = React.useRef<NodeJS.Timeout>()

  React.useEffect(() => {
    if (task.status.stage === "KEYWORDS_IDENTIFYING") {
      if (timerRef.current != null) clearTimeout(timerRef.current)

      timerRef.current = setTimeout(() => {
        scheduleModal.setOpened(true)
      }, 7_000)
    }
    return undefined
  }, [task.status.stage])

  React.useEffect(
    () => () => {
      if (timerRef.current != null) clearTimeout(timerRef.current)
    },
    []
  )

  return (
    <>
      {task.isCompleted ? (
        <div className={styles.root}>
          <ProgressAnimation value={100} />

          <Typography className={styles.title} type="h2">
            Total keywords found
          </Typography>
          <Typography className={styles.subtitle} type="h1" weight="bold">
            {numberWithCommas(task.totalKeywords)}
          </Typography>

          <Button
            className={styles.control}
            color="primary"
            onClick={onComplete}
          >
            Start Keyword Clustering
          </Button>
        </div>
      ) : (
        <div className={styles.root}>
          <ProgressAnimation value={progress} />

          <Typography type="h2">{task.status.label}...</Typography>
        </div>
      )}

      <Modal
        className={styles.scheduleModal}
        isOpen={scheduleModal.isOpened}
        onClose={() => scheduleModal.setOpened(false)}
        hideHeader
      >
        <Stack gutter="8" align="center">
          <Stack gutter="8" align="center" className={styles.titleContainer}>
            <Typography type="h1">
              Unlocking the Power of Keywords with Expert Insights!
            </Typography>
            <Typography type="h2">
              Our AI is scanning tens of millions of keywords to uncover the
              hidden opportunities and insights tailored for you. Shortly, the
              analysis will reveal the treasure trove of data. Join us for an
              informative, complimentary 30-minute session with our growth
              marketing experts to unlock the keyword data and strategize for
              your industry.
            </Typography>
            <Typography type="h3" weight="bold">
              Schedule below to gain an edge in your industry!
            </Typography>
          </Stack>

          <InlineWidget
            url={CALENDLY_URL}
            styles={{
              height: "min(calc(100vh - 360px), 920px)",
              minWidth: 800,
            }}
          />
        </Stack>
      </Modal>
    </>
  )
})

export default KeywordScrappingSubForm
