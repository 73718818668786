/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import _chunk from "lodash/chunk"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"

import { AssignedAttribute } from "@store/opportunities/opportunities.transformer"

import styles from "./AttributesList.module.scss"

type AttributesListProps = {
  title?: string
  className?: string
  maxColumns?: number
  attributes: AssignedAttribute[]
}

const AttributesList: React.FC<AttributesListProps> = observer(
  ({ className, title = "", attributes, maxColumns = 1 }) => {
    const chunks = useMemo(() => {
      if (attributes.length) {
        if (attributes.length > 10) {
          const chunkSize = Math.ceil(
            attributes.length / (maxColumns > 0 ? maxColumns : 1)
          )
          return _chunk(attributes, chunkSize)
        }
        return [attributes]
      }
      return null
    }, [attributes, maxColumns])

    return (
      <Box className={clsx(styles.root, className)} padding="15px">
        <div className={styles.title}>
          <Typography type="h4" color="primary" upperCase>
            {title}
          </Typography>
        </div>
        <div className={styles.content}>
          {chunks ? (
            chunks.map((attrs) => (
              <div className={styles.chunk}>
                {attrs.map((attr) => (
                  <Typography type="h3" className={styles.keyword}>
                    {attr.name}
                  </Typography>
                ))}
              </div>
            ))
          ) : (
            <Typography type="h3" color="gray">
              (No assigned attributes)
            </Typography>
          )}
        </div>
      </Box>
    )
  }
)

export default AttributesList
