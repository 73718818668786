import { MapperType } from "@components/ui/WaterfallTable/types"
import { MetricNameType } from "@framework/types/metrics"
import MetricCell from "@framework/prototypes/report/MetricsTable/MetricCell"
import { getMetricDescriptor } from "@framework/constants/metrics"

const getMetricsCellRenderer = (
  name: MetricNameType
): MapperType["render"] => ({
  Component: MetricCell,
  props: { name },
})

const getMetricMapper = (name: MetricNameType) => {
  const metric = getMetricDescriptor(name)
  return {
    name: metric.name,
    label: metric.shortLabel,
    render: getMetricsCellRenderer(name),
  }
}

export const KPIsMapper: MapperType[] = [
  {
    ...getMetricMapper("Impressions"),
    widthBasis: "20px",
    sortable: true,
  },
  {
    ...getMetricMapper("SearchImpressionShare"),
    sortable: true,
  },
  {
    ...getMetricMapper("Clicks"),
    sortable: true,
  },
  {
    ...getMetricMapper("Ctr"),
    sortable: true,
  },
  {
    ...getMetricMapper("AverageCpc"),
    sortable: true,
  },
  {
    ...getMetricMapper("Cost"),
    sortable: true,
  },
  {
    ...getMetricMapper("Conversions"),
    sortable: true,
  },
  {
    ...getMetricMapper("ConversionRate"),
    widthBasis: "20px",
    sortable: true,
  },
  {
    ...getMetricMapper("Revenue"),
    sortable: true,
  },
  {
    ...getMetricMapper("Roas"),
    widthBasis: "0.8 0.8",
    sortable: true,
  },
]

export default KPIsMapper
