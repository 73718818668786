import { observable, action, computed } from "mobx"
import _groupBy from "lodash/groupBy"

import { IndustryType } from "@framework/types/account"
import { ClientFile } from "@framework/types/client"
import { ClientInfoType } from "@framework/types/clientProfile"
import accountService from "@services/account.service"
import clientService from "@services/client.service"
import { Option } from "@framework/types/types"
import ClientStore from "../client/client"
import {
  getInitialClientContractsDetailsForm,
  getInitialClientContractsForm,
  getInitialClientInfoForm,
} from "./dataTransformers"

export class ClientProfileStore {
  @observable clientStore: ClientStore | null = null

  @observable isValid = false

  @observable isEdit = false

  @observable submitFormCallback?: () => void

  @observable industries: Option[] | null = null

  @observable industriesLoading = false

  @observable files: ClientFile[] | null = null

  @computed get clientInfo() {
    return getInitialClientInfoForm(this.clientStore?.client)
  }

  @computed get clientContracts() {
    return getInitialClientContractsForm(this.clientStore?.client)
  }

  @computed get clientContractDetails() {
    return getInitialClientContractsDetailsForm(this.clientStore?.client)
  }

  constructor(clientStore: ClientStore) {
    this.clientStore = clientStore
  }

  @action fetchIndustries = async () => {
    this.industriesLoading = true
    try {
      const response = await accountService.getIndustriesList()
      this.industries =
        response.data.data?.map((item) => ({
          value: item.name.toString(),
          label: item.name,
        })) ?? []
    } catch (error) {
      console.log(error)
    }
    this.industriesLoading = false
  }

  @observable clientUpdateLoading: boolean = false

  @observable clientUpdateError: string | null = null

  @action updateClient = async (clientId: number, form: ClientInfoType) => {
    this.clientUpdateLoading = true
    this.clientUpdateError = null
    try {
      const response = await clientService.updateClient(clientId, form)
      if (response.status === 200) {
        // TODO
      }
    } catch (error) {
      console.log(error)
      this.clientUpdateError = "Error while updating client"
    } finally {
      this.clientUpdateLoading = false
    }
  }

  @observable clientFilesLoading: boolean = false

  @action loadClientFiles = async (clientId: number) => {
    this.clientFilesLoading = true
    try {
      const response = await clientService.getClientFiles(clientId)
      if (response.status === 200) {
        this.files = response.data.data
      }
    } catch (error) {
      console.log(error)
    }
    this.clientFilesLoading = false
  }

  @action getFileUploadProgress = async (clientId: number, fileId: number) => {
    try {
      // const { status } = await clientService.getFileProgress(clientId, fileId)
      // if (status === 200) {
      //   return 100
      // }
    } catch (error) {
      console.log(error)
    }
    return 0
  }

  @observable editFileListLoading: boolean = false

  @observable uploadFileError: string | null = null

  @action uploadFiles = async (clientId: number, files: File[]) => {
    this.editFileListLoading = true
    this.uploadFileError = null
    try {
      const form = new FormData()

      for (let x = 0; x < files.length; x += 1) {
        form.append("files", files[x])
      }

      const { status } = await clientService.uploadFiles(clientId, form)
      if (status === 200) {
        return
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        this.uploadFileError = error.response?.data?.error ?? "unknown"
        return
      }
      this.uploadFileError = "unknown"
    } finally {
      this.editFileListLoading = false
    }
  }

  @action deleteFile = async (clientId: number, fileId: number) => {
    this.editFileListLoading = true
    try {
      const { status } = await clientService.removeFiles(clientId, fileId)
      if (status === 200) {
        // TODO
        return
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.editFileListLoading = false
    }
  }

  @action setIsEdit = (state: boolean) => {
    this.isEdit = state
    this.setSubmitCallback()
  }

  @action setIsValid = (state: boolean) => {
    this.isValid = state
  }

  @action setSubmitCallback = (callback?: () => void) => {
    this.submitFormCallback = callback
  }
}

export default ClientProfileStore
