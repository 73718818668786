import { FormikContextType } from "formik"
import { useCallback, useEffect, useMemo, useRef } from "react"
import * as Yup from "yup"

import { useStore } from "@store/index"
import { MonthlyAmountMap } from "@store/checkbook/checkbook"
import { PeriodType } from "@components/forms/CheckbookForm/types"
import { getCheckbookSequence } from "./utils"

const useCheckbookForm = (
  checkbookData: MonthlyAmountMap,
  period: PeriodType
) => {
  const {
    manageProfileStore: { isEdit, setIsEdit },
    checkbookStore: { campaignDate, initialMonthlyAdSpend, updateCheckbook },
    accountStore: { accountId },
  } = useStore()

  const formikRef = useRef<FormikContextType<MonthlyAmountMap>>(null)
  const formik = formikRef.current

  const initialValues = useMemo(() => {
    if (!checkbookData || !isEdit) return {}
    return getInitialCheckbook(
      getCheckbookSequence(period),
      checkbookData,
      formikRef.current?.values,
      initialMonthlyAdSpend ?? 0
    )
  }, [checkbookData, isEdit, period, initialMonthlyAdSpend])

  const validationSchema = useMemo(() => {
    const editable = Object.keys(initialValues)
    return Yup.object().shape(
      Object.fromEntries(
        editable.map((name) => [name, Yup.number().required("Required")])
      )
    )
  }, [initialValues])

  const handleSubmit = useCallback(
    async (values: any) => {
      if (accountId && campaignDate) {
        await updateCheckbook(accountId, campaignDate, values).then(
          (success) => {
            if (success) setIsEdit(false)
          }
        )
      }
    },
    [campaignDate, accountId, updateCheckbook, setIsEdit]
  )

  useEffect(() => {
    formik?.resetForm({ values: initialValues, touched: {} })
  }, [initialValues, isEdit])

  return {
    innerRef: formikRef,
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  }
}

export const getInitialCheckbook = (
  editableSequence: string[],
  checkbook: MonthlyAmountMap,
  previousCheckbook?: MonthlyAmountMap,
  defaultAmount: number = 0
) =>
  editableSequence.reduce<MonthlyAmountMap>((acc, monthKey) => {
    const value =
      previousCheckbook?.[monthKey] ??
      checkbook[monthKey] ??
      defaultAmount.toString()
    acc[monthKey] = value
    return acc
  }, {})

export default useCheckbookForm
