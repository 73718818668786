import React from "react"
import clsx from "clsx"

import Box from "@components/ui/Box/Box"
import KeywordAnswerForm from "./KeywordAnswerForm"

import styles from "./KeywordAnswerFormBox.module.scss"

interface KeywordAnswerFormBoxProps {
  className?: string
  onClose?: () => void
}

export const KeywordAnswerFormBox: React.FC<KeywordAnswerFormBoxProps> = ({
  className,
  onClose,
}) => (
  <Box className={clsx(styles.root, className)}>
    <KeywordAnswerForm onClose={onClose} />
  </Box>
)

export default KeywordAnswerFormBox
