import { action, observable } from "mobx"
import React, { useContext } from "react"

import { reportGroupOptions } from "@framework/constants/report"
import { ReportGroupName } from "@framework/types/report"
import { SortByType } from "@framework/types/types"

export class TableContextStore {
  @observable viewTypeOptions = reportGroupOptions

  @observable viewType = reportGroupOptions[0]

  @observable searchQuery = ""

  @observable sortBy: SortByType = {
    value: "none",
    direction: false,
  }

  @action setViewType = (value: string) => {
    this.viewType = value as ReportGroupName
  }

  @action setSearchQuery = (value: string) => {
    this.searchQuery = value
  }

  @action toggleOrder = (name: string) => {
    const shouldOff = this.sortBy.value === name && this.sortBy.direction
    this.sortBy = {
      value: shouldOff ? "none" : name,
      direction: this.sortBy.value === name,
    }
  }
}

export const TableContext = React.createContext<TableContextStore | null>(null)

export const useTableContext = () => {
  const context = useContext(TableContext)
  if (context == null) throw new Error("Can't find Table context")
  return context
}
