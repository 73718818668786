import React from "react"
import clsx from "clsx"

import Button from "@components/ui/Button/Button"

import styles from "./ButtonColumn.module.scss"

export type ToggleButtonColumnProps = {
  onClick?: (e: any) => void
  className?: string
  checked: boolean
}

export const ToggleButtonColumn: React.FC<ToggleButtonColumnProps> = ({
  children,
  checked,
  className,
  onClick,
}) => (
  <div className={clsx(styles.root, className)}>
    <Button
      variant="contained"
      color={checked ? "secondary" : "primary"}
      className={styles.button}
      onClick={onClick}
    >
      {children}
    </Button>
  </div>
)

export default ToggleButtonColumn
