/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable } from "mobx"
import _sortBy from "lodash/sortBy"

import accountService from "@services/account.service"
import { ID } from "@framework/types/types"
import { transformKeywords } from "./opportunities.transformer"
import OpportunitiesStore from "./opportunities"
import CreateCampaignsStore from "./created-campaign.store"

class OpportunitiesController {
  @observable opportunitiesStore: OpportunitiesStore

  @observable createdCampaign: CreateCampaignsStore

  @observable publishCampaign?: () => void

  // constructor

  constructor(options: {
    createdCampaign: CreateCampaignsStore
    opportunitiesStore: OpportunitiesStore
    onPublishCampaign?: () => void
  }) {
    this.opportunitiesStore = options.opportunitiesStore
    this.createdCampaign = options.createdCampaign
    this.publishCampaign = options.onPublishCampaign
  }

  // computed

  // actions

  @action loadRecommendedKeywords = async (accountId: ID) => {
    const { keywordsList, shoppingCart } = this.opportunitiesStore
    try {
      keywordsList.isLoading = true
      keywordsList.error = null

      const response = await accountService.getKeywordsByAccountId(accountId)

      const recKeywords = transformKeywords(response.data.data.records)

      keywordsList.keywordsMap = new Map(
        recKeywords.map((it) => [it.id.toString(), it])
      )

      keywordsList.keywordsIds = Array.from(keywordsList.keywordsMap.keys())

      shoppingCart.selected = keywordsList.recommendedKeywords.reduce(
        (acc, item) => {
          if (item.active && !item.inUse) acc.add(item.id.toString())
          return acc
        },
        new Set<string>()
      )
    } catch (error) {
      keywordsList.error = "Unexpected error"
    } finally {
      keywordsList.isLoading = false
    }
  }

  @action switchKeyword = async (accountId: ID, keywordId: ID) => {
    const { shoppingCart } = this.opportunitiesStore

    const activeStatus = shoppingCart.isSelected(keywordId)

    try {
      const newStatus = !activeStatus

      shoppingCart.selectKeyword(keywordId, newStatus)

      const { status } = await accountService.switchKeyword(accountId, {
        attributeId: Number(keywordId),
        active: newStatus,
      })

      if (status >= 300) shoppingCart.selectKeyword(keywordId, activeStatus)
    } catch (error) {
      //
      shoppingCart.selectKeyword(keywordId, activeStatus)
    }
  }

  @action changeBudgetFraction = (keywordId: ID, value: number) => {
    if (Number.isNaN(value)) return

    const { keywordsList } = this.opportunitiesStore

    const keyword = keywordsList.getKeywordById(keywordId)
    if (keyword) keyword.budgetFraction = value
  }

  @action setPublishCampaignHandler = (handler?: () => void) => {
    this.publishCampaign = handler
  }
}

export default OpportunitiesController
