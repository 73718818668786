import React from "react"
import { ID } from "@framework/types/types"
import throttle from "lodash/throttle"

const emptyArray: any[] = []

type AdGroupEntity = ({ AdGroupId: number } | { AdgroupId: number }) & {
  CampaignId: number
}

export const useFilteredAdGroupChild = <T extends AdGroupEntity>(config: {
  collection: T[] | null
  adGroups: ID[]
  campaigns?: ID[]
}) => {
  const { collection, adGroups, campaigns } = config

  const [inProgress, setInProgress] = React.useState(false)

  const [filteredCollection, setFilteredCollection] =
    React.useState<T[]>(emptyArray)

  const filterList = React.useCallback(
    throttle((adGroups: ID[], campaigns?: ID[]) => {
      setInProgress(true)

      if ((!adGroups.length && !campaigns?.length) || !collection?.length)
        setFilteredCollection(emptyArray)
      else {
        const adGroupsSet = new Set(adGroups.map((it) => it?.toString()))
        const campaignsSet = new Set(campaigns?.map((it) => it?.toString()))

        const res = collection.filter((it) => {
          const adGroupId = "AdgroupId" in it ? it?.AdgroupId : it?.AdGroupId

          if (adGroupId != null) return adGroupsSet.has(adGroupId.toString())

          return campaignsSet.has(it.CampaignId.toString())
        })
        setFilteredCollection(res)
      }

      setInProgress(false)
    }, 1_000),
    [collection]
  )

  React.useEffect(() => {
    setInProgress(true)
    filterList(adGroups, campaigns)
  }, [filterList, adGroups, campaigns])

  return { filteredCollection, inProgress }
}

export default useFilteredAdGroupChild
