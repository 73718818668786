import { action, computed, observable } from "mobx"
import { QueryReportType } from "@framework/types/account"
import { ID } from "@framework/types/types"

class QueriesStore {
  // INJECTIONS

  // CONSTRUCTOR

  // STATE

  @observable data: QueryReportType[] | null = null

  @observable isLoading: boolean = false

  @observable error: string | null = null

  // ACTIONS

  @action storeData = (data: QueryReportType[]) => {
    this.data = data
  }

  @computed get dataIndex() {
    return new Map(
      this.data?.map((it) => [this.getKey(it.Text, it.CampaignIds), it])
    )
  }

  getKey = (text: string, campaignIds: ID[]) =>
    `${text}_${campaignIds.join("_")}`

  @computed get getByID() {
    const { dataIndex } = this
    return (text: string, campaignIds: ID[]) =>
      dataIndex.get(this.getKey(text, campaignIds))
  }
}

export default QueriesStore
