import React from "react"
import clsx from "clsx"

import useDelayedValue from "@framework/hooks/useDelayedValue"

import styles from "./Drawer.module.scss"

export interface DrawerProps {
  open: boolean
  className?: string
}

export const Drawer: React.FC<DrawerProps> = ({
  open,
  children,
  className,
}) => {
  const isMounted = useDelayedValue(open, 200) || open

  if (!isMounted) return null
  return (
    <div
      className={clsx(
        styles.root,
        open ? styles.visible : styles.hidden,
        className
      )}
    >
      {children}
    </div>
  )
}

export default Drawer
