import React from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import get from "lodash/get"
import { FormikProvider, useFormik, useFormikContext } from "formik"
import identity from "lodash/identity"

import Stack from "@components/ui/Stack/Stack"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import Label from "@components/ui/Label/Label"
import FormTextField from "@framework/prototypes/FormTextField"
import { websiteURLValidator } from "@utils/validators"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import { Button } from "@components/ui/Button"
import FromTagsSelector from "@framework/prototypes/FromTagsSelector"
import { idArrayToOptions } from "@utils/arrayUtils"
import { allIndustriesWebsites } from "./utils"

import styles from "./IntroForm.module.scss"

const MAX_COMPETITORS = 3

export const validationSchema = yup.object({
  website: websiteURLValidator.clone().default("").required(),
  competitors: yup
    .array()
    .of(websiteURLValidator.clone().required().label("Competitor"))
    .default([""])
    .required(),
  seedKeywords: yup.array().of(yup.object()).default([]),
})

export type FormData = yup.InferType<typeof validationSchema>

type IntroFormProps = {
  initialValues?: FormData
  loading?: boolean
  onSubmit: (data: FormData) => void
}

const IntroForm: React.FC<IntroFormProps> = observer(
  ({ initialValues, loading, onSubmit }) => {
    const formik = useFormik<FormData>({
      initialValues: {
        ...validationSchema.getDefault(),
        ...validationSchema.cast(initialValues),
      },
      validationSchema,
      onSubmit,
    })

    const addCompetitor = () => {
      formik.setValues(
        {
          ...formik.values,
          competitors: [...formik.values.competitors, ""],
        },
        false
      )
    }

    const deleteCompetitor = (index: number) => {
      formik.setValues(
        {
          ...formik.values,
          competitors: (formik.values.competitors as string[]).filter(
            (_, itIdx) => itIdx !== index
          ),
        },
        false
      )
    }

    return (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gutter="20" align="stretch">
            <CompetitorRow
              label="Your website"
              name="website"
              placeholder="Enter your website URL"
            />

            {formik.values.competitors.map(
              (it: string, idx: number, list: any[]) => {
                const isLastIndex = list.length - 1 === idx
                const label = `Competitor ${list.length > 1 ? idx + 1 : ""}`
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={styles.row} key={idx}>
                    <Label label={label}>
                      <FormTextField
                        name={`competitors[${idx}]`}
                        placeholder="Enter your competitor's website URL"
                      />
                    </Label>

                    <div className={styles.rowControl}>
                      {idx !== 0 && (
                        <IconButton
                          title={`Delete ${label}`}
                          color="primary"
                          onClick={() => deleteCompetitor(idx)}
                        >
                          <Icon name="trash" />
                        </IconButton>
                      )}

                      {isLastIndex &&
                        idx + 1 < MAX_COMPETITORS &&
                        !(formik.errors?.competitors as string[])?.at(idx) &&
                        !!(formik.values?.competitors as any[])?.at(idx) && (
                          <IconButton
                            title="Add Competitor"
                            color="primary"
                            onClick={addCompetitor}
                          >
                            <Icon name="plus" />
                          </IconButton>
                        )}
                    </div>
                  </div>
                )
              }
            )}

            <Label label="Keywords Seed">
              <FromTagsSelector
                name="seedKeywords"
                placeholder="Enter additional keywords seed"
                unit="seed"
              />
            </Label>

            <Button type="submit" disabled={loading}>
              Start keyword indexing
            </Button>
          </Stack>
        </form>
      </FormikProvider>
    )
  }
)

export default IntroForm

const websiteOptions = idArrayToOptions(allIndustriesWebsites, identity)

const CompetitorRow: React.FC<{
  name: string
  label: string
  placeholder: string
}> = ({ name, label, placeholder }) => {
  const formik = useFormikContext<FormData>()

  const websitesOptions = useOptionSearchList({
    list: websiteOptions,
    minQueryLength: 3,
    defaultQuery: get(formik.values, name),
  })

  return (
    <FormSimpleSelectField
      label={label}
      name={name}
      placeholder={placeholder}
      withSearch
      {...websitesOptions}
    />
  )
}
