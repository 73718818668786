/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"
import moment from "moment"

import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { mbNoData, moneyFormatter } from "@services/utils"
import Label from "@components/ui/Label/Label"
import DatePicker from "@components/ui/DatePicker/DatePicker"
import { ContractDetailsFormType } from "@framework/types/clientProfile"
import { MM_DD_YYYY_DATE_FORMAT } from "@framework/constants/moment-format"
import FormNumberField from "@framework/prototypes/FormNumberField"

import styles from "./ContractDetailsForm.module.scss"

const parseStringDateArray = (array: string[]) =>
  array.filter((str) => !!str).map((dateStr) => moment(dateStr).toDate())

type ContractDetailsFormProps = {
  className?: string
}

const ContractDetailsForm: React.FC<ContractDetailsFormProps> = observer(
  ({ className }) => {
    const {
      clientProfileStore: { isEdit },
      clientStore: { client },
    } = useStore()

    const formik = useFormikContext<ContractDetailsFormType>()

    const period: Date[] = useMemo(
      () =>
        parseStringDateArray([
          formik.values.contractStartDate,
          formik.values.contractEndDate,
        ]),
      [formik.values.contractStartDate, formik.values.contractEndDate]
    )

    const handleChangeDate = (period: Date[] | Date) => {
      if (Array.isArray(period)) {
        formik.setFieldValue("contractStartDate", period[0])
        formik.setFieldValue("contractEndDate", period[1])
        return
      }
      formik.setFieldValue("contractStartDate", period)
      formik.setFieldValue("contractEndDate", "")
    }

    return (
      <Box className={clsx(styles.root, className)} padding="25px">
        <Typography type="h2">Contract Details</Typography>
        {isEdit ? (
          <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
            <div className={styles.fieldsWrapper}>
              <FormNumberField
                label="Total Ad Spend Budget $"
                name="yearlyAdBudget"
                decimalScale={0}
                thousandSeparator
                prefix="$"
              />

              <Label
                label="Contract Period"
                error={
                  formik.touched.contractStartDate &&
                  formik.errors.contractStartDate
                }
              >
                <DatePicker
                  onChange={handleChangeDate}
                  onBlur={formik.handleBlur}
                  defValue={period}
                  multiSelect
                />
              </Label>
            </div>
          </form>
        ) : (
          client && (
            <div className={styles.container}>
              <Label label="Total Ad Spend Budget $" className={styles.wrapper}>
                <Typography type="h2" color="primary" bold>
                  {mbNoData(client.yearlyAdBudget, moneyFormatter)}
                </Typography>
              </Label>

              <Label label="Contract Period" className={styles.wrapper}>
                <Typography type="h2" color="primary" bold>
                  {mbNoData(
                    [client.contractStartDate, client.contractEndDate]
                      .map((date) =>
                        moment(date).format(MM_DD_YYYY_DATE_FORMAT)
                      )
                      .join(" - ")
                  )}
                </Typography>
              </Label>
            </div>
          )
        )}
      </Box>
    )
  }
)

export default ContractDetailsForm
