import { ProductTempChangeData } from "@framework/types/account"
import { ID } from "@framework/types/types"
import { observable, action, computed } from "mobx"
import accountService from "@services/account.service"
import RootStore from "../RootStore"
import ProductChange from "./ProductChange"

export class ProductChangesStore {
  @observable rootStore: RootStore

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @observable changes: Record<string, ProductChange> = {}

  @computed get productFeedStore() {
    return this.rootStore.productFeedStore
  }

  @computed get changeById() {
    return (productId: ID | null): ProductChange => {
      if (!productId) return new ProductChange()

      const { changes } = this

      if (changes[productId] == null) {
        changes[productId] = new ProductChange()
      }

      const { accountId } = this.rootStore.accountStore

      if (productId && !changes[productId].initialized && accountId) {
        changes[productId].load(accountId, productId)
      }

      return changes[productId]
    }
  }

  @observable editChangeLoading = false

  @observable editChangeError: string | null = null

  @action addChange = async (
    accountId: string | number,
    productId: string | number,
    changes: any = {}
  ) => {
    this.editChangeLoading = true
    this.editChangeError = null
    try {
      await accountService.addTemporaryChangeToProduct(
        accountId,
        productId,
        changes
      )
      this.changeById(productId).load(accountId, productId)
    } catch (error: any) {
      this.editChangeError =
        error.response?.data?.error ??
        "Unknown error while adding temporary change"
    } finally {
      this.editChangeLoading = false
    }
    return this.editChangeError
  }

  @action dropProductChange = async (
    accountId: string | number,
    productId: string | number
  ) => {
    this.editChangeLoading = true
    this.editChangeError = null
    try {
      await accountService.dropProductTemporaryChangeById(accountId, productId)

      this.productFeedStore.reloadData()
      this.dropChanges()
    } catch (error: any) {
      this.editChangeError =
        error.response?.data?.error ??
        "Unknown error while dropping all temporary changes"
    } finally {
      this.editChangeLoading = false
    }
    return this.editChangeError
  }

  @action dropProductChanges = async (accountId: ID) => {
    this.editChangeLoading = true
    this.editChangeError = null
    try {
      const { mode, data } = this.rootStore.productFeedStore.list.selected
      await accountService.dropProductTemporaryChanges(accountId, {
        mode,
        ids: [...data].map(Number),
      })

      this.productFeedStore.reloadData()
      this.dropChanges()
    } catch (error: any) {
      this.editChangeError =
        error.response?.data?.error ??
        "Unknown error while dropping all temporary changes"
    } finally {
      this.editChangeLoading = false
    }
    return this.editChangeError
  }

  @action dropChanges = () => {
    this.changes = {}
  }

  @action applyChange = async (
    accountId: string | number,
    productId: string | number,
    change: ProductTempChangeData
  ) => {
    this.editChangeLoading = true
    this.editChangeError = null
    try {
      await accountService.applyProductChange(accountId, productId, {
        ...change.update,
        ...change.meta,
      })
    } catch (error: any) {
      this.editChangeError =
        error.response?.data?.error ??
        "Unknown error while applying all temporary changes"
    } finally {
      this.editChangeLoading = false
    }
    return this.editChangeError
  }
}

export default ProductChangesStore
