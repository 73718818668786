import clsx from "clsx"
import React, { CSSProperties } from "react"

import OnClickTooltip from "@components/ui/Tooltip/TooltipContainer/OnClickTooltipContainer"
import Typography from "@components/ui/Typography/Typography"
import useToggle from "@framework/hooks/useToggle"

import styles from "./ChangesBadge.module.scss"

const getColor = (count: number) => {
  if (count < 3) return "#FFBC57"
  if (count < 4) return "#FF7F57"
  return "#FF5555"
}

interface ChangesBadgeProps {
  children?: number
  changes?: string[]
  total: number
}

const ChangesBadge: React.FC<ChangesBadgeProps> = React.memo(
  ({ total, changes = [] }) => {
    const color = getColor(changes.length)
    const { isOpened, setOpened } = useToggle()

    const BadgeNode = (
      <div
        className={styles.badge}
        style={{ "--main-color": color } as CSSProperties}
      >
        {total ?? changes.length}
      </div>
    )

    return !changes.length ? (
      BadgeNode
    ) : (
      <OnClickTooltip
        className={clsx(styles.root, { [styles.focused]: isOpened })}
        placement="left"
        justify="start"
        onChange={setOpened}
        toolTip={
          <div className={styles.list}>
            <Typography type="h5" color="gray">
              Changes
            </Typography>
            {changes.map((changeName) => (
              <div key={changeName}>
                {changeName} {/* <span> • Lack of content</span> */}
              </div>
            ))}
          </div>
        }
      >
        {BadgeNode}
      </OnClickTooltip>
    )
  }
)

export default ChangesBadge
