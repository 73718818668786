import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import useURLSearchParams from "@framework/hooks/useURLSearchParams"
import { useStore } from "@root/store"
import YouTubeIcon from "@root/assets/svgIcons/YouTubeIcon"
import GmailIcon from "@root/assets/svgIcons/GmailIcon"
import GoogleIcon from "@root/assets/svgIcons/GoogleIcon"
import DisplayIcon from "@root/assets/svgIcons/DisplayIcon"
import DiscoverIcon from "@root/assets/svgIcons/DiscoverIcon"
import Button from "../../../../../components/ui/Button/Button"
import styles from "./Assets.module.scss"

const Preview: FC = observer(() => {
  const params = useURLSearchParams()
  const assetGroupId: number = Number(params.get("assetGroupId"))
  const { pMaxAssetGroupStore, accountStore } = useStore()
  const { previewLink, generatePreviewLink } = pMaxAssetGroupStore
  const accountId = accountStore.getAccountId()

  useEffect(() => {
    generatePreviewLink(accountId, assetGroupId)
  }, [])

  return (
    <section className={styles.previewWrapper}>
      <div className={styles.previewIcons}>
        <YouTubeIcon />
        <GmailIcon />
        <GoogleIcon />
        <DisplayIcon />
        <DiscoverIcon />
      </div>
      <Button
        size="big"
        href={previewLink?.previewURL}
        variant="outlined"
        color="primary"
        as="a"
        target="_blank"
        disabled={!previewLink?.previewURL}
      >
        Open Preview
      </Button>
    </section>
  )
})

export default Preview
