import React from "react"
import { observer } from "mobx-react-lite"

import Select from "@components/ui/DropDown"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import { LabelMapper } from "@framework/types/types"
import { ButtonColorType } from "@components/ui/Button/types"
import { useStore } from "@store/index"

type StatusSwitchProps = {
  disabled?: boolean
  value: string
  options: LabelMapper[]
  className?: string
  onChange?: (value: string) => void
}

const StatusSwitch: React.FC<StatusSwitchProps> = observer(
  ({ options, value, disabled, onChange }) => {
    const {
      adCopyStore: { isEditingAvailable },
    } = useStore()

    const label = options.find((it) => it.name === value)

    const isDisabled = !isEditingAvailable || disabled

    return (
      <Select.DropDown
        disabled={isDisabled}
        target={(active) => (
          <Chip
            type="outlined"
            color={(label?.color as ButtonColorType) ?? "default"}
            endIcon={<Icon name="arrow-down" rotateAngle={active ? 180 : 0} />}
          >
            {label?.label ?? "Unknown"}
          </Chip>
        )}
      >
        <Select.Box>
          <Select.ItemsList>
            {options.map((it) => (
              <Select.Option
                onClick={() => onChange?.(it.name)}
                isSelected={it.name === value}
                key={it.name}
              >
                {it.label}
              </Select.Option>
            ))}
          </Select.ItemsList>
        </Select.Box>
      </Select.DropDown>
    )
  }
)

export default StatusSwitch
