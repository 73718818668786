import { action, computed, observable } from "mobx"

import { GoogleAdsLocation } from "@framework/types/manageCampaign"
import { ID } from "@framework/types/types"

export class LocationsStore {
  @observable data: Map<string, GoogleAdsLocation> = new Map()

  @action storeData = (data: GoogleAdsLocation[]) => {
    data.map((it) => this.data.set(it.id.toString(), it))
  }

  @computed get getById() {
    const { data } = this
    return (id: ID) => data.get(id.toString())
  }
}

export default LocationsStore
