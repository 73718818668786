import React, { ReactElement } from "react"
import clsx from "clsx"

import { Option } from "@framework/types/types"
import Icon from "@components/ui/Icon/Icon"
import SVGIconWrapper from "@root/assets/svgIcons/SVGIconWrappper"
import { IconName } from "../Icon/types"

import styles from "./Tabs.module.scss"

export interface NavOption extends Option {
  disabled?: boolean
  alter?: string
  exact?: boolean
  hidden?: boolean
}

export interface TabItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string
  before?: React.ReactElement | IconName
  active?: boolean
  disabled?: boolean
  iconSVG?: ReactElement
}

export const TabItem: React.FC<TabItemProps> = React.memo(
  ({
    className,
    active = false,
    before,
    disabled,
    children,
    iconSVG,
    ...rest
  }) => (
    <button
      type="button"
      className={clsx(
        styles.item,
        {
          [styles.active]: active,
          [styles.disabled]: disabled,
          [styles.iconSVG]: iconSVG,
        },
        className
      )}
      {...rest}
    >
      {before != null &&
        !iconSVG &&
        (typeof before === "string" ? <Icon name={before} /> : before)}
      {iconSVG && <SVGIconWrapper>{iconSVG}</SVGIconWrapper>}
      {children}
    </button>
  )
)

export default TabItem
