import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import BackButton from "@framework/prototypes/BackButton"
import Templates from "@components/ui/Templates"
import Box from "@components/ui/Box/Box"
import Stepper from "@components/ui/Stepper/Stepper"
import { Option } from "@framework/types/types"
import useURLSearchParams from "@framework/hooks/useURLSearchParams"
import { FormStepName } from "@store/manage-campaigns/validation"
import { useStore } from "@store/index"
import EditAssetGroupPage from "@pages/PMax/components/EditAssetGroupPage"
import CreateAssetGroupPage from "@pages/PMax/components/CreateAssetGroupPage"
import BudgetAndBiddingForm from "../components/BudgetForm"
import CampaignSettingsForm from "../components/CampaignSettingsForm"
import AssignGroupForm from "../components/AssignGroupForm"
import FormSummary from "../components/FormSummary/FormSummary"
import { useActiveSectionContext } from "../ActiveSectionContext"

import styles from "./EditCampaign.module.scss"

const stepsSequence: Option<FormStepName>[] = [
  { value: "budget", label: "Budget and bidding" },
  { value: "settings", label: "Campaign settings" },
  { value: "assets", label: "Asset group" },
  { value: "summary", label: "Summary" },
]

export const EditCampaignForm: React.FC = observer(() => {
  const location = useLocation()
  const history = useHistory()

  const activeSectionContext = useActiveSectionContext()

  const params = useURLSearchParams()
  const currentStepName = params.get("s")
  const assetGroupId = params.get("assetGroupId")
  const createAssetGroup = params.get("createAssetGroup")

  const formStep = stepsSequence.findIndex((it) => it.value === currentStepName)

  const {
    editPMaxCampaignStore: store,
    accountStore: { accountId },
  } = useStore()

  const completed = stepsSequence.map((it) => store.isStepValid(it.value))

  const handleNext = () => {
    const newStepIndex = formStep + 1
    if (newStepIndex >= stepsSequence.length) {
      // TODO publish
    } else {
      const newSearch = new URLSearchParams(params)
      newSearch.set("s", stepsSequence[newStepIndex].value)
      history.replace(
        `/google-ads/${accountId}/campaign/${
          store.campaignId
        }?${newSearch.toString()}`
      )
    }
  }

  const changeStep = (stepIdx: number) => {
    const newSearch = new URLSearchParams(params)
    newSearch.set("s", stepsSequence[stepIdx].value)
    history.replace(`${location.pathname}?${newSearch.toString()}`)
  }

  const handleLeave = () => {
    history.replace("../manage-campaign")
  }

  const handleBack = () => {
    if (activeSectionContext.activeForm) {
      changeStep(stepsSequence.length - 1)
      return
    }

    const newStepIndex = formStep - 1
    if (newStepIndex < 0) handleLeave()
    else changeStep(newStepIndex)
  }

  React.useEffect(() => {
    if (currentStepName) {
      const newStepIdx = stepsSequence.findIndex(
        (it) => it.value === currentStepName
      )

      if (
        newStepIdx >= 0 &&
        stepsSequence[newStepIdx]?.value &&
        store.isStepValid(stepsSequence[newStepIdx]?.value)
      )
        return
    }

    const firstInvalidIdx = stepsSequence.findIndex(
      (it) => !store.isStepValid(it.value)
    )

    if (firstInvalidIdx >= 0) changeStep(firstInvalidIdx)
    else changeStep(stepsSequence.length - 1)
  }, [])

  const handleRedirect = (idx: number) => {
    const potentialStep = stepsSequence[idx]
    const prevStep = stepsSequence[idx - 1]

    if (potentialStep == null) return

    if (
      !store.isStepValid(potentialStep.value) &&
      !(prevStep != null && store.isStepValid(prevStep.value))
    )
      return

    changeStep(idx)
  }

  return (
    <Stack direction="row" gutter="20">
      <div className={styles.menuContainer}>
        <Templates.Header
          offset="big"
          left={
            <Stack direction="row" gutter="16" align="center">
              <BackButton onClick={handleLeave} />
              <Typography type="h1" weight="bold">
                Performance Max
              </Typography>
            </Stack>
          }
        />

        <Box className={styles.menu}>
          <Stack gutter="16">
            <Typography type="h2" className={styles.menuLabel}>
              Personal settings
            </Typography>
            <Stepper
              orientation="vertical"
              steps={stepsSequence}
              complete={completed}
              onClick={(_, idx) => handleRedirect(idx)}
              activeStep={formStep}
            />
          </Stack>
        </Box>
      </div>

      <div className={styles.bodyContainer}>
        {formStep === 0 && (
          <BudgetAndBiddingForm onSubmit={handleNext} onCancel={handleBack} />
        )}
        {formStep === 1 && (
          <CampaignSettingsForm onSubmit={handleNext} onCancel={handleBack} />
        )}
        {formStep === 2 && !assetGroupId && !createAssetGroup && (
          <AssignGroupForm onSubmit={handleNext} onCancel={handleBack} />
        )}
        {formStep === 2 && assetGroupId && <EditAssetGroupPage />}
        {formStep === 2 && !assetGroupId && createAssetGroup && (
          <CreateAssetGroupPage />
        )}
        {formStep === 3 && (
          <FormSummary onSubmit={handleLeave} onCancel={handleBack} />
        )}
      </div>
    </Stack>
  )
})

export default EditCampaignForm
