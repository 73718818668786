import keyBy from "lodash/keyBy"
import capitalize from "lodash/capitalize"
import { Option } from "@framework/types/types"
import {
  PermissionLevel,
  permissionsType,
  PermissionType,
} from "@framework/types/user"

const agencyDescriptionMap: Record<PermissionLevel, string> = {
  director:
    "Full Access to Add, delete, edit users to agency, clients and Accounts. Can manage and view campaigns",
  write:
    "Can create, edit, manage campaigns for all or assigned clients and accounts",
  read: "Can view client list, accounts, campaigns for all or assigned clients and accounts",
}
const clientDescriptionMap: Record<PermissionLevel, string> = {
  director:
    "Full Access to Add, delete, edit users to clients and Accounts. Can manage and view campaigns",
  write: "Can create, edit, manage campaigns for specific client and accounts",
  read: "Can view accounts and campaigns for all or assigned accounts",
}
const accountDescriptionMap: Record<PermissionLevel, string> = {
  director:
    "Full Access to Add, delete user and or Accounts. Can manage and view campaigns",
  write: "Can create, edit, manage campaigns for specific accounts",
  read: "Can view account",
}
export const descriptionsMap: Record<
  PermissionType,
  Record<PermissionLevel, string>
> = {
  agency: agencyDescriptionMap,
  client: clientDescriptionMap,
  account: accountDescriptionMap,
}

export const getDescriptor = (
  accessType?: PermissionType,
  accessLevel?: PermissionLevel
) => {
  if (accessLevel && accessType)
    return descriptionsMap[accessType]?.[accessLevel] ?? null
  return null
}

export const permissionLevelOptions: {
  value: PermissionLevel
  label: string
  description: string
}[] = [
  {
    value: "director",
    label: "Admin",
    description:
      "Full Access to Add, delete, edit users to clients and Accounts. Can manage and view campaigns",
  },
  {
    value: "write",
    label: "Manager",
    description:
      "Can create, edit, manage campaigns for specific client and accounts",
  },
  {
    value: "read",
    label: "Viewer",
    description: "Can view accounts and campaigns for all or assigned accounts",
  },
]

export const permissionLevels = permissionLevelOptions.map((it) => it.value)

export const permissionLevelMap = keyBy(permissionLevelOptions, "value")

export const getPermissionLevelOption = (level?: string) => {
  if (level) return permissionLevelMap[level] ?? null
  return null
}

export const permissionsTypeOptions: Option[] = permissionsType.map((item) => ({
  value: item,
  label: capitalize(item),
}))
