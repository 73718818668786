/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
import { action, computed, observable, runInAction } from "mobx"
import moment from "moment"
import keyBy from "lodash/keyBy"

import { clientKPIDropDownListMock } from "@components/ui/KPIsWidget/mockData"
import { generateTimeAxisLabels, periodLength, periodicityToUnit } from "@components/utils/date"
import { ClientsPerformance } from "@framework/types/client"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import clientService from "@services/client.service"
import { TimeSegmentedPerformanceReport, PerformanceReport, MetricNameType } from "@framework/types/metrics"
import { apiDateFormatter } from "@services/utils"
import { nanoid } from "nanoid"
import RootStore from "../RootStore"
import ClientMonthlyReportStore from "./monthly-report.store"

class ClientDashboardStore {
  // INJECTIONS

  @observable monthlyPerformanceStore: ClientMonthlyReportStore

  // CONSTRUCTOR

  constructor(root: RootStore) {
    this.monthlyPerformanceStore = new ClientMonthlyReportStore(root)
    const currentDate = new Date()
    this.dateRange = [currentDate, currentDate]
  }

  // STATE

  @observable dateRange: [Date, Date]

  @observable periodicity: PerformanceReportPeriodicity = "daily"

  @observable currPeriodPerformance: PerformanceReport[] | null = null

  @observable prevPeriodPerformance: PerformanceReport[] | null = null

  @observable performance: PerformanceReport | null = null

  @observable clientsPerformanceReport: ClientsPerformance | null = null

  @observable overallKPILoadError: string | null = null

  @observable error: string | null = null

  @observable activeMetrics: MetricNameType[] = [
    clientKPIDropDownListMock[0],
    clientKPIDropDownListMock[1],
  ]

  @observable isHistoricalPerformanceLoading: boolean = false

  @observable loadingPerformance: boolean = false

  @observable currPeriodLabels: string[] = []

  @observable prevPeriodLabels: string[] = []

  @computed get currPeriod() {
    return { from: this.dateRange[0], to: this.dateRange[1] }
  }

  @computed get prevPeriod() {
    return {
      from: moment(this.dateRange[0]).subtract(1, "year").toDate(),
      to: moment(this.dateRange[1]).subtract(1, "year").toDate(),
    }
  }

  // ACTIONS

  @action setDateRange = (date: [Date, Date]) => {
    this.dateRange = date.sort((d1, d2) => d1.getTime() - d2.getTime())
  }

  @action setPeriodicity = (value: PerformanceReportPeriodicity) => {
    this.periodicity = value
  }

  @action setMetric = (index: number, metricName: MetricNameType) => {
    this.activeMetrics[index] = metricName
  }

  @observable lastRequestId: string = nanoid()

  @action loadHistoricalPerformance = async (clientId: number) => {
    try {
      const requestId = nanoid()

      this.isHistoricalPerformanceLoading = true

      const totalChatPoints = periodLength(
        [this.currPeriod.from, this.currPeriod.to],
        periodicityToUnit(this.periodicity)
      )

      const currPeriodLabels = generateTimeAxisLabels(
        this.currPeriod.from,
        totalChatPoints,
        this.periodicity
      )

      const prevPeriodLabels = generateTimeAxisLabels(
        this.prevPeriod.from,
        totalChatPoints,
        this.periodicity
      )

      // reset current state
      runInAction(() => {
        this.lastRequestId = requestId

        this.currPeriodPerformance = null
        this.currPeriodLabels = currPeriodLabels

        this.prevPeriodPerformance = null
        this.prevPeriodLabels = prevPeriodLabels

        this.error = null
      })

      const [current, previous] = await Promise.all([
        this._loadPerformancePeriodReport(
          clientId,
          this.currPeriod.from,
          this.currPeriod.to
        ),
        this._loadPerformancePeriodReport(
          clientId,
          this.prevPeriod.from,
          this.prevPeriod.to
        ),
      ])

      // ignore outdated response
      if (this.lastRequestId !== requestId) return



      const currPeriodPerformance = transformHistoricalTrendData(
        this.currPeriodLabels,
        current
      )

      const prevPeriodPerformance = transformHistoricalTrendData(
        this.prevPeriodLabels,
        previous
      )

      // set new state
      runInAction(() => {
        this.currPeriodLabels = currPeriodLabels
        this.currPeriodPerformance = currPeriodPerformance

        this.prevPeriodLabels = prevPeriodLabels
        this.prevPeriodPerformance = prevPeriodPerformance

        this.isHistoricalPerformanceLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.error = "Unexpected error while loading historical KPI trends"
        this.isHistoricalPerformanceLoading = false
      })
    }
  }

  private _loadPerformancePeriodReport = async (
    clientId: number,
    from: Date,
    to: Date
  ) =>
    clientService
      .getPerformancePeriodReport(clientId, {
        from: apiDateFormatter(from),
        to: apiDateFormatter(to),
        periodicity: this.periodicity,
      })
      .then((response) => response.data.data)

  @action loadPerformance = async (clientId: number, from: Date, to: Date) => {
    this.loadingPerformance = true
    try {
      this.performance = null
      this.clientsPerformanceReport = null
      this.overallKPILoadError = null
      const response = await clientService
        .getPerformance(clientId, {
          from: apiDateFormatter(from),
          to: apiDateFormatter(to),
          compare: true,
        })
        .then((response) => response.data.data)
      this.performance = response.Metrics
      this.clientsPerformanceReport = response.AccountsPerformance
    } catch (error) {
      this.overallKPILoadError = "error"
    }
    this.loadingPerformance = false
  }
}

export default ClientDashboardStore

const transformHistoricalTrendData = (
  labels: string[],
  data: TimeSegmentedPerformanceReport[]
): PerformanceReport[] => {
  const dataMap = keyBy(data, (it) => it.Period)
  return labels.map((pointKey) => dataMap[pointKey]?.Metrics ?? {})
}