import React from "react"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Icon from "@components/ui/Icon/Icon"
import { SortByType } from "@framework/types/types"

import styles from "./EditAdCopyForm.module.scss"

export interface ColumnHeaderCellProps {
  name: string
  orderBy?: SortByType | null
  label: string
  onSort?: (name: string) => void
}

export const ColumnHeaderCell: React.FC<ColumnHeaderCellProps> = ({
  name,
  label,
  orderBy,
  onSort,
}) => (
  <Typography
    type="h4"
    color="black70Color"
    className={styles.colHeader}
    onClick={() => onSort?.(name)}
  >
    <Stack align="center" direction="row">
      {label}
      {orderBy != null && orderBy.value === name && (
        <Icon name="arrow-down" rotateAngle={orderBy.direction ? 180 : 0} />
      )}
    </Stack>
  </Typography>
)

export default ColumnHeaderCell
