import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { useController, useStore } from "@store/index"
import ConfirmModal from "@components/modals/ConfirmModal/ConfirmModal"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ConfirmPublishAdCopiesModal: React.FC<Props> = observer(
  ({ isOpen, onClose }) => {
    const alert = useAlert()
    const { adCopyManagement } = useController()
    const {
      accountStore: { accountId },
    } = useStore()

    const handleApply = async () => {
      if (accountId == null) return

      const error = await adCopyManagement.publishChanges(accountId)

      onClose()

      if (error)
        alert.error(<AlertMessage title="Error occurred" description={error} />)
      else
        alert.success(
          <AlertMessage
            title="Successfully published"
            description="Ad copies have been successfully published!"
          />
        )
    }

    return (
      <ConfirmModal
        title="Publish changes to Google"
        message="Are you sure you want to publish all changes?"
        isOpen={isOpen}
        onClose={onClose}
        applyText="Yes, Publish changes"
        onApply={handleApply}
      />
    )
  }
)

export default ConfirmPublishAdCopiesModal
