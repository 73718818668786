import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Box from "@components/ui/Box/Box"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import { numberWithCommas } from "@utils/numberUtils"

import styles from "./KeywordsList.module.scss"

export type KeywordItemProps = {
  item: any
  isSelected: boolean
  isActive?: boolean
  setRef?: <T extends HTMLDivElement = any>(ref: T) => void
  handleSelect: (item: any) => void
  handleAdd?: (id: number) => void
}

export const KeywordItem: React.FC<KeywordItemProps> = observer(
  ({ item, isActive, isSelected, setRef, handleSelect, handleAdd }) => {
    const { id, name, distance, avgSearchVolume, categoryName, inUse } = item
    const textColor = "dark"

    return (
      <div ref={setRef}>
        <Box
          onClick={() => handleSelect(item)}
          className={clsx(styles.item, {
            [styles.selected]: isSelected,
          })}
        >
          <Tooltip lineClamp={2} text={name} type="h3">
            <Typography type="h3" color={textColor}>
              {name}
            </Typography>
          </Tooltip>
          <Typography className={styles.capitalize} color={textColor}>
            {categoryName}
          </Typography>
          <Typography className={styles.capitalize} color={textColor}>
            {avgSearchVolume}
          </Typography>
          <Typography color={textColor}>
            {numberWithCommas(distance, 2)}
          </Typography>
          <div className={clsx(styles.buttonContainer)}>
            {inUse ? (
              <Typography type="h3">Active</Typography>
            ) : (
              <Button
                variant={isActive ? "ghost" : "outlined"}
                color="primary"
                fullWidth
                onClick={(e) => {
                  e.stopPropagation()
                  handleAdd?.(id)
                }}
              >
                {isActive ? "Remove" : "Add"}
              </Button>
            )}
          </div>
        </Box>
      </div>
    )
  }
)

export default KeywordItem
