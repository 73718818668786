import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import FormTextField from "@framework/prototypes/FormTextField"
import Label from "@components/ui/Label/Label"
import styles from "./Assets.module.scss"

const AssetGroupName: FC = observer(() => (
  <section className={styles.assetGroupNameWrap}>
    <div className={styles.assetGroupName}>
      <Typography type="h2">Asset group name</Typography>
      <Label
        label="Enter asset group name"
        type="body2"
        className={styles.assetGroupNameInput}
      >
        <FormTextField name="name" />
      </Label>
    </div>
  </section>
))

export default AssetGroupName
