import sortBy from "lodash/sortBy"
import get from "lodash/get"

import { MapperType } from "@components/ui/WaterfallTable/types"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import {
  AccountReport,
  AdSetReport,
  CampaignReport,
  ChannelReport,
  PeriodReport,
  PlatformReport,
} from "@store/reports/types"
import { SortByType } from "@framework/types/types"
import MetricCell from "./MetricCell"

export const getMetricsCellRenderer = (
  name: MetricNameType
): MapperType["render"] => ({
  Component: MetricCell,
  props: { name },
})

export const transformMetricsToTableMapper = (
  metrics: MetricNameType[]
): MapperType[] =>
  metrics.map<MapperType>((id) => {
    const description = getMetricDescriptor(id)
    return {
      name: description.name,
      label: description.shortLabel,
      sortable: true,
      render: getMetricsCellRenderer(description.name),
      widthBasis: `${Math.max(7, description.shortLabel.length) + 2}ch`,
    }
  })

export const sortReports = <
  T extends
    | PlatformReport
    | PeriodReport
    | AccountReport
    | ChannelReport
    | CampaignReport
    | AdSetReport
>(
  reports: T[],
  sort: SortByType
): T[] => {
  if (!sort.value || reports.length < 2) return reports
  const res = sortBy(
    reports,
    sort.value === "label" || sort.value === "status"
      ? (it) => get(it, sort.value)
      : `${sort.value}.Value`
  )
  if (sort.direction) return res
  return res.reverse()
}
