import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"
import { AdsReportType } from "@framework/types/account"
import { MapperType } from "@components/ui/WaterfallTable/types"
import AdContent from "@framework/prototypes/AdCopy/Preview/AdCard/AdContent"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import { useStore } from "@store/index"

import styles from "./index.module.scss"

export const AD_COPY_EDIT_TASK = "edit-ad"

interface AdRowProps {
  mapper: MapperType[]
  data: AdsReportType
  className?: string
  editable?: boolean
}

const AdRow: React.FC<AdRowProps> = observer(
  ({ mapper, data, editable = true, className }) => {
    const {
      manageCampaignStore,
      accountStore: { accountId },
    } = useStore()

    const headline =
      data.Headline && data.Headline !== " --"
        ? data.Headline
        : data.HeadlinePart1

    const description = data.Description

    const handleEdit = () => {
      if (accountId == null) return
      manageCampaignStore.createEditAdCopyTask(accountId, data.Id)
    }

    const isEditable = editable && data.Status !== "REMOVED"

    return (
      <ClosableRow
        id={data.Id}
        className={className}
        component={
          <TypedRow
            label={
              <div className={styles.labelContainer}>
                <AdContent
                  website={data.FinalUrl}
                  headlinesLimit={3}
                  headlines={headline.split(" | ")}
                  descriptions={description.split(". ")}
                  variant="desktop"
                />
                {isEditable ? (
                  <IconButton onClick={handleEdit}>
                    <Icon name="pen" />
                  </IconButton>
                ) : null}
              </div>
            }
            data={{
              ...data,
              AdId: data.Id,
              type: "ad",
              editable: isEditable,
            }}
            leftTip={<TypedTip data={campaignEntityDescription.ad} />}
            mapper={mapper}
          />
        }
      />
    )
  }
)

export default AdRow
