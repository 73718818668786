import React, { FC, useEffect, useState } from "react"
import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
// import Icon from "@components/ui/Icon/Icon"
// import IconButton from "@components/ui/Button/IconButton"
// import TextField from "@components/ui/TextField/TextField"
import AudienceSignalItem from "@pages/PMax/components/EditAssetGroupPage/AudienceSignal/AudienceSignalItem"
import { Button } from "@components/ui/Button"
import { AudiencesEntity } from "@framework/types/manageCampaign"
import { observer } from "mobx-react-lite"
import useURLSearchParams from "@framework/hooks/useURLSearchParams"
import { useStore } from "@root/store"
import { useAlert } from "react-alert"
import { AddAudienceRequestType } from "@services/account-campaign.service"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import styles from "./AudienceSignal.module.scss"

interface AudienceSignalSidebarProps {
  isOpen: boolean
  onClose: () => void
}

const AudienceSignalSidebar: FC<AudienceSignalSidebarProps> = observer(
  ({ isOpen, onClose }) => {
    const params = useURLSearchParams()
    const assetGroupId: number = Number(params.get("assetGroupId"))
    const { pMaxAssetGroupStore, accountStore } = useStore()
    const alert = useAlert()
    const { getAudiences, addAudience, audiences, isLoading } =
      pMaxAssetGroupStore
    const accountId = accountStore.getAccountId()

    // const [searchString, setSearchString] = useState<string>("")
    const [filteredList, setFilteredList] = useState<AudiencesEntity[] | null>(
      null
    )
    const [selectedAudienceId, setSelectedAudienceId] = useState<number | null>(
      null
    )

    const handleAddAudience = async () => {
      const body: AddAudienceRequestType = {
        audienceId: selectedAudienceId as number,
      }
      // async function* addAudienceGenerator() {
      //   let results
      //   // eslint-disable-next-line no-restricted-syntax
      //   for (const item of body) {
      //     // @ts-ignore
      //     results = yield addAudience(accountId, assetGroupId, item)
      //   }
      //   return results
      // }
      // const gen = addAudienceGenerator()
      //
      // const callback = async (result: any) => {
      //   if (result.value.error && !result.done) {
      //     alert.error(result.value.error)
      //   }
      //   if (result.done) {
      //     alert.success("Audience successfully added")
      //     await getAudiences(accountId, assetGroupId)
      //   }
      // }
      //
      // let result
      // while (!result || !result.done) {
      //   // eslint-disable-next-line no-await-in-loop
      //   result = await gen.next(result && result.value)
      //   callback(result)
      // }
      await addAudience(accountId, assetGroupId, body).then((err) => {
        if (err) {
          alert.error(err)
        } else {
          alert.success("Audience successfully added")
          getAudiences(accountId, assetGroupId)
          onClose()
        }
      })
      setSelectedAudienceId(null)
    }
    useEffect(() => {
      getAudiences(accountId, assetGroupId)
    }, [])

    useEffect(() => {
      setFilteredList(audiences)
    }, [audiences])

    // const onSearch = (str: string) => {
    //   setSearchString(str)
    //   const filteredItems = audiences.filter((i) =>
    //     i.name.toLowerCase().includes(str.toLowerCase())
    //   )
    //   setFilteredList(filteredItems)
    // }

    const handleSelectAudience = (id: number) => {
      if (selectedAudienceId === id) {
        setSelectedAudienceId(null)
      } else {
        setSelectedAudienceId(id)
      }
    }

    return (
      <SidebarContainer
        isOpen={isOpen}
        onClose={onClose}
        className={styles.sidebar}
      >
        {isLoading ? (
          <CircleLoader />
        ) : (
          <Stack gutter="32" grid-gap="0">
            <Typography type="h2">Select an audience</Typography>
            {/* <TextField */}
            {/*  className={styles.searchField} */}
            {/*  before={<Icon name="search" key="search" />} */}
            {/*  after={ */}
            {/*    searchString ? ( */}
            {/*      <IconButton onClick={() => onSearch("")}> */}
            {/*        <Icon name="cross" /> */}
            {/*      </IconButton> */}
            {/*    ) : null */}
            {/*  } */}
            {/*  placeholder="Search by audience name" */}
            {/*  onChange={(e) => onSearch(e.target.value)} */}
            {/*  value={searchString} */}
            {/* /> */}
            <Typography type="h3" className={styles.listingSubtitle}>
              Your saved suggested audiences
            </Typography>
            <div className={styles.audiencesList}>
              {filteredList?.map((item) => (
                <AudienceSignalItem
                  audience={item}
                  key={item.id}
                  onSelectAudience={handleSelectAudience}
                  selectedAudienceId={selectedAudienceId}
                />
              ))}
            </div>

            <div className={styles.sidebarFooter}>
              <Button
                color="primary"
                className={styles.sidebarButton}
                onClick={handleAddAudience}
              >
                Confirm
              </Button>
              <Button color="secondary" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Stack>
        )}
      </SidebarContainer>
    )
  }
)

export default AudienceSignalSidebar
