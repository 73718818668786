import { action, computed, observable } from "mobx"

import Loadable from "@framework/classes/Loadable"
import { LanguageEntity } from "@framework/types/manageCampaign"
import { ID } from "@framework/types/types"

export class Languages extends Loadable<LanguageEntity[]> {
  @computed get dataIndex(): Map<string, LanguageEntity> {
    if (this.data == null) return new Map()
    return new Map(this.data.map((it) => [it.criterionId.toString(), it]))
  }

  @computed get findById() {
    const { dataIndex } = this
    return (id: ID) => dataIndex.get(id.toString())
  }
}

export default Languages
