import { observer } from "mobx-react-lite"
import React from "react"

import Button from "@components/ui/Button/Button"
import { ID } from "@framework/types/types"
import { useStore } from "@store/index"

import styles from "./CompareFeedSidebar.module.scss"

export interface FooterProps {
  productId: ID
  isLoading?: boolean
  hasChanges?: boolean
}

export const Footer: React.FC<FooterProps> = observer(
  ({ productId, isLoading = false, hasChanges }) => {
    const {
      accountStore: { accountId },
      productFeedStore: { list, changes, GMC },
    } = useStore()

    const productChange = changes.changeById(productId)

    const handleApply = async () => {
      if (accountId && productChange.initialized && productChange.data) {
        const error = await changes.applyChange(
          accountId,
          productId,
          productChange.data
        )
        if (error) return
        list.reloadProductFeed()
        productChange.load(accountId, productId)
      }
    }

    const handleSkip = async () => {
      if (accountId && productChange.initialized) {
        const error = await changes.dropProductChange(accountId, productId)
        if (!error) productChange.load(accountId, productId)
      }
    }

    const handleGMCExport = () => {
      if (accountId && productChange.initialized && productId != null) {
        if (GMC.isConnected) {
          GMC.publishProducts(accountId, productId)
        } else {
          GMC.showPlaceholderModal(true)
        }
      }
    }

    const disabled = !productChange.initialized && isLoading

    return (
      <div className={styles.footer}>
        <div className={styles.control}>
          {/* <Button disabled={disabled} color={"secondary"}>
            Cancel
          </Button> */}
        </div>
        <div className={styles.control}>
          <Button
            disabled={disabled || !hasChanges}
            color="secondary"
            onClick={handleSkip}
          >
            Skip all changes
          </Button>
          <Button
            onClick={handleGMCExport}
            disabled={disabled || !GMC.isExportAvailable || !GMC.isInited}
          >
            Publish to Google
          </Button>
          {/* <Button disabled={disabled || !hasChanges} onClick={handleApply}>
            Apply all changes
          </Button> */}
        </div>
      </div>
    )
  }
)

export default Footer
