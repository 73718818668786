import { PerformanceReport } from "@framework/types/metrics"

export type ViewType = "table" | "card"

// types can be change during API integration
export const metaCampaignStatusNames = [
  "INACTIVE",
  "IN_REVIEW",
  "ACTIVE",
  "LOW_PERFORMANCE",
  "FAILED",
] as const

export type MetaCampaignStatus = (typeof metaCampaignStatusNames)[number]

export type MetaCampaignReportData = {
  id: string
  status: MetaCampaignStatus
  name: string
  campaignName: string
  adSetName: string
  previewUrl: string
  totalViews?: number
} & PerformanceReport
