import { ChartOptions, LinearScaleOptions } from "chart.js"
import identity from "lodash/identity"

import { getMetricDescriptor } from "@framework/constants/metrics"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { MetricNameType } from "@framework/types/metrics"
import { DeepPartial } from "chart.js/types/utils"

const DAY_FORMAT = "MMM dd, yyyy"
const MONTH_FORMAT = "MMM yyyy"
const YEAR_FORMAT = "MMM yyyy"

export const periodicityToScaleUnit = (
  periodicity: PerformanceReportPeriodicity
): "day" | "week" | "month" => {
  if (periodicity === "monthly") return "month"
  if (periodicity === "weekly") return "week"
  return "day"
}

const getSuggestedMaxValue = (kpiName: string): number | undefined => {
  if (
    kpiName === "Ctr" ||
    kpiName === "ResClickRate" ||
    kpiName === "ConversionRate"
  )
    return 0.1
  return undefined
}

export const makePerformanceChartYAxisConfig = (
  KPI2: MetricNameType,
  position: "right" | "left" = "left",
  displayGrid = true
): DeepPartial<LinearScaleOptions> => ({
  position,
  axis: "y",
  ticks: {
    font: { family: "'Poppins'" },
    callback: (value: number | string) =>
      (getMetricDescriptor(KPI2)?.formatter ?? identity)(value),
    padding: 10,
  },
  // min: 0,
  // max: getMaxValue(KPI2, KPI2MaxValue),
  suggestedMin: 0,
  suggestedMax: getSuggestedMaxValue(KPI2),
  grid: {
    display: displayGrid,
    drawBorder: false,
  },
})

export const makePerformanceChartConfig = (
  labels: string[],
  periodicity: PerformanceReportPeriodicity = "daily",
  scales: Record<string, DeepPartial<LinearScaleOptions>>,
  xAxisUnitName: "unit" | "minUnit" = "minUnit"
): ChartOptions<"line"> => {
  const stepSize = labels.length > 15 ? 2 : 1

  return {
    maintainAspectRatio: false,
    aspectRatio: 3,
    layout: {
      padding: {
        top: 15,
        bottom: 5,
        left: 5,
        right: 5,
      },
    },
    elements: {
      point: {
        radius: 1,
        hitRadius: 5,
        backgroundColor: "white",
        hoverRadius: 4,
        hoverBackgroundColor: "white",
        hoverBorderWidth: 1.5,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        axis: "x",
        type: "time",
        time: {
          [xAxisUnitName]: periodicityToScaleUnit(periodicity),
          stepSize,
          isoWeekday: true,
          tooltipFormat: DAY_FORMAT,
          displayFormats: {
            day: DAY_FORMAT,
            week: DAY_FORMAT,
            month: MONTH_FORMAT,
            year: YEAR_FORMAT,
          },
        },
        ticks: {
          font: { family: "'Poppins'" },
        },
        grid: {
          display: false,
        },
      },
      ...scales,
    },
    plugins: {
      tooltip: {
        axis: "x",
        position: "nearest",
        usePointStyle: true,
        borderWidth: 3,
        callbacks: {
          labelPointStyle() {
            return {
              pointStyle: "circle",
              rotation: 0,
            }
          },
          label(item) {
            const { label, key } = item.dataset as any
            const valueTransformer =
              getMetricDescriptor(key as MetricNameType)?.formatter ?? identity
            return ` ${label}:  ${valueTransformer((item.raw as number) ?? 0)}`
          },
        },
      },
    },
  }
}

export default makePerformanceChartConfig
