/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React, { CSSProperties, ReactNode } from "react"

import { TypographyColorType, TypographyVariantType } from "@utils/enums"
import colorStyles from "./TypographyColor.module.sass"

import styles from "./Typography.module.scss"

type BlackColors =
  | "black100Color"
  | "black90Color"
  | "black80Color"
  | "black70Color"
  | "black60Color"
  | "black40Color"
  | "black30Color"
  | "black20Color"
  | "black10Color"
  | "black05Color"
  | "black03Color"

type PrimaryColors =
  | "primary140Color"
  | "primary120Color"
  | "primary100Color"
  | "primary80Color"
  | "primary60Color"
  | "primary40Color"
  | "primary20Color"
  | "primary10Color"
  | "primary05Color"

type InfoColors = "info120Color" | "info100Color" | "info80Color"

type ErrorColors =
  | "error120Color"
  | "error100Color"
  | "error80Color"
  | "error20Color"

type SuccessColors = "success120Color" | "success100Color" | "success80Color"

export interface ITypographyProps {
  children?: ReactNode
  type?: TypographyVariantType
  inline?: boolean
  /**
   * @deprecated use weight instead
   */
  bold?: boolean
  weight?: "normal" | "semibold" | "bold"
  color?:
    | TypographyColorType
    | BlackColors
    | PrimaryColors
    | InfoColors
    | ErrorColors
    | SuccessColors
  className?: string
  title?: string
  upperCase?: boolean
  style?: CSSProperties
  as?: "div" | "span" | "label"
  onClick?: React.MouseEventHandler
}

export const Typography: React.FC<ITypographyProps> = ({
  children,
  as: Component = "div",
  type = "body1",
  color = "dark",
  inline = false,
  bold = false,
  upperCase = false,
  weight,
  className,
  title,
  style,
  onClick,
}: ITypographyProps) => (
  <Component
    style={style}
    className={clsx(styles.root, className, styles[type], colorStyles[color], {
      [styles[`weight-${weight}`]]: weight != null,
      [styles.bold]: weight == null && bold,
      [styles.upperCase]: upperCase,
      [styles.inline]: inline,
    })}
    role={onClick ? "button" : undefined}
    onClick={onClick}
    title={title}
  >
    {children}
  </Component>
)

export default Typography
