import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

import Box from "@components/ui/Box/Box"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/Loader"
import Typography from "@components/ui/Typography/Typography"
import { numberWithCommas } from "@components/utils/numberUtils"
import { ShopifyStatusUpdate } from "@framework/types/account"
import { Option } from "@framework/types/types"
import { useStore } from "@store/index"

import styles from "./OptimizationProduct.module.scss"

const mapper: Option<keyof ShopifyStatusUpdate>[] = [
  { value: "productsAdded", label: "Shopify products added " },
  { value: "productsApproved", label: "Products updated" },
  { value: "productsSendToGoogle", label: "Products sent to GMC" },
  // { value: "productsInReview", label: "Products for review" },
  // { value: "productsApproved", label: "Products approved" },
  // { value: "productsSendToGoogle", label: "Products sent to Google" },
]

export interface OptimizationProductProps {
  className?: string
}

export const OptimizationProduct: React.FC<OptimizationProductProps> = observer(
  ({ className }) => {
    const history = useHistory()

    const {
      accountStore: { accountId },
      productFeedStore: {
        dashboard,
        isInited,
        isFeedAvailable,
        isProductsImportLoading,
        importShopifyProducts,
      },
    } = useStore()

    const handleImportProducts = () => {
      if (!accountId) return
      importShopifyProducts(accountId)
    }

    const handleShowFeed = () => history.push("./product-feed")

    useEffect(() => {
      if (accountId) dashboard.loadStatus(accountId)
    }, [accountId])

    const status: any = dashboard.status ?? {}

    return (
      <div className={clsx(styles.root, className)}>
        <Box padding={24}>
          <Typography type="h2">Product Status</Typography>

          <Loader isLoading={dashboard.isStatusLoading} />

          <div className={styles.linkList}>
            {mapper.map(({ value, label }) => (
              <div className={styles.link}>
                <Typography type="h6" color="gray" upperCase>
                  {label}
                </Typography>
                <Typography
                  className={styles.clickable}
                  type="h3"
                  color="primary"
                  bold
                  onClick={handleShowFeed}
                >
                  {numberWithCommas(status[value] ?? 0)} {">"}
                </Typography>
              </div>
            ))}
          </div>
        </Box>

        <div className={styles.control}>
          <Button
            onClick={handleShowFeed}
            disabled={isProductsImportLoading || !isInited || !isFeedAvailable}
            color="primary"
          >
            Product Feed
          </Button>
          <Button
            onClick={handleImportProducts}
            color="secondary"
            disabled={isProductsImportLoading || !isInited}
          >
            Import Products
          </Button>
        </div>
      </div>
    )
  }
)

export default OptimizationProduct
