import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Stack from "@components/ui/Stack/Stack"
import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import ScrollShadow from "@components/ui/ScrollShadow/ScrollShadow"
import RequiredSettingsForm from "./RequiredSettingsForm"
import AdvancedSettingsForm from "./AdvancedSettingsForm"
import WrappableContainer from "../EditCopySidebar/WrappableContainer"

import styles from "./RegenerateAdCopyForm.module.scss"

export interface RegenerateAdCopyMultiFormProps {}

export const RegenerateAdCopyMultiForm: React.FC<RegenerateAdCopyMultiFormProps> =
  observer(() => (
    <div className={clsx(styles.container, styles.sections)}>
      <ScrollShadow className={styles.scrollContainer}>
        {(ref) => (
          <ScrollContainer
            ref={ref}
            type="secondary"
            className={styles.bodyContainer}
          >
            <Stack gutter="0">
              <section className={styles.section}>
                <RequiredSettingsForm />
              </section>
              <section className={styles.section}>
                <WrappableContainer label="Advanced" defaultOpened>
                  <AdvancedSettingsForm />
                </WrappableContainer>
              </section>
            </Stack>
          </ScrollContainer>
        )}
      </ScrollShadow>
    </div>
  ))

export default RegenerateAdCopyMultiForm
