import React from "react"

import Modal, { ModalProps } from "@components/ui/Modal/Modal"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"
import { useKeywordInsightsContext } from "@pages/Opportunities/KeywordInsightsContext"
import { observer } from "mobx-react-lite"
import RelatedTopicsTable from "./RelatedTopicsTable"

import styles from "./RatedTopicsModal.module.scss"

interface RatedTopicsModalProps extends ModalProps {}

export const RatedTopicsModal: React.FC<RatedTopicsModalProps> = observer(
  ({ onClose, ...rest }) => {
    const { store } = useKeywordInsightsContext()

    const { activeGroup, options, setActiveGroup } = store.relatedTopicsStore
    const allItems = store.relatedTopicsStore.activeList

    return (
      <Modal hideHeader {...rest} className={styles.root}>
        <Templates.TripleHeader
          height="big"
          left={
            <Typography type="h3" weight="bold">
              Related Topics
            </Typography>
          }
          right={
            <IconButton size="medium" onClick={onClose}>
              <Icon name="cross" />
            </IconButton>
          }
        >
          <SimpleSelect
            value={activeGroup}
            options={options}
            onSelect={setActiveGroup}
            className={styles.dropdown}
          />
        </Templates.TripleHeader>

        <RelatedTopicsTable items={allItems} />
      </Modal>
    )
  }
)

export default RatedTopicsModal
