import { action, observable } from "mobx"
import { nanoid } from "nanoid"

import OpportunitiesController from "@store/opportunities/opportunities.controller"
import { ID } from "@framework/types/types"
import accountService from "@services/account.service"
import { RelatedQuestionEntity } from "@framework/types/opportunities"
import KeywordInsightsStore from "./keyword-insights.store"

export class KeywordInsightsController {
  @observable opportunitiesController: OpportunitiesController

  @observable store: KeywordInsightsStore

  constructor(dependencies: {
    opportunitiesController: OpportunitiesController
    keywordsInsightsStore: KeywordInsightsStore
  }) {
    this.opportunitiesController = dependencies.opportunitiesController
    this.store = dependencies.keywordsInsightsStore
  }

  @action loadTopKeywords = async (accountId: ID, keywordId: ID) => {
    const store = this.store.topKeywordsStore
    try {
      store.isLoading = true
      store.error = null

      const response = await accountService.getKeywordTopKeywords(
        accountId,
        keywordId
      )

      store.data = response.data.data ?? []
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  @action loadRelatedQuestions = async (accountId: ID, keywordId: ID) => {
    const store = this.store.relatedQuestionsStore
    try {
      store.isLoading = true
      store.error = null

      const response = await accountService.getKeywordRelatedQuestions(
        accountId,
        keywordId
      )

      response.data.data.forEach((it) => {
        const collectionStore = store.getCollectionBySetName(it.questionSet)
        collectionStore.setData(
          it.questions.map<RelatedQuestionEntity>(({ id, ...rest }) => ({
            id: id ? id.toString() : nanoid(),
            ...rest,
          }))
        )
      })
    } catch (error) {
      store.error = "Failed to load questions"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  @action loadRelatedTopics = async (keywordId: ID) => {
    const store = this.store.relatedTopicsStore
    try {
      store.isLoading = true
      store.error = null

      const response = await accountService.getKeywordRelatedTopics(keywordId)
      const records = response.data.data

      store.data = {
        top: records?.top?.map((it) => ({ ...it, id: nanoid() })) ?? [],
        rising: records?.rising?.map((it) => ({ ...it, id: nanoid() })) ?? [],
      }
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }
}

export default KeywordInsightsController
