import React from "react"
import { observer, useLocalStore } from "mobx-react-lite"
import {
  InfiniteLoader,
  List as VirtualList,
  AutoSizer,
  CellMeasurerCache,
} from "react-virtualized"
import throttle from "lodash/throttle"
import sortBy from "lodash/sortBy"

import { useController, useStore } from "@store/index"
import NoData from "@components/ui/NoData/NoData"
import { MetricNameType } from "@framework/types/metrics"
import {
  TableSortContext,
  TableSortContextStore,
} from "@framework/prototypes/TableSortContext"
import Label from "@components/ui/CheckBox/Label/Label"
import Templates from "@components/ui/Templates"
import SearchInput from "@framework/prototypes/SearchContext/SearchInput"
import {
  SearchContext,
  SearchContextStore,
} from "@framework/prototypes/SearchContext"
import { SortByType } from "@framework/types/types"
import CampaignsSelect from "@framework/prototypes/CampaignsSelect/CampaignsSelect"
import { useSelectedCampaignsContext } from "@framework/prototypes/SelectedCampaignsContext"
import { CollapsibleContext } from "@framework/prototypes/Collapsable/CollapsibleContext"
import useCollapsible from "@framework/prototypes/Collapsable/useCollapsible"
import { PMaxProductReportType } from "@framework/types/account"
import Collapsable from "@components/ui/Collapsible/Collapsable"
import RectAutoMeasurer from "@pages/ManageCampaign/components/CampaignTable/RectAutoMeasurer"
import CollapsibleList from "@framework/prototypes/Collapsable/CollapsibleList"
import { insightPMaxOptions } from "./constants"
import Header from "./ProductTableHeader"
import CampaignInsightsTemplate from "./CampaignInsightsTemplate"
import Table from "./Table/Table"
import ContentMenuTemplate from "./ContentMenuTemplate"
import useSortedProducts from "./useSortedProducts"
import ProductRow from "./ProductRow"

import styles from "./InsightTable.module.scss"

const defaultSortOption: SortByType = { value: "Conversions", direction: false }

interface RevenueProductsTableProps {
  sidebarSlot?: React.ReactNode
}

export const RevenueProductsTable: React.FC<RevenueProductsTableProps> =
  observer(({ sidebarSlot }) => {
    const {
      accountStore: { accountId },
      accountDashboardStore: {
        dateRange,
        campaignInsightsStore: {
          topCampaignStore,
          revenueProductsStore: { campaignStore, productsStore },
        },
      },
    } = useStore()

    const { accountDashboardController: controller } = useController()

    const searchStore = useLocalStore(() => new SearchContextStore())
    const sortStore = useLocalStore(
      () =>
        new TableSortContextStore({
          defaultValue: defaultSortOption,
        })
    )

    const [columns, setColumns] = React.useState<MetricNameType[]>(
      insightPMaxOptions.slice(0, 5)
    )

    const { selectedCampaigns, setCampaigns } = useSelectedCampaignsContext()

    const valuableCampaigns = React.useMemo(
      () => new Set(campaignStore.data ?? []),
      [campaignStore.data]
    )

    const campaignOptions = React.useMemo(
      () =>
        sortBy(topCampaignStore.data ?? [], (it) =>
          valuableCampaigns.has(it.Id.toString()) ? 0 : 1
        ),
      [topCampaignStore.data, valuableCampaigns]
    )

    const handleChangeColumn = (name: string, idx: number) => {
      setColumns((prev: MetricNameType[]) => {
        const newValue = [...prev]
        const swapIdx = prev.findIndex((it) => it === name)
        newValue[idx] = name as MetricNameType
        if (swapIdx >= 0) newValue[swapIdx] = prev[idx]
        return newValue
      })
    }

    const loadData = React.useCallback(
      throttle((selectedCampaigns: string[]) => {
        controller.loadRevenueProductsList(
          accountId,
          dateRange,
          selectedCampaigns
        )
      }, 2_000),
      [dateRange, accountId]
    )

    const collapsibleContext = useLocalStore(() => new CollapsibleList())

    const listRef = React.useRef<VirtualList | null>()

    const cache = React.useMemo(
      () =>
        new CellMeasurerCache({
          fixedWidth: true,
          defaultHeight: 68,
          minHeight: 55,
        }),
      [productsStore.data, collapsibleContext.isAnyActive]
    )

    React.useEffect(() => {
      listRef.current?.recomputeRowHeights()
    }, [cache])

    React.useEffect(() => {
      loadData(selectedCampaigns)
    }, [selectedCampaigns, loadData])

    React.useEffect(() => {
      controller.loadPMaxCampaigns(accountId, dateRange)
    }, [accountId, dateRange])

    const { data: products, inProgress: isSorting } = useSortedProducts({
      products: productsStore.data,
      sortBy: sortStore.sortBy,
      searchQuery: searchStore.searchQuery,
    })

    const total = products.length ?? 0
    const isLoading = productsStore.isLoading || isSorting

    return (
      <CollapsibleContext.Provider value={collapsibleContext}>
        <SearchContext.Provider value={searchStore}>
          <CampaignInsightsTemplate
            loading={isLoading}
            controlSlot={
              <Label text="Campaigns" textPosition="before">
                <CampaignsSelect
                  isOptionValuable={(id) => !valuableCampaigns.has(id)}
                  options={campaignOptions}
                  value={selectedCampaigns}
                  onChange={setCampaigns}
                  className={styles.dropdown}
                />
              </Label>
            }
          >
            <ContentMenuTemplate sidebarSlot={sidebarSlot}>
              <Templates.Header offset="small" left={<SearchInput />} />

              <TableSortContext.Provider value={sortStore}>
                <Table className={styles.table}>
                  <Header
                    options={insightPMaxOptions}
                    nameLabel="Product Name"
                    columns={columns}
                    onChangeColumn={handleChangeColumn}
                  />

                  <div className={styles.tableBody}>
                    {total > 0 ? (
                      <InfiniteLoader
                        isRowLoaded={() => true}
                        loadMoreRows={async () => {}}
                        threshold={20}
                        minimumBatchSize={20}
                        rowCount={total}
                      >
                        {({ onRowsRendered, registerChild }) => (
                          <AutoSizer>
                            {(size) => (
                              <VirtualList
                                ref={(node) => {
                                  registerChild(node)
                                  listRef.current = node
                                }}
                                onItemsRendered={onRowsRendered}
                                rowHeight={cache.rowHeight}
                                rowRenderer={(props) => {
                                  const { index, style, parent, key } = props
                                  const item = products[index]
                                  return (
                                    <div style={style} key={key}>
                                      <RectAutoMeasurer
                                        index={index}
                                        parent={parent}
                                        cache={cache}
                                        key={key}
                                      >
                                        <CollapsableProductRow
                                          data={item}
                                          mapper={columns}
                                        />
                                      </RectAutoMeasurer>
                                    </div>
                                  )
                                }}
                                height={size.height}
                                width={size.width}
                                rowCount={total}
                              />
                            )}
                          </AutoSizer>
                        )}
                      </InfiniteLoader>
                    ) : (
                      <NoData className={styles.placeholder}>
                        {isLoading
                          ? "Loading..."
                          : selectedCampaigns.length
                          ? "No data found"
                          : "No campaigns selected"}
                      </NoData>
                    )}
                  </div>
                </Table>
              </TableSortContext.Provider>
            </ContentMenuTemplate>
          </CampaignInsightsTemplate>
        </SearchContext.Provider>
      </CollapsibleContext.Provider>
    )
  })

export default RevenueProductsTable

const CollapsableProductRow: React.FC<{
  data: PMaxProductReportType
  mapper: MetricNameType[]
}> = observer(({ data, mapper }) => {
  const collapsibleContext = useCollapsible()

  const hasVariants = data.Variants != null && data.Variants.length > 0

  const handleOpen = hasVariants
    ? () => collapsibleContext.toggle(data.Title)
    : undefined

  const isOpen = collapsibleContext.isActive(data.Title)

  return (
    <div>
      <ProductRow
        data={data}
        mapper={mapper}
        open={isOpen}
        onOpen={handleOpen}
        highlighted
        lineClamp={2}
      />

      {hasVariants && (
        <Collapsable open={isOpen}>
          <div>
            {data.Variants?.map((variant) => (
              <ProductRow data={variant} mapper={mapper} key={variant.Title} />
            ))}
          </div>
        </Collapsable>
      )}
    </div>
  )
})
