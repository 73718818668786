import { action, observable } from "mobx"

import { ID } from "@framework/types/types"
import accountCampaignService from "@services/account-campaign.service"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import { datePairToPeriod } from "@utils/date"
import {
  BaseCampaignReportType,
  KeywordReportType,
  PMaxProductReportType,
  QueryReportType,
} from "@framework/types/account"
import AccountDashboardStore from "./account-dashboard"

export class AccountDashboardController {
  @observable dashboardStore: AccountDashboardStore

  constructor(config: { accountDashboardStore: AccountDashboardStore }) {
    this.dashboardStore = config.accountDashboardStore
  }

  @action loadTopCampaignList = async (accountId: ID, datePair: Date[]) => {
    const store = this.dashboardStore.campaignInsightsStore.topCampaignStore
    try {
      store.isLoading = true
      store.error = null

      const response = await accountCampaignService.getCampaignReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
          includePmaxDrafts: false,
          compare: true,
        }
      )

      store.storeData(response.data.data?.map((it) => it.Campaign))
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  @action loadTopKeywordsList = async (accountId: ID, datePair: Date[]) => {
    const { keywordsStore, campaignStore } =
      this.dashboardStore.campaignInsightsStore.topKeywordsStore

    try {
      campaignStore.isLoading = true
      campaignStore.error = null

      keywordsStore.isLoading = true
      keywordsStore.error = null

      const response = await accountCampaignService.getTopKeywordsReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
        }
      )

      const keywords = response.data?.data ?? []

      campaignStore.storeData(extractCampaigns(keywords))
      keywordsStore.storeData(keywords)
    } catch (error) {
      keywordsStore.error = "Unexpected error"
      campaignStore.error = "Unexpected error"
    } finally {
      keywordsStore.isLoading = false
      campaignStore.isLoading = false
    }
    return keywordsStore.error
  }

  @action loadRevenueProductsList = async (
    accountId: ID | null = null,
    datePair: Date[],
    campaigns: ID[]
  ) => {
    const store =
      this.dashboardStore.campaignInsightsStore.revenueProductsStore
        .productsStore

    try {
      store.isLoading = true
      store.error = null

      if (accountId == null) throw new Error("accountId is required")

      if (!campaigns.length) {
        store.storeData([])
        return store.error
      }

      const response = await accountCampaignService.getPMaxProductReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
          campaignIds: campaigns.map((it) => Number(it)),
        }
      )

      store.storeData(response.data?.data ?? [])
    } catch (error) {
      store.error = "Unexpected error"
      store.storeData([])
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  @action loadPMaxCampaigns = async (
    accountId: ID | null = null,
    datePair: Date[]
  ) => {
    const { campaignStore } =
      this.dashboardStore.campaignInsightsStore.revenueProductsStore

    try {
      campaignStore.isLoading = true
      campaignStore.error = null

      if (accountId == null) throw new Error("accountId is required")

      const response = await accountCampaignService.getPMaxProductReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
        }
      )

      const products = response.data.data ?? []

      campaignStore.storeData(extractCampaignIds(products))
    } catch (error) {
      campaignStore.error = "Unexpected error"
      campaignStore.storeData([])
    } finally {
      campaignStore.isLoading = false
    }
    return campaignStore.error
  }

  @action loadTopQueriesList = async (
    accountId: ID | null,
    datePair: Date[],
    campaigns: ID[]
  ) => {
    const { queriesStore } =
      this.dashboardStore.campaignInsightsStore.topQueriesStore

    try {
      queriesStore.isLoading = true
      queriesStore.error = null

      if (accountId == null) throw new Error("accountId is required")

      if (!campaigns.length) {
        queriesStore.storeData([])
        return queriesStore.error
      }

      const response = await accountCampaignService.getSearchQueriesReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
          campaignIds: campaigns.map((it) => Number(it)),
        }
      )

      const queries = response.data?.data ?? []

      queriesStore.storeData(queries)
    } catch (error) {
      queriesStore.error = "Unexpected error"
    } finally {
      queriesStore.isLoading = false
    }
    return queriesStore.error
  }

  @action loadQueriesCampaigns = async (
    accountId: ID | null,
    datePair: Date[]
  ) => {
    const { campaignStore } =
      this.dashboardStore.campaignInsightsStore.topQueriesStore

    try {
      campaignStore.isLoading = true
      campaignStore.error = null

      if (accountId == null) {
        campaignStore.storeData([])
        return null
      }

      const response = await accountCampaignService.getSearchQueriesReports(
        accountId,
        {
          ...datePairToPeriod(datePair, YYYY_MM_DD_DATE_FORMAT),
        }
      )

      const queries = response.data?.data ?? []

      campaignStore.storeData(extractCampaignIds(queries))
    } catch (error) {
      campaignStore.error = "Unexpected error"
    } finally {
      campaignStore.isLoading = false
    }
    return campaignStore.error
  }
}

export default AccountDashboardController

const extractCampaigns = (keywords: KeywordReportType[]) => {
  const uniqueCampaigns = new Map<number, BaseCampaignReportType>()
  keywords.forEach((it) => {
    if (!uniqueCampaigns.has(it.CampaignId))
      uniqueCampaigns.set(it.CampaignId, {
        Id: it.CampaignId,
        Name: it.CampaignName,
      })
  })
  return [...uniqueCampaigns.values()]
}

const extractCampaignIds = (
  list: (PMaxProductReportType | QueryReportType)[]
) => {
  const campaignsSet = new Set(list.flatMap((it) => it.CampaignIds ?? []))
  return [...campaignsSet.values()].map((id) => id.toString())
}
