import React from "react"
import { observer } from "mobx-react-lite"

import { ClosableRow } from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import TypedTip from "@components/ui/WaterfallTable/RowTip"
import { campaignEntityDescription } from "@framework/constants/manageCampaign"
import { KeywordReportType } from "@framework/types/account"
import { MapperType } from "@components/ui/WaterfallTable/types"

interface KeywordRowProps {
  mapper: MapperType[]
  data: KeywordReportType
  className?: string
  editable?: boolean
}

const KeywordRow: React.FC<KeywordRowProps> = observer(
  ({ mapper, data, editable = true, className }) => (
    <ClosableRow
      id={data.Id}
      className={className}
      component={
        <TypedRow
          label={data.Criteria ?? "-"}
          data={{
            ...data,
            KeywordId: data.Id,
            type: "keyword",
            editable: editable && data.Status !== "REMOVED",
          }}
          leftTip={<TypedTip data={campaignEntityDescription.keywords} />}
          mapper={mapper}
        />
      }
    />
  )
)

export default KeywordRow
