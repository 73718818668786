/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react"
import clsx from "clsx"
import { FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"
import * as Yup from "yup"

import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import { ClientInformationFormType } from "@framework/types/clientProfile"
import { urlRegEx } from "@components/utils/validators"
import Information from "./components/Information/Information"

import styles from "./ProfileInformation.module.scss"

export const clientInfoValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  street: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  postal: Yup.number(),
  website: Yup.string().matches(urlRegEx, "Enter correct url!"),
  industry: Yup.string(),
})

export type ProfileInformationProps = {
  className?: string
}

const ProfileInformation: React.FC<ProfileInformationProps> = observer(
  ({ className }) => {
    const { clientProfileStore, clientStore } = useStore()

    const {
      isEdit,
      clientInfo,
      clientUpdateLoading,
      setIsEdit,
      setIsValid,
      submitFormCallback,
      setSubmitCallback,
      updateClient,
    } = clientProfileStore

    const { clientId, loading } = clientStore

    const handleSubmit = useCallback(
      (values: ClientInformationFormType) => {
        if (clientId) {
          updateClient(clientId, values)
        }
      },
      [clientId, updateClient]
    )

    const formik = useFormik<ClientInformationFormType>({
      initialValues: clientInfo,
      validateOnBlur: true,
      validationSchema: clientInfoValidationSchema,
      onSubmit: handleSubmit,
    })

    const isTouched = useMemo(
      () => !!Object.keys(formik.touched).length,
      [formik.touched]
    )

    useEffect(() => {
      if (!clientUpdateLoading && submitFormCallback) {
        setIsEdit(false)
      }
    }, [clientUpdateLoading])

    useEffect(() => {
      if (isEdit) formik.resetForm({ values: clientInfo })
    }, [isEdit])

    useEffect(() => {
      if (isTouched) {
        setSubmitCallback(formik.submitForm)
      }
    }, [formik.touched])

    useEffect(() => {
      setIsValid(formik.isValid)
    }, [formik.isValid, setIsValid])

    useEffect(() => () => setIsEdit(false), [setIsEdit])

    return (
      <FormikProvider value={formik}>
        <div className={clsx(styles.root, className)}>
          <Loader isLoading={loading || clientUpdateLoading} />
          <Information />
        </div>
      </FormikProvider>
    )
  }
)

export default ProfileInformation
