import { useCallback, useState } from "react"

export const useToggle = (
  initValue: boolean = false
): {
  isOpened: boolean
  setOpened: (value: boolean) => void
  handleToggle: () => unknown
} => {
  const [isOpened, setOpened] = useState(initValue)

  const handleToggle = useCallback(() => {
    setOpened((state) => !state)
  }, [])

  return { isOpened, setOpened, handleToggle }
}
export default useToggle
