import React from "react"
import { useHistory } from "react-router-dom"

import { observer } from "mobx-react-lite"
import Divider from "@components/ui/Divider/Divider"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { NavOption } from "@components/ui/Tabs/types"
import Tabs from "@components/ui/Tabs/Tabs"

import styles from "./SidebarMenu.module.scss"

export const personalSettingsOptions: NavOption[] = [
  { label: "Summary", value: "dashboard", icon: "dashboard" },
  { label: "General", value: "general", icon: "lightbulb" },
  { label: "Winners", value: "winners", icon: "vip-crown" },
]

export const companySettingsOptions: NavOption[] = [
  { label: "Default Report", value: "report/report-1", icon: "file-list" },
  { label: "Default Report", value: "report/report-2", icon: "file-list" },
  { label: "Default Report", value: "report/report-3", icon: "file-list" },
]

export const otherOptions: NavOption[] = [
  { label: "Created Report", value: "report/new", icon: "bill-line" },
]

interface SidebarMenuProps {
  activeRoute?: NavOption
}

export const SidebarMenu: React.FC<SidebarMenuProps> = observer(
  ({ activeRoute }) => {
    const history = useHistory()
    const {
      accountStore: { accountId },
      authStore: { user },
    } = useStore()

    const handleMenu = ({ value }: NavOption) => {
      if (accountId) history.replace(`/meta-ads/${accountId}/${value}`)
    }

    return (
      <Stack gutter="0" align="stretch">
        <Stack gutter="0">
          <Typography type="h3" className={styles.menuLabel}>
            Overview
          </Typography>
          <Tabs
            direction="vertical"
            options={personalSettingsOptions}
            currentValue={activeRoute?.value}
            onChange={handleMenu}
            fullWidth
          />
        </Stack>

        <Divider noLine />

        <Stack gutter="0">
          <Typography type="h3" className={styles.menuLabel}>
            Reports
          </Typography>
          <Tabs
            direction="vertical"
            options={companySettingsOptions}
            currentValue={activeRoute?.value}
            onChange={handleMenu}
            fullWidth
          />
        </Stack>

        <Divider />

        <Stack gutter="0">
          <Tabs
            direction="vertical"
            options={otherOptions}
            currentValue={activeRoute?.value}
            onChange={handleMenu}
            fullWidth
          />
        </Stack>
      </Stack>
    )
  }
)

export default SidebarMenu
