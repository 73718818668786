import React from "react"
import { observer, useLocalStore } from "mobx-react-lite"

import Container from "@root/layouts/Container/Container"
import PrivateLayout from "@root/layouts/PrivateLayout/PrivateLayout"
import { useStore } from "@store/index"
import OpportunitiesStore from "@store/opportunities/opportunities"
import OpportunitiesController from "@store/opportunities/demo-opportunities.controller"
import {
  ControllerContext,
  OpportunitiesStoreProvider,
} from "@store/opportunities/provider"
import DemoMultiForm from "./DemoMultiFormV2"
import DemoFormManager, { DemoMultiformContext } from "./tasks/FormManager"

import styles from "./index.module.scss"

const IndustryAnalysisDemo: React.FC = observer(() => {
  const { manageProfileStore, accountStore, createdCampaign } = useStore()

  const opportunitiesStore = useLocalStore(
    () =>
      new OpportunitiesStore({
        defaultSortBy: { value: "avgSearchVolume", direction: false },
      })
  )

  const demoManager = useLocalStore(
    () =>
      new DemoFormManager({
        accountStore,
        manageProfileStore,
        opportunitiesStore,
      })
  )

  const opportunitiesController: ControllerContext = useLocalStore(() => ({
    opportunities: new OpportunitiesController({
      createdCampaign,
      opportunitiesStore,
      demoManager,
    }),
  }))

  return (
    <DemoMultiformContext.Provider value={demoManager}>
      <OpportunitiesStoreProvider
        store={opportunitiesStore}
        controller={opportunitiesController}
      >
        <PrivateLayout
          scope="channel"
          disabledHeader
          disableNavigation
          disableProfile
        >
          <Container className={styles.container}>
            <DemoMultiForm />
          </Container>
        </PrivateLayout>
      </OpportunitiesStoreProvider>
    </DemoMultiformContext.Provider>
  )
})

export default IndustryAnalysisDemo
