/* eslint-disable no-underscore-dangle */
import capitalize from "lodash/capitalize"
import { observable, action, computed } from "mobx"

import userService from "@services/user.service"
import { AUTH_TOKEN_KEY } from "@framework/constants/auth"
import { ShopifyLoginParams } from "@framework/types/auth"
import authService from "@services/auth.service"
import { sha256 } from "@services/utils"
import { PreSignupEntity } from "@framework/types/user"
import RootStore from "../RootStore"

const DEFAULT_ERROR_MESSAGE = "Unexpected error"

export class AuthStore {
  @observable root: RootStore

  @observable isTokenChecked: boolean = false

  @observable private _token: string | null =
    localStorage.getItem(AUTH_TOKEN_KEY)

  @observable isLoading: boolean = false

  @observable error: string | null = null

  constructor(root: RootStore) {
    this.root = root
  }

  get token() {
    return this._token
  }

  set token(newToken: string | null) {
    if (newToken) localStorage.setItem(AUTH_TOKEN_KEY, newToken)
    else localStorage.removeItem(AUTH_TOKEN_KEY)
    this._token = newToken
  }

  @computed get user() {
    return this.root.userStore.data
  }

  @computed get isAdmin() {
    return Boolean(this.user?.isAdmin)
  }

  @computed get isAuthorized() {
    return this.user != null && this.isTokenChecked
  }

  @computed get fullName() {
    if (!this.user?.Name) return "No Name"
    const { Name, Surname } = this.user
    return `${Name} ${Surname}`
  }

  @action login = async (login: string, pass: string) => {
    this.isLoading = true
    this.error = null
    try {
      const passHash = await sha256(pass)
      const response = await authService.signIn(login, passHash)

      this.root.userStore.setUser(response.data.data)
      this.token = response.data.data.APIKey
    } catch (error: any) {
      this.error = error.response?.data?.error ?? DEFAULT_ERROR_MESSAGE
    } finally {
      this.isLoading = false
    }
    this.isTokenChecked = true
    return this.error
  }

  @action loginWithShopify = async (
    email: string,
    credentials: ShopifyLoginParams
  ) => {
    this.isLoading = true
    this.signUpShopifyError = null
    try {
      const propertyId = (await authService.getProductFeerAccount()).data.data

      const form = {
        ...credentials,
        email,
        property_id: propertyId,
      }
      await authService.signUpShopify(form)

      return { propertyId, error: null }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        this.signUpShopifyError = capitalize(error.response.data.error)
      } else {
        this.signUpShopifyError = DEFAULT_ERROR_MESSAGE
      }
      this.logout()
      return { propertyId: null, error: this.signUpShopifyError }
    } finally {
      this.isLoading = false
    }
  }

  @action checkToken = async () => {
    this.isLoading = true
    try {
      if (this.token) {
        const error = await this.root.userStore.load()
        if (error) throw new Error(error)
      }
    } catch (error) {
      this.logout()
    } finally {
      this.isLoading = false
      this.isTokenChecked = true
    }
  }

  @observable isSignUpShopifyLoading: boolean = false

  @observable signUpShopifyError: string | null = null

  @action signUpShopify = async (
    email: string,
    credentials: ShopifyLoginParams
  ) => {
    this.isSignUpShopifyLoading = true
    this.signUpShopifyError = null
    try {
      const form = {
        ...credentials,
        email,
      }
      await authService.signUpShopify(form)
    } catch (error: any) {
      if (error?.response?.data?.error) {
        this.signUpShopifyError = capitalize(error.response.data.error)
      } else {
        this.signUpShopifyError = DEFAULT_ERROR_MESSAGE
      }
    } finally {
      this.isSignUpShopifyLoading = true
    }
    return this.signUpShopifyError
  }

  @observable isPreSignupInProgress: boolean = false

  @observable preSignUpError: string | null = null

  @action preSignUp = async (form: PreSignupEntity) => {
    try {
      this.isPreSignupInProgress = true
      this.preSignUpError = null

      await userService.preRegister(form)
    } catch (error: any) {
      this.preSignUpError = DEFAULT_ERROR_MESSAGE
    } finally {
      this.isPreSignupInProgress = true
    }
    return this.preSignUpError
  }

  @action logout = () => {
    this.root.userStore.setUser(null)
    this.token = null
  }
}

export default AuthStore
