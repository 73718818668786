import React from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"

import clsx from "clsx"
import styles from "./DefaultLayout.module.scss"

export interface DefaultLayoutProps {
  className?: string
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = observer(
  ({ className, children }) => (
    <div className={styles.root} id="scroll-container">
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography type="h2" color="primary">
            ADORO
          </Typography>
        </div>

        <div className={styles.body}>
          <div className={clsx(styles.bodyContainer, className)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
)

export default DefaultLayout
