/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PropsWithChildren, forwardRef } from "react"
import clsx from "clsx"
import styles from "./Box.module.scss"

interface BoxProps {
  elevation?: 0 | 1 | 2 | 3
  /**
   * @deprecated use className instead
   */
  padding?: number | string
  /**
   * @deprecated use className instead
   */
  width?: number | string
  /**
   * @deprecated use className instead
   */
  height?: number | string
  className?: string
  onClick?: React.MouseEventHandler
}

const Box = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(
  (props, ref) => {
    const {
      children,
      padding,
      width,
      height,
      className,
      elevation = 1,
      onClick,
    } = props

    const boxStyle = {
      "--box-padding": transformCssVar(padding, "10px"),
      "--box-width": transformCssVar(width, "auto"),
      "--box-height": transformCssVar(height, "auto"),
    } as React.CSSProperties

    return (
      <div
        className={clsx(styles.root, styles[`shadow-L${elevation}`], className)}
        style={boxStyle}
        ref={ref}
        onClick={onClick}
      >
        {children}
      </div>
    )
  }
)

const transformCssVar = (
  padding: number | string | undefined = undefined,
  def: string | undefined = undefined
) => {
  if (!padding) return def
  if (typeof padding === "number") return `${padding}px`
  return padding
}

export default Box
