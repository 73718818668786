import React, { useEffect, useState } from "react"

import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import IconButton from "@components/ui/Button/IconButton"
import { PreviewMode } from "../types"

import styles from "./Header.module.scss"

export interface PreviewHeaderProps {
  title: string
  itemNumber?: number
  mode: PreviewMode
  onModeChange: (type: PreviewMode) => void
  onFrameChange: (dir?: "left" | "right") => void
}

const Header: React.FC<PreviewHeaderProps> = ({
  title,
  itemNumber = 0,
  mode = "desktop",
  onModeChange,
  onFrameChange,
}) => {
  const [isPlaying, setPlay] = useState(true)

  const togglePlay = () => setPlay((val) => !val)

  const handleSwipe = (dir: "left" | "right") => () => {
    setPlay(false)
    onFrameChange(dir)
  }

  useEffect(() => {
    if (!isPlaying) return undefined
    const interval = setInterval(() => {
      onFrameChange("right")
    }, 7_000)
    return () => {
      clearInterval(interval)
    }
  }, [itemNumber, isPlaying, onFrameChange])

  return (
    <div className={styles.root}>
      <Typography type="h2">{title}</Typography>
      <div className={styles.control}>
        <div className={styles.section}>
          <IconButton
            className={styles.arrow}
            size="small"
            variant="ghost"
            color="secondary"
            title="Previous"
            onClick={handleSwipe("left")}
          >
            <Icon name="arrow-right" rotateYAngle={180} />
          </IconButton>
          <IconButton
            className={styles.arrow}
            size="small"
            variant="ghost"
            color="secondary"
            title="Next"
            onClick={handleSwipe("right")}
          >
            <Icon name="arrow-right" />
          </IconButton>
          <IconButton
            size="small"
            variant="ghost"
            color="secondary"
            title={isPlaying ? "Pause" : "Play"}
            onClick={togglePlay}
          >
            <Icon name={isPlaying ? "pause" : "play"} />
          </IconButton>
        </div>
        <div className={styles.section}>
          <IconButton
            size="small"
            variant="ghost"
            color={mode === "desktop" ? "primary" : "secondary"}
            title="Desktop"
            onClick={() => onModeChange("desktop")}
          >
            <Icon name="display" />
          </IconButton>
          <IconButton
            size="small"
            variant="ghost"
            color={mode === "mobile" ? "primary" : "secondary"}
            title="Mobile"
            onClick={() => onModeChange("mobile")}
          >
            <Icon name="mobile" />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default Header
