/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { Formik } from "formik"

import Box from "@components/ui/Box/Box"
import DatePicker from "@components/ui/DatePicker/DatePicker"
import Label from "@components/ui/Label/Label"
import Loader from "@components/ui/Loader/Loader"
import Pagination from "@components/ui/Pagination"
import Typography from "@components/ui/Typography/Typography"
import {
  YEAR_MONTH_KEY_FORMAT,
  MM_DD_YYYY_DATE_FORMAT,
} from "@framework/constants/moment-format"
import { useStore } from "@store/index"
import { initArrayByLength } from "@components/utils/numberUtils"
import { MonthlyAmountMap } from "@store/checkbook/checkbook"
import { mbNoData, moneyFormatter } from "@services/utils"
import { MapperOption } from "@components/forms/CheckbookForm/types"
import useActualMonthlyAdSpend from "./useActualMonthlyAdSpend"
import EditableCheckbookTable from "../CheckbookTable/EditableCheckbookTable"
import useCampaignDate from "./useCampaignDate"
import FormController from "../FormController"
import useCheckbookForm from "../useCheckbookForm"
import { getCheckbookSequence } from "../utils"

import styles from "./PlannedAdSpend.module.scss"

type PlannedAdSpendProps = {
  className?: string
  isEdit?: boolean
}

const PlannedAdSpend: React.FC<PlannedAdSpendProps> = observer(
  ({ className }) => {
    const store = useStore()
    const { manageProfileStore, checkbookStore, accountStore } = store
    const { isEdit, setIsEdit } = manageProfileStore
    const {
      currentCampaignDate,
      contractAdSpend,
      checkbookLoading,
      checkbookData,
      initialMonthlyAdSpend,
      timeline,
      setPlanedAdSpend,
      loadCheckbook,
    } = checkbookStore

    const { accountId } = accountStore

    const { campaignDate, minCampaignDate, maxCampaignDate, setCampaignDate } =
      useCampaignDate()

    const {
      years,
      activeYear,
      activeYearIndex,
      setActiveYearIndex,
      editablePeriod,
    } = timeline

    const { isActualMonthlyAmountLoading, actualMonthlyAmount } =
      useActualMonthlyAdSpend(activeYear)

    const initialValues = useMemo(() => {
      if (!checkbookData) return {}
      return getInitialCheckbook(
        getCheckbookSequence(editablePeriod),
        checkbookData,
        initialMonthlyAdSpend
      )
    }, [checkbookData, editablePeriod, initialMonthlyAdSpend])

    const plannedData = useMemo(() => {
      if (!checkbookData) return {}
      return { ...initialValues, ...checkbookData }
    }, [checkbookData, initialValues])

    const mapper = useMemo((): MapperOption[] => {
      if (!activeYear) return []
      const startDate = moment.utc().year(activeYear).startOf("year")
      return initArrayByLength(12).map((index) => {
        const monthStartDate = startDate.clone().add(index, "month")
        return {
          name: monthStartDate.format(YEAR_MONTH_KEY_FORMAT),
          index,
        }
      })
    }, [activeYear])

    const formProps = useCheckbookForm(initialValues, editablePeriod)

    const data = {
      planned: plannedData,
      actual: actualMonthlyAmount,
    }

    useEffect(() => {
      if (plannedData) {
        setPlanedAdSpend(plannedData)
      }
    }, [plannedData])

    useEffect(() => {
      if (accountId && !isEdit) {
        loadCheckbook(accountId)
      }
    }, [isEdit])

    useEffect(() => {
      setCampaignDate()
      return () => setIsEdit(false)
    }, [])

    useEffect(() => {
      if (!isEdit && currentCampaignDate) {
        setCampaignDate(currentCampaignDate)
      }
    }, [isEdit, currentCampaignDate])

    const idLoading = checkbookLoading || isActualMonthlyAmountLoading

    return (
      <Formik {...formProps}>
        <Box className={clsx(styles.root, className)}>
          <div className={styles.header}>
            <Label label="Contract Yearly Ad Spend">
              <Typography type="h2" color="primary" bold>
                {mbNoData(contractAdSpend.toString(), moneyFormatter)}
              </Typography>
            </Label>
            <Label label="Campaign Launch Date">
              {currentCampaignDate ? (
                isEdit ? (
                  <DatePicker
                    defValue={campaignDate}
                    onChange={setCampaignDate}
                    minDate={minCampaignDate}
                    maxDate={maxCampaignDate}
                  />
                ) : (
                  <Typography type="h4" color="dark" className={styles.date}>
                    {moment(campaignDate).format(MM_DD_YYYY_DATE_FORMAT)}
                  </Typography>
                )
              ) : null}
            </Label>
          </div>
          <Loader isLoading={idLoading} />
          <Typography type="h3" className={styles.title}>
            Planned Monthly Ad Spend Breakdown
          </Typography>

          <EditableCheckbookTable mapper={mapper} data={data} isEdit={isEdit} />

          <FormController />

          {years.length > 0 && (
            <div className={styles.control}>
              <Pagination
                forcePage={activeYearIndex}
                pageCount={years.length}
                onPageChange={({ selected }) => setActiveYearIndex(selected)}
                pageLabelBuilder={(page) => years?.[page - 1] ?? "-"}
              />
            </div>
          )}
        </Box>
      </Formik>
    )
  }
)

export const getInitialCheckbook = (
  editableSequence: string[],
  checkbook: MonthlyAmountMap,
  defaultAmount: number = 0
) =>
  editableSequence.reduce<MonthlyAmountMap>((acc, monthKey) => {
    const value = checkbook[monthKey] ?? defaultAmount.toString()
    acc[monthKey] = value
    return acc
  }, {})

export default PlannedAdSpend
