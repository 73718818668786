import { nanoid } from "nanoid"
import without from "lodash/without"
import uniq from "lodash/uniq"

import { initArray, initArrayByLength, randomNumber } from "@utils/numberUtils"
import { AdCopyDetails } from "@framework/types/adCopy"
import { AdCopyPreview } from "./types"

export const makeExcludingGenerator = (collection: string[]) => {
  let state: string[] = [...collection]

  return (except: string[] = []) => {
    if (state.length <= 0) state = [...collection]

    if (state.length === 1) {
      const res = state[0]

      state = [...collection]

      if (!except.includes(res)) return res
    }

    const leftovers = without(state, ...except)

    const randomIndex = randomNumber(0, 100) % leftovers.length
    const res = leftovers[randomIndex]

    state = without(state, res)

    return res
  }
}

export const shuffleSliceMap = (
  collection: string[],
  rows: number,
  cols: number
): string[][] => {
  const getNextRandom = makeExcludingGenerator(collection)
  return initArray(rows, () => getNextRandom()).map((it) =>
    initArrayByLength(cols - 1).reduce(
      (acc) => {
        acc.push(getNextRandom(acc))
        return acc
      },
      [it]
    )
  )
}

export const makeAdCopyPreviews = (
  website: string,
  headlines: string[],
  descriptions: string[],
  total: number = descriptions.length
): AdCopyPreview[] => {
  if (total < 1) throw new Error("Total should be more 1 and more")

  const adCopyDescriptions = shuffleSliceMap(descriptions, total, 2)
  const adCopyHeadlines = shuffleSliceMap(headlines, total, 3)

  const res = initArray(total, (idx) => ({
    id: nanoid(),
    website,
    headlines: adCopyHeadlines[idx],
    descriptions: adCopyDescriptions[idx],
  }))

  return res
}

export const adCopyPreviewsToDetails = (
  previews: AdCopyPreview[]
): Partial<AdCopyDetails> => {
  const headlines = uniq(previews.flatMap((it) => it.headlines))
  const descriptions = uniq(previews.flatMap((it) => it.descriptions))

  return {
    finalUrl: previews[0].website,
    mobileUrl: previews[0].website,
    ...Object.fromEntries(
      headlines.slice(0, 15).map((it, idx) => [`headline${idx + 1}`, it])
    ),
    ...Object.fromEntries(
      descriptions.slice(0, 4).map((it, idx) => [`description${idx + 1}`, it])
    ),
  }
}
