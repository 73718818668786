/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"

import WaterfallTable, {
  ClosableRow,
} from "@components/ui/WaterfallTable/WaterfallTable"
import TypedRow from "@components/ui/WaterfallTable/TypedRow"
import { moneyFormatter, precisePercentageFormatter } from "@services/utils"
import Button from "@components/ui/Button/Button"
import { transformConditionsList } from "@store/account-rules/dataTransformer"
import { Option } from "@framework/types/types"
import { MM_DD_YYYY_DATE_FORMAT } from "@framework/constants/moment-format"
import { MapperType } from "@components/ui/WaterfallTable/types"
import { Condition, Rule } from "@framework/types/manageCampaign"
import { RuleActions } from "./components/RuleActions/RuleActions"
import { RuleStatusSwitch } from "./components/ResultStatus/RuleStatus"
import {
  actionOptions,
  campaignTypeOptions,
  conditionFrequencyOptions,
  operatorOptions,
  ruleTypeOptions,
} from "../EditRule/components/BudgetTypeRoleForm/options"
import ConditionRow from "./components/ConditionsTable/ConditionRow"

import styles from "./RulesTable.module.scss"

const optionsToMapper = (options: Option[]) =>
  options.reduce<any>((mapper, ruleOption) => {
    mapper[ruleOption.value] = ruleOption.label
    return mapper
  }, {})

const renderWithMapper = (mapper: any) => (value: string) =>
  value !== undefined ? mapper[value] : ""

const renderDate = (value: Date) => {
  if (value === undefined) return ""
  const date = moment(value).format(MM_DD_YYYY_DATE_FORMAT)
  if (date === "01/01/0001") return ""
  return date
}

export const TableButton: React.FC<any> = ({ children, onClick }) => (
  <Button
    onClick={onClick}
    fit
    variant="ghost"
    color="secondary"
    className={styles.linkButton}
  >
    {children}
  </Button>
)

export const Budget: React.FC<any> = ({ value }) => (
  <TableButton>{`${moneyFormatter(value.amount)}/${value.period}`}</TableButton>
)

export const mapper: MapperType[] = [
  {
    name: "trigger",
    label: "Rule type",
    renderCallback: renderWithMapper(optionsToMapper(ruleTypeOptions)),
  },
  {
    name: "start_date",
    label: "Start Date",
    renderCallback: (value: Date, data: Condition) =>
      data.trigger === "DATE" ? renderDate(value) : "",
  },
  {
    name: "end_date",
    label: "End Date",
    renderCallback: (value: Date, data: Condition) =>
      data.trigger === "DATE" ? renderDate(value) : "",
  },
  {
    name: "operator",
    label: "is (Operator)",
    renderCallback: renderWithMapper(optionsToMapper(operatorOptions)),
  },
  {
    name: "value",
    label: "Value %",
    renderCallback: (value?: number) =>
      (value || value === 0) && precisePercentageFormatter(value.toString(), 0),
  },
  {
    name: "action",
    label: "Then (Action)",
    renderCallback: renderWithMapper(optionsToMapper(actionOptions)),
  },
  {
    name: "applied_area",
    label: "Applied to (Campaign Type) ",
    widthBasis: "2 2",
    renderCallback: renderWithMapper(optionsToMapper(campaignTypeOptions)),
  },
  {
    name: "frequency",
    label: "Frequency",
    renderCallback: (value: string, data) =>
      data.trigger === "DATE"
        ? ""
        : renderWithMapper(optionsToMapper(conditionFrequencyOptions))(value),
  },
  {
    name: "active",
    label: "Status",
    renderCallback: (value, data) =>
      value !== undefined && (
        <RuleStatusSwitch checked={value} ruleId={data.id} />
      ),
  },
  {
    name: "name",
    label: "Action",
    widthBasis: ".5 .5",
    renderCallback: (value: number, data) =>
      value !== undefined && <RuleActions ruleId={data.id} />,
  },
]

type RulesTableProps = {
  rulesList: Rule[]
  className?: string
}

const RulesTable: React.FC<RulesTableProps> = observer(
  ({ className, rulesList }) => (
    <div className={clsx(styles.root, className)}>
      <WaterfallTable
        mapper={mapper}
        headerClassName={styles.header}
        label="Rule name"
      >
        {rulesList.map((props, index) => (
          <ClosableRow
            id={index}
            component={
              <TypedRow
                label={props.name}
                data={props}
                containerClassName={styles.fatTable}
                mapper={mapper}
              />
            }
            key={props.id}
          >
            {transformConditionsList(props.Conditions)?.map((data, idx) => (
              <ConditionRow data={data} label="" index={idx} mapper={mapper} />
            )) ?? null}
          </ClosableRow>
        ))}
      </WaterfallTable>
    </div>
  )
)

export default RulesTable
