import React, { FC } from "react"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import clsx from "clsx"
import RadioCheckedIcon from "@root/assets/svgIcons/RadioCheckedIcon"
import { AudiencesEntity } from "@framework/types/manageCampaign"
import { observer } from "mobx-react-lite"
import styles from "./AudienceSignal.module.scss"

type AudienceSignalItemProps = {
  audience: AudiencesEntity
  onSelectAudience: (id: number) => void
  selectedAudienceId: number | null
}

const checkAudienceSelection = (
  audience: AudiencesEntity,
  selectedAudienceId: number | null
): boolean => {
  if (audience.isSelected && !selectedAudienceId) {
    return true
  }
  if (!audience.isSelected && selectedAudienceId === audience.id) {
    return true
  }
  return audience.isSelected && selectedAudienceId === audience.id
}

const AudienceSignalItem: FC<AudienceSignalItemProps> = observer(
  ({ selectedAudienceId, audience, onSelectAudience }) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={clsx(styles.audienceSignalItem, {
        [styles.selected]: checkAudienceSelection(audience, selectedAudienceId),
      })}
      onClick={() => {
        onSelectAudience(audience.id)
      }}
    >
      <div className={styles.audienceSignalTitle}>
        <Typography type="h3">{audience.name} </Typography>
        <span> ID: {audience.id}</span>
      </div>
      <div className={styles.audienceSignalItemInfo}>
        <div>
          {audience.dimension?.audienceSegment?.userLists?.map((i) => (
            <div className={styles.textIcon}>
              <Icon name="attachment" />
              <Typography type="subtitle" color="gray">
                {i.name}
              </Typography>
            </div>
          ))}
        </div>
        <div>
          <Typography type="subtitle" color="gray">
            {audience.status === "ENABLED" ? "Eligible" : audience.status}
          </Typography>
          {/* <Typography type="subtitle" color="gray"> */}
          {/*  1/2 best practices for sales */}
          {/* </Typography> */}
        </div>
      </div>
      {checkAudienceSelection(audience, selectedAudienceId) && (
        <RadioCheckedIcon className={styles.audienceSignalItemIcon} />
      )}
    </div>
  )
)

export default AudienceSignalItem
