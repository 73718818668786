import React from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { Legend } from "@components/ui/Charts/Legends/LegendList"
import { useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import Tabs from "@components/ui/Tabs/Tabs"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { getPlatformOption } from "@framework/constants/report"

import styles from "./Header.module.sass"

export const ChartHeader = observer(() => {
  const {
    reports: { mainFilter, filter },
  } = useStore()

  const currLegends = mainFilter.activeFilter.platforms
  const prevLegends = mainFilter.isComparisonEnabled ? currLegends : null
  const prevPeriodLabel = mainFilter.activeFilter.prevPeriod.label

  return (
    <Templates.Header
      offset="none"
      left={
        <Stack direction="row">
          {currLegends.map((value) => {
            const option = getPlatformOption(value)
            return (
              <Legend
                label={option.label}
                color={option.color}
                key={option.value}
              />
            )
          })}
          {prevLegends?.map((value) => {
            const option = getPlatformOption(value)
            return (
              <Legend
                dashed
                label={`${option.label} (${prevPeriodLabel})`}
                color={option.color}
                key={option.value}
              />
            )
          })}
        </Stack>
      }
      right={
        <Tabs
          options={filter.periodicityOptions}
          currentValue={filter.activePeriodicity}
          onChange={({ value }) =>
            filter.setPeriodicity(value as PerformanceReportPeriodicity)
          }
        />
      }
    />
  )
})

export default ChartHeader
