import React from "react"
import { CellMeasurerProps } from "react-virtualized/dist/es/CellMeasurer"

export const RectAutoMeasurer: React.FC<CellMeasurerProps> = ({
  cache,
  parent,
  children,
  index = 0,
  rowIndex = index,
  columnIndex = 0,
}) => {
  const targetRef = React.useRef<HTMLDivElement>(null)
  const resizeObserver = React.useRef<ResizeObserver | null>(null)

  React.useEffect(() => {
    if (targetRef.current == null) return undefined

    const handleResize = (rect: DOMRectReadOnly | null) => {
      const height = cache.hasFixedHeight() ? 0 : rect?.height ?? 0
      const width = cache.hasFixedWidth() ? 0 : rect?.width ?? 0

      cache.set(rowIndex, columnIndex, width, height)

      if (rect != null && width === rect.width && height === rect.height) return

      parent.invalidateCellSizeAfterRender?.({ rowIndex, columnIndex })
      parent.recomputeGridSize?.({ rowIndex, columnIndex })
    }

    resizeObserver.current = new ResizeObserver((entries) => {
      if (entries.length) {
        handleResize(structuredClone(entries[0].contentRect))
      }
    })

    resizeObserver.current.observe(targetRef.current)

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect()
      }
    }
  }, [targetRef.current])

  const initRect = targetRef.current?.getBoundingClientRect()

  const isHeightSync =
    initRect == null ||
    cache.hasFixedHeight() ||
    cache.getHeight(rowIndex, columnIndex) === initRect.height

  const isWidthSync =
    initRect == null ||
    cache.hasFixedWidth() ||
    cache.getWidth(rowIndex, columnIndex) === initRect.width

  if (!isWidthSync || !isHeightSync) {
    const height = cache.hasFixedHeight() ? 0 : initRect?.height ?? 0
    const width = cache.hasFixedWidth() ? 0 : initRect?.width ?? 0

    cache.set(rowIndex, columnIndex, width, height)
  }

  return (
    <div
      ref={targetRef}
      style={{ minWidth: "fit-content", minHeight: "fit-content" }}
    >
      {children}
    </div>
  )
}

export default React.memo(RectAutoMeasurer)
