import { NavOption } from "./types"

export const findAlternateNavItem = (
  navItemsList: NavOption[],
  alter?: string
) => {
  if (!alter) return undefined
  return navItemsList.find((item) => alter === item.alter)
}

export const findDefaultNavItem = (
  navItemsList: NavOption[],
  pathname: string,
  alter?: string
) => {
  const foundItem =
    findAlternateNavItem(navItemsList, alter) ??
    navItemsList.find((item) => pathname.endsWith(item.value))

  return foundItem
}

export default null
