import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import config from "@root/config"
import CampaignSegmentation from "../CampaignSegmentation/CampaignSegmentation"
import MonthlyGoals from "../MonthlyGoals/MonthlyGoals"
import CampaignInsights from "../CampaignInsights/CampaignInsights"

import styles from "./CampaignPerformance.module.scss"

type CampaignPerformanceProps = {
  className?: string
}

const CampaignPerformance: React.FC<CampaignPerformanceProps> = observer(
  ({ className }) => (
    <div className={clsx(styles.root, className)}>
      <Typography type="h1" color="primary" weight="bold">
        Campaign Performance
      </Typography>
      <MonthlyGoals />
      <CampaignSegmentation />
      {!!config.ENABLE_ACCOUNT_CAMP_INSIGHTS && <CampaignInsights />}
    </div>
  )
)

export default CampaignPerformance
