import { useMemo } from "react"
import useQueryParams from "./useQueryParamsNew"
import { ShopifyLoginParams } from "../types/auth"

export const useValidShopifyCredentials = () => {
  const form = useQueryParams<ShopifyLoginParams>()

  return useMemo(() => {
    if (form != null && "token" in form && "shop_id" in form) {
      return {
        ...form,
        phone: form.phone != null ? form.phone.toString() : form.phone,
      }
    }
    return null
  }, [form])
}

export default useValidShopifyCredentials
