import { action, observable } from "mobx"

import { SortByType } from "@framework/types/types"

export class OrderController {
  @observable order: SortByType = {
    value: "none",
    direction: false,
  }

  @action sortBy = (name: string) => {
    const shouldOff = this.order.value === name && this.order.direction
    this.order = {
      value: shouldOff ? "none" : name,
      direction: this.order.value === name,
    }
    return this.order
  }
}

export default OrderController
