import { action, computed, observable } from "mobx"

import Loadable from "@framework/classes/Loadable"
import { UserData, UserEntity } from "@framework/types/user"
import userService, {
  RestorePasswordRequestPayload,
  UpdatePasswordRequestPayload,
} from "@services/user.service"
import RootStore from "../RootStore"

const DEFAULT_ERROR_MESSAGE = "Unexpected error"

export class User extends Loadable<UserData> {
  @observable root: RootStore

  @computed get userId() {
    return this.data?.ID ?? null
  }

  @computed get fullName() {
    if (!this.data?.Name) return "No Name"
    const { Name, Surname } = this.data
    return `${Name} ${Surname}`
  }

  constructor(root: RootStore) {
    super()
    this.root = root
  }

  @action load = async () => {
    this.isLoading = true
    this.error = null
    try {
      const { data: payload } = await userService.getUser()

      this.setUser(payload.data)
    } catch (error: any) {
      this.error = error.response?.data?.error ?? DEFAULT_ERROR_MESSAGE
    } finally {
      this.isLoading = false
    }
    return this.error
  }

  @action update = async (data: Partial<UserEntity>) => {
    this.isLoading = true
    this.error = null
    if (!this.userId) throw Error("User not ready")
    try {
      await userService.updateUser(this.userId, data)
      const response = await userService.getUser()
      this.setUser(response.data.data)
    } catch (error: any) {
      this.error = error.response?.data?.error ?? DEFAULT_ERROR_MESSAGE
    } finally {
      this.isLoading = false
    }
    return this.error
  }

  @action changePassword = async (data: UpdatePasswordRequestPayload) => {
    this.isLoading = true
    this.error = null
    if (!this.userId) throw Error("User not ready")
    try {
      await userService.changePassword(data)
      await this.root.authStore.logout()
    } catch (error: any) {
      this.error = error.response?.data?.error ?? DEFAULT_ERROR_MESSAGE
    } finally {
      this.isLoading = false
    }
    return this.error
  }

  @action restorePassword = async (data: RestorePasswordRequestPayload) => {
    this.isLoading = true
    this.error = null
    try {
      await userService.forgotPassword(data)
    } catch (error: any) {
      this.error = error.response?.data?.error ?? DEFAULT_ERROR_MESSAGE
    } finally {
      this.isLoading = false
    }
    return this.error
  }

  @action resetPassword = async (
    token: string,
    data: UpdatePasswordRequestPayload
  ) => {
    this.isLoading = true
    this.error = null
    try {
      await userService.resetPassword(token, data)
    } catch (error: any) {
      this.error = error.response?.data?.error ?? DEFAULT_ERROR_MESSAGE
    } finally {
      this.isLoading = false
    }

    return this.error
  }

  @action setUser = (user: UserData | null) => {
    this.data = user
  }
}

export default User
