import { action, computed, observable } from "mobx"
import { UserData, UserEntity } from "@framework/types/user"

export class ManagementStore {
  @observable user: Partial<UserData> | null = null

  @computed get permission() {
    return this.user?.permissions?.[0] ?? {}
  }

  @computed get isEdit() {
    return !!this.user?.ID
  }

  @computed get isUserInEdit() {
    return this.user != null
  }

  @action createUser = () => {
    this.user = {}
  }

  @action editUser = (user: any) => {
    this.user = user
  }

  @action dropUser = () => {
    this.user = null
  }
}

export default ManagementStore
