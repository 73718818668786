import React from "react"

import Icon from "@components/ui/Icon/Icon"
import BaseListItem, { ListItemProps } from "./ListItem"

export interface OptionProps extends ListItemProps {
  isSelected?: boolean
  disabled?: boolean
}

const SimpleOption: React.FC<OptionProps> = ({
  isSelected,
  children,
  ...rest
}) => (
  <BaseListItem
    {...rest}
    after={<Icon name={isSelected ? "done" : "none"} primary />}
  >
    {children}
  </BaseListItem>
)

export default SimpleOption
