import React from "react"

import Modal, { ModalProps } from "@components/ui/Modal/Modal"
import KeywordAnswerForm from "./KeywordAnswerForm"

import styles from "./KeywordAnswerFormModal.module.scss"

export const KeywordAnswerFormModal: React.FC<ModalProps> = ({ ...rest }) => (
  <Modal {...rest}>
    <div className={styles.root}>
      <KeywordAnswerForm onSubmit={rest.onClose} />
    </div>
  </Modal>
)

export default KeywordAnswerFormModal
