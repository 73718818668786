import { observer } from "mobx-react-lite"
import React from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { useStore } from "@store/index"

export interface PrivateRouteProps extends RouteProps {
  accessFlag?: boolean
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  accessFlag = true,
  ...props
}) => {
  const { authStore } = useStore()
  return authStore.isAuthorized && accessFlag ? (
    <Route {...props} />
  ) : (
    <Redirect to="/login" />
  )
}

export default observer(PrivateRoute)
