import React from "react"
import { observer } from "mobx-react-lite"

import { BaseCellProps } from "@components/ui/WaterfallTable/types"
import { AdCopyStatus, AdsReportType } from "@framework/types/account"
import { statusToLabel } from "@utils/stringUtils"

export interface AdStrategyProps extends BaseCellProps {
  data: AdsReportType
}

export const AdStatus: React.FC<AdStrategyProps> = observer(({ value }) => (
  <>{renderAdStatus(value)}</>
))

export default AdStatus

const renderAdStatus = (status: AdCopyStatus) => {
  if (status === "ELIGIBLE_MAY_SERVE") return "Eligible"
  if (status === "REVIEW_IN_PROGRESS") return "Under review"
  return statusToLabel(status)
}
