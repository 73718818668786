import {
  MetricNameType,
  MetricType,
  PerformanceReport,
  TimeSegmentedPerformanceReport,
} from "@framework/types/metrics"
import { NamedAdGroupSegmentedPerformanceReport } from "@framework/types/account"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import moment from "moment"
import { periodicityToScaleUnit } from "@components/ui/Charts/LineChart/config"
import { DEF_YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"
import { initArrayByLength, randomNumber } from "./numberUtils"

export const generateRandomLink = () =>
  "https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley"

export const generatePictureSrc = () =>
  `http://placekitten.com/g/${randomNumber(200, 300)}/${randomNumber(200, 300)}`

export const getPerformanceReportMock = (
  mapper: MetricNameType[],
  withTrend: boolean = true
): PerformanceReport =>
  Object.fromEntries(
    mapper.map<[MetricNameType, MetricType]>((name) => [
      name,
      {
        Value: randomNumber(0, 1_000),
        Change: withTrend ? Math.random() * 2 - 1 : undefined,
      },
    ])
  ) as PerformanceReport

export const getPerformanceReportSegmentedByAdGroupMock = (
  groups: number,
  metrics: MetricNameType[]
): NamedAdGroupSegmentedPerformanceReport[] =>
  initArrayByLength(groups).map((idx) => ({
    Name: `Brand ${idx}`,
    ...getPerformanceReportMock(metrics),
    AdGroups: initArrayByLength(3).map((idx) => ({
      Name: `Option ${idx}`,
      ...getPerformanceReportMock(metrics),
    })),
  }))

export const getPerformanceReportSegmentedByPeriodMock = (
  period: [Date, Date],
  periodicity: PerformanceReportPeriodicity,
  metrics: MetricNameType[]
): TimeSegmentedPerformanceReport[] => {
  const unit = periodicityToScaleUnit(periodicity)
  const endMoment = moment(period[1])
  const startMoment = moment(period[0]).startOf(unit)
  const points = endMoment.diff(startMoment, unit) || 1
  return initArrayByLength(points).map((n) => ({
    Period: startMoment.clone().add(n, unit).format(DEF_YYYY_MM_DD_DATE_FORMAT),
    Metrics: getPerformanceReportMock(metrics),
  }))
}
