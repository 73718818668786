import React from "react"

import Typography from "@components/ui/Typography/Typography"
import Trend from "@components/ui/Typography/Trend/Trend"
import { percentageFormatter } from "@services/utils"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import { MetricDirection } from "@framework/types/metrics"

import styles from "../KPIsWidget.module.scss"

export type DataBlockProps = {
  name: string
  amount?: number | string
  trend?: number
  trendFormatter?: (value: number) => MetricDirection
}

const DataBlock: React.FC<DataBlockProps> = ({
  name,
  amount = 0,
  trend = 0,
  trendFormatter = positiveTransformDirection,
}) => (
  <div className={styles.item} key={name}>
    <Typography type="subhead1" color="gray" upperCase>
      {name}
    </Typography>
    <Typography type="h2" color="dark" bold>
      {amount}
    </Typography>
    <Trend {...trendFormatter(trend)}>{percentageFormatter(trend)}</Trend>
  </div>
)

export default DataBlock
