import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom"

import { NavOption } from "@components/ui/Tabs/types"
import {
  companySettingsOptions,
  otherOptions,
  personalSettingsOptions,
} from "./components/SidebarMenu/SidebarMenu"
import SettingsMenu from "./SettingsMenu"
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy"

const menuOptions: NavOption[] = [
  ...personalSettingsOptions,
  ...companySettingsOptions,
  ...otherOptions,
]

export const SettingsRoot: React.FC = observer(() => {
  const location = useLocation()
  const history = useHistory()

  const activeRoute = useMemo(
    () =>
      menuOptions.find(
        ({ value }) =>
          !!matchPath(location.pathname, { path: `/settings/${value}` })
      ),
    [location.pathname]
  )

  const getNavHandler = (path: string) => () => {
    history.replace(`/settings/${path}`)
  }

  useEffect(() => {
    if (!activeRoute) getNavHandler(menuOptions[0].value)()
  }, [activeRoute])

  return (
    <Switch>
      <Route path="/settings/privacy" component={PrivacyPolicy} />
      <Route path="/settings/*" component={SettingsMenu} />
      <Route path="/settings/**">
        <Redirect to="/settings" />
      </Route>
    </Switch>
  )
})

export default SettingsRoot
