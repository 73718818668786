import { action, computed, observable, reaction, toJS } from "mobx"

import { ProductPublishStats } from "@framework/types/account"
import { ID } from "@framework/types/types"
import accountService from "@services/account.service"
import RootStore from "@store/RootStore"
import { InfiniteIdList } from "@services/common"

const PRODUCT_FEED_GMC_EXPORT_LUNCHED_KEY = "PRODUCT_FEED_GMC_EXPORT_LUNCHED"

export class GMCStore {
  @observable rootStore: RootStore

  @computed get productFeedStore() {
    return this.rootStore.productFeedStore
  }

  constructor(root: RootStore) {
    this.rootStore = root

    this.syncLunchedExport()

    reaction(
      () => this.lunchedPublishing.size,
      () => {
        localStorage.setItem(
          PRODUCT_FEED_GMC_EXPORT_LUNCHED_KEY,
          JSON.stringify(toJS(Array.from(this.lunchedPublishing)))
        )
      }
    )
  }

  @observable isPlaceholderModalOpened = false

  @observable lunchedPublishing: Set<string> = new Set()

  @observable exportStatus: ProductPublishStats | null = null

  @computed get isInited() {
    return this.exportStatus != null
  }

  @computed get isExportAvailable() {
    return (
      this.exportStatus?.running == null ||
      this.exportStatus?.running === "finished" ||
      this.exportStatus?.running === "failed"
    )
  }

  @computed get wasPublishingLunched() {
    const { importKey } = this.rootStore.productFeedStore
    if (importKey == null) return false
    return this.lunchedPublishing.has(importKey)
  }

  @action showPlaceholderModal = (open = false) => {
    this.isPlaceholderModalOpened = open
  }

  @observable isExportStatusLoading: boolean = false

  @observable exportStatusError: string | null = null

  @action loadGMCExportStatus = async (accountId: ID) => {
    if (this.isExportStatusLoading) return null
    try {
      const { importKey } = this.rootStore.productFeedStore
      if (importKey == null) throw new Error()

      this.isExportStatusLoading = true
      const response = await accountService.getGMCPublishStatus(accountId)
      this.exportStatusError = null
      this.exportStatus = response.data.data

      if (
        this.exportStatus.running != null &&
        this.exportStatus.running !== "finished" &&
        this.exportStatus.running !== "failed" &&
        !this.wasPublishingLunched
      )
        this.lunchedPublishing.add(importKey)
    } catch (error) {
      this.exportStatus = {}
      this.exportStatusError = "Unexpected error"
    } finally {
      this.isExportStatusLoading = false
    }
    return this.exportStatusError
  }

  @observable publishError: string | null = null

  @observable publishLoading = false

  @computed get isConnected() {
    const account = this.rootStore.accountStore?.account
    if (!account) return false
    const { gmcFeedId, gmcMerchantId } = account
    return Boolean(gmcFeedId && gmcMerchantId)
  }

  @action publishProducts = async (accountId: ID, productId?: ID) => {
    try {
      this.publishError = null
      this.publishLoading = true

      const { importKey } = this.rootStore.productFeedStore
      if (importKey == null) throw new Error()
      this.lunchedPublishing.add(importKey)

      const { mode, data } = this.rootStore.productFeedStore.list.selected

      const payload: InfiniteIdList =
        productId == null
          ? { ids: [...data].map(Number), mode }
          : { mode: "include", ids: [Number(productId)] }

      await accountService.publishProductsToGMC(accountId, payload)
      await this.subscribeOnExportStatus(accountId)
    } catch (error: any) {
      this.publishError = error?.response?.data?.error ?? "Unexpected error"
    } finally {
      this.publishLoading = false
    }
    return this.publishError
  }

  syncLunchedExport = () => {
    try {
      const str = localStorage.getItem(PRODUCT_FEED_GMC_EXPORT_LUNCHED_KEY)
      if (str) this.lunchedPublishing = new Set(JSON.parse(str))
    } catch (error) {
      this.lunchedPublishing = new Set()
    }
  }

  @action finalizePublishing = () => {
    this.productFeedStore.reloadData()
    const { importKey } = this.rootStore.productFeedStore
    if (importKey == null) return
    this.lunchedPublishing.delete(importKey)
  }

  @observable private timer?: NodeJS.Timeout

  @action subscribeOnExportStatus = async (
    accountId: ID,
    delay: number = 10_000
  ) => {
    this.unsubscribeOnExportStatus()
    this.loadGMCExportStatus(accountId)

    this.timer = setInterval(() => {
      this.loadGMCExportStatus(accountId)
    }, delay)
  }

  @action unsubscribeOnExportStatus = () => {
    if (this.timer) clearInterval(this.timer)
  }
}

export default GMCStore
