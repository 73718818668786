import React from "react"

interface GoogleIconProps {
  width?: number
  height?: number
}

const GoogleIcon = ({ width, height }: GoogleIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 33}
    height={height || 32}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="#4285F4"
        d="M27.847 14.262c0-1.147-.093-1.984-.295-2.852H14.453v5.177h7.69c-.156 1.287-.993 3.225-2.853 4.527l-.026.173 4.141 3.209.287.029c2.636-2.434 4.155-6.015 4.155-10.263Z"
      />
      <path
        fill="#34A853"
        d="M14.452 27.904c3.767 0 6.93-1.24 9.24-3.38l-4.403-3.41c-1.179.822-2.76 1.395-4.837 1.395-3.69 0-6.82-2.434-7.937-5.798l-.164.014-4.306 3.333-.057.157c2.295 4.557 7.007 7.689 12.464 7.689Z"
      />
      <path
        fill="#FBBC05"
        d="M6.515 16.711a8.59 8.59 0 0 1-.465-2.76c0-.96.17-1.89.45-2.758l-.009-.185-4.36-3.387-.143.068A13.965 13.965 0 0 0 .5 13.952c0 2.248.543 4.371 1.488 6.263l4.527-3.504Z"
      />
      <path
        fill="#EB4335"
        d="M14.452 5.395c2.62 0 4.387 1.131 5.395 2.077l3.937-3.845C21.366 1.38 18.22 0 14.452 0 8.995 0 4.282 3.131 1.988 7.689L6.5 11.193c1.132-3.364 4.263-5.798 7.953-5.798Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h28v28H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default GoogleIcon
