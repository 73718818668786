import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "@root/store"
import useURLSearchParams from "@framework/hooks/useURLSearchParams"
import Loader from "@components/ui/Loader/Loader"
import EditAssetGroupForm from "@pages/PMax/components/EditAssetGroupPage/EditAssetGroupForm"

const EditAssetGroupPage: FC = observer(() => {
  const params = useURLSearchParams()
  const assetGroupId: number = Number(params.get("assetGroupId"))
  const { pMaxAssetGroupStore, accountStore } = useStore()

  const { getAssetGroupById, data } = pMaxAssetGroupStore
  const accountId = accountStore.getAccountId()

  useEffect(() => {
    getAssetGroupById(accountId, assetGroupId)
  }, [])

  if (!data.id) return <Loader isLoading />

  return (
    <EditAssetGroupForm
      formProps={data}
      accountId={accountId}
      assetGroupId={assetGroupId}
    />
  )
})

export default EditAssetGroupPage
