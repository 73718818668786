import React from "react"
import { observer } from "mobx-react-lite"
import { TrendValue } from "@components/ui/DataChangeWidget/DataChangeWidget"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import Typography from "@components/ui/Typography/Typography"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import { CampaignReportType } from "@framework/types/account"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Column from "./Table/Column"
import Row from "./Table/Row"

import styles from "./InsightTable.module.scss"

export const CampaignRow: React.FC<{
  data: CampaignReportType
  mapper: MetricNameType[]
}> = observer(({ data: item, mapper: columns }) => (
  <Row className={styles.campaignRow}>
    <Column key="name">
      <Tooltip text={item.Name} lineClamp={2}>
        {item.Name}
      </Tooltip>
    </Column>
    {columns.map((metricName) => {
      const metricValue = item[metricName]
      const value = metricValue?.Value ?? 0
      const delta = metricValue?.Change ?? 0
      const desc = getMetricDescriptor(metricName)
      return (
        <Column key={metricName}>
          <Typography className={styles.column}>
            <Typography>{desc.formatter(value) ?? value}</Typography>
            <TrendValue
              className={styles.trend}
              value={Math.ceil(delta * 100)}
              arrowAfter
              metricDir={(desc.trendFormat ?? positiveTransformDirection)(
                delta
              )}
            />
          </Typography>
        </Column>
      )
    })}
  </Row>
))

export default CampaignRow
