import { AxiosResponse } from "axios"
import { MetricOption } from "@store/reports/types"
import { MetricNameType } from "@framework/types/metrics"
import HttpService from "./http.service"
import config from "../config"
import { ID } from "../framework/types/types"

export interface ReportTableSettingsRow {
  isEnabled: boolean
  name: string
}
export interface GetReportTableSettingsResponse {
  data: MetricNameType[]
}

export interface GetUserReportTableSettingsResponse {
  data: null | {
    settings: MetricOption[]
  }
}

class ReportAPI extends HttpService {
  getReportsTableSettings = (): Promise<
    AxiosResponse<GetReportTableSettingsResponse>
  > => this.get(`users/reports/default-settings`)

  getUserReportsTableSettings = (
    userId: ID
  ): Promise<AxiosResponse<GetUserReportTableSettingsResponse>> =>
    this.get(`user/${userId}/reports/settings`)

  updateUserReportsTableSettings = (
    userId: ID,
    settings: MetricOption[]
  ): Promise<AxiosResponse<GetUserReportTableSettingsResponse>> =>
    this.post(`user/${userId}/reports/settings`, { settings })
}

export default new ReportAPI({
  API_URL: config.API_ROOT,
})
