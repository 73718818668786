import React, { useMemo } from "react"
import StatusBadge from "@pages/GoogleShoppingChannel/components/Status/StatusBadge"
import { LabelMapper } from "@framework/types/types"
import { getCampaignStatusMapper } from "./EditCopySidebar/utils"

export const useCampaignStatusOptionList = (list: LabelMapper[]) => {
  const mapper = useMemo(() => new Map(list.map((it) => [it.name, it])), [list])
  const options = useMemo(() => list.map((it) => it.name), [list])

  return {
    options,
    renderValue: (name: string) => (
      <StatusBadge
        value={mapper.get(name)?.name ?? ""}
        mapper={getCampaignStatusMapper}
      />
    ),
  }
}

export default useCampaignStatusOptionList
