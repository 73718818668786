import React from "react"
import get from "lodash/get"

import TextDiff from "@components/ui/TextDiff/TextDiff"
import Typography from "@components/ui/Typography/Typography"
import { mbNoDataWithUnit } from "@services/utils"
import { ViewCellProps } from "../../types"

import styles from "./TableCell.module.scss"

export interface SimpleTextCellProps extends ViewCellProps {
  unitName?: string
  valueFormatter?: (value: any) => string
}

export const SimpleTextCell: React.FC<SimpleTextCellProps> = React.memo(
  ({ name, data, changeData, unitName, valueFormatter = (v: any) => v }) => {
    const unit = unitName != null ? get(data, unitName, "") : ""

    const value = data?.[name]?.toString() ?? ""
    const change = changeData?.update?.[name]?.toString() ?? ""

    return (
      <Typography className={styles.defaultText}>
        {!change ? (
          mbNoDataWithUnit(value, unit, valueFormatter)
        ) : (
          <>
            <TextDiff by="words" oldText={value} newText={change} /> {unit}
          </>
        )}
      </Typography>
    )
  }
)

export default SimpleTextCell
