import React, { FC, useState } from "react"
import Typography from "@components/ui/Typography/Typography"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import { Button } from "@components/ui/Button"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import useOutsideClick from "@framework/hooks/useOutsideClick"
import { observer } from "mobx-react-lite"
import styles from "./AssetImage.module.scss"

interface AssetImageProps {
  checked: boolean
  src: string
  name: string
  id?: number
  size?: string
  isLoading?: boolean
  onRename?: (name: string, id: number) => void
  onLocalRename?: (name: string) => void
  onDelete?: (ids: number[]) => void
  onLocalDelete?: (names: string[]) => void
  onLocalCrop?: (name: string) => void
  onCrop?: (id: number) => void
  onCheck?: (id: number) => void
  onLocalCheck?: (name: string) => void
}

const AssetImage: FC<AssetImageProps> = observer(
  ({
    src,
    name,
    id,
    size,
    isLoading,
    checked,
    onRename,
    onLocalRename,
    onDelete,
    onLocalDelete,
    onCrop,
    onLocalCrop,
    onCheck,
    onLocalCheck,
  }) => {
    const [isActionsMenuVisible, setIsActionsMenuVisible] = useState(false)

    const handleChange = () => {
      if (onCheck && id) {
        onCheck(id)
      } else if (onLocalCheck) {
        onLocalCheck(name)
      }
    }

    const handleOnRename = () => {
      setIsActionsMenuVisible(false)
      if (onLocalRename) {
        onLocalRename(name)
      } else if (id && onRename) {
        onRename(name, id)
      }
    }
    const handleOnDelete = () => {
      if (onLocalDelete) {
        onLocalDelete([name])
      } else if (id && onDelete) {
        onDelete([id])
      }
      setIsActionsMenuVisible(false)
    }
    const handleOnCrop = () => {
      if (onLocalCrop) {
        onLocalCrop(name)
      } else if (onCrop && id) {
        onCrop(id)
      }

      setIsActionsMenuVisible(false)
    }
    const ref = useOutsideClick(() => {
      setIsActionsMenuVisible(false)
    })
    return (
      <div className={styles.assetImageWrap}>
        <div className={styles.imageWrap}>
          {isLoading ? (
            <div className={styles.loader}>
              <CircleLoader />
            </div>
          ) : null}
          <IconButton
            className={styles.menuButton}
            size="small"
            onClick={() => setIsActionsMenuVisible((prevState) => !prevState)}
          >
            <Icon name="three-dots" />
          </IconButton>
          <div className={styles.imageActions} />
          {isActionsMenuVisible && (
            <div className={styles.actionsMenu} ref={ref}>
              <div>
                <Button
                  color="secondary"
                  variant="ghost"
                  before={<Icon name="pen" />}
                  onClick={handleOnRename}
                >
                  Rename
                </Button>
              </div>
              <div>
                <Button
                  color="secondary"
                  variant="ghost"
                  before={<Icon name="fullscreen-enter" />}
                  onClick={handleOnCrop}
                >
                  Crop
                </Button>
              </div>
              <div className={styles.actionsMenuDelete}>
                <Button
                  color="secondary"
                  variant="ghost"
                  before={<Icon name="trash" />}
                  onClick={handleOnDelete}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
          <img src={src} alt={name} className={styles.image} />
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottomText}>
            <Typography className={styles.nameText} type="body2">
              {name}
            </Typography>
            {size ? <Typography type="body2">{size}</Typography> : null}
          </div>
          <CheckBox checked={checked} onChange={handleChange} />
        </div>
      </div>
    )
  }
)

export default AssetImage
