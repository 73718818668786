/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"

import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { useOpportunitiesStore } from "@store/opportunities/provider"
import KeywordsList from "./components/KeywordsList/KeywordsList"
import LocationsMap from "./components/GoogleMap/LocationsMap"
import KeywordLocationTooltip from "./components/LocationTooltip/KeywordLocationToolTip"
import AccountLocationTooltip from "./components/LocationTooltip/AccountLocationToolTip"

import styles from "./KeywordsMapModal.module.scss"

const categoryGroup1 = ["neighborhood", "street", "postal"]

const getColor = (item?: any) => {
  if (!item.categoryName) return "#17899E"
  if (categoryGroup1.includes(item.categoryName)) return "#5CBD95"
  return "#E9635A"
}

type KeywordsMapModalProps = {
  isOpen: boolean
  onClose: () => void
}

const center = {
  lat: 40,
  lng: -95,
}

const KeywordsMapModal: React.FC<KeywordsMapModalProps> = observer(
  ({ isOpen, onClose }) => {
    const { keywordsWithLocations: keywords } = useOpportunitiesStore()
    const {
      accountStore: { account },
    } = useStore()

    const [selected, setSelected] = useState<any>()
    const [position, setPosition] = useState<any>(center)

    const accountLocation = useMemo(
      () => ({
        id: account?.id,
        name: account?.name,
        location: { lat: account?.point[1], lng: account?.point[0] },
        pointType: "account",
      }),

      [account]
    )

    const points = useMemo(
      () => [...keywords, accountLocation],
      [keywords, accountLocation]
    )

    const handleSelect = useCallback((item?: any) => {
      setSelected(item)
    }, [])

    const handleSelectAndFocus = useCallback((item: any) => {
      setSelected((prev: any) => {
        const newSelected = prev && prev.id === item.id ? undefined : item
        if (newSelected?.location) setPosition({ ...item.location })
        return newSelected
      })
    }, [])

    useEffect(() => {
      if (!isOpen) {
        setSelected(null)
        setPosition(center)
      } else {
        setPosition(accountLocation.location)
      }
    }, [isOpen])

    const toolTip = selected ? (
      selected.pointType === "account" ? (
        <AccountLocationTooltip
          account={account}
          data={selected}
          onCloseClick={() => handleSelect()}
          key={selected.id}
        />
      ) : (
        <KeywordLocationTooltip
          onCloseClick={() => handleSelect()}
          data={selected}
          short={categoryGroup1.includes(selected.categoryName)}
          key={selected.id}
        />
      )
    ) : null

    return (
      <Modal
        isOpen={isOpen}
        className={styles.root}
        onClose={() => onClose()}
        title={
          <Typography type="h2" bold color="primary">
            Market Keyword Map
          </Typography>
        }
      >
        <div className={styles.body}>
          <KeywordsList
            keywords={keywords}
            selected={selected}
            handleSelect={handleSelectAndFocus}
          />
          <LocationsMap
            selected={selected}
            center={position}
            points={points}
            getColor={getColor}
            className={styles.mapContainer}
            handleSelect={handleSelect}
            toolTip={toolTip}
          />
        </div>
      </Modal>
    )
  }
)

export default React.memo(KeywordsMapModal)
