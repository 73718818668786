/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { findDefaultNavItem } from "@components/ui/Tabs/utils"
import PrivateLayout, {
  NavBarProps,
} from "@root/layouts/PrivateLayout/PrivateLayout"
import { useStore } from "@store/index"
import { NavOption } from "@components/ui/Tabs/types"
import { productFeedNavItems as navItems } from "./navOptions"
import { accountSidebarNavItems } from "../navOptions"

const GoogleShoppingLayout: React.FC = observer(({ children }) => {
  const history = useHistory()

  const {
    appStore: { showEverything },
    accountStore: { account, accountId },
  } = useStore()

  const [tab, setTab] = useState<NavOption>()

  const { pathname, search } = useLocation<any>()

  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  )

  const filteredNavItems: NavOption[] = accountSidebarNavItems

  const handleNavBarClick = useCallback(
    (item: NavOption) => history.push(`/shopping/${accountId}${item.value}`),
    [history, accountId]
  )

  const handleSidebarClick = useCallback(
    (item: NavOption) => history.push(`/account/${accountId}${item.value}`),
    [accountId, history]
  )

  const withNavBar = useMemo(
    (): NavBarProps | undefined => ({
      options: navItems,
      title: "Product Feed",
      onSelect: handleNavBarClick,
      currentTab: tab,
    }),
    [handleNavBarClick, tab]
  )

  useEffect(() => {
    setTab(findDefaultNavItem(navItems, pathname, queryParams.alter))
  }, [pathname, queryParams])

  const sidebarNav = useMemo((): NavBarProps | undefined => {
    const confirmation = pathname.endsWith("/create-campaign")

    if (!confirmation) {
      return {
        options: filteredNavItems,
        title: account?.name ?? "",
        onSelect: handleSidebarClick,
        currentTab: filteredNavItems[showEverything ? 2 : 1],
      }
    }
  }, [account, handleSidebarClick, pathname, showEverything, filteredNavItems])

  return (
    <PrivateLayout
      navBar={withNavBar}
      sideBar={sidebarNav}
      scope="channel"
      key="shopping"
    >
      {children}
    </PrivateLayout>
  )
})

export default GoogleShoppingLayout
