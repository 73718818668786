import { observable, action, computed } from "mobx"
import { difference } from "ramda"
import AbstractFilter from "./AbstractFilter"
import { ID, Option } from "../types/types"

const fullDifference = (list1: any, list2: any) =>
  difference(list1, list2).length + difference(list2, list1).length

export class OptionsFilter extends AbstractFilter {
  @observable options: Option[]

  @observable active: ID[]

  @observable temp: ID[]

  constructor(options?: Option[]) {
    super()
    this.temp = []
    this.active = []
    this.options = options ?? []
  }

  @computed get tempFiltersCount() {
    return this.temp.length
  }

  @computed get activeFiltersCount() {
    return this.active.length
  }

  @computed get filtersCountDifference() {
    return fullDifference(this.active, this.temp)
  }

  @computed get isSync() {
    return !(
      this.active.length !== this.temp.length ||
      this.filtersCountDifference === 0
    )
  }

  @computed get isSelected() {
    return (optionId: ID) => this.temp.includes(optionId)
  }

  @action resetTemp = () => {
    this.temp = [...this.active]
  }

  @action resetAll = () => {
    this.active = []
    this.temp = []
  }

  @action applyFilter = () => {
    this.active = [...this.temp]
  }

  @action setOptions = (options: Option[]) => {
    // TODO clean up temp/active value
    this.options = options
  }

  @action toggleOption = (optionId: ID) => {
    const isActive = this.isSelected(optionId)
    if (isActive) this.temp = this.temp.filter((value) => value !== optionId)
    else this.temp.push(optionId)
  }
}

export default OptionsFilter
