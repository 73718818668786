/* eslint-disable no-useless-escape */
import moment from "moment"
import { YEAR_MONTH_KEY_FORMAT } from "@framework/constants/moment-format"
import {
  CheckbookReport,
  MonthlyCheckbookAmount,
} from "@framework/types/clientCheckbook"

export const normalizeCheckbookAmountList = async (
  report: MonthlyCheckbookAmount[]
): Promise<MonthlyCheckbookAmount[]> =>
  report.map(({ month, ...rest }) => ({
    month: moment
      .utc(month, YEAR_MONTH_KEY_FORMAT)
      .format(YEAR_MONTH_KEY_FORMAT),
    ...rest,
  }))

export const transformCheckbookReport = async ({
  checkbooks,
  ...rest
}: CheckbookReport): Promise<CheckbookReport> => ({
  checkbooks: await normalizeCheckbookAmountList(checkbooks),
  ...rest,
})

export const extractUniqYears = (
  checkbooks: MonthlyCheckbookAmount[]
): number[] =>
  Array.from(
    checkbooks.reduce((collection, item) => {
      const year = moment(item.month, YEAR_MONTH_KEY_FORMAT).year()
      collection.add(year)
      return collection
    }, new Set<number>())
  )

export default {}
