import moment from "moment"
import React from "react"
import { initArrayByLength, randomNumber } from "@components/utils/numberUtils"
import { MM_DD_YYYY_DATE_FORMAT } from "@framework/constants/moment-format"
import { Option } from "@framework/types/types"
import { percentageFormatter } from "@services/utils"
import {
  actionOptions,
  campaignTypeOptions,
  operatorOptions,
  ruleTypeOptions,
} from "./components/EditRule/components/BudgetTypeRoleForm/options"

export const createRandomData = () => ({
  startDate: moment().toDate(),
  endDate: moment().startOf("year").toDate(),
  operator: randomNumber(0, 2),
  campaignType: "All Non-Brand Campiangs",
  frequency: !!randomNumber(0, 2),
  action: !!randomNumber(0, 2),
})

export const createRandomRoleData = () => ({
  rowAction: !!randomNumber(0, 2),
  ruleType: !!randomNumber(0, 2),
  status: !!randomNumber(0, 2),
})

export const roleItems = initArrayByLength(7).map((index) => ({
  data: createRandomRoleData(),
  label: `Rule_${index}`,
  id: randomNumber(0, 9999999),
  conditions: initArrayByLength(4).map((index) => ({
    data: createRandomData(),
    label: `Condition_${index}`,
    id: randomNumber(0, 9999999),
  })),
}))

export const campaignItems = initArrayByLength(3).map((index) => ({
  data: createRandomData(),
  label: `Campaign_${index}`,
  id: randomNumber(0, 9999999),
}))

export const adGroupItems = initArrayByLength(3).map((index) => ({
  data: createRandomData(),
  label: `AdGroup_${index}`,
  id: randomNumber(0, 9999999),
}))

export const renderOption =
  <T extends string = string>(options: Option<T>[], defValue: string = "-") =>
  (value: T) =>
    options.find((option) => option.value === value)?.label ?? defValue

export const renderDate = (date: Date) =>
  moment(date).format(MM_DD_YYYY_DATE_FORMAT)

type MapperType = {
  name: string
  label: string
  flex?: string
  renderCallback?: (value: any) => React.ReactNode
}

export const budgetRuleMapper: MapperType[] = [
  {
    name: "trigger",
    label: "IF (Trigger)",
    renderCallback: renderOption(ruleTypeOptions),
  },
  {
    name: "operator",
    label: "IS (Operator)",
    renderCallback: renderOption(operatorOptions),
  },
  {
    name: "value",
    label: "Value #, $ or %",
    renderCallback: percentageFormatter,
  },
  {
    name: "action",
    label: "Then (Action)",
    flex: "1.5 1.5",
    renderCallback: renderOption(actionOptions),
  },
  {
    name: "applied_area",
    label: "Applied to (Campaign Type)",
    flex: "2 2",
    renderCallback: renderOption(campaignTypeOptions, "Custom"),
  },
]

export const timeRuleMapper: MapperType[] = [
  { name: "start_date", label: "Start Campaign", renderCallback: renderDate },
  { name: "end_date", label: "End Campaign", renderCallback: renderDate },
  {
    name: "applied_area",
    label: "Applied to (Campaign Type)",
    flex: "2 2",
    renderCallback: renderOption(campaignTypeOptions, "Custom"),
  },
]

export const defBudgetRow = {
  trigger: "Budget",
  operator: "Less Then",
  value: "100%",
  action: "Allow 5% overbudget",
  campaignType: "Applied to  All Bon-Brand Campiangs",
}
