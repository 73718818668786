import React from "react"

import LimitLengthTextField from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/EditCopySidebar/LimitLengthTextField"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Typography from "@components/ui/Typography/Typography"
import { data } from "./AnswersList"

import styles from "./AnswerForm.module.scss"

interface AnswerFormProps {
  index: number
}

export const AnswerForm: React.FC<AnswerFormProps> = ({ index: idx }) => {
  const it = data[idx]
  return (
    <label htmlFor={it.id} className={styles.root}>
      <Typography type="h5" weight="bold" className={styles.header}>
        Generated at: 03/21/2023 16:47
      </Typography>

      <div className={styles.body}>
        <CheckBox id={it.id} />

        <div className={styles.content}>
          <Typography weight="semibold">{it.question}</Typography>

          <LimitLengthTextField
            name={`list[${idx}].answer`}
            maxChars={1000}
            value={it.answer}
          />
        </div>
      </div>
    </label>
  )
}

export default AnswerForm
