import React from "react"
import { observer } from "mobx-react-lite"

import Modal from "@components/ui/Modal/Modal"
import { AdCopyTask } from "@store/manageProfile/edit-adCopy.store"

import DuplicateAdCopyForm from "./DuplicateAdCopyForm"

type DuplicateAdCopyModalProps = {
  isOpened: boolean
  task?: AdCopyTask | null
  onClose: () => void
}

const DuplicateAdCopyModal: React.FC<DuplicateAdCopyModalProps> = observer(
  ({ isOpened, onClose, task }) => (
    <Modal isOpen={isOpened} onClose={onClose} hideHeader>
      {task != null && (
        <DuplicateAdCopyForm
          adCopyIds={task.adCopyIds}
          onCancel={onClose}
          onSubmit={onClose}
        />
      )}
    </Modal>
  )
)

export default DuplicateAdCopyModal
