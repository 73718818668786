import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import WaterfallTable from "@components/ui/WaterfallTable/WaterfallTable"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import CampaignRow from "./CampaignRow"

import styles from "./CustomCampaignsTable.module.scss"

export type CheckedCampaignsMap = {
  [campaignId: string]: SelectedAdGroupsMap
}

export type SelectedAdGroupsMap = {
  [key: string]: boolean
}

export type CustomCampaignsTableProps = {
  onChange?: (id: string[]) => void
}

export const CustomCampaignsTable: React.FC<CustomCampaignsTableProps> =
  observer(({ onChange }) => {
    const { rulesStore } = useStore()

    const { campaigns } = rulesStore

    const [checkedCampaigns, setCheckedCampaigns] =
      useState<CheckedCampaignsMap>({})

    const handleChangeAdGroup = useCallback(
      (id: string, selectedGroups: SelectedAdGroupsMap) => {
        setCheckedCampaigns((oldState) => ({
          ...oldState,
          [id]: selectedGroups,
        }))
      },
      [setCheckedCampaigns]
    )

    useEffect(() => {
      if (onChange)
        onChange(
          Object.values(checkedCampaigns).flatMap((map) => Object.keys(map))
        )
    }, [checkedCampaigns, onChange])

    return (
      <div className={styles.root}>
        <Typography type="h3">Custom Campaign and Ad Groups</Typography>
        <div className={styles.tableContainer}>
          <WaterfallTable
            withTip
            headerClassName={styles.header}
            rootClassName={styles.tableRoot}
            label="Campaign Name"
          >
            {campaigns?.map((props, idx) =>
              props.Adgroups ? (
                <CampaignRow
                  id={props.Id.toString()}
                  selectedAdGroups={checkedCampaigns[props.Id] ?? {}}
                  onChangeAdGroups={handleChangeAdGroup}
                  adGroups={props.Adgroups}
                  label={props.Name}
                  index={idx}
                  key={props.Id}
                />
              ) : null
            )}
          </WaterfallTable>
        </div>
      </div>
    )
  })

export default CustomCampaignsTable
