import { observable } from "mobx"
import RootStore from "@store/RootStore"
import ReportSettingsStore from "./settings.store"
import ReportFilterStore from "./report-filter.store"
import SummaryPerformanceStore from "./performance.store"
import TimeSegmentedPerformanceStore from "./time-segmented-performance.store"
import KpiTrendsFilterStore from "./kpitrends-filter.store"
import ExtendedReportStore from "./extend-report.store"

export class ReportStore {
  constructor(root: RootStore) {
    this.settings = new ReportSettingsStore()
    this.mainFilter = new ReportFilterStore(root)
    this.filter = new KpiTrendsFilterStore()
    this.summaryPerformance = new SummaryPerformanceStore()
    this.extendedReport = new ExtendedReportStore(root)
    this.currPeriodPerformance = new TimeSegmentedPerformanceStore()
    this.prevPeriodPerformance = new TimeSegmentedPerformanceStore()
  }

  @observable mainFilter: ReportFilterStore

  @observable filter: KpiTrendsFilterStore

  @observable settings: ReportSettingsStore

  @observable summaryPerformance: SummaryPerformanceStore

  @observable extendedReport: ExtendedReportStore

  @observable currPeriodPerformance: TimeSegmentedPerformanceStore

  @observable prevPeriodPerformance: TimeSegmentedPerformanceStore
}

export default ReportStore
