import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import RulesTable from "../RulesTable/RulesTable"
import Header from "../Header/Header"

import styles from "./RulesList.module.scss"

type RulesListProps = {}

const RulesList: React.FC<RulesListProps> = observer(() => {
  const { rulesStore, accountStore } = useStore()
  const {
    rulesList,
    rulesListLoading,
    editRuleLoading,
    deleteRuleLoading,
    loadRuleList,
    loadCampaignReports,
  } = rulesStore
  const { accountId } = accountStore

  useEffect(() => {
    if (accountId && !deleteRuleLoading) loadRuleList(accountId)
  }, [accountId, deleteRuleLoading, loadRuleList])

  useEffect(() => {
    if (accountId) loadCampaignReports(accountId)
  }, [accountId])

  return (
    <div className={styles.root}>
      <ServiceDownInterceptor />
      <Loader isLoading={rulesListLoading || editRuleLoading} />
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.body}>
        <RulesTable rulesList={rulesList ?? []} />
      </div>
    </div>
  )
})

export default RulesList
