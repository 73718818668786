import {
  RelatedQuestionEntity,
  RelatedQuestionSetName,
} from "@framework/types/opportunities"
import { action, computed, observable } from "mobx"

export class RelatedQuestionsSetStore {
  readonly setName: RelatedQuestionSetName

  @observable data: RelatedQuestionEntity[] = []

  constructor(options: { name: RelatedQuestionSetName }) {
    this.setName = options.name
  }

  @computed get total() {
    const { data } = this
    return data.length
  }

  @action setData = (data: RelatedQuestionEntity[]) => {
    this.data = data
  }
}

export default RelatedQuestionsSetStore
