import clamp from "lodash/clamp"

export const gradient = [
  "var(--error-80-color)",
  "var(--error-40-color)",
  "var(--black-03-color)",
  "var(--success-40-color)",
  "var(--success-80-color)",
]

/**
 * @param trend expected value from -1 to 1
 */
export const getTrendGradientColor = (trend?: number) => {
  if (trend == null) return "transparent"

  const index = Math.ceil((Math.abs(trend + 1) / 2) * gradient.length) - 1

  return gradient[clamp(index, 0, gradient.length - 1)]
}

export default null
