import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"

import LineChart, {
  LineDataType,
} from "@components/ui/Charts/LineChart/LineChart"
import Box from "@components/ui/Box/Box"
import { darkColors } from "@components/ui/Charts/utils"
import makePerformanceChartConfig, {
  makePerformanceChartYAxisConfig,
} from "@components/ui/Charts/LineChart/config"
import Loader from "@components/ui/Loader/Loader"

import Templates from "@components/ui/Templates"
import Typography from "@components/ui/Typography/Typography"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import { renderMetricOption } from "@framework/constants/metrics"
import { metricNamesList, MetricNameType } from "@framework/types/metrics"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import Tabs from "@components/ui/Tabs/Tabs"
import { periodicityOptions } from "@framework/constants/report"
import { generateTimeAxisLabels } from "@utils/date"
import { initArray, randomNumber } from "@utils/numberUtils"
import { extractPlatformReportDataset } from "./utils"

import styles from "./index.module.sass"

// TODO sync with main filter
const startDate = moment().clone().subtract(13, "days").startOf("day").toDate()

// TODO calculate from selected filter date range
const totalPoints = 14

// TODO sync with global filter
const defaultMetricsOptions = [...metricNamesList]

type KPITrendChartProps = {
  className?: string
}

const KPITrendChart: React.FC<KPITrendChartProps> = observer(
  ({ className }) => {
    const [activePeriodicity, setActivePeriodicity] =
      React.useState<PerformanceReportPeriodicity>("daily")

    const [activeMetric, setActiveMetric] =
      React.useState<MetricNameType>("Cost")

    // TODO calculate depends on API
    const labels = React.useMemo(
      () => generateTimeAxisLabels(startDate, totalPoints, activePeriodicity),
      [activePeriodicity]
    )

    // TODO integrate with API
    const dataMock: LineDataType[] = React.useMemo(
      () => [
        {
          dashed: false,
          dataset: [
            extractPlatformReportDataset(
              activeMetric,
              initArray(totalPoints, () => randomNumber(0, 1_000))
            ),
          ],
        },
      ],
      [activeMetric, activePeriodicity]
    )

    const chartConfig = React.useMemo(
      () =>
        makePerformanceChartConfig(
          labels,
          activePeriodicity,
          {
            y: makePerformanceChartYAxisConfig(activeMetric, "left"),
          },
          "unit"
        ),
      [labels, activePeriodicity, activeMetric]
    )

    // TODO integrate with API
    const isLoading = false

    return (
      <Box className={clsx(styles.root, className)}>
        {/* Main header */}
        <Templates.TripleHeader
          // offset="medium"
          left={<Typography type="h2">KPI Trends</Typography>}
          right={
            <>
              <SimpleSelect
                className={styles.filed}
                value={activeMetric}
                options={defaultMetricsOptions}
                renderValue={renderMetricOption}
                onSelect={(v) => setActiveMetric(v as MetricNameType)}
              />
            </>
          }
        >
          <Tabs
            options={periodicityOptions}
            currentValue={activePeriodicity}
            onChange={({ value }) =>
              setActivePeriodicity(value as PerformanceReportPeriodicity)
            }
          />
        </Templates.TripleHeader>

        <Loader isLoading={isLoading} />

        {/* Chart */}
        <LineChart
          lineData={dataMock}
          labels={labels}
          colors={darkColors}
          options={chartConfig}
        />
      </Box>
    )
  }
)

export default KPITrendChart
