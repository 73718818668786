/* eslint-disable react/no-array-index-key */
import React from "react"
import clsx from "clsx"

import Typography, {
  ITypographyProps,
} from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import Box from "@components/ui/Box/Box"
import { MetricDirection } from "@framework/types/metrics"
import { TypographyVariantType } from "@utils/enums"

import styles from "./DataChangeWidget.module.scss"

type DataChangeWidgetProps = {
  className?: string
  title?: string
  options?: {
    amount: string
    trend: {
      value: number
      direction: number
    }
  }[]
}

const DataChangeWidget: React.FC<DataChangeWidgetProps> = ({
  className,
  title,
  options = [],
}) => (
  <Box className={clsx(styles.root, className)}>
    <Typography type="h2" color="dark" weight="bold">
      {title}
    </Typography>

    <div className={styles.wrapper}>
      {options.map((data: any, idx) => {
        const { amount, trend } = data

        return (
          <div className={styles.item} key={idx}>
            <Typography type="h5" color="dark">
              {amount}
            </Typography>
            <TrendValue {...trend} />
          </div>
        )
      })}
    </div>
  </Box>
)

export type TrendValueProps = {
  value: number
  typographyType?: ITypographyProps["type"]
  weight?: ITypographyProps["weight"]
  metricDir: MetricDirection
  className?: string
  hideArrow?: boolean
  arrowAfter?: boolean
  renderValue?: (value: number) => React.ReactNode
}

export const TrendValue: React.FC<TrendValueProps> = ({
  value,
  metricDir,
  typographyType = "h5",
  hideArrow,
  arrowAfter = false,
  weight,
  className,
  renderValue = defaultRenderValue,
}) => {
  const { direction, color } = metricDir

  const arrowNode = direction !== "none" && !hideArrow && (
    <Icon
      name="arrow-down"
      className={styles.arrow}
      rotateAngle={direction === "up" ? 180 : 0}
    />
  )
  return (
    <Typography
      className={clsx(styles.trend, className)}
      type={typographyType}
      weight={weight}
      color={color || "lightGray"}
    >
      {!arrowAfter && arrowNode}
      {renderValue(value)}
      {arrowAfter && arrowNode}
    </Typography>
  )
}

export default DataChangeWidget

export const defaultRenderValue = (value: number) => {
  const sign = value >= 0 ? "" : "-"
  const absValue = Math.abs(value)
  const inf = absValue === Infinity ? <span>&#8734;</span> : absValue
  return (
    <>
      {sign}
      {inf}%
    </>
  )
}
