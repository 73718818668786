/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import { NavOption } from "@components/ui/Tabs/types"

import styles from "./SideBar.module.scss"

export interface SideBarProps {
  options: NavOption[]
  currentValue?: NavOption
  onChange?: (value: NavOption) => void
}

const SideBar: React.FC<SideBarProps> = observer(
  ({ options, currentValue, onChange }) => {
    const rootNode = useRef<HTMLDivElement>(null)

    return (
      <div className={styles.root} ref={rootNode}>
        <Typography color="light" type="h1" className={styles.header}>
          ADORO
        </Typography>

        <ul className={styles.list}>
          {options.map((navItem) => {
            const { value, label, icon } = navItem
            return (
              <li
                className={clsx(styles.item, styles.sidebarItem, {
                  [styles.active]: currentValue?.value === value,
                })}
                onClick={() => onChange?.(navItem)}
                key={value}
              >
                {icon && <Icon name={icon} className={styles.icon} />}
                {label.toUpperCase()}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
)

export default SideBar
