import React from "react"

interface RadioCheckedIconProps {
  className?: string
  color?: string | null
}
const RadioCheckedIcon = ({ className = "", color }: RadioCheckedIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={32}
    fill="none"
    className={className}
  >
    <rect width={32} height={32} x={0.5} fill={color || "#17899E"} rx={16} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M24.5 16a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm2 0c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10Zm-10.592 3.67 6.333-7-1.482-1.34-5.645 6.237-2.924-2.79-1.38 1.446 3.666 3.5.743.71.69-.762Z"
      clipRule="evenodd"
    />
  </svg>
)
export default RadioCheckedIcon
