import React, { useCallback, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import moment from "moment"
import _uniqueId from "lodash/uniqueId"

import Box from "@components/ui/Box/Box"
import Label from "@components/ui/Label/Label"
import Button from "@components/ui/Button/Button"
import DatePicker from "@components/ui/DatePicker/DatePicker"
import { toTimeCondition } from "@store/account-rules/dataTransformer"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import useOptionList from "@framework/prototypes/useOptionList"
import {
  ConditionType,
  TimeConditionFormType,
} from "@framework/types/manageCampaign"
import CustomCampaignsTable from "../CustomCampaignsTable/CustomCampaignsTable"
import { campaignTypeOptions } from "../BudgetTypeRoleForm/options"

import styles from "./TimeTypeRoleForm.module.scss"

const defaultInitialValues: TimeConditionFormType = {
  trigger: "DATE",
  period: [moment().toDate(), moment().add(1, "day").toDate()],
  appliedArea: campaignTypeOptions[0].value,
  campaignIds: [],
}

type TimeTypeRoleFormProps = {
  onSubmit: (data: ConditionType) => any
  onCancel?: () => any
  defaultValue?: ConditionType
}

const TimeTypeRoleForm: React.FC<TimeTypeRoleFormProps> = observer(
  ({ defaultValue, onSubmit, onCancel }) => {
    const handleSubmit = useCallback(
      (data: TimeConditionFormType) =>
        onSubmit(
          toTimeCondition(
            defaultValue?.id ?? _uniqueId("new_"),
            data,
            defaultValue?.id === "OLD" ? "UPDATE" : "CREATE"
          )
        ),
      [defaultValue, onSubmit]
    )

    const initialValues = useMemo(
      () => (defaultValue ? defaultInitialValues : defaultInitialValues),
      [defaultValue]
    )

    const formik = useFormik<TimeConditionFormType>({
      initialValues,
      validateOnBlur: true,
      onSubmit: handleSubmit,
    })

    const handleSetDate = useCallback(
      (date: Date | Date[]) => {
        formik.setFieldValue("period", Array.isArray(date) ? date : "")
      },
      [formik.setFieldValue]
    )

    const handleChangeIds = useCallback(
      (data: string[]) => {
        formik.setFieldValue("campaignIds", data)
      },
      [formik.setFieldValue]
    )

    const campaignOptions = useOptionList(campaignTypeOptions)

    return (
      <FormikProvider value={formik}>
        <form className={styles.root}>
          <Box className={styles.shortRow}>
            <Label
              className={styles.field}
              label="Campaign period"
              error={formik.touched.period && formik.errors.period}
              floatingError
            >
              <DatePicker
                defValue={formik.values.period}
                onBlur={formik.handleBlur}
                onChange={handleSetDate}
                multiSelect
              />
            </Label>

            <FormSimpleSelectField
              className={styles.field}
              label="Applied to (Campaign Type) "
              name="appliedArea"
              {...campaignOptions}
            />
            <Button
              className={styles.rowButton}
              onClick={formik.isValid ? formik.submitForm : onCancel}
              variant="outlined"
              size="big"
            >
              {formik.isValid ? "Save" : "Cancel"}
            </Button>
          </Box>
          {formik.values.appliedArea === "CUSTOM" && (
            <CustomCampaignsTable onChange={handleChangeIds} />
          )}
        </form>
      </FormikProvider>
    )
  }
)

export default TimeTypeRoleForm
