import React from "react"
import { observer } from "mobx-react-lite"

import Table from "@components/ui/Table/Table"
import Row from "@components/ui/Table/Row"
import Column from "@components/ui/Table/Column"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import { ID } from "@framework/types/types"
import { useStore } from "@store/index"
import { mbNoData } from "@services/utils"
import { numberWithCommas } from "@utils/numberUtils"

import styles from "./index.module.scss"

export interface LocationTableProps {
  title: string
  list: ID[]
  onDelete?: (id: ID) => void
  onDeleteAll?: () => void
}

export const LocationTable: React.FC<LocationTableProps> = observer(
  ({ list, title, onDelete, onDeleteAll }) => {
    const { locationsStore: store } = useStore()

    return (
      <Typography type="body2" color="black100Color" className={styles.table}>
        <Table hideBorder>
          <Row>
            <Column variant="header" width="40%">
              <Typography type="caption3">
                {title} ({list.length})
              </Typography>
            </Column>
            <Column variant="header" width="50%" align="end">
              <Typography type="caption3">Reach</Typography>
            </Column>
            <Column variant="header" width="10%">
              <IconButton size="small" onClick={() => onDeleteAll?.()}>
                <Icon name="cross" />
              </IconButton>
            </Column>
          </Row>

          {list.map((id) => {
            const item = store.getById(id)
            return (
              <Row key={id}>
                <Column>{item?.name ?? "N/A"}</Column>
                <Column align="end">
                  {mbNoData(item?.reach, numberWithCommas)}
                </Column>
                <Column>
                  <IconButton size="small" onClick={() => onDelete?.(id)}>
                    <Icon name="cross" />
                  </IconButton>
                </Column>
              </Row>
            )
          })}
        </Table>
      </Typography>
    )
  }
)

export default LocationTable
