import React from "react"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"

import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Stepper, { StepOptionsProps } from "@components/ui/Stepper/Stepper"
import Templates from "@components/ui/Templates"
import { useAdCopyAIWizardContext } from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/AdCopyTable/AdCopyAIWizardContext"
import { adCopyPreviewsToDetails } from "@framework/prototypes/AdCopy/Preview/utils"
import { AdCopyDetailsData } from "@framework/types/adCopy"
import { DeepPartial } from "@framework/types/utils"
import ContentPreview from "./ContentPreview"
import Goals from "./Goals"
import RequiredSettings from "./RequiredSettings"
import AdvancedSettings from "./AdvancedSettings"

import styles from "./index.module.sass"

type AdCopyWizardModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit?: (data: DeepPartial<AdCopyDetailsData>) => void
  onCancel?: () => void
}

const AdCopyWizardModal: React.FC<AdCopyWizardModalProps> = observer(
  ({ isOpen, onClose, onCancel = onClose, onSubmit }) => {
    const wizardContext = useAdCopyAIWizardContext()

    const { completed, setCompleted } = wizardContext
    const { activeStep, setActiveStep } = wizardContext

    const handleSubmit = () => {
      try {
        if (
          wizardContext.settings == null ||
          wizardContext.generatedAdCopy == null
        )
          throw new Error("Form is not ready for submit")

        const adCopyFormData: DeepPartial<AdCopyDetailsData> = {
          ...adCopyPreviewsToDetails(wizardContext.generatedAdCopy),
          promptSettings: toJS(wizardContext.settings),
        }

        onSubmit?.(adCopyFormData)
      } catch (e) {
        wizardContext.setFatalError("Unexpected error")
      }
    }

    const setStep = React.useCallback(
      (step: StepOptionsProps) => {
        setActiveStep((prevStep) => {
          const index = steps.findIndex((item) => item.value === step.value)
          return index >= 0 ? index : prevStep
        })
      },
      [setActiveStep]
    )

    const nextStep = () => {
      if (steps.length <= activeStep + 1) {
        handleSubmit()
        return
      }

      setActiveStep((activeStep) => activeStep + 1)
    }

    const prevStep = () => {
      if (activeStep <= 0) {
        onCancel()
        return
      }

      setActiveStep((activeStep) => activeStep - 1)
    }

    const { Component } = steps[activeStep]

    const handleStepSubmit = () => {
      setCompleted((prev) => ({ ...prev, [activeStep]: true }))
      nextStep()
    }

    const isLastStep = activeStep + 1 >= steps.length

    const stepperNode = (
      <Stepper
        activeStep={activeStep}
        complete={completed}
        steps={steps}
        className={styles.stepper}
      />
    )

    return (
      <Modal
        className={styles.root}
        title="AI Helper"
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className={styles.body}>
          {Component != null && (
            <Component
              onSubmit={handleStepSubmit}
              onCancel={prevStep}
              headerSlot={stepperNode}
              controlSlot={
                <Templates.Header
                  offset="none"
                  left={
                    <Button onClick={prevStep} color="secondary">
                      Back
                    </Button>
                  }
                  right={
                    <Button type="submit" color="primary">
                      {isLastStep ? "Create" : "Continue"}
                    </Button>
                  }
                />
              }
            />
          )}
        </div>
      </Modal>
    )
  }
)

export default AdCopyWizardModal

export type FormComponentProps = {
  headerSlot?: React.ReactNode
  controlSlot?: React.ReactNode
  onSubmit: () => void
  onCancel?: () => void
}

export type StepOption = StepOptionsProps & {
  Component: React.FC<FormComponentProps>
  name: string
}

const steps: StepOption[] = [
  {
    value: "1",
    name: "goals",
    label: "Goals",
    Component: Goals,
  },
  {
    value: "2",
    name: "required-settings",
    label: "Required settings",
    Component: RequiredSettings,
  },
  {
    value: "3",
    name: "advanced-settings",
    label: "Advanced Settings",
    Component: AdvancedSettings,
  },
  {
    value: "4",
    name: "content-preview",
    label: "Content Preview",
    Component: ContentPreview,
  },
]
