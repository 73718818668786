import React, { useContext } from "react"

export interface SelectedCampaignsContextStore {
  selectedCampaigns: string[]
  setCampaigns: (value: string[]) => void
}

export const SelectedCampaignsContext =
  React.createContext<SelectedCampaignsContextStore | null>(null)

export const useSelectedCampaignsContext = () => {
  const context = useContext(SelectedCampaignsContext)
  if (context == null)
    throw new Error("Can't find SelectedCampaignsContext context")
  return context
}
