import React from "react"

import Typography from "@components/ui/Typography/Typography"

import styles from "./FieldRow.module.sass"

interface FieldRowProps {
  title: string
  subtitle: string
}

const FieldRow: React.FC<FieldRowProps> = ({ title, subtitle, children }) => (
  <div className={styles.root}>
    <div className={styles.label}>
      <Typography type="h4" weight="semibold">
        {title}
      </Typography>
      <Typography type="body2" color="black70Color">
        {subtitle}
      </Typography>
    </div>

    {children}
  </div>
)

export default FieldRow
