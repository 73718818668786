import { useMemo } from "react"

import { NavOption } from "@components/ui/Tabs/types"
import config from "@root/config"
import { useStore } from "@store/index"

const filterHiddenNavs = (list: NavOption[]) => list.filter((it) => !it.hidden)

export const useAccountNavItems = () => {
  const {
    productFeedStore: { GMC },
  } = useStore()

  return useMemo(() => {
    const navList: NavOption[] = []

    navList.push({
      label: "Account Dashboard",
      icon: "dashboard",
      value: "/dashboard",
    })

    navList.push({
      label: "Manage Profile",
      icon: "user",
      value: "/manage-profile",
    })

    navList.push({
      label: "Checkbook",
      icon: "bill-line",
      value: "/checkbook",
      alter: "checkbook",
    })

    if (!config.HIDE_SHOPIFY && GMC.isConnected)
      navList.push({
        label: "Product Feed",
        icon: "list-check",
        value: "/product-feed",
        exact: true,
      })

    navList.push({
      label: "Channels",
      icon: "archive-drawer",
      value: "/channels",
    })

    navList.push({
      label: "Reports",
      icon: "file-chart",
      value: "/reports",
      hidden: !!config.HIDE_REPORTS,
    })

    return navList
  }, [GMC.isConnected])
}

export const accountSidebarNavItems: NavOption[] = filterHiddenNavs([
  {
    label: "Account",
    icon: "hotel-fill",
    value: "/manage-profile",
  },
  {
    label: "Dashboard",
    icon: "dashboard-fill",
    value: "/dashboard",
  },
  {
    label: "Channels",
    icon: "archive-drawer-fill",
    value: "/channels",
  },
  {
    label: "Checkbook",
    icon: "bill-fill",
    value: "/checkbook",
    alter: "checkbook",
  },
  {
    label: "Reports",
    icon: "file-chart-fill",
    value: "/reports",
    hidden: !!config.HIDE_REPORTS,
  },
])

export const accountNavMenuItems: NavOption[] = filterHiddenNavs([
  { label: "Account Dashboard", icon: "dashboard", value: "/dashboard" },
  { label: "Opportunities", icon: "bag", value: "/opportunities" },
  { label: "Manage Campaign", icon: "megaphone", value: "/campaign" },
  { label: "Checkbook", icon: "checkbook", value: "/checkbook" },
  { label: "Manage Profile", icon: "user", value: "/profile" },
  { label: "Rules", icon: "hummer", value: "/rules" },
])

export const accountListNavItems: NavOption[] = filterHiddenNavs([
  { label: "Account List", icon: "hotel-fill", value: "/account-list" },
  { label: "Dashboard", icon: "dashboard-fill", value: "/dashboard" },
  { label: "Profile", icon: "user-fill", value: "/profile" },
  { label: "Checkbook", icon: "bill-fill", value: "/checkbook" },
])

/**
 * @deprecated
 */
export const agencyListNavItems: NavOption[] = filterHiddenNavs([
  { value: "/agency", label: "Agency", icon: "building" },
  { value: "/report", label: "Report", icon: "statistics" },
  { value: "/settings", label: "Settings", icon: "settings" },
  { value: "/users", label: "Users", icon: "user" },
])

export const clientListNavItems: NavOption[] = filterHiddenNavs([
  { label: "Account List", icon: "hotel-fill", value: "/accounts" },
  { label: "Dashboard", icon: "dashboard-fill", value: "/dashboard" },
  { label: "Checkbook", icon: "bill-fill", value: "/checkbook" },
  { label: "Profile", icon: "user-fill", value: "/profile" },
  {
    label: "Reports",
    icon: "file-chart-fill",
    value: "/reports",
    hidden: !!config.HIDE_REPORTS,
  },
])

export const paidSearchNavItems: NavOption[] = filterHiddenNavs([
  {
    label: "Dashboard",
    icon: "dashboard",
    value: "/dashboard",
  },
  {
    label: "Ad Copy Management",
    icon: "folder-settings",
    value: "/manage-ad-copy",
  },
  {
    label: "Manage Channel",
    icon: "home-gear",
    value: "/manage-profile",
  },
  {
    label: "Checkbook",
    icon: "bill-line",
    value: "/manage-profile?alter=checkbook",
    alter: "checkbook",
  },
  { label: "Opportunities", icon: "lightbulb", value: "/opportunities" },
  { label: "Rules", icon: "auction", value: "/rules", disabled: true },
  {
    label: "Manage Campaign",
    icon: "focus-line",
    value: "/manage-campaign",
  },
])
