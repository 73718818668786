import React from "react"
import { observer } from "mobx-react-lite"
import * as yup from "yup"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import { FormikProvider, useFormik } from "formik"
import FormTextField from "@framework/prototypes/FormTextField"
import { useAdCopyAIWizardContext } from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/AdCopyTable/AdCopyAIWizardContext"
import FieldRow from "../components/FieldRow"
import { FormComponentProps } from "."
import { requiredSettingsValidationSchema as validationSchema } from "./validation"

import styles from "./RequiredSettings.module.sass"

type FormData = yup.InferType<typeof validationSchema>

type RequiredSettingsProps = {} & FormComponentProps

const RequiredSettings: React.FC<RequiredSettingsProps> = observer(
  ({ headerSlot, controlSlot, onSubmit }) => {
    const wizardContext = useAdCopyAIWizardContext()

    const handleSubmit = (data: FormData) => {
      wizardContext.updateSettings(data)

      onSubmit()
    }

    const formik = useFormik<FormData>({
      initialValues: validationSchema.cast(wizardContext.settings ?? {}, {
        stripUnknown: true,
      }),
      validationSchema,
      onSubmit: handleSubmit,
    })

    return (
      <FormikProvider value={formik}>
        <form className={styles.root} onSubmit={formik.handleSubmit}>
          {headerSlot}

          <div className={styles.header}>
            <Typography type="h4" weight="bold">
              Fill Required Settings
            </Typography>
          </div>

          <div className={styles.body}>
            <Stack gutter="32" align="stretch">
              <FieldRow
                title="Set an URL"
                subtitle="Send your customers to the exact page they can take action"
              >
                <FormTextField
                  name="finalUrl"
                  placeholder="Paste URL AdCopy must lead to"
                />
              </FieldRow>

              <FieldRow
                title="What product(s) are you selling?"
                subtitle="Include as many general products"
              >
                <FormTextField
                  name="products"
                  placeholder="Type your products"
                />
              </FieldRow>

              <FieldRow
                title="Tell about your product(s)"
                subtitle="What are the unique selling points and/or features of your products?"
              >
                <FormTextField
                  name="productDescription"
                  placeholder="Type the unique selling points"
                />
              </FieldRow>

              <FieldRow
                title="Provide a company description"
                subtitle="Tell customers why they should choose you"
              >
                <FormTextField
                  name="companyDescription"
                  placeholder="Type the company description"
                />
              </FieldRow>
            </Stack>
          </div>

          {controlSlot}
        </form>
      </FormikProvider>
    )
  }
)

export default RequiredSettings
