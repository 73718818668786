/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import clsx from "clsx"
import { useFormikContext } from "formik"

import { MonthlyAmountMap } from "@store/checkbook/checkbook"
import Typography from "@components/ui/Typography/Typography"
import { preciseMoneyFormatter } from "@services/utils"
import FormNumberField from "@framework/prototypes/FormNumberField"

import styles from "./AmountFiledColumn.module.scss"

export type AmountFiledColumnProps = {
  name: string
  initialValue?: string
  className?: string
}

export const AmountFiledColumn: React.FC<AmountFiledColumnProps> = React.memo(
  ({ className, name, initialValue }) => {
    const formik = useFormikContext<MonthlyAmountMap>()

    const isEditable = formik.values[name] !== undefined

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.col}>
          {isEditable ? (
            <FormNumberField
              name={name}
              decimalScale={2}
              thousandSeparator
              floatingError
              prefix="$"
            />
          ) : (
            <Typography type="h5" className={styles.notEditable}>
              {initialValue ? preciseMoneyFormatter(initialValue) : "-"}
            </Typography>
          )}
        </div>
      </div>
    )
  }
)

export default AmountFiledColumn
