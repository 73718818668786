import React, { useState, useRef, useEffect } from "react"
import clsx from "clsx"
import { Option } from "@framework/types/types"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import ContextMenu from "@components/ui/ContextMenu/ContextMenu"
import { NavOption } from "@components/ui/Tabs/types"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import { numberWithCommas } from "@components/utils/numberUtils"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import { capitalize } from "lodash"
import { Channel } from "@store/account-linking/account-linking"
import { AccountData } from "@framework/types/account"
import { observer } from "mobx-react-lite"
import ConnectedChannels from "../ConnectedChannels/ConnectedChannels"

import styles from "./ListItem.module.scss"

type ListItemProps = {
  className?: string
  profileName?: string
  industry?: string
  totalAdSpend?: number
  profileStatus?: string
  profileCompetition?: number
  campaignStatus?: string
  options: NavOption[]
  onAction: (name: Option) => void
  checked?: boolean
  metaCredentials?: AccountData["metaCredentials"]
  onChange?: () => void
}

const ListItem: React.FC<ListItemProps> = observer(
  ({
    className,
    profileName,
    industry,
    totalAdSpend,
    profileStatus,
    profileCompetition,
    campaignStatus,
    options,
    checked,
    metaCredentials,
    onAction,
    onChange,
  }) => {
    const rootNode = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleClick = (e: MouseEvent) => {
      if (rootNode.current && rootNode.current.contains(e.target as Node)) {
        return
      }
      setIsOpen(false)
    }

    const handleOpenMenu = () => {
      setIsOpen((prev) => !prev)
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClick)

      return () => {
        document.removeEventListener("mousedown", handleClick)
      }
    }, [])

    const channelOptions: Channel[] = React.useMemo(() => {
      const res = [
        {
          label: "Google Ads",
          value: "googleAds",
          iconSrc: "/images/googleAdwords.svg",
        },
      ]
      if (metaCredentials?.accountId)
        res.push({
          label: "Facebook Ads",
          value: "facebookAds",
          iconSrc: "/images/facebook_icon.svg",
        })
      return res
    }, [metaCredentials])

    return (
      <Box className={clsx(styles.root, className)} ref={rootNode}>
        <CheckBox checked={checked} onChange={onChange} />

        <div className={styles.wrapper}>
          <Tooltip text={profileName} type="h5">
            <Typography
              type="h5"
              color="dark"
              className={styles.clickable}
              onClick={() => onAction(options[0])}
            >
              {profileName}
            </Typography>
          </Tooltip>

          <Typography type="h5" color="dark">
            {industry}
          </Typography>

          <Typography type="h5" color="dark">
            {`$${numberWithCommas(totalAdSpend)}`}
          </Typography>

          <Typography type="h5" color="dark">
            {mapStatus(profileStatus)}
          </Typography>

          <Typography type="h5" color="dark">
            {profileCompetition}
          </Typography>

          <ConnectedChannels items={channelOptions} />

          <Typography type="h5" color="dark">
            {mapStatus(campaignStatus)}
          </Typography>

          <IconButton
            size="small"
            variant="ghost"
            color={isOpen ? "default" : "primary"}
            onClick={handleOpenMenu}
          >
            <Icon name="three-dots" />
          </IconButton>
        </div>

        {isOpen && (
          <ContextMenu
            options={options}
            onClick={onAction}
            className={styles.contextMenu}
          />
        )}
      </Box>
    )
  }
)

export default ListItem

const mapStatus = (status?: string) => {
  if (status == null) return "-"
  if (status === "ENABLED") return "Active"
  return capitalize(status.toLowerCase())
}
