import React from "react"
import { useStore } from "@store/index"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Table from "@components/ui/Table/Table"
import { useEditorFormRedirect } from "@pages/PMax/ActiveSectionContext"
import Cortege from "./Cortege"
import styles from "./FormSummary.module.scss"

export const SettingsTable: React.FC = () => {
  const { languagesStore, editPMaxCampaignStore: store } = useStore()

  const renderLocations = () => {
    const { locationTarget, locationIds, excludeLocationIds } = store.data

    if (locationTarget === "ALL") return "All countries and territories"
    if (locationTarget === "USA_CANADA") return "United States and Canada"
    if (locationTarget === "USA") return "United States"

    if (locationIds.length && excludeLocationIds.length)
      return (
        <>
          {locationIds.length
            ? `Targeted: ${locationIds.length} locations`
            : null}
          , &nbsp;
          {excludeLocationIds.length
            ? `Excluded: ${excludeLocationIds.length} locations`
            : null}
        </>
      )
    if (locationIds.length) return `Targeted: ${locationIds.length} locations`

    if (excludeLocationIds.length)
      return `Excluded: ${excludeLocationIds.length} locations`

    return "-"
  }

  const renderLanguages = () => {
    const { languageIds } = store.data

    if (languageIds.length === 0) return "N/A"

    if (
      languagesStore.data != null &&
      languageIds.length === languagesStore.data?.length
    )
      return "All"

    const langs = languageIds.map(
      (it: string) => languagesStore.findById(it)?.name ?? `Unknown (${it})`
    )

    return `${langs.slice(0, 3).join(", ")} ${
      langs.length > 3 ? ` and ${langs.length - 3} mode` : ""
    }`
  }

  const editLocations = useEditorFormRedirect("settings", "locations")
  const editLanguages = useEditorFormRedirect("settings", "languages")

  return (
    <Stack gutter="16" direction="column">
      <Typography type="h2">Campaign settings</Typography>

      <Box className={styles.section}>
        <Table>
          <Cortege label="Locations" onEdit={editLocations}>
            {renderLocations()}
          </Cortege>

          <Cortege label="Languages" onEdit={editLanguages}>
            {renderLanguages()}
          </Cortege>
        </Table>
      </Box>
    </Stack>
  )
}

export default SettingsTable
