/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable } from "mobx"
import _sortBy from "lodash/sortBy"
import {
  KeywordCategoryGroup,
  RecommendedKeyword,
} from "@framework/types/opportunities"
import { ID, RangeType, SortByType } from "@framework/types/types"
import { formatCompetition } from "@pages/Opportunities/components/RecommendedKeywords/Table/utils"
import { groupByCategory, transformEstimate } from "./opportunities.transformer"
import Calculator from "./calculator"
import AttributeChart from "./attributeChart"
import KeywordsFilterStore from "./filter"
import SearchReportStore from "./search-report"
import KeywordsListStore from "./keywords-list"
import ShoppingCartStore from "./shopping-cart"
import OpportunitiesViewStore from "./opportunities-view"

const searchFilter = (
  query: string,
  value: RecommendedKeyword | KeywordCategoryGroup
) =>
  !query ||
  value.displayName.toLocaleLowerCase().includes(query.toLocaleLowerCase())

const listFilter = <T,>(list: T[], value: T) =>
  !list.length || list.includes(value)

const filterByStatus = (list: ID[], value: RecommendedKeyword) =>
  !list.length ||
  (list.includes("active") && value.active) ||
  (list.includes("not-active") && !value.active)

const filterByCompetitive = (list: ID[], value: RecommendedKeyword) =>
  !list.length || list.includes(formatCompetition(value.competition))

const filterByCPC = (range: RangeType = {}, value: RecommendedKeyword) => {
  const { avgCpc } = value
  const { min, max } = range
  if (avgCpc == null) return true
  return (
    (min != null ? min <= avgCpc : true) && (max != null ? max >= avgCpc : true)
  )
}

class OpportunitiesStore {
  @observable selectedMonths: string[] = []

  @observable searchFiler: string = ""

  @observable usedMonths: string[] = ["Jan", "Apr", "Dec"]

  @observable calculator: Calculator

  @observable attributeChartStore: AttributeChart

  @observable keywordsFilter: KeywordsFilterStore

  @observable searchReport: SearchReportStore

  @observable keywordsList: KeywordsListStore

  @observable shoppingCart: ShoppingCartStore

  @observable view: OpportunitiesViewStore

  @computed get recommendedKeywords(): RecommendedKeyword[] {
    return this.keywordsList.recommendedKeywords
  }

  @observable sortBy: SortByType

  // constructor

  constructor(config?: { defaultSortBy?: SortByType }) {
    this.keywordsFilter = new KeywordsFilterStore(this)
    this.attributeChartStore = new AttributeChart(this)
    this.calculator = new Calculator()
    this.searchReport = new SearchReportStore()
    this.keywordsList = new KeywordsListStore()
    this.shoppingCart = new ShoppingCartStore({
      calculatorStore: this.calculator,
      keywordsList: this.keywordsList,
    })
    this.view = new OpportunitiesViewStore()
    this.sortBy = config?.defaultSortBy ?? {
      value: "none",
      direction: false,
    }
  }

  // computed

  @computed get totalSearchVolumeAvailable(): number {
    return this.recommendedKeywords.reduce(
      (acc, item) => acc + item.avgSearchVolume,
      0
    )
  }

  @computed get totalMonths() {
    return this.selectedMonths.length
  }

  @computed get categoryGroups() {
    return groupByCategory(this.recommendedKeywords)
  }

  @computed get keywordsWithLocations() {
    return this.recommendedKeywords.filter(({ distance }) => distance > -1)
  }

  @computed get filteredCategories(): KeywordCategoryGroup[] {
    const { keywordsFilter, categoryGroups, searchFiler } = this
    const categoryFilter = keywordsFilter.group.byCategories.active
    const statusFilter = keywordsFilter.group.byAttributes.group.status.active
    const competitiveFilter =
      keywordsFilter.group.byAttributes.group.competitive.active
    const cpcFilter = keywordsFilter.group.byAttributes.group.cpc.active

    return categoryGroups
      .map((category) => ({
        ...category,
        keywords: category.keywords.filter(
          (keyword) =>
            // search
            searchFilter(searchFiler, keyword) &&
            // by status
            filterByStatus(statusFilter, keyword) &&
            // by competition
            filterByCompetitive(competitiveFilter, keyword) &&
            // byCpc
            filterByCPC(cpcFilter, keyword)
        ),
      }))
      .filter((category) =>
        searchFiler
          ? category.keywords.length > 0 || searchFilter(searchFiler, category)
          : category.keywords.length > 0 &&
            // by category
            listFilter(categoryFilter, category.id.toString())
      )
  }

  @computed get filteredKeywords() {
    const { keywordsFilter, recommendedKeywords, searchFiler } = this
    const categoryFilter = keywordsFilter.group.byCategories.active
    const statusFilter = keywordsFilter.group.byAttributes.group.status.active
    const competitiveFilter =
      keywordsFilter.group.byAttributes.group.competitive.active
    const cpcFilter = keywordsFilter.group.byAttributes.group.cpc.active

    return recommendedKeywords.filter(
      (keyword) =>
        // search
        searchFilter(searchFiler, keyword) &&
        // by category
        listFilter(categoryFilter, keyword.category.id.toString()) &&
        // by status
        filterByStatus(statusFilter, keyword) &&
        // by competition
        filterByCompetitive(competitiveFilter, keyword) &&
        // byCpc
        filterByCPC(cpcFilter, keyword)
    )
  }

  @computed get sortedCategories(): KeywordCategoryGroup[] {
    const { filteredCategories, sortBy } = this

    const sortingKey =
      sortBy.value === "estimate"
        ? (data: RecommendedKeyword | KeywordCategoryGroup) =>
            transformEstimate(data, 1)
        : sortBy.value

    const withSortedAttributes: KeywordCategoryGroup[] = filteredCategories.map(
      (category) => ({
        ...category,
        keywords: sortBy.direction
          ? _sortBy(category.keywords, sortingKey)
          : _sortBy(category.keywords, sortingKey).reverse(),
      })
    )

    return sortBy.direction
      ? _sortBy(withSortedAttributes, sortingKey)
      : _sortBy(withSortedAttributes, sortingKey).reverse()
  }

  @computed get sortedKeywords() {
    const { filteredKeywords, sortBy } = this

    const sortingKey =
      sortBy.value === "estimate"
        ? (data: RecommendedKeyword) => transformEstimate(data, 1)
        : sortBy.value

    return sortBy.direction
      ? _sortBy(filteredKeywords, sortingKey)
      : _sortBy(filteredKeywords, sortingKey).reverse()
  }

  // actions

  @action setSortBy = (item: string) => {
    const newValue = {
      value: item,
      direction: !this.sortBy.direction,
    }
    this.sortBy = newValue
  }

  @action setSearchFilter = (value: string) => {
    this.searchFiler = value
  }

  @action setSelectedMonths = (months: string[]) => {
    this.selectedMonths = months
  }
}

export default OpportunitiesStore
