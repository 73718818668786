import clsx from "clsx"
import React, { useRef } from "react"

import { RecommendedKeyword } from "@framework/types/opportunities"
import { useStore } from "@store/index"
import Typography from "@components/ui/Typography/Typography"
import {
  useOpportunitiesController,
  useOpportunitiesStore,
} from "@store/opportunities/provider"
import { observer } from "mobx-react-lite"
import KeywordItem from "./KeywordItem"

import styles from "./KeywordsList.module.scss"

export type KeywordsListProps = {
  keywords?: RecommendedKeyword[]
  selected: any
  handleSelect: (item: any) => void
}

export const KeywordsList: React.FC<KeywordsListProps> = observer(
  ({ keywords = [], selected, handleSelect }) => {
    const selectedRef = useRef<HTMLDivElement>()

    const { opportunities } = useOpportunitiesController()
    const opportunitiesStore = useOpportunitiesStore()
    const { accountStore } = useStore()
    const { accountId } = accountStore

    const handleClick = (id: number) => {
      if (accountId) opportunities.switchKeyword(accountId, id)
    }

    const setRef = (ref: HTMLDivElement) => {
      selectedRef.current = ref
      ref?.scrollIntoView({ block: "center", behavior: "smooth" })
    }

    return (
      <div className={clsx(styles.root, styles.thinScrollbar)}>
        <div className={styles.header}>
          <Typography type="h6" upperCase color="gray">
            Keywords
          </Typography>
          <Typography type="h6" upperCase color="gray">
            Category
          </Typography>
          <Typography type="h6" upperCase color="gray">
            Search Volume
          </Typography>
          <Typography type="h6" upperCase color="gray">
            Distance (miles)
          </Typography>
          <Typography type="h6" upperCase color="gray">
            Status
          </Typography>
        </div>
        <div className={styles.list}>
          {keywords.map((item) => {
            const isSelected = selected && selected.id === item.id
            const isActive = opportunitiesStore.shoppingCart.isSelected(item.id)
            return (
              <KeywordItem
                item={item}
                isActive={isActive}
                isSelected={isSelected}
                handleSelect={handleSelect}
                handleAdd={handleClick}
                setRef={isSelected ? setRef : undefined}
                key={item.id}
              />
            )
          })}
        </div>
      </div>
    )
  }
)

export default KeywordsList
