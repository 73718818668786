import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { CollapsibleHeader } from "@components/ui/Collapsible/CollapsibleHeader"
import useToggle from "@framework/hooks/useToggle"
import { Collapsable } from "@components/ui/Collapsible/Collapsable"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import { useFormikContext } from "formik"
import { EditAssetGroupFormValues } from "@pages/PMax/components/EditAssetGroupPage/EditAssetGroupForm"
import { CallToActionTypes } from "@framework/types/manageCampaign"
import styles from "./Assets.module.scss"

const CALL_TO_ACTION: Record<CallToActionTypes, string> = {
  UNSPECIFIED: "Unspecified",
  UNKNOWN: "Unknown",
  LEARN_MORE: "Learn More",
  GET_QUOTE: "Get Quote",
  APPLY_NOW: "Apply Now",
  SIGN_UP: "Sign Up",
  CONTACT_US: "Contact Us",
  SUBSCRIBE: "Subscribe",
  DOWNLOAD: "Download",
  BOOK_NOW: "Book Now",
  SHOP_NOW: "Shop Now",
  BUY_NOW: "Buy Now",
  DONATE_NOW: "Donate Now",
  ORDER_NOW: "Order Now",
  PLAY_NOW: "Play Now",
  SEE_MORE: "See More",
  START_NOW: "Start Now",
  VISIT_SITE: "Visit Site",
  WATCH_NOW: "Watch Now",
}

const optionsArray: CallToActionTypes[] = Object.keys(
  CALL_TO_ACTION
) as CallToActionTypes[]

const CallToAction: FC = observer(() => {
  const toggle = useToggle()
  const [option, setOption] = useState<CallToActionTypes>("UNSPECIFIED")
  const { values, setFieldValue } = useFormikContext<EditAssetGroupFormValues>()

  useEffect(() => {
    if (values.callToActionType) {
      setOption(values.callToActionType)
    }
  }, [values.callToActionType])

  const handleSelectChange = (value: CallToActionTypes) => {
    setOption(value)
    setFieldValue("callToActionType", value)
  }

  return (
    <div className={styles.section}>
      <CollapsibleHeader
        label="Call to action"
        onClick={toggle.handleToggle}
        open={toggle.isOpened}
        type="h2"
        rootClassName={styles.collapsibleHeader}
      />
      <Collapsable open={toggle.isOpened}>
        <SimpleSelect
          renderValue={(value: CallToActionTypes) => CALL_TO_ACTION[value]}
          value={option}
          options={optionsArray}
          onSelect={(value) => handleSelectChange(value)}
        />
      </Collapsable>
    </div>
  )
})

export default CallToAction
