import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Stack from "@components/ui/Stack/Stack"
import { useEditAdCopyContext } from "./EditAdCopyContext"

import styles from "./EditAdCopyForm.module.scss"

interface FooterProps {
  onRegenerate?: React.MouseEventHandler
}

export const Footer: React.FC<FooterProps> = observer(({ onRegenerate }) => {
  const context = useEditAdCopyContext()

  return (
    <Templates.Header
      offset="none"
      className={styles.footer}
      left={
        <Stack direction="row">
          <Button color="secondary" onClick={context.onClose}>
            Cancel
          </Button>
          {context.isEdit && (
            <Button
              color="secondary"
              endIcon={{ name: "trash" }}
              onClick={context.onDelete}
            >
              {context.isBulk ? "Bulk delete" : "Delete"}
            </Button>
          )}

          {!context.isBulk && (
            <Button
              color="secondary"
              endIcon={{ name: "gear" }}
              onClick={onRegenerate}
            >
              Regenerate Ad Copy
            </Button>
          )}
        </Stack>
      }
      right={
        <Button type="submit" color="primary">
          {context.submitLabel}
        </Button>
      }
    />
  )
})

export default Footer
