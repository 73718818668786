import { observable, action, computed } from "mobx"
import sortBy from "lodash/sortBy"
import get from "lodash/get"
import identity from "lodash/identity"

import RootStore from "@store/RootStore"
import InfiniteSelected from "@store/common/InfiniteSelected"
import { Frame } from "./types"

export class ActiveAdCopiesStore {
  root: RootStore

  @computed get adCopyStore() {
    return this.root.adCopyStore
  }

  constructor(root: RootStore) {
    this.root = root
    this.selectedCampAdGroups = new Map()
    this.selectedCopies = new InfiniteSelected({ total: 0 })
    this.allCampaignAdCopiesIndex = {}
    this.frameCopiesGroups = []
    this.frame = null
    this.total = 0
  }

  @action resetSelected = () => {
    this.selectedCampAdGroups = new Map()
    this.selectedCopies = new InfiniteSelected({ total: 0 })
    this.allCampaignAdCopiesIndex = {}
    this.frameCopiesGroups = []
    this.frame = null
    this.total = 0
  }

  @observable selectedCampAdGroups: Map<string, Set<string>>

  @observable selectedCopies: InfiniteSelected

  @observable allCampaignAdCopiesIndex: Record<string, string[]>

  @observable frameCopiesGroups: [string, string[]][]

  @observable total: number

  @observable frame: Frame | null

  @computed get activeCampaigns() {
    return Array.from(this.selectedCampAdGroups.keys())
  }

  @action updateActiveCampaignCopiesIndex = () => {
    this.allCampaignAdCopiesIndex =
      this.adCopyStore.copiesCollection.adCopies.reduce<
        Record<string, string[]>
      >((acc, copyId) => {
        const copy = this.adCopyStore.copiesCollection.getById(copyId)
        if (copy == null) return acc
        acc[copy.campaignID] = acc[copy.campaignID] ?? []
        acc[copy.campaignID].push(copy.id)
        return acc
      }, {})
    this.total = this.adCopyStore.copiesCollection.adCopies.length
    this.selectedCopies = new InfiniteSelected({ total: this.total })
  }

  @action updateGroups = async (newFrame: Frame | null = this.frame) => {
    if (newFrame == null) {
      this.frameCopiesGroups = []
    } else {
      this.frameCopiesGroups = newFrame.groups.map((it) => {
        const copies = this.allCampaignAdCopiesIndex[it.id]
        const sortedSlice = this.sortCopies(copies).slice(it.start, it.end)
        return [it.id, sortedSlice]
      })
    }
    this.frame = newFrame
  }

  sortCopies = (copies: string[]) => {
    const { order } = this.adCopyStore.header
    const sortAttribute = order.value === "none" ? "name" : order.value
    const sortedSlice = sortBy(copies, (copyId) =>
      get(this.adCopyStore.getAdCopyByLocalID(copyId), sortAttribute)
    )

    return order.direction ? sortedSlice : sortedSlice.reverse()
  }

  @computed get selectedAdGroups() {
    const { selectedCampAdGroups: selectedAdGroups } = this
    return Array.from(selectedAdGroups.values()).flatMap((it) =>
      Array.from(it.values())
    )
  }

  @computed get isAllAdGroupsSelected() {
    const { selectedCampAdGroups: selectedAdGroups, adCopyStore } = this
    return (campaignId: string) => {
      const campAdGroups = adCopyStore.getCampaignByID(campaignId)?.groupIds
      const adGroupsSet = selectedAdGroups.get(campaignId)
      return (
        !!(campAdGroups && adGroupsSet) &&
        campAdGroups.length === adGroupsSet.size
      )
    }
  }

  @computed get isAnyAdGroupSelected() {
    const { selectedCampAdGroups: selectedAdGroups } = this
    return (campaignId: string) => {
      const adGroupsSet = selectedAdGroups.get(campaignId)
      return !!((adGroupsSet?.size ?? 0) > 0)
    }
  }

  @computed get isAdGroupSelected() {
    const { selectedCampAdGroups: selected, adCopyStore } = this
    return (adGroupId: string) => {
      const addGroups = adCopyStore.getAdGroupByID(adGroupId)
      if (!addGroups) return false
      const selectedAdGroups = selected.get(addGroups.campaignId)
      return !!selectedAdGroups?.has(adGroupId)
    }
  }

  @action selectCampaign = (campaignId: string, value: boolean) => {
    const camp = this.adCopyStore.getCampaignByID(campaignId)
    if (!camp?.groupIds?.length) throw new Error("NONE_AD_GROUPS")
    if (!value) this.selectedCampAdGroups.delete(campaignId)
    else this.selectedCampAdGroups.set(campaignId, new Set(camp.groupIds))
  }

  @action selectAdGroup = (adGroupId: string, value: boolean) => {
    const adGroup = this.adCopyStore.getAdGroupByID(adGroupId)
    if (!adGroup?.campaignId) return

    const selectedSet = this.selectedCampAdGroups.get(adGroup.campaignId)
    if (value) {
      if (selectedSet) selectedSet.add(adGroupId)
      else
        this.selectedCampAdGroups.set(adGroup.campaignId, new Set([adGroupId]))
    } else if (selectedSet) {
      if (selectedSet.size > 1) selectedSet.delete(adGroupId)
      else this.selectedCampAdGroups.delete(adGroup.campaignId)
    }
  }

  calcFrame = (page: number, pageSize: number): Frame => {
    const {
      activeCampaigns,
      allCampaignAdCopiesIndex: allSelectedAdCopyGroups,
      total,
    } = this
    const startIdx = page * pageSize

    let skipped = 0
    let leftToAdd = pageSize

    const groups: Frame["groups"] = []

    for (let i = 0; i < activeCampaigns.length && leftToAdd > 0; i += 1) {
      const campId = activeCampaigns[i]
      const list = allSelectedAdCopyGroups[campId] ?? []

      if (skipped + list.length > startIdx) {
        const start = startIdx > skipped ? startIdx - skipped : 0
        const end = start + Math.min(list.length - start, leftToAdd)

        const length = end - start
        if (length) {
          groups.push({ id: campId, start, end })
          leftToAdd -= length
        }
      }
      skipped += list.length
    }

    return {
      groups,
      total,
      page,
      pageSize,
      chunkSize: pageSize - leftToAdd,
    }
  }

  getSelectedAdCopyList = async () =>
    Object.values(this.allCampaignAdCopiesIndex).flatMap<string>(identity)

  getSelectedAdCopies = async () => {
    const allAdCopies = await this.getSelectedAdCopyList()
    return allAdCopies.filter((it) => this.selectedCopies.isSelected(it))
  }
}

export default ActiveAdCopiesStore
