import React from "react"
import { SortByType } from "@framework/types/types"
import { searchAds, sortAdReports } from "@pages/ManageCampaign/utils"
import { AdsReportType } from "@framework/types/account"

const emptyArray: AdsReportType[] = []

export const useSortedAds = (config: {
  ads: AdsReportType[] | null
  sortBy: SortByType
  searchQuery?: string
}) => {
  const { ads, sortBy, searchQuery } = config

  const [inProgress, setInProgress] = React.useState(false)

  const [sortedAds, setSortedAds] = React.useState<AdsReportType[]>(emptyArray)

  const update = async (
    campaignList: AdsReportType[],
    sortBy?: SortByType,
    searchQuery?: string
  ) => {
    setInProgress(true)

    const filtered = await searchCampaigns(campaignList, searchQuery)
    const res = await sortCampaigns(filtered, sortBy)

    setSortedAds(res)
    setInProgress(false)
  }

  React.useEffect(() => {
    update(ads ?? emptyArray, sortBy, searchQuery)
  }, [ads, sortBy, searchQuery])

  return { sortedKeywords: sortedAds, inProgress }
}

const sortCampaigns = async (ads: AdsReportType[], sortBy?: SortByType) => {
  if (ads.length === 0) return ads

  const res = await sortAdReports(ads, sortBy)
  return res
}

const searchCampaigns = async (
  ads: AdsReportType[],
  searchQuery: string = ""
) => {
  const res = await searchAds(ads, searchQuery)
  return res
}

export default useSortedAds
