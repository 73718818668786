import React from "react"
import { observer } from "mobx-react-lite"

import { printDateRangeWithDiff, periodLength } from "@utils/date"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import DatePeriodOption from "@components/ui/DatePicker/DatePeriodOption"
import Select from "@components/ui/DropDown"
import { mbNoData } from "@services/utils"
import { Button } from "../Button"
import Icon from "../Icon/Icon"

import styles from "./ButtonDatePicker.module.scss"

export interface ButtonDatePickerProps {
  maxDate?: Date
  minDate?: Date
  range: DateRangeOption
  rangeOptions: DateRangeOption[]
  onRangeChange?: (option: DateRangeOption) => void
}

export const ButtonDatePicker: React.FC<ButtonDatePickerProps> = observer(
  ({ range: currPeriod, rangeOptions, minDate, maxDate, onRangeChange }) => (
    <Select.DropDown
      keepOpened
      target={(isActive) => (
        <Button
          before={<Icon name="calendar" />}
          color="secondary"
          variant={isActive ? "outlined" : "contained"}
        >
          {currPeriod?.range
            ? `${mbNoData(currPeriod?.range, (v) =>
                periodLength(v, "day")
              )} Days`
            : "Select date..."}
        </Button>
      )}
    >
      <Select.Box className={styles.box}>
        <Select.Group>
          <DatePeriodOption
            title="Date range"
            subtitle={
              currPeriod ? printDateRangeWithDiff(currPeriod.range) : "-"
            }
            value={currPeriod?.value}
            onSelect={(v) => onRangeChange?.(v)}
            options={rangeOptions}
            minDate={minDate}
            maxDate={maxDate}
          >
            {mbNoData(currPeriod?.label)}
          </DatePeriodOption>
        </Select.Group>
      </Select.Box>
    </Select.DropDown>
  )
)

export default ButtonDatePicker
