import React, { useCallback, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"

import Box from "@components/ui/Box/Box"
import Label from "@components/ui/Label/Label"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import { useStore } from "@store/index"
import Icon from "@components/ui/Icon/Icon"
import { mbNoData } from "@services/utils"
import NoData from "@components/ui/NoData/NoData"
import { toTypeConditionsList } from "@store/account-rules/dataTransformer"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import FormTextField from "@framework/prototypes/FormTextField"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import useOptionList from "@framework/prototypes/useOptionList"
import {
  BaseConditionType,
  BudgetConditionType,
  ConditionType,
} from "@framework/types/manageCampaign"
import {
  conditionFrequencyOptions,
  ruleTypeOptions,
} from "./components/BudgetTypeRoleForm/options"
import TimeTypeRoleForm from "./components/TimeTypeRoleForm/TimeTypeRoleForm"
import BudgetTypeRoleForm from "./components/BudgetTypeRoleForm/BudgetTypeRoleForm"
import { budgetRuleMapper, timeRuleMapper } from "../../mock"

import styles from "./EditRule.module.scss"

const defaultInitialForm: RuleFormType = {
  name: "",
  description: "",
  active: true,
  conditions: {},
}

type RuleFormType = {
  name: string
  description: string
  active: boolean
  conditions: { [key: string]: ConditionType }
}

interface EditRuleProps {}

const EditRule: React.FC<EditRuleProps> = observer(() => {
  const { rulesStore, accountStore } = useStore()

  const {
    ruleToEdit: ruleId,
    rulesList,
    conditionErrors,
    setRuleToEdit,
    createRuleWithConditions,
    loadCampaignReports,
  } = rulesStore
  const { accountId } = accountStore

  const [isFormShown, setFormShown] = useState(false)
  const [ruleType, setType] = useState(ruleTypeOptions[0].value)
  const [frequency, setFrequency] = useState(conditionFrequencyOptions[0].value)

  const handleCancel = useCallback(() => {
    setRuleToEdit()
  }, [setRuleToEdit])

  const ruleToEdit = useMemo(() => {
    if (!ruleId) return undefined
    return rulesList?.find((item) => item.id === ruleId)
  }, [ruleId, rulesList])

  const initialValues: RuleFormType = useMemo(() => {
    if (!ruleToEdit) return defaultInitialForm

    const { name, description, active, Conditions } = ruleToEdit
    const conditions = Conditions ? toTypeConditionsList(Conditions) : []

    if (conditions.length > 0) {
      setType(conditions[0].trigger)
      if (conditions[0].trigger === "BUDGET")
        setFrequency((conditions[0] as BudgetConditionType).frequency)
    }

    return {
      ...defaultInitialForm,
      name,
      description,
      active,
      conditions: Object.fromEntries(conditions.map((item) => [item.id, item])),
    }
  }, [ruleToEdit])

  const handleSubmit = useCallback(
    ({ name, description, active, conditions }: RuleFormType) => {
      if (accountId) {
        const newRuleData = {
          name,
          description,
          active,
        }

        createRuleWithConditions(
          accountId,
          newRuleData,
          Object.values(conditions),
          ruleId ?? undefined
        ).then((value: any) => {
          if (value !== false) handleCancel()
        })
      }
    },
    [accountId]
  )

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required("Filed is required"),
        conditions: yup
          .object()
          .test("Is empty", "Rule should have at least 1 condition", (data) => {
            const list = Object.values(data)
            return (
              list.length > 0 &&
              !!Object.values(data).find(
                (item: BaseConditionType) => item.status !== "DELETE"
              )
            )
          }),
      }),
    []
  )

  const formik = useFormik<RuleFormType>({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  })

  const handleAddCondition = useCallback(
    (condition: ConditionType) => {
      formik.setFieldValue("conditions", {
        ...formik.values.conditions,
        [condition.id]: { ...condition },
      })
      setFormShown(false)
    },
    [formik]
  )

  const handleCancelForm = useCallback(() => {
    setFormShown(false)
  }, [])

  const handleDelete = useCallback(
    (id: string) => {
      const { [id]: toDelete, ...rest } = formik.values.conditions
      if (toDelete) {
        const newConditions =
          toDelete.status === "CREATE"
            ? { ...rest }
            : {
                ...rest,
                [id]: { ...toDelete, status: "DELETE" },
              }
        formik.setFieldValue("conditions", newConditions)
      }
    },
    [formik]
  )

  const canChangeType = useMemo(
    () => Object.values(formik.values.conditions).length === 0,
    [formik]
  )

  const { conditionMapper, FormComponent } = useMemo(() => {
    setFormShown(false)

    if (ruleType === "DATE") {
      return {
        FormComponent: TimeTypeRoleForm,
        conditionMapper: timeRuleMapper,
      }
    }
    if (ruleType === "BUDGET") {
      return {
        FormComponent: BudgetTypeRoleForm,
        conditionMapper: budgetRuleMapper,
      }
    }
    return {
      FormComponent: () => <NoData>Unknown rule type</NoData>,
      conditionMapper: null,
    }
  }, [ruleType])

  const conditions = useMemo(
    () =>
      Object.values(formik.values.conditions).filter(
        ({ status }) => status !== "DELETE"
      ),
    [formik.values.conditions]
  )

  const isTouched = useMemo(() => {
    const list = Object.values(formik.touched)
    return (
      (list.length > 0 && !list.includes(false)) ||
      Object.values(formik.values.conditions).find(
        ({ status }) => status !== "OLD"
      )
    )
  }, [formik.touched, formik.values.conditions])

  useEffect(() => {
    if (accountId) loadCampaignReports(accountId)
  }, [])

  const ruleOptions = useOptionList(ruleTypeOptions)
  const frequencyOptions = useOptionList(conditionFrequencyOptions)

  return (
    <FormikProvider value={formik}>
      <div className={styles.root}>
        <ServiceDownInterceptor />
        <form className={styles.body}>
          <Box className={styles.row}>
            <FormTextField
              label="Rule name"
              name="name"
              className={styles.field}
            />

            <FormTextField
              label="Rule description"
              name="description"
              className={styles.field}
            />
          </Box>
          <div className={styles.container}>
            <Typography type="h2">Rule Conditions</Typography>
            <Box className={styles.row}>
              <Label className={styles.field} label="Rule type">
                <SimpleSelect
                  {...ruleOptions}
                  disabled={!canChangeType}
                  value={ruleType}
                  onSelect={setType}
                />
              </Label>
              {ruleType !== "DATE" && (
                <Label className={styles.field} label="Frequency">
                  <SimpleSelect
                    {...frequencyOptions}
                    disabled={!canChangeType}
                    value={frequency}
                    onSelect={setFrequency}
                  />
                </Label>
              )}
            </Box>
          </div>
          <Typography type="h2">Existing conditions</Typography>
          {conditions.length > 0 ? (
            <div className={styles.container}>
              {conditions.map((data: any) => (
                <Label
                  label=""
                  error={conditionErrors?.[data.id]}
                  errorTextType="h3"
                >
                  <Box className={styles.shortRow} key={data.id}>
                    {conditionMapper?.map((mapper) => (
                      <Label
                        className={styles.field}
                        label={mapper.label}
                        style={{ flex: mapper.flex ?? "" }}
                        key={mapper.name}
                      >
                        {mbNoData(data[mapper.name], mapper.renderCallback)}
                      </Label>
                    ))}
                    <Icon
                      className={styles.controlIcon}
                      onClick={() => handleDelete(data.id)}
                      name="trash"
                      primary
                    />
                  </Box>
                </Label>
              ))}
            </div>
          ) : (
            <NoData>
              {formik.touched.conditions && formik.errors.conditions ? (
                <Typography color="red">{formik.errors.conditions}</Typography>
              ) : (
                "None conditions yet added"
              )}
            </NoData>
          )}
          {isFormShown ? (
            <div className={styles.container}>
              <Typography type="h2">New Condition</Typography>
              <FormComponent
                onSubmit={handleAddCondition}
                onCancel={handleCancelForm}
                frequency={frequency}
              />
            </div>
          ) : (
            <div className={styles.justifiedStart}>
              <Button
                variant="contained"
                startIcon={{ name: "plus", circle: true }}
                onClick={() => setFormShown(true)}
              >
                Add new condition
              </Button>
            </div>
          )}
        </form>
        <div className={styles.footer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={!isTouched}
            variant="contained"
            onClick={formik.submitForm}
          >
            Save Rule
          </Button>
        </div>
      </div>
    </FormikProvider>
  )
})

export default EditRule
