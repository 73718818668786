import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import ProgressSubsection from "@pages/PMax/components/EditAssetGroupPage/Assets/ProgressSubsection"
import Label from "@components/ui/Label/Label"
import Images from "@pages/PMax/components/EditAssetGroupPage/Assets/Images"
import Logos from "@pages/PMax/components/EditAssetGroupPage/Assets/Logos"
import Headlines from "@pages/PMax/components/EditAssetGroupPage/Assets/Headlines"
import LongHeadlines from "@pages/PMax/components/EditAssetGroupPage/Assets/LongHeadlines"
import Description from "@pages/PMax/components/EditAssetGroupPage/Assets/Description"
import Sitelinks from "@pages/PMax/components/EditAssetGroupPage/Assets/Sitelinks"
import Preview from "@pages/PMax/components/EditAssetGroupPage/Assets/Preview"
import FormTextField from "@framework/prototypes/FormTextField"
import { AssetGroupEntity } from "@framework/types/manageCampaign"
import Videos from "@pages/PMax/components/EditAssetGroupPage/Assets/Videos"
import BusinessName from "@pages/PMax/components/EditAssetGroupPage/Assets/BusinessName"
import CallToAction from "@pages/PMax/components/EditAssetGroupPage/Assets/CallToAction"
import styles from "./Assets.module.scss"

interface AssetsProps {
  data: Partial<AssetGroupEntity>
  handleDeleteVideo: (id: number) => void
}
const Assets: FC<AssetsProps> = observer(({ data, handleDeleteVideo }) => {
  const headlines = data.headlineCount || []
  const longHeadlines = data.longHeadlineCount || []
  const videos = data.youtubeVideos || []
  const images = data.imageAssets || []
  const logos = data.logoAssets || []
  return (
    <section className={styles.assetsWrapper}>
      <Typography type="h2" className={styles.listingTitle}>
        Assets
      </Typography>
      <ProgressSubsection
        images={images}
        description={data.descriptionCount || []}
        video={[]}
        products={[]}
        headlines={[...headlines, ...longHeadlines]}
      />
      <div className={styles.assetsSections}>
        <div>
          <div className={styles.finalUrl}>
            <Typography type="h2">Final URL</Typography>
            <Typography type="body1" color="gray">
              URL expansion is on
            </Typography>
            <Label label="Final URL" type="body2" className={styles.inputUrl}>
              <FormTextField name="finalUrl" />
            </Label>
          </div>

          <Images images={images} />
          <Logos logos={logos} />
          <Videos handleDeleteVideo={handleDeleteVideo} videos={videos} />
          <Headlines />
          <LongHeadlines />
          <Description />
          <BusinessName />
          <Sitelinks />
          <CallToAction />
        </div>
        <Preview />
      </div>
    </section>
  )
})

export default Assets
