/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"

import { Option } from "@framework/types/types"
import Icon from "@components/ui/Icon/Icon"
import BaseListItem from "@components/ui/DropDown/ListItem"
import Select from "@components/ui/DropDown"

type ContextMenuProps = {
  className?: string
  options: Option[]
  onClick?: (name: Option) => void
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  className,
  options,
  onClick,
}) => (
  <Select.Box className={className}>
    {options.map((option) => {
      const { value, label, icon } = option
      return (
        <BaseListItem
          onClick={() => onClick && onClick(option)}
          key={value}
          before={icon && <Icon name={icon} primary />}
        >
          {label}
        </BaseListItem>
      )
    })}
  </Select.Box>
)

export default ContextMenu
