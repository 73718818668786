import React from "react"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import { useHistory } from "react-router-dom"
import { useAlert } from "react-alert"

import Button from "@components/ui/Button/Button"
import SelectCustomerModal from "@components/modals/SelectCustomerModal/SelectCustomerModal"
import SelectAccountChannelModal from "@components/modals/SelectAccountChannelModal/SelectAccountChannelModal"
import useToggle from "@framework/hooks/useToggle"
import { useStore } from "@store/index"
import { CustomerType } from "@store/account-linking/account-linking"
import useAccountLinkRedirect from "@framework/prototypes/LinkAccount/useAccountLinkRedirect"
import useQueryParams from "@framework/hooks/useQueryParams"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { TabNames } from "../../CreateCampaign"

import styles from "./GoogleAdwordsLink.module.scss"

type GoogleAdwordsLinkProps = {
  switchTab: (tabName: TabNames) => void
}

const GoogleAdwordsLink: React.FC<GoogleAdwordsLinkProps> = observer(
  ({ switchTab }) => {
    const history = useHistory()
    const params = useQueryParams()
    const alert = useAlert()

    const {
      accountLinkingStore: { linkPending, linkAccount, loadCustomerList },
      accountStore: { accountId, loadAccount },
    } = useStore()

    const customersModal = useToggle(false)
    const channelModal = useToggle(false)

    const { redirect: linkRedirect } = useAccountLinkRedirect()

    const [code, setCode] = React.useState<string>()

    const handleLinkAccount = async (selected: CustomerType) => {
      if (!accountId) return
      const error = await linkAccount(accountId, selected)
      if (error != null) {
        alert.error(
          <AlertMessage title="Failed to link account" description={error} />
        )
        return
      }
      customersModal.setOpened(false)

      await loadAccount()

      switchTab("action")
    }

    const handleLinkModalClose = () => {
      customersModal.setOpened(false)
      loadAccount()
    }

    const handleNewAccountClick = (item: string) => {
      if (!accountId) return
      channelModal.setOpened(false)
      linkRedirect("create-campaign", { id: accountId })
    }

    React.useEffect(() => {
      const { code } = params
      if (code) {
        setCode(code)
        history.replace(history.location.pathname)
      }
    }, [params])

    React.useEffect(() => {
      if (accountId && code) {
        loadCustomerList(code, accountId, "account")
        customersModal.setOpened(true)
      }
    }, [code])

    return (
      <div className={styles.root}>
        <Typography
          type="h2"
          color="primary"
          weight="bold"
          className={styles.title}
        >
          {`To Implement New Ads Campaign, link Google \n Ads Account`}
        </Typography>
        <img src="/images/googleAdwords.svg" alt="Google Ads" />
        <div className={styles.buttonsWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => channelModal.setOpened(true)}
          >
            Link Account
          </Button>
        </div>

        <SelectCustomerModal
          isOpen={customersModal.isOpened}
          isLoading={linkPending}
          onSubmit={handleLinkAccount}
          onClose={handleLinkModalClose}
        />

        <SelectAccountChannelModal
          isOpen={channelModal.isOpened}
          onClose={() => channelModal.setOpened(false)}
          onSubmit={handleNewAccountClick}
        />
      </div>
    )
  }
)

export default GoogleAdwordsLink
