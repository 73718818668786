import moment from "moment"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { periodLength, printDateRange } from "@utils/date"
import difference from "lodash/difference"
import { YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"

export const isOptionsListEqual = <T>(list1: T[], list2: T[]) =>
  // eslint-disable-next-line eqeqeq
  list1 == list2 ||
  (list1.length === list2.length && difference(list1, list2).length === 0)

export const isDateRangeOptionsEqual = (
  date1: [Date, Date],
  date2: [Date, Date]
) =>
  printDateRange(date1, YYYY_MM_DD_DATE_FORMAT) ===
  printDateRange(date2, YYYY_MM_DD_DATE_FORMAT)

export const getCurrentPeriodOptions = (
  maxDate = new Date()
): DateRangeOption[] => {
  const startMoment = moment(maxDate).endOf("day")
  return [
    {
      label: "Last 7 days",
      value: "one_week",
      range: [
        startMoment.clone().subtract(6, "days").startOf("day").toDate(),
        startMoment.clone().toDate(),
      ],
    },
    {
      label: "Last 14 days",
      value: "two_weeks",
      range: [
        startMoment.clone().subtract(13, "days").startOf("day").toDate(),
        startMoment.clone().toDate(),
      ],
    },
    {
      label: "Last 30 days",
      value: "month",
      range: [
        startMoment.clone().subtract(29, "days").startOf("day").toDate(),
        startMoment.clone().toDate(),
      ],
    },
    {
      label: "Custom period",
      value: "custom",
      editable: true,
      range: [
        startMoment.clone().subtract(6, "days").startOf("day").toDate(),
        startMoment.clone().toDate(),
      ],
    },
  ]
}

export const getPreviousPeriodOptions = (
  period: [Date, Date]
): DateRangeOption[] => {
  const startMoment = moment(period[0])
  const startMomentYearAgo = moment(period[1]).subtract(1, "years")
  const periodDays = periodLength(period, "day")
  return [
    {
      label: "Previous period",
      value: "prev_period",
      range: [
        startMoment.clone().subtract(periodDays, "days").toDate(),
        startMoment.clone().subtract(1, "days").toDate(),
      ],
    },
    {
      label: "Previous year",
      value: "previous_year",
      range: [
        startMomentYearAgo
          .clone()
          .subtract(periodDays - 1, "days")
          .toDate(),
        startMomentYearAgo.clone().toDate(),
      ],
    },
  ]
}
