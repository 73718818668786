import React from "react"
import { ID } from "@framework/types/types"
import AddKeywordsModal from "@components/modals/AddKeywordsModal/AddKeywordsModal"
import useToggle from "@framework/hooks/useToggle"

const useAddKeywordsModal = (options?: { adGroupId?: ID; campaignId?: ID }) => {
  const modal = useToggle()

  const Modal = modal.isOpened ? (
    <AddKeywordsModal
      isOpen
      onClose={() => modal.setOpened(false)}
      campaignId={options?.campaignId}
      adGroupId={options?.adGroupId}
    />
  ) : null
  return {
    open: () => modal.setOpened(true),
    Modal,
    ...modal,
  }
}

export default useAddKeywordsModal
