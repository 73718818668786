import keyBy from "lodash/keyBy"
import moment from "moment"

import {
  DEF_YYYY_MM_DD_DATE_FORMAT,
  YEAR_MONTH_KEY_FORMAT,
  YEAR_WEEK_KEY_FORMAT,
} from "@framework/constants/moment-format"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import {
  MetricNameType,
  PerformanceReport,
  TSPerfReportMap,
  TimeSegmentedPerformanceReport,
} from "@framework/types/metrics"

export const extractTimeSegmentedPerformanceValues = (
  metric: MetricNameType,
  performanceList: PerformanceReport[]
): number[] => performanceList.map((pointData) => pointData[metric]?.Value ?? 0)

export const periodicityToDateFormat = (
  periodicity: PerformanceReportPeriodicity
): string => {
  if (periodicity === "monthly") return YEAR_MONTH_KEY_FORMAT
  if (periodicity === "weekly") return YEAR_WEEK_KEY_FORMAT
  return DEF_YYYY_MM_DD_DATE_FORMAT
}

export const toTSPerfReportMap = (
  endDate: string, // YYYY-MM-DD
  report: TimeSegmentedPerformanceReport[],
  periodicity: "day" | "week" | "month"
): TSPerfReportMap =>
  keyBy(report, (it) =>
    moment
      .utc(it.Period, DEF_YYYY_MM_DD_DATE_FORMAT)
      .diff(moment.utc(endDate, DEF_YYYY_MM_DD_DATE_FORMAT), periodicity)
  )
