import React from "react"

import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { initArray } from "@utils/numberUtils"

import styles from "./ReportCard.module.sass"

interface ReportSkeletonCardProps {
  totalMetrics?: number
  loading?: boolean
}

export const ReportSkeletonCard: React.FC<ReportSkeletonCardProps> = ({
  totalMetrics = 15,
  loading = false,
}) => (
  <Box className={styles.root} elevation={1}>
    <div className={styles.header}>
      <Skeleton
        loading={loading}
        count={1}
        minWidth={75}
        maxWidth={90}
        lineHeight={12}
        align="start"
      />

      <Skeleton
        loading={loading}
        count={1}
        minWidth={100}
        lineHeight={20}
        rounded
        align="end"
        style={{ flex: "1 0 20px" }}
      />
    </div>

    <Skeleton
      loading={loading}
      count={1}
      minWidth={30}
      maxWidth={50}
      lineHeight={10}
    />

    <div className={styles.previewContainer} />

    <Stack
      className={styles.metricsContainer}
      gutter="4"
      direction="column"
      align="stretch"
    >
      {initArray(totalMetrics, (index) => (
        <Stack
          direction="row"
          justify="space-between"
          align="center"
          key={index}
        >
          <Skeleton
            loading={loading}
            count={1}
            minWidth={30}
            maxWidth={90}
            lineHeight={15}
            align="start"
          />

          <Skeleton
            loading={loading}
            count={1}
            rounded
            minWidth={20}
            maxWidth={60}
            lineHeight={22}
            align="end"
          />
        </Stack>
      ))}
    </Stack>
  </Box>
)

export default React.memo(ReportSkeletonCard)
